// ChatBotWrapper.js
import React from 'react';
import CustomProfitAssist from "./CustomProfitAssist";
import "./ChatBotWrapper.css";
import "./category.css";
import "./conversation.css";
import "./recentChats.css";

function ChatBotWrapper(props) {
    return (
        <div className="chat-wrapper">
            {<CustomProfitAssist userSettings={props.userSettings} showChatbot={props.showChatbot}
                                 allowedMenuLinks={props.allowedMenuLinks}/>}
        </div>
    );
}

export default ChatBotWrapper;
