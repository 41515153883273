import { useFeatureFlagEnabled } from "posthog-js/react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { components } from "react-select";
import { BUTTON_TYPE, BUTTON_VARIANT, DROPDOWN_TYPE, HEADER_ELEMENT, SIZES } from "../class/constants";
import { findOptionByKey } from "../class/utils";
import { lang } from "../language/messages_en";
import Button from "../newComponents/Button";
import DropDown from "../newComponents/DropDown";
import { ReactComponent as EditIcon } from "../styles/images/icons/edit-square.svg";
import { ReactComponent as NewEditIcon } from "../styles/images/menu-svgs/edit-icon.svg";

/**
 * This component represents the scenario shown in the left hand menu.
 * It handles changing the scenario.
 * @param {*} isReadOnly if scenario can not be changed, the edit button will not be available (default value = false)
 * @param {*} scenarioOptions scenario dropdown options
 * @param {*} defaultScenarioId default selected scenario ID
 * @returns
 */
const Option = (props) => {
  return (
    <div title={props.data.title}>
      <components.Option {...props} />
    </div>
  );
};

Option.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
  }),
};

const ScenarioContainer = ({ isReadOnly = false, scenarioOptions, defaultScenarioId, saveScenario }) => {
  const [isInEditMode, setInEditMode] = useState(false); // if isInEditMode = true, it means that the user is seing a dropdown to change the scenario
  const [selectedScenario, setselectedScenario] = useState(scenarioOptions?.find((f) => f.value === defaultScenarioId));

  const useNewComponents = useFeatureFlagEnabled("use_new_components");

  const saveSelectedScenario = () => {
    saveScenario(selectedScenario);
    setInEditMode(false);
  };

  useEffect(() => {
    setselectedScenario(scenarioOptions?.find((f) => f.value === defaultScenarioId));
  }, [scenarioOptions, defaultScenarioId]);

  const renderOldContainer = () => {
    return (
      <div className="scenario-div">
        <label className="scenario-label">{lang.scenario}</label>
        {isInEditMode && !isReadOnly ? (
          <>
            <div id="scenario-select">
              <DropDown
                id="select-set"
                data-testid="select-set"
                className="scenario-dropdown input__dropdown"
                name={HEADER_ELEMENT.SCENARIO}
                value={findOptionByKey(scenarioOptions, selectedScenario?.value)}
                onChange={(val) => setselectedScenario(val)}
                options={scenarioOptions}
                type={DROPDOWN_TYPE.INPUT}
                firstAttr={"#scenario-select"}
              />
            </div>

            <Button
              data-testid="save-scenario"
              variant={BUTTON_VARIANT.SECONDARY}
              size={SIZES.ICON}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={saveSelectedScenario}
              leftIcon={<i className="fal fa-save fa-lg"></i>}
            />
          </>
        ) : (
          <>
            <label data-testid="label-scenario" className="scenario-value" value={selectedScenario?.label || "1"}>
              {selectedScenario?.label}
            </label>
            <Button
              data-testid="edit-scenario"
              variant={BUTTON_VARIANT.SECONDARY}
              size={SIZES.ICON}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={() => setInEditMode(true)}
              leftIcon={<EditIcon />}
              style={{ opacity: isReadOnly ? 0 : 1, pointerEvents: isReadOnly ? "none" : "unset", display: "grid" }}
            />
          </>
        )}
      </div>
    );
  };

  const renderNewContainer = () => {
    return (
      <div className="scenario-div new-scenario-container">
        {isInEditMode && !isReadOnly ? (
            <div id="scenario-select">
              <DropDown
                id="select-set"
                data-testid="select-set"
                className="scenario-dropdown button__dropdown"
                name={HEADER_ELEMENT.SCENARIO}
                value={findOptionByKey(scenarioOptions, selectedScenario?.value)}
                onChange={(val) => setselectedScenario(val)}
                options={scenarioOptions}
                type={DROPDOWN_TYPE.BUTTON}
                placeholder={findOptionByKey(scenarioOptions, selectedScenario?.value)?.label}
                firstAttr={"#scenario-select"}
                icon={<i className="fal fa-save fa-lg"></i>}
                removeDropdownIndicator
                ignoreFocusState
              />
              <div className="scenario-save-button-container">
                <Button
                  data-testid="save-scenario"
                  variant={BUTTON_VARIANT.PRIMARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={saveSelectedScenario}
                  label={"SAVE"}
                />
              </div>
            </div>
        ) : (
          <div style={{ display: "flex", columnGap: "0.41667vw" }}>
            <Button
              data-testid="edit-scenario"
              id="edit-scenario"
              variant={BUTTON_VARIANT.SECONDARY}
              size={SIZES.ICON}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={() => setInEditMode(true)}
              leftIcon={<NewEditIcon />}
              style={{ display: isReadOnly ? "hidden" : "unset", pointerEvents: isReadOnly ? "none" : "unset", display: "grid", padding: "0 2px 2px 5px" }}
            />

            <div className={"scenario-label-container"}>
              <label className="scenario-label">{lang.scenario}</label>
              <label data-testid="label-scenario" className="scenario-value" value={selectedScenario?.label || "1"}>
                {selectedScenario?.label}
              </label>
            </div>
          </div>
        )}
      </div>
    );
  };

  return <>{useNewComponents ? renderNewContainer() : renderOldContainer()}</>;
};

export default ScenarioContainer;
