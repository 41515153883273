import { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useAuthenticated } from "../CustomHooks";
import ErrorBoundary from "../../ErrorBoundary";
import Button from "../../newComponents/Button";
import ManageScenarios from "../../sections/ManageScenarios";
import { ReportHeader } from "../ReportHeader";
import { Loader } from "../../form/elements";
import { logout } from "../../class/common";
import { ALL_REPORTS, ALL_WIDGETS, BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../../class/constants";
import { lang } from "../../language/messages_en";
import { getAllFileTypeColumns, getClientPeriods } from "../api/apiDataModeling";
import {renderBreadcrumbsAndDescription, renderTitle} from "../functions/componentFunctions";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from "posthog-js/react";

/**
 * @author Sandra Abdelkhalek
 * @description This component holds the the header of the Manage Scenario & Manage Scenario's component
 * where the header contains the report title and the body carrying the Manage Scenario's
 * @param {*} props
 * @returns Report Header & Manage Scenario's body
 */
const ManageScenariosWrapper = (props) => {
  const { userAllowedMenuLinks } = props;
  const { userSettings } = props;

  const profitFormat = ALL_WIDGETS.FIELDS.MANAGE_SCENARIOS;

  /** References & react utility */
  const reportRef = useRef();
  const params = useParams();
  const dispatch = useDispatch();

  /** Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

  const [reportTitle, setReportTitle] = useState(ALL_REPORTS.MANAGE_SCENARIO);
  const [clientPeriodsState, setClientPeriodsState] = useState([]);
  const [fileTypeColumns, setFileTypeColumns] = useState([]);
  const [headerElements, setHeaderElements] = useState([]);
  const [isConfigureBudgetScreen, setIsConfigureBudgetScreen] = useState(false);
  const useNewComponents = useFeatureFlagEnabled('use_new_components');

  /**
   * @function useEffect()
   * @description on screen initialization if isAuthenticated is false user gets logged out
   * isAuthenticated is a boolean read from a custom hook named useAuthenticated
   **/
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  /**
   * @function useEffect()
   * @description On component mount, fetch client periods & file type columns
   */
  useEffect(() => {
    getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
    getAllFileTypeColumns(undefined, undefined, setFileTypeColumns);
  }, []);

  /**
   * @function useEffect()
   * @description render header elements and update them when we go to configure budget
   */
  useEffect(() => {
    let headerElements = useNewComponents ? getNewHeaderElements() : getHeaderElements();
    setHeaderElements(headerElements);
  }, [isConfigureBudgetScreen, reportTitle, useNewComponents]);

  /**
   * @function getHeaderElements()
   * @description This function renders reportTile
   * @returns array of components to be rendered in {@link ReportHeader} component
   */
  const getHeaderElements = () => {
    let headerElements = [];
    headerElements.push(
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        {renderTitle(reportTitle)}
        {isConfigureBudgetScreen && (
          <div style={{ display: "flex", columnGap: "0.42vw" }}>
            <Button
              id="configure-budget-back"
              label={lang.navigation.btns.back}
              variant={BUTTON_VARIANT.SECONDARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={exitConfigureBudget}
            />
            <Button
              id="configure-budget-next"
              label={lang.navigation.btns.save}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={reportRef.current?.configureBudgetRef.saveBudgetConfigurations}
            />
          </div>
        )}
      </div>
    );
    return headerElements;
  };

  const renderHeaderFirstRow = () => {
    return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription)

  }

  const renderHeaderSecondRow = () => {
    return (
        <div style={{display: "flex", columnGap: "0.42vw"}}>
          <Button
              id="configure-budget-back"
              label={lang.navigation.btns.back}
              variant={BUTTON_VARIANT.SECONDARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={exitConfigureBudget}
          />
          <Button
              id="configure-budget-next"
              label={lang.navigation.btns.save}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={reportRef.current?.configureBudgetRef.saveBudgetConfigurations}
          />
        </div>
    )
  }

  const getNewHeaderElements = () => {
    let headerElements = [];
    headerElements.push(
        <div style={{display: "flex", width: "100%", alignItems: "center"}}
             className="new_header_menu first_header_row gap_between_buttons">
          {renderHeaderFirstRow()}
        </div>
    );

    headerElements.push(
        <>
          {isConfigureBudgetScreen && (
              <div className={"new_header_menu second_header_row gap_between_buttons "}
                   style={{display: "flex", width: "100%", padding: "0.41667vw 0"}}>
                {renderHeaderSecondRow()}
              </div>
          )}
        </>
    );
    return headerElements;
  };


  /**
   * @function exitConfigureBudget()
   * @description This is the onClick of the 'Back' button.
   * It returns the user to the Manage Scenario screen
   */
  const exitConfigureBudget = () => {
    setIsConfigureBudgetScreen(false);
    setReportTitle(ALL_REPORTS.MANAGE_SCENARIO);
  };

  /**
   * @function goToConfigureBudget()
   * @description This is the onClick of the 'Configure' button found in the table actions buttons.
   * It redirectd the user to the Configure Budget screen
   */
  const goToConfigureBudget = () => {
    setIsConfigureBudgetScreen(true);
    setReportTitle(lang.budgeting.configure_budgeting.report_title);
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={<p id="loading">Loading...</p>}>
        <div id={"main-component-container"} className={"main-component-container"} style={{ "--banner-height": 0 }}>
          {/* <Loader newLoader /> */}
          <div className={"header-banner-div-hidden"} />
          <div className="main-report-header">
            <ReportHeader headerElements={headerElements} />
          </div>
          <div className="main_report">
            {Object.keys(clientPeriodsState).length > 0 && (
              <ManageScenarios
                ref={reportRef}
                allBudgetingColumns={fileTypeColumns.allBudgetingColumns}
                allNumericTransactionColumns={fileTypeColumns.allNumericTransactionColumns}
                allPeriods={clientPeriodsState.allPeriods}
                allTypeColumns={fileTypeColumns.allTypeColumns}
                dispatch={dispatch}
                fromEngine
                periods={clientPeriodsState.periods}
                scenarios_limit={userSettings.scenario_limit}
                user={userSettings.user}
                userAllowedSections={userAllowedMenuLinks}
                goToConfigureBudget={goToConfigureBudget}
                isConfigureBudgetScreen={isConfigureBudgetScreen}
              />
            )}
          </div>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
};

export { ManageScenariosWrapper };

