import DialogActions from '@mui/material/DialogActions';
import DialogContent from "@mui/material/DialogContent";
import { AuthErrorCodes, browserSessionPersistence, getAuth, getMultiFactorResolver, getRedirectResult, multiFactor, onAuthStateChanged, PhoneAuthProvider, RecaptchaVerifier, SAMLAuthProvider, setPersistence, signInWithEmailAndPassword, signInWithPopup, signInWithRedirect, signOut } from "firebase/auth";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import HttpsRedirect from "react-https-redirect";
import Popup from "react-popup";
import { useHistory } from "react-router-dom";

import { getLocalStorageValueByParameter, setLocalStorageValueByParameter, toggleLoader, _LOGGED_OUT } from "./class/common";
import { API_URL, BUTTON_TYPE, BUTTON_VARIANT, CLIENTS, CLIENT_ID_STORAGE, ACTION_ID_STORAGE, USER_EMAIL_STORAGE, DATA_LAYER_PARAMS, DEFAULT_CLIENT_ID, DIALOG_SIZE, LOG_LEVEL, MANAGE_ACCESS, SIZES, UI_ACTIONS, APPLY_SCOPE } from "./class/constants";
import { convertPxToViewport } from './class/formatting';
import { alertAndLogError, isInputEmpty, isValidEmailAddress, prepareLogo, removeAllCookies, removeCookie, saveCookie } from "./class/jqueries.js";
import { fetchAPI, fetchAPIAjax, FETCHAPI_PARAMS, FETCH_METHOD, logUIError, logAPI, logUIInfo, COORDINATES_REQUEST_TRACKER } from "./class/networkUtils";
import {onEnterClick, getSectionId, parseBoolean} from "./class/utils";
import SetPassword from './components/signInUp/SetPassword';
import VerifyCode from "./components/signInUp/VerifyCode";
import { auth } from "./firebase/firebase.js";
import { Email, Loader, Password, SubmitBtn } from "./form/elements.js";
import { lang } from "./language/messages_en";
import LogoPI from "./LogoPI.js";
import SelectClient from './SelectClient';
import "./styles/login.css";
import "./styles/popup.css";
import Button from './newComponents/Button';
import Input from './newComponents/Input';
import Modal from './newComponents/Modal';
import cookie from 'react-cookies';
import { clearIndexedDB, openDatabase } from './indexedDB';

const $ = require("jquery");

const SHOW_TOAST_MESSAGE = "showToastMessage"
const SELECT_SESSION_TIMEOUT = "selectSessionTimeout"
const LoginUnified = () => {

  const [password, setPass] = useState(null);
  const [resolver, setResolver] = useState(null);
  const [mobileNum, setMobileNum] = useState(null);
  const [showVerify, setShowVerify] = useState(false);
  const [landingPageUrl, setLandingPageUrl] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [isSetMobileNumber, setIsSetMobileNumber] = useState(false);
  const [verifiedd, setVerifiedd] = useState(false);
  const [enrolled, setEnrolled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [isResetPasswordDisabled, setIsResetPasswordDisabled] = useState(false);
  const [resetEmail, setResetEmail] = useState($('#Email').val());
  const [selectClient, setSelectClient] = useState(false);
  const [clients, setClients] = useState([]);
  const clientId = useRef(null);
  const validationLocal = useRef(null);
  const [showSSO, setShowSSO] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEmailDifferent, setIsEmailDifferent] = useState(false);
  const currentShowSSO = useRef(false);
  const validationRef = useRef();

  // const resetEmail = useRef(null);
  const history = useHistory();
  const currUser = useRef(null);
  const email = useRef(null);
  const isMfa = useRef(false);
  const verified = useRef(false);
  const isReEnroll = useRef(false);
  const mobileNumber = useRef("");
  const isEnrolled = useRef(false);
  const savedPass = useRef(null);
  const defaultClient = useRef("");
  var reCaptchaVerifier3;
  
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [message, setMessage] = useState("");
  const [show, showResetDialog] = useState(false);
  const [isInfoDialogOpen, showInfoDialog] = useState(false);
  const [dialogInfoMsg, setDialogInfoMsg] = useState("");
  const dontLogin = useRef(false);

  useEffect(() => {
    $(window).on('beforeunload', function () {
      auth.signOut();
    });
    window._pi_stopTrackingAndCheckAction(UI_ACTIONS.URL_LOAD);
    if (window.email) {
      $("#Email").val(window.email);
    }
    auth.useDeviceLanguage();
    reCaptchaVerifier3 = new RecaptchaVerifier('new-sign-in-button-recaptcha', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        handleSubmit(auth,browserSessionPersistence);
      }
    }, auth);
    window.addEventListener("keydown",onEnterEvent);
    reCaptchaVerifier3.render();
    return () => {
      reCaptchaVerifier3.clear();
    }
  }, []);

  useEffect(() => {
    const keyDownHandler = event => {
      if (show && (event.key === 'Enter' || event.keyCode == 13)) {
        dontLogin.current = true;
        resetPasswordSendClick();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [show]);

  const onEnterEvent = (event) => {
    // If the user presses the "Enter" key on the keyboard
    if (!show && !dontLogin.current) {
      // Trigger the button element with a click
      if (currentShowSSO.current) {
        onEnterClick(event, "sign-in-button-sso");
      } else {
        onEnterClick(event, "new-sign-in-button");
      }
    }
    dontLogin.current = false;
  }

  useEffect(() => {
    if (currUser.current && (!isMfa.current || verified.current)) {
      signIn("1 - "+ currUser.current?.uid+"_"+currUser.current?.email);
    }
    onAuthStateChanged(auth, user => {
      if (user) {
        currUser.current = user;
        mobileNumber.current = mobileNumber.current === "null" ? false : mobileNumber.current;
        if (!isMfa.current && isEnrolled.current) {// first sign in after turning mfa off
          unEnrollUser();
        } else if (isMfa.current && !isEnrolled.current && mobileNumber.current && !isReEnroll.current) {// after on, off , on
          reEnroll();
        } else if (!mobileNumber.current && isMfa.current) {/// after the reset + first sign in 
          setIsSetMobileNumber(true);
        } else if (user && (!isMfa.current || verified.current)) {
          if(currentShowSSO.current || parseBoolean(localStorage.getItem("signInWithSSO"))){
            if (currentShowSSO.current == false && parseBoolean(localStorage.getItem("signInWithSSO"))) {
              return;
            }
            if(email.current.toLowerCase() === user.email.toLowerCase()){
              toggleLoader(true, "ssoVerification");
              signInWithSSO();
            }else{
              // block the user from logging in when inserting a different username on the identity provider side
              setIsEmailDifferent(true);
              $("#toastEmail").addClass("keep");
              grecaptcha.reset();
              toggleLoader(false, "handleSubmit");
              auth.signOut();
            }
          }else{
            signIn("2 - "+ user.uid +"_"+ user.email);
          }
        }
      }
    });
  }, [verifiedd, verificationId]);

  useEffect(() => {
    if (landingPageUrl && (!isMfa.current || verified.current)) {
      landingUrlCallback();
        window.dataLayer.push({
        [DATA_LAYER_PARAMS.EVENT] : 'login',
        [DATA_LAYER_PARAMS.USER_EMAIL]: email.current || currUser.current?.email,
        [DATA_LAYER_PARAMS.CLIENT_ID]: clientId.current,
      });
    }
  }, [landingPageUrl]);

  useEffect(()=>{
    if(getLocalStorageValueByParameter(SHOW_TOAST_MESSAGE) === "true"){
      $("#toastClientSelect").addClass("keep");
    }

  },[])

  const reEnroll = () => {
    let phoneNumber = mobileNumber.current;
    const multiFactorUser = multiFactor(auth.currentUser);
    multiFactorUser.getSession()
      .then(function (multiFactorSession) {
        // Send verification code
        const provider = new PhoneAuthProvider(auth);
        var phoneInfoOptions = {
          phoneNumber: phoneNumber,
          session: multiFactorSession,
        };
        return provider.verifyPhoneNumber(phoneInfoOptions, reCaptchaVerifier3)
      })
      .then(function (verificationId) {
        setVerificationId(verificationId);
        isReEnroll.current = true;
        setShowVerify(true);
      })
      .catch(function () {
        grecaptcha.reset();
        setInfoDialogOpen(true, lang.mfa.code_not_sent);
      });
  };

  const signInCallBack = (id) => {
    clientId.current = id;
    loginToApp(id);
  }

  const signIn = (from) => {
    checkIfPasswordExpired(from);
  }

  const signingIn = (removeLoader) => {
    if (validationLocal.current) {
      const activeClients = getClientsOfUser(validationLocal.current.clientsStatuses);
      validationLocal.current.clientsStatuses = activeClients; // remove clients with inactive roles from dropdown

      if (activeClients.length === 1) {
        signInCallBack(activeClients[0].client_id);
      } else {
        const defaultClient = activeClients.find(e => e.client_id === activeClients[0].default_client && 
                                                      e.access_group_status === MANAGE_ACCESS.ACTIVE && 
                                                      e.role_status === MANAGE_ACCESS.ACTIVE && 
                                                      e.user_status === MANAGE_ACCESS.ACTIVE);

        setClients(activeClients);
        if (!defaultClient) { // if the default client is not active
          if(removeLoader ) {
            toggleLoader(false, "ssoVerification");
          }
          setSelectClient(true);
        } else {
          signInCallBack(defaultClient.client_id);
        }
      }
    }
  }

  const signInWithSSO = () => {
    signingIn(true);
  }

  const showSSOComp = () =>{
    cookie.save('fromSignIn','true');
    localStorage.setItem("signInWithSSO",(!showSSO).toString());
    setErrorMessage("");
    currentShowSSO.current = !showSSO;
    setShowSSO(!showSSO);
  }

  const unEnrollUser = () => {
    var options = multiFactor(auth.currentUser).enrolledFactors;
    return multiFactor(auth.currentUser).unenroll(options[0])
      .then(function () {
        isEnrolled.current = false;
      }).catch(function (error) {
        console.log(error);
      });
  }

  const landingUrlCallback = () => {
    window.removeEventListener("keydown", onEnterEvent);
    $(window).keydown(function (e) {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });
    window._format.fetchUserSettings(function () {
      toggleLoader(false, "loginToApp");
      history.push(landingPageUrl);
      localStorage.removeItem(window.location.host + "_" + _LOGGED_OUT);
      // updateLastLoginDate(email.current);
      toggleLoader(false, "verification");
      toggleLoader(false, "ssoVerification");
    });
  };

  /**
   * clear session, localstorage and cookies
   */
  const clearAllSavedData = () => {
    removeAllCookies(true);
    setLocalStorageValueByParameter(window.location.host + "_lastTimeActive", "");
    setLocalStorageValueByParameter(CLIENT_ID_STORAGE, "");
    setLocalStorageValueByParameter(ACTION_ID_STORAGE, "");
  }

  const loginToApp = (clientId) => {
    if (currUser.current) {
      toggleLoader(true, "loginToApp");
      clearAllSavedData();
      setLocalStorageValueByParameter(CLIENT_ID_STORAGE, clientId);
      setLocalStorageValueByParameter(USER_EMAIL_STORAGE, currUser.current.email);
      saveCookie("user_email", email.current);
      setActionIdToLocalStorage();
      getUserSettings(currUser.current.accessToken, landingUrlCallback);
    }
  }

  /**
   * checks if passwrod expired so it redirects him to set password screen if it did or to landing page it didn't
   */
  const checkIfPasswordExpired = (from) => {
    let url = window.location.href;
    let query = {
      action: "checkIfPasswordExpired",
      email: email.current || currUser.current?.email,
      domainUrl: url
    }
    if(!email.current){
      logAPI(("from: " + from + " email.current: "+ email.current + " currUser.current: "+ currUser.current?.email +" emailHTML " + $("#Email").val()), LOG_LEVEL.WARNING);
    }

    let onThenCallback = function (data) {
      if (!parseBoolean(data.isExpired)) {
        // if the default client is not active
        signingIn()

      } else {
        let hashKey = data.hashKey;
        let resetPageURL = "set_password/" + hashKey;
        setMessage(lang.manage_access.password_expired);
        sessionStorage.setItem("showToast", true);
        window.history.pushState({savedPass:savedPass.current, comparePassUnified:true}, null, "/" + resetPageURL);
        window.location.href = "/" + resetPageURL;
      }
    }

    let options = {
      [FETCHAPI_PARAMS.funcName]: "checkIfUserPasswordExpired",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: API_URL.USER,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.platform.login.check_if_password_expired,
      [FETCHAPI_PARAMS.screenName]: lang.observability.platform.login.screen_name,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
    };

    fetchAPIAjax(options, false);
  }

  const checkUserAccess = (email, isLoginSSOFromUI) => {
    let validation = {
      isActive: false,
      domainInfo: {
        isValid: false,
      },
      failedAttempts: 0
    };
    let query = {
      action: "checkUserAccess",
      email: email,
      domainUrl: window.location.href,
      isLoginSSOFromUI:isLoginSSOFromUI
    };

    let onThenCallback = function (data) {
      if (data) {
        validation.failedAttempts = data.failedAttempts;
        validation.domainInfo = JSON.parse(data.isValidDomain);
        if(!isLoginSSOFromUI && validation.domainInfo.isUserBlocked){
          return validation;
        }
        if (validation.domainInfo.mobileNumber) {
          setMobileNum(validation.domainInfo.mobileNumber)
          mobileNumber.current = validation.domainInfo.mobileNumber;
        }
        if (parseBoolean(validation.domainInfo.isMFA)) {
          isMfa.current = true;
        }
        validation.clientsStatuses = data.statuses;
        for (let e in data.statuses) {
          let user_status = data.statuses[e].user_status;
          let role_status = data.statuses[e].role_status;
          let access_group_status = data.statuses[e].access_group_status;
          if (user_status && role_status && access_group_status &&
            user_status === MANAGE_ACCESS.ACTIVE &&
            role_status === MANAGE_ACCESS.ACTIVE &&
            access_group_status === MANAGE_ACCESS.ACTIVE) {
            validation.isActive = true;
            break;
          }
        }
        //setActionIdToLocalStorage();
      }
    };
    let options = {
      [FETCHAPI_PARAMS.funcName]: "checkUserAccess",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: API_URL.USER,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.platform.login.check_user_acces,
      [FETCHAPI_PARAMS.screenName]: lang.observability.platform.login.screen_name,
    };
    fetchAPIAjax(options, false);
    return validation;
  };

  const setActionIdToLocalStorage = () => {
    let onThenCallback = (data) => {
      if (data && data.sessionId) {
        const actionId = btoa(data.sessionId);
        setLocalStorageValueByParameter(ACTION_ID_STORAGE, actionId);
      } else {
        logUIInfo('Session ID not found.');
      }
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getSessionId",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: "/get-session-id",
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: {},
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.platform.login.get_session_id,
      [FETCHAPI_PARAMS.screenName]: lang.observability.platform.login.screen_name,
    };
    fetchAPI(fetchOptions);
  }
  
  const resetFailedAttempts = (email) =>{
    setFailedAttempts(email, true);
  }

  const incrementFailedAttempts = (email) =>{
    setFailedAttempts(email, false, true);
  }

  /**
   * change failed_attempts_number in users table for a specific user
   * @param {*} email 
   * @param {*} isResetFailedAttempts 
   * @param {*} isIncrementFailedAttempts 
   */
  const setFailedAttempts = (email, isResetFailedAttempts, isIncrementFailedAttempts) => {
    let query = {
      action: "setFailedAttempts",
      email: email,
      isResetFailedAttempts: isResetFailedAttempts,// set failed_attempts_number to 0
      isIncrementFailedAttempts: isIncrementFailedAttempts //  incerement failed_attempts_number due to a failed attempt
    };
    let onThenCallback = (data) => {
      
    };
    let options = {
      [FETCHAPI_PARAMS.funcName]: "setFailedAttempts",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: API_URL.USER,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.platform.login.set_failed_attempts,
      [FETCHAPI_PARAMS.screenName]: lang.observability.platform.login.screen_name,
    };
    fetchAPI(options);
  };

  const getUserSettings = (idToken, callback) => {
    // let sectionId = getSectionId("PM", "");

    let query = {
      action: "getUserSettings",
      idToken: idToken,
      user_email: email.current,
      section: "",
      url: window.location.href.split("#")[0],
      logging_in: true,
      domainUrl: window.location.href,
      client_id: clientId.current
    }

    let onThenCallback = (data) => {
      if(data.settings.userHasNoScreenAccess){
        setLandingPageUrl("/profit_isle_start");
      }else{
        sessionStorage.setItem(APPLY_SCOPE, data.scopeFilters?.[0]?.isDefault);
        setLandingPageUrl(data.settings.landingPageUrl);
      }
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "getUserSettings_login",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: API_URL.USER_SETTINGS,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.email]: email.current,
      [FETCHAPI_PARAMS.profitFormat]: "Login",
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.platform.login.get_user_settings,
      [FETCHAPI_PARAMS.screenName]: lang.observability.platform.login.screen_name,
    };
    fetchAPI(fetchOptions);
  };

  const checkIfClientMFA = () => {
    var email = $("#Email").val().trim();
    var password = $("#Password").val().trim();
    if(isInputEmpty(email) || isInputEmpty(password) || !isValidEmailAddress(email)) {
      stopLoadingAndShowMessage(lang.manage_access.user.user_cannot_login_stmt);
    } 
    let validation = checkUserAccess(email, false);
    if(validation.domainInfo.isUserBlocked){
      stopLoadingAndShowMessage(lang.manage_access.user.user_blocked_stmt);
      return;
    }
    validationRef.current = validation;
    if (parseBoolean(validation.domainInfo.isMFA)) {
      document.getElementById('new-sign-in-button-recaptcha').click();
    }else {
      handleSubmit(auth,browserSessionPersistence);
    }
  }

  /**
   * handles login submit
   * @param {*} auth
   * @param {*} browserSessionPersistence
   */
  const handleSubmit = (auth, browserSessionPersistence) => {
    let openDB = async() => {
      let opened = await openDatabase();
      setLocalStorageValueByParameter(COORDINATES_REQUEST_TRACKER, JSON.stringify([]));
      clearIndexedDB();
    }
    openDB();
    cookie.save('fromSignIn','true');
    setLocalStorageValueByParameter(SHOW_TOAST_MESSAGE, false)
    $("#toastClientSelect").removeClass("keep");
    var email = $("#Email").val().trim();
    let validation = validationRef.current;
    var password = $("#Password").val().trim();
    savedPass.current = $("#Password").val().trim()
    toggleLoader(true, "handleSubmit");
    if(isInputEmpty(email) || isInputEmpty(password) || !isValidEmailAddress(email)) {
      stopLoadingAndShowMessage(lang.manage_access.user.user_cannot_login_stmt);
    } else {
      if (validation.isActive && parseBoolean(validation.domainInfo.isValid)) {
        setErrorMessage("");
        saveCookie("user_email", email);
        setPersistence(auth, browserSessionPersistence)
          .then((result) => {
            setLocalStorageValueByParameter(SELECT_SESSION_TIMEOUT,validation.domainInfo.selectSessionTimeout)
            signInWithEmailAndPassword(auth, email, password)
              .then(()=>{// username and pass are correct
                if(validation.failedAttempts >0){// after a correct login, the failed attempts should be cleared
                  resetFailedAttempts(email);
                }
              })
              .catch(function (error) {
                // Handle Errors here
                toggleLoader(false, "handleSubmit");
                if (error.code === AuthErrorCodes.MFA_REQUIRED) {
                  isEnrolled.current = true;
                  setEnrolled(true);
                  var resolver = getMultiFactorResolver(auth, error);
                  var phoneInfoOptions = {
                    multiFactorHint: resolver.hints[0],
                    session: resolver.session,
                  };
                  const phoneAuthProvider = new PhoneAuthProvider(auth);
                  // Send SMS verification code
                  phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, reCaptchaVerifier3)
                    .then(function (verificationId) {
                      setVerificationId(verificationId);
                      if (validation.domainInfo.mobileNumber && validation.domainInfo.mobileNumber !== "null") {
                        setShowVerify(true);
                      }
                      setResolver(resolver);
                    })
                    .catch(function (error){
                      logUIError("Error while signing in using email and password using MFA " + error.message);
                      console.log(error);
                    })
                } else {
                  if(error.code === AuthErrorCodes.INVALID_PASSWORD){// if wrong pass, increment the number of failed attempts for this user
                    incrementFailedAttempts(email); 
                  }
                  stopLoadingAndShowMessage(lang.manage_access.user.user_cannot_login_stmt);
                  logUIError("Error while signing in using email and password " + error.message);
                  logUIError(error.message);
                }
              });
          })
        .then(() => {
          toggleLoader(false, "handleSubmit");
          validationLocal.current = validation;
          setLocalStorageValueByParameter(DEFAULT_CLIENT_ID,validation.clientsStatuses[0].default_client);
          setLocalStorageValueByParameter(CLIENTS, JSON.stringify(getClientsOfUser(validation.clientsStatuses)));
        });
      } else {
        stopLoadingAndShowMessage(lang.manage_access.user.user_cannot_login_stmt);
        return;
      }
    }
  };

  const getClientsOfUser = (statuses) => {
    return statuses.filter(e=>e.access_group_status === MANAGE_ACCESS.ACTIVE && e.role_status === MANAGE_ACCESS.ACTIVE && e.user_status === MANAGE_ACCESS.ACTIVE);
  }


  /**
   * handles login submit for SSO
   */
  const handleSubmitSSO = () => {
    $("#sign-in-button-sso").addClass("uk-disabled");
    $("#sign-in-button-sso").addClass("disabled");

    let openDB = async() => {
      let opened = await openDatabase();
      setLocalStorageValueByParameter(COORDINATES_REQUEST_TRACKER, JSON.stringify([]));
      clearIndexedDB();
    }
    openDB();
    cookie.save('fromSignIn','true');
    setLocalStorageValueByParameter(SHOW_TOAST_MESSAGE, false);
    setIsEmailDifferent(false);
    $("#toastEmail").removeClass("keep");
    $("#toastClientSelect").removeClass("keep");
    var email = $("#Email").val().trim();
    toggleLoader(true, "handleSubmit");
    let validation=checkUserAccess(email, true);
    if(isInputEmpty(email) || !isValidEmailAddress(email)) {
      stopLoadingAndShowMessage(lang.manage_access.user.user_cannot_login_stmt);
      $("#sign-in-button-sso").removeClass("uk-disabled");
      $("#sign-in-button-sso").removeClass("disabled");
    }else if(!validation.isActive || !parseBoolean(validation.domainInfo.isValid)){
      stopLoadingAndShowMessage(lang.manage_access.user.sso_not_associated);
      $("#sign-in-button-sso").removeClass("uk-disabled");
      $("#sign-in-button-sso").removeClass("disabled");
    } else if(validation.domainInfo.isSSO === "true"){
      setErrorMessage("");
      const auth = getAuth();
      const provider = new SAMLAuthProvider(validation.domainInfo.samlProvider);
      toggleLoader(false, "handleSubmit");
      signInWithPopup(auth, provider).then((result) => { // if in app signed in and clicked back from broswer
        setLocalStorageValueByParameter(SELECT_SESSION_TIMEOUT,validation.domainInfo.selectSessionTimeout)
        validationLocal.current = validation;
        setLocalStorageValueByParameter(DEFAULT_CLIENT_ID,validation.clientsStatuses[0].default_client);
        setLocalStorageValueByParameter(CLIENTS, JSON.stringify(getClientsOfUser(validation.clientsStatuses)));          //  Provider data available using getAdditionalUserInfo()
        $("#sign-in-button-sso").removeClass("uk-disabled");
        $("#sign-in-button-sso").removeClass("disabled");
        if (currUser.current && currUser.current !== null && email.toLowerCase() === currUser.current.email.toLowerCase()){
          signInWithSSO();
        }  
      // User is signed in.
      })
       .catch((error) => {
        $("#sign-in-button-sso").removeClass("uk-disabled");
        $("#sign-in-button-sso").removeClass("disabled");
        logUIError("Error while signing in using SSO for email:" + (email ) + " Error: " + error);
        console.log(error);
        stopLoadingAndShowMessage(lang.manage_access.user.sso_problem);
      });
    } else {
      $("#sign-in-button-sso").removeClass("uk-disabled");
      $("#sign-in-button-sso").removeClass("disabled");
      stopLoadingAndShowMessage(lang.manage_access.user.sso_not_associated);
      return;
    }
  };

  const goToMultiClients=()=>{
    if(verified.current && isMfa.current &&
      validationLocal.current.clientsStatuses.filter(e=>e.access_group_status === MANAGE_ACCESS.ACTIVE
        && e.role_status === MANAGE_ACCESS.ACTIVE).length > 1
        && !validationLocal.current.clientsStatuses.filter(e=>e.access_group_status === MANAGE_ACCESS.ACTIVE
          && e.role_status === MANAGE_ACCESS.ACTIVE)[0].default_client) {
      setSelectClient(true);
    }
  }

  const stopLoadingAndShowMessage = (message) => {
    grecaptcha.reset();
    setErrorMessage(message);
    toggleLoader(false, "handleSubmit");
  };

  const setVerified = (param) => {
    if (verified.current !== param) {
      verified.current = param;
      setVerifiedd(param);
    }
  }
  const setEmail = (value) => {
    email.current = value;
  }

  const returnToLogin =() =>{
    // setSelectClient(false);
    window.location.reload();// to set selectClient to false, logout, reset captcha and return to the initial state
  }

  if(selectClient){
    return (<SelectClient clients={clients} returnToLogin={returnToLogin} signIn={signInCallBack} email={email.current || currUser.current?.email || $("#Email").val()} isLogin={true}/>)
  }

  if (isSetMobileNumber) {
    return (<SetPassword isUnified={true} setVerified={setVerified} email={email.current} password={password} firstSignin={true} unEnrollUser={unEnrollUser} goToMultiClients={goToMultiClients}/>)
  }

  if (showVerify) {
    return (<VerifyCode isUnified={true} verificationId={verificationId} fromLogin={true} phone={mobileNum}
      resolver={resolver} email={email.current} password={password} setVerified={setVerified} isReEnroll={isReEnroll.current} />)
  }

  /**
   * opens the reset password dialog
   */
  function resetPassword() {
    setResetEmail($('#Email').val()); // set the entered email in the email input in 'reset password dialog'
    showResetDialog(true);
  }

  /**
   *
   * @returns onClick function for sending email when reseting password
   */
  const resetPasswordSendClick = () => {
    let email = $("#reset-password-email").val();

    // validates emails's format and checks if the foeld is empty
    let test = isValidEmailAddress(email);
    if(!test) {
      setResetPasswordError(lang.manage_access.user.user_credentials_wrong_stmt);
      setIsResetPasswordDisabled(true);
      return;
    }

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const path = API_URL.USER;

    let url = window.location.href;
    let url_array = url.split("//");
    let protocol = url_array[0];
    let domainUrl = url_array[1].split("/")[0];
    let fullUrl = protocol + "//" + domainUrl;

    var query = {
      "action": "forgotPassword",
      "email": email,
      "domainUrl": fullUrl
    }
    let onThenCallback = (data) => {
      if (!data.isUserActive) {
        showResetDialog(true);
        setResetPasswordError(lang.manage_access.user.user_credentials_wrong_stmt);
        setIsResetPasswordDisabled(true);
        return;
      }

      if (data) {
        setMessage(lang.EMAIL_WILL_BE_SENT);
        setTimeout(function () {
          launchToast();
        }, 0.1);

        showResetDialog(false);
      }
    };
    let fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "forgotPassword",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]:path,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.platform.login.forgot_password,
      [FETCHAPI_PARAMS.screenName]: lang.observability.platform.login.screen_name,
    };
    fetchAPI(fetchOptions);
    showResetDialog(false);
  }

  /**
   * checks if email is valid and sets the value of the input field.
   * If email is not valid, sets an error message, otherwise remove the error
   * @param {*} e
   */
  const validateAndSetResetEmail = (e) => {
    let email = e.target.value;
    let isEmailValid = isValidEmailAddress(email);
    // resetEmail.current = email;
    setResetEmail(email);

    if(isEmailValid) {
      setResetPasswordError(""); // remove error if inputted email is valid
    }
    // remove disable from send button if the inputted email is valid
    if(isResetPasswordDisabled) { // this condition is to remove disable when the send button has been already disabled
      setIsResetPasswordDisabled(!isEmailValid)
    }
  }
  const resetBodyContent = () => {
    return (
      <>
        <div className="uk-display-flex pi-warning-background uk-border-rounded uk-padding-small warning_banner_container">
          <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
          <div className="fs-16">{warning}</div>
        </div>
        <div className="uk-display-flex uk-flex-center fs-14 align-items-center label_input_container">
          <span className="text-grey uk-flex-inline uk-margin-default-right">
            Email
          </span>
          <Input
            placeholder="Please enter your email address"
            // className="form-control"
            id="reset-password-email"
            name="email"
            type="email"
            autoComplete="off"
            required
            value={resetEmail}
            onChange={validateAndSetResetEmail}
            style={{ fontSize: convertPxToViewport(12) }}
          />
        </div>
        <span className="reset-password-error uk-padding-large-left error-message red">{resetPasswordError}</span>
      </>
    );
  }

  const launchToast = () => {
    $("#toastLogin").addClass("show");
    setTimeout(function () {
      $("#toastLogin").removeClass("show");
    }, 4000);
  }

  /**
   * on cancel in 'reset password': close dialog, remove error message and enable send button
   */
  const onCancelResetPassword = () => {
    showResetDialog(false);
    setIsResetPasswordDisabled(false);
    setResetPasswordError("");
  }

  const dialogActions = () => {
    return (
      <>
        <Button
          label={"Send"}
          aria-label="Close"
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={resetPasswordSendClick}
          disabled={isResetPasswordDisabled}
        />
        <Button
          label={"Cancel"}
          aria-label="Close"
          variant={BUTTON_VARIANT.TERTIARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={onCancelResetPassword}
        />

      </>
    );
  }

  const dismissToast=()=>{
    setLocalStorageValueByParameter(SHOW_TOAST_MESSAGE,false)
    $("#toastClientSelect").removeClass("keep");
  }

  const dismissEmailToast=()=>{
    setIsEmailDifferent(false);
    $("#toastEmail").removeClass("keep");
  }

  const setInfoDialogOpen = (isOpen, infoMsg) => {
   showInfoDialog(isOpen);
   setDialogInfoMsg(infoMsg)
  }

  const openInfoDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => showInfoDialog(false)}
      />
    )
  }

  let warning = lang.manage_access.send_password_reset_email2;
  return (
    <HttpsRedirect>
      <div className="main-body">
        <Modal
          id={"info-dialog"}
          openDialog={isInfoDialogOpen}
          bodyContent={() => <h4>{dialogInfoMsg}</h4>}
          dialogActions={openInfoDialogActions}
          closeClick={() => showInfoDialog(false)}
          size={DIALOG_SIZE.MEDIUM}
        />
        <Helmet>
          <meta http-equiv="Expires" content="0" />
          <meta http-equiv="Cache-Control" content="no-cache" />
          <meta http-equiv="Pragma" content="no-cache" />
        </Helmet>
        <header className="login-header">
          <LogoPI skipLogo={true}/>
        </header>
        <main id="body">
          <Loader newLoader />
          <div id="toastLogin" className={("toast "+(isError?"toast-fail":"toast-success"))}>
            <div id="desc"><i className={("fa-lg fas "+(isError ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText") + " uk-margin-small-right")} aria-hidden="true"></i>{message}</div>
          </div>
          {isEmailDifferent?
            <div id="toastEmail" className="toast toast-warning">
              <div id="descClientSelect"><i className="fa-lg fas fa-exclamation-triangle uk-margin-small-right orangeText " aria-hidden="true"></i>{lang.manage_access.user.sso_match_username}
                <span className="dismissToast" onClick={dismissEmailToast}>X</span>
              </div>
            </div>
          :""}
          <section className="container login-container">
            <div id="loginForm" className="login-form mrgt5 mrgb10">
              <Logo />
              <Modal
                id={"reset-password-dialog"}
                title={lang.manage_access.reset_password}
                openDialog={show}
                closeClick={onCancelResetPassword}
                bodyContent={resetBodyContent}
                dialogActions={dialogActions}
                size={DIALOG_SIZE.MEDIUM}
              />
              <section className="mrgt30 mrgb15">
                {showSSO? <span className='sso_title_text'>{lang.LOGIN_WITH_YOUR_SSO}</span>:""}
                <form autoComplete="on">
                  <Email className={("uk-margin-medium-top" + (showSSO ? "" : " mrgb20"))} onChange={setEmail}/>
                {!showSSO?
                  <Password onChange={setPass} resetPassword={resetPassword} />
                :""}
                </form>
                <span className="error-message red text-align-left">{errorMessage}</span>
                <div className="mrgt30 rememberBtn">
                    <div className="rememberBtn">
                      <SubmitBtn id="new-sign-in-button" className={"max_width" + (showSSO ? " uk-hidden" :"")}  onClick={()=>{checkIfClientMFA()}}/>
                      <SubmitBtn id="new-sign-in-button-recaptcha" className={"max_width uk-hidden" }/>
                      <SubmitBtn id="sign-in-button-sso" className={"max_width" + (showSSO ? "" :" uk-hidden")} onClick={()=>{handleSubmitSSO(auth)}} />
                    </div>
                </div>
                <div className="line_container">
                  <div className="login-line"></div>
                  <div className='line_or_text'>{lang.OR}</div>
                  <div className="login-line"></div>
                </div>
                <div>
                <div className="text-align-center uk-margin-medium-top">
                    <button className={"text-blue text-link uk-display-inline-block link_text_button_large"} onClick={showSSOComp} >{showSSO?lang.LOGIN_USING_DIFF_METHOD:lang.LOGIN_WITH_SSO}</button>
                </div>
                </div>
              </section>
              {getLocalStorageValueByParameter(SHOW_TOAST_MESSAGE) === "true" ?
                <div id="toastClientSelect" className="selectclient toast toast-warning">
                  <div id="descClientSelect"><i className="fa-lg fas fa-exclamation-triangle uk-margin-small-right orangeText " aria-hidden="true"></i>Your session has expired.
                    <span className="dismissToast" onClick={dismissToast}>X</span>
                  </div>
                </div> : ""
              }
            </div>
          </section>
        </main>
      </div>
    </HttpsRedirect>
  );
}

const Logo = () => {
  // let logoImage = prepareLogo(window.location.host);
  let src = "/images/login-logo.png";

  return (
    <img
      className="img-responsive sign-in-logo"
      src={src}
    />
  );
};

export default LoginUnified;