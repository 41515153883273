import PropTypes from "prop-types";
import React, { useState } from "react";
import { IconTitle } from "../../templateLayout/IconTitle";
import "./accordion.css";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { ReactComponent as ChevronUp } from "../../styles/images/menu-svgs/chevron-up.svg";
import { ReactComponent as ChevronDown } from "../../styles/images/menu-svgs/chevron-down.svg";


const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const onItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="accordion">
      {items?.map((item, index) => (
        <div key={`accordion-item-${index}`} className={`accordion-item ${item.contentComponent ? "expandable" : ""}`}>
          {item.contentComponent ? (
            <>
              <AccordionTitleComponent key={`accordion-title-item-${index}`} item={item.titleObj} isExpanded={index === activeIndex} onClick={() => onItemClick(index)} />
              <div key={item?.titleObj?.menu_item_machine_name} className={`accordion-content-container ${index === activeIndex ? "active" : ""}`}>
                <div className="content-container">
                  <div className="content">{item.contentComponent}</div>
                </div>
              </div>
            </>
          ) : (
            <div className="non-expandable-item">{item.titleComponent}</div>
          )}
        </div>
      ))}
    </div>
  );
};

const AccordionTitleComponent = ({ item, isExpanded, onClick }) => {
  const useNewComponents = useFeatureFlagEnabled('use_new_components');

  return (
    <div className={`accordion-title-container`} onClick={onClick}>
      <IconTitle
        title={item?.menu_item_display_name}
        subtitle={item?.description}
        titleClass={"accordion-title"}
        subtitleClass={"accordion-subtitle"}
        titleContainerClass={"accordion-title-component-title-container"}
        iconTitleContainerClass={"accordion-title-icon-title-container"}
        machine_name={item?.menu_item_machine_name}
        svgClass={`accordion-title-icon-svg ${useNewComponents ? "new-design" : ""}`}
        icon={
          <>
            <img src={`/images/menu-svgs/${item.menu_item_machine_name}.svg`} alt="" />
            <div className={`chevron-style ${useNewComponents ? "new-design" : ""}`}>
              {isExpanded ? <ChevronUp /> : <ChevronDown />}
            </div>
          </>
        }
      />
    </div>
  );
};

Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

AccordionTitleComponent.propTypes = {
  item: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default Accordion;
