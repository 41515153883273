import { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { convertPxToViewport } from "../../class/formatting";
import * as am5percent from '@amcharts/amcharts5/percent';
import { Skeleton } from "@mui/material";
import { FormatTypes } from "../../class/constants";
import { formatValString } from "../../class/format";

const PieChart = (props) => {
  const chartRef = useRef(null);
  const rootRef = useRef(null);

  const renderChart = () => {
    if (!rootRef.current) {
      const root = am5.Root.new(props.id);
      rootRef.current = root;
      root._logo.dispose();
      const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
          // layout: root.verticalLayout,
          // innerRadius: am5.percent(50)
          endAngle: 270
      })
      );
      const series = chart.series.push(
      am5percent.PieSeries.new(root, {
          valueField: "value",
          categoryField: "category",
          // alignLabels: false
          endAngle: 270
      })
      );

      series.states.create("hidden", {
        endAngle: -90
      });

      series.labels.template.setAll({
      textType: "circular",
      centerX: 0,
      centerY: 0
      });
      
      series.set("tooltip", am5.Tooltip.new(root, {
        labelText: "Return Rate: {returnRate.formatNumber('#.#')}% \nReturn Amount: {amount}"
      }));

      series.appear(1000, 100);
      chartRef.current = { chart, series };
    }
  }

  useEffect(() => {
      renderChart();
      return () => {
        if (rootRef.current) {
            rootRef.current.dispose();
            rootRef.current = null;
        }
      }
    }, [props.chartData]);
      
  useEffect(() => {
      if (chartRef.current && props.chartData && props.chartData?.length > 0) {
          let data = formatData(props.chartData);
          chartRef.current.series.data.setAll(data);
      }else if (chartRef.current && props.chartData?.length === 0) {
        rootRef.current?.dispose();
        rootRef.current = null;
        chartRef.current = null;
      } else {
        renderChart();
      }
  }, [props.chartData]);
      
  const formatData = (data)=> {
    const totalSum = data.reduce((sum, item) => Number(sum) + Number(item.returns), 0);
    const finalArr = data.map(({ name, returns, returnRate }) => ({ category: name, value: (Number(returns)/totalSum)*100, returnRate: Number(returnRate), amount: formatValString(returns, FormatTypes.AMOUNT) }));
    return finalArr.filter(({ value }) => Math.abs(value) > 0);
  }

  return (
    <>
      {props?.chartData?.length === 0 ?
              <div style={{paddingTop:'6vw', display:'flex', flexDirection:"column", justifyContent:"space-between"}}>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
              </div>
        : ""}
      <div id={props.id} style={{ width: "100%", height: "41vh" }}></div>
    </>
  );
};

export default PieChart;
