import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import CheckableList from "../CheckableList";
import SearchBar from "../SearchBar";
import "./querySearchableList.css";
import { CheckableListSkeleton } from "./CheckableListSkeleton";
import PropTypes from "prop-types";

const QuerySearchableList = forwardRef(function QuerySearchableList(
  { sectionsData, extraInfo, children, handleCheckboxChange, selectionLimitReached, fetchQuery, removeEntityFromRecent, setSearchTerm },
  ref
) {
  const [isDataFiltering, setIsDataFiltering] = useState(false);
  const [isLimitReached, setLimitReached] = useState(selectionLimitReached);

  const checkableList = useRef();
  const debounceTimeoutRef = useRef(null);

  useImperativeHandle(ref, () => ({
    setIsDataFiltering: (val) => {
      setIsDataFiltering(val);
    },
  }));

  useEffect(() => {
    checkableList.current?.setLimitReached(selectionLimitReached);
  }, [selectionLimitReached]);

  const handleSearchInputChange = (searchValue) => {
    // Clear the previous debounce timeout
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set a new debounce timeout
    debounceTimeoutRef.current = setTimeout(() => {
      setSearchTerm(searchValue);
      console.log("--- searchTerm: " + searchValue + ", Refetching entities ---")
      fetchQuery(searchValue);
    }, 300);
  };

  useEffect(() => {
    // Cleanup function to abort any ongoing requests when the component unmounts
    return () => {
      clearTimeout(debounceTimeoutRef.current);
    };
  }, []);

  return (
    <div ref={ref} id="searchable-checkable-list">
      <SearchBar onInputChange={handleSearchInputChange} placeholderText={"Search"} />
      <div className="searchable-checkable-list-body">
        {children}
        {isDataFiltering && <CheckableListSkeleton numberOfItems={11} />}
        {!isDataFiltering && (
          <div id="searchable-list-sections-container">
            {sectionsData.map((section, index) => {
              return (
                <div key={"searchable-list-section-" + index} className={`searchable-list-section ${section.containerClassName || ""}`}>
                  {section?.titleComponent}
                  {section?.data?.length > 0 && (
                    <CheckableList
                      ref={checkableList}
                      itemsList={section?.data}
                      extraInfo={extraInfo}
                      handleCheckboxChange={handleCheckboxChange}
                      removeEntityFromRecent={removeEntityFromRecent}
                      limitReached={isLimitReached}
                      setLimitReached={setLimitReached}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
});

QuerySearchableList.propTypes = {
  sectionsData: PropTypes.array.isRequired,
  extraInfo: PropTypes.string,
  children: PropTypes.node,
  handleCheckboxChange: PropTypes.func.isRequired,
  selectionLimitReached: PropTypes.bool,
  fetchQuery: PropTypes.func.isRequired,
  removeEntityFromRecent: PropTypes.func,
  setSearchTerm: PropTypes.func,
};

export { QuerySearchableList };
