import { Suspense, useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";


// import NewHeader from './NewHeader';
import AppliedFilters from "../../components/AppliedFilters";
import FilterByDropdown from "../../components/FilterByDropdown";
import NewBanner from "../../components/NewBanner";
import { DimensionsDropdowns, Loader } from "../../form/elements";
import Button from "../../newComponents/Button";
import Modal from "../../newComponents/Modal";
import SwitchToggle from "../../newComponents/SwitchToggle";
import FilterDialog from "../../sections/filter/FilterDialog";
import SessionTimeout from "../../SessionTimeout";
import { ReportHeader } from "../ReportHeader";

import "../styles/reportBody.css";

import {
  ALL_REPORTS,
  ALL_WIDGETS,
  API_URL,
  BOUNDARIES,
  BUTTON_DROPDOWN_VARIANT,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CURRENT_FILTER_COOKIE,
  DIALOG_SIZE,
  FILTER,
  FY_VALUES,
  HEADER_ELEMENT,
  LISTENER,
  MENU_ITEM,
  SAVE_COMPANY_FILTERS,
  SAVED_FILTER_ID_COOKIE,
  SCENARIO_STATUS,
  SIZES,
  UI_ACTIONS,
} from "../../class/constants";
import { lang } from "../../language/messages_en";

import {
    formatAdvancedFilter,
    formatBasicFilter,
    getLocalStorageValueByParameter,
    logout,
    setLocalStorageValueByParameter,
    updateWindowLoading,
} from "../../class/common";
import { generateQuarter, getActualYear, getPeriodDifference, getPeriodFromDate, getPeriodTextFromMonth } from "../../class/date";
import { alertAndLogError, saveCookie } from "../../class/jqueries";
import { checkIfMonthsValid, copyObjectValues, findOptionByKeyValue, getSectionExists, tryParse } from "../../class/utils";
import ErrorBoundary from "../../ErrorBoundary";
import GeographyConcentration from "../../GeographyConcentration";
import ButtonDropdown from "../../newComponents/ButtonDropdown";
import { getNewEntityFilter } from "../../sections/filter/FilterHelperFunctions";
import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import {
  updateDatasetState,
  updatePeriodsStatusState,
  updatePsViewsState,
  updateVectorState,
} from "../../actions/scenariosActions";
import { getClientPeriods, getCustomViewData, getDatasets, getFilterEntities, getPeriodsStatus, getVectors } from "../api/api";
import { useAuthenticated, useCalendarToggle, useExportScopeFilter } from "../CustomHooks";
import {
  messageDialogContent, renderBreadcrumbsAndDescription,
  renderButton,
  renderPeriodCalendar,
  renderTitle,
  runExtendedProfitStack,
  tablesToExcel
} from "../functions/componentFunctions";
import { addCorrespondingParenthesisToFilter } from "../functions/filterFunctions";
import { LoaderSkeleton } from "../LoaderSkeleton";
import shortid from "shortid";
import { QuickFilters } from "../../components/quickFilters/QuickFilters";
import { useListener } from "../../actions/CustomEventHandler";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from "posthog-js/react";
/**
 * @author Sarah Farjallah
 * @description component renders title based if state or zipcode view, vectorsdropwodwn, periods Calendar, vectors dropdown, filter and configure dropdown with  {@link GeographyConcentration} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link GeographyConcentration}
 */
const GeographyConcentrationWrapper = (props) => {
  /**Store */
  const psLinesOptions = useSelector((state) => state.psLinesOptions);
  const quadrantTierOptions = useSelector((state) => state.quadrantTierOptions);
  const profiles = useSelector(state=>state.profiles);
  const stateProfiles = useSelector(state=>state.stateProfiles);
  const customGroups = useSelector(state=>state.customGroups);
  const profileColumns = useSelector(state=>state.profileColumns);
  const columnProfiles = useSelector(state=>state.columnProfiles);
  const geographyListData = useSelector(state=>state.geographyListData);
  const geographyObjBeforeBack = useSelector(state=>state.geographyObjBeforeBack);
  const geographyCostHierarchy = useSelector(state=>state.geographyCostHierarchy);

  const userAllowedMenuLinks = props.userAllowedMenuLinks;
  const userSettings = props.userSettings;
  const scenarioState = props.scenarioState;
  const switchClient = props.switchClient;

  /**References & react utility */
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const dimensionDropdownsRef = useRef();
  const reportRef = useRef();
  const appliedFiltersRef = useRef();
  const filterDialRef = useRef();
  const filterByRef = useRef();
  const profitStackSelectedEntities = useRef();
  const dropDownRef = useRef();
  const exportRef = useRef();
  const confirmPassRef = useRef();
  const sessionTimeoutRef = useRef();
  const configDropdownRef = useRef();
  const countRef = useRef(0);

  /**State */
  const [periodsStatusState, setPeriodsStatusState] = useState({});
  const [vectorState, setVectorState] = useState({});
  const [datasetState, setDatasetState] = useState({});
  const [PSViewsState, setPSViewsState] = useState({});
  const [clientPeriodsState, setClientPeriodsState] = useState([]);

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
  const { toggleCalendar } = useCalendarToggle();
  const exportScopeFilter = useExportScopeFilter(userSettings, datasetState?.datasetOptions, vectorState?.vectorOptions, filterDialRef?.current?.state?.psLinesOptions);


  /**Filter Related States */
  const [calendarVisible, showCalendar] = useState(false);
  const [filterFinal, setFilterFinal] = useState(
    props.history.location.state
      ? props.history.location.state.outsideFilter
        ? JSON.stringify(tryParse(props.history.location.state.outsideFilter).filter) || []
        : []
      : []
  );
  const [filterFinalBasic, setFilterFinalBasic] = useState(
    props.history.location.state
      ? props.history.location.state.mainFilterFinalBasic
        ? tryParse(props.history.location.state.mainFilterFinalBasic) || []
        : []
      : []
  );
  const [basicFilterEntities, setBasicFilterEntities] = useState([]);
  const [exportQueryFilter, setExportQueryFilter] = useState("");
  const [displayFilter, setDisplayFilter] = useState();
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [isAdvancedFilterApplied, setIsAdvancedFilterApplied] = useState();
  const [tempFilter, setTempFilter] = useState();
  const [basicFilterEntitiesState, setBasicFilterEntitiesState] = useState([]);
  const [count, setCount] = useState(0);
  const [basicFilterItem, setBasicFilterItem] = useState();

  /**Header Related States */
  const [headerChangedCounter, setHeaderChangedCounter] = useState(0);
  const [headerElements, setHeaderElements] = useState([]);
  const [profitFormat, setProfitFormat] = useState(ALL_WIDGETS.GEOGRAPHY_CONCENTRATION);
  const [reportTitle, setReportTitle] = useState(ALL_REPORTS.GEOGRAPHY_CONCENTRATION);
  const [openLimitAccessDialog, setOpenLimitAccessDialog] = useState(false);
  const [limitAccessMsg, setLimitAccessMsg] = useState("");
  const [showGreyOverLay, setShowGreyOverLay] = useState(true);
  const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
  const [callGo, setCallGo] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState(lang.header.custom_text.download_start);
  const [openInfoDialog, setOpenInfoDilog] = useState();
  const [infoDialogMsg, setInfoDialogMsg] = useState();
  const [openFilterDialog, setOpenFilterDialog] = useState();
  const useNewComponents = useFeatureFlagEnabled('use_new_components');

  const [compareMode, setCompareMode] = useState(false);

  const manageColsAccess = {
    [ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS), [userAllowedMenuLinks]),
    [ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS), [userAllowedMenuLinks]),
    [ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS), [userAllowedMenuLinks]),
    [ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS), [userAllowedMenuLinks])
};

  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (switchClient) {
      sessionTimeoutRef?.current?.clearTimeOut();
    }
  }, [switchClient]);

  useListener(LISTENER.APPLY, () => {
    if (!showGreyOverLay) {
        apply();
        setCallGo(false);
    }
  });

  useEffect(() => {
    setCompareMode(false);
    if (scenarioState) {
      setPeriodsStatusState({});
      setVectorState({});
      setDatasetState({});
      setClientPeriodsState({});
      setShowGreyOverLay(true);
      getPeriodsStatus(scenarioState, dispatch, updatePeriodsStatusState, setPeriodsStatusState, props, profitFormat, userSettings, 12);
      getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
      getVectors(scenarioState, dispatch, updateVectorState, setVectorState, reportTitle, profitFormat, props, false, filterFinal, userSettings, vectorState, filterDialRef, ALL_REPORTS.GEOGRAPHY_CONCENTRATION );
      getDatasets(scenarioState, dispatch, updateDatasetState,setDatasetState, props, profitFormat, params,false, userSettings, datasetState);
      setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
    }
  }, [scenarioState]);

  useEffect(() => {
    if (
      Object.keys(periodsStatusState).length > 0 &&
      Object.keys(scenarioState).length > 0 &&
      Object.keys(clientPeriodsState).length > 0 &&
      Object.keys(vectorState).length > 0 &&
      Object.keys(PSViewsState).length > 0 &&
      userSettings.user
    ) {
      let headerElements = useNewComponents ? getNewHeaderElements() : getHeaderElements();
      setHeaderElements(headerElements);
    }
  }, [periodsStatusState, clientPeriodsState, calendarVisible, vectorState, PSViewsState, showGreyOverLay, compareMode, 
      count, basicFilterEntities, filterFinalBasic, headerChangedCounter, compareMode, reportRef.current, filterFinal]);

  useEffect(() => {
    if (vectorState) {
      getCustomViewData(
        PSViewsState,
        vectorState,
        params,
        filterFinal,
        userSettings,
        scenarioState,
        dispatch,
        updatePsViewsState,
        setPSViewsState,
        false,
        props
      );
    }
  }, [vectorState]);

  useEffect(() => {
    if (callGo && !showGreyOverLay) {
      apply();
      setCallGo(false);
    }
  }, [callGo]);

  /**HeaderElements Related Functions */
  const getHeaderElements = () => {
    let sectionsData = getFilterBySectionsData();
    let minYear = 2010;
    let maxYear = 2030;
    let isDropDownDisabled = false; 
    let headerElements = [];
    let startPeriod = typeof periodsStatusState.nextCustomStartDate === 'string' ? periodsStatusState.nextCustomStartDate : getPeriodFromDate(periodsStatusState.nextCustomStartDate);
    let endPeriod =typeof periodsStatusState.nextCustomEndDate === 'string' ? periodsStatusState.nextCustomEndDate : getPeriodFromDate(periodsStatusState.nextCustomEndDate);
    let endDateInput = typeof periodsStatusState.nextCustomEndDate === 'string' ? periodsStatusState.nextCustomEndDate : (getActualYear(periodsStatusState.nextCustomEndDate) + getPeriodTextFromMonth(periodsStatusState.nextCustomEndDate))
    let inputValue = typeof periodsStatusState.nextCustomStartDate === 'string' ? periodsStatusState.nextCustomStartDate : (getActualYear(periodsStatusState.nextCustomStartDate) + getPeriodTextFromMonth(periodsStatusState.nextCustomStartDate));
    let canSaveCompanyFilters = getSectionExists(userAllowedMenuLinks, SAVE_COMPANY_FILTERS);

    if(inputValue !== endDateInput) {
        inputValue += " "+ lang.COMMON.TO.toLowerCase() +" "+ endDateInput;
    }
    headerElements.push(
        <div style={{ display: "flex", width: "100%", alignItems: "center" }} className='first_header_row gap_between_buttons'>
            {renderTitle(reportTitle + (reportRef?.current?.state?.boundary !== BOUNDARIES.STATE && (props?.history?.location?.state?.selectedBoundaryName || reportRef?.current?.state?.selectedBoundaryName) ?  " - " + ( props?.history?.location?.state?.selectedBoundaryName || reportRef?.current?.state?.selectedBoundaryName) : ""))}
            {renderPeriodCalendar(periodsStatusState, handleElementChange, undefined, clientPeriodsState.allPeriods, toggleCalendar, isDropDownDisabled, scenarioState)}
            <div key={Math.random()} uk-tooltip={isDropDownDisabled ? lang.exit_drill_to_proceed : "title:"} className={"uk-flex uk-flex-column uk-flex-start"}>
                <div key={Math.random()} className={isDropDownDisabled ? " disabled" : ""}>
                    <DimensionsDropdowns
                        ref={dimensionDropdownsRef}
                        disabled={reportTitle === ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT /*|| this.props.state.isBridgeReport || this.isProfitStack*/}
                        numberOfDimensions={1}
                        dimensionOptions={vectorState.vectorOptions?.filter(e=>e.vector_group === 'Customer')}
                        vectorDisabled={vectorState.vectorDisabled}
                        excludeOptions={true} onChangeDimension={(e, index, fromMount) => handleElementChange(HEADER_ELEMENT.VECTOR, e, fromMount, index, true)}
                        shouldFetchData={false}
                        selectedDimensions={vectorState.nextVectors}
                        startAsEmpty={false}
                        dropdownTooltip={undefined}
                        hideLabel={true}
                    />
                </div>
            </div>
            <div id="apply_div">{renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), '', '', apply, !showGreyOverLay)}</div>
        </div>);


    headerElements.push(
      <div
        className="second_header_row gap_between_buttons"
        style={{ display: "flex", width: "100%", padding: "0.41667vw 0" }}
      >
        {userSettings?.useNewQuickFilters ? (
          <QuickFilters
              vectorOptions={vectorState.vectorOptions}
              profitFormat={profitFormat}
              userSettings={userSettings}
              scenarioState={scenarioState}
              filterFinal={filterFinal}
              setQuickFilters={setQuickFilters}
              switchBasicToAdvanced={switchBasicToAdvanced}
              clearAdvancedFilters={clearAdvancedFilters}
              filterDialRef={filterDialRef}
          />) 
          : (
          <FilterByDropdown
            ref={filterByRef}
            sectionsData={copyObjectValues(sectionsData.data)}
            clearClick={clearClick}
            isClearBtnDisabled={sectionsData.isClearBtnDisabled}
            handleCheckboxChange={handleCheckBoxChange}
            basicFilterCountLimit={userSettings.basicFilterCountLimit}
            switchBasicToAdvanced={switchBasicToAdvanced}
            filterFinal={filterFinal}
          />)
        }
        <FilterDialog
          id={"secondary_header_" + "geography"}
          ref={filterDialRef}
          section_id={params.sectionId}
          vectorOptions={vectorState.vectorOptions}
          profitFormat={"list"}
          mustHideFilter={false}
          categoryToolkit={false}
          onChangeFilter={onChangeFilter}
          quadTierIsAllowed={true}
          isTempScenario={false}
          scenario_id={scenarioState.scenario}
          mustFetchDatasets={false}
          user={userSettings.user}
          isDrilling={false}
          fromOptions={datasetState.datasetOptions}
          datasetOptions_id={datasetState.datasetOptions_id}
          dataSet={datasetState.dataset}
          useCookies={true}
          parentMessage={""}
          psFilterDisabled={false}
          quadrantsDisabled={false}
          quadrantDefault={false}
          checkIfMonthsValid={checkIfMonthsValid}
          comparisonScenarioId={undefined}
          filterDisplayEditable={true}
          psLinesOptions={psLinesOptions}
          quadrantTierOptions={quadrantTierOptions}
          dispatch={dispatch}
          savedFilterDataState={filterDialRef?.current?.state?.savedFilterData}
          isRedirectionFromStacks={false}
          savedFilter={true}
          fromSecondaryHeader={true}
          useNewQuickFilters={userSettings.useNewQuickFilters}
          canSaveCompanyFilters={canSaveCompanyFilters}
          // handleOverlayAndHeights={this.props.handleOverlayAndHeights}
        />
      </div>
    );
    let compareButtonText = reportRef?.current?.state.boundary !== BOUNDARIES.STATE ? lang.geography_concentration.compare_zipcodes_text : lang.geography_concentration.compare_state_text;

    if(!userSettings.useNewQuickFilters) {
      headerElements.push(
          <div className='third_row_header' style={{ display: "flex", width: "100%" }}>
              <div key={Math.random()} className={"noprint " + (displayFilter && ((filterFinalBasic?.length > 0 && filterFinalBasic !== "[]") || (filterFinal?.length > 0 && filterFinal !== "[]")) ? ("border-third-header ") : "") + "uk-display-block"}>
                  <AppliedFilters filterProps={filterFinalBasic} filterFinal={filterFinal} ref={appliedFiltersRef}
                      userAllowedVectors={userSettings.user?.user_allowed_vectors} basicFilterCountLimit={userSettings.basicFilterCountLimit}
                      handleCheckboxChange={handleBasicFilterCheckBoxChange} sectionsData={basicFilterEntities}
                      fetchData={filterOnBasicFilter} clearFilter={filterDialRef?.current?.clearFilter} vectors={vectorState.vectorOptions}
                      quadrantsDisabled={false}
                      psFilterDisabled={false}
                      parentMessage={""/*props.filterWarningMessage*/} saveFilter={filterDialRef?.current?.startSaveFilter}
                      clearBasicFilter={clearClick} clearFilterFromDialog={filterDialRef?.current?.clearFilter} forgetFilter={filterDialRef?.current?.handleOpenForgetFilterDialog}
                      className={isFilterDisabled ? "uk-disabled disabled" : ""} tooltip={"title:" + ("") + ";pos: top-left"} filterIsEditable={filterDialRef?.current?.state?.savedFilterData?.editable}
                  />
              </div>
          </div>
      );
    }

    headerElements.push(
    <div key={shortid.generate()} className="fourth_row_header" style={{ display: "flex", width: "100%" }}>
      <div className="landscape-subheader-container">
        {!reportRef?.current?.state?.isListFullScreen &&(
          <div key={shortid.generate()}>
          <Button
            id="landscape-compare-btn"
            variant={reportRef?.current?.state.fullCompareSelection ? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
            type={BUTTON_TYPE.DEFAULT}
            size={SIZES.DEFAULT}
            disabled={
              (reportRef?.current?.state.compareMode && !reportRef?.current?.state.fullCompareSelection) ||
              showGreyOverLay ||
              reportRef?.current?.state.notValidScenario
            }
            label={reportRef?.current?.state?.boundary === BOUNDARIES.STATE ? lang.geography_concentration.compare_button_state : lang.geography_concentration.compare_button_zipcode}
            leftIcon={<i className="heatmap-configure-btn fas fa-retweet"></i>}
            onBtnClick={() => {
              setCompareMode(true);reportRef?.current?.setCompareMode(false, reportRef?.current?.state.boundary === BOUNDARIES.STATE);
            }}
          />
          </div>
        ) }
        {compareMode ? (
          <div key={shortid.generate()} className="" style={{display: "flex",alignItems: "center"}}>
            <span className="select-segments-text compare-text-italic">
              {reportRef?.current?.state.fullCompareSelection ? "" : compareButtonText}{" "}
              <span
                className="stop-compare-text"
                onClick={() => {
                  setCompareMode(false);
                  reportRef?.current?.setCompareMode(true, reportRef?.current?.state.boundary === BOUNDARIES.STATE);
                }}
              >
                {lang.geography_concentration.stop_comparison}
              </span>
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      {!reportRef?.current?.state?.isListFullScreen &&
      <ButtonDropdown
          id={"geography-config"}
          ref={configDropdownRef}
          placeholder={lang.heatmap_configure.configure}
          className="heatmap-configure-btn"
          dropId={"configure-dropdown"}
          variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
          disabled={reportRef?.current?.state.notValidScenario}
          size={SIZES.SMALL}
          renderContainerBody={reportRef?.current?.renderConfigureComponent}
          firstAttr={"#button-drop-geography-config"}
          secondAttr={"#configure-geography-container"}
        />}
      </div>
    );

    return headerElements;
  };

  const renderHeaderFirstRow = () => {
    let title = reportTitle + (reportRef?.current?.state?.boundary !== BOUNDARIES.STATE && (props?.history?.location?.state?.selectedBoundaryName || reportRef?.current?.state?.selectedBoundaryName) ?  " - " + ( props?.history?.location?.state?.selectedBoundaryName || reportRef?.current?.state?.selectedBoundaryName) : "");
    return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), title, props.reportDescription)
  }

  const renderHeaderSecondRow = (isDropDownDisabled) => {
    return (
        <>
          {renderPeriodCalendar(periodsStatusState, handleElementChange, undefined, clientPeriodsState.allPeriods, toggleCalendar, isDropDownDisabled, scenarioState)}
          <div key={Math.random()} uk-tooltip={isDropDownDisabled ? lang.exit_drill_to_proceed : "title:"}
               className={"uk-flex uk-flex-column uk-flex-start"}>
            <div key={Math.random()} className={isDropDownDisabled ? " disabled" : ""}>
              <DimensionsDropdowns
                  ref={dimensionDropdownsRef}
                  disabled={reportTitle === ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT /*|| this.props.state.isBridgeReport || this.isProfitStack*/}
                  numberOfDimensions={1}
                  dimensionOptions={vectorState.vectorOptions?.filter(e => e.vector_group === 'Customer')}
                  vectorDisabled={vectorState.vectorDisabled}
                  excludeOptions={true}
                  onChangeDimension={(e, index, fromMount) => handleElementChange(HEADER_ELEMENT.VECTOR, e, fromMount, index, true)}
                  shouldFetchData={false}
                  selectedDimensions={vectorState.nextVectors}
                  startAsEmpty={false}
                  dropdownTooltip={undefined}
                  hideLabel={true}
              />
            </div>
          </div>
          <div
              id="apply_div">{renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), '', '', apply, !showGreyOverLay)}</div>
        </>
    );

  }

  const renderHeaderThirdRow = (sectionsData, canSaveCompanyFilters, compareButtonText) => {
    return (
        <>
          <div className="gap_between_buttons" style={{display:"flex"}}>
          {userSettings?.useNewQuickFilters ? (
                  <QuickFilters
                      vectorOptions={vectorState.vectorOptions}
                      profitFormat={profitFormat}
                      userSettings={userSettings}
                      scenarioState={scenarioState}
                      filterFinal={filterFinal}
                      setQuickFilters={setQuickFilters}
                      switchBasicToAdvanced={switchBasicToAdvanced}
                      clearAdvancedFilters={clearAdvancedFilters}
                      filterDialRef={filterDialRef}
                  />)
              : (
                  <FilterByDropdown
                      ref={filterByRef}
                      sectionsData={copyObjectValues(sectionsData.data)}
                      clearClick={clearClick}
                      isClearBtnDisabled={sectionsData.isClearBtnDisabled}
                      handleCheckboxChange={handleCheckBoxChange}
                      basicFilterCountLimit={userSettings.basicFilterCountLimit}
                      switchBasicToAdvanced={switchBasicToAdvanced}
                      filterFinal={filterFinal}
                  />)
          }
          <FilterDialog
              id={"secondary_header_" + "geography"}
              ref={filterDialRef}
              section_id={params.sectionId}
              vectorOptions={vectorState.vectorOptions}
              profitFormat={"list"}
              mustHideFilter={false}
              categoryToolkit={false}
              onChangeFilter={onChangeFilter}
              quadTierIsAllowed={true}
              isTempScenario={false}
              scenario_id={scenarioState.scenario}
              mustFetchDatasets={false}
              user={userSettings.user}
              isDrilling={false}
              fromOptions={datasetState.datasetOptions}
              datasetOptions_id={datasetState.datasetOptions_id}
              dataSet={datasetState.dataset}
              useCookies={true}
              parentMessage={""}
              psFilterDisabled={false}
              quadrantsDisabled={false}
              quadrantDefault={false}
              checkIfMonthsValid={checkIfMonthsValid}
              comparisonScenarioId={undefined}
              filterDisplayEditable={true}
              psLinesOptions={psLinesOptions}
              quadrantTierOptions={quadrantTierOptions}
              dispatch={dispatch}
              savedFilterDataState={filterDialRef?.current?.state?.savedFilterData}
              isRedirectionFromStacks={false}
              savedFilter={true}
              fromSecondaryHeader={true}
              useNewQuickFilters={userSettings.useNewQuickFilters}
              canSaveCompanyFilters={canSaveCompanyFilters}
              // handleOverlayAndHeights={this.props.handleOverlayAndHeights}
          />
          <div className="landscape-subheader-container">
            {!reportRef?.current?.state?.isListFullScreen && (
                <div key={shortid.generate()}>
                  <Button
                      id="landscape-compare-btn"
                      variant={reportRef?.current?.state.fullCompareSelection ? BUTTON_VARIANT.PRIMARY : BUTTON_VARIANT.SECONDARY}
                      type={BUTTON_TYPE.DEFAULT}
                      size={SIZES.DEFAULT}
                      disabled={
                          (reportRef?.current?.state.compareMode && !reportRef?.current?.state.fullCompareSelection) ||
                          showGreyOverLay ||
                          reportRef?.current?.state.notValidScenario
                      }
                      label={reportRef?.current?.state?.boundary === BOUNDARIES.STATE ? lang.geography_concentration.compare_button_state : lang.geography_concentration.compare_button_zipcode}
                      leftIcon={<i className="heatmap-configure-btn fas fa-retweet"></i>}
                      onBtnClick={() => {
                        setCompareMode(true);
                        reportRef?.current?.setCompareMode(false, reportRef?.current?.state.boundary === BOUNDARIES.STATE);
                      }}
                  />
                </div>
            )}
            {compareMode ? (
                <div key={shortid.generate()} className="" style={{display: "flex", alignItems: "center"}}>
            <span className="select-segments-text compare-text-italic">
              {reportRef?.current?.state.fullCompareSelection ? "" : compareButtonText}{" "}
              <span
                  className="stop-compare-text"
                  onClick={() => {
                    setCompareMode(false);
                    reportRef?.current?.setCompareMode(true, reportRef?.current?.state.boundary === BOUNDARIES.STATE);
                  }}
              >
                {lang.geography_concentration.stop_comparison}
              </span>
            </span>
                </div>
            ) : (
                ""
            )}
          </div>
          </div>
          {!reportRef?.current?.state?.isListFullScreen &&
              <ButtonDropdown
                  id={"geography-config"}
                  ref={configDropdownRef}
                  placeholder={lang.heatmap_configure.configure}
                  className="heatmap-configure-btn"
                  dropId={"configure-dropdown"}
                  variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
                  disabled={reportRef?.current?.state.notValidScenario}
                  size={SIZES.SMALL}
                  renderContainerBody={reportRef?.current?.renderConfigureComponent}
                  firstAttr={"#button-drop-geography-config"}
                  secondAttr={"#configure-geography-container"}
                  toRight={true}
                  mode={"dark"}
              />}
        </>
    );
  }

  const getNewHeaderElements = () => {
    let sectionsData = getFilterBySectionsData();
    let isDropDownDisabled = false;
    let headerElements = [];
    let endDateInput = typeof periodsStatusState.nextCustomEndDate === 'string' ? periodsStatusState.nextCustomEndDate : (getActualYear(periodsStatusState.nextCustomEndDate) + getPeriodTextFromMonth(periodsStatusState.nextCustomEndDate))
    let inputValue = typeof periodsStatusState.nextCustomStartDate === 'string' ? periodsStatusState.nextCustomStartDate : (getActualYear(periodsStatusState.nextCustomStartDate) + getPeriodTextFromMonth(periodsStatusState.nextCustomStartDate));
    let canSaveCompanyFilters = getSectionExists(userAllowedMenuLinks, SAVE_COMPANY_FILTERS);

    if (inputValue !== endDateInput) {
      inputValue += " " + lang.COMMON.TO.toLowerCase() + " " + endDateInput;
    }
    headerElements.push(
        <div style={{display: "flex", width: "100%", alignItems: "center"}}
             className='new_header_menu first_header_row gap_between_buttons'>
          {renderHeaderFirstRow()}
        </div>
    );

    headerElements.push(
        <div
            className="new_header_menu second_header_row gap_between_buttons"
            style={{display: "flex", width: "100%", padding: "0.41667vw 0"}}
        >
          {renderHeaderSecondRow(isDropDownDisabled)}
        </div>
    );
    let compareButtonText = reportRef?.current?.state.boundary !== BOUNDARIES.STATE ? lang.geography_concentration.compare_zipcodes_text : lang.geography_concentration.compare_state_text;

    headerElements.push(
        <div className="new_header_menu third_row_header" style={{display: "flex", width: "100%"}}>
          {renderHeaderThirdRow(sectionsData, canSaveCompanyFilters, compareButtonText)}
        </div>
    );

    return headerElements;
  };

  const handleElementChange = (name, e, fromMount = false, dimIndex = 0) => {
    switch (name) {
      case HEADER_ELEMENT.SELECTION_RANGE: {
        let selectedYears = e.filter((year) => year.isSelected);
        var tempState = copyObjectValues(periodsStatusState);
        if (selectedYears.length > 0) {
          let periods = getLocalStorageValueByParameter("periods") ? JSON.parse(getLocalStorageValueByParameter("periods")) : "";
          let sortedSelectedYears = selectedYears.map((p) => p.year + p.value).sort();
          let startPeriod = sortedSelectedYears[0];
          let endPeriod = sortedSelectedYears[sortedSelectedYears.length - 1];
          let startDate = new Date(periods.filter((e) => e.value === startPeriod)[0].start_date);
          let endDate = new Date(periods.filter((e) => e.value === endPeriod)[0].end_date);
          tempState.nextCustomStartDate = startDate;
          tempState.nextCustomEndDate = endDate;
          tempState.customStartDate = startDate;
          tempState.customEndDate = endDate;
      
          saveCookie("nextCustomStartDate", startDate);
          saveCookie("nextCustomEndDate", endDate);
        
          setPeriodsStatusState(tempState);
          setShowGreyOverLay(true);
        }
        break;
      }
      case HEADER_ELEMENT.VECTOR: {
        var vectorValue = typeof e === "object" ? e.value : e;
        var tempState = copyObjectValues(vectorState);
        saveCookie(HEADER_ELEMENT.VECTOR, vectorValue);
        saveCookie("nextCustomStartDate", periodsStatusState.nextCustomStartDate);
        saveCookie("nextCustomEndDate", periodsStatusState.nextCustomEndDate);

        tempState.nextVectors = copyObjectValues(vectorState.nextVectors);
        tempState.nextVectors[dimIndex] = vectorValue;
        tempState.vectors = copyObjectValues(vectorState.nextVectors);
        tempState.vectors[dimIndex] = vectorValue;
        tempState.vectorObjects = tempState.vectors.map(v=>{
          return findOptionByKeyValue(tempState.vectorOptions, "value", v);
        });
        setVectorState(tempState);
        setShowGreyOverLay(true);
        break;
      }
      default:
        break;
    }
    setCompareMode(false);
    reportRef?.current?.setCompareMode(true, reportRef?.current?.state.boundary === BOUNDARIES.STATE, true);
    let headerChangedCounterState = headerChangedCounter;
    setHeaderChangedCounter(headerChangedCounterState + 1);
  };

  const setInfoDialogOpen = (isOpen, infoMsg) => {
    setOpenInfoDilog(isOpen);
    setInfoDialogMsg(infoMsg);
  };

  const runApplyValidations = () => {
    if (
      getPeriodDifference(
        getPeriodFromDate(periodsStatusState.customStartDate),
        getPeriodFromDate(periodsStatusState.customEndDate)
      ) > 12
    ) {
      setInfoDialogOpen(true, lang.not_allowed_periods_selection);
      return false;
    }
    return true;
  };

  const apply = () => {
    if (!runApplyValidations()) {
      return;
    }
    reportRef?.current.go(undefined, true);
    setShowGreyOverLay(false);
  };

  /**Filter Related Functions */
  const getExportQueryFilter = (additionalFilter) => {
    if (additionalFilter?.filter && typeof additionalFilter?.filter != "function") {
      additionalFilter = additionalFilter.filter;
    }
    return additionalFilter
      ? additionalFilter.find((f) => !f.isBasicFilter)
        ? formatAdvancedFilter(
            additionalFilter,
            userSettings.user?.user_allowed_vectors,
            datasetState.datasetOptions,
            vectorState.vectorOptions,
            filterDialRef?.current?.state?.psLinesOptions
          )
        : formatBasicFilter(additionalFilter, userSettings?.user?.user_allowed_vectors)
      : exportQueryFilter;
  };

  const switchBasicToAdvanced = (filters) => {
    if (filterByRef?.current) {
      filterByRef.current.handleClose();
    }

    if(!filterFinal) {
      return;
    }
    
    let filterStr = filterFinal;
    let filter =
      typeof filterStr === "string"
        ? JSON.parse(filterStr)
        : filterStr.filter && typeof filterStr.filter !== "function"
        ? filterStr.filter
        : filterStr;

    if(filters && userSettings?.useNewQuickFilters) {
      filter = filters
    } 

    filter = addCorrespondingParenthesisToFilter(filter);
    if (filter.find((e) => e.isBasicFilter)) {
      filterDialRef?.current?.onChangeSavedFilter(filter, false, true);
    }
  };

  const setQuickFilters = (filters) => {
    if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) { 
      localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
      localStorage.removeItem(CURRENT_FILTER_COOKIE);
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
    }
    
    setFilterFinal(JSON.stringify(filters));
    setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
    if (!showGreyOverLay) {
        setCallGo(true);
    }
  }

  const clearAdvancedFilters = (isClearAll) => {
    if(isClearAll) {
        filterDialRef?.current?.clearFilter();
        setFilterFinal([]);
    }
    if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        let exportQueryFilter = formatBasicFilter([], userSettings.user?.user_allowed_vectors);
        setExportQueryFilter(exportQueryFilter);
    }
  }

  const onChangeFilter = (filterStr, afterDrilling, applyFilter = false, isClear, isSwitchBasic, filterDisplayName) => {
    let filter = typeof filterStr === "string" ? JSON.parse(filterStr) : filterStr;
    // on changing advanced saved filter its name should be removed if cookie has no saved filter since we remove the saved filter from cookie when we do a change on it
    if (
      !filterDisplayName &&
      (getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === null ||
        !getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) ||
        getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === "undefined")
    ) {
      filter.map((item) => {
        item.filter_display_name = undefined;
      });
    }

    if (isSwitchBasic) {
      filter.map(function (item) {
        item.isBasicFilter = false;
      });
    }
    let tempState = {};

    tempState.filterFinal = typeof filter !== "string" ? JSON.stringify(filter) : filter;

    if (isClear) {
      filter = "[]";
      tempState.filterFinalBasic = [];
    } else if (!filter.find((f) => !f.isBasicFilter)) {
      tempState.filterFinalBasic = copyObjectValues(filter).filter((e) => e.field === "number"); //from selecting a saved basic filter
      let filterObjs = copyObjectValues(filter);
      const unique = [...new Set(filterObjs.map((item) => item.vector))]; // [ 'A', 'B']
      for (var e in unique) {
        getFilterEntities(
          { value: unique[e] },
          vectorState,
          setBasicFilterEntities,
          basicFilterEntitiesState,
          profitFormat,
          userSettings,
          scenarioState,
          tempState.filterFinal,
          getCountRef,
          setCount,
          setCountRef
        );
      }
    } else if (applyFilter) {
      // from apply in advanced
      tempState.filterFinalBasic = [];
    }
    tempState.tempFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;

    if (!applyFilter) {
      let tempState2 = { tempFilter: filterStr }; //this state represents the filter displayed in the header but not necessarily applied on the data (pending apply())
      if (!isSwitchBasic) {
        tempState2.filterFinalBasic = tempState.filterFinalBasic;
        setFilterFinalBasic(tempState2.filterFinalBasic);
      }
      setTempFilter(filterStr);
      return;
    }

    //check if filter contains quadrant or quadrant/tier filter
    tempState.isAdvancedFilterApplied = isClear ? false : true;
    tempState.exportQueryFilter =
      filterStr === "[]"
        ? undefined
        : formatAdvancedFilter(
            JSON.parse(filterStr),
            userSettings.user?.user_allowed_vectors,
            datasetState.datasetOptions,
            vectorState.vectorOptions,
            filterDialRef?.current?.state?.psLinesOptions
          );

    setFilterFinal(tempState.filterFinal);
    setFilterFinalBasic(tempState.filterFinalBasic);
    setExportQueryFilter(tempState.exportQueryFilter);
    // setIsAdvancedFilterApplied(tempState.isAdvancedFilterApplied);
    setTempFilter(tempState.tempFilter);

    if (isClear) {
      filterStr = "[]";
    }
    if(history && history.location && history.location.state ){
      history.push({
        state: {
          outsideFilter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          mainFilter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          filter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          filterFinal: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          originalMainFilter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          scenarioState: history?.location?.state?.scenarioState,
          isScopeFilterChanged: history?.location?.state?.isScopeFilterChanged,
        }
      })
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filter));
    }
    if (!showGreyOverLay) {
      // apply();
      setCallGo(true);
    }
  };

  const setCountRef = (count) => {
    countRef.current = count;
  };

  const getCountRef = () => {
    return countRef.current;
  };

  const renderHeader = () => {
    let countState = countRef.current;
    setCountRef(countState + 1);
    setCount(countState + 1);
  }

  const getFilterBySectionsData = () => {
    let isClearBtnDisabled = false;
    let filterFinalBasicState = filterFinalBasic || [];
    let vectors = vectorState.vectorOptions?.filter((f) => f.entityCount <= userSettings.filterVectorCountThreshold || f.isGroupTitle); // remove vectors with entityCount <= threshold and that are not group title;
    if (vectors && filterFinalBasicState) {
      filterFinalBasicState = typeof filterFinalBasicState === "object" ? filterFinalBasicState : JSON.parse(filterFinalBasicState);
      filterFinalBasicState = Array.isArray(filterFinalBasicState) ? filterFinalBasicState : filterFinalBasicState.filter;
      isClearBtnDisabled = filterFinalBasicState.length === 0;
      vectors = vectors.map((item) => ({
        ...item,
        checked: !item.isGroupTitle && filterFinalBasicState.some((e) => e.vector === item.value), // .some() checks if any of the element.vector === item.value
      }));
    }

    // Add suggested section if we have nextVectors and nextVectors' entity count < threshold
    let sectionsJson = [];
    let quarters = [];
    var startQuarter = datasetState.nextStartQuarter?.split("Q")[1];
    var endQuarter = datasetState.nextEndQuarter?.split("Q")[1];
    var startYear = datasetState.nextStartQuarter?.split("Q")[0];
    var endYear = datasetState.nextEndQuarter?.split("Q")[0];
    quarters = generateQuarter(Number(startQuarter), startYear, Number(endQuarter), endYear, true);
    vectors = vectors; // add isDisabled to vectors that are not generated

    let suggestedVectors = vectors?.filter((f) => vectorState.nextVectors?.includes(f.value) && !f.isGroupTitle && !f.isDisabled); // get nextVectors as jsonObject
    suggestedVectors = suggestedVectors?.filter((f) => f.entityCount <= userSettings.filterVectorCountThreshold); // checks if header vectors' entity count <= thereshold
    let hasSuggested = suggestedVectors?.length > 0;
    if (hasSuggested) {
      sectionsJson.push({
        sectionTitle: lang.suggested,
        itemsList: suggestedVectors,
      });
    }

    sectionsJson.push({
      sectionTitle: lang.all,
      itemsList: vectors,
    });
    return { data: sectionsJson, isClearBtnDisabled: isClearBtnDisabled };
  };

  const handleCheckBoxChange = (item) => {
    let filterFinalArr = filterFinal !== "[]" && typeof filterFinal === "string" ? JSON.parse(filterFinal) : filterFinal; // when filterFinal = ""[]"", remove the extra ""
    let isAdvancedFilterApplied =
      filterFinalArr &&
      filterFinalArr !== "[]" &&
      (typeof filterFinalArr === "object" ? filterFinalArr.find((e) => !e.isBasicFilter) : JSON.parse(filterFinalArr).find((e) => !e.isBasicFilter));
    if (isAdvancedFilterApplied) {
      setBasicFilterItem(item);
      openFilterModal();
    } else {
      handleBasicFilterCheckBoxChange(item);
    }
  };

  const filterOnBasicFilter = (basicAppliedFilter) => {
    let filters = copyObjectValues(basicAppliedFilter.filter((e) => e.entities.length));
    filters?.forEach((e) => {
      e.isBasicFilter = true;
      e.filter_display_name = undefined;
      e.entities = e.entities.map((ent) => {
        ent.value = ent.number;
        ent.label = ent.number;
        return ent;
      });
    });
    let filtersByName = copyObjectValues(filters);
    filtersByName?.forEach((e) => {
      e.field = "name";
      e.entities = e.entities.map((ent) => {
        ent.value = ent.name;
        ent.label = ent.name;
        return ent;
      });
      filters.push(e);
    });

    if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
      localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
      localStorage.removeItem(CURRENT_FILTER_COOKIE);
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
    }
    let filterFinalBasic = copyObjectValues(basicAppliedFilter); //typeof _this.state.filterFinalBasic === "string" ? JSON.parse(_this.state.filterFinalBasic) : _this.state.filterFinalBasic;
    if (filterDialRef?.current) {
      filterDialRef?.current?.setState({
        savedFilterData: {},
      });
    }

    if (filterDialRef?.current?.savedFilterNameInput) {
      filterDialRef.current.savedFilterNameInput.value = "";
    }
    filterFinalBasic?.forEach((e) => {
      e.filter_display_name = undefined;
    });

    filters = addCorrespondingParenthesisToFilter(filters);
    if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
      localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
      localStorage.removeItem(CURRENT_FILTER_COOKIE);
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
    }
    setFilterFinal(JSON.stringify(filters));
    setFilterFinalBasic(filterFinalBasic);
    setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
    if (!showGreyOverLay) {
      // apply();
      setCallGo(true);
    }
  };

  const handleBasicFilterCheckBoxChange = (item, clear, entity, allEntities) => {
    let tempState = {};
    let filter = typeof filterFinalBasic === "string" ? JSON.parse(filterFinalBasic) : filterFinalBasic;
    let initialFilter = copyObjectValues(filter);

    filter = filter || [];
    let removedLine = [];
    let isClear = false;
    let isUnCheck = false;
    if (entity && item) {
      for (let e in filter) {
        if (filter[e].vector === item.vector) {
          if (entity.checked === true) {
            filter[e].entities.push(entity);
          } else {
            filter[e].entities = filter[e].entities.filter((f) => f.value !== entity.value);
          }
        }
      }
    } else if (item && allEntities) {
      for (let e in filter) {
        if (filter[e].vector === item.vector) {
          for (let j in allEntities) {
            filter[e].entities.push(allEntities[j]);
          }
        }
      }
    } else if (item) {
      if (filter?.find((e) => e.vector === item.value)) {
        removedLine = filter.find((e) => e.vector === item.value);
        filter = filter.filter((e) => e.vector !== item.value);
        delete basicFilterEntities[item.value];
        isUnCheck = true;
      } else {
        let selectedEmptyFilter = getNewEntityFilter(
          item.value,
          FILTER.VALUES.FIELD.NUMBER,
          [],
          FILTER.VALUES.FILTER_ROW_TYPE.FILTER,
          lang.ui_filter.dropdowns.and
        );
        selectedEmptyFilter.vectorLabel = item.label;
        filter?.push(selectedEmptyFilter);
      }
    } else if (clear) {
      filter = [];
      tempState.filterFinal = [];
      isClear = true;
      if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        tempState.exportQueryFilter = formatBasicFilter(filter, userSettings.user?.user_allowed_vectors);
        setExportQueryFilter(tempState.exportQueryFilter);
      }
    }
    filter = addCorrespondingParenthesisToFilter(filter);
    if (isUnCheck && removedLine.entities.length > 0) {
      tempState.filterFinalBasic = filter;
      let copyFilter = copyObjectValues(filter);
      copyFilter.forEach((e) => {
        if (e.entities) {
          e.isBasicFilter = true;
          e.entities = e.entities.map((ent) => {
            ent.value = ent.number;
            ent.label = ent.number;
            return ent;
          });
        }
      });
      let filtersByName = copyObjectValues(copyFilter);
      filtersByName.forEach((e) => {
        e.field = "name";
        e.entities = e.entities.map((ent) => {
          ent.value = ent.name;
          ent.label = ent.name;
          return ent;
        });
        copyFilter.push(e);
      });
      tempState.filterFinal = JSON.stringify(copyFilter);
      tempState.exportQueryFilter = formatBasicFilter(copyFilter, userSettings.user?.user_allowed_vectors);
    }
    filter.map((item) => {
      item.filter_display_name = undefined;
    });
    tempState.filterFinalBasic = isUnCheck ? JSON.stringify(filter) : filter;
    setFilterFinal(tempState.filterFinal);
    setFilterFinalBasic(tempState.filterFinalBasic);

    if(item && Object.keys(basicFilterEntities).length && basicFilterEntities[item.value]) { 
      basicFilterEntities[item.value].map(entity => entity.checked = false); 
    } 
    
    let countState = countRef.current;
    setCount(countState + 1);
    countRef.current = countState + 1;
    if (filterDialRef?.current) {
      filterDialRef?.current?.setState({
        savedFilterData: {},
      });
    }

    if (filterDialRef?.current?.savedFilterNameInput) {
      filterDialRef.current.savedFilterNameInput.value = "";
    }

    if (isUnCheck && removedLine.entities.length > 0 && !showGreyOverLay) {
      if (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(tempState.filterFinal));
      }
      let countstate = countRef.current;
      setCount(countstate + 1);
      countRef.current = countState + 1;
      // apply();
      setCallGo(true);
    } else if (!isClear) {
      getFilterEntities(item, vectorState, setBasicFilterEntities, basicFilterEntitiesState, profitFormat, userSettings, scenarioState, tempState.filterFinal, getCountRef, setCount, setCountRef);
    }
  };

  const clearClick = () => {
    handleBasicFilterCheckBoxChange(undefined, true);
    if (!showGreyOverLay) {
      // apply();
      setCallGo(true);
    }
  };

  const openFilterModal = () => {
    setOpenFilterDialog(true);
  };

  const closeFilterModal = () => {
    setOpenFilterDialog(false);
  };

  const filterModalBody = () => {
    return (
      <h4>
        {lang.clear_advanced_filter}
        <br />
        {lang.switching_to_quick_filter}
      </h4>
    );
  };

  const handleBasicFilterAndClose = () => {
    closeFilterModal();
    clearAdvancedFilter(basicFilterItem);
  };

  const clearAdvancedFilter = (basicFilterItem) => {
    filterDialRef?.current?.clearFilter();
    setFilterFinal([]);
    handleBasicFilterCheckBoxChange(basicFilterItem);
  };

  const saveDialogActions = () => {
    return (
      <>
        <Button
          label={lang.modal.buttons.clear_filter}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={handleBasicFilterAndClose}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={closeFilterModal}
        />
      </>
    );
  };



  /**Limit Access Related Functions */
  const checkForLimitAccessMessage = (data, showModal) => {
    let noAccessMessage = data.no_access_message;
    if (!!noAccessMessage && showModal && !props.checkedPsLines) {
      setLimitAccessDialogOpen(true, noAccessMessage);
    }
    return !!noAccessMessage;
  };

  const setLimitAccessDialogOpen = (isOpen, msg) => {
    setLimitAccessMsg(msg);
    setOpenLimitAccessDialog(isOpen);
  };

  const runExtendedProfitStackFunc = (dropdownName) => {
    runExtendedProfitStack(
      dropdownName,
      vectorState,
      datasetState,
      profitFormat,
      profitStackSelectedEntities,
      reportRef,
      PSViewsState,
      periodsStatusState,
      scenarioState,
      filterDialRef,
      filterFinal,
      filterFinalBasic,
      undefined,
      userAllowedMenuLinks,
      history
    );
  };

  const tablesToExcelWrapper = () => {
    let zipCodeVector = userSettings.zipCodeVector;
    let stateVector =userSettings.stateVector;
    let stateVectorObj = vectorState.vectorOptions?.filter(e=>e.value.toLowerCase()+"name" === stateVector?.toLowerCase() || e.value.toLowerCase()+"number" === stateVector?.toLowerCase() || e.value.toLowerCase()+"number" === stateVector?.toLowerCase());
    let zipCodeVectorObj = vectorState.vectorOptions?.filter(e=>e.value.toLowerCase()+"name" === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+"number" === zipCodeVector?.toLowerCase() || e.value.toLowerCase()+"number" === zipCodeVector?.toLowerCase());
    let dataTier= reportRef?.current?.state.zipCodeSelected ? vectorState.vectorObjects[0].label : reportRef?.current.state.boundary === BOUNDARIES.STATE ? stateVectorObj && stateVectorObj.length > 0 ? stateVectorObj[0]?.label : "" : zipCodeVectorObj && zipCodeVectorObj.length > 0 ? zipCodeVectorObj[0]?.label : "";
    tablesToExcel(reportTitle, userSettings, vectorState, datasetState, scenarioState, reportRef, reportRef?.current?.getExportFilter, dataTier);
}

  let trackingData = window._pi_getTrackingDataObj();
  trackingData = trackingData === null ? "" : JSON.stringify(trackingData);
  let scenarioLabel = scenarioState?.scenarioObjects?.length > 0 ? scenarioState?.scenarioObjects[0].label : "";

  let showSkeleton = !(
    Object.keys(periodsStatusState).length > 0 &&
    Object.keys(vectorState).length > 0 &&
    Object.keys(scenarioState).length > 0 &&
    Object.keys(datasetState).length > 0 &&
    headerElements.length > 0
  );


  useEffect(() => {
    updateWindowLoading(showSkeleton, "skeleton-loader");
  }, [showSkeleton])

  return (
    <>
      {showSkeleton && <LoaderSkeleton />}
      <ErrorBoundary>
        <div id="overlay-selectedBubbleList" className="overlay-manage-columns uk-hidden" /> 
        <div id="overlay-defaultBubbleList" className="overlay-manage-columns uk-hidden" />
        <Suspense fallback={<p id="loading">Loading...</p>}>
          <SessionTimeout
            ref={sessionTimeoutRef}
            isAuthenticated={userSettings.user && userSettings.machine_name !== ""}
            logout={logout}
            session_timeout_ui={userSettings.session_timeout_ui}
            session_timeout_api={userSettings.session_timeout_api}
          />
          <Modal
            id={"check-limit-access-dialog"}
            openDialog={openLimitAccessDialog}
            closeClick={() => setLimitAccessDialogOpen(false, "")}
            bodyContent={() => messageDialogContent(limitAccessMsg)}
            dialogActions={() => (
              <Button
                label={lang.modal.buttons.ok}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                aria-label="Close"
                onBtnClick={() => setLimitAccessDialogOpen(false, "")}
              />
            )}
            size={DIALOG_SIZE.MEDIUM}
          />
          <Modal
            id={"header-info-dialog"}
            openDialog={openInfoDialog}
            bodyContent={() => <span className="uk-text-large">{infoDialogMsg}</span>}
            dialogActions={() => (
              <Button
                label={lang.modal.buttons.ok}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => setInfoDialogOpen(false, "")}
              />
            )}
            closeClick={() => setInfoDialogOpen(false, "")}
            size={DIALOG_SIZE.MEDIUM}
          />
          <Modal
            id={"switch-basic-to-advanced-filter"}
            openDialog={openFilterDialog}
            bodyContent={filterModalBody}
            dialogActions={saveDialogActions}
            size={DIALOG_SIZE.MEDIUM}
            closeClick={closeFilterModal}
          />
          <div id="toastExport" className={!!error ? "toast toast-fail" : "toast toast-success"}>
            <div id="desc">
              <i
                className={
                  "fa-lg fas uk-margin-small-right " + (!!error ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")
                }
                aria-hidden="true"
              ></i>
              {message}
              <span></span>
            </div>
          </div>
          <iframe name="frame" id="frame_export" style={{ display: "none" }}></iframe>
          <div
            id={"main-component-container"}
            className={"main-component-container " + (showSkeleton ? "hidden" : "")}
            style={{ "--banner-height": isScenarioBannerVisible ? "6%" : 0 }}
          >
            <Loader newLoader />
            <div className={isScenarioBannerVisible ? "header-banner-div" : "header-banner-div-hidden"}>
              {isScenarioBannerVisible && <NewBanner
                bannerClassName={"header-banner scenario-banner"}
                labelClassName={"header-banner-label scenario-banner-label"}
                icon={<RoundInfoIcon className="info-banner-icon" />}
                label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
                body={
                  <Button
                    id={"banner-dismiss-btn"}
                    label={lang.modal.buttons.dismiss}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => setScenarioBannerVisible(false)}
                  />
                }
              />}
            </div>

            <div className="main-report-header">
              <ReportHeader headerElements={headerElements} />
            </div>
            <div className="main_report">
              {headerElements.length > 0 && showGreyOverLay && <div className="overlay-div">{Object.keys(window._loading)?.length > 0 ? "" : lang.overlay_go_msg}</div>}
              <div id="main_report_container" className="main_report_container">
              {!showSkeleton && 
              <GeographyConcentration
                ref={reportRef}
                userSettingsState={userSettings}
                periodsStatusState={periodsStatusState}
                vectorsStatusState={vectorState}
                datasetStatusState={datasetState}
                clientPeriodsStatusState={clientPeriodsState}
                userAllowedMenuLinks={userAllowedMenuLinks}
                filterFinal={filterFinal}
                scenarioState={scenarioState}
                checkForLimitAccessMessage={checkForLimitAccessMessage}
                clientPeriodsState={clientPeriodsState}
                exportQueryFilter={getExportQueryFilter()}
                profitFormat={profitFormat}
                history={props.history}
                location={props.history.location}
                match={{params:params}}
                filterDialRef={filterDialRef?.current}
                getExportQueryFilter={getExportQueryFilter}
                dispatch={dispatch}
                manageColsAccess={manageColsAccess}
                configDropdownRef={configDropdownRef}
                renderHeader={renderHeader}
                showGreyOverlay={showGreyOverLay}
                tablesToExcelWrapper={tablesToExcelWrapper}
                profiles={profiles}
                stateProfiles={stateProfiles}
                customGroups={customGroups}
                profileColumns={profileColumns}
                columnProfiles={columnProfiles}
                PSViewsState={PSViewsState}
                apply={apply}
                setShowGreyOverLay={setShowGreyOverLay}
                geographyListData={geographyListData}
                geographyObjBeforeBack={geographyObjBeforeBack}
                geographyCostHierarchy={geographyCostHierarchy}
                reportTitle={reportTitle}
                setCompareMode={setCompareMode}
                psLinesOptions={psLinesOptions && psLinesOptions.has(scenarioState.scenario) ? psLinesOptions.get(scenarioState.scenario)[0] : []}
                isScenarioBannerVisible
                useNewReorderList={userSettings.useNewReorderList}
                useNewAddColumn={userSettings.useNewAddColumn}
                exportScopeFilter={exportScopeFilter}
              />
              }
              </div>
            </div>
          </div>
        </Suspense>
      </ErrorBoundary>
    </>
  );
};

export { GeographyConcentrationWrapper };

