// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category-container {
    display: flex;
    align-items: center;
    column-gap: 0.78125vw;
    align-self: stretch;
  }

  .category{
    display: flex;
    padding: 0.885vw 1.042vw 1.042vw 1.042vw;
    align-items: center;
    gap: 0.78125vw;
    flex: 1 0;
    border-radius: 6px;
    border: 1px solid #E9E9FF;
    background: #FFF;
    cursor: pointer;
  }

.category svg {
    width: 1.302vw;
    height: 100%;
}

.category .category_text_label{
    font-size: var(--value-13-px);
    color: rgba(59, 59, 75, 1);
    font-weight: 500;
    line-height: 125%;
}`, "",{"version":3,"sources":["webpack://./src/components/chatBot/category.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qBAAqB;IACrB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,wCAAwC;IACxC,mBAAmB;IACnB,cAAc;IACd,SAAW;IACX,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;EACjB;;AAEF;IACI,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,6BAA6B;IAC7B,0BAA0B;IAC1B,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".category-container {\n    display: flex;\n    align-items: center;\n    column-gap: 0.78125vw;\n    align-self: stretch;\n  }\n\n  .category{\n    display: flex;\n    padding: 0.885vw 1.042vw 1.042vw 1.042vw;\n    align-items: center;\n    gap: 0.78125vw;\n    flex: 1 0 0;\n    border-radius: 6px;\n    border: 1px solid #E9E9FF;\n    background: #FFF;\n    cursor: pointer;\n  }\n\n.category svg {\n    width: 1.302vw;\n    height: 100%;\n}\n\n.category .category_text_label{\n    font-size: var(--value-13-px);\n    color: rgba(59, 59, 75, 1);\n    font-weight: 500;\n    line-height: 125%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
