import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { linearizeHierarchy } from "../../class/array";
import { AMOUNT_FIELD, AMOUNT_OF, AVG_MED_TOGGLE_TABS, BUTTON_TYPE, BUTTON_VARIANT, FIRST_ATTRIBUTE, MOM_CONFIGURE_TOGGLE_TABS, PSL, SIZES, VECTOR_ATTRIBUTE } from "../../class/constants";
import { copyObjectValues } from "../../class/utils";
import { ToggleTab } from "../../form/elements";
import { lang } from "../../language/messages_en";
import Button from "../../newComponents/Button";
import RadioButton from "../../newComponents/RadioButton";
import RowStackConfigComponent from "./RowStackConfigComponent";
const _children ="children";
const Inflector = require('inflected');

const RangeStackConfiguration = ({
  toggleTabLabel,
  toggleOptions,
  defaultToggleValue=MOM_CONFIGURE_TOGGLE_TABS[0].value,
  primaryBtnLabel,
  onPrimaryClick,
  listOfDropdowns,
  defaultObj,
  disableMed,
  isFromList,
  isFromGeoOrPL,
  handleToggleTabChange=undefined,
  defaultShowTipValue=false,
  isMoM,
  useRadioButtons,
  setSelectedConfOption,
  selectedConfOption
}, ref) => {
  useImperativeHandle(ref, () => ({
    setShowTip: setShowTip
  }));

  const [disabled, setDisabled] = useState(false);
  const [toggleTab, setToggleTab] = useState(defaultToggleValue);
  const [obj, setObj] = useState(defaultObj);
  const [showTip, setShowTip] = useState(defaultShowTipValue);
  const [radioButtonVal, setRadioButtonVal] = useState(obj.amountType);
  const rowStackConfigureCompRef = useRef();

  const onClick = (notApply) => {
    onPrimaryClick(obj, notApply);
  };

  const setTempSelectedConfOptionFunc = (val)=>{
    setRadioButtonVal(false);
    setSelectedConfOption(val);
  }

  const updateData = (arr, value) => {
    arr.forEach((row) => {
      if (row.value === value) {
        row.checked = true;
        return;
      }
      if (!row.children) {
        return;
      }
      updateData(row.children, value);
    });

    return arr;
  };
  const renderDropdowns = () => {
    let dropdownDiv = [];
    listOfDropdowns.forEach((row) => {
      let rowTitle = row.toggleTitle;
      let dropdownDefaultVal = row.dropdownDefaultValue;
      let selectedTab = row.selectedTab;

      if(row.type === PSL.value){
        let value = obj.psl || row.dropdownData[0].value;
        row.dropdownData = updateData(row.dropdownData, value);
        rowTitle = AMOUNT_OF + linearizeHierarchy(row.dropdownData, _children)?.find(e=>e.value === value)?.label;// to handle changing the title in real time while selecting an option from the dropdown
      } else if(row.type === VECTOR_ATTRIBUTE.value) {
        dropdownDefaultVal = obj.secondAttribute || row.dropdownDefaultValue;
        selectedTab = obj.isSecondMedian ? AVG_MED_TOGGLE_TABS[1].value : AVG_MED_TOGGLE_TABS[0].value;
        selectedTab = !!obj.isSecondMedian ? selectedTab : row.selectedTab; // if obj.isSecondMedian is undefined, we set the toggle to avg (default)

        let attributeTitlePrefix = obj.isSecondMedian ? AVG_MED_TOGGLE_TABS[1].title : AVG_MED_TOGGLE_TABS[0].title;
        rowTitle = Inflector.singularize(rowTitle.replace("[selected vector]", obj.secondAttribute?.label || dropdownDefaultVal?.label));
        rowTitle = rowTitle.replace("[average]", attributeTitlePrefix);
      } else if (row.type === FIRST_ATTRIBUTE.value) {
        dropdownDefaultVal = obj.firstAttribute || row.dropdownDefaultValue;
        selectedTab = obj.isFirstMedian ? AVG_MED_TOGGLE_TABS[1].value : AVG_MED_TOGGLE_TABS[0].value;
        selectedTab = !!obj.isFirstMedian ? selectedTab : row.selectedTab; // if obj.isFirstMedian is undefined, we set the toggle to avg (default)

        let attributeTitlePrefix = obj.isFirstMedian ? AVG_MED_TOGGLE_TABS[1].title : AVG_MED_TOGGLE_TABS[0].title;
        rowTitle = Inflector.singularize(rowTitle.replace("[selected attribute]", obj.firstAttribute?.label || dropdownDefaultVal?.label));
        rowTitle = rowTitle.replace("[average]", attributeTitlePrefix);
      }

      dropdownDiv.push(
        <RowStackConfigComponent
          ref={rowStackConfigureCompRef} // ref should be in an array when implementing per attributes and per vectors 
          key={row.type}
          isDisabled={false}
          type={row.type}
          toggleDefaultValue={row.toggleDefaultValue}
          toggleTitle={rowTitle}
          isTreeDropdown={row.isTreeDropdown}
          dropdownPlaceholder={row.dropdownPlaceholder}
          dropdownData={row.dropdownData}
          dropdownDefaultValue={dropdownDefaultVal}
          dropdownMode={row.dropdownMode}
          onToggleChange={handleLabelToggle}
          toggleTabOptions={row.toggleTabOptions}
          onToggleTabChange={handleRowSelectTab}
          selectedTab={selectedTab}
          onDropdownChange={handleDropdownChange}
          disableMed={disableMed}
          useRadioButtons={useRadioButtons}
          setTempSelectedConfOption={setTempSelectedConfOptionFunc}
          radioButtonValue={!radioButtonVal}
          selectedConfOption= {selectedConfOption}
          selectedType={obj.selectedType}
          optionLabel={row.optionLabel}
          showSelectedTooltip={row.showSelectedTooltip}
        />
      );
    });

    return dropdownDiv;
  };

  useEffect(()=>{
    onClick(true);
    setDisabled(obj.psl === "");
  },[obj])

  /**
   * This handles the change of the toggle/radio button that is next to the dropdown title
   * Ex: in Total stacks, its the on/off toggle next to %/PSL and in MoM, its the radio button next to % amount psl
   * @param {*} type (ex: psl/firstAttribute/secondAttribute/amount)
   * @param {*} val 
   */
  const handleLabelToggle = (type, val) => {
    if(isMoM) {
      handleTypeChange(type);
    } else {
      if(type === PSL.value){
        setDisabled(obj.psl === "");
      }
      switch (type) {
        case PSL.value:
          setObj({ ...obj, hidePsl: !val});
          break;
        case FIRST_ATTRIBUTE.value:
          setObj({ ...obj, hideFirstAttribute: !val });
          break;
        case VECTOR_ATTRIBUTE.value:
          setObj({ ...obj, hideSecondAttribute: !val });
          break;
      }
    }

  };

  /**
   * This handles the changes of avg/med tabs that we render in <RowStackConfiguration> next to each dropdown
   * @param {*} tab 
   * @param {*} type 
   */
  const handleRowSelectTab = (tab, type) => {
    let isMed = tab === "med";
    setDisabled(obj.psl === "");

    if(isMoM){
      handleTypeChange(type + "Med", tab);
    } else {
      switch (type) {
        case FIRST_ATTRIBUTE.value:
          setObj({ ...obj, isFirstMedian: isMed });
          break;
        case VECTOR_ATTRIBUTE.value:
          setObj({ ...obj, isSecondMedian: isMed });
          break;
      }
    }
  };

  /**
   * This handled the toggle tabs of amount/percentage 
   * @param {*} tab It's the selected tab in amount field (ex:in MoM it can be either amount or percentage)
   */
  const handleSelectTab = (tab) => {
    if(isMoM){
      handleTypeChange(AMOUNT_FIELD, tab);
    } else {
      setDisabled(obj.psl === "");
      setObj({ ...obj, amountType: tab});
      onChangeToggleTabs(tab);
    }
  };

  const onChangeToggleTabs = (tab) => {
    setToggleTab(tab);
    if(typeof handleToggleTabChange === "function") {
      handleToggleTabChange(tab);
    }
  }
  /**
   * This handles the change of the radio button of the amoun toggle tabs.
   * Dropdowns radio button are handled in <RowStackConfiguration>
   */
  const setTabsRadioBtnValue = (checked, selectedType=AMOUNT_FIELD) => {
      setRadioButtonVal(checked);
  };

  /**
   * This function is called when we chnage the radio button selection of amount and when we make a change in one of the dropdowns and amount tabs.
   * It fills the obj with the correct values and handles the amount/perc toggle radio button
   * @param {*} type 
   * @param {*} newInputVal 
   */
  const handleTypeChange = (type, newInputVal) => {
    const defaultAmountTabValue = MOM_CONFIGURE_TOGGLE_TABS[0].value;
    let isAmountTabsSelected = type === AMOUNT_FIELD;
    let isPSLDropdownSelected = type === PSL.value;
    let isVectorDropdownSelected = type === VECTOR_ATTRIBUTE.value;
    let isAttributeDropdownSelected = type === FIRST_ATTRIBUTE.value;
    let isVectorToggleSelected = type === VECTOR_ATTRIBUTE.value +  "Med";
    let isAttrToggleSelected = type === FIRST_ATTRIBUTE.value +  "Med";

    let dataOfSelectedType = listOfDropdowns?.find(f => f.type === type.replace("Med", ""));
    let isMed = newInputVal === AVG_MED_TOGGLE_TABS[1].value;
    let nullifyFirstMedValue = !(isAttributeDropdownSelected || isAttrToggleSelected);
    let nullifySecondMedValue = !(isVectorDropdownSelected || isVectorToggleSelected);

    let tempState = copyObjectValues(obj);
    tempState.amountType = isAmountTabsSelected ? (newInputVal ? newInputVal : defaultAmountTabValue) : undefined;
    tempState.psl = isPSLDropdownSelected ? (newInputVal ? newInputVal.value : dataOfSelectedType.dropdownData[0].value) : undefined;
    tempState.secondAttribute = isVectorDropdownSelected ? (newInputVal ? newInputVal : dataOfSelectedType.dropdownDefaultValue) : (isVectorToggleSelected ? obj.secondAttribute || dataOfSelectedType.dropdownDefaultValue : undefined);
    tempState.isSecondMedian = nullifySecondMedValue ? undefined : (isVectorToggleSelected && isMed) || (isVectorDropdownSelected && obj.isSecondMedian);
    tempState.firstAttribute = isAttributeDropdownSelected ? (newInputVal ? newInputVal : dataOfSelectedType.dropdownDefaultValue) : (isAttrToggleSelected ? obj.firstAttribute || dataOfSelectedType.dropdownDefaultValue : undefined);
    tempState.isFirstMedian = nullifyFirstMedValue ? undefined : (isAttrToggleSelected && isMed) || (isAttributeDropdownSelected && obj.isFirstMedian) ;
    tempState.selectedType = type.replace("Med", "");

    onChangeToggleTabs(tempState.amountType || defaultAmountTabValue);
    setTabsRadioBtnValue(isAmountTabsSelected);
    setObj(tempState);
  }

  /**
   * This handle the changes of dropdowns rendered in <RowStackCofiguration>
   * @param {*} currentNode 
   * @param {*} selectedNodes 
   * @param {*} type 
   */
  const handleDropdownChange = (currentNode, selectedNodes, type) => {
    setDisabled(selectedNodes && selectedNodes.length === 0);
    setDisabled(obj.psl === "");
    
    if(isMoM) {
      handleTypeChange(type, currentNode);
    } else {
      switch (type) {
        case PSL.value:
          setObj({
            ...obj,
            psl: selectedNodes.length === 0 ? "" : currentNode.value
          });
          break;
        case FIRST_ATTRIBUTE.value:
          setObj({ ...obj, firstAttribute: currentNode});
          break;
        case VECTOR_ATTRIBUTE.value:
          setObj({ ...obj, secondAttribute: currentNode});
          break;
      }
    }
  };

  return (
    <div className="table-stack-configure-container">
      <div className="uk-display-flex" style={{columnGap: "0.16vw", alignItems:"center"}}>
        {useRadioButtons?
        <div className="uk-margin-xsmall-bottom">
          <RadioButton
          checked={radioButtonVal}
          onChange={() => handleTypeChange(AMOUNT_FIELD)}
          />
          </div> :""
        }
        <div>
          <label className={"fs-14 " + (useRadioButtons ? "uk-margin-xsmall-top uk-margin-xsmall-left" : "")}>{toggleTabLabel}</label>
          {!isMoM && (
            <i
              className="configure-build-info fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer"
              uk-tooltip={
                isFromList || isFromGeoOrPL
                  ? "title:" + lang.total_stacks_configure.info.configure_tab_from_list
                  : "title:" + lang.total_stacks_configure.info.configure_tab
              }
            />
          )}
        </div>
      </div>
      {toggleOptions?.length > 0 &&
        <div className="toggle-tabs-div">
          <ToggleTab isDisabled={false} mode={"light"} options={toggleOptions} type="table" onSelectTab={(tab) => handleSelectTab(tab)} defaultValue={toggleTab} />
          {showTip && (
            <span className="configure-tip">
              {lang.lamp_emoji}
              <span className="italic">
                <b>{lang.apply_filter_tip.split("<b>")[0]}</b>
                {lang.apply_filter_tip.split("<b>")[1]}
              </span>
            </span>
          )}
        </div>
      }
      {renderDropdowns()}
      <div className="uk-flex uk-flex-right configure_main_button_margin_top">
        <Button
          label={primaryBtnLabel}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          disabled={disabled}
          onBtnClick={onClick}
        />
      </div>
    </div>
  );
};

export default forwardRef(RangeStackConfiguration);
