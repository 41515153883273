import { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import NewBanner from "../../components/NewBanner";
import Button from "../../newComponents/Button";
import { ReportHeader } from "../ReportHeader";

import { BUTTON_TYPE, BUTTON_VARIANT, DROPDOWN_TYPE, FY_VALUES, HEADER_ELEMENT, SCENARIO_STATUS, SIZES } from "../../class/constants";
import { lang } from "../../language/messages_en";

import { useRef } from "react";
import { logout } from "../../class/common";
import { capitalizeFirstLetter, copyObjectValues, findOptionByKey } from "../../class/utils";
import ErrorBoundary from "../../ErrorBoundary";
import { Loader } from "../../form/elements";
import DropDown from "../../newComponents/DropDown";
import SwitchToggle from "../../newComponents/SwitchToggle";
import VectorDefinitions from "../../sections/defineVectors/VectorDefinitions";
import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import { useAuthenticated } from "../CustomHooks";
import {renderBreadcrumbsAndDescription, renderTitle} from "../functions/componentFunctions";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from "posthog-js/react";
/**
 * @author Sandra Abdelkhalek
 * @description component renders title, scenario dropdown, print button, with  {@link VectorDefinitions} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link VectorDefinitions}
 */
const VectorDefinitionsWrapper = (props) => {
  const userAllowedMenuLinks = props.userAllowedMenuLinks;
  const userSettings = props.userSettings;
  const propsScenarioState = props.scenarioState;

  /** References & react utility */
  const reportRef = useRef();
  const history = useHistory();

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

  /** Header Related States */
  const [headerElements, setHeaderElements] = useState([]);
  const [profitFormat, setProfitFormat] = useState(lang.vector_definitions.vector_definitions_title);
  const [reportTitle, setReportTitle] = useState(capitalizeFirstLetter(lang.vector_definitions.vector_definitions_title));

  const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
  const [scenarioState, setScenarioState] = useState(propsScenarioState);
  const useNewComponents = useFeatureFlagEnabled('use_new_components');

  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    /** When comming from header info, inherit the already selected scenario */
    if (window.scenario) {
      handleScenarioChange(JSON.parse(window.scenario));
    }
  }, [propsScenarioState]);

  useEffect(() => {
    if (Object.keys(scenarioState).length > 0) {
      let headerElements = useNewComponents ? getNewHeaderElements() : getHeaderElements();
      setHeaderElements(headerElements);
      setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
    }
  }, [scenarioState]);

  /** Header elements actions */
  const handleScenarioChange = (newScenario) => {
    let tempState = copyObjectValues(scenarioState);
    tempState.scenario = newScenario.value;
    tempState.scenarios = [newScenario.value];
    tempState.scenarioObjects = [newScenario];
    tempState.nextScenarios = [newScenario.value];
    tempState.nextScenariosObjects = [newScenario];
    tempState.scenarioStatus = newScenario.scenario_status;
    tempState.scenarioList = tempState.scenarioList;
    let scenarioStates = {scenarioState: tempState};
    
    history.push({
      pathname: window.location.pathname,
      search: "?",
      hash: "",
      state: scenarioStates
    });

    setScenarioState(tempState);
  };



  /** Header elements */
  const getHeaderElements = () => {
    let headerElements = [];
    headerElements.push(
      <div
        style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between" }}
        className="first_header_row gap_between_buttons noprint"
      >
        {renderTitle(reportTitle)}
        <div style={{ display: "flex", columnGap: "0.42vw", flexDirection: "row" }}>
          <DropDown
            id="select-set"
            className="width-200 input__dropdown noprint"
            name={HEADER_ELEMENT.SCENARIO}
            value={findOptionByKey(scenarioState.scenarioList, scenarioState.scenario)}
            onChange={handleScenarioChange}
            options={scenarioState.scenarioList}
            disabled={false}
            type={DROPDOWN_TYPE.INPUT}
          />
          <Button
            id="print-btn"
            label="Print"
            className="noprint"
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            leftIcon={<i className="fal fa-print" />}
            onBtnClick={() => window.print()}
          />
        </div>
      </div>
    );

    return headerElements;
  };

  const renderHeaderFirstRow = () => {
    return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription)
  }

  const renderHeaderSecondRow = () => {
    return (
        <>
          <div style={{display: "flex", columnGap: "0.42vw", flexDirection: "row"}}>
            <DropDown
                id="select-set"
                className="width-200 input__dropdown noprint"
                name={HEADER_ELEMENT.SCENARIO}
                value={findOptionByKey(scenarioState.scenarioList, scenarioState.scenario)}
                onChange={handleScenarioChange}
                options={scenarioState.scenarioList}
                disabled={false}
                type={DROPDOWN_TYPE.INPUT}
            />
            <Button
                id="print-btn"
                label="Print"
                className="noprint"
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                leftIcon={<i className="fal fa-print"/>}
                onBtnClick={() => window.print()}
            />
          </div>
        </>
    );
  }

  const getNewHeaderElements = () => {
    let headerElements = [];

    headerElements.push(
        <div style={{display: "flex", width: "100%", alignItems: "center"}}
             className='new_header_menu first_header_row gap_between_buttons'>
          {renderHeaderFirstRow()}
        </div>
    );

    headerElements.push(
        <div
            className="new_header_menu second_header_row gap_between_buttons"
            style={{display: "flex", width: "100%", padding: "0.41667vw 0"}}
        >
          {renderHeaderSecondRow()}
        </div>
    );

    return headerElements;
  };


  let trackingData = window._pi_getTrackingDataObj();
  trackingData = trackingData === null ? "" : JSON.stringify(trackingData);
  let scenarioLabel = scenarioState?.scenarioObjects?.length > 0 ? scenarioState?.scenarioObjects[0].label : "";

  return (
      <>
        <Suspense fallback={<p id="loading">Loading...</p>}>
          <div
              id={"main-component-container"}
              className={"main-component-container"}
              style={{"--banner-height": isScenarioBannerVisible ? "6%" : 0}}
          >
            <div className={isScenarioBannerVisible ? "header-banner-div" : "header-banner-div-hidden"}>
              <Loader newLoader />

              <NewBanner
                bannerClassName={"header-banner noprint"}
                labelClassName={"header-banner-label scenario-banner-label"}
                icon={<RoundInfoIcon className="info-banner-icon" />}
                label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
                body={
                  <Button
                    id={"banner-dismiss-btn"}
                    label={lang.modal.buttons.dismiss}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => setScenarioBannerVisible(false)}
                  />
                }
              />
            </div>

            <div className="main-report-header ">
              <ReportHeader headerElements={headerElements} />
            </div>
            <div className="main_report">
              <div id="main_report_container" className="main_report_container">
                {Object.keys(scenarioState).length > 0 && headerElements.length > 0 && (
                  <VectorDefinitions
                    ref={reportRef}
                    userSettingsState={userSettings}
                    userAllowedMenuLinks={userAllowedMenuLinks}
                    scenarioState={scenarioState}
                    profitFormat={profitFormat}
                    reportTitle={reportTitle}
                  />
                )}
              </div>
            </div>
          </div>
        </Suspense>
    </>
  );
};

export { VectorDefinitionsWrapper };

