import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { linearizeHierarchy } from "../../class/array";
import { DROPDOWN_TYPE, FY_VALUES, PSL } from "../../class/constants";
import Container from "../../components/manageColumns/Container";
import { ToggleTab, ToggleValue } from "../../form/elements";
import { lang } from "../../language/messages_en";
import DropDown from "../../newComponents/DropDown";
import RadioButton from "../../newComponents/RadioButton";

const RowStackConfigComponent = ({
  type, // this is the type of dropdown we are rendering
  toggleDefaultValue,
  toggleTitle,
  onToggleChange,
  isTreeDropdown,
  dropdownPlaceholder,
  dropdownData,
  dropdownDefaultValue,
  onDropdownChange,
  dropdownMode,
  toggleTabOptions,
  onToggleTabChange,
  selectedTab,
  disableMed,
  isDisabled,
  useRadioButtons,
  selectedType, // this holds the type that has the radio button checked (either amount/vector/psl/attribute)
  optionLabel,
  showSelectedTooltip
}, ref) => {
  useImperativeHandle(ref, () => ({
    setRadioButtonVal: setRadioButtonVal
  }));
  const [disabled, setDisabled] = useState(
    toggleDefaultValue === FY_VALUES.OFF
  );
  const [toggleValue, setToggleValue] = useState(toggleDefaultValue);
  const [radioButtonVal, setRadioButtonVal] = useState(selectedType === type);
  const [toggleTab, setToggleTab] = useState(selectedTab);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState(dropdownData);
  const [dropdownValue, setDropdownValue] = useState(dropdownDefaultValue);

  useEffect(() => {
    setRadioButtonVal(selectedType === type);
    if(selectedType !== type && !isTreeDropdown) {
      setDropdownValue(dropdownDefaultValue);
      setToggleTab(selectedTab)
    }
  }, [selectedType]);

  const handleOnToggleChange = () => {
    let value = disabled;
    setToggleValue(value ? FY_VALUES.ON : FY_VALUES.OFF);
    onToggleChange(type, value);
    setDisabled(!value);
    setErrorMessage("");

    if (type === PSL.value && value && linearizeHierarchy(data,"children").filter((f) => f.checked).length === 0) {
      setErrorMessage(lang.configue_psl_empty);
    }
  };

  const handleOnRadioButtonChange = () => {
      setRadioButtonVal(!radioButtonVal);
      onToggleChange(type, !radioButtonVal);
      setErrorMessage("");
      if (type === PSL.value && disabled && linearizeHierarchy(data,"children").filter((f) => f.checked).length === 0) {
        setErrorMessage(lang.configue_psl_empty);
    }
  };

  /**
   * This function handles the change of toggle tabs (AVG/MED).
   * We send the tab (avg or med) and the dropdown type (attribute or vector)
   * @param {*} tab (ex: if avg is selected -> tab=avg)
   */
  const handleSelectTab = (tab) => {
    setToggleTab(tab);
    setRadioButtonVal(true);
    onToggleTabChange(tab, type);
  };

  useEffect(()=>{
    $('[id^=rdts]').each(function() {// hide the X icon for the DropdownTreeSelect selected option
      if($(this)[0] && $(this)[0].id.endsWith("_button") && !$(this).hasClass("uk-hidden")){
        $(this).addClass("uk-hidden");
      }
    });
    if (type === PSL.value && linearizeHierarchy(data,"children").filter((f) => f.checked).length === 0) {
      setErrorMessage(lang.configue_psl_empty);
    }
  })

  /**
   * loops over the lines and their children to find the matching node and updates it
   * @param {} arr
   * @param {*} node
   * @returns
   */
  const updateData = (arr, node) => {
    arr.forEach((row) => {
      if (row.value === node.value) {
        row.checked = node.checked;
        return;
      }
      if (!row.children) {
        return;
      }
      updateData(row.children, node);
    });

    return arr;
  };

  /**
   * loops over the lines and their children and updates it to checked or not checked
   * @param {} arr
   * @param {*} isChecked
   * @returns
   */
  const updateAllData = (arr, isChecked) => {
    arr.forEach((row) => {
      row.checked = isChecked;
      if (!row.children) {
        return;
      }
      updateAllData(row.children, isChecked);
    });

    return arr;
  };

  const handleDropdownChange = (currentNode, selectedNodes) => {
    if (!disabled) {
      setErrorMessage(
        selectedNodes && selectedNodes.length === 0
          ? lang.configue_psl_empty
          : ""
      );

      onDropdownChange(currentNode, selectedNodes, type);
    }

    if (selectedNodes) {
      // for tree dropdown
      let updatedData = updateAllData(data, false); // uncheck all checked lines
      updatedData = updateData(data, currentNode);
      setData(updatedData);
    } else {
      // for simple dropdowns
      setDropdownValue(currentNode);
    }
  };

  return (
    <div className="row-stack-configure uk-margin-top">
      {useRadioButtons?
      <RadioButton
        labelClassName = "fs-14 uk-text-bold text-overflow-ellipsis"
        checked={radioButtonVal}
        onChange={handleOnRadioButtonChange}
        disabled={isDisabled}
        label={toggleTitle} 
      />
      :
      <ToggleValue
        isDisabled={isDisabled}
        value={toggleValue}
        titleConstant={toggleTitle}
        onChangeValue={handleOnToggleChange}
      />}
      <div className="uk-flex uk-margin-xsmall-top gap_between_buttons">
      <div uk-tooltip={isDisabled?lang.data_still_loading:"title:"} style={{width: "100%"}}>
        <div className={isDisabled?"disabled ":""}>
          {isTreeDropdown ? (
            <Container
              texts={{ placeholder: dropdownPlaceholder }}
              data={data}
              onChange={handleDropdownChange}
              mode={dropdownMode}
              className={"dropdown-tree" + (disabled ? " disabled" : "")}
            />
          ) : (
            <DropDown
              id="select-set"
              className="uk-cursor-pointer input__dropdown"
              value={dropdownValue}
              onChange={(e) => handleDropdownChange(e)}
              options={dropdownData}
              // isDisabled={disabled}
              disabled={disabled}
              class = {"configure-table-data-select"}
              type={DROPDOWN_TYPE.INPUT}
              formatOptionLabel={optionLabel}
              showSelectedTooltip={showSelectedTooltip}
            />
          )}
        </div>
      </div>
        {toggleTabOptions && toggleTabOptions.length > 1 && (
          <div uk-tooltip={isDisabled ?lang.data_still_loading: disableMed ? lang.disableMedTooltip:"title:"} className="max_width">
            <div className={isDisabled?"disabled ":""}>
              <ToggleTab
                options={toggleTabOptions}
                onSelectTab={(tab) => handleSelectTab(tab)}
                defaultValue={toggleTab}
                isDisabled={disabled || disableMed}
                mode={"light"}
              />
            </div>
          </div>
        )}
      </div>
      {errorMessage !== "" && (
        <div className="fs-12 red italic">{errorMessage}</div>
      )}
    </div>    
  );
};

export default forwardRef(RowStackConfigComponent);
