import { Box, ClickAwayListener } from "@mui/material";
import { useRef, useState } from "react";
import { BUTTON_TYPE, BUTTON_VARIANT, ENTITY_TYPES, SIZES } from "../../class/constants";
import { lang } from "../../language/messages_en";
import Button from "../../newComponents/Button";
import { ReactComponent as ChevronDown } from "../../styles/images/chevron-down.svg";
import { ReactComponent as Arrow } from "../../styles/images/icons/small-thick-arrow.svg";
import "./drill.css";
import DrillOptionDropdown from "./DrillOptionDropdown";
import {useFeatureFlagEnabled} from "posthog-js/react";


const DrillButtonDropdown = ({onOptionClick, vectors, disabled, tooltipMessage, variant=BUTTON_VARIANT.PRIMARY}) => {
  const [open, setOpen] = useState(false);
  const useNewComponents = useFeatureFlagEnabled('use_new_components')

  const handleClick = () =>{
    setOpen((prev) => !prev);
  }

  const handleClickAway = () => {
    setOpen(false);
  };

  const onOptionClickFunc = (option, type) => {
    setOpen(false);
    onOptionClick(option, type);
  };

  const renderBody = () => {
    let generatedVectors = vectors.filter(e=>e.generated);
    return (
      <div className="drill-dropdown-body">
        <div className="padding title_description_container">
          <span className="start-drilling-text">{lang.drill.start_drilling}</span>
          <div><span className="start-drilling-message">{lang.drill.start_drilling_message}</span></div>
        </div>
        <div className="separator"></div>
        <div className="drill-drodpown-options-div">
          <DrillOptionDropdown
            title={lang.drill.by_segment}
            type={ENTITY_TYPES.SEGMENT}
            options={generatedVectors}
            optionClick={onOptionClickFunc}
          />
          <div className="separator"></div>
          <DrillOptionDropdown
            title={lang.drill.by_tier}
            type={ENTITY_TYPES.PROFITTIER}
            options={generatedVectors}
            optionClick={onOptionClickFunc}
          />
          <div className="separator"></div>
          <DrillOptionDropdown
            title={lang.drill.by_entity}
            type={ENTITY_TYPES.KEY}
            options={vectors}
            optionClick={onOptionClickFunc}
            allowSegmentationIcon
            isSearchable
          />
        </div>
      </div>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div style={{ position: "relative" }} uk-tooltip={tooltipMessage}>
        <Button
          id="drill-btn"
          className="drill-button"
          disabled={disabled}
          onBtnClick={handleClick}
          label={"Drill"}
          variant={useNewComponents ? variant : BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          rightIcon={useNewComponents ? <Arrow className="arrow_svg"/> : <ChevronDown className="chevron-down"/>}
        />
        {open ? renderBody() : null}
      </div>
    </ClickAwayListener>
  );
};

export default DrillButtonDropdown;