import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import Base from '../../Base';
import AppliedFilters from '../../components/AppliedFilters';
import ConfirmPasswordModal from '../../components/ConfirmPasswordModal';
import FilterByDropdown from '../../components/FilterByDropdown';
import ManageColumns from '../../components/manageColumns/ManageColumns';
import NewBanner from '../../components/NewBanner';
import { DimensionsDropdowns, Loader } from '../../form/elements';
import Button from '../../newComponents/Button';
import Modal from '../../newComponents/Modal';
import FilterDialog from '../../sections/filter/FilterDialog';
import { ReportHeader } from '../ReportHeader';

import {
    ALL_FORMATS,
    ALL_REPORTS,
    ALL_WIDGETS,
    API_URL,
    BUTTON_TYPE, BUTTON_VARIANT,
    CURRENT_FILTER_COOKIE,
    DIALOG_SIZE,
    FILTER,
    FormatTypes,
    HEADER_ELEMENT, MENU_ITEM,
    PROFILE_COLUMN, PSS,
    ROLLING_SEGMENTS,
    SAVED_FILTER_ID_COOKIE,
    SCENARIO_STATUS,
    SELECTED_DRILL_LIST,
    SIZES, UI_ACTIONS,
    costtype, BUTTON_DROPDOWN_VARIANT, COLUMN_PROFILE,
    PSL_RETURN_NAMES,
    LISTENER,
    SAVE_COMPANY_FILTERS
} from '../../class/constants';
import { lang } from "../../language/messages_en";

import {
    formatAdvancedFilter,
    formatBasicFilter,
    getLocalStorageValueByParameter,
    logout,
    setLocalStorageValueByParameter,
    triggerExportData,
    updateWindowLoading
} from '../../class/common';
import { generateQuarter, getPeriodDifference, getPeriodFromDate, getPeriodsObjectNew } from '../../class/date';
import {saveCookie} from '../../class/jqueries';
import {
    areObjectsNotEmpty,
    checkIfMonthsValid,
    copyObjectValues,
    findOptionByKeyValue,
    getSectionExists, numberToWords,
    profileHasColumnsAccess,
    tryParse
} from '../../class/utils';
import { getNewEntityFilter } from '../../sections/filter/FilterHelperFunctions';
import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import { updateDatasetState, updatePeriodsStatusState, updatePsViewsState, updateVectorState } from '../../actions/scenariosActions';
import { getClientPeriods, getCustomViewData, getDatasets, getFilterEntities, getPeriodsStatus, getVectors } from '../api/api';
import { useAuthenticated, useCalendarToggle, useExportScopeFilter } from "../CustomHooks";
import {
    atLeastOneVectorHasNAInData,
    messageDialogContent,
    renderBreadcrumbsAndDescription,
    renderButton,
    renderPeriodCalendar,
    renderTitle,
    runExtendedProfitStack,
    tablesToExcel
} from '../functions/componentFunctions';
import { addCorrespondingParenthesisToFilter } from '../functions/filterFunctions';
import { LoaderSkeleton } from '../LoaderSkeleton';
import {FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS} from "../../class/networkUtils";
import ButtonDropdown from "../../newComponents/ButtonDropdown";
import DrillButtonDropdown from '../../components/drill/DrillButtonDropdown';
import { QuickFilters } from '../../components/quickFilters/QuickFilters';
import { useListener } from '../../actions/CustomEventHandler';
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from 'posthog-js/react';

const _modified = " Modified"; 
const _selectedListProfile = "selectedProfile_list";
/**
 * @author Sarah Farjallah
 * @description This component holds the the header of the list and list component named Base where the header contains profile selected name, period calendar, vector dropdown, apply button,
 * view stack dropdown, manage columns, fiterDialog component, export dropdown and the body carrying the Base component
 * @param {*} props 
 * @returns Report header and Base
 */
const ListWrapper = (props) => {
    /**Store */
    const profiles = useSelector(state=>state.profiles);
    const stateProfiles = useSelector(state=>state.stateProfiles);
    const customGroups = useSelector(state=>state.customGroups);
    const profileColumns = useSelector(state=>state.profileColumns);
    const columnProfiles = useSelector(state=>state.columnProfiles);
    const psLinesOptions = useSelector(state=>state.psLinesOptions);
    const quadrantTierOptions = useSelector(state=>state.quadrantTierOptions);
    const drillListData = useSelector((state) => state.drillListData);
    const listData = useSelector((state) => state.listData);
    const listObjBeforeBack = useSelector((state) => {
      const listObj = state.listObjBeforeBack;
      return (listObj instanceof Map) && listObj?.get("listObjBeforeBack")?.length > 0 ? listObj?.get("listObjBeforeBack")[0] : undefined;
    });


    const manageColumnsProfileFromRedux = listObjBeforeBack?.manageColumnsProfile;
    const userAllowedMenuLinks = props.userAllowedMenuLinks;
    const userSettings = props.userSettings;
    const scenarioState = props.scenarioState;

    /**References & react utility */
    const dispatch = useDispatch();
    const params = useParams();
    const history = useHistory();
    
    const manageColsOverlay = useRef();
    const manageColsDrillOverlay = useRef();
    const dimensionDropdownsRef = useRef();
    const reportRef = useRef();
    const manageColumnsDrillRef = useRef();
    const manageColsRef = useRef();
    const appliedFiltersRef = useRef();
    const filterDialRef = useRef();
    const filterByRef = useRef();
    const profitStackSelectedEntities = useRef();
    const exportRef = useRef();
    const exportRefVal = useRef();
    const confirmPassRef = useRef();
    const countRef = useRef(0);
    const drillFilterRef = useRef();
    const configureDropDownHeaderRef = useRef();

    /**State */
    const [periodsStatusState, setPeriodsStatusState] = useState({});
    const [vectorState, setVectorState] = useState({});
    const [datasetState, setDatasetState] = useState({});
    const [PSViewsState, setPSViewsState] = useState({}); 
    const [clientPeriodsState, setClientPeriodsState] = useState([]);
    const [calendarVisible, showCalendar] = useState(false);

    /**Custom Hooks */
    const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
    const { toggleCalendar } = useCalendarToggle();
    const exportScopeFilter = useExportScopeFilter(userSettings, datasetState?.datasetOptions, vectorState?.vectorOptions, filterDialRef?.current?.state?.psLinesOptions);

    /**filter Related States */
    const [filterFinal, setFilterFinal] = useState(props.history.location.state ? props.history.location.state.outsideFilter ? JSON.stringify(tryParse(props.history.location.state.outsideFilter).filter) || JSON.stringify(tryParse(props.history.location.state.outsideFilter)) || [] : [] : []);
    const [filterFinalBasic, setFilterFinalBasic] = useState(props.history.location.state ? props.history.location.state.mainFilterFinalBasic ? tryParse(props.history.location.state.mainFilterFinalBasic) || [] : [] : []);
    const [basicFilterEntities, setBasicFilterEntities] = useState([]);
    const [exportQueryFilter, setExportQueryFilter] = useState("");
    const [displayFilter, setDisplayFilter] = useState();
    const [isFilterDisabled, setIsFilterDisabled] = useState(false);
    const [isAdvancedFilterApplied, setIsAdvancedFilterApplied] = useState();
    const [tempFilter, setTempFilter] = useState();
    const [basicFilterEntitiesState, setBasicFilterEntitiesState] = useState([]);
    const [count, setCount] = useState(0);
    const [basicFilterItem, setBasicFilterItem] = useState();
    const [drillFilter, setDrillFilter] = useState();
    const [originalDrillFilter, setOriginalDrillFilter] = useState();

    const filterSet = useRef(false);

    /**Header Related States */
    const [headerChangedCounter, setHeaderChangedCounter] = useState(0); 
    const [headerElements, setHeaderElements] = useState([]);
    const [profitFormat, setProfitFormat] = useState(ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.List);
    const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.List);
    const [manageColumnsProfile, setManageColumnsProfile] = useState();
    const [openLimitAccessDialog, setOpenLimitAccessDialog] = useState(false);
    const [limitAccessMsg, setLimitAccessMsg] = useState('');
    const [viewStackOpen, setViewStackOpen] = useState(false);
    const [showGreyOverLay, setShowGreyOverLay] = useState(true);
    const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
    const [callGo, setCallGo] = useState(false);
    const [exportOptions, setExportOptions] = useState([]);
    const [exportValue, setExportValue] = useState();
    const [headerChanged, setHeaderChanged] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState(lang.header.custom_text.download_start);
    const [dataLength, setDataLength] = useState(0);
    const [isDrilling, setIsDrilling] = useState(false);
    const [openInfoDialog, setOpenInfoDilog] = useState();
    const [infoDialogMsg, setInfoDialogMsg] = useState();
    const [openFilterDialog, setOpenFilterDialog] = useState();
    const [selectedEntities, setSelectedEntities] = useState(0);
    const [drillVectorType, setDrillVectorType] = useState();
    const [isFromDashboards, setIsFromDashboards] = useState(history?.location?.state?.isFromDashboards);
    const useNewComponents = useFeatureFlagEnabled('use_new_components');

    const remaining = numberToWords(3 - selectedEntities);
    const rowText = selectedEntities < 2 ? "rows" : "row";

    const manageColumnsProps = {
        manageColumnsUpdateSections: [ALL_WIDGETS.FIELDS.LIST, MENU_ITEM.FIELDS.HEATMAP, MENU_ITEM.FIELDS.EROSION_AND_GROWTH, ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS]
    };
    const manageColsAccess = {
        [ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.MANAGE_USER_COLUMNS), [userAllowedMenuLinks]),
        [ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_COMPANY_COLUMNS), [userAllowedMenuLinks]),
        [ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.EDIT_COMPANY_COLUMNS), [userAllowedMenuLinks]),
        [ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS]: useMemo(() => getSectionExists( userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_VECTOR_COLUMNS), [userAllowedMenuLinks])
    };


    /**
     * @function useEffect()
     * on screen initialization if isAuthenticated is false user gets logged out 
     * isAuthenticated is a boolean read from a custom hook named useAuthenticated
     **/
    useEffect(() => {
        if(!isAuthenticated) {
          logout();
        }
      }, [isAuthenticated]);
    
    /**
     * @function useEffect()
     * Once scenario is received to the component whether from changing scenario or from initializing screen a set of functions are being called in order to fetch the data dependant on the scenario 
     */
    useEffect(()=>{
        if (scenarioState) {
          //when changing scenario, clear all states so that it won't run useEffect when new states are still not updated
            setPeriodsStatusState({});
            setVectorState({});
            setDatasetState({});
            setClientPeriodsState({});
            setShowGreyOverLay(true);
            getPeriodsStatus(scenarioState, dispatch, updatePeriodsStatusState, setPeriodsStatusState, props, profitFormat, userSettings, 12);
            getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
            getVectors(scenarioState, dispatch, updateVectorState, setVectorState, reportTitle, profitFormat, props, false, filterFinal, userSettings, vectorState, filterDialRef, ALL_REPORTS.LIST_TOP);
            getDatasets(scenarioState, dispatch, updateDatasetState,setDatasetState, props, profitFormat, params,false, userSettings, datasetState);
            setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
            setExportOptionsForUser();
      }
    },[scenarioState]);

    /**
     * @function useEffect()
     * Once all data related to the selected/changed scenario are fetched getHeaderElements function is called to render the header with the updated data
     * any change to drilling or filter or export will also trigger the rerender of the header
     */
    useEffect(()=>{
        if (areObjectsNotEmpty([periodsStatusState, scenarioState, clientPeriodsState, vectorState, PSViewsState]) && userSettings.user) {
            let headerElements = useNewComponents ? getNewHeaderElements() : getHeaderElements();
            setHeaderElements(headerElements);
            if (exportValue !== undefined) {
              setHeaderChanged(true);
            }
        }
    },[periodsStatusState, clientPeriodsState, calendarVisible, vectorState, PSViewsState, manageColumnsProfile, showGreyOverLay, exportOptions,
         isDrilling, count, basicFilterEntities, filterFinalBasic, headerChangedCounter, manageColumnsProfile, exportValue, drillFilter, selectedEntities, filterFinal]);

    /**
     * @function useEffect()
     * once vectorState has changed or initialzed getCusomViewData is called
    */
    useEffect(()=>{
        if (vectorState) {
            getCustomViewData(PSViewsState, vectorState, params, filterFinal, userSettings, scenarioState ,dispatch, updatePsViewsState, setPSViewsState, false, props);
            // if (!showGreyOverLay) {
            //     apply(); // if no greyoverlay then call apply()
            // }
        }
    },[vectorState]);

    /**
     * @function useEffect()
     * on intialization of component state in history is being modified to remove outsideFilter and isFromDashboards value if the list is accessed from a list widget in Dashboards screen 
     */
    useEffect(() => {
        if(history?.location?.state?.isFromDashboards){
            const currentState = history.location.state || {};
            const newState = { ...currentState };
            delete newState.isFromDashboards;
            delete newState.outsideFilter;
            history.replace({
            pathname: history.location.pathname,
            state: newState,
            });
        }

       
      }, []);

    /**
     * When we change the scope, we need to trigger the exitDrill() function to redirect the user to list.
     * isListDrilling is needed to know if we are in list drill when we change scope.
     * This condition will only be called if we are in list-drill and we changed scope.
     */
      useEffect(() => {
        if(history?.location?.state?.isScopeFilterChanged) {
            exitDrill()

            const currentState = history.location.state || {};
            const newState = { ...currentState };

            delete newState.isScopeFilterChanged;
            newState.isListDrilling = false;
            history.push({
                state: newState,
            });
        }
      }, [history?.location?.state?.isScopeFilterChanged])

    useEffect(() => {
        if (selectedEntities > 0) {
            setSelectedEntities(0);
        }
    }, [isDrilling]);

    /**
     * @function useEffect()
     * triggering apply function to fetch data once apply is clicked or filter is changed or profile from manage columns is changed only if greyoverlay is not showing
     */
    useEffect(() => {
        if (callGo && !showGreyOverLay) {
            apply();
            setCallGo(false);
        }
        if (selectedEntities > 0) {
            setSelectedEntities(0);
        }
    }, [callGo, manageColumnsProfile, filterFinal, filterFinalBasic]);


    useListener(LISTENER.APPLY, () => {
        if (!showGreyOverLay) {
            apply();
            setCallGo(false);
        }
    });
    
    /**
     * @function setFilterTo()
     * function sent as props to {@link FilterDialog} component to be called from there to set the filter inherited from previous screen and trigger displaying it on the UI 
     */
    const setFilterTo = () => {
      let filter = filterFinal;
      //before saving the filter, save the old value if not previously set
      if(filterFinal && typeof filterFinal !== 'string') {
          filter = JSON.stringify(filterFinal);        //onChangeSavedFilter expects a string and not an array
      }
      let filterSetRef = filterSet.current; 
      if (!filterSetRef && filterFinal?.length > 0) {
        filterSet.current = true;
        filterDialRef?.current?.onChangeSavedFilter(filter, false);
      }
  }
    const renderExportBody = () => {
        let configureClassName = "table-configure-dialog";
        return (
            <div id="configure-dropdown" style={{position: "absolute", right: "1.25vw"}}>
                <div id="stack_table_export_dialog" className={configureClassName + " table-configure-dialog configure-container configure_body"}>
                    {exportOptions.map((option, index) => (
                        <div className={"export_options_" + option.value} key={index}>
                            <div className="stacks_configure_title" onClick={()=>option?.click()}>{option.label}</div>
                            <div className="export_description">{option.description}</div>
                            <div className="export_container_divider"></div>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const onDrillOptionClick = (option, type) =>{
        let cardsDataLength = reportRef.current.pageComponent?.state?.cardsData?.length;
        if(cardsDataLength>0){
            reportRef.current.pageComponent.tabulatorDrill.current.drill(option.value, type);
        }else{
            reportRef.current.pageComponent.tabulatorList.current.drill(option.value, type);
        }
    }

    const setQuickFilters = (filters) => {
        if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) { 
            localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
            localStorage.removeItem(CURRENT_FILTER_COOKIE);
            setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
        }
        
        setFilterFinal(JSON.stringify(filters));
        setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
        if (!showGreyOverLay) {
            setCallGo(true);
        }
    }

    /**
     * @function getHeaderElements()
     * function calls multiple functions and pushes reportTile, periodCalendar, vectorDropdown, ManageColumns, FilterDialog, Export,ViewStack DropDown,apply button and back button in case of drilling to headerElements array
     * @returns array of components to be rendered in {@link ReportHeader} component
     */
    const getHeaderElements = () => {
        let sectionsData = getFilterBySectionsData();
       
        let isDropDownDisabled = false; 
        let headerElements = [];

        let startPeriodMnageCol = getPeriodFromDate(new Date(periodsStatusState.customStartDate));
        let endPeriodManageCol = getPeriodFromDate(new Date(periodsStatusState.customEndDate));
       
        headerElements.push(
            <div style={{ display: "flex", width: "100%", alignItems: "center" }} className='first_header_row gap_between_buttons'>
                {renderTitle(manageColumnsProfile && manageColumnsProfile !== null && manageColumnsProfile?.name + (manageColumnsProfile?.is_modified ? _modified : "") ||  "List")}
                {renderPeriodCalendar(periodsStatusState, handleElementChange, undefined, clientPeriodsState.allPeriods, toggleCalendar, isDropDownDisabled, scenarioState)}

                {/* <div key={Math.random()} className="template-report-title">by</div> */}
                <div key={Math.random()} uk-tooltip={isDropDownDisabled ? lang.exit_drill_to_proceed : "title:"} className={"uk-flex uk-flex-column uk-flex-start"}>
                    <div key={Math.random()} className={isDropDownDisabled ? " disabled" : ""}>
                        <DimensionsDropdowns
                            ref={dimensionDropdownsRef}
                            disabled={reportTitle === ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT /*|| this.props.state.isBridgeReport || this.isProfitStack*/}
                            numberOfDimensions={1}
                            dimensionOptions={/*this.isGeographyConcentration ? vectorOptions?.filter(e=>e.vector_group === 'Customer') :*/ vectorState.vectorOptions}
                            vectorDisabled={vectorState.vectorDisabled || reportTitle === ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT
                            /*|| props.state.isBridgeReport || this.isProfitStack || (this.props.isDrilling && this.props.isMainReport.isSecondDimension)*/}
                            excludeOptions={true} onChangeDimension={(e, index, fromMount) => handleElementChange(HEADER_ELEMENT.VECTOR, e, fromMount, index, true)}
                            shouldFetchData={false}
                            selectedDimensions={vectorState.nextVectors}
                            startAsEmpty={false/*(this.isManageScenarios || this.isCompareScenarios) && !this.state.nextVectors.length*/}
                            dropdownTooltip={/*this.props.isDrilling && this.props.isMainReport.isSecondDimension ? lang.exit_drill_to_proceed :*/ undefined}
                            hideLabel={true}
                        />
                    </div>
                </div>
                <div id="apply_div">{renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), '', '', apply, !showGreyOverLay)}</div>
                {manageColsAccess && !Object.keys(manageColsAccess).every((k) => !manageColsAccess[k]) && vectorState?.vectorOptions ?
                    <ManageColumns
                        ref={manageColsRef}
                        key={HEADER_ELEMENT.ADD_COLUMNS}
                        scenarioId={scenarioState.scenario}
                        profitFormat={ALL_WIDGETS.FIELDS.LIST}
                        approveBulkChange={approveBulkChange}
                        user={userSettings.user}
                        onSelectProfile={updateManageColumnsProfile}
                        onSelectDefaultProfile={onSelectDefaultProfile}
                        onChangeProfileData={onChangeProfileData}
                        onToggleBoard={onToggleManageColsBoard}
                        manageColsAccess={manageColsAccess}
                        comparisonScenarioId={undefined}
                        vectorObjects={vectorState.vectorObjects}
                        sectionsProfilesAreValidFor={manageColumnsProps.manageColumnsUpdateSections}
                        hideDropdown={false/**isDrilling */}
                        vectorOptions={vectorState.vectorOptions}
                        checkForLimitAccessMessage={checkForLimitAccessMessage}
                        vector={vectorState.nextVectors[0]}
                        outsideColumnProfileId={props?.history?.location?.state ? props?.history?.location?.state?.columnProfileId : undefined }
                        report={reportTitle}
                        hideArrow={false}
                        hideManage={isDrilling}
                        updateManageColumnsProfile={updateManageColumnsProfile}
                        isCompareScenarios={false}
                        dataSet={userSettings.dataset}
                        dispatch={dispatch}
                        manageColumnsSelectionLimit={userSettings.manageColumnsSelectionLimit}
                        selectedRange={startPeriodMnageCol + (startPeriodMnageCol !== endPeriodManageCol ? " to " + endPeriodManageCol : "")}
                        selectedFilter={[]/*filterFinal*/}
                        profileColumns={profileColumns}
                        customGroups={customGroups}
                        profiles={profiles}
                        columnProfiles={columnProfiles}
                        stateProfiles={stateProfiles}
                        constraint={"5"}
                        characterSizeLimit={userSettings.characterSizeLimit}
                        useNewReorderList={userSettings.useNewReorderList}
                        useAppearanceList={userSettings.useAppearanceList}
                        setColumnWidthState={reportRef.current?.pageComponent?.setColumnWidthState}
                        setDrillListWidthState={reportRef.current?.pageComponent?.setDrillListWidthState}
                        scenarioState={scenarioState}
                        useNewAddColumn={userSettings.useNewAddColumn}
                    /> : ""}
            </div>);
 
        const viewStack = ({ label, isDisabled, tooltipText }) => (
            <div id={label} className={"option-padding uk-flex-between uk-display-flex"} disabled={isDisabled} uk-tooltip={!!tooltipText ? tooltipText : ""}> {label} </div>
        );
        let cardsDataLength = reportRef.current?.pageComponent?.state?.cardsData?.length
        
        let isViewStackDisabled = reportRef.current?.pageComponent?.state?.isCheckboxDisabled;
        let viewStackTooltip = isViewStackDisabled? lang.COMMON.profit_stack_not_supported_three_vectors: "";
        
        let rowData = reportRef.current?.pageComponent?.tabulatorList?.current?.state?.cell?.getRow().getData() || reportRef.current?.pageComponent?.tabulatorDrill?.current?.state?.cell?.getRow().getData();        
        let selectedVectorWithNoKeyHasNA = rowData && (!rowData[PSL_RETURN_NAMES.NAME] && !rowData[PSL_RETURN_NAMES.NUMBER] && (rowData[PSL_RETURN_NAMES.QUADRANT] === "N/A" || rowData[PSL_RETURN_NAMES.QUADRANT_TIER] === "N/A"));
        let atLeastOneVectorOtherThanSelectedHasNA = rowData && atLeastOneVectorHasNAInData(rowData, vectorState?.vectorOptions);
        
        let drillLimitExceeded = cardsDataLength && (cardsDataLength >= Number(userSettings?.drillSelectionLimit));
        let isDrillDisabled = atLeastOneVectorOtherThanSelectedHasNA || selectedVectorWithNoKeyHasNA || drillLimitExceeded;
        let drillTooltip = isDrillDisabled? drillLimitExceeded? lang.drill_limit_exceeded : lang.COMMON.drill_not_supported_list_q :"";
        let canSaveCompanyFilters = getSectionExists(userAllowedMenuLinks, SAVE_COMPANY_FILTERS);
        let renderDrillButton = selectedEntities >0  && selectedEntities <=  Number(userSettings?.drillEntitySelectionLimit);
        headerElements.push(
            <div
                className="second_header_row gap_between_buttons"
                style={{display: "flex", width: "100%", padding: "0.41667vw 0"}}
            >
                {renderDrillButton &&
                    <DrillButtonDropdown vectors={vectorState.vectorOptions?.filter(e=>!e.isGroupTitle)} onOptionClick={onDrillOptionClick} disabled={isDrillDisabled} tooltipMessage={drillTooltip}/>
                }
                {selectedEntities>0 && <div uk-tooltip={viewStackTooltip || "title:"}>
                        <Button
                            id="view_stack"
                            label={lang.header.placeholders.view_stack}
                            variant={BUTTON_VARIANT.PRIMARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}
                            disabled={isViewStackDisabled}
                            onBtnClick={viewStackClick}
                        />
                </div>
                }
                <div uk-tooltip={"title:" + (isDrilling ? lang.filter_not_supported_drill_mode : "")}>
                {userSettings?.useNewQuickFilters ? (
                    <QuickFilters
                        vectorOptions={vectorState.vectorOptions}
                        profitFormat={profitFormat}
                        userSettings={userSettings}
                        scenarioState={scenarioState}
                        filterFinal={filterFinal}
                        setQuickFilters={setQuickFilters}
                        switchBasicToAdvanced={switchBasicToAdvanced}
                        clearAdvancedFilters={clearAdvancedFilters}
                        filterDialRef={filterDialRef}
                        disabled={isDrilling}
                    />)
                    : (
                    <FilterByDropdown
                        ref={filterByRef}
                        sectionsData={copyObjectValues(sectionsData.data)}
                        clearClick={clearClick}
                        isClearBtnDisabled={sectionsData.isClearBtnDisabled}
                        handleCheckboxChange={handleCheckBoxChange}
                        basicFilterCountLimit={userSettings.basicFilterCountLimit}
                        switchBasicToAdvanced={switchBasicToAdvanced}
                        disabled={isDrilling}
                    />
                )}
                </div>
                <div uk-tooltip={"title:" + (isDrilling ? lang.filter_not_supported_drill_mode : "")}>
                <FilterDialog
                    id={"secondary_header_" + "list"}
                    ref={filterDialRef}
                    section_id={params.sectionId}
                    vectorOptions={vectorState.vectorOptions}
                    profitFormat={"list"}
                    mustHideFilter={false}
                    categoryToolkit={false}
                    onChangeFilter={onChangeFilter}
                    quadTierIsAllowed={true}
                    isTempScenario={false}
                    scenario_id={scenarioState.scenario}
                    mustFetchDatasets={false}
                    user={userSettings.user}
                    isDrilling={isDrilling}
                    fromOptions={datasetState.datasetOptions}
                    datasetOptions_id={datasetState.datasetOptions_id}
                    dataSet={datasetState.dataset}
                    useCookies={!isFromDashboards}
                    parentMessage={""}
                    psFilterDisabled={false}
                    quadrantsDisabled={false}
                    quadrantDefault={false}
                    checkIfMonthsValid={checkIfMonthsValid}
                    comparisonScenarioId={undefined}
                    filterDisplayEditable={true}
                    psLinesOptions={psLinesOptions}
                    quadrantTierOptions={quadrantTierOptions}
                    dispatch={dispatch}
                    savedFilterDataState={history?.location?.state?.savedFilterDataState || filterDialRef?.current?.state?.savedFilterData}
                    isRedirectionFromStacks={false}
                    savedFilter={true}
                    fromSecondaryHeader={true}
                    setFilterTo={setFilterTo}
                    useNewQuickFilters={userSettings.useNewQuickFilters}
                    filterFinal={filterFinal}
                    canSaveCompanyFilters={canSaveCompanyFilters}
                    // handleOverlayAndHeights={this.props.handleOverlayAndHeights}
                />
                </div>
                <div className="profit-stack-filter-container-div" style={{marginLeft: "auto"}}>
                    <div
                        className={"profit-stack-filter-container view-profit-stack-filter-container disabled-profit-stack-wrapper"}></div>
                </div>
                {isDrilling && manageColsAccess && !Object.keys(manageColsAccess).every((k) => !manageColsAccess[k]) && vectorState?.vectorOptions ?
                        <ManageColumns ref={manageColumnsDrillRef}
                            key={HEADER_ELEMENT.ADD_COLUMNS + "_"}
                            resetDrillVectorType = {reportRef.current?.pageComponent?.resetDrillVectorType}
                            scenarioId={scenarioState.scenario}
                            profitFormat={ALL_WIDGETS.FIELDS.LIST}
                            user={userSettings.user}
                            onToggleBoard={onToggleManageColsDrillBoard}
                            onSelectDefaultProfile={reportRef.current?.pageComponent?.selectProfile}
                            onSelectProfile={reportRef.current?.pageComponent?.selectProfile}
                            onChangeProfileData={reportRef.current?.pageComponent?.onApplyProfile}
                            manageColsAccess={manageColsAccess}
                            vectorObjects={vectorState.vectorObjects}
                            vectorOptions={vectorState.vectorOptions}
                            updateDrillProfile={reportRef.current?.pageComponent?.updateDrillProfile}
                            checkForLimitAccessMessage={checkForLimitAccessMessage}
                            vector={isDrilling && props?.history?.location?.state?.drillTier ? props.history.location.state.drillTier : vectorState.vectors[0]}
                            hideArrow={false}
                            id={"overlay-manage-columns-drill"}
                            // hideBoard={true}
                            updateManageColumnsProfile={updateManageColumnsProfile}
                            uncheckPSEntities={reportRef.current?.uncheckPSEntities}
                            outsideColumnProfileId={reportRef.current?.state.drillProfile ? reportRef.current?.state.drillProfile[COLUMN_PROFILE.ID] : undefined}
                            isDrilling={isDrilling}
                            drillProfile={reportRef.current?.state.drillProfile}
                            manageColumnsSelectionLimit={userSettings.manageColumnsSelectionLimit}
                            setDrillTabulatorPageSize={reportRef.current?.pageComponent?.setDrillTabulatorPageSize}
                            constraint={"9"}
                            dispatch={dispatch}
                            characterSizeLimit={userSettings.characterSizeLimit}
                            useNewReorderList={userSettings.useNewReorderList}
                            useAppearanceList={userSettings.useAppearanceList}
                            setColumnWidthState={reportRef.current?.pageComponent?.setColumnWidthState}
                            setDrillListWidthState={reportRef.current?.pageComponent?.setDrillListWidthState}
                            scenarioState={scenarioState}
                            useNewAddColumn={userSettings.useNewAddColumn}
                            profileColumns={profileColumns}
                            customGroups={customGroups}
                            profiles={profiles}
                            columnProfiles={columnProfiles}
                            stateProfiles={stateProfiles}
                        />
                    : ""}
                    <ButtonDropdown
                        id={"stacks-table-export-btn"}
                        ref={configureDropDownHeaderRef}
                        disabled={showGreyOverLay}
                        placeholder={lang.header.titles.xls_short}
                        className="export-button-table uk-margin-small-left"
                        // onBtnClick={this.showConfigureDialog}
                        dropId={"stack_table_export_dialog"}
                        variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
                        size={SIZES.SMALL}
                        firstAttr={"#button-drop-stacks-table-export-btn"}
                        secondAttr={"#stack_table_export_dialog"}
                        renderContainerBody={renderExportBody}
                        tooltip={lang.header.tooltips.export_format}
                    />
            </div>
        );

        if(!userSettings?.useNewQuickFilters) {
            headerElements.push(
                <div className='third_row_header' style={{ display: "flex", width: "100%" }}>
                    <div key={Math.random()} className={"noprint " + (displayFilter && ((filterFinalBasic?.length > 0 && filterFinalBasic !== "[]") || (filterFinal?.length > 0 && filterFinal !== "[]")) ? ("border-third-header ") : "") + "uk-display-block"}>
                        <AppliedFilters filterProps={filterFinalBasic} filterFinal={filterFinal} ref={appliedFiltersRef}
                            userAllowedVectors={userSettings.user?.user_allowed_vectors} basicFilterCountLimit={userSettings.basicFilterCountLimit}
                            handleCheckboxChange={handleBasicFilterCheckBoxChange} sectionsData={basicFilterEntities}
                            fetchData={filterOnBasicFilter} clearFilter={filterDialRef?.current?.clearFilter} vectors={vectorState.vectorOptions}
                            quadrantsDisabled={false}
                            psFilterDisabled={false}
                            parentMessage={""/*props.filterWarningMessage*/} saveFilter={filterDialRef?.current?.startSaveFilter}
                            clearBasicFilter={clearClick} clearFilterFromDialog={filterDialRef?.current?.clearFilter} forgetFilter={filterDialRef?.current?.handleOpenForgetFilterDialog}
                            className={isFilterDisabled ? "uk-disabled disabled" : ""} tooltip={"title:" + ("") + ";pos: top-left"} filterIsEditable={filterDialRef?.current?.state?.savedFilterData?.editable}
                            isDrilling={isDrilling}
                        />
                    </div>
                </div>
            );
        }

            headerElements.push(
                <ConfirmPasswordModal
                    ref={confirmPassRef} 
                    user={userSettings.user} 
                    manageColumnsProfile={isDrilling ? (manageColumnsDrillRef?.current?.state?.appliedProfile || manageColumnsDrillRef?.current?.state?.selectedProfile) : manageColumnsProfile}
                    isDrilling={isDrilling}
                    trackingData={trackingData} 
                    dataLength={dataLength} //userAuthenticated_exportAll={this.state.userAuthenticated_exportAll} 
                    pageComponent={reportRef?.current?.pageComponent} 
                    getPeriodsObject={getPeriodsObjectNew} 
                    isTempScenario={false} 
                    match={props.match}
                    vectors={vectorState.vectors} 
                    vectorOptions={vectorState.vectorOptions} 
                    profitFormat={profitFormat} 
                    scenarios={scenarioState.scenarios}
                    filterDialRef={filterDialRef?.current} 
                    exportAll={exportAll} 
                    filterFinal={filterFinal}  
                    exportQueryFilter={getExportQueryFilter}
                    exportRef= {exportRef} 
                    pssLines={manageColsRef?.current?.state?.profitStackLineColumns}
                    getExportValue={getExportValue} 
                    export_all={exportValue} 
                    isClientSSO={userSettings.isClientSSO} 
                    rollingSegment = {ROLLING_SEGMENTS.R_12}
                    periodsStatusState= {periodsStatusState}
                    clientPeriodsState = {clientPeriodsState}
                    datasetState = {datasetState}
                    drillFilter={JSON.stringify(drillFilter)}
                    exportScopeFilter={exportScopeFilter}
                />);
        
        return headerElements;
    }

    const renderHeaderFirstRow = () => {
        let additionalCrumb = manageColumnsProfile && manageColumnsProfile !== null && manageColumnsProfile?.name + (manageColumnsProfile?.is_modified ? _modified : "") || ""
        return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription, additionalCrumb)
    }

    const renderHeaderSecondRow = (isDropDownDisabled, startPeriodMnageCol, endPeriodManageCol) => {
        return (
            <>
                {
                    renderPeriodCalendar(periodsStatusState, handleElementChange, undefined, clientPeriodsState.allPeriods, toggleCalendar, isDropDownDisabled, scenarioState)
                }

                {/* <div key={Math.random()} className="template-report-title">by</div> */
                }
                <div key={Math.random()} uk-tooltip={isDropDownDisabled ? lang.exit_drill_to_proceed : "title:"}
                     className={"uk-flex uk-flex-column uk-flex-start"}>
                    <div key={Math.random()} className={isDropDownDisabled ? " disabled" : ""}>
                        <DimensionsDropdowns
                            ref={dimensionDropdownsRef}
                            disabled={reportTitle === ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT /*|| this.props.state.isBridgeReport || this.isProfitStack*/}
                            numberOfDimensions={1}
                            dimensionOptions={/*this.isGeographyConcentration ? vectorOptions?.filter(e=>e.vector_group === 'Customer') :*/ vectorState.vectorOptions}
                            vectorDisabled={vectorState.vectorDisabled || reportTitle === ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.BRIDGE_REPORT
                                /*|| props.state.isBridgeReport || this.isProfitStack || (this.props.isDrilling && this.props.isMainReport.isSecondDimension)*/}
                            excludeOptions={true}
                            onChangeDimension={(e, index, fromMount) => handleElementChange(HEADER_ELEMENT.VECTOR, e, fromMount, index, true)}
                            shouldFetchData={false}
                            selectedDimensions={vectorState.nextVectors}
                            startAsEmpty={false/*(this.isManageScenarios || this.isCompareScenarios) && !this.state.nextVectors.length*/}
                            dropdownTooltip={/*this.props.isDrilling && this.props.isMainReport.isSecondDimension ? lang.exit_drill_to_proceed :*/ undefined}
                            hideLabel={true}
                        />
                    </div>
                </div>
                <div
                    id="apply_div">{renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), '', '', apply, !showGreyOverLay)}</div>
                </>)

    }

    const renderHeaderThirdRow = (isViewStackDisabled, isDrillDisabled, renderDrillButton, viewStackTooltip, drillTooltip, canSaveCompanyFilters, sectionsData, startPeriodMnageCol, endPeriodManageCol) => {
        return (
            <>
                {renderDrillButton &&
                    <DrillButtonDropdown vectors={vectorState.vectorOptions?.filter(e => !e.isGroupTitle)}
                                         onOptionClick={onDrillOptionClick} disabled={isDrillDisabled}
                                         tooltipMessage={drillTooltip}/>
                }
                {selectedEntities > 0 && <div uk-tooltip={viewStackTooltip || "title:"}>
                    <Button
                        id="view_stack"
                        label={lang.header.placeholders.view_stack}
                        variant={BUTTON_VARIANT.PRIMARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        disabled={isViewStackDisabled}
                        onBtnClick={viewStackClick}
                    />
                </div>
                }
                <div uk-tooltip={"title:" + (isDrilling ? lang.filter_not_supported_drill_mode : "")}>
                    {userSettings?.useNewQuickFilters ? (
                            <QuickFilters
                                vectorOptions={vectorState.vectorOptions}
                                profitFormat={profitFormat}
                                userSettings={userSettings}
                                scenarioState={scenarioState}
                                filterFinal={filterFinal}
                                setQuickFilters={setQuickFilters}
                                switchBasicToAdvanced={switchBasicToAdvanced}
                                clearAdvancedFilters={clearAdvancedFilters}
                                filterDialRef={filterDialRef}
                                disabled={isDrilling}
                            />)
                        : (
                            <FilterByDropdown
                                ref={filterByRef}
                                sectionsData={copyObjectValues(sectionsData.data)}
                                clearClick={clearClick}
                                isClearBtnDisabled={sectionsData.isClearBtnDisabled}
                                handleCheckboxChange={handleCheckBoxChange}
                                basicFilterCountLimit={userSettings.basicFilterCountLimit}
                                switchBasicToAdvanced={switchBasicToAdvanced}
                                disabled={isDrilling}
                            />
                        )}
                </div>
                <div uk-tooltip={"title:" + (isDrilling ? lang.filter_not_supported_drill_mode : "")}>
                    <FilterDialog
                        id={"secondary_header_" + "list"}
                        ref={filterDialRef}
                        section_id={params.sectionId}
                        vectorOptions={vectorState.vectorOptions}
                        profitFormat={"list"}
                        mustHideFilter={false}
                        categoryToolkit={false}
                        onChangeFilter={onChangeFilter}
                        quadTierIsAllowed={true}
                        isTempScenario={false}
                        scenario_id={scenarioState.scenario}
                        mustFetchDatasets={false}
                        user={userSettings.user}
                        isDrilling={isDrilling}
                        fromOptions={datasetState.datasetOptions}
                        datasetOptions_id={datasetState.datasetOptions_id}
                        dataSet={datasetState.dataset}
                        useCookies={!isFromDashboards}
                        parentMessage={""}
                        psFilterDisabled={false}
                        quadrantsDisabled={false}
                        quadrantDefault={false}
                        checkIfMonthsValid={checkIfMonthsValid}
                        comparisonScenarioId={undefined}
                        filterDisplayEditable={true}
                        psLinesOptions={psLinesOptions}
                        quadrantTierOptions={quadrantTierOptions}
                        dispatch={dispatch}
                        savedFilterDataState={history?.location?.state?.savedFilterDataState || filterDialRef?.current?.state?.savedFilterData}
                        isRedirectionFromStacks={false}
                        savedFilter={true}
                        fromSecondaryHeader={true}
                        setFilterTo={setFilterTo}
                        useNewQuickFilters={userSettings.useNewQuickFilters}
                        filterFinal={filterFinal}
                        canSaveCompanyFilters={canSaveCompanyFilters}
                        // handleOverlayAndHeights={this.props.handleOverlayAndHeights}
                    />
                </div>
                <div className="profit-stack-filter-container-div" style={{marginLeft: "auto"}}>
                    <div
                        className={"profit-stack-filter-container view-profit-stack-filter-container disabled-profit-stack-wrapper"}></div>
                </div>
                {isDrilling && manageColsAccess && !Object.keys(manageColsAccess).every((k) => !manageColsAccess[k]) && vectorState?.vectorOptions ?
                    <ManageColumns ref={manageColumnsDrillRef}
                                   key={HEADER_ELEMENT.ADD_COLUMNS + "_"}
                                   resetDrillVectorType={reportRef.current.pageComponent.resetDrillVectorType}
                                   scenarioId={scenarioState.scenario}
                                   profitFormat={ALL_WIDGETS.FIELDS.LIST}
                                   user={userSettings.user}
                                   onToggleBoard={onToggleManageColsDrillBoard}
                                   onSelectDefaultProfile={reportRef.current.pageComponent.selectProfile}
                                   onSelectProfile={reportRef.current.pageComponent.selectProfile}
                                   onChangeProfileData={reportRef.current.pageComponent.onApplyProfile}
                                   manageColsAccess={manageColsAccess}
                                   vectorObjects={vectorState.vectorObjects}
                                   vectorOptions={vectorState.vectorOptions}
                                   updateDrillProfile={reportRef.current.pageComponent.updateDrillProfile}
                                   checkForLimitAccessMessage={checkForLimitAccessMessage}
                                   vector={isDrilling && props?.history?.location?.state?.drillTier ? props.history.location.state.drillTier : vectorState.vectors[0]}
                                   hideArrow={false}
                                   id={"overlay-manage-columns-drill"}
                        // hideBoard={true}
                                   updateManageColumnsProfile={updateManageColumnsProfile}
                                   uncheckPSEntities={reportRef.current.uncheckPSEntities}
                                   outsideColumnProfileId={reportRef.current.state.drillProfile ? reportRef.current.state.drillProfile[COLUMN_PROFILE.ID] : undefined}
                                   isDrilling={isDrilling}
                                   drillProfile={reportRef.current.state.drillProfile}
                                   manageColumnsSelectionLimit={userSettings.manageColumnsSelectionLimit}
                                   setDrillTabulatorPageSize={reportRef.current.pageComponent.setDrillTabulatorPageSize}
                                   constraint={"9"}
                                   dispatch={dispatch}
                                   characterSizeLimit={userSettings.characterSizeLimit}
                                   useNewReorderList={userSettings.useNewReorderList}
                                   useAppearanceList={userSettings.useAppearanceList}
                                   setColumnWidthState={reportRef.current?.pageComponent?.setColumnWidthState}
                                   setDrillListWidthState={reportRef.current?.pageComponent?.setDrillListWidthState}
                                   scenarioState={scenarioState}
                                   useNewAddColumn={userSettings.useNewAddColumn}
                                   profileColumns={profileColumns}
                                   customGroups={customGroups}
                                   profiles={profiles}
                                   columnProfiles={columnProfiles}
                                   stateProfiles={stateProfiles}
                    />
                    : ""}
                {
                    manageColsAccess && !Object.keys(manageColsAccess).every((k) => !manageColsAccess[k]) && vectorState?.vectorOptions ?
                        <ManageColumns
                            ref={manageColsRef}
                            key={HEADER_ELEMENT.ADD_COLUMNS}
                            scenarioId={scenarioState.scenario}
                            profitFormat={ALL_WIDGETS.FIELDS.LIST}
                            approveBulkChange={approveBulkChange}
                            user={userSettings.user}
                            onSelectProfile={updateManageColumnsProfile}
                            onSelectDefaultProfile={onSelectDefaultProfile}
                            onChangeProfileData={onChangeProfileData}
                            onToggleBoard={onToggleManageColsBoard}
                            manageColsAccess={manageColsAccess}
                            comparisonScenarioId={undefined}
                            vectorObjects={vectorState.vectorObjects}
                            sectionsProfilesAreValidFor={manageColumnsProps.manageColumnsUpdateSections}
                            hideDropdown={false/**isDrilling */}
                            vectorOptions={vectorState.vectorOptions}
                            checkForLimitAccessMessage={checkForLimitAccessMessage}
                            vector={vectorState.nextVectors[0]}
                            outsideColumnProfileId={props?.history?.location?.state ? props?.history?.location?.state?.columnProfileId : undefined}
                            report={reportTitle}
                            hideArrow={false}
                            hideManage={isDrilling}
                            updateManageColumnsProfile={updateManageColumnsProfile}
                            isCompareScenarios={false}
                            dataSet={userSettings.dataset}
                            dispatch={dispatch}
                            manageColumnsSelectionLimit={userSettings.manageColumnsSelectionLimit}
                            selectedRange={startPeriodMnageCol + (startPeriodMnageCol !== endPeriodManageCol ? " to " + endPeriodManageCol : "")}
                            selectedFilter={[]/*filterFinal*/}
                            profileColumns={profileColumns}
                            customGroups={customGroups}
                            profiles={profiles}
                            columnProfiles={columnProfiles}
                            stateProfiles={stateProfiles}
                            constraint={"5"}
                            characterSizeLimit={userSettings.characterSizeLimit}
                            useNewReorderList={userSettings.useNewReorderList}
                            useAppearanceList={userSettings.useAppearanceList}
                            setColumnWidthState={reportRef.current?.pageComponent?.setColumnWidthState}
                            setDrillListWidthState={reportRef.current?.pageComponent?.setDrillListWidthState}
                            scenarioState={scenarioState}
                            useNewAddColumn={userSettings.useNewAddColumn}
                        /> : ""
                }
                <ButtonDropdown
                    id={"stacks-table-export-btn"}
                    ref={configureDropDownHeaderRef}
                    disabled={showGreyOverLay}
                    placeholder={lang.header.titles.xls_short}
                    className="export-button-table"
                    // onBtnClick={this.showConfigureDialog}
                    dropId={"stack_table_export_dialog"}
                    variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
                    size={SIZES.SMALL}
                    firstAttr={"#button-drop-stacks-table-export-btn"}
                    secondAttr={"#stack_table_export_dialog"}
                    renderContainerBody={renderExportBody}
                    tooltip={lang.header.tooltips.export_format}
                    mode={"dark"}
                />
            </>
        )
    }

    const getNewHeaderElements = () => {
        let sectionsData = getFilterBySectionsData();

        let isDropDownDisabled = false;
        let headerElements = [];

        let startPeriodMnageCol = getPeriodFromDate(new Date(periodsStatusState.customStartDate));
        let endPeriodManageCol = getPeriodFromDate(new Date(periodsStatusState.customEndDate));

        headerElements.push(
            <div style={{ display: "flex", width: "100%", alignItems: "center" }} className='new_header_menu first_header_row gap_between_buttons'>
                {renderHeaderFirstRow()}
            </div>);

        const viewStack = ({ label, isDisabled, tooltipText }) => (
            <div id={label} className={"option-padding uk-flex-between uk-display-flex"} disabled={isDisabled} uk-tooltip={!!tooltipText ? tooltipText : ""}> {label} </div>
        );
        let cardsDataLength = reportRef.current?.pageComponent?.state?.cardsData?.length

        let isViewStackDisabled = reportRef.current?.pageComponent?.state?.isCheckboxDisabled;
        let viewStackTooltip = isViewStackDisabled? lang.COMMON.profit_stack_not_supported_three_vectors: "";

        let rowData = reportRef.current?.pageComponent?.tabulatorList?.current?.state?.cell?.getRow().getData() || reportRef.current?.pageComponent?.tabulatorDrill?.current?.state?.cell?.getRow().getData();
        let selectedVectorWithNoKeyHasNA = rowData && (!rowData[PSL_RETURN_NAMES.NAME] && !rowData[PSL_RETURN_NAMES.NUMBER] && (rowData[PSL_RETURN_NAMES.QUADRANT] === "N/A" || rowData[PSL_RETURN_NAMES.QUADRANT_TIER] === "N/A"));
        let atLeastOneVectorOtherThanSelectedHasNA = rowData && atLeastOneVectorHasNAInData(rowData, vectorState?.vectorOptions);

        let drillLimitExceeded = cardsDataLength && (cardsDataLength >= Number(userSettings?.drillSelectionLimit));
        let isDrillDisabled = atLeastOneVectorOtherThanSelectedHasNA || selectedVectorWithNoKeyHasNA || drillLimitExceeded;
        let drillTooltip = isDrillDisabled? drillLimitExceeded? lang.drill_limit_exceeded : lang.COMMON.drill_not_supported_list_q :"";
        let canSaveCompanyFilters = getSectionExists(userAllowedMenuLinks, SAVE_COMPANY_FILTERS);
        let renderDrillButton = selectedEntities >0  && selectedEntities <=  Number(userSettings?.drillEntitySelectionLimit);
        headerElements.push(
            <div
                className="new_header_menu second_header_row gap_between_buttons"
                style={{display: "flex", width: "100%", padding: "0.41667vw 0"}}
            >
                {renderHeaderSecondRow(isDropDownDisabled)}
            </div>
        );

            headerElements.push(
                <div className='new_header_menu third_row_header gap_between_buttons' style={{ display: "flex", width: "100%" }}>
                    {renderHeaderThirdRow(isViewStackDisabled, isDrillDisabled, renderDrillButton, viewStackTooltip, isViewStackDisabled, drillTooltip, canSaveCompanyFilters, sectionsData, startPeriodMnageCol, endPeriodManageCol)}
                </div>
            );

        headerElements.push(
            <ConfirmPasswordModal
                ref={confirmPassRef}
                user={userSettings.user}
                manageColumnsProfile={isDrilling ? (manageColumnsDrillRef?.current?.state?.appliedProfile || manageColumnsDrillRef?.current?.state?.selectedProfile) : manageColumnsProfile}
                isDrilling={isDrilling}
                trackingData={trackingData}
                dataLength={dataLength} //userAuthenticated_exportAll={this.state.userAuthenticated_exportAll}
                pageComponent={reportRef?.current?.pageComponent}
                getPeriodsObject={getPeriodsObjectNew}
                isTempScenario={false}
                match={props.match}
                vectors={vectorState.vectors}
                vectorOptions={vectorState.vectorOptions}
                profitFormat={profitFormat}
                scenarios={scenarioState.scenarios}
                filterDialRef={filterDialRef?.current}
                exportAll={exportAll}
                filterFinal={filterFinal}
                exportQueryFilter={getExportQueryFilter}
                exportRef= {exportRef}
                pssLines={manageColsRef?.current?.state?.profitStackLineColumns}
                getExportValue={getExportValue}
                export_all={exportValue}
                isClientSSO={userSettings.isClientSSO}
                rollingSegment = {ROLLING_SEGMENTS.R_12}
                periodsStatusState= {periodsStatusState}
                clientPeriodsState = {clientPeriodsState}
                datasetState = {datasetState}
                drillFilter={JSON.stringify(drillFilter)}
                exportScopeFilter={exportScopeFilter}
            />);

        return headerElements;
    }

    /**
     * @function handleElementChange()
     * function is called whenever any change in the header elements is done, it's send as props to each component rendered in {@link ReportHeader} from getHEadereElements function
     * @param {String} name used to identify the name of the element changing @example  name=HEADER_ELEMENT.SELECTION_RANGE
     * @param {*} e
     * @param {Boolean} fromMount
     * @param {Number} dimIndex
     */
    const handleElementChange = (name, e, fromMount = false, dimIndex=0)=> {
        switch(name){
            case HEADER_ELEMENT.SELECTION_RANGE: {
                let selectedYears = e.filter(year => year.isSelected);
                let tempState = copyObjectValues(periodsStatusState);
                if (selectedYears.length > 0) {
                    let periods = getLocalStorageValueByParameter("periods")? JSON.parse(getLocalStorageValueByParameter("periods")): [];
                    let sortedSelectedYears = selectedYears.map(p => p.year + p.value).sort();
                    let startPeriod = sortedSelectedYears[0];
                    let endPeriod = sortedSelectedYears[sortedSelectedYears.length - 1];
                    let startDate = new Date(periods.filter(e=>e.value === startPeriod)[0].start_date);
                    let endDate = new Date(periods.filter(e=>e.value === endPeriod)[0].end_date);
                    let isReportPSSMapping =false; // _this.props.state.stagingReport === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW || _this.props.state.stagingReport === ALL_WIDGETS.PS_MAPPING_MENU;
                    // if(isReportPSSMapping && this.state.onMount){
                    //     tempState.onMount = false;
                    // }
                    // if (_this.isVectorAnalysis && !_this.validatePeriodRange(startDate, endDate)) {
                    //     _this.setInfoDialogOpen(true, lang.insufficient_periods_selection)
                    //     return;
                    // } else if ((_this.props.state.stagingReport === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW || _this.props.state.stagingReport === ALL_WIDGETS.PS_MAPPING_MENU) && getPeriodDifference(startPeriod, endPeriod) > 12) {
                    //     _this.setState({periodsExceeded: true})
                    //     _this.setInfoDialogOpen(true, lang.not_allowed_periods_selection);
                    //     return;
                    // }
                    // _this.setState({periodsExceeded: false})
                    tempState.nextCustomStartDate = startDate;
                    tempState.nextCustomEndDate = endDate;
                    tempState.customStartDate = startDate;
                    tempState.customEndDate = endDate;
                    if (reportTitle !== ALL_WIDGETS.FIELDS.TOTAL_STACKS && !reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(ALL_WIDGETS.FIELDS.COMPARE_STACKS)) {
                        saveCookie("nextCustomStartDate", startDate);
                        saveCookie("nextCustomEndDate", endDate);
                    }
                    setPeriodsStatusState(tempState);
                    setShowGreyOverLay(true);
                    if (selectedEntities > 0) {
                        setSelectedEntities(0);
                    }
                    setIsDrillingFunc(false);
                    // if (_this.props.state.stagingReport === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW || _this.props.state.stagingReport === ALL_WIDGETS.PS_MAPPING_MENU) {
                    //     tempState.customStartDate = startDate;
                    //     tempState.customEndDate = endDate;
                    //     tempState.oldCustomStartDate = _this.state.customStartDate;
                    //     tempState.oldCustomEndDate = _this.state.customEndDate;
                    // }
                }
                break;
            }
            case HEADER_ELEMENT.VECTOR: {
                var vectorValue = typeof e === "object" ? e.value : e;
                let tempState = copyObjectValues(vectorState);
                saveCookie(HEADER_ELEMENT.VECTOR, vectorValue);
                if(periodsStatusState.nextCustomStartDate && periodsStatusState.nextCustomEndDate && 
                    (reportTitle !== ALL_WIDGETS.FIELDS.TOTAL_STACKS && 
                        (reportTitle && (!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(ALL_WIDGETS.FIELDS.COMPARE_STACKS))))) {
                    saveCookie("nextCustomStartDate", periodsStatusState.nextCustomStartDate);
                    saveCookie("nextCustomEndDate", periodsStatusState.nextCustomEndDate);
                }
                
                tempState.nextVectors = copyObjectValues(vectorState.nextVectors);
                tempState.nextVectors[dimIndex] = vectorValue;
                tempState.vectors = copyObjectValues(vectorState.nextVectors);
                tempState.vectors[dimIndex] = vectorValue;
                tempState.vectorObjects = tempState.vectors.map(v=>{
                    return findOptionByKeyValue(tempState.vectorOptions, "value", v);
                });
                setVectorState(tempState);
                setShowGreyOverLay(true);
                if (selectedEntities > 0) {
                    setSelectedEntities(0);
                }
                setIsDrillingFunc(false);

                break;
            }
            case HEADER_ELEMENT.CUSTOM_VIEW:
            case HEADER_ELEMENT.VIEW_STACK: {
                let tempState = copyObjectValues(PSViewsState);
                tempState.custom_stack_id = e.value;
                tempState.current_stack_id = e.value;
                tempState.nextProfitStackViewId = e.value;
                tempState.profitStackViewId = e.value;
                setPSViewsState(tempState);
                window._pi_initialiseTracking(UI_ACTIONS.PROFIT_STACK, userSettings.user.email, userSettings.machine_name, profitFormat, false);
                // if(!fromMount && this.isBase) { //removing this condition would make the Back btn not working
                    //!fromMount cz in fetchListsCallback handleElementChange is called for custom view, we dont want to reload in callback
                    //this.isBase cz if in PSS, already handled in handleHeaderElementChangeCallback, do not push more URLs to history for nothing
                    // callback = ()=> {
                runExtendedProfitStackFunc(name, tempState,reportRef.current.pageComponent.state.allVectorsSelected, vectorState.nextVectors[0]); //redirect to entity stacks screen
                    // }
                // }
                break;
            }
            default: break;
        }
        let headerChangedCounterState = headerChangedCounter;
        setHeaderChangedCounter(headerChangedCounterState+1);
    }

    /**
     * @function setInfoDialogOpen
     * @param {Boolean} isOpen 
     * @param {String} infoMsg 
     */
    const setInfoDialogOpen = (isOpen, infoMsg) => {
        setOpenInfoDilog(isOpen);
        setInfoDialogMsg(infoMsg);
    }

    /**
     * @function runApplyValidations()
     * @returns false if selection of periods is more than 1 year
     */
    const runApplyValidations = () => {
        if (getPeriodDifference(getPeriodFromDate(periodsStatusState.customStartDate), getPeriodFromDate(periodsStatusState.customEndDate)) > 12) {
            setInfoDialogOpen(true, lang.not_allowed_periods_selection)
            return false;
        }
        return true;
      }
    /**
     * @function apply()
     * calls go in report if applyValidations is true and sets isDrilling and greyOVerlay to false
     * @returns 
     */
    const apply = ()=>{
        if (!runApplyValidations()) {
            return;
        }
        let isDrillingValue = isDrilling;
        if (showGreyOverLay) {
            setIsDrilling(false);
            isDrillingValue = false;
        }
        reportRef?.current.go(isDrillingValue);
        setShowGreyOverLay(false);
    }

    /**Export Related functionalities */
    /**
     * @function setExportOptionsForUser()
     * @param {*} hideExportOnList if true thn removes exportExcel option from list
     */
    const setExportOptionsForUser = (hideExportOnList) => {
        let exportOptions = hideExportOnList ? [] : [{
            value: "export_excel",
            label: lang.header.titles.export_to_excel,
            tooltip: lang.header.tooltips.export_all_excel,
            description:lang.header.titles.export_selected_visible_rows,
            click: () => {tablesToExcelWrapper(); setExportAll(false, true);}
        }];
        var hasExportCSVAccess = getSectionExists(userAllowedMenuLinks, MENU_ITEM.FIELDS.EXPORT_DATA_TO_CSV);
        if (hasExportCSVAccess) {
            exportOptions.push({
                value: ALL_WIDGETS.EXPORT_ALL,
                label: lang.header.titles.export_to_csv,
                tooltip: lang.header.tooltips.csv,
                description:lang.header.titles.export_selected_rows_csv,
                click: () => {setExportAll(false)}
            });
            exportOptions.push({
                value: ALL_WIDGETS.EXPORT_CSV_PSL,
                label: lang.header.titles.csv_all_psl,
                tooltip: lang.header.tooltips.csv_all,
                description:lang.header.titles.export_all_csv,
                click: () => {setExportAll(true)}
            });
        }
        setExportOptions(exportOptions);
    }
    /**
     * @function setExportAll()
     * @param {String} value hold value chosen from export dropdown 
     * @param {Boolean} isExportExcel true if exportExcel selected
     */
    const setExportAll=(value, isExportExcel) => {
        setExportValue(isExportExcel ? undefined :value);
        exportRefVal.current = value;
        if(!isExportExcel){
            // exportAll();
        }
        configureDropDownHeaderRef.current.setConfigDropdownOpen(false);
    }

    useEffect(() => {
      if(exportValue !== undefined  && headerChanged){
        exportAll();
      }
    }, [headerChanged]) 

    const getExportValue = () => {
      return exportRefVal?.current;
    }

    const tablesToExcelWrapper = () => {
        tablesToExcel(reportTitle, userSettings, vectorState, datasetState, scenarioState, reportRef, getExportQueryFilter);
    }

    const showError = () =>{
        setMessage(lang.header.custom_text.error);
        setError(lang.header.custom_text.error);
        launchExportToast();
        setHeaderChanged(false);
        setExportValue(undefined);
    }
    /**
     * @function exportAll()
     * function called when export csv or export csv include all psl values are selected from export dropdown
     */
    const exportAll = () => {
        let useOutputService = userSettings.useOutputService;
        if(reportRef.current.pageComponent?.tabulatorList?.current?.tabulator?.getData()?.length===0){
            return;
        }
        window._pi_initialiseTracking(UI_ACTIONS.EXPORT_ALL, userSettings.user.email, userSettings.machine_name, profitFormat, false);
        confirmPassRef.current.setState({
            userAuthenticated_exportAll: false,
        });
        setMessage(lang.header.custom_text.download_start);
        setError(undefined);
        try { //export
            launchExportToast();
            let query = $("#export-all-form").serializeArray().reduce(function(obj, item) {
                obj[item.name] = item.value;
                return obj;
            });
            query.numericFormatName = window._format?.numeric_format_name
            if(isDrilling && drillFilter && query.filter && typeof query.filter === "string" && typeof drillFilter === "object"){
                query.filter = encodeURIComponent(query.filter);
            }
            let onThenCallback = function (blob) {
                if (blob?.size === 0) {
                    showError();
                    return;
                }
                if(useOutputService) { // this means we are using output-service export logic
                    $("#frame_export").attr('src', blob.signedUrl);
                } else {
                    triggerExportData(blob, query, userSettings);
                }
                setHeaderChanged(false);
                setExportValue(undefined);
            }
            let onCompleteCallback = function () {
                setHeaderChanged(false);
                setExportValue(undefined);
            }

            let onErrorCallback = function () {
                showError();
            }
            let periodsObject = getPeriodsObjectNew(periodsStatusState, clientPeriodsState, datasetState);
            let periods = periodsObject.periods;
            let options = {
                [FETCHAPI_PARAMS.funcName]: "exportAll",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.path]: API_URL.PROFIT_LANDSCAPE_EXPORT_ALL,
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
                [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
                [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
                [FETCHAPI_PARAMS.onErrorCallback]: onErrorCallback,
                [FETCHAPI_PARAMS.useBlob]: !useOutputService,
                [FETCHAPI_PARAMS.screenName]: lang.observability.output.list.screen_name,
                [FETCHAPI_PARAMS.requestDescription]: (isDrilling ? lang.observability.output.list.requests_description.export_drill: lang.observability.output.list.requests_description.export) + (exportValue? lang.observability.output.list.requests_description.with_all_psls:""),
                [FETCHAPI_PARAMS.vector]: isDrilling ? reportRef?.current?.pageComponent.state.drillTier : vectorState.vectors[0],
                [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
                [FETCHAPI_PARAMS.periods]: periods.join(","),
            }
            fetchAPI(options);
        } catch (err) {
            showError();
        }
        //removed because there is nothing else to do for this action, tracking continued on the API
        window._pi_removeTrackingData();  
    }    

    /**
     * @function launchExportToast
     *  !!should be moved to a global file
     */
    const launchExportToast = () => {
        $("#toastExport").addClass("show");
        setTimeout(function(){
            $("#toastExport").removeClass("show");
        }, 4000);
    } 

    /**ManageColumns Related functiontions */ 
    const onToggleManageColsBoard = () => {
        manageColsOverlay.current.classList.toggle("uk-hidden");
    }

    /**ManageColumns Related functiontions */
    const onToggleManageColsDrillBoard = () => {
        manageColsDrillOverlay.current.classList.toggle("uk-hidden");
    }

    /**
     * @function onSelectDefaultProfile()
     * @param {Object} profile profile selected by default in manageColumns
     * @param {*} callback function to be executed then updating profile
     * called from {@link ManageColumns} when the  profile is selected by default on initialization 
     */
    const onSelectDefaultProfile = (profile, callback) => {
      var isModified = false;
      if(manageColumnsProfileFromRedux) {
        isModified = manageColumnsProfileFromRedux.is_modified;
      }
      profile.is_modified = isModified;
      updateManageColumnsProfile(profile, callback);
    }

    /**
     * @function onChangeProfileData()
     * @param {Object} profile profile selected by user or clicking on apply in managecolumna
     * @param {*} callback function to be executed then updating profile
     * called from {@link ManageColumns} when any profile is selected or apply is clicked 
     */
    const onChangeProfileData = (profile, callback) => { 
        updateManageColumnsProfile(profile, callback);
        setCallGo(true);
    }


    /**
     * @function updateManageColumnsProfile()
     * @param {*} profile 
     * @param {*} callback 
     * called from onSelectDefaultProfile and onChangeProfileData to update profile sent as param and executing function sent as param
     */
    const updateManageColumnsProfile = (profile, callback) => {
        let manageColumnsProfile = copyObjectValues(profile);
        manageColumnsProfile.is_modified = profile.is_modified;
        manageColumnsProfile.is_applied = profile.is_applied;
        manageColumnsProfile.name = profile.name;
        manageColumnsProfile.label = profile.name;
        manageColumnsProfile.simplified_columns = profile.simplified_columns || profile.columns;
        setManageColumnsProfile(manageColumnsProfile);
        if (callback) callback();
    }

    const saveDrillVectorType=(type)=> {
        setDrillVectorType(type);
    }

    const approveBulkChange = (key, element) => {
        if(key === PROFILE_COLUMN.IS_CHECKED) {
            let elementCosttype = element[PSS.DUPLICATE_KEYS.COSTTYPE];
            if(elementCosttype === costtype.calculated) {
                //if calculated, allow only if not percentage
                return ALL_FORMATS[element[PSS.FORMAT_TYPE_ID]] !== FormatTypes.PERCENTAGE;
            } else {
                return ![costtype.attribute, costtype.medians, costtype.ratios, costtype.totalratios, costtype.count].includes(elementCosttype);
            }
        }
    }

    const reselectProfile = () => {
        manageColsRef?.current?.setSelectedProfile(manageColumnsProfile); // to select the selected list in list screen when exiting th drill
        if(sessionStorage.getItem(_selectedListProfile)) { // set checkedItems to the original list selected items
            manageColsRef?.current?.setState({
                checkedItems: JSON.parse(sessionStorage.getItem(_selectedListProfile)).simplified_columns
            })
        }
    }
   
    /**Drill Related Functions */
    const setIsDrillingFunc = (isDrilling) => {
        setIsDrilling(isDrilling);

        const currentState = history.location.state || {};
        const newState = { ...currentState };
        newState.isListDrilling = isDrilling;
        history.push({state: newState});

        if(!isDrilling) {
          sessionStorage.removeItem(SELECTED_DRILL_LIST); //Remove drill list from session when !isDrilling
          saveDrillFilter({});
        }

    }

    const exitDrill = () => {
        setIsDrilling(false);
        reportRef?.current?.pageComponent.resetDrillVectorType();
        reportRef?.current?.onBackNavigation();
    }

    const saveDrillFilter = (filter, df) => {
      drillFilterRef.current = filter;
      setDrillFilter(filter);
      setOriginalDrillFilter(df);
    }

    /**Filter Related Functions */
    /**
     * @function getExportQueryFilter()
     * @param {Object} additionalFilter 
     * @returns if additional filter is added mainly @example from drill then it's formatted and returned 
     */
     const getExportQueryFilter = (additionalFilter) =>{
        if (additionalFilter?.filter && typeof additionalFilter?.filter != "function"){
            additionalFilter=additionalFilter.filter;
        }
        return additionalFilter? additionalFilter.find(f => !f.isBasicFilter) ? formatAdvancedFilter(additionalFilter, userSettings.user?.user_allowed_vectors, datasetState.datasetOptions, vectorState.vectorOptions, filterDialRef?.current?.state?.psLinesOptions) : formatBasicFilter(additionalFilter, userSettings?.user?.user_allowed_vectors)
        :exportQueryFilter;
    }

    const switchBasicToAdvanced = (filters) =>{
        if(filterByRef?.current){
            filterByRef.current.handleClose();
        }
        
        if(!filterFinal) {
            return;
        }
        
        let filterStr = filterFinal;
        let filter = typeof filterStr === "string" ? JSON.parse(filterStr) : (filterStr.filter && typeof filterStr.filter !== "function"? filterStr.filter : filterStr); 

        if(filters && userSettings?.useNewQuickFilters) {
            filter = filters
        } 

        filter = addCorrespondingParenthesisToFilter(filter);
        if(filter.find(e=>e.isBasicFilter)){
            filterDialRef?.current?.onChangeSavedFilter(filter, false, true);
        }
        
    }
    /**
     * @function onChangeFilter()
     * @param {*} filterStr 
     * @param {Boolean} afterDrilling 
     * @param {Boolean} applyFilter 
     * @param {Boolean} isClear 
     * @param {Boolean} isSwitchBasic 
     * @param {String} filterDisplayName 
     */
    const  onChangeFilter=(filterStr, afterDrilling, applyFilter=false, isClear, isSwitchBasic, filterDisplayName)=> {
        let filter = typeof filterStr === "string" ? JSON.parse(filterStr): filterStr;
        // on changing advanced saved filter its name should be removed if cookie has no saved filter since we remove the saved filter from cookie when we do a change on it
        if (!filterDisplayName && (getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === null || !getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) || getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === "undefined")){
            filter.map((item)=>{item.filter_display_name = undefined });
        }

        if (isSwitchBasic) {
            filter.map(function(item){item.isBasicFilter = false});
        } 
        let tempState = {};
        
        tempState.filterFinal = typeof filter !== "string" ? JSON.stringify(filter) : filter;

        if(isClear){
            filter="[]";
            tempState.filterFinalBasic =  [];
        }else if (!filter.find(f => !f.isBasicFilter)){
            tempState.filterFinalBasic =  copyObjectValues(filter).filter(e=>e.field === "number"); //from selecting a saved basic filter
            let filterObjs = copyObjectValues(filter);
            const unique = [...new Set(filterObjs.map(item => item.vector))]; // [ 'A', 'B']
            for (var e in unique) {
                getFilterEntities({value:unique[e]}, vectorState, setBasicFilterEntities, basicFilterEntitiesState, profitFormat, userSettings, scenarioState, tempState.filterFinal , getCountRef, setCount, setCountRef)
            }
        }else if(applyFilter){ // from apply in advanced
            tempState.filterFinalBasic =  [];
        }
        tempState.tempFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
        let currState = history.state;

        if(!applyFilter) {
            let tempState2 = {tempFilter: filterStr};    //this state represents the filter displayed in the header but not necessarily applied on the data (pending apply())
            if(!isSwitchBasic){
                tempState2.filterFinalBasic = tempState.filterFinalBasic;
                setFilterFinalBasic(tempState2.filterFinalBasic);
            }
            setTempFilter(filterStr);
            return;
        }
        // _this.resetLimit();
        //check if filter contains quadrant or quadrant/tier filter
        tempState.isAdvancedFilterApplied = isClear? false : true;
        tempState.exportQueryFilter = filterStr === "[]" ? undefined : formatAdvancedFilter(JSON.parse(filterStr), userSettings.user?.user_allowed_vectors, datasetState.datasetOptions, vectorState.vectorOptions, filterDialRef?.current?.state?.psLinesOptions);
        setFilterFinal(tempState.filterFinal);
        setFilterFinalBasic(tempState.filterFinalBasic);
        setExportQueryFilter(tempState.exportQueryFilter);
        setIsAdvancedFilterApplied(tempState.isAdvancedFilterApplied);
        setTempFilter(tempState.tempFilter);
        if(isClear){
            filterStr="[]";
        }
        
        let state = history.location?.state;
        if(!isFromDashboards && state){
          state.outsideFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
          state.mainFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
          state.filter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
          state.filterFinal = typeof filter !== "string" ? JSON.stringify(filter) : filter;
          state.originalMainFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
          history.push({
            state: state
          })
          setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filter));
        }
        
        if (!showGreyOverLay) {
            // apply();
            setCallGo(true);

        }
    }
    /**
     * @function setCountRef()
     * @param {*} count 
     */
    const setCountRef= (count) => {
        countRef.current = count;
    }

    /**
     * @function getCountRef()
     * @returns count in reference
     */
    const getCountRef = () => {
        return countRef.current;
    }

    /**
     * @function getFilterBySectionsData()
     * @returns object with data containing vectors in itemslist along with checked flag to dterming if vector is checked and returns isClearBtnDisabled determined as true no vector is checked
     */
    const getFilterBySectionsData = () => {
        let isClearBtnDisabled = false;
        let filterFinalBasicState = filterFinalBasic || [];
        let vectors  = vectorState.vectorOptions?.filter(f => f.entityCount <= userSettings.filterVectorCountThreshold || f.isGroupTitle) // remove vectors with entityCount <= threshold and that are not group title;
        if (vectors && filterFinalBasicState) {
            filterFinalBasicState = typeof filterFinalBasicState === "object" ? filterFinalBasicState : JSON.parse(filterFinalBasicState);
            filterFinalBasicState = Array.isArray(filterFinalBasicState) ? filterFinalBasicState : filterFinalBasicState.filter;
            isClearBtnDisabled = filterFinalBasicState.length === 0;
            vectors = vectors.map((item) => ({
                ...item,
                checked: !item.isGroupTitle && filterFinalBasicState.some((e) => e.vector === item.value) // .some() checks if any of the element.vector === item.value
            }));
        }

        // Add suggested section if we have nextVectors and nextVectors' entity count < threshold
        let sectionsJson = [];
        let quarters = [];
        var startQuarter = datasetState.nextStartQuarter?.split("Q")[1];
        var endQuarter =  datasetState.nextEndQuarter?.split("Q")[1];
        var startYear =  datasetState.nextStartQuarter?.split("Q")[0];
        var endYear =  datasetState.nextEndQuarter?.split("Q")[0];
        quarters = generateQuarter(Number(startQuarter), startYear, Number(endQuarter), endYear, true);
        vectors =  vectors; // add isDisabled to vectors that are not generated

        let suggestedVectors = vectors?.filter(f => vectorState.nextVectors?.includes(f.value) && !f.isGroupTitle && !f.isDisabled); // get nextVectors as jsonObject 
        suggestedVectors = suggestedVectors?.filter(f => f.entityCount <= userSettings.filterVectorCountThreshold); // checks if header vectors' entity count <= thereshold
        let hasSuggested = suggestedVectors?.length > 0 ;
        if (hasSuggested) {
            sectionsJson.push({
                sectionTitle: lang.suggested,
                itemsList: suggestedVectors,
            });
        }

        sectionsJson.push({
            sectionTitle: lang.all,
            itemsList: vectors,
        })
        return {data: sectionsJson, isClearBtnDisabled: isClearBtnDisabled};
    }

    /**
     * @function handleCheckBoxChange() 
     * called from {@link FilterByDropdown} when a vector is checked
     * @param {*} item 
     */
    const handleCheckBoxChange=(item)=>{ // TODO: to be removed when we remove useNewQuickFilters flag
        let filterFinalArr = filterFinal !== "[]" && typeof filterFinal === "string" ? JSON.parse(filterFinal) : filterFinal; // when filterFinal = ""[]"", remove the extra ""
        let isAdvancedFilterApplied = filterFinalArr && filterFinalArr !=="[]" && (typeof filterFinalArr === "object"? filterFinalArr.find(e=>!e.isBasicFilter): JSON.parse(filterFinalArr).find(e=>!e.isBasicFilter));
        if(isAdvancedFilterApplied){
            setBasicFilterItem(item);
            openFilterModal();
        }else{
            handleBasicFilterCheckBoxChange(item);
        }
    }

    /**
     * @function filterOnBasicFilter()
     * function called from {@link AppliedFilters} when clicking to apply a basic filter
     * @param {*} basicAppliedFilter 
     */
    const filterOnBasicFilter=(basicAppliedFilter)=>{
        let filters = copyObjectValues(basicAppliedFilter.filter(e=>e.entities.length));
        filters?.forEach(e=>{
            e.isBasicFilter = true;
            e.filter_display_name = undefined;
            e.entities = e.entities.map(ent=>{ent.value = ent.number; ent.label = ent.number; return ent})
        });
        let filtersByName = copyObjectValues(filters);
        filtersByName?.forEach(e=>{
            e.field = "name";
            e.entities = e.entities.map(ent=>{ent.value = ent.name; ent.label = ent.name; return ent})
            filters.push(e);
        });

        if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) { 
            localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
            localStorage.removeItem(CURRENT_FILTER_COOKIE);
            setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
        }
        let filterFinalBasic = copyObjectValues(basicAppliedFilter);//typeof _this.state.filterFinalBasic === "string" ? JSON.parse(_this.state.filterFinalBasic) : _this.state.filterFinalBasic;
        if(filterDialRef?.current) {
            filterDialRef?.current?.setState({
                savedFilterData: {}
            })
        }
       
        if(filterDialRef?.current?.savedFilterNameInput){
            filterDialRef.current.savedFilterNameInput.value = ""
        }
        filterFinalBasic?.forEach(e=>{
            e.filter_display_name = undefined;
        });

        filters = addCorrespondingParenthesisToFilter(filters);
        if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) { 
            localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
            localStorage.removeItem(CURRENT_FILTER_COOKIE);
            setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
        }
        setFilterFinal(JSON.stringify(filters));
        setFilterFinalBasic(filterFinalBasic)
        setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
        if (!showGreyOverLay) {
            setCallGo(true);
            // apply();
        }
    }

    /**
     * @function handleBasicFilterCheckBoxChange()
     * function called when entities checked in basic filters
     * @param {*} item 
     * @param {*} clear 
     * @param {*} entity 
     * @param {*} allEntities 
     */
    const handleBasicFilterCheckBoxChange=(item,clear,entity,allEntities)=>{ // TODO: to be removed when we remove useNewQuickFilters flag
        let tempState = {};
        let filter = typeof filterFinalBasic === "string" ? JSON.parse(filterFinalBasic) : filterFinalBasic;
        let initialFilter = copyObjectValues(filter);

        filter = filter || [];
        let removedLine = [];
        let isClear = false;
        let isUnCheck = false;
        if (entity && item) {
            for (let e in filter) {
                if (filter[e].vector === item.vector) {
                    if (entity.checked === true){
                        filter[e].entities.push(entity)
                    }else{
                        filter[e].entities = filter[e].entities.filter(f => f.value !== entity.value)
                    }
                }
            }
        } else if(item && allEntities){
            for (let e in filter) {
                if (filter[e].vector === item.vector) {
                    for(let j in allEntities){
                        filter[e].entities.push(allEntities[j]);
                    }
                }
            }
        }else if (item) {
            if(filter?.find(e=>e.vector === item.value)){
                removedLine = filter.find(e=>e.vector === item.value);
                filter = filter.filter(e=>e.vector !== item.value);
                delete basicFilterEntities[item.value]
                isUnCheck = true;
            }else{
                let selectedEmptyFilter = getNewEntityFilter(item.value, FILTER.VALUES.FIELD.NUMBER, [], FILTER.VALUES.FILTER_ROW_TYPE.FILTER, lang.ui_filter.dropdowns.and);
                selectedEmptyFilter.vectorLabel = item.label;
                filter?.push(selectedEmptyFilter);
            }
           
        } else if (clear) {
            filter = [];
            tempState.filterFinal = [];
            isClear = true;
            if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
                localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
                localStorage.removeItem(CURRENT_FILTER_COOKIE);
                tempState.exportQueryFilter = formatBasicFilter(filter, userSettings.user?.user_allowed_vectors);
                setExportQueryFilter(tempState.exportQueryFilter);
            }
        } 
        filter = addCorrespondingParenthesisToFilter(filter);
        if(isUnCheck && removedLine.entities.length>0){
            tempState.filterFinalBasic = filter;
            let copyFilter = copyObjectValues(filter);
            copyFilter.forEach(e=>{
                if(e.entities){
                    e.isBasicFilter = true;
                    e.entities = e.entities.map(ent=>{ent.value = ent.number; ent.label = ent.number; return ent})
                }
            });
            let filtersByName = copyObjectValues(copyFilter);
            filtersByName.forEach(e=>{
                e.field = "name";
                e.entities = e.entities.map(ent=>{ent.value = ent.name; ent.label = ent.name; return ent})
                copyFilter.push(e);
            });
            tempState.filterFinal = JSON.stringify(copyFilter);
            tempState.exportQueryFilter = formatBasicFilter(copyFilter, userSettings.user?.user_allowed_vectors)
        }
        filter.map((item)=>{item.filter_display_name = undefined });
        tempState.filterFinalBasic = isUnCheck? JSON.stringify(filter): filter;
        setFilterFinal(tempState.filterFinal);
        setFilterFinalBasic(tempState.filterFinalBasic);

        if(item && Object.keys(basicFilterEntities).length && basicFilterEntities[item.value]) { 
          basicFilterEntities[item.value].map(entity => entity.checked = false); 
        } 
        
        let countState = countRef.current;
        setCount(countState + 1);
        countRef.current = countState+1;
        // when we add the first dropdown, the initial filter will be 0 and the new filter will be 1.
        // when we remove the first dropdown, the filter will be 0 and the initial will be 1
        // this condition is to prevent resizing the tables when we add/remove more than 1 dropdown
        // if(isClear || (initialFilter.length <= 1 && filter.length === 0) || (initialFilter.length ===0 && filter.length <= 1)) {
        //     _this.handleOverlayAndHeights(filter.length > 0);
        // }
        if(filterDialRef?.current) {
            filterDialRef?.current?.setState({
                savedFilterData: {}
            })
        }

        if(filterDialRef?.current?.savedFilterNameInput){
            filterDialRef.current.savedFilterNameInput.value = ""
        }

        if(isUnCheck && removedLine.entities.length>0 && !showGreyOverLay){
            if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
                localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
                localStorage.removeItem(CURRENT_FILTER_COOKIE);
                setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(tempState.filterFinal));
            }
            let countstate = countRef.current;
            setCount(countstate+1);
            countRef.current = countState+1;
            // apply();
            setCallGo(true);

        }else if(!isClear){
            getFilterEntities(item, vectorState, setBasicFilterEntities, basicFilterEntitiesState, profitFormat, userSettings, scenarioState, tempState.filterFinal, getCountRef, setCount, setCountRef);
        }
    }
    /**
     * @function clearClick()
     * called from {@link FilterByDropdown} and {@link AppliedFilters}
     */
    const clearClick=()=>{
        handleBasicFilterCheckBoxChange(undefined, true);
        if(!showGreyOverLay) {
          setCallGo(true);
          // apply(); 
        }
    } 

    const openFilterModal = () => {
        setOpenFilterDialog(true);
    }

    const closeFilterModal = () => {
        setOpenFilterDialog(false);
    }

    const filterModalBody = () => {
        return (
            <h4>{lang.clear_advanced_filter}<br />{lang.switching_to_quick_filter}</h4>
        )
    }

    const handleBasicFilterAndClose = () =>{
        closeFilterModal();
        clearAdvancedFilter(basicFilterItem)
    }

    const clearAdvancedFilter = (basicFilterItem) => {
        filterDialRef?.current?.clearFilter();
        setFilterFinal([]);
        handleBasicFilterCheckBoxChange(basicFilterItem);
    }

    const clearAdvancedFilters = (isClearAll) => {
        if(isClearAll) {
            filterDialRef?.current?.clearFilter();
            setFilterFinal([]);
        }
        if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
            localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
            localStorage.removeItem(CURRENT_FILTER_COOKIE);
            let exportQueryFilter = formatBasicFilter([], userSettings.user?.user_allowed_vectors);
            setExportQueryFilter(exportQueryFilter);
        }
    }

    const saveDialogActions = () => {
        return (
            <>
                <Button
                    label={lang.modal.buttons.clear_filter}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={handleBasicFilterAndClose}
                />
                <Button
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    onBtnClick={closeFilterModal}
                />
            </>
        )
    }
    
    /**Profit Stack DropDown Functions */
    const toggleDropDownState = (status) => {
        setViewStackOpen(false);
                
        if(status) {
          $(".profit-stack-filter-container").addClass("disabled-profit-stack-wrapper");
        } else {
          $(".profit-stack-filter-container").removeClass("disabled-profit-stack-wrapper");
        }
        $(".profit-stack-filter-container-div").attr("uk-tooltip", status ? lang.select_entities_to_view_PS : "title:");
    }

    /**Limit Access Related Functions */
    const checkForLimitAccessMessage = (data, showModal)=> {
        let noAccessMessage = data.no_access_message;
        if(!!noAccessMessage && showModal && !props.checkedPsLines) {
            setLimitAccessDialogOpen(true, noAccessMessage);
        }
        return !!noAccessMessage;
    }

    const setLimitAccessDialogOpen = (isOpen, msg)=> {
        setLimitAccessMsg(msg)
        setOpenLimitAccessDialog(isOpen);
    }

    /**Entity Stacks Redirection Related Functions */
    const saveChosenEntities=(entities)=>{
        profitStackSelectedEntities.current = entities;
        setSelectedEntities(entities?.length);
        if (entities.length === 0) {
          // $(".profit-stack-filter-container").addClass("disabled-profit-stack-wrapper");
          // $(".profit-stack-filter-container-div").attr("uk-tooltip", lang.select_entities_to_view_PS);
        }
    }

    const runExtendedProfitStackFunc = (dropdownName, PSViewsTempState, allVectorsSelected, selectedDropdownVector) => {
      runExtendedProfitStack(dropdownName, vectorState, datasetState, profitFormat, profitStackSelectedEntities, reportRef,
          PSViewsTempState, periodsStatusState, scenarioState, filterDialRef, filterFinal, filterFinalBasic, manageColumnsProfile, 
          userAllowedMenuLinks, history, isDrilling, dispatch, drillFilter, allVectorsSelected, selectedDropdownVector, originalDrillFilter, drillVectorType);
    }

    /** 
     * @function displayDefaultColumns()
     * This functions sets the default profile columns of manage columns and not the filtered one from searching in manage columns state
     */
    const displayDefaultColumns = () => {
      let columns = manageColsRef.current?.state.profileColumns;
      let data = manageColsRef.current?.updateSearchResult(columns, "");
      manageColsRef.current?.setState({
        profileColumns: data,
        isSearching: false,
        searchValue: ""
      })
    }

    let trackingData = window._pi_getTrackingDataObj();
    trackingData = trackingData === null ? "" : JSON.stringify(trackingData);
    let scenarioLabel = scenarioState?.scenarioObjects?.length > 0 ? scenarioState?.scenarioObjects[0].label : "";
    
    let showSkeleton = !(
      areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, datasetState]) &&
      profileHasColumnsAccess(manageColumnsProfile, manageColsAccess) &&
      headerElements.length > 0
    );

    useEffect(() => {
      updateWindowLoading(showSkeleton, "skeleton-loader");
    }, [showSkeleton])

    const viewStackClick = () => {
        let tempState = copyObjectValues(PSViewsState);
        tempState.custom_stack_id = 0;
        tempState.current_stack_id = 0;
        tempState.nextProfitStackViewId = 0;
        tempState.profitStackViewId = 0;
        setPSViewsState(tempState);
        window._pi_initialiseTracking(UI_ACTIONS.PROFIT_STACK, userSettings.user.email, userSettings.machine_name, profitFormat, false);
        runExtendedProfitStackFunc(ALL_WIDGETS.FIELDS.VIEW_STACK, tempState, reportRef.current.pageComponent.state.allVectorsSelected, vectorState.nextVectors[0]);
    }

    return (
      <Suspense fallback={<LoaderSkeleton />}>
        {showSkeleton && <LoaderSkeleton />}

        <Modal
          id={"check-limit-access-dialog"}
          openDialog={openLimitAccessDialog}
          closeClick={() => setLimitAccessDialogOpen(false, "")}
          bodyContent={() => messageDialogContent(limitAccessMsg)}
          dialogActions={() => (
            <Button
              label={lang.modal.buttons.ok}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              aria-label="Close"
              onBtnClick={() => setLimitAccessDialogOpen(false, "")}
            />
          )}
          size={DIALOG_SIZE.MEDIUM}
        />
        <Modal
          id={"header-info-dialog"}
          openDialog={openInfoDialog}
          bodyContent={() => <span className="uk-text-large">{infoDialogMsg}</span>}
          dialogActions={() => (
            <Button
              label={lang.modal.buttons.ok}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={() => setInfoDialogOpen(false, "")}
            />
          )}
          closeClick={() => setInfoDialogOpen(false, "")}
          size={DIALOG_SIZE.MEDIUM}
        />
        <Modal
          id={"switch-basic-to-advanced-filter"}
          openDialog={openFilterDialog}
          bodyContent={filterModalBody}
          dialogActions={saveDialogActions}
          size={DIALOG_SIZE.MEDIUM}
          closeClick={closeFilterModal}
        />
        <div id="toastExport" className={!!error ? "toast toast-fail" : "toast toast-success"}>
          <div id="desc">
            <i
              className={"fa-lg fas uk-margin-small-right " + (!!error ? "fa-minus-circle uk-text-primary" : "fa-check-circle greenText")}
              aria-hidden="true"
            ></i>
            {message}
            <span></span>
          </div>
        </div>
        <iframe name="frame" id="frame_export" style={{ display: "none" }}></iframe>
        <div ref={manageColsOverlay} id="overlay-manage-columns" className="uk-hidden" />
        <div ref={manageColsDrillOverlay} id="overlay-manage-columns-drill" className="uk-hidden"></div>
        <div
          id={"main-component-container"}
          className={"main-component-container " + (showSkeleton ? "hidden" : "")}
          style={{ "--banner-height": isScenarioBannerVisible ? "6%" : 0 }}
        >
          <Loader newLoader />
          <div className={isScenarioBannerVisible ? "header-banner-div" : "header-banner-div-hidden"}>
            {isScenarioBannerVisible && <NewBanner
              bannerClassName={"header-banner scenario-banner"}
              labelClassName={"header-banner-label scenario-banner-label"}
              icon={<RoundInfoIcon className="info-banner-icon" />}
              label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
              body={
                <Button
                  id={"banner-dismiss-btn"}
                  label={lang.modal.buttons.dismiss}
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={() => setScenarioBannerVisible(false)}
                />
              }
            />}
          </div>

          <div className="main-report-header">
            <ReportHeader headerElements={headerElements} />
          </div>
          
          <div className="main_report list-main-report">
              {headerElements.length > 0 && showGreyOverLay && <div className="overlay-div">{Object.keys(window._loading)?.length > 0 ? "" : lang.overlay_go_msg}</div>}
              {areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, datasetState]) &&
              profileHasColumnsAccess(manageColumnsProfile, manageColsAccess) &&
              headerElements.length > 0 ? (
                <Base
                  ref={reportRef}
                  setSelectedEntities={setSelectedEntities}
                  checkForLimitAccessMessage={checkForLimitAccessMessage}
                  clientPeriodsState={clientPeriodsState}
                  datasetState={datasetState}
                  exportQueryFilter={getExportQueryFilter}
                  filterFinal={filterFinal}
                  history={props.history}
                  manageColsAccess={manageColsAccess}
                  manageColumnsProfile={manageColumnsProfile}
                  periodsStatusState={periodsStatusState}
                  profitFormat={profitFormat}
                  psLinesOptions={psLinesOptions && psLinesOptions.has(scenarioState.scenario) ? psLinesOptions.get(scenarioState.scenario)[0] : []}
                  reselectProfile={reselectProfile}
                  saveChosenEntities={saveChosenEntities}
                  saveDrillFilter={saveDrillFilter}
                  scenarioState={scenarioState}
                  setDataLength={setDataLength}
                  setExportOptionsForUser={setExportOptionsForUser}
                  setHeaderChanged={setHeaderChanged}
                  setIsDrilling={setIsDrillingFunc}
                  setShowGreyOverLay={setShowGreyOverLay}
                  toggleDropDownState={toggleDropDownState}
                  userAllowedMenuLinks={userAllowedMenuLinks}
                  userSettingsState={userSettings}
                  vectorState={vectorState}
                  profiles={profiles}
                  stateProfiles={stateProfiles}
                  customGroups={customGroups}
                  profileColumns={profileColumns}
                  columnProfiles={columnProfiles}
                  quadrantTierOptions={quadrantTierOptions}
                  displayDefaultColumns={displayDefaultColumns}
                  dispatch={dispatch}
                  listData={listData}
                  drillListData={drillListData}
                  updateManageColumnsProfile={updateManageColumnsProfile}
                  updateColumnsOrder={manageColsRef.current?.updateColumnsOrder}
                  manageColumnsDrillRef={manageColumnsDrillRef.current}
                  manageColsRef={manageColsRef.current}
                  drillSelectionLimit={Number(userSettings?.drillSelectionLimit)}
                  exitDrill={exitDrill}
                  hasDrillOption={true}
                  useNewReorderList={userSettings.useNewReorderList}
                  saveDrillVectorType={saveDrillVectorType}
                  exportScopeFilter={exportScopeFilter}
                />
              ) : (
                ""
              )}
            </div>
        </div>
      </Suspense>
    );
};

export { ListWrapper };

