import { Link, useLocation } from "react-router-dom";
import { capitaliseFirstLetterAfterChar } from "../../class/utils";
import { ReactComponent as Arrow } from "../../styles/images/icons/small-thick-arrow.svg";
import PropTypes from "prop-types";

const Breadcrumbs = ({ additionalCrumb, title, redirectedFromObj, gotToLandingFunc }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  let breadcrumbPath = "";

  return (
    <div className="breadcrumbs">
      {gotToLandingFunc && <button className="breadcrumbs_text" onClick={gotToLandingFunc}>{"Start"}</button>}
      {!gotToLandingFunc && <Link className="breadcrumbs_text" to="/profit_isle_start">Start</Link>}
      
      {pathnames.map((name, index) => {
        breadcrumbPath += `/${name}`;
        const isLast = index === pathnames.length - 1;
        let additionalTitle = isLast && additionalCrumb ? `${additionalCrumb}` : "";
        let redirectedFromReportTitle = redirectedFromObj?.title;
        name = title && isLast ? title : name;
        return (
          <div className="breadcrumbs_text_arrow" key={breadcrumbPath}>
            {isLast && redirectedFromReportTitle && (
              <Breadcrumb
                title={capitaliseFirstLetterAfterChar(redirectedFromReportTitle).replaceAll("_", " ")}
                onClick={redirectedFromObj?.onClick}
              />
            )}
            {/* This condition is needed for when we are in erosion - bridge because we will receive redirectedFromObj and have the pathname both equal to Erosion and we only need to display it once */}
            {redirectedFromReportTitle?.toLowerCase() !== name?.toLowerCase() && (
              <Breadcrumb title={capitaliseFirstLetterAfterChar(name).replaceAll("_", " ")} />
            )}
            {/* Adding an additional title crumb aftr adding all pathnames. ex: Erosion > Bridge Report */}
            {additionalTitle && <Breadcrumb title={additionalTitle} />}
          </div>
        );
      })}
    </div>
  );
};

const Breadcrumb = ({ title, onClick }) => {
  return (
    <>
      <Arrow />
      {onClick ? (
        <button className="breadcrumbs_text" onClick={onClick}>
          {title}
        </button>
      ) : (
        <div className="breadcrumbs_text">{title}</div>
      )}
    </>
  );
};

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Breadcrumbs.propTypes = {
  additionalCrumb: PropTypes.string, // This is used when in List for example, we display the list name as the last breadcrumb ex:System Default
  title: PropTypes.string, // This is used when in entity stacks for example, we display Entity Stacks - [vector name] or Compare Segments - [vector name]
  // This is used to allow the user to go back to the previous report in some screens like list > entity stacks. The user can click on list to go back to List screen from entity stacks
  redirectedFromObj: PropTypes.shape({
    title: PropTypes.string, // clickable title that will be used to go back to previous report ex: go from list to entity stacks, title=List and will be displayed as List > Entity Stacks
    onClick: PropTypes.func, // onClick function that is the onBackClick for most screens
  }),
};

export default Breadcrumbs;
