import { extractFromFullQuarter, generatePeriods, getFullQuarterFromStartEndQuarters, getGeneratedQuarterRange, getLastBuiltPeriodForSegments, getPeriodQuarter, getQuarterFromDate, monthDiff } from "../../class/date";
import { isValidDate } from "../../class/utils";
import { getValidPeriods } from "../../templateLayout/functions/periodFunctions";
import Tag from "./Tag"
import TrenChart from "./TrendChart"
import {ReactComponent as Alert} from "../../styles/images/actionableInsights/alert-triangle.svg";
import {ReactComponent as TrendDown} from "../../styles/images/actionableInsights/trending-down.svg";
import {ReactComponent as DollarSign} from "../../styles/images/actionableInsights/dollar-sign.svg";
import {ReactComponent as TrendUp} from "../../styles/images/actionableInsights/trending-up.svg";
import { API_URL, FormatTypes, FY_VALUES, RETURN_INSIGHTS, ROLLING_SEGMENTS } from "../../class/constants";
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from "../../class/networkUtils";
import { useEffect, useRef, useState } from "react";
import { convertPxToViewport } from "../../class/formatting";
import { ReactComponent as HashIcon } from "../../styles/images/actionableInsights/hash.svg";
import { lang } from "../../language/messages_en";
import { formatValString } from "../../class/format";
import { SkeletonLoader } from "../../form/elements";
import { joinPeriods } from "../../class/common";

const ExecutiveView = (props) => {
    const [impactOnMargin, setImpactOnMargin] = useState('...');
    const [percentageOfProfit, setPercentageOfProfit] = useState();
    const [totalReturns, setTotalReturns] = useState();
    const [returnsVsLastMonth, setReturnsVsLastMonth] = useState('...');
    const [returnsVsLastYear, setReturnsVsLastYear] = useState('...');
    const [returnsPercentage, setReturnsPercentage] = useState();
    const [brandsOutliers, setBrandsOutliers] = useState();
    const [allBrandsCount, setAllBrandsCount] = useState();
    const [chartData, setChartData] = useState([]);
    const abortControllers = useRef(new Map());
    const [insights, setInsights] = useState([]);
    const [isFetchingInsights, setIsFetchingInsights] = useState(false);
    const [returnsDataFetched, setReturnsDataFetched] = useState(false);
    const [impactOnMarginFetched, setImpactOnMarginFetched] = useState(false);
    const [brandOutlierFetched, setBrandOutlierFetched] = useState(false);
    const [returnAndSalesFetched, setReturnAndSalesFetched] = useState(false);

    useEffect(() => {
        return () => {
            abortControllers.current.forEach((controller, key) => {
                if (!controller.signal.aborted) {
                    controller.abort();
                }
            });
            abortControllers.current.clear(); // ✅ Clear controllers
        };
    }, []);
    
    useEffect(()=> {
        if (Object.keys(props?.periodsStatusState).length > 0 && Object.keys(props.clientPeriodsState).length > 0  && Object.keys(props.datasetState).length > 0) {
            setTotalReturns();
            setReturnsPercentage();
            setReturnsVsLastMonth('...');
            setReturnsVsLastYear('...');
            setImpactOnMargin('...');
            setPercentageOfProfit();
            setBrandsOutliers();
            setChartData([]);

            getReturnsData();
            getImpactOnMargin();
            getBrandOutliers();
            getReturnsAndSales();
            setIsFetchingInsights(true);
        } else {
            setTotalReturns();
            setReturnsPercentage();
            setReturnsVsLastMonth('...');
            setReturnsVsLastYear('...');
            setImpactOnMargin('...');
            setPercentageOfProfit();
            setBrandsOutliers();
            setChartData([]);
        }
    }, [props?.periodsStatusState, props.clientPeriodsState, props.datasetState]);

    useEffect(()=>{
        if(returnsDataFetched && impactOnMarginFetched && brandOutlierFetched && returnAndSalesFetched){
            getInsights();
        }
    },[returnsDataFetched, impactOnMarginFetched, brandOutlierFetched, returnAndSalesFetched])

    const createAbortController = (key) => {
        if (abortControllers.current.has(key)) {
            abortControllers.current.get(key).abort(); 
        }
        const controller = new AbortController();
        abortControllers.current.set(key, controller);
        return controller;
    };

    const getInsights=()=>{
        let data = {};
        data.totalReturns = totalReturns;
        data.returnsPercentage = returnsPercentage;
        data.returnsVsLastMonth = returnsVsLastMonth;
        data.returnsVsLastYear = returnsVsLastYear;
        data.totalImpact = impactOnMargin;
        data.percentageOfProfit = percentageOfProfit;
        data.brandsOutliers = brandsOutliers;
        data.allBrandsCount = allBrandsCount;
        data.returnsAndSales = chartData;
        const controller = createAbortController("getInsights");
        var query = {
            action: "getInsights",
            insightsData: data,
            view: RETURN_INSIGHTS.EXECUTIVE_VIEW.VALUE
        }
        var onThenCallback = (data)=>{
            abortControllers.current.delete("getInsights");
            setIsFetchingInsights(false);
            setInsights(data.insights);
        }
    
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getInsights",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.RETURNS_INSIGHTS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.returns_insights.screen_name ,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.returns_insights.get_insights_executive,
            [FETCHAPI_PARAMS.signal]:  controller.signal,
        }
        fetchAPI(fetchOptions);
    }

    const getPeriodsObject =()=> {
        let periods = [];
        let periods_yoy = [];
        let periods_pa = [];
        let quarter = "";
        let quarterPre = "";
        let segmentPeriod = "";
        let months = FY_VALUES.M3;
        let startDate = getValidPeriods(props.periodsStatusState, props.clientPeriodsState)?.startDate;
        let endDate = getValidPeriods(props.periodsStatusState, props.clientPeriodsState)?.endDate;
      
        if (isValidDate(startDate) && isValidDate(endDate) && startDate  && endDate) {
            let periodsCount = monthDiff(startDate, endDate) + 1;
            periods = generatePeriods(startDate, periodsCount);
            periods_yoy =  generatePeriods(startDate, periodsCount,false);
            periods_pa = generatePeriods(startDate, periodsCount,true);
        }
    
        let periodsStateOrProps = props.clientPeriodsState?.periods;
        if (!!periodsStateOrProps && isValidDate(startDate) && isValidDate(endDate)) {
            let firstQuarter = getQuarterFromDate(startDate);
            let endQuarter = getQuarterFromDate(endDate);
    
            let firstPreQuarter = getPeriodQuarter(periods_pa[0]);
            let endPreQuarter = getPeriodQuarter(periods_pa[periods_pa.length-1]);
    
            let generatedRange = getGeneratedQuarterRange(props.datasetState.datasetOptions, firstQuarter, endQuarter);
            let generatedRangePre = getGeneratedQuarterRange(props.datasetState.datasetOptions, firstPreQuarter, endPreQuarter);
    
            let fullQuarter = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRange[0], generatedRange[1]));
            let fullQuarterPre = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRangePre[0], generatedRangePre[1]));
    
            quarter = fullQuarter.quarter;
            quarterPre = fullQuarterPre.quarter;
            months = fullQuarter.months;
    
            let lastSelectedPeriod = periods[periods.length - 1];
            let builtPeriods = props.periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label);
            segmentPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
        }
        return {periods: periods, segmentPeriod: segmentPeriod, quarter: quarter, months: months, periods_yoy: periods_yoy, periods_pa: periods_pa, preQuarter: quarterPre};
    }
    
      const getReturnsData = () => {
        setReturnsDataFetched(false);
        let periodsObj = getPeriodsObject();// to get the quarter
        const controller = createAbortController("getReturnsData")
        var query = {
            action: "getReturns",
            scenario_id: props.scenarioState.scenario ,
            currentPeriods:periodsObj.periods,
            previousPeriods: periodsObj.periods_pa,
            previousYearPeriods: periodsObj.periods_yoy,
            quarter: periodsObj.quarter+(periodsObj.months && periodsObj.months !== FY_VALUES.M3? periodsObj.months :""),
            rollingPeriod: periodsObj.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12,
            FY:  FY_VALUES.M3,
        }
        var onThenCallback = (data)=>{
            setReturnsDataFetched(true);
            abortControllers.current.delete("getImpactOnMargin");
            setTotalReturns(formatValString(data.total_returns.totalReturns, FormatTypes.AMOUNT));
            setReturnsPercentage(formatValString(data.total_returns.returnRatePercentage, FormatTypes.PERCENTAGE));
            setReturnsVsLastMonth(formatValString(data.returns_vs_last_month.percentage_change, FormatTypes.PERCENTAGE));
            setReturnsVsLastYear(formatValString(data.returns_vs_last_year.returnRatePercentage, FormatTypes.PERCENTAGE));
        }
    
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getReturns",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.RETURNS_INSIGHTS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.returns_insights.screen_name ,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.returns_insights.get_returns,
            [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
            [FETCHAPI_PARAMS.periods]:  periodsObj.periods.join(",") + "," + periodsObj.periods_pa.join(","),
            [FETCHAPI_PARAMS.signal]:  controller.signal,
        }
        fetchAPI(fetchOptions);
      }
    
      const getImpactOnMargin=()=> {
        setImpactOnMarginFetched(false);
        const controller = createAbortController("getImpactOnMargin")
        let periodsObj = getPeriodsObject();
        var query = {
            action: "getImpactOnMargin",
            scenario_id: props.scenarioState.scenario ,
            currentPeriods:periodsObj.periods,
            previousPeriods: periodsObj.periods_pa,
            previousYearPeriods: periodsObj.periods_yoy,
            quarter: periodsObj.quarter+(periodsObj.months && periodsObj.months !== FY_VALUES.M3? periodsObj.months :""),
            rollingPeriod: periodsObj.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12,
            FY:  FY_VALUES.M3,
        }
        var onThenCallback = (data)=>{
            setImpactOnMarginFetched(true);
            abortControllers.current.delete("getImpactOnMargin");
            setImpactOnMargin(formatValString(data.percentage_of_profit.value, FormatTypes.AMOUNT));
            setPercentageOfProfit(formatValString(data.percentage_of_profit.valuePercentage, FormatTypes.PERCENTAGE));
        }
    
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getImpactOnMargin",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.RETURNS_INSIGHTS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.returns_insights.screen_name ,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.returns_insights.get_impact_on_margin,
            [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
            [FETCHAPI_PARAMS.periods]:  periodsObj.periods.join(","),
            [FETCHAPI_PARAMS.signal]:  controller.signal,
        }
        fetchAPI(fetchOptions);
      }
    
      const getBrandOutliers=()=> {
        setBrandOutlierFetched(false);
        const controller = createAbortController("getBrandOutliers") 
        let periodsObj = getPeriodsObject();
        var query = {
            action: "getBrandOutliers",
            scenario_id: props.scenarioState.scenario ,
            currentPeriods:periodsObj.periods,
            previousPeriods: periodsObj.periods_pa,
            previousYearPeriods: periodsObj.periods_yoy,
            quarter: periodsObj.quarter+(periodsObj.months && periodsObj.months !== FY_VALUES.M3? periodsObj.months :""),
            rollingPeriod: periodsObj.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12,
            FY:  FY_VALUES.M3,
        }
        var onThenCallback = (data)=>{
            setBrandOutlierFetched(true);
            abortControllers.current.delete("getBrandOutliers");
            setBrandsOutliers(formatValString(data.brand_outliers.outlierBrands, FormatTypes.NUMERIC));
            setAllBrandsCount(data.brand_outliers.totalBrands);
        }
    
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getBrandOutliers",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.RETURNS_INSIGHTS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.returns_insights.screen_name ,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.returns_insights.get_brand_outliers,
            [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
            [FETCHAPI_PARAMS.periods]:  periodsObj.periods.join(","),
            [FETCHAPI_PARAMS.signal]:  controller.signal,
        }
        fetchAPI(fetchOptions);
      }

      const getReturnsAndSales=()=> {
        setReturnAndSalesFetched(false);
        let periodsObj = getPeriodsObject();
        const controller = createAbortController("getReturnsAndSales") 
        var query = {
            action: "getReturnsAndSales",
            scenario_id: props.scenarioState.scenario ,
            currentPeriods:periodsObj.periods,
            previousPeriods: periodsObj.periods_pa,
            previousYearPeriods: periodsObj.periods_yoy,
            quarter: periodsObj.quarter+(periodsObj.months && periodsObj.months !== FY_VALUES.M3? periodsObj.months :""),
            rollingPeriod: periodsObj.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12,
            FY:  FY_VALUES.M3,
        }
        var onThenCallback = (data)=>{
            setReturnAndSalesFetched(true);
            setChartData(data.returns_and_sales);
            abortControllers.current.delete("getReturnsAndSales");
        }
    
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getReturnsAndSales",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.RETURNS_INSIGHTS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.returns_insights.screen_name ,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.returns_insights.get_brand_outliers,
            [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
            [FETCHAPI_PARAMS.periods]:  periodsObj.periods.join(","),
            [FETCHAPI_PARAMS.signal]:  controller.signal,
        }
        fetchAPI(fetchOptions);
      }
    let brandOutLierPerc = Number(allBrandsCount) ==0? 0 : formatValString((Number(brandsOutliers)*100)/Number(allBrandsCount), FormatTypes.PERCENTAGE);
    let periodsObj2 = getPeriodsObject();
    return (
    <div style={{background:"#f9fafb", padding:"0.78125vw 0.677vw", overflow:"auto", maxHeight:"84%"}}>
        <div className='executive-view-container'>
            <span style={{
                fontSize: "1.042vw",
                fontWeight: '600'
            }}>Executive Summary - Return Overview</span>
            <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-between", gap: "1.042vw"}}>
                <div className={"colored-card shine-effect"} style={{display: 'flex', flexDirection: 'column', width: '100%', gap: "1.042vw"}}>
                    <div style={{fontSize: "0.833vw", color: "black", opacity: "0.6", fontWeight: "600"}}>TOTAL
                        RETURNS
                    </div>
                    <Tag title="Dollar Value" value={totalReturns}
                         color="" hexColor="#dc2626" bgColor={"#FFFF"}/>
                    <Tag title="Change" value={returnsVsLastMonth} icon={TrendDown} color="" hexColor="#dc2626"
                         bgColor={"#f75c5c36"} info={" vs " + joinPeriods(periodsObj2?.periods_pa)}/>
                </div>
                <div className={"colored-card shine-effect"} style={{display: 'flex', flexDirection: 'column', width: '100%', gap: "1.042vw"}}>
                    <div style={{fontSize: "0.833vw", color: "black", opacity: "0.6", fontWeight: "600"}}>IMPACT ON
                        PROFIT
                    </div>
                    <Tag title="Total Impact" value={impactOnMargin} bgColor={"#FFFF"}/>
                    <Tag title="Percentage of Profit" value={percentageOfProfit} color="" info ={" "}
                         hexColor="#ea580c" bgColor={"#FFFF"}/>
                </div>
                <div className={"colored-card shine-effect"} style={{display: 'flex', flexDirection: 'column', width: '100%', gap: "1.042vw"}}>
                    <div style={{fontSize: "0.833vw", color: "black", opacity: "0.6", fontWeight: "600"}}>BRAND OUTLIERS</div>
                    <Tag title="Count" value={brandsOutliers} color="" hexColor="#2563eb" bgColor={"#FFFF"}/>
                    <Tag title="Percentage of Total Brands" value={brandOutLierPerc} color="" hexColor="#059669" info ={" "}
                         bgColor={"#FFFF"}/>
                </div>
                <div className={"colored-card shine-effect"} style={{display: 'flex', flexDirection: 'column', width: '100%', gap: "1.042vw"}}>
                    <div style={{fontSize: "0.833vw", color: "black", opacity: "0.6", fontWeight: "600"}}>RETURN RATE
                    </div>
                    <Tag title="Current Rate" value={returnsPercentage} bgColor={"#FFFF"}/>
                    <Tag title="Rate Change" value={returnsVsLastYear} icon={TrendUp} color="" hexColor="#059669"
                         bgColor={"#FFFF"} info={" vs " + joinPeriods(periodsObj2?.periods_yoy)}/>
                </div>
            </div>
            <div style={{display: "flex", flexDirection:"column", gap:"1.042vw"}}>
                <div className="bold">Key Insights</div>
                <div style={{display:"flex", flexWrap: "wrap", gap: "1.042vw" }}>
                    {isFetchingInsights?
                        <div className="insight">
                            <SkeletonLoader />
                        </div>
                    :insights?.map(insight=>{
                        return (
                            <div className="insight" style={{
                                width: '100%',
                                backgroundColor: "#ffff",
                                borderRadius: "0.417vw",
                                display: "flex",
                                flexDirection: "column",
                                rowGap: convertPxToViewport(8),
                                boxShadow: "3px 6px 8px 0 rgba(0, 0, 0, 0.1)",
                                borderLeft: "4px solid #6495ED",
                                padding: "0.885vw 0.677vw"
                            }}
                            >
                                <div style={{color: "#6495ED", fontSize: "0.833vw", fontWeight: '600'}}>{insight.title}</div>
                                <div style={{color: "grey", fontSize: "0.78125vw"}}>{insight.insight}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* <div style={{
                height: "75%",
                backgroundColor: "#ffff",
                borderRadius: "5px",
                boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 6px 0px",
                padding:"0.625vw"
            }}>
                <TrenChart chartData={chartData}/>
            </div> */}
        </div>
    </div>
    )
}

export default ExecutiveView;