import { useState } from "react";
import { ToggleTab } from "../../form/elements";

const HMConfigureToggle = ({
  compKey,
  toggleTabLabel,
  toggleOptions,
  defaultTab,
  isDisabled = false,
  infoLabel,
  onTabChange,
}) => {
  const [toggleTab, setToggleTab] = useState(defaultTab);

  const handleSelectTab = (tab) => {
    setToggleTab(tab);
    onTabChange(compKey, tab);
  };

  return (
    <div className="heatmap-configure uk-margin-bottom">
      <label className="heatmap-configure label-configure-landscape">{toggleTabLabel}</label>
      <div className="heatmap-configure uk-flex uk-flex-middle uk-padding-xsmall-top heatmap_configure_row">
        <ToggleTab
          key={toggleTabLabel + "_" + toggleTab}
          isDisabled={isDisabled}
          options={toggleOptions}
          type="table"
          onSelectTab={(tab) => handleSelectTab(tab)}
          defaultValue={toggleTab}
          className={"heatmap-configure"}
          uniqueCLassNameForAll={"heatmap-configure"}
          mode={"light"}
        />
        <i className="heatmap-configure">{infoLabel}</i>
      </div>
    </div>
  );
};

export default HMConfigureToggle;
