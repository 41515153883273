import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { linearizeHierarchy } from "../../class/array";
import { copyObjectValues, getTranslationFile } from "../../class/utils";
import Container from "../../components/manageColumns/Container";
import { ToggleTab } from "../../form/elements";
import Button from "../../newComponents/Button";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../../class/constants";

const StackChartConfiguration = forwardRef(({
  configureChartWithoutTooltip,
  dropdownLabel,
  dropdownPlaceholder,
  dropdownData,
  multiSelectLimit,
  isMultiSelect,
  toggleOptions,
  toggleDefaultValue,
  onToggleSelectTab,
  hasToggleTabs,
  primaryBtnLabel,
  onPrimaryClick,
  selectedNodes,
  setChartSelectedNodes,
  isFromList
},ref) => {
    const lang = getTranslationFile();
    const [selectedTab, setSelectedTab] = useState(toggleDefaultValue);
    const [errorMessage, setErrorMessage] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [data, setData] = useState(dropdownData);
    const [dataObj, setDataObj] = useState([]);
    const [nodes, setNodes] = useState(selectedNodes);
    const [changed, setChanged] = useState(1);


    useImperativeHandle(ref, () => ({
        getDataObj:()=>{
            return dataObj;
        }
      }));

    useEffect(()=>{
        let selectedNodes = findCheckedItems(data);
        let error = "";
        if(selectedNodes.length === 0) {
            error = lang.dashboards.messages.profit_stack_line_is_empty;
        }else {
            let updatedData = data;
            updatedData = updateAllData(data, false); // uncheck all checked lines
            if(isMultiSelect) {
                for (var e in selectedNodes) {
                    updatedData = updateData(updatedData, selectedNodes[e])
                }
                setData(updatedData);
            } else {
                for (var e in selectedNodes) {
                    updatedData = updateData(updatedData, selectedNodes[e])
                }
                setData(updatedData);
            }
        }
        setDisabled(selectedNodes.length === 0 || selectedNodes.length > multiSelectLimit);
        setErrorMessage(error);
    }, []);

    useEffect(()=>{
        setData(data);
    }, [data]);
    
    const onDropdownChange = (currentNode, selectedNodes) => {
        let error = "";
        if(multiSelectLimit && selectedNodes.length > multiSelectLimit) {
            error = lang.dashboards.messages.ps_line_limit;
            currentNode.checked = false;
            document.getElementById(currentNode._id).checked = false;
            selectedNodes.forEach(function(item, index){
                if(!item.checked){
                    selectedNodes.splice(index,1);
                }
            });
            setChartSelectedNodes(selectedNodes);
        }
        if(selectedNodes.length === 0) {
            error = lang.dashboards.messages.profit_stack_line_is_empty;
        }

        setDisabled(selectedNodes.length === 0 || selectedNodes.length > multiSelectLimit);
        if(error!==errorMessage){
            setErrorMessage(error);
        }
        setData(data);
        // setChanged(changed+1);
        if (error!==lang.dashboards.messages.ps_line_limit) {
            if(isMultiSelect) {
                let updatedData = updateData(data, currentNode)
                setData(updatedData);
            } else {
                let updatedData = updateAllData(data, false); // uncheck all checked lines
                updatedData = updateData(data, currentNode);
                setData(updatedData);
            }
            setChartSelectedNodes(selectedNodes);
        }
    };

    /**
     * loops over the lines and their children to find the matching node and updates it
     * @param {} arr 
     * @param {*} node 
     * @returns 
     */
    const updateData = (arr, node) => {
        arr.forEach(row => {
            if (row.value === node.value) {
                row.checked = node.checked;
                return;
            }
            if (!row.children) { return; }
            updateData(row.children, node);
        });

        return arr;
    }

    /**
     * loops over the lines and their children and updates it to checked or not checked
     * @param {} arr 
     * @param {*} isChecked 
     * @returns 
     */
    const updateAllData = (arr, isChecked) => {
        arr.forEach(row => {
            row.checked = isChecked;
            if (!row.children) { return; }
            updateAllData(row.children, isChecked);
        });

        return arr;
    }

    const findCheckedItems = (arr) => {
        // arr.forEach(row => {
        //     if(row.checked /*&& (!checkedItems || !checkedItems.find(e => e.returnname === row.returnname))*/) {
        //         checkedItems.push(row);
        //     }
        //     if(!row.children) { return; }
        //     findCheckedItems(row.children, copyObjectValues(checkedItems));
        // });
        let checkedItems = linearizeHierarchy(arr,"children").filter(e=>e.checked===true)
        return checkedItems;
    }

    const onSelectTab = (value) => {
        onToggleSelectTab(value);
        setSelectedTab(value)
    }

    const onClick = () => {
        let checkedData = findCheckedItems(data);

        if(hasToggleTabs && selectedTab !== toggleDefaultValue) {
            setDataObj({data: checkedData, amount: selectedTab});
        }
        onPrimaryClick(checkedData, selectedTab);
    }

  return (
    <div className="stack-configure-container">
      <label className="fs-14 uk-margin-xsmall-bottom">
        {dropdownLabel}
      </label>
      <sup className="red">*</sup>
      {configureChartWithoutTooltip ? "" : <i className="configure-build-info fs-12 fal fa-info-circle uk-margin-xsmall-left uk-cursor-pointer" uk-tooltip={isFromList? "title:"+lang.total_stacks_configure.info.configure_tab_from_list : "title:"+lang.total_stacks_configure.info.configure_tab}/>}
      <div className="uk-flex">
        <Container
            texts={{ placeholder: dropdownPlaceholder }}
            data={data}
            onChange={onDropdownChange}
            mode={isMultiSelect ? "hierarchical" : "radioSelect"}
            className={
            isMultiSelect
                ? "dropdown-tree multi-select-drop-down"
                : "dropdown-tree"
            }
        />
        {hasToggleTabs ? 
            <ToggleTab
                options={toggleOptions}
                type="chart"
                onSelectTab={(tab) => onSelectTab(tab)}
                defaultValue={selectedTab}
                mode={"light"}
            />
            : 
            ""}
        </div>
        {errorMessage !== "" ? <div className="fs-12 red italic">{errorMessage}</div> : ""}
        <div className="uk-flex uk-flex-right uk-margin-default-top">
            <Button 
                label={primaryBtnLabel}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}  
                onBtnClick={onClick}
                disabled={disabled}
            />
        </div>
    </div>
  );
});

export default StackChartConfiguration;
