import { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

// import NewHeader from './NewHeader';
import ManageStacks from "../../components/manageStacks/ManageStacks";
import NewBanner from "../../components/NewBanner";
import Button from "../../newComponents/Button";
import { ReportHeader } from "../ReportHeader";

import { ALL_WIDGETS, BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, MENU_ITEM, SCENARIO_STATUS, SIZES } from "../../class/constants";
import { lang } from "../../language/messages_en";

import { logout } from "../../class/common";
import { convertPxToViewport } from "../../class/formatting";
import { getSectionExists, tryParse } from "../../class/utils";
import ErrorBoundary from "../../ErrorBoundary";
import { Loader } from "../../form/elements";
import Modal from "../../newComponents/Modal";
import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import { updatePeriodsStatusState } from "../../actions/scenariosActions";
import { getPeriodsStatus } from "../api/api";
import { useAuthenticated } from "../CustomHooks";
import {renderBreadcrumbsAndDescription, renderTitle} from "../functions/componentFunctions";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from "posthog-js/react";
/**
 * @author Sarah Farjallah
 * @description component renders  ManageStacks Stacks Title with {@link ManageStacks} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link ManageStacks}
 */
const ManageStacksWrapper = (props) => {
  const { userAllowedMenuLinks } = props;
  const { userSettings } = props;
  const { scenarioState } = props;
  const { switchClient } = props;
  const { checkingForUnsavedChanges } = props;
  const { isMenuOpen } = props;
  /**References & react utility */
  const dispatch = useDispatch();

  const reportRef = useRef();
  const sessionTimeoutRef = useRef();

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

  const [profitFormat, setProfitFormat] = useState(ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.MANAGE_STACKS);
  const [periodsStatusState, setPeriodsStatusState] = useState({});
  const [headerElements, setHeaderElements] = useState([]);
  const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
  const [filterFinal, setFilterFinal] = useState(
    props.history.location.state
      ? props.history.location.state.outsideFilter
        ? JSON.stringify(tryParse(props.history.location.state.outsideFilter).filter) || []
        : []
      : []
  );
  const [stackStatus, setStackStatus] = useState({ reportTitle: ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.MANAGE_STACKS });
  const [isSaveButtonDisabled, seIsSaveButtonDisabled] = useState(false);
  const [userStacks, setUserStacks] = useState(0);
  const [companyStacks, setCompanyStacks] = useState(0);
  const [isUserAllowed, setIsUserAllowed] = useState(
    getSectionExists(userAllowedMenuLinks, MENU_ITEM.FIELDS.MANAGE_STACKS) ||
      getSectionExists(userAllowedMenuLinks, "manage_user_stacks")
  );
  const [isCompanyAllowed, setIsCompanyAllowed] = useState(
    getSectionExists(userAllowedMenuLinks, MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_STACKS) ||
      getSectionExists(userAllowedMenuLinks, "manage_company_stacks")
  );
  const [openDisabled, setOpenDisabled] = useState(0);
  const [divIdState, setDivIdState] = useState();
  const [destinationClientIdState, setDestinationClientIdState] = useState();
  const [menuOpen, setMenuOpen] = useState(isMenuOpen);
  const [isChanged, setIsChanged] = useState(0);
  const [open, setOpen] = useState(false);
  const useNewComponents = useFeatureFlagEnabled('use_new_components');

  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (checkingForUnsavedChanges) {
       setDivIdState(props.divId);
       setDestinationClientIdState(props.destinationClientId);
      if (reportRef?.current?.stackConfig?.current?.getStackIsChanged()) {
        if (isSaveButtonDisabled) {
            openManageStacksDialogDisabled();
        } else {
            openManageStacksDialog();
        }
      } else {
        props.setCheckingForUnsavedChanges(false);
        props.setCheckedUnsavedChangesRef(true, props.divId && Object.keys(props.divId).length > 0, !!props.destinationClientId);
      }
    }
  }, [checkingForUnsavedChanges, props.divId, props.destinationClientId]);

  useEffect(() => {
    if (isMenuOpen !== menuOpen) {
      setMenuOpen(isMenuOpen);
      reportRef?.current?.stacksRef?.current?.replaceData(); // adjust table to container's width
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (Object.keys(scenarioState).length > 0) {
      getPeriodsStatus(
        scenarioState,
        dispatch,
        updatePeriodsStatusState,
        setPeriodsStatusState,
        props,
        profitFormat,
        userSettings,
        12
      );
      setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
    }
  }, [scenarioState]);

  useEffect(() => {
    if (Object.keys(scenarioState).length > 0 && Object.keys(periodsStatusState).length > 0) {
      let headerElements = useNewComponents ? getNewHeaderElements() : getHeaderElements();
      setHeaderElements(headerElements);
    }
  }, [periodsStatusState, isSaveButtonDisabled, stackStatus, userSettings, userStacks, companyStacks, isChanged]);

  const closeManageStacksDialogDisabled = () => {
    setOpenDisabled(false);
    setDestinationClientIdState(undefined);
    setDivIdState(undefined);
    props.setCheckingForUnsavedChanges(false);
    props.setCheckedUnsavedChangesRef(false, false, false);
  };

  const openManageStacksDialogDisabled = () => {
    setOpenDisabled(true);
  };

  const onButtonNo = () => {
    props.setCheckingForUnsavedChanges(false);
    props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);
  };

  const onButtonYes = () => {
    let cb = () => {
        props.setCheckingForUnsavedChanges(false);
        props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);  
    }
    reportRef?.current?.saveStacks(cb);
  }

  const dialogNotDisabled = () => {
    return (
      <>
        <Button
          label={"Continue"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          className="uk-padding-small-right"
          onBtnClick={onButtonNo}
          aria-label="Close"
        />
        <Button
          label={"Cancel"}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          className="uk-padding-small-right"
          onBtnClick={closeManageStacksDialogDisabled}
        />
      </>
    );
  };

  const manageStacksDialogBodyDisabled = () => {
    return (
      <div>
        <span className="fs-16">{lang.manage_stacks.must_be_fully_configured}</span>
      </div>
    );
  };

  const getAddStackState = () => {
    return stackStatus.addStack;
  };

  const toggleStackConfig = (toggle, isEdit, isBack) => {
    changeAddStack(toggle, isEdit, isBack);
  };

  const changeAddStack = (param, isEdit, isBack) => {
    if (isEdit && !isBack && stackStatus.reportTitle !== "Edit Stack") {
      setStackStatus({
        addStack: false,
        editStack: true,
        reportTitle: "Edit Stack",
      });
    } else if (!isBack && stackStatus.reportTitle !== "Add Stack") {
      setStackStatus({
        editStack: false,
        addStack: param,
        reportTitle: "Add Stack",
      });
    } else {
      emptyCurrentStackState();
      setStackStatus({
        addStack: param,
        editStack: param,
        editedProfitStackFields: [],
        editedStackInfo: {},
        reportTitle: "Manage Stacks",
      });
    }
  };

  const emptyCurrentStackState = () => {
    reportRef?.current?.emptyCurrentStackState();
  };

  const toggleSaveButton = (isChangedParam) => {
    seIsSaveButtonDisabled(isChangedParam);
    let isChangedState = isChanged;
    setIsChanged(isChangedState + 1);
  };

  const getHeaderElements = () => {
    let headerElements = [];
    let isDisabledButton = isSaveButtonDisabled;
    let onBackValidation = reportRef?.current?.onBackValidation(true);
    let isDisabled = !(
      (companyStacks < userSettings.companyStackLimit && isCompanyAllowed) ||
      (userStacks < userSettings.userStackLimit && isUserAllowed)
    );

    if (getAddStackState() || stackStatus.editStack) {
      headerElements.push(
        <div className="manage-stacks-header">
          <>{renderTitle(stackStatus.reportTitle)}</>
          <div className="uk-flex-inline">
            <div style={{ alignSelf: "center", display: "flex" }}>
              {!isDisabledButton ? (
                <span className="fs-12 red italic uk-margin-default-right" style={{ alignSelf: "center" }}>
                  {lang.manage_columns.text.changes_not_saved}
                </span>
              ) : onBackValidation ? (
                <span className="fs-12 red italic uk-margin-default-right" style={{ alignSelf: "center" }}>
                  {lang.manage_columns.text.changes_not_saved}
                </span>
              ) : null}
              {onBackValidation || !isDisabledButton ? (
                <span
                  onClick={() => reportRef?.current?.onResetStacksClick()}
                  className="fs-12"
                  uk-tooltip={"title: This action will discard your unsaved changes; pos: bottom"}
                  style={{ alignSelf: "center" }}
                >
                  <a>Reset</a>
                </span>
              ) : null}
              <Button
                id="back_add_stack"
                label={lang.navigation.btns.back}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                className="uk-margin-default-left"
                onBtnClick={() => {
                  reportRef?.current?.onBackValidation(false);
                }}
              />
              <span
                key={"changes-already-saved"}
                uk-tooltip={
                  onBackValidation && isDisabledButton
                    ? "title:" + lang.manage_stacks.not_configured + ""
                    : !isDisabledButton
                    ? "title: "
                    : onBackValidation === false
                    ? "title: Changes already saved "
                    : onBackValidation && !isDisabledButton
                    ? "title: "
                    : null
                }
              >
                <Button
                  id="header-next"
                  label={lang.navigation.btns.save}
                  variant={BUTTON_VARIANT.PRIMARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  className="uk-margin-default-left"
                  disabled={isDisabledButton}
                  onBtnClick={() => {
                    reportRef?.current?.checkCompatibilityBeforeSave();
                  }}
                />
              </span>
            </div>
            {!getAddStackState() && !stackStatus.editStack ? (
              <div
                className="manage-column"
                uk-tooltip={"title:" + (isDisabled ? lang.manage_stacks.delete_to_proceed + ";pos: bottom" : "")}
              >
                <Button
                  label={lang.manage_stacks.add_stack}
                  variant={BUTTON_VARIANT.SECONDARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  className="uk-margin-xmedium-left"
                  disabled={isDisabled}
                  onBtnClick={() => {
                    changeAddStack(true, false, false);
                  }}
                  leftIcon={<i className="far fa-plus-circle fa-lg" />}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else {
      headerElements.push(
        <div className="manage-stacks-header">
          <>{renderTitle(stackStatus.reportTitle)}</>
          <div className="uk-flex-inline">
            <div style={{ alignSelf: "center", display: "flex" }}>
              <span
                className="uk-text-bold uk-margin-xsmall-right"
                style={{ fontSize: convertPxToViewport(15), position: "relative", right: convertPxToViewport(14) }}
              >
                {lang.manage_stacks.stacks}:
              </span>
              <span
                className="uk-margin-xsmall-right"
                style={{ fontSize: convertPxToViewport(13), position: "relative", right: convertPxToViewport(7) }}
              >
                <i className="fa-lg fal fa-user fa-1x uk-margin-xsmall-right font_weight_icon" /> {userStacks}/
                {userSettings.userStackLimit}
              </span>
              <span className="uk-margin-xsmall-right" style={{ fontSize: convertPxToViewport(13) }}>
                <i className="fa-lg fal fa-users fa-1x uk-margin-xsmall-right font_weight_icon" /> {companyStacks}/
                {userSettings.companyStackLimit}
              </span>
            </div>
            {!getAddStackState() && !stackStatus.editStack ? (
              <div
                className="manage-column"
                uk-tooltip={"title:" + (isDisabled ? lang.manage_stacks.delete_to_proceed + ";pos: bottom" : "")}
              >
                <Button
                  label={lang.manage_stacks.add_stack}
                  variant={BUTTON_VARIANT.SECONDARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  className="uk-margin-xmedium-left"
                  disabled={isDisabled}
                  onBtnClick={() => {
                    changeAddStack(true, false, false);
                  }}
                  leftIcon={<i className="far fa-plus-circle fa-lg" />}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    }
    return headerElements;
  };

  const renderHeaderFirstRow = () => {
    if (getAddStackState() || stackStatus.editStack) {
      let reportURL = props?.location?.pathname?.split("/");
      let reportName = reportURL?.[reportURL?.length - 1];
      let renderFromObject = {
        title: reportName || ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.MANAGE_STACKS,
        onClick: () => reportRef?.current?.onBackValidation(false)
      }

      return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription, stackStatus.reportTitle, renderFromObject)
    } else {
      return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription)
    }
  }

  const renderHeaderSecondRow = (isDisabledButton, onBackValidation, isDisabled) => {
    if (getAddStackState() || stackStatus.editStack) {
      return (
          <>
            <div className="uk-flex-inline">
              <div style={{alignSelf: "center", display: "flex"}}>
                {!isDisabledButton ? (
                    <span className="fs-12 red italic uk-margin-default-right" style={{alignSelf: "center"}}>
                  {lang.manage_columns.text.changes_not_saved}
                </span>
                ) : onBackValidation ? (
                    <span className="fs-12 red italic uk-margin-default-right" style={{alignSelf: "center"}}>
                  {lang.manage_columns.text.changes_not_saved}
                </span>
                ) : null}
                {onBackValidation || !isDisabledButton ? (
                    <span
                        onClick={() => reportRef?.current?.onResetStacksClick()}
                        className="fs-12"
                        uk-tooltip={"title: This action will discard your unsaved changes; pos: bottom"}
                        style={{alignSelf: "center"}}
                    >
                  <a>Reset</a>
                </span>
                ) : null}
                <Button
                    id="back_add_stack"
                    label={lang.navigation.btns.back}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    className="uk-margin-default-left"
                    onBtnClick={() => {
                      reportRef?.current?.onBackValidation(false);
                    }}
                />
                <span
                    key={"changes-already-saved"}
                    uk-tooltip={
                      onBackValidation && isDisabledButton
                          ? "title:" + lang.manage_stacks.not_configured + ""
                          : !isDisabledButton
                              ? "title: "
                              : onBackValidation === false
                                  ? "title: Changes already saved "
                                  : onBackValidation && !isDisabledButton
                                      ? "title: "
                                      : null
                    }
                >
                <Button
                    id="header-next"
                    label={lang.navigation.btns.save}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    className="uk-margin-default-left"
                    disabled={isDisabledButton}
                    onBtnClick={() => {
                      reportRef?.current?.checkCompatibilityBeforeSave();
                    }}
                />
              </span>
              </div>
              {!getAddStackState() && !stackStatus.editStack ? (
                  <div
                      className="manage-column"
                      uk-tooltip={"title:" + (isDisabled ? lang.manage_stacks.delete_to_proceed + ";pos: bottom" : "")}
                  >
                    <Button
                        label={lang.manage_stacks.add_stack}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className="uk-margin-xmedium-left"
                        disabled={isDisabled}
                        onBtnClick={() => {
                          changeAddStack(true, false, false);
                        }}
                        leftIcon={<i className="far fa-plus-circle fa-lg"/>}
                    />
                  </div>
              ) : (
                  ""
              )}
            </div>
          </>
      )
    } else {
      return (
          <>
            <div className="uk-flex-inline">
              <div style={{alignSelf: "center", display: "flex"}}>
              <span
                  className="uk-text-bold uk-margin-xsmall-right"
                  style={{fontSize: convertPxToViewport(15), position: "relative", right: convertPxToViewport(14)}}
              >
                {lang.manage_stacks.stacks}:
              </span>
                <span
                    className="uk-margin-xsmall-right"
                    style={{fontSize: convertPxToViewport(13), position: "relative", right: convertPxToViewport(7)}}
                >
                <i className="fa-lg fal fa-user fa-1x uk-margin-xsmall-right font_weight_icon"/> {userStacks}/
                  {userSettings.userStackLimit}
              </span>
                <span className="uk-margin-xsmall-right" style={{fontSize: convertPxToViewport(13)}}>
                <i className="fa-lg fal fa-users fa-1x uk-margin-xsmall-right font_weight_icon"/> {companyStacks}/
                  {userSettings.companyStackLimit}
              </span>
              </div>
              {!getAddStackState() && !stackStatus.editStack ? (
                  <div
                      className="manage-column"
                      uk-tooltip={"title:" + (isDisabled ? lang.manage_stacks.delete_to_proceed + ";pos: bottom" : "")}
                  >
                    <Button
                        label={lang.manage_stacks.add_stack}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        className="uk-margin-xmedium-left"
                        disabled={isDisabled}
                        onBtnClick={() => {
                          changeAddStack(true, false, false);
                        }}
                        leftIcon={<i className="far fa-plus-circle fa-lg"/>}
                    />
                  </div>
              ) : (
                  ""
              )}
            </div>
          </>
      )
    }
  }

  const getNewHeaderElements = () => {
    let headerElements = [];
    let isDisabledButton = isSaveButtonDisabled;
    let onBackValidation = reportRef?.current?.onBackValidation(true);
    let isDisabled = !(
        (companyStacks < userSettings.companyStackLimit && isCompanyAllowed) ||
        (userStacks < userSettings.userStackLimit && isUserAllowed)
    );

    headerElements.push(
        <div style={{display: "flex", width: "100%", alignItems: "center"}}
             className='new_header_menu first_header_row gap_between_buttons'>
          {renderHeaderFirstRow()}
        </div>
    );

    headerElements.push(
        <div
            className="new_header_menu second_header_row gap_between_buttons"
            style={{display: "flex", width: "100%", padding: "0.41667vw 0"}}
        >
          {renderHeaderSecondRow(isDisabledButton, onBackValidation, isDisabled)}
        </div>
    );

    return headerElements;
  };


  const closeManageStacksDialog = () => {
    setOpen(false);
  };

  const openManageStacksDialog = () => {
    setOpen(true);
  };

  const manageStacksDialogBodyContent = () => {
    let incompatibleScenarios = "";
    incompatibleScenarios = reportRef?.current?.manageStacksDialogBodySave(true);
    if (incompatibleScenarios && incompatibleScenarios.length > 0) {
      return (
          <div className="uk-border-rounded">
            <span className="fs-16">{lang.manage_stacks.discard}</span>
            <div className="fs-16 uk-margin">{lang.manage_stacks.invalid_scenarios}</div>
            <div className="fs-16 uk-margin-bottom">
              {incompatibleScenarios.map((e) => {
                return <h5 key={e}>{e}</h5>;
              })}
            </div>
            <span className="fs-16">{lang.manage_stacks.save_first}</span>
          </div>
      );
    } else {
      return (
          <div>
            <span className="fs-16">{lang.manage_stacks.save_before_discard}</span>
          </div>
      );
    }
  };

  const dialogSaveDisabled = () => {
    return (
        <>
          {" "}
          <Button
              label={"Yes"}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={onButtonYes}
              aria-label="Close"
          />
          <Button
              label={"No"}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={onButtonNo}
        />
        <Button
          label={"Cancel"}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          onBtnClick={closeManageStacksDialog}
        />
      </>
    );
  };

  let scenarioLabel = scenarioState?.scenarioObjects?.length > 0 ? scenarioState?.scenarioObjects[0].label : "";
  return (
    <>
        <Suspense fallback={<p id="loading">Loading...</p>}>
          <div
            id={"main-component-container"}
            className={"main-component-container"}
            style={{ "--banner-height": isScenarioBannerVisible ? "6%" : 0 }}
          >
            <Modal
              id={"manage-stacks-incompatible-scenarios-dialog"}
              openDialog={open}
              closeClick={closeManageStacksDialog}
              bodyContent={manageStacksDialogBodyContent}
              dialogActions={dialogSaveDisabled}
              size={DIALOG_SIZE.MEDIUM}
            />
            <Modal
              id={"manage-stacks-disabled-save-discard-changes-dialog"}
              openDialog={openDisabled}
              closeClick={closeManageStacksDialogDisabled}
              bodyContent={manageStacksDialogBodyDisabled}
              dialogActions={dialogNotDisabled}
              size={DIALOG_SIZE.MEDIUM}
            />
            <Loader newLoader />
            <div className={isScenarioBannerVisible ? "header-banner-div" : "header-banner-div-hidden"}>
              {isScenarioBannerVisible && <NewBanner
                bannerClassName={"header-banner scenario-banner"}
                labelClassName={"header-banner-label scenario-banner-label"}
                icon={<RoundInfoIcon className="info-banner-icon" />}
                label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
                body={
                  <Button
                    id={"banner-dismiss-btn"}
                    label={lang.modal.buttons.dismiss}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => setScenarioBannerVisible(false)}
                  />
                }
              />}
            </div>

            <div className="main-report-header">
              <ReportHeader headerElements={headerElements} />
            </div>
            <div className="main_report">
              <div id="main_report_container" className="main_report_container scrollable-component">
                {Object.keys(scenarioState).length > 0 && Object.keys(periodsStatusState).length > 0 ? (
                  <ManageStacks
                    ref={reportRef}
                    userSettingsState={userSettings}
                    userAllowedMenuLinks={userAllowedMenuLinks}
                    scenarioState={scenarioState}
                    scenarioList={scenarioState.scenarioList}
                    profitFormat={profitFormat}
                    history={props.history}
                    periodsStatusState={periodsStatusState}
                    filterFinal={filterFinal}
                    isSaveButtonDisabled={isSaveButtonDisabled}
                    toggleSaveButton={toggleSaveButton}
                    user_stacks={userStacks}
                    company_stacks={companyStacks}
                    isUserAllowed={isUserAllowed}
                    isCompanyAllowed={isCompanyAllowed}
                    setUserStacks={setUserStacks}
                    setCompanyStacks={setCompanyStacks}
                    setIsUserAllowed={setIsUserAllowed}
                    setIsCompanyAllowed={setIsCompanyAllowed}
                    stackStatus={stackStatus}
                    changeAddStack={changeAddStack}
                    toggleStackConfig={toggleStackConfig}
                    dispatch={dispatch}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Suspense>
    </>
  );
};

export { ManageStacksWrapper };
