import React, { useMemo } from "react";
import "./styles/landingPage.css";
import { useDispatch } from "react-redux";
import {
  ALL_WIDGETS,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CUSTOM_REPORT,
  DIALOG_SIZE, IMAGE_TYPE,
  MENU_ITEM,
  SHOW_RELEASE_MEASSAGE,
  SIZES
} from "../class/constants";
import { lang } from "./../language/messages_en";
import Button from "../newComponents/Button";
import Carousel from "../newComponents/Carousel";
import { useState } from "react";
import { getImagesSignedUrls, updateShowAgain } from "./api/api";
import { updateUserSettings } from "./actions/userSettingsActions";
import {copyObjectValues, parseBoolean, tryParse} from "../class/utils";
import { ReactComponent as MessageSmile } from "../styles/images/icons/message-smile-feedback.svg";
import Modal from "../newComponents/Modal";
import { IconTitle } from "./IconTitle";
import Accordion from "../components/accordion/Accordion";
import { updateShowReleaseNotesNotification } from "./api/api";
import {getMenuItemsAccordionContent} from "./functions/componentFunctions";
import { useFeatureFlagEnabled } from "posthog-js/react";

const LandingPage = (props) => {
  const memoizedReleaseBanner = useMemo(() => props.userSettings?.releaseMessage, [props.userSettings?.releaseMessage]);
  const memoizedShowFeedback = useMemo(() => props.userSettings?.showProvideFeedback, [props.userSettings?.showProvideFeedback]);
  const dispatch = useDispatch();
  const { userSettings } = props;
  const [acknowledgementValue, setAcknowledgementValue] = useState(props.userSettings?.acknowledgementDialog ? tryParse(props.userSettings?.acknowledgementDialog) : null)
  const [showAcknowledgement, setShowAcknowledgement] = useState(acknowledgementValue?.visible ? parseBoolean(acknowledgementValue?.visible) : null);
  const [showReleasePopup, setShowReleasePopup] = useState(false);
  const [showNotification, setShowNotification] = useState((acknowledgementValue?.content && acknowledgementValue?.content?.length > 0) ? false : props.userSettings?.user?.show_release_notification);
  
  const useNewComponents = useFeatureFlagEnabled('use_new_components');

  const openReleasePopup = (open) => {
    setShowReleasePopup(open);
    let tempState = copyObjectValues(props.userSettings);
    if (open && tempState?.user?.show_release_notification === true) {
      setShowNotification(false);
      tempState.user.show_release_notification = false;
      dispatch(updateUserSettings(tempState));
      updateShowReleaseNotesNotification(tempState, false);
    }
  }

  const goToFeedbackURL = () =>{
    window.open(props.userSettings?.provideFeedbackUrl)
  }

  const acknowledgementModalBody = () => {
    return (
        <div style={{fontSize:"0.729vw"}}>
          {acknowledgementValue.content}
        </div>
    );
  };

  const acknowledgementModalActions = () => {
    return (
        <Button
            label={"Acknowledge"}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.LARGE}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={handleCloseAcknowledgementModal}
        />
    )
  }

  const handleCloseAcknowledgementModal = () =>{
      sessionStorage.setItem("showAcknowledgeModal", "false");
      setShowAcknowledgement(false);
  }


  const renderMenuItemsTooltip = (linkGroup) => {
    return (
      <div className="menu-items-tooltip-div menu-items-landing-page">
        <div style={{ display: "flex", flexDirection: "column", rowGap: "0.2vw"}}>
          <span className="menu-items-tooltip-title">{linkGroup.menu_item_display_name}</span>
          <span className="menu-items-tooltip-description">{linkGroup[MENU_ITEM.COLUMNS.MENU_ITEM_DESCRIPTION]}</span>
        </div>
        <Accordion key={linkGroup?.menu_item_machine_name} items={getMenuItemsAccordionContent(linkGroup, (e)=>props.goToReport(e))} />
      </div>
    );
  };

  return (
    <>
      {showReleasePopup && memoizedReleaseBanner && !props.switchClient ?
    <div className="release_popup_container">
      <div className="release_popup_backdrop"></div>
      <Carousel className={"release_carousel"} openPopup={openReleasePopup} images={props.imagesSignedUrls.filter(f=>f.imageType===IMAGE_TYPE.RELEASE_NOTES)}/>
    </div>
    :""}
      <div className={`landing-page-contents ${useNewComponents && "new-design"}`}>
        <div className="upper-container">
        <div className="title_reports_container">
          <div className="welcomeContainer">
            <div>
              <div className="welcome_container_name" style={{color: "#FFF", fontSize: "0.8vw", fontWeight: 600}}>{lang.menu.hi_text + " " + props.userSettings?.user?.first_name}</div>
              <div className="welcome_container_subtitle" style={{color: "#AAABB0", fontSize: "0.75vw", lineHeight: "120%"}}>{lang.menu.welcome_message_subtitle}</div>
            </div>
            <div className="LoginTextFeedbackContainer">
              {memoizedShowFeedback ? (
                <Button
                  label={lang.modal.buttons.provide_feedback}
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  className="provide_feedback_button"
                  leftIcon={<MessageSmile />}
                  onBtnClick={() => goToFeedbackURL()}
                />
              ) : (
                ""
              )}
            </div>
            {memoizedReleaseBanner && (
              <IconTitle
                title={props.userSettings?.releaseMessage}
                subtitle={props.userSettings?.releaseMessageDescription}
                icon={
                  <div className="notification-div-container">
                    <div className="notification-icon-container">
                      <img alt="alert" src={"/images/menu-svgs/alert.svg"}/>
                      {showNotification?<div className="green-dot"></div>:""}
                    </div>
                  </div>
                }
                titleClass={"release-message-title"}
                subtitleClass={"release-message-subtitle"}
                titleContainerClass={"release-message-icon-title-container-title"}
                svgClass={"release-message-icon-title-svg"}
                iconTitleContainerClass={"release-message-icon-title-container"}
                onClick={() => openReleasePopup(true)}
              />
            )}
          </div>
          <div className="upper-menu-items-container">
            <div className="report_group_container">
            {props.userAllowedMenuLinks.map((item) => {
              if (item[MENU_ITEM.COLUMNS.CATEGORY] === "menu_item" && item.menu_item_category !== "ADMINISTRATION" && (item.children?.length > 0 || item.menu_item_machine_name === 'dashboards')) {
                return ( 
                  <IconTitle
                    key={item.menu_item_machine_name}
                    title={item.menu_item_display_name}
                    subtitle={item[MENU_ITEM.COLUMNS.MENU_ITEM_DESCRIPTION]}
                    titleClass={"landing-menu-item-title"}
                    subtitleClass={"menu-items-subtitle"}
                    svgClass={`menu-items-icon-title-svg ${useNewComponents ? "new-design" : ""}`}
                    iconTitleContainerClass={"menu-items-icon-title-container"}
                    tooltipOnHover={item.menu_item_machine_name === 'dashboards' ? undefined : renderMenuItemsTooltip(item)}
                    type={item.menu_item_type}
                    parent_machine_name={item.menu_item_machine_name}
                    machine_name={item.menu_item_machine_name}
                    url={item.url ? item.url : "/" + item.menu_item_machine_name + "/" + item.menu_item_machine_name}
                    onClick={item.menu_item_machine_name === 'dashboards' ? (e) => {
                      props.goToReport(e)
                    } : () => {}}
                    icon={<img  className={item.menu_item_machine_name === "actionable_insights" ? "scale-image" : ""}src={"/images/menu-svgs/" + item.menu_item_machine_name + ".svg"}/>}
                    menuItemCategory={item.menu_item_category }
                    hasHoverEffect
                  />
              );
            }
            })}
              {parseBoolean(props.userSettings?.addModule) && props.userSettings?.addModuleUrl ?
                  <IconTitle
                      title={lang.menu.add_module_title}
                      subtitle={lang.menu.add_module_subtitle}
                      titleClass={"landing-menu-item-title"}
                      subtitleClass={"menu-items-subtitle"}
                      iconTitleContainerClass={"add-module-icon-title-container"}
                      svgClass={"add-module-icon-title-svg"}
                      titleContainerClass={"add-module-icon-title-container-title"}
                      onClick={() => {
                        window.open(props.userSettings?.addModuleUrl)
                      }}
                      icon={<img src={"/images/menu-svgs/add_module.svg"}/>}
                      /> :
                  ""}
            </div>
          </div>
        </div>
        </div>
        <div className="lower-container">
          <div className="title_reports_container">
            <div className="lower-menu-items-container">
              <div className="report_group_container">
          {props.userAllowedMenuLinks.map((item) => {
            if (item[MENU_ITEM.COLUMNS.CATEGORY] === "menu_item" && item.menu_item_category === "ADMINISTRATION" && item.children?.length > 0) {
              return (
                <IconTitle
                    key={item.menu_item_machine_name}
                    title={item.menu_item_display_name}
                    subtitle={item[MENU_ITEM.COLUMNS.MENU_ITEM_DESCRIPTION]}
                    icon={<img alt={item.menu_item_machine_name} src={"/images/menu-svgs/" + item.menu_item_machine_name + ".svg"}/>}
                    tooltipOnHover={renderMenuItemsTooltip(item)}
                    titleClass={"landing-menu-item-report-title"}
                    subtitleClass={"menu-items-subtitle"}
                    svgClass={"report-grp-menu-items-icon-title-svg"}
                    iconTitleContainerClass={"menu-items-icon-title-container"}
                    type={item.menu_item_type}
                    parent_machine_name={item.menu_item_machine_name}
                    machine_name={item.menu_item_machine_name}
                    url={item.url ? item.url : "/" + item.menu_item_machine_name + "/" + item.menu_item_machine_name}
                    hasHoverEffect
                />    
              );
            }
          })}
          </div>
          </div>
          </div>
        </div>
      </div>
      {acknowledgementValue && acknowledgementValue.content.length > 0 && !props.switchClient ?
          <Modal
              id={"acknowledgement-dialog"}
              openDialog={sessionStorage.getItem("showAcknowledgeModal") === "false" ? false : showAcknowledgement}
              title={acknowledgementValue.title}
              bodyContent={acknowledgementModalBody}
              dialogActions={acknowledgementModalActions}
              size={DIALOG_SIZE.SMALL}
              removeClose={true}
              center={true}
          /> : ""}
    </>
  );
};

export { LandingPage };
