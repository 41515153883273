import { useEffect, useState } from "react";
import {ReactComponent as Microphone} from '../../styles/images/chatbot/big_microphone.svg'

const SoundFlicker = () => {
  const [isFlickering, setIsFlickering] = useState(false);

  useEffect(() => {
    let audioContext;
    let analyser;
    let dataArray;
    let microphone;
    let stream;
  

    const startAudio = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
        analyser = audioContext.createAnalyser();

        microphone = audioContext.createMediaStreamSource(stream);
        microphone.connect(analyser);

        analyser.fftSize = 256; // Size of the frequency data
        const bufferLength = analyser.frequencyBinCount;
        dataArray = new Uint8Array(bufferLength);

        const detectSound = () => {
          analyser.getByteFrequencyData(dataArray);
          const volume = dataArray.reduce((sum, value) => sum + value, 0) / bufferLength;
          setIsFlickering(volume > 50);
          requestAnimationFrame(detectSound);
        };

        detectSound();
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    };

    startAudio();
    
    return () => {
      if (audioContext) {
        if (stream) {
            stream.getTracks().forEach((track) => track.stop()); // Stop all media tracks
        }
        audioContext.close();
      }
    };
  }, []);


  return (
    // <img
    //     src="../../styles/images/chatbot/big_micropho.svg"
    //     alt="Flicker Effect"
    //     style={{
    //       opacity: isFlickering ? 1 : 0.5,
    //       transition: "opacity 0.1s",
    //     }}
    //   />
      <div className="micro_flicker">
        {/*<div*/}
        {/*    className={`micro_image_flicker ${isFlickering ? 'active' : ''}`}*/}
        {/*/>*/}
        <Microphone className="micro_image"/>

      </div>
  );
};

export default SoundFlicker;
