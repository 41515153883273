import React, { Component } from 'react';
import '../styles/login.css';
import '../styles/popup.css';
import '../styles/common.css';
import '../styles/globalStyles/loader.css';
import 'jquery';
import 'jqueryui';
import { addRowNumberToOptions, extractInitials } from '../class/jqueries';
import { excludeOptions, getTranslationFile, findOptionByKey, deepCompareObjects} from '../class/utils';
import Select, {components} from 'react-select';
import { UI_ACTIONS, ROW_NUMBER, ALL_REPORTS, APP_BLUE_COLOR, FY_VALUES, COLUMN_PROFILE, VIEWPORT_WIDTH, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DROPDOWN_TYPE } from '../class/constants';
import { is_aN } from '../class/number';
import shortid from 'shortid';
import SearchableSelect from '../components/SearchableSelect';
import { convertPxToViewport } from '../class/formatting';
import Button from '../newComponents/Button';
import Input from '../newComponents/Input';
import Dropdown from '../newComponents/DropDown';
import SwitchToggle from '../newComponents/SwitchToggle';
const lang = getTranslationFile();

const $ = require('jquery');
const Email = (props) => {
     const setEmail = () => {
        if(props.onChange){
            props.onChange($("#Email").val())
        }
     }

        return (
            <div className={("row login-input-container" + (props.className? " "+props.className:""))}>
				<label className="col-12 mrgl0" htmlFor="Username">Email address</label>
				<div className="col-12">
                    <Input 
                        placeholder="Please enter your email address" 
                        // className="form-control" 
                        id="Email" name="email" 
                        type="email" 
                        autoComplete="on"
                        required="required" 
                        onChange={() => setEmail()} 
                        style={{fontSize: convertPxToViewport(12)}}
                    />
			   </div>
		    </div>
        );
    }


const Password = (props) => {
    const setEmail = () => {
        props.onChange($("#Password").val())
    }
    const resetPassword = () => {
        props.resetPassword();
    }
        return (
            <div className="row uk-margin-xsmall-bottom login-input-container">
                <div className="password-label-container">
                    <label htmlFor="Password">Password</label>
                    <a className="resetPassword link_text_button_large" id="resetPassword" ui_tracking={UI_ACTIONS.FORGOT_PWD} onClick={resetPassword}>Forgot your password?</a>
                </div>
                <div className="col-12">
                    <Input placeholder="Please enter your password" 
                        // className="form-control"
                        id="Password"
                        name="password"
                        type="password" 
                        autoComplete="off" 
                        required="required" 
                        onChange={() => setEmail()} 
                        style={{fontSize: convertPxToViewport(12)}}
                    />
                </div>
            </div> 
        );
    }

const SubmitBtn = ({ id, onClick, className }) => {
        return (
          <Button
            label="Log in"
            id={id}
            className={className}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.LARGE}
            type={BUTTON_TYPE.DEFAULT}
            ui_tracking={UI_ACTIONS.LOGIN}
            onBtnClick={onClick}
          />
        );
}


class ResetPasswordBtn extends Component {
    render(){
        return (
            <div>
				<input type="button" value="Reset Password" className="red-btn btn_primary sign-in-button" onClick={this.props.onClick} ui_tracking={UI_ACTIONS.RESET_PWD}/>
		    </div>
        );
    }
}

class CancelBtn extends Component {
    render(){
        return (
            <div>
				<input type="button" value="Back" className="forgot-pass-back-btn" onClick={this.props.onClick}/>
		    </div>
        );
    }
}

class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

        this.changeTab =this.changeTab.bind(this);
    }

    changeTab (tab) {
        this.props.onChange(tab)
    }

    render(){
        let content;
        return (
            <div id={this.props.id ? this.props.id : ""} className={"tabs-container"}>
                {this.props.tabs ? this.props.tabs.map(tab => {
                    return <TabButton tabWithDotIcon={this.props.tabWithDotIcon} isActive={this.props.activeTab === tab} text={tab} changeTab={this.changeTab} key={tab} isModelingSettings={this.props.isModelingSettings}/>
                }):""}
            
            <div>{content}</div>
            </div>
        );
        }
  }
  
    const TabButton = ({text, changeTab, isActive,isModelingSettings, tabWithDotIcon}) =>{
        
        const myStyle = { 
            background:"transparent", 
            border:"none", 
            // color:"#333", 
            borderBottom: convertPxToViewport(2)+ " solid #e0e0e0",
            minWidth: convertPxToViewport(120), 
            paddingBottom: convertPxToViewport(5),
            position:"relative"
            // fontSize: convertPxToViewport(14),
        }
        if(isModelingSettings){
            myStyle.textAlign = 'left';
            myStyle.minWidth = convertPxToViewport(160);
        }
        return(
            <div className={isActive?"vertical_tab_active":'vertical_tab'}>
                <button style={myStyle} className={isActive ? 'pi-active-tab pi-active-tab-new' : 'pi-tab pi-tab-new'} onClick={() => changeTab(text)}>
                    {text}
                    {tabWithDotIcon && tabWithDotIcon === text &&
                        <span className="new_added_dot"></span>
                    }
                </button>
            </div>
        )
    }

  const Tab = props =>{
    return(
      <React.Fragment>
        {props.children}
      </React.Fragment>
    )
  }

/**
 *
 * Loader for all screens
 * @param {String} uniqueClassName this is used to have a specific loader for a specific event and not a global loader that will be displayed when firing toggleLoader
 * @version 1.0.0
 * @author [Lara Jannoun]
 */
class Loader extends Component {
  render() {
    let loaderClassName = "indexPlus loading";
    loaderClassName = this.props.uniqueClassName ? this.props.uniqueClassName + " default-loader" : loaderClassName;
    loaderClassName = this.props.newLoader ? "loader-body" : loaderClassName;

    return (
      <div
        id="loading"
        className={`${this.props.fullContainer === false ? "uk-width-1-3" : ""} loader-container 
        ${loaderClassName} ${this.props.className || ""}`}
      >
        <div className="circle__loader">
          <div className="logo__image"></div>
        </div>
      </div>
    );
  }
}

class ConfigureLoader extends Component {
    render(){
        return (
            <div id="configureLoading" className={`${this.props.fullContainer === false ? "uk-width-1-3" : ""}  configure-loading configure-loader-container ${this.props.className || ""}`}>
                <div className="circle__loader">
                    <div className="logo__image"></div>
                </div>
            </div>
        );
    }
}

/**
 * Skeleton loader
 */
const SkeletonLoader = () => {   
    return (
        <div className="skeleton-container">
          <div className="default-line medium-line"></div>
          <div className="default-line"></div>
          <div className="default-line short-line"></div>
        </div>
    );
}

/**
 * Custom styling for react select component
 */
const CustomSelect = (props) => {
    const customStyles = {
        option: (styles) => ({
            ...styles,
            cursor: 'pointer',
            fontSize: convertPxToViewport(12),
            padding: convertPxToViewport(8)+' 0 '+convertPxToViewport(8)+' '+convertPxToViewport(9),
     
        }),
        control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            borderColor: '#dcdcdc',
            minHeight: convertPxToViewport(33),
            fontSize: convertPxToViewport(12),
            borderRadius: convertPxToViewport(4),
            borderWidth: convertPxToViewport(1),
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: convertPxToViewport(30),
            padding: '0 ' + convertPxToViewport(6)
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: convertPxToViewport(30),
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            paddingTop: convertPxToViewport(6),
            paddingBottom: convertPxToViewport(6),
        }),
        clearIndicator: (styles) => ({
            ...styles,
            paddingTop: convertPxToViewport(6),
            paddingBottom: convertPxToViewport(6),
        }),
        placeholder: defaultStyles => {
            return {
                ...defaultStyles,
                color: "#C6CCD7",
                lineHeight: convertPxToViewport(12)
            };
        },
        menu: provided => ({ ...provided, zIndex: 9999,})
    };
    return (
        <div onClick={()=>{props.showDimensionDropdown?props.showDimensionDropdown():""}} className='select-dimension-dropdown'>
            <Select
                classNamePrefix={"scenario-label " + (props.class || "")}
                styles={customStyles}
                menuPlacement="auto"
                maxMenuHeight={convertPxToViewport(190)}
                menuIsOpen={props.report === ALL_REPORTS.HEATMAP ? undefined : props.dimensionDropdownOpen}
                {...props}
            />
        </div>)
};

class LoaderWaitingMessage extends Component {
    render(){
        return (
            <div className={[this.props.fullContainer === false ? "uk-width-1-3" : "" ] + "loader-waiting-message"}>
                <p className={"first-message"}>{lang.loader_first_message}</p>
                <p className={"second-message"}>{lang.loader_second_message}</p>
            </div>
        );
    }
}

class CustomizedLoaderWaitingMessage extends Component {
    render(){
        return (
            <div className={[this.props.fullContainer === false ? "uk-width-1-3" : "" ] + "customized-loader-waiting-message"}>
                <p className={"first-message"}>{this.props.message}</p>
              
            </div>
        );
    }
}

/**
 *
 * stepper for configure page
 * @version 1.0.0
 * @author [Lara Jannoun]
 */
class Stepper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allSteps: null,
            activeStep: 0,
            finishStep: props.finishStep
        };
        
        this.renderSteps = this.renderSteps.bind(this);
        this.setStep = this.setStep.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.previousStep = this.previousStep.bind(this);

    }

    nextStep() {
        this.renderSteps(this.props.steps, this.state.activeStep+1);
    }

    previousStep() {
        this.renderSteps(this.props.steps, this.state.activeStep-1);
    }

    setStep(index) {
        this.renderSteps(this.props.steps, index);
    }

    getStep() {
        return this.state.activeStep;
    }

    renderSteps(steps, stepperIndex) {
        var counter = 0;
        let allSteps = [];
        if (this.props.preStep !== "" && stepperIndex === 2) {
            allSteps.push(<div className={"md-step"} key={"firstStepper"+counter}>
            <div className="md-step-oval md-step-circle" onClick={this.props.stepCallback}><i className='fas fa-angle-left fa-lg' alt='Expand'/>&nbsp;<span>{this.props.preTextMessage}</span></div>
            <div className="md-step-title"></div>
            <div className="md-step-oval-bar-right"></div>
        </div>)
        }
        $.map(steps, function (step) {
            counter++;
            if (step) {
                allSteps.push(<div className={(counter === stepperIndex === true ? " active " : counter < stepperIndex ? " done " : "") + "md-step" } key={"stepper"+counter}>
                    <div className="md-step-circle"><span>{counter}</span></div>
                    <div className="md-step-title">{step.title}</div>
                    <div className="md-step-bar-left"></div>
                    <div className="md-step-bar-right"></div>
                </div>)
            }
        });
        if (this.props.finishStep !== "" && stepperIndex === allSteps.length) {
            allSteps.push(<div className={"md-step"} key={"lastStepper"+counter}>
            <div className="md-step-oval md-step-circle" onClick={this.props.stepCallback} title="Once build screen is ready, you will be directed to it."><span>{this.props.textMessage}&nbsp;</span><i className='fas fa-angle-right fa-lg' alt='Expand'/></div>
            <div className="md-step-title"></div>
            <div className="md-step-oval-bar-left"></div>
        </div>)
        }

        this.setState({
            allSteps: allSteps,
            activeStep: stepperIndex
        })

    }

    componentDidMount() {
        this.renderSteps(this.props.steps, this.props.stepperIndex);
    }

    render() {

        return (
            <div className="md-stepper-horizontal">
                {this.state.allSteps ? this.state.allSteps : ""}
            </div>
        );
    }
}


class DimensionsDropdowns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dimensionOptions: [],
            dimensionValues: [],
            dimensionDropdownOpen: false
        }

        this.onChangeDimension = this.onChangeDimension.bind(this);
        this.fillDimensions = this.fillDimensions.bind(this);
        this.resetVectors = this.resetVectors.bind(this);
        this.resetVectorOptions = this.resetVectorOptions.bind(this);
    }

    resetVectors(vectors) {
        if (vectors) {
            for (var i = 0; i < vectors.length; i++){
                this.onChangeDimension({value: vectors[i], [ROW_NUMBER]:i}, true);
            }    
        }
    }
    resetVectorOptions(newOptions) {
        this.setState({
            dimensionOptions: newOptions
        })
    }

    fillDimensions(props) {
        if(!props.dimensionOptions || !props.dimensionOptions.length) {
            if(this.props.startAsEmpty) {
                this.setState({
                    dimensionOptions: props.dimensionOptions
                });
            }
            return;
        }
        var dimensionOptions = [];
        var dimensionValues = [];

        var propSelectedDimensions = [];
        props.selectedDimensions.map((item, key)=>{
            if(item) {
                var tempObj = {label: item, value: item};
                propSelectedDimensions[key] = addRowNumberToOptions([tempObj], key)[0];
            }
        });

        var counter = 0;
        for(var i=0; i < props.numberOfDimensions; i++) {
            //call addRowNumber... to be able to call onChange since it depends on rowNumber
            dimensionOptions[i] = addRowNumberToOptions(props.dimensionOptions, i);

            if(dimensionOptions[i] && dimensionOptions[i][counter] && dimensionOptions[i][counter].isDisabled) {     //check if this option is disabled, take the one after it
                counter++;
            }

            var currentDimensionObj = {};
            if(propSelectedDimensions && propSelectedDimensions.length) {
                currentDimensionObj = propSelectedDimensions[i]
            } else {
                currentDimensionObj = dimensionOptions[i][counter];  //set the value for this dropdown
            }
            dimensionValues[i] = currentDimensionObj;
            counter++;

            //call onChange function to remove the chosen option from the other dropdowns' options
            this.onChangeDimension(dimensionValues[i], true);
        }

        if(!this.props.startAsEmpty) {      //leave the dropdowns empty until the user selects an option
            this.setState({
                dimensionValues: dimensionValues
            });
        }
        
    }

    componentDidUpdate() {
        var shouldFillDim = false;
        var tempState = {}
        if(this.props.dimensionOptions && this.props.dimensionOptions.length || this.props.startAsEmpty) {
            if(!deepCompareObjects(this.props.dimensionOptions, this.state.dimensionOptions_props)) {
                tempState.dimensionOptions_props = this.props.dimensionOptions;
                shouldFillDim = true;
            }
            if(!deepCompareObjects(this.props.selectedDimensions, this.state.selectedDimensions_props) || (this.state.dimensionValues.length > 0 && this.state.dimensionValues[0].value !== this.state.selectedDimensions_props[0])) {
                tempState.selectedDimensions_props = this.props.selectedDimensions;
                shouldFillDim = true;
            }
            if(this.props.numberOfDimensions !== this.state.numberOfDimensions_props) {
                tempState.numberOfDimensions_props = this.props.numberOfDimensions;
                shouldFillDim = true;
            }
        }
        // var shouldFillDim = this.props.selectedDimensions.length && (
        
        if(shouldFillDim) {
            this.setState(tempState);
            this.fillDimensions(this.props);
        }
    }

    componentDidMount() {
        if(typeof this.props.fetchData === "function" && this.props.shouldFetchData) {
            this.props.fetchData(undefined, this.state.dimensionValues);
        }

        if(!deepCompareObjects(this.props.selectedDimensions, this.state.selectedDimensions_props)) {
            this.fillDimensions(this.props);
        }
        this.closeDimensionDropdown();
    }
    closeDimensionDropdown = () => {
        let _this = this;
        $(document).on("mouseover click", function (event) {
            if (($('*[class^="scenario-label"]:hover').length === 0) && (_this.state.dimensionDropdownOpen)) {
                if (event.type === "click") {
                    _this.setState({
                        dimensionDropdownOpen: false
                    })
                }
            }
        });
    }

    showDimensionDropdown = () => {
        let _this = this;
        _this.setState({
            dimensionDropdownOpen: !_this.state.dimensionDropdownOpen
        })
    }

    onChangeDimension(valueObj, fromMount = false) {
        if (!valueObj) return;
        var tempState = {};
        //set the value for the current dropdown
        tempState.dimensionValues = this.state.dimensionValues;
        if(!this.props.startAsEmpty && fromMount) {
            tempState.dimensionValues[valueObj[ROW_NUMBER]] = valueObj.value;
        }

        //remove option from other dropdowns
        if(this.props.excludeOptions) {
            var dimensionOptions = this.state.dimensionOptions;
            for(var i=0; i < this.props.numberOfDimensions; i++) {
                //retrieve the values for the other dropdowns, to exclude them from the options of this one
                var otherDropDownsValues = tempState.dimensionValues.filter((el, index)=>index !== i);
                // if(valueObj[ROW_NUMBER] !== i) {
                    dimensionOptions[i] = excludeOptions(this.props.dimensionOptions, otherDropDownsValues);
                // }
            }
            tempState.dimensionOptions = dimensionOptions;
        }

        this.setState(tempState);

        if (this.props.onChangeDimension && typeof this.props.onChangeDimension === "function" && !fromMount) {
            this.props.onChangeDimension(valueObj.value, valueObj[ROW_NUMBER], fromMount);
        }
    }
    
    render() {
       var _this = this;
        const optionLabel = ({label, isDisabled, vector_description, is_default, isGroupTitle, tooltipText}) => (
            <div className={(isGroupTitle ? "option-group-header" : "") +" option-padding uk-flex-between uk-display-flex"} uk-tooltip={!!tooltipText ? tooltipText : ""}>
                {label} {!isGroupTitle && !isDisabled ? <i className="fal fa-info-circle" uk-tooltip={(!vector_description || is_default) ? lang.no_vector_description : vector_description} 
                style={{"color":"white","marginTop":convertPxToViewport(5)}}></i> : !isGroupTitle && isDisabled ?  
                <i className="fal fa-info-circle" uk-tooltip={lang.segments_not_generated}
                style={{"color":"black","marginTop":convertPxToViewport(5)}}></i> : ""}
            </div>
        );
        var dropdowns = [];
        var dimensionSelectOptions = [];
        for(var i=0; i < this.props.numberOfDimensions; i++) {
            dimensionSelectOptions = addRowNumberToOptions(this.state.dimensionOptions[i], i);
            var label = lang.DIMENSIONS[Object.keys(lang.DIMENSIONS)[i]].DROP_DOWN_LABEL;
            if(ALL_REPORTS.HEATMAP === this.props.profitFormat) {
                label = lang.DIMENSIONS.HEATMAP[Object.keys(lang.DIMENSIONS)[i]].DROP_DOWN_LABEL;
            }
            if(ALL_REPORTS.CONTOUR_MAP === this.props.profitFormat) {
                label = lang.DIMENSIONS.CONTOURMAP[Object.keys(lang.DIMENSIONS)[i]].DROP_DOWN_LABEL;
            }
            dropdowns.push(
                <div className="dimension-label-dd" key={"dimension_"+i} uk-tooltip={this.props.dropdownTooltip ? this.props.dropdownTooltip : "title:" }>
                    {!_this.props.hideLabel && <label className="align-self-end" htmlFor="dimension">{label}</label>}
                    <Dropdown
                        filterOption={(option, searchText)=>{return option.label.toLowerCase().includes(searchText.toLowerCase())}}
                        disabled={this.props.vectorDisabled}
                        id={"select_dimension_"+i}
                        className={"select-dataSet uk-width-small select-half input__dropdown "+ (this.props.disabled ? " disabled disabled-dropdown " : "uk-cursor-pointer")}
                        // classNamePrefix={"dimension-label"}
                        name="dimension"
                        value={findOptionByKey(dimensionSelectOptions, this.state.dimensionValues[i]) || dimensionSelectOptions.find(option => !option.isGroupTitle)}
                        onChange={(option)=>this.onChangeDimension(option, false)}
                        options={dimensionSelectOptions}
                        formatOptionLabel={optionLabel}
                        onDropdownClick={this.showDimensionDropdown}
                        dimensionDropdownOpen={this.state.dimensionDropdownOpen}
                        report={this.props.profitFormat}
                        type={DROPDOWN_TYPE.INPUT}
                        showSelectedTooltip
                    />
                </div>
            )
        }

        return (
            <div className="dimensions-container">
                {dropdowns}
            </div>
        )
    }
}

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state ={
        };
    }

    componentDidUpdate() {
        var obj = this;      
        $(function() {
            ////TO UNCOMMENT***********************************/////////
        // Get the width of the parent element (to convert to percentage)
        const parentWidth = $(".ui-slider-handle.ui-state-default.ui-corner-all").parent().width();
        // Get the "left" value of the element in pixels
        const leftValueInPixels = parseFloat($(".ui-slider-handle.ui-state-default.ui-corner-all").css("left"));
        // Calculate the "left" value in percentage
        const leftValueInPercentage = (leftValueInPixels / parentWidth) * 100;
        let finalValue = leftValueInPercentage + "%";
        $("#slider.ui-slider").css("background", `linear-gradient(to right,
            #08155E 0%,
            #08155E ${finalValue},
            #D9D9D9 ${finalValue},
            #D9D9D9 100%)`);

            $("#"+obj.props.id ).slider({
                value:obj.props.value,
                min: obj.props.startPoint,
                max: obj.props.endPoint,
                change: function() {
                  const parentWidth = $(".ui-slider-handle.ui-state-default.ui-corner-all").parent().width();
                  // Get the "left" value of the element in pixels
                  const leftValueInPixels = parseFloat($(".ui-slider-handle.ui-state-default.ui-corner-all").css("left"));
                  // Calculate the "left" value in percentage
                  const leftValueInPercentage = (leftValueInPixels / parentWidth) * 100;
                  let finalValue = leftValueInPercentage + "%";
                  $("#slider.ui-slider").css("background", `linear-gradient(to right,
                      #08155E 0%,
                      #08155E ${finalValue},
                      #D9D9D9 ${finalValue},
                      #D9D9D9 100%)`);
                },
                step:obj.props.incrementVal,
                slide: function( event, ui ) {
                    if (obj.props.type === 'threshold') {
                        $(".numerated-div").find('.bold-text').removeClass("bold-text");
                        $("#" + ui.value.toString().replace('.', '') + "_percentage").addClass("bold-text");
                    } else {
                        $(".heatMapPeriods").find('div').each(function(){
                            $(this).css("font-weight","100");
                        });
                        $( "#"+ui.value.toString().replace('.','')+"_period").css("font-weight","bold");
                    }
                    if(obj.props.type === 'assigned_cost' || obj.props.type === 'assigned_psl'){
                        if(obj.props.type === 'assigned_cost'){
                            $( "#assigned_cost_val" ).html(ui.value);
                        } else {
                            $( "#assigned_psl_val" ).html( ui.value);
                        }
                    } else {
                        obj.props.setLimit(ui.value);
                    }
                }
            });
        });
    }

    componentDidMount() {
        var obj = this;
        $(function () {
            if (obj.props.value) {
                if (obj.props.type === 'threshold') {
                    $("#" + obj.props.value.toString().replaceAll('.', '') + "_percentage").addClass("bold-text");
                } else {
                    $("#" + obj.props.value.toString() + "_period").css("font-weight", "bold");
                }
            }
            $("#" + obj.props.id).slider({
                value: obj.props.value,
                min: obj.props.startPoint,
                max: obj.props.endPoint,
                step: obj.props.incrementVal,
                change: function() {
                  const parentWidth = $(".ui-slider-handle.ui-state-default.ui-corner-all").parent().width();
                  // Get the "left" value of the element in pixels
                  const leftValueInPixels = parseFloat($(".ui-slider-handle.ui-state-default.ui-corner-all").css("left"));
                  // Calculate the "left" value in percentage
                  const leftValueInPercentage = (leftValueInPixels / parentWidth) * 100;
                  let finalValue = leftValueInPercentage + "%";
                  $("#slider.ui-slider").css("background", `linear-gradient(to right,
                      #08155E 0%,
                      #08155E ${finalValue},
                      #D9D9D9 ${finalValue},
                      #D9D9D9 100%)`);
                },
                slide: function (event, ui) {
                    if (obj.props.type === 'threshold') {
                        $(".numerated-div").find('.bold-text').removeClass("bold-text");
                        $("#" + ui.value.toString().replaceAll('.', '') + "_percentage").addClass("bold-text");
                    } else {
                        $(".heatMapPeriods").find('div').each(function () {
                            $(this).css("font-weight", "100");
                        });
                        $("#" + ui.value.toString().replace('.', '') + "_period").css("font-weight", "bold");
                    }
                    if (obj.props.type === 'assigned_cost' || obj.props.type === 'assigned_psl') {
                        if (obj.props.type === 'assigned_cost') {
                            $("#assigned_cost_val").html(ui.value);
                        } else {
                            $("#assigned_psl_val").html(ui.value);
                        }
                    } else {
                        obj.props.setLimit(ui.value);
                    }
                }
            });
            ////TO UNCOMMENT***********************************/////////
            // Get the width of the parent element (to convert to percentage)
            const parentWidth = $(".ui-slider-handle.ui-state-default.ui-corner-all").parent().width();
            // Get the "left" value of the element in pixels
            const leftValueInPixels = parseFloat($(".ui-slider-handle.ui-state-default.ui-corner-all").css("left"));
            // Calculate the "left" value in percentage
            const leftValueInPercentage = (leftValueInPixels / parentWidth) * 100;
            let finalValue = leftValueInPercentage + "%";
            $("#slider.ui-slider").css("background", `linear-gradient(to right,
                        #08155E 0%,
                        #08155E ${finalValue},
                        #D9D9D9 ${finalValue},
                        #D9D9D9 100%)`);
        });
    }

    render(){
        return(
            <div className='slider_container'>
                <div className={this.props.className ? this.props.className : ""} id={this.props.id}></div>
                <input type="hidden" id="threshold" className={this.props.className ? this.props.className : ""}/>
            </div>
        )
    }
}

class RoundSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            min: 0,
            max: 100,
            step: 1,
            isSlim: this.props.isSlim || false,
            isNumerated: !!this.props.numberOptions || false,
        };

        this.init = this.init.bind(this);
        this.setValue = this.setValue.bind(this);
        this.handleNumberClick = this.handleNumberClick.bind(this);
        
        this.currentVal = this.state.value;
    }

    /**
     * this function is used to initialize the object with
     * custom params passed from parent
     * @param {*} initObj 
     */
    init(initObj = {}) {
        let _this = this;

        this.setState({
            value: initObj.value || this.state.value,
            min: initObj.min || this.state.min,
            max: initObj.max || this.state.max,
            step: initObj.step || this.state.step
        }, function(){
            $(_this.refs.slider_bar).slider({
                value: _this.state.value,
                min: _this.state.min,
                max: _this.state.max,
                step: _this.state.step,
                
                stop: function( event, ui ) {
                    if(typeof _this.props.setValueCallback === "function") {
                        _this.currentVal = ui.value;
                        _this.props.setValueCallback(ui.value);
                    }
                }
            });
            _this.props.setValueCallback(this.state.value, true);     //upon initialization, call parent callback to set the initial value
        });

        this.currentVal = initObj.value || this.state.value;
    }

    handleNumberClick(value) {
        this.setValue(Number(value));
        this.props.setValueCallback(value);
    }

    /**
     * this function is used by the parent component to set the value when updated from outside
     * @param {*} value 
     */
    setValue(value) {
        if(is_aN(value) && parseFloat(value) !== this.currentVal) {
            //check added so that when slider propagates a value to be updated to the parent,
            //we do not want it to be re-updated if that same value comes back to it,
            //i.e: when props.setValueCallback causes the calling of this function
            this.currentVal = value;
            $(this.refs.slider_bar).slider( "option", "value", parseFloat(value));
        }
    }

    componentDidMount() {
        this.init(this.props.init);
    }

    render(){
        let numberOptions = [];
        if(!!this.props.numberOptions) {
            numberOptions = this.props.numberOptions.map(opt=>{
                let value = opt.value || opt;
                let label = opt.label || opt;
                let isSelected = this.currentVal === value;
                return <div id={"round-slider-"+label} key={"FY-num-"+value} className={"slider-option uk-cursor-pointer"+ (isSelected?" uk-text-bold":"")} onClick={()=>this.handleNumberClick(value)}>{label}</div>
            })
        }

        let isCustomWidth = !!this.props.width;
        //props.isDisabled <-- for disabling the complete functionality of the slider
        //props.disabledSlider <-- for disabling the slider only, and using the number options instead
        let disabledClass = this.props.isDisabled ? "disabled":"";
        return(
            <div className={"round-slider-container "+(isCustomWidth?"":"uk-width-full") + " " + disabledClass} style={isCustomWidth ? {width: convertPxToViewport(this.props.width)} : {}}>
                <div ref="slider_bar" className={"slider-bar uk-width-full "} style={this.props.isSlim ? {height: convertPxToViewport(6)} : {}}></div>
                {this.state.isNumerated ?
                    <div className={"slider-label uk-width-full uk-display-inline-flex uk-flex-between uk-margin-xsmall-top "}>{numberOptions}</div>
                :""}
            </div>
        )
    }
}

class PeriodCostTab extends Component {
 
    constructor(props) {
        super(props);
        this.state = {

            value: this.props.defaultValue || this.props.options[0].value,
        }
    }

    handleTabClick = (value) => {
        if (this.state.value !== value) {
          this.setState({ value }, () => {
            if (typeof this.props.onSelectTab === "function") {
              this.props.onSelectTab(value);
            }
          });
        }
      };

    onSelectTab(option) {
        let value = $(option.target).attr("value") || $(option.currentTarget).attr("value");
        this.setState({
            value: value,
        }, () => {
            if(typeof this.props.onSelectTab === "function" && value) {
                this.props.onSelectTab(value);
            }
        });
    }

    render() {
        const myStyle = { 
            background:"transparent", 
            border:"none", 
            color:"black", 
            minWidth: convertPxToViewport(120), 
            paddingBottom: convertPxToViewport(5),
            fontSize: convertPxToViewport(15),
        }
        const { options } = this.props;
        const { value } = this.state;
        
        return (
          <ul className="uk-tab pi-tab period">
            {options.map((opt) => (
              <li
                key={opt.value}
                className={value === opt.value ? "pi-active-tab-2" : "pi-tab"}
                onClick={() => this.handleTabClick(opt.value)}
              >
                <a style= {myStyle} className="pi-tab uk-text-decoration-none uk-flex-center uk-text-capitalize">{opt.label}</a>
              </li>
            ))}
          </ul>
  
        );
    }
}

class ToggleTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
          value: props.defaultValue
        }

    }

    componentDidUpdate(prevProps) {
      if(prevProps.defaultValue !== this.props.defaultValue) {
        this.setState({
          value: this.props.defaultValue
        })
      }
    }

    onSelectTab(option) {
        let value = $(option.target).attr("value") || $(option.currentTarget).attr("value");
        this.setState({
            value: value,
        }, () => {
            if(typeof this.props.onSelectTab === "function" && value) {
                this.props.onSelectTab(value);
            }
        });
    }

    render() {
        const _this = this;
        let { options } = this.props;
        let uniqueCLassNameForAll = this.props.uniqueCLassNameForAll;

        let tabs = [];
        let type = this.props.type;
        let liWidth = 100 / options.length;  //to reserve 10% for the padding
        let liMargin = 5 / (options.length - 1); //-1 cz we are not setting a margin for the first option
        let splitByRow = _this.props.splitRows && options.length > 3;
        options.forEach((opt, index) => {
            let isActive = _this.state.value === opt.value;

            tabs.push(
                <li  key={"tab-" + opt.value + type} onClick={(val) => !opt.isDisabled && this.onSelectTab(val)} value={opt.value}
                    className={(isActive ? "uk-active" : "") + " height-30 uk-padding-remove-left uk-text-center " + (opt.isDisabled? " cursorNotAllowed" : " uk-cursor-pointer") + (splitByRow ? " uk-width-1-5":"") +(this.props.isDisabled ? " disabled" : "") + (uniqueCLassNameForAll ? " " + uniqueCLassNameForAll : "")}
                    style={!splitByRow ? {width: liWidth + "%"} : {}} uk-tooltip={opt.tooltip ? opt.tooltip : ""}>
                    <a key = {"tab-" + opt.value + type+"a"}value={opt.value}
                       className={"uk-display-flex uk-flex-middle uk-flex-center uk-height-1-1 uk-text-decoration-none uk-text-capitalize uk-text-medium " + (opt.isDisabled? " disabled" : "") + (uniqueCLassNameForAll ? " " + uniqueCLassNameForAll : "")}
                       aria-expanded="false">
                        {opt.iconClass && opt.iconClass !== "" ?
                            <i value={opt.value} className={opt.iconClass + " uk-padding-xxsmall-left-right" + (isActive ? " uk-active" : "") + (uniqueCLassNameForAll ? " " + uniqueCLassNameForAll : "")}/>
                            : ""
                        }
                        <span key = {"tab-" + opt.value + type+"span"} className={"uk-lightbox-caption" + (uniqueCLassNameForAll ? " " + uniqueCLassNameForAll : "")}>{opt.label}</span>
                    </a>
                </li>
            );
        });

        return (
            <ul className={(splitByRow ? "uk-grid-small uk-grid " : "toggle-tab width-fit-content flex-wrap ") + "default_toggle_tabs uk-margin-remove-left"} uk-tooltip={this.props.toolTip?this.props.toolTip:"title:"}>
                {tabs}
            </ul>
        );
    }
}


/**
 * Important Note: when using this component, if we're showing a second modal immediately after hiding
 * the first one, even if we're using the same component, they might interfere with each other. If the
 * modal-backdrop remains and the screen becomes irresponsive, just set a small timer between hiding the
 * modal and its callback. Ex: Manage Columns -- startDelete().
 */
class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            variableList: []
        }

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.setVariableList = this.setVariableList.bind(this);

        this.id = this.props.id || "modal_"+shortid.generate();
    }

    setVariableList(list) {
        if(!Array.isArray(list)) {
            return;
        }
        
        this.setState({
            variableList: list
        })
    }

    setElementsList(list) {
        if(!Array.isArray(list)) {
            return;
        }
        
        this.setState({
            elementsList: list
        })
    }

    /**
     * this function receives parameters that override the props.
     * if the text in this modal does not change, call with no params and send them as props instead
     * @param {*} text 
     * @param {*} trueText 
     * @param {*} onTrueCB 
     * @param {*} falseText 
     * @param {*} onFalseCB 
     */
    showModal(title, text, trueText, onTrueCB, falseText, onFalseCB, cancelText, onCancelCB) {
        this.setState({
            title: title,
            text: text,
            trueText: trueText,
            falseText: falseText,
            onTrueCallback: onTrueCB,
            onFalseCallback: onFalseCB,
            cancelText: cancelText,
            onCancelCB: onCancelCB
        }, ()=>{
            window._profitIsleOpenModal(this.id);
        });
    }

    hideModal() {
        window._profitIsleCloseModal(this.id);
    }

    scrollOnHideModal() {
        let onTrueCallback = this.state.onTrueCallback || this.props.onTrue || function () { };
        this.props.hideModal();
        onTrueCallback();
    }

    render() {
        let message = this.state.text || this.props.text || "";
        let _text = []
        if(message.includes(lang.modal.variable_place_holder)) {
            _text.push(<h4 className="uk-padding-small uk-text-center">{message.split(lang.modal.variable_place_holder)[0]}</h4>)
            this.state.variableList.forEach(item=>{_text.push(<h4 className="uk-text-center">{"- " + item.pslName}</h4>)});
            _text.push(<h4 className="uk-padding-small uk-text-center">{message.split(lang.modal.variable_place_holder)[1]}</h4>)
        } else {
            _text = <h4 className={this.state.cancelText? "uk-padding-xsmall" : "uk-padding-small uk-text-center"}>{message}</h4>
        }
        let _elementsList = this.state.elementsList || [];

        let _title = this.state.title || this.props.title || undefined;
        let trueText = this.state.trueText || this.props.trueText || lang.modal.buttons.ok;
        let falseText = this.state.falseText || this.props.falseText || undefined;
        let onTrueCallback = this.state.onTrueCallback || this.props.onTrue || function(){};
        let onFalseCallback = this.state.onFalseCallback || this.props.onFalse || function(){};
        let cancelText = this.state.cancelText;
        let onCancelCB = this.state.onCancelCB || this.props.onCancelCB || function(){};
        return (
            <div id={this.id} className="index modal fade" role="dialog">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="uk-display-flex uk-flex-between uk-padding-small">
                            {_title ? 
                                <div className="fs-20">{_title}</div>
                                :
                                <div></div>
                            }
                            <Button 
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.ICON}
                                type={BUTTON_TYPE.DEFAULT}
                                className="close-modal-button "
                                data-dismiss="modal"
                                leftIcon={<i className="fa-2x fal fa-times" />}
                                aria-label="Close"
                                onBtnClick={() => this.props.hideModal ? this.props.hideModal():""}
                            />
                        </div>
                        <div className="modal-body uk-padding-small">
                            {_text}
                            {_elementsList}
                            <div className="uk-display-flex justify-content-end uk-padding-large-top uk-padding-small-right">
                                {cancelText?
                                    <Button 
                                        label={cancelText}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        data-dismiss="modal"
                                        className="uk-margin-default-right"
                                        onBtnClick={onCancelCB}
                                    />
                                :""}
                                {falseText ?
                                    <Button 
                                        label={falseText}
                                        variant={BUTTON_VARIANT.SECONDARY}
                                        size={SIZES.DEFAULT}
                                        type={BUTTON_TYPE.DEFAULT}
                                        data-dismiss="modal"
                                        className="uk-margin-default-right"
                                        onBtnClick={onFalseCallback}
                                    />
                                    :""}
                                <Button 
                                    label={trueText}
                                    variant={BUTTON_VARIANT.PRIMARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    data-dismiss="modal"
                                    onBtnClick={() => this.props.hideModal ? this.scrollOnHideModal() : onTrueCallback()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class CircleLoader extends Component {

    render() {
        let perc = this.props.percentage;
        let radius = this.props.radius;
        let strokeWidth = radius / 5;
        let circumference = 2 * Math.PI * radius;
        let offset = circumference - (circumference * perc) / 100;
        let frontCircleColor = this.props.color || APP_BLUE_COLOR;
        let width = radius * 2 + strokeWidth * 2;
        let height = radius * 2 + strokeWidth * 2;
        let fontSize = radius * 3/4;

        let animationName = "percentage-loader";    //define the animation name
        let keyframes = `@-webkit-keyframes ${animationName} {
            0% {stroke-dashoffset: 0; stroke-width: 0;}
        }`;   //define the animation behaviour corresponding to this animation
        
        //when loading document stylesheets, they might contain CSS files loaded from different domains, and trying to access their contents would throw a security Exception.
        //so we filter out the ones that are from this domain and that we have access to, to use them for injecting the CSS rules.
        const styleSheets = Array.from(document.styleSheets).filter(sheet => !sheet.href || sheet.href.startsWith(window.location.origin));
        let styleSheet = styleSheets[0];
        if (styleSheet instanceof CSSStyleSheet && styleSheet.cssRules) {
            styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
        }
        
        let circleStyle = {     //common for both circles
            fill: "none",
            strokeWidth: strokeWidth,
            strokeDasharray: circumference,
            width: width,
            height: height
        }
        let backCircleStyle = {     //only the background circle
            strokeDashoffset: 0,
	        stroke: "#424242"
        }
        let frontCircleStyle = {        //only the frontground circle
            strokeDashoffset: offset,
            stroke: frontCircleColor,
            animationName: animationName,
            animationDuration: "1.5s",
            animationTimingFunction: "linear",
            animationDelay: "1s"
        }
        frontCircleStyle = Object.assign({}, circleStyle, frontCircleStyle);
        backCircleStyle = Object.assign({}, circleStyle, backCircleStyle);

        return(
            <div className={"circle-loader " + this.props.className} style={{width: width, height: height}}>
                <svg style={{width: width, height: height, transform: "rotate(-90deg)"}}>
                    {this.props.showBackCircle ?
                        <circle className={"circle-loader-back-circle"} cx={width/2} cy={height/2} r={radius}
                            style={backCircleStyle}></circle>
                    :""}
                    <circle className={"circle-loader-front-circle"} cx={width/2} cy={height/2} r={radius}
                        style={frontCircleStyle}></circle>
                </svg>

                <div className="number" style={{bottom: radius + fontSize - 1, position: "relative"}}>
                    {/* <h2>{formatValString(perc, FormatTypes.PERCENTAGE)}</h2> */}
                    <h2 className="uk-flex uk-flex-center" style={{fontSize: fontSize}}>{perc + "%"}</h2>
                </div>
            </div>
        )
    }
}

class Badge extends Component {
    render(){
        return (
            <div className={"notification-badge uk-lightbox-caption " + (this.props.className ? this.props.className : "")}>{this.props.count}</div>
        );
    }
}

class ToggleValue extends Component{
    render(){
        return (
            <div className="uk-flex uk-flex-middle">
                <div uk-tooltip={this.props.isDisabled?lang.data_still_loading:"title:"}>
                    <SwitchToggle isDisabled={this.props.showHistory || this.props.isDisabled} value={this.props.value} checked = {this.props.value === FY_VALUES.ON ? true : false}
                        onChange={this.props.onChangeValue} />
                </div>
                <p className="fs-14 uk-margin-xsmall-left">{this.props.titleConstant}</p>
                <p className=" uk-text-bold fs-14 uk-margin-xsmall-left">{this.props.titleVariable}</p>
            </div>
        );
    }
}

class DropDownMenu extends Component {
    onDelete(e, data){
        e.stopPropagation();
        this.props.onDelete(data);
    }
    render(){
        var _this = this;
        const customStyles = {
            control: (styles) => ({ ...styles, cursor: 'pointer', border: 'unset', background: '#d9d9d9', borderRadius: convertPxToViewport(4), borderWidth: convertPxToViewport(1)}),
            placeholder: (styles) => ({ ...styles, color: '#FFF', fontSize: convertPxToViewport(12) }),
            option: (styles) => ({ ...styles, cursor: 'pointer', padding: convertPxToViewport(8) + ' ' + convertPxToViewport(4) }),
            menu: styles => ({ ...styles, width: convertPxToViewport(330) })
        };
        const OptionComponent = props => {
            let data = props.data;
            return (
                <components.Option {...props}>
                    {data[_this.props.groupField] ?
                        <div className="uk-flex uk-flex-middle manage_columns_group">
                            {/* <i className={data.faClass + " uk-padding-xsmall-right"}></i> */}
                            <span className='manage_columns_label_text'>{props.label}</span>
                        </div>
                    :
                    <div title={data.description} className='dropdown_row_fullheight'>
                        <div className="uk-flex uk-flex-between uk-flex-middle dropdown_row_fullheight">
                            <div className="fs-12 uk-flex uk-flex-middle">
                                <span className="pi-text-white-space-wrap uk-padding-xxsmall-right">{props.label}</span>
                                {!data[COLUMN_PROFILE.IS_CREATOR_SYSTEM]?
                                    <em className="user-full-name text-white">{data[_this.props.displayName]}</em>
                                    :""
                                }
                            </div>
                            <div className="uk-flex uk-flex-between uk-flex-middle">
                                { !data.editAllowed ? "" :
                                    <React.Fragment>
                                        <i className="manage_columns_hover_options text-white fa-lg fal fa-edit uk-margin-small-right" onClick={(e)=>_this.props.onEdit(e, data)}></i>
                                        { !data.is_company_default?
                                            <i className="manage_columns_hover_options text-white fa-lg fal fa-trash-alt uk-margin-small-right" onClick={(e)=>this.onDelete(e, data[_this.props.idKey])}></i>
                                            :""
                                        }
                                        
                                    </React.Fragment>
                                }
                                {data.value === _this.props.idMadeAsDefault ?
                                    <span className="disabled uk-margin-xsmall-right-left">{lang.manage_columns.text.default}</span>
                                :
                                    <a className="manage_columns_hover_options text-white fs-12 uk-text-nowrap" onClick={(e)=>_this.props.onMakeDefault(e, data)}>{lang.manage_columns.text.make_default}</a>
                                }
                            </div>
                        </div>
                    </div>
                    }
                </components.Option>
            );
        }
        return (
            <div className={"manage-columns split__button uk-flex uk-margin-default-left"}>
                <div uk-tooltip={this.props.tooltip}>
                    <Button  
                        label= {this.props.title}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT} 
                        aria-hidden="true"
                        disabled={this.props.isButtonDisabled}
                        className="split__button--modal uk-margin-medium-left"
                        onBtnClick={this.props.toggleBoard}
                        leftIcon={<i className="far fa-plus-circle fa-lg" />}
                    />
                </div>
                <div id={"searchable_select_container"+this.props.constraint}>
                    <SearchableSelect ref={el=>this.menuRef=el} constraint={this.props.constraint} options={this.props.options} OptionComponent={OptionComponent} value={this.props.value} fromDashboard={true} onChange={this.props.onChange} placeholder={this.props.placeholder}></SearchableSelect>
                </div>
            </div>
        );
    }
}


export default Slider;

export {Email, Password, SubmitBtn, ResetPasswordBtn, CancelBtn, Loader, SkeletonLoader, LoaderWaitingMessage, Stepper, 
     DimensionsDropdowns, Slider, RoundSlider, ToggleTab,PeriodCostTab, Modal, Tab, Tabs,
    CircleLoader, Badge, DropDownMenu, CustomSelect, ToggleValue ,ConfigureLoader,CustomizedLoaderWaitingMessage};
