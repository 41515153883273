import { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom";
import ErrorBoundary from "../../ErrorBoundary";
import ProfitStacks from "../../sections/profitStacks/ProfitStacks";
import { ReportHeader } from "../ReportHeader";
// import { Modal } from "@mui/material";
import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import {
  ALL_REPORTS,
  ALL_WIDGETS,
  AMOUNT_FIELD,
  AMOUNT_TITLE,
  BUTTON_DROPDOWN_VARIANT,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  CONFIGURE_TOGGLE_TABS,
  CONFIGURE_TOGGLE_TABS_NOT_LIST,
  CURRENT_FILTER_COOKIE,
  DIALOG_SIZE,
  DRILL_PARAMETERS_LABEL,
  DROPDOWN_TYPE, ENTITY_STACKS_OBJECT, EXPORT_CONTAINER,
  FILTER,
  FIRST_ATTRIBUTE,
  FY_VALUES,
  HEADER_ELEMENT, LISTENER, MENU_ITEM, MOM_CONFIGURE_TOGGLE_TABS,
  PROFIT_STACK_VIEW,
  SAVE_COMPANY_FILTERS,
  SAVED_FILTER_ID_COOKIE,
  SCENARIO_STATUS,
  SELECTED_DRILL_LIST,
  SIZES,
  UI_ACTIONS,
} from "../../class/constants";
import { lang } from "../../language/messages_en";
import { DimensionsDropdowns, Loader, SkeletonLoader } from "../../form/elements";
import NewBanner from "../../components/NewBanner";
import Button from "../../newComponents/Button";
import {
  areObjectsNotEmpty,
  checkIfMonthsValid,
  copyObjectValues,
  findOptionByKey,
  getSectionExists,
  isObjectNotEmpty,
  tryParse,
  updateChildAttrByKey
} from "../../class/utils";
import { messageDialogContent, renderButton, renderPeriodCalendar, renderTitle, tablesToExcel } from "../functions/componentFunctions";
import { generateQuarter, getActualYear, getPeriodDifference, getPeriodFromDate, getPeriodTextFromMonth } from "../../class/date";
import SwitchToggle from "../../newComponents/SwitchToggle";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useAuthenticated, useCalendarToggle, useExportScopeFilter, useLoading } from "../CustomHooks";
import { getClientPeriods, getCustomViewData, getDatasets, getFilterEntities, getPeriodsStatus, getVectors, logout } from "../api/api";
import {
  updateDatasetState,
  updatePeriodsStatusState,
  updatePsViewsState,
  updateVectorState,
} from "../../actions/scenariosActions";
import { convertPxToViewport } from "../../class/formatting";
import DropDown from "../../newComponents/DropDown";
import { formatAdvancedFilter, formatBasicFilter, getCurrency, getLocalStorageValueByParameter, handleCurrency, setLocalStorageValueByParameter, updateWindowLoading } from "../../class/common";
import { readCookie, saveCookie } from "../../class/jqueries";
import AppliedFilters from "../../components/AppliedFilters";
import { addCorrespondingParenthesisToFilter } from "../functions/filterFunctions";
import Modal from "../../newComponents/Modal";
import FilterByDropdown from "../../components/FilterByDropdown";
import FilterDialog from "../../sections/filter/FilterDialog";
import { getNewEntityFilter } from "../../sections/filter/FilterHelperFunctions";
import { LoaderSkeleton } from "../LoaderSkeleton";
import ButtonDropdown from "../../newComponents/ButtonDropdown";
import RangeStackConfiguration from "../../sections/profitStacks/RangeStackConfiguration";
import {disablePeriods, getLastBuiltPeriod} from "../functions/periodFunctions";
import { QuickFilters } from "../../components/quickFilters/QuickFilters";
import { updateDrillListData, updateListData, updateListObjBeforeBack } from "../../actions/listActions";
import { updateHeatmapListData, updateHeatmapObjBeforeBack } from "../../actions/heatMapActions";
import { updateLandscapeBubbleListData, updateLandscapeBubbleObjBeforeBack, updateLandscapeCostHierarchy } from "../../actions/landscapeBubbleActions";
import { updateGeographyCostHierarchy, updateGeographyListData, updateGeographyObjBeforeBack } from "../../actions/geographyConcentrationActions";
import { useListener } from "../../actions/CustomEventHandler";
/**
 * @author Mostafa Haydar
 * @description component renders title based on the additional Props sent , filter,period calendar or period drop down based on he additional Props sent , Apply button,  expand all button and export excel button with  {@link Profitstacks} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link ProfitStacks}
 */
const StackWrapper = (props) => {
  /**Store */
  //  const profiles = useSelector(state=>state.profiles);
  //  const stateProfiles = useSelector(state=>state.stateProfiles);
  //  const customGroups = useSelector(state=>state.customGroups);
  //  const profileColumns = useSelector(state=>state.profileColumns);
  //  const columnProfiles = useSelector(state=>state.columnProfiles);
   const psLinesOptions = useSelector(state=>state.psLinesOptions);
   const quadrantTierOptions = useSelector(state=>state.quadrantTierOptions);

  const userAllowedMenuLinks = props.userAllowedMenuLinks;
  const userSettings = props.userSettings;
  const scenarioState = props.scenarioState;
  const switchClient = props.switchClient;
  const { isMenuOpen } = props;

  /**References & react utility */
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const manageColsOverlay = useRef();
  const reportRef = useRef();
  const appliedFiltersRef = useRef();
  const filterDialRef = useRef();
  const filterByRef = useRef();
  const configDropdownStacksHeader = useRef();
  const countRef = useRef(0);
  const filterSet = useRef(false);
  const configureCompRef = useRef();
  const configureDropDownHeaderRef = useRef();

  /**State */
  const [periodsStatusState, setPeriodsStatusState] = useState({});
  const [vectorState, setVectorState] = useState({});
  const [datasetState, setDatasetState] = useState({});
  const [PSViewsState, setPSViewsState] = useState({});
  const [clientPeriodsState, setClientPeriodsState] = useState([]);
  const [disabledTootlip, setDisabledTooltip] = useState("");

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
  const { toggleCalendar } = useCalendarToggle();
  const loading = useLoading();
  const exportScopeFilter = useExportScopeFilter(userSettings, datasetState?.datasetOptions, vectorState?.vectorOptions, filterDialRef?.current?.state?.psLinesOptions);

  /**Filter Related States */
  const [calendarVisible, showCalendar] = useState(false);
  const [filterFinal, setFilterFinal] = useState(
    props.history.location.state
      ? props.history?.location?.state?.dimensionListFilter ? 
      props.history?.location?.state?.dimensionListFilter :
      props.history.location.state.outsideFilter
        ? JSON.stringify(tryParse(props.history.location.state.outsideFilter).filter) || []
        : []
      : []
  );
  const [filterFinalBasic, setFilterFinalBasic] = useState(
    props.history.location.state
      ? props.history.location.state.mainFilterFinalBasic
        ? tryParse(props.history.location.state.mainFilterFinalBasic) || []
        : []
      : []
  );
  const [basicFilterEntities, setBasicFilterEntities] = useState([]);
  const [exportQueryFilter, setExportQueryFilter] = useState("");
  const [displayFilter, setDisplayFilter] = useState();
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [isAdvancedFilterApplied, setIsAdvancedFilterApplied] = useState();
  const [tempFilter, setTempFilter] = useState();
  const [basicFilterEntitiesState, setBasicFilterEntitiesState] = useState([]);
  const [count, setCount] = useState(0);
  const [basicFilterItem, setBasicFilterItem] = useState();
  const [drillFilter, setDrillFilter] = useState();

  /**Header Related States */
  const [headerChangedCounter, setHeaderChangedCounter] = useState(0);
  const [headerElements, setHeaderElements] = useState([]);

  const [profitFormat, setProfitFormat] = useState(ALL_WIDGETS.FIELDS.PROFIT_STACK);
  const [reportTitle, setReportTitle] = useState(props.additionalProps?.isMoM
      ? ALL_WIDGETS.FIELDS.MOM_STACKS
      : props.additionalProps?.isYTD
      ? ALL_WIDGETS.FIELDS.YTD_STACKS
      : props.additionalProps?.isYOY
      ? ALL_WIDGETS.FIELDS.YOY_STACKS
      : props.additionalProps?.isTotal
      ? ALL_WIDGETS.FIELDS.TOTAL_STACKS
      : props?.history?.location?.state?.bubbleReportTitle
      ? props.history.location.state.bubbleReportTitle
      : ALL_WIDGETS.FIELDS.ENTITY_STACKS);
  const [manageColumnsProfile, setManageColumnsProfile] = useState();
  const [openLimitAccessDialog, setOpenLimitAccessDialog] = useState(false);
  const [limitAccessMsg, setLimitAccessMsg] = useState("");
  const [viewStackOpen, setViewStackOpen] = useState(false);
  const [showGreyOverLay, setShowGreyOverLay] = useState(true);
  const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
  const [callGo, setCallGo] = useState(false);
  const [headerChanged, setHeaderChanged] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState(lang.header.custom_text.download_start);
  const [dataLength, setDataLength] = useState(0);
  const [isDrilling, setIsDrilling] = useState(false);
  const [openInfoDialog, setOpenInfoDilog] = useState();
  const [infoDialogMsg, setInfoDialogMsg] = useState();
  const [openFilterDialog, setOpenFilterDialog] = useState();
  const [isTableExpanded, setTableExpanded] = useState(false);
  const [isExpandDisabled, setExpandDisabled] = useState(true);
  const [isExportExcelDisabled, setIsExportExcelDisabled] = useState(true);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [warningMsg, setWarningMsg] = useState("");
  const [customViewId, setCustomViewId] = useState();
  const [checkOnCustom, setCheckOnCustom] = useState(false);
  const [periodRange, setPeriodRange] = useState();
  const [isFromDashboards, setIsFromDashboards] = useState(history?.location?.state?.isFromDashboards);
  const [isConcatenationOfFilters, setIsConcatenationOfFilters] = useState(history?.location?.state?.isConcatenationOfFilters);
  const [menuOpen, setMenuOpen] = useState(isMenuOpen);
  const [headerMsg, setHeaderMsg] = useState(undefined)
  const [selectedConfOption, setSelectedConfOption] = useState("");
  const [exportBodyOptions, setExportBodyOptions] = useState();


    let scenarioLabel = scenarioState?.scenarioObjects?.length > 0 ? scenarioState?.scenarioObjects[0].label : "";
  let areStatesNotEmpty = areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, datasetState]);
  let periodRangeOptions = lang.header.options.period_range.slice(1, -2);

  useEffect(() => {
    const format = props.additionalProps?.isMoM ? ALL_WIDGETS.FIELDS.MOM_STACKS : props.additionalProps?.isYTD ? ALL_WIDGETS.FIELDS.YTD_STACKS : props.additionalProps?.isYOY ? ALL_WIDGETS.FIELDS.YOY_STACKS : props.additionalProps?.isEntity ? ALL_WIDGETS.FIELDS.ENTITY_STACKS : props.additionalProps?.isTotal ? ALL_WIDGETS.FIELDS.TOTAL_STACKS: ALL_WIDGETS.FIELDS.PROFIT_STACK;
    setProfitFormat(format);

    const title =  props.additionalProps?.isMoM
    ? ALL_WIDGETS.FIELDS.MOM_STACKS
    :props.additionalProps?.isYTD
    ? ALL_WIDGETS.FIELDS.YTD_STACKS
    : props.additionalProps?.isYOY
    ? ALL_WIDGETS.FIELDS.YOY_STACKS
    : props.additionalProps?.isTotal
    ? ALL_WIDGETS.FIELDS.TOTAL_STACKS
    : props?.history?.location?.state?.bubbleReportTitle
    ? props.history.location.state.bubbleReportTitle
    : ALL_WIDGETS.FIELDS.ENTITY_STACKS 

    setReportTitle(title)
    setPeriodRange(props.additionalProps?.isYOY ? periodRange : undefined)
    setHeaderMsg(props.additionalProps?.isMoM ? headerMsg : undefined);
  }, [props.additionalProps]);

  useListener(LISTENER.APPLY, () => {
    if (!showGreyOverLay) {
        apply();
        setCallGo(false);
    }
  });

  useEffect(() => {
    // this use effect is to change the filter when dimensionListFilter changes
    setFilterFinal( props.history.location.state
      ? props.history?.location?.state?.dimensionListFilter ? 
      props.history?.location?.state?.dimensionListFilter :
      props.history.location.state.outsideFilter
        ? JSON.stringify(tryParse(props.history.location.state.outsideFilter).filter) || []
        : []
      : [])
  }, [props.history?.location?.state?.dimensionListFilter])

  useEffect(() => {
    if (scenarioState) {
      if(!checkOnCustom) {
        setCustomViewId(history?.location?.state?.stack?.custom_stack_id);
        setCheckOnCustom(true);
      }
      filterSet.current = false;
      //when changing scenario, clear all states so that it won't run useEffect when new states are still not updated
      setPeriodsStatusState({});
      setVectorState({});
      setDatasetState({});
      setClientPeriodsState({});
      setShowGreyOverLay(true);
      setExpandDisabled(true);
      setIsExportExcelDisabled(true);
      getPeriodsStatus(
        scenarioState,
        dispatch,
        updatePeriodsStatusState,
        setPeriodsStatusState,
        props,
        profitFormat,
        userSettings,
        12
      );
      getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings, getPeriodState);
      getVectors(
        scenarioState,
        dispatch,
        updateVectorState,
        setVectorState,
        reportTitle,
        profitFormat,
        props,
        false,
        filterFinal,
        userSettings,
        vectorState,
        filterDialRef
      );
      getDatasets(
        scenarioState,
        dispatch,
        updateDatasetState,
        setDatasetState,
        props,
        profitFormat,
        params,
        false,
        userSettings,
        datasetState
      );
      setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
      if(reportRef.current?.pageComponent?.state?.isAllExpanded){
        expandCollapseClick();
      }
      setExportOptions();
    }
    setHeaderMsg(undefined);
  }, [scenarioState, reportTitle]);

  useEffect(() => {
    if (scenarioState) {
      setShowGreyOverLay(true);
      setExpandDisabled(true);
      setIsExportExcelDisabled(true);
      setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
      reportRef?.current?.getProfitStackHierarchy(0, scenarioState.scenario, undefined, undefined, undefined, true, true);
      if(reportRef.current?.pageComponent?.state?.isAllExpanded){
        expandCollapseClick();
      }
    }
  }, [JSON.stringify(props.additionalProps)]);

  /**
   * When we change the scope, we need to trigger the backToReport() function to redirect the user to list/heatmap/geo/landscape.
   * we remove the saved objects in store because we want to resend a request with scope filter.
   */
  useEffect(() => {
    if(history?.location?.state?.isScopeFilterChanged) {
        backToReport()
        dispatch(updateListObjBeforeBack([], "listObjBeforeBack", true));
				dispatch(updateListData([], "listData", true));
				dispatch(updateDrillListData([], "drillListData", true));

        dispatch(updateHeatmapListData([], "heatmapListData", true));
        dispatch(updateHeatmapObjBeforeBack([], "heatmapObjBeforeBack", true));

        dispatch(updateLandscapeBubbleListData([], "landscapeBubbleListData", true));
        dispatch(updateLandscapeBubbleObjBeforeBack([], "landscapeBubbleObjBeforeBack", true));
        dispatch(updateLandscapeCostHierarchy([], "landscapeCostHierarchy", true));

        dispatch(updateGeographyListData([], "geographyListData", true));
        dispatch(updateGeographyObjBeforeBack([], "geographyObjBeforeBack", true));
        dispatch(updateGeographyCostHierarchy([], "geographyCostHierarchy", true));

        sessionStorage.removeItem(SELECTED_DRILL_LIST); //Remove drill list from session
    }
  }, [history?.location?.state?.isScopeFilterChanged])

  useEffect(() => {
    if (vectorState) {
      getCustomViewData(
        PSViewsState,
        vectorState,
        params,
        filterFinal,
        userSettings,
        scenarioState,
        dispatch,
        updatePsViewsState,
        setPSViewsState,
        false,
        props
      );
      // if (!showGreyOverLay) {
      //     apply(); // if no greyoverlay then call apply()
      // }
    }
  }, [vectorState]);

  useEffect(()=> {
    if(PSViewsState?.profitStackViews && PSViewsState?.profitStackViews.filter(e=>e.value ===customViewId).length === 0 && customViewId){
      setWarningDialogOpen(true, "The stack you are viewing is incompatible or restricted on the scenario chosen");
      setCustomViewId(undefined);
      setShowGreyOverLay(true);
    }
  },[PSViewsState])

  useEffect(() => {
    if (
      areObjectsNotEmpty([periodsStatusState, vectorState, scenarioState, clientPeriodsState, PSViewsState]) &&
      userSettings.user
    ) {
      let headerElements = getHeaderElements();
      setHeaderElements(headerElements);
      
    }
  }, [
    periodsStatusState,
    clientPeriodsState,
    calendarVisible,
    vectorState,
    PSViewsState,
    manageColumnsProfile,
    showGreyOverLay,
    isDrilling,
    count,
    basicFilterEntities,
    filterFinal,
    filterFinalBasic,
    headerChangedCounter,
    manageColumnsProfile,
    isExpandDisabled,
    isExportExcelDisabled,
    isTableExpanded,
    reportTitle,
    headerMsg,
    disabledTootlip
  ]);

    useEffect(() => {
        if (isMenuOpen !== menuOpen) {
            setMenuOpen(isMenuOpen);
            setTimeout(() => {
                reportRef?.current?.pageComponent?.tabulator?.redraw(); // adjust table to container's width
            }, 500)
        }
    }, [isMenuOpen]);

  useEffect(() => {
    if(!props.additionalProps?.isEntity && !isAuthenticated && !(props?.history?.location?.state?.isManageStacks && props.additionalProps.isTotal)) {
      logout();
    }
    setShowGreyOverLay(true);
    props.setScenarioDisabled(false)
  }, [isAuthenticated]);

  /** this useEffect makes the scenario dropdown read-only if we are redirected from landscape of geography only */
  useEffect(() => {
    let disabled = props.history?.location?.state?.bubbleReportTitle === reportTitle // if we are in compare stacks, the scenario is disabled but when we change stack screen, the scenario should be enabled
    props.setScenarioDisabled(disabled)
    
  }, [loading, reportTitle]);

  useEffect(()=>{
    if(callGo && !showGreyOverLay) {
        apply();
        setCallGo(false);
    }
  }, [callGo, filterFinal, filterFinalBasic]);

  useEffect(() => {
      if(history?.location?.state?.isFromDashboards){
        const currentState = history.location.state || {};
        const newState = { ...currentState };
        delete newState.isFromDashboards;
        delete newState.isConcatenationOfFilters;
        delete newState.periodRange;
        delete newState.outsideFilter;
        delete newState.inheritedPeriod;

        setPeriodRange(currentState.periodRange); // set the profit stack type state before clearing the history
        history.replace({
          pathname: history.location.pathname,
          state: newState,
        });
      }
  }, []);
  
  const backToReport = () => {
    setIsDrilling(false);
    reportRef?.current?.onBackNavigation();
  };

  const tablesToExcelWrapper = (allRows) => {
    tablesToExcel(reportTitle, userSettings, vectorState, datasetState, scenarioState, reportRef, getExportQueryFilter, undefined, allRows);
    configureDropDownHeaderRef.current.setConfigDropdownOpen(false);
  };

  const runApplyValidations = () => {
    if (getPeriodDifference(getPeriodFromDate(periodsStatusState.customStartDate), getPeriodFromDate(periodsStatusState.customEndDate)) > 12 && !props.additionalProps?.isTotal) {
        setInfoDialogOpen(true, lang.not_allowed_periods_selection)
        return false;
    }
    return true;
  }

  const apply = () => {
    if (!runApplyValidations() && !props.additionalProps?.isTotal && !props.additionalProps?.isYOY && !props.additionalProps?.isYTD) {
      return false;
    }
    reportRef?.current.go(PSViewsState.custom_stack_id);

    setShowGreyOverLay(false);
  };

  const getExportQueryFilter = () => {
    let additionalFilter = filterFinal? typeof filterFinal === "string" ? (filterFinal === "" ? [] : tryParse(filterFinal)) : filterFinal:"";
    if (additionalFilter?.filter && typeof additionalFilter?.filter != "function") {
      additionalFilter = additionalFilter.filter;
    }
    return additionalFilter
      ? additionalFilter.find((f) => !f.isBasicFilter)
        ? formatAdvancedFilter(
            additionalFilter,
            userSettings.user?.user_allowed_vectors,
            datasetState.datasetOptions,
            vectorState.vectorOptions,
            filterDialRef?.current?.state?.psLinesOptions
          )
        : formatBasicFilter(additionalFilter, userSettings?.user?.user_allowed_vectors)
      : exportQueryFilter;
  };

  const handleCheckBoxChange=(item)=>{
    let filterFinalArr = filterFinal !== "[]" && typeof filterFinal === "string" ? JSON.parse(filterFinal) : filterFinal; // when filterFinal = ""[]"", remove the extra ""
    let isAdvancedFilterApplied = filterFinalArr && filterFinalArr !=="[]" && (typeof filterFinalArr === "object"? filterFinalArr.find(e=>!e.isBasicFilter): JSON.parse(filterFinalArr).find(e=>!e.isBasicFilter));
    if(isAdvancedFilterApplied){
        setBasicFilterItem(item);
        openFilterModal();
    }else{
        handleBasicFilterCheckBoxChange(item);
    }
}

  const setExportOptions = () => {
    let exportOptions = [];
    exportOptions.push({
      value: EXPORT_CONTAINER.EXPORT_VISIBLE,
      label: lang.header.titles.export_visible_rows,
      description: lang.header.titles.export_visible_rows_description,
      // tooltip: lang.header.tooltips.csv
      click: () => tablesToExcelWrapper()
    });
    exportOptions.push({
      value: EXPORT_CONTAINER.EXPORT_ALL,
      label: lang.header.titles.export_all_rows,
      description: lang.header.titles.export_all_rows_description,
      // tooltip: lang.header.tooltips.csv_all
      click: () => tablesToExcelWrapper(true)
    });
    setExportBodyOptions(exportOptions);
  }

  const filterOnBasicFilter=(basicAppliedFilter)=>{
    let filters = copyObjectValues(basicAppliedFilter.filter(e=>e.entities.length));
    filters?.forEach(e=>{
        e.isBasicFilter = true;
        e.filter_display_name = undefined;
        e.entities = e.entities.map(ent=>{ent.value = ent.number; ent.label = ent.number; return ent})
    });
    let filtersByName = copyObjectValues(filters);
    filtersByName?.forEach(e=>{
        e.field = "name";
        e.entities = e.entities.map(ent=>{ent.value = ent.name; ent.label = ent.name; return ent})
        filters.push(e);
    });

    if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) { 
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
    }
    let filterFinalBasic = copyObjectValues(basicAppliedFilter);//typeof _this.state.filterFinalBasic === "string" ? JSON.parse(_this.state.filterFinalBasic) : _this.state.filterFinalBasic;
    if(filterDialRef?.current) {
        filterDialRef?.current?.setState({
            savedFilterData: {}
        })
    }
   
    if(filterDialRef?.current?.savedFilterNameInput){
        filterDialRef.current.savedFilterNameInput.value = ""
    }
    filterFinalBasic?.forEach(e=>{
        e.filter_display_name = undefined;
    });

    filters = addCorrespondingParenthesisToFilter(filters);
    if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) { 
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
    }
    setFilterFinal(JSON.stringify(filters));
    setFilterFinalBasic(filterFinalBasic)
    setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
    if (!showGreyOverLay) {
      setCallGo(true)
        // apply();
    }
  }

  const handleBasicFilterCheckBoxChange=(item,clear,entity,allEntities)=>{
    let tempState = {};
    let filter = typeof filterFinalBasic === "string" ? JSON.parse(filterFinalBasic) : filterFinalBasic;
    let initialFilter = copyObjectValues(filter);

    filter = filter || [];
    let removedLine = [];
    let isClear = false;
    let isUnCheck = false;
    if (entity && item) {
        for (let e in filter) {
            if (filter[e].vector === item.vector) {
                if (entity.checked === true){
                    filter[e].entities.push(entity)
                }else{
                    filter[e].entities = filter[e].entities.filter(f => f.value !== entity.value)
                }
            }
        }
    } else if(item && allEntities){
        for (let e in filter) {
            if (filter[e].vector === item.vector) {
                for(let j in allEntities){
                    filter[e].entities.push(allEntities[j]);
                }
            }
        }
    }else if (item) {
        if(filter?.find(e=>e.vector === item.value)){
            removedLine = filter.find(e=>e.vector === item.value);
            filter = filter.filter(e=>e.vector !== item.value);
            delete basicFilterEntities[item.value]
            isUnCheck = true;
        }else{
            let selectedEmptyFilter = getNewEntityFilter(item.value, FILTER.VALUES.FIELD.NUMBER, [], FILTER.VALUES.FILTER_ROW_TYPE.FILTER, lang.ui_filter.dropdowns.and);
            selectedEmptyFilter.vectorLabel = item.label;
            filter?.push(selectedEmptyFilter);
        }
       
    } else if (clear) {
        filter = [];
        tempState.filterFinal = [];
        isClear = true;
        if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
            localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
            localStorage.removeItem(CURRENT_FILTER_COOKIE);
            tempState.exportQueryFilter = formatBasicFilter(filter, userSettings.user?.user_allowed_vectors);
            setExportQueryFilter(tempState.exportQueryFilter);
        }
    } 
    filter = addCorrespondingParenthesisToFilter(filter);
    if(isUnCheck && removedLine.entities.length>0){
        tempState.filterFinalBasic = filter;
        let copyFilter = copyObjectValues(filter);
        copyFilter.forEach(e=>{
            if(e.entities){
                e.isBasicFilter = true;
                e.entities = e.entities.map(ent=>{ent.value = ent.number; ent.label = ent.number; return ent})
            }
        });
        let filtersByName = copyObjectValues(copyFilter);
        filtersByName.forEach(e=>{
            e.field = "name";
            e.entities = e.entities.map(ent=>{ent.value = ent.name; ent.label = ent.name; return ent})
            copyFilter.push(e);
        });
        tempState.filterFinal = JSON.stringify(copyFilter);
        tempState.exportQueryFilter = formatBasicFilter(copyFilter, userSettings.user?.user_allowed_vectors)
    }
    filter.map((item)=>{item.filter_display_name = undefined });
    tempState.filterFinalBasic = isUnCheck? JSON.stringify(filter): filter;
    setFilterFinal(tempState.filterFinal);
    setFilterFinalBasic(tempState.filterFinalBasic);
    
    if(item && Object.keys(basicFilterEntities).length && basicFilterEntities[item.value]) { 
      basicFilterEntities[item.value].map(entity => entity.checked = false); 
    } 

    let countState = countRef.current;
    setCount(countState + 1);
    countRef.current = countState+1;
    // when we add the first dropdown, the initial filter will be 0 and the new filter will be 1.
    // when we remove the first dropdown, the filter will be 0 and the initial will be 1
    // this condition is to prevent resizing the tables when we add/remove more than 1 dropdown
    // if(isClear || (initialFilter.length <= 1 && filter.length === 0) || (initialFilter.length ===0 && filter.length <= 1)) {
    //     _this.handleOverlayAndHeights(filter.length > 0);
    // }
    if(filterDialRef?.current) {
        filterDialRef?.current?.setState({
            savedFilterData: {}
        })
    }

    if(filterDialRef?.current?.savedFilterNameInput){
        filterDialRef.current.savedFilterNameInput.value = ""
    }

    if(isUnCheck && removedLine.entities.length>0 && !showGreyOverLay){
        if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
            localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
            localStorage.removeItem(CURRENT_FILTER_COOKIE);
            setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(tempState.filterFinal));
        }
        let countstate = countRef.current;
        setCount(countstate+1);
        countRef.current = countState+1;
        setCallGo(true);
        // apply();
    }else if(!isClear){
        getFilterEntities(item, vectorState, setBasicFilterEntities, basicFilterEntitiesState, profitFormat, userSettings, scenarioState, tempState.filterFinal, getCountRef, setCount, setCountRef);
    }
  }

  const  onChangeFilter=(filterStr, afterDrilling, applyFilter=false, isClear, isSwitchBasic, filterDisplayName)=> {
    let filter = typeof filterStr === "string" ? JSON.parse(filterStr): filterStr;
    // on changing advanced saved filter its name should be removed if cookie has no saved filter since we remove the saved filter from cookie when we do a change on it
    if (!filterDisplayName && (getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === null || !getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) || getLocalStorageValueByParameter(SAVED_FILTER_ID_COOKIE) === "undefined")){
        filter.map((item)=>{item.filter_display_name = undefined });
    }

    if (isSwitchBasic) {
        filter.map(function(item){item.isBasicFilter = false});
    } 
    let tempState = {};
    
    tempState.filterFinal = typeof filter !== "string" ? JSON.stringify(filter) : filter;

    if(isClear){
        filter="[]";
        tempState.filterFinalBasic =  [];
    }else if (!filter.find(f => !f.isBasicFilter)){
        tempState.filterFinalBasic =  copyObjectValues(filter).filter(e=>e.field === "number"); //from selecting a saved basic filter
        let filterObjs = copyObjectValues(filter);
        const unique = [...new Set(filterObjs.map(item => item.vector))]; // [ 'A', 'B']
        for (var e in unique) {
            getFilterEntities({value:unique[e]}, vectorState, setBasicFilterEntities, basicFilterEntitiesState, profitFormat, userSettings, scenarioState, tempState.filterFinal , getCountRef, setCount, setCountRef)
        }
    }else if(applyFilter){ // from apply in advanced
        tempState.filterFinalBasic =  [];
    }
    tempState.tempFilter = typeof filter !== "string" ? JSON.stringify(filter) : filter;
    let currState = history.state;
    if(!applyFilter) {
        let tempState2 = {tempFilter: filterStr};    //this state represents the filter displayed in the header but not necessarily applied on the data (pending apply())
        if(!isSwitchBasic){
            tempState2.filterFinalBasic = tempState.filterFinalBasic;
            setFilterFinalBasic(tempState2.filterFinalBasic);
        }
        setTempFilter(filterStr);
        return;
    }
    // _this.resetLimit();
    //check if filter contains quadrant or quadrant/tier filter
    tempState.isAdvancedFilterApplied = isClear? false : true;
    tempState.exportQueryFilter = filterStr === "[]" ? undefined : formatAdvancedFilter(JSON.parse(filterStr), userSettings.user?.user_allowed_vectors, datasetState.datasetOptions, vectorState.vectorOptions, filterDialRef?.current?.state?.psLinesOptions);
    setFilterFinal(tempState.filterFinal);
    setFilterFinalBasic(tempState.filterFinalBasic);
    setExportQueryFilter(tempState.exportQueryFilter);
    setIsAdvancedFilterApplied(tempState.isAdvancedFilterApplied);
    setTempFilter(tempState.tempFilter);
    if(isClear){
        filterStr="[]";
    }
    if(history && history.location && history.location.state && !history.location.state.isRedirectionFromBubble && !history.location.state.isRedirectionFromStacks && !isFromDashboards){
      history.push({
        state: {
          outsideFilter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          mainFilter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          filter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          filterFinal: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          originalMainFilter: typeof filter !== "string" ? JSON.stringify(filter) : filter,
          scenarioState: history?.location?.state?.scenarioState,
          callOnMount: history?.location?.state?.callOnMount,
          checkedPsLines: history?.location?.state?.checkedPsLines,
        }
      })
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filter));
    }
    if (!showGreyOverLay) {
      setCallGo(true);
    }
  }

  const clearClick=()=>{
    handleBasicFilterCheckBoxChange(undefined, true);
    if(!showGreyOverLay) {
      setCallGo(true);
        // apply(); 
    }
  } 

  const openFilterModal = () => {
    setOpenFilterDialog(true);
  }

  const closeFilterModal = () => {
    setOpenFilterDialog(false);
  }

  const filterModalBody = () => {
    return (
        <h4>{lang.clear_advanced_filter}<br />{lang.switching_to_quick_filter}</h4>
    )
  }

  const handleBasicFilterAndClose = () =>{
    closeFilterModal();
    clearAdvancedFilter(basicFilterItem)
  }

  const clearAdvancedFilter = (basicFilterItem) => {
    filterDialRef?.current?.clearFilter();
    setFilterFinal([]);
    handleBasicFilterCheckBoxChange(basicFilterItem);
  }

  const saveDialogActions = () => {
    return (
        <>
            <Button
                label={lang.modal.buttons.clear_filter}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={handleBasicFilterAndClose}
            />
            <Button
                label={lang.modal.buttons.cancel}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                aria-label="Close"
                onBtnClick={closeFilterModal}
            />
        </>
    )
  }

  const setInfoDialogOpen = (isOpen, infoMsg) => {
    setOpenInfoDilog(isOpen);
    setInfoDialogMsg(infoMsg);
  }

  const setCountRef= (count) => {
    countRef.current = count;
  }

  const getCountRef = () => {
    return countRef.current;
  }

  const getFilterBySectionsData = () => {
    let isClearBtnDisabled = false;
    let filterFinalBasicState = filterFinalBasic || [];
    let vectors  = vectorState.vectorOptions?.filter(f => f.entityCount <= userSettings.filterVectorCountThreshold || f.isGroupTitle) // remove vectors with entityCount <= threshold and that are not group title;
    if (vectors && filterFinalBasicState) {
        filterFinalBasicState = typeof filterFinalBasicState === "object" ? filterFinalBasicState : JSON.parse(filterFinalBasicState);
        filterFinalBasicState = Array.isArray(filterFinalBasicState) ? filterFinalBasicState : filterFinalBasicState.filter;
        isClearBtnDisabled = filterFinalBasicState.length === 0;
        vectors = vectors.map((item) => ({
            ...item,
            checked: !item.isGroupTitle && filterFinalBasicState.some((e) => e.vector === item.value) // .some() checks if any of the element.vector === item.value
        }));
    }

    // Add suggested section if we have nextVectors and nextVectors' entity count < threshold
    let sectionsJson = [];
    let quarters = [];
    var startQuarter = datasetState.nextStartQuarter?.split("Q")[1];
    var endQuarter =  datasetState.nextEndQuarter?.split("Q")[1];
    var startYear =  datasetState.nextStartQuarter?.split("Q")[0];
    var endYear =  datasetState.nextEndQuarter?.split("Q")[0];
    quarters = generateQuarter(Number(startQuarter), startYear, Number(endQuarter), endYear, true);
    vectors =  vectors; // add isDisabled to vectors that are not generated

    sectionsJson.push({
        sectionTitle: lang.all,
        itemsList: vectors,
    })
    return {data: sectionsJson, isClearBtnDisabled: isClearBtnDisabled};
  }

  const switchBasicToAdvanced = (filters) =>{
    if(filterByRef?.current){
        filterByRef.current.handleClose();
    }

    if(!filterFinal) {
      return;
    }
    
    let filterStr = filterFinal;
    let filter = typeof filterStr === "string" ? JSON.parse(filterStr) : (filterStr.filter && typeof filterStr.filter !== "function"? filterStr.filter : filterStr); 
    if(filters && userSettings?.useNewQuickFilters) {
      filter = filters
    } 

    filter = addCorrespondingParenthesisToFilter(filter);
    if(filter.find(e=>e.isBasicFilter)){
        filterDialRef?.current?.onChangeSavedFilter(filter, false, true);
    }
}

    const expandCollapseClick = (fromClearSearch) => {
        if (fromClearSearch) {
            setTableExpanded(false);
            reportRef.current?.pageComponent?.collapseOnClearSearch();
        } else {
            setTableExpanded(!isTableExpanded);
            reportRef.current?.pageComponent?.expandCollapseAll();
        }
    }

  const getPeriodState = () => {
    return periodsStatusState;
  }

  const setFilterTo = () => {
    let filter = filterFinal;
    //before saving the filter, save the old value if not previously set
    if(filterFinal && typeof filterFinal !== 'string') {
    filter = JSON.stringify(filterFinal);        //onChangeSavedFilter expects a string and not an array
    }
    let filterSetRef = filterSet.current; 
    if (!filterSetRef && filterFinal?.length > 0) {
    filterSet.current = true;
    filterDialRef?.current?.onChangeSavedFilter(filter, false);
    }
  }

  const setQuickFilters = (filters) => {
    if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) { 
      localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
      localStorage.removeItem(CURRENT_FILTER_COOKIE);
      setLocalStorageValueByParameter(CURRENT_FILTER_COOKIE, JSON.stringify(filters));
    }
    
    setFilterFinal(JSON.stringify(filters));
    setExportQueryFilter(formatBasicFilter(filters, userSettings.user?.user_allowed_vectors));
    if (!showGreyOverLay) {
        setCallGo(true);
    }
  }

  const clearAdvancedFilters = (isClearAll) => {
    if(isClearAll) {
        filterDialRef?.current?.clearFilter();
        setFilterFinal([]);
    }
    if(!reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) && !reportTitle.startsWith(lang.compare_stacks)) {
        localStorage.removeItem(SAVED_FILTER_ID_COOKIE);
        localStorage.removeItem(CURRENT_FILTER_COOKIE);
        let exportQueryFilter = formatBasicFilter([], userSettings.user?.user_allowed_vectors);
        setExportQueryFilter(exportQueryFilter);
    }
  }

  /**HeaderElements Related Functions */
  const getHeaderElements = () => {
    let isYTD = props.additionalProps?.isYTD;
    let isMoM = props.additionalProps?.isMoM;
    let isYOY = props.additionalProps?.isYOY;
    let isTotal = props.additionalProps?.isTotal;
    let isEntity = props.additionalProps?.isEntity;
    let isRange = props.additionalProps?.isRange;
    let sectionsData = getFilterBySectionsData();

    let isDropDownDisabled = false; 
    let headerElements = [];

    const periodRangeDD = ({label, isDisabled, description}) => (
      <div className={"option-padding uk-flex-between uk-display-flex"+(isDisabled?" option-group-header":"")} disabled={isDisabled}>
          {label}{isDisabled?"":<i className="fal fa-info-circle" uk-tooltip={description} style={{"color":"white","marginTop":convertPxToViewport(5)}}></i>}
      </div>
    );
 
    const profitStackViewsDD = ({ label, isDisabled, tooltipText }) => (
      <div
        className={"option-label-padding uk-flex-between uk-display-flex"}
        disabled={isDisabled}
        uk-tooltip={!!tooltipText ? tooltipText : ""}
      >
        {label}
      </div>
    );

      const optionLabel = ({label, isDisabled, vector_description, is_default, isGroupTitle, tooltipText}) => (
          <div className={(isGroupTitle ? "option-group-header" : "") +" option-padding uk-flex-between uk-display-flex"} uk-tooltip={!!tooltipText ? tooltipText : ""}>
              {label} {!isGroupTitle && !isDisabled ? <i className="fal fa-info-circle" uk-tooltip={(!vector_description || is_default) ? lang.no_vector_description : vector_description}
                                                         style={{"color":"white","marginTop":convertPxToViewport(5)}}></i> : !isGroupTitle && isDisabled ?
              <i className="fal fa-info-circle" uk-tooltip={lang.period_not_built}
                 style={{"color":"black","marginTop":convertPxToViewport(5)}}></i> : ""}
          </div>
      );
    let periods = periodsStatusState.actuallyBuiltPeriods.length > 0 ? periodsStatusState.actuallyBuiltPeriods : periodsStatusState.builtPeriods; //this.isProfitStacks ? this.state.builtPeriods ?  this.state.builtPeriods.filter(e=>e.is_built) : this.state.periodOptions && this.state.periodOptions.length  ?this.state.periodOptions : this.state.periods: this.state.periods;
    periods = disablePeriods(periods);
    let periodFoundInBuiltPeriods = periodsStatusState?.builtPeriods?.find(e=>e.value === history?.location?.state?.period);
    let period = clientPeriodsState.period === null ? (isFromDashboards && periodFoundInBuiltPeriods.is_built ? periodFoundInBuiltPeriods : getLastBuiltPeriod(periodsStatusState?.builtPeriods)) : clientPeriodsState.period;
    if (clientPeriodsState.period === null) {
      let tempState = clientPeriodsState;
      tempState.period = period;
      setClientPeriodsState(tempState);
    }
    let periodRangeOptions = lang.header.options.period_range.slice(1, -2);
    let areMultipleVectorsSelected = props?.history?.location?.state?.profitStackItems && Object.keys(props?.history?.location?.state?.profitStackItems[0]).find(e=>e.includes(ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME));
    let canSaveCompanyFilters = getSectionExists(userAllowedMenuLinks, SAVE_COMPANY_FILTERS);
    headerElements.push(
      <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="first_header_row gap_between_buttons">
        {renderTitle(
            props.additionalProps?.isMoM
            ? ALL_WIDGETS.FIELDS.MOM_STACKS
            :props.additionalProps?.isYTD
            ? ALL_WIDGETS.FIELDS.YTD_STACKS
            : props.additionalProps?.isYOY
            ? ALL_WIDGETS.FIELDS.YOY_STACKS
            : props.additionalProps?.isTotal
            ? ALL_WIDGETS.FIELDS.TOTAL_STACKS
            : props.history.location.state.bubbleReportTitle
            ? props.history.location.state.bubbleReportTitle
            : props.history.location?.state?.vectorName 
            ? ALL_WIDGETS.FIELDS.ENTITY_STACKS + (areMultipleVectorsSelected && props.history.location.state.drilling? "" :( " - " + props.history.location.state.vectorName))
            : ALL_WIDGETS.FIELDS.ENTITY_STACKS + (areMultipleVectorsSelected && props.history.location.state.drilling? "" : (" - " + findOptionByKey(vectorState.vectorOptions, (props.history.location.state.profitStackItems[0]?.tier || vectorState.nextVectors[0])).label))
        )}
        <div
          key={Math.random()}
          uk-tooltip={isDropDownDisabled ? lang.exit_drill_to_proceed : "title:"}
          className={"uk-flex uk-flex-column uk-flex-start"}
        >
          <div key={Math.random()} className={isDropDownDisabled ? " disabled" : ""}>
            {/* <label htmlFor="select-set" id="profit-stack-view-label">{lang.header.titles.profit_stack}</label> */}
            <DropDown
              id="select-profit-stack-view"
              className="input-width-200 input__dropdown"
              placeholder={lang.header.placeholders.profit_stack}
              value={
                findOptionByKey(PSViewsState.profitStackViews, PSViewsState.custom_stack_id) ||
                findOptionByKey(PSViewsState.profitStackViews, PSViewsState.nextProfitStackViewId) ||
                findOptionByKey(PSViewsState.profitStackViews, reportRef.current?.state?.profitStackViewId) ||
                findOptionByKey(PSViewsState.profitStackViews, 0)
              }
              onChange={(e) => handleElementChange(HEADER_ELEMENT.PROFIT_STACK, e)}
              options={PSViewsState.profitStackViews} //options filled in loadCustomViewData()
              formatOptionLabel={profitStackViewsDD}
              type={DROPDOWN_TYPE.INPUT}
            />
          </div>
        </div>
        {isYOY ? (
          <DropDown
            id="select-profit-stack-view"
            className="input-width-200 input__dropdown"
            placeholder={lang.header.placeholders.profit_stack_type}
            value={findOptionByKey(periodRangeOptions, periodRange || periodRangeOptions[0])}
            onChange={(e) => handleElementChange(HEADER_ELEMENT.PERIOD_RANGE, e)}
            options={periodRangeOptions}
            formatOptionLabel={periodRangeDD}
            type={DROPDOWN_TYPE.INPUT}
          />
        ) : (
          ""
        )}
        {isYTD || isYOY ? (
          <div key={Math.random()}>
            <DropDown
              id="PeriodRange"
              className="uk-width-small input__dropdown"
              name="PeriodRange"
              value={period && period.value ? findOptionByKey(periods, period.value) : findOptionByKey(clientPeriodsState.periodOptions, period)}
              onChange={(e) => handleElementChange(HEADER_ELEMENT.PERIOD, e)}
              options={periods}
              type={DROPDOWN_TYPE.INPUT}
              formatOptionLabel={optionLabel}
            />
          </div>
        ) : (
          renderPeriodCalendar(
            periodsStatusState,
            handleElementChange,
            undefined,
            clientPeriodsState.allPeriods,
            toggleCalendar,
            isDropDownDisabled,
            scenarioState,
            false,
            undefined,
            periodsStatusState.noPeriodInheritance ? ALL_WIDGETS.FIELDS.MOM_STACKS : ALL_WIDGETS.FIELDS.TOTAL_STACKS
          )
        )}

        <div id="apply_div">{renderButton(lang.modal.buttons.apply, lang.modal.buttons.apply.toLowerCase(), "", "", apply, !showGreyOverLay)}</div>
      </div>
    );

    headerElements.push(
      <div className="second_header_row gap_between_buttons" style={{ display: "flex", width: "100%", padding: "0.41667vw 0", alignItems: "center" }}>
        {userSettings?.useNewQuickFilters ? (
          <QuickFilters
              vectorOptions={vectorState.vectorOptions}
              profitFormat={profitFormat}
              userSettings={userSettings}
              scenarioState={scenarioState}
              filterFinal={filterFinal}
              setQuickFilters={setQuickFilters}
              switchBasicToAdvanced={switchBasicToAdvanced}
              clearAdvancedFilters={clearAdvancedFilters}
              filterDialRef={filterDialRef}
          />) 
          : (
          <FilterByDropdown
            ref={filterByRef}
            sectionsData={copyObjectValues(sectionsData.data)}
            clearClick={clearClick}
            isClearBtnDisabled={sectionsData.isClearBtnDisabled}
            handleCheckboxChange={handleCheckBoxChange}
            basicFilterCountLimit={userSettings.basicFilterCountLimit}
            switchBasicToAdvanced={switchBasicToAdvanced}
          />)
        }
        <FilterDialog
          id={"secondary_header_" + "stacks"}
          ref={filterDialRef}
          section_id={params.sectionId}
          vectorOptions={vectorState.vectorOptions}
          profitFormat={profitFormat}
          mustHideFilter={false}
          categoryToolkit={false}
          onChangeFilter={onChangeFilter}
          quadTierIsAllowed={true}
          isTempScenario={false}
          scenario_id={scenarioState.scenario}
          mustFetchDatasets={false}
          user={userSettings.user}
          isDrilling={false}
          fromOptions={datasetState.datasetOptions}
          datasetOptions_id={datasetState.datasetOptions_id}
          dataSet={datasetState.dataset}
          useCookies={!reportRef?.current?.state?.isFromList && !history?.location?.state?.isRedirectionFromBubble && !history?.location?.state?.isRedirectionFromStacks  && !isFromDashboards}
          parentMessage={""}
          psFilterDisabled={false}
          quadrantsDisabled={props.additionalProps?.isEntity? false :true}
          quadrantDefault={false}
          checkIfMonthsValid={checkIfMonthsValid}
          comparisonScenarioId={undefined}
          filterDisplayEditable={true}
          psLinesOptions={psLinesOptions}
          quadrantTierOptions={quadrantTierOptions}
          dispatch={dispatch}
          savedFilterDataState={history?.location?.state?.savedFilterDataState || filterDialRef?.current?.state?.savedFilterData}
          isRedirectionFromStacks={reportRef?.current?.state?.isFromList}
          savedFilter={true}
          fromSecondaryHeader={true}
          setFilterTo={setFilterTo}
          useNewQuickFilters={userSettings.useNewQuickFilters}
          filterFinal={filterFinal}
          canSaveCompanyFilters={canSaveCompanyFilters}
          // handleOverlayAndHeights={this.props.handleOverlayAndHeights}
        />
        {isMoM && headerMsg &&
          <span className="header-tip-msg">
            {headerMsg.split("<i>")[0]} 
            {headerMsg.split("<i>").length > 0 ? <span className="italic-msg"> {headerMsg.split("<i>")[1]} </span> : ""}
          </span>
        }
        <div style={{display: "flex", flexDirection: "row", marginLeft: "auto" }}>
            <ButtonDropdown
                id={"stacks-table-export-btn"}
                ref={configureDropDownHeaderRef}
                placeholder={lang.header.titles.xls}
                className="export-button-table uk-margin-small-left"
                // onBtnClick={this.showConfigureDialog}
                dropId={"stack_table_export_dialog"}
                variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
                size={SIZES.SMALL}
                firstAttr={"#button-drop-stacks-table-export-btn"}
                secondAttr={"#stack_table_export_dialog"}
                renderContainerBody={renderExportBody}
                disabled={isExportExcelDisabled}
                disabledTooltip={disabledTootlip}
            />
        {(isRange || isMoM) &&
          <ButtonDropdown
          id={"stacks-table-configure-btn"}
          ref={configDropdownStacksHeader}
          placeholder={lang.heatmap_configure.configure}
          className="configure-button-table uk-margin-small-left"
          // onBtnClick={this.showConfigureDialog}
          dropId={"stack_table_configure_dialog"}
          variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
          size={SIZES.SMALL}
          firstAttr={"#button-drop-stacks-table-configure-btn"}
          secondAttr={"#stack_table_configure_dialog"}
          renderContainerBody={renderConfigureBody}
        />
        }

        {isEntity || (props?.history?.location?.state?.isManageStacks && isTotal)? (
          <Button
            key={"back-btn"}
            id="header-back"
            className="uk-margin-small-left"
            label={lang.navigation.btns.back}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={backToReport}
          />
        ):""}
        </div>
        
      </div>
    );

    if(!userSettings.useNewQuickFilters) {
      headerElements.push(
        <div className="third_row_header" style={{ display: "flex", width: "100%" }}>
          <div
            key={Math.random()}
            className={
              "noprint " +
              (displayFilter && ((filterFinalBasic?.length > 0 && filterFinalBasic !== "[]") || (filterFinal?.length > 0 && filterFinal !== "[]"))
                ? "border-third-header "
                : "") +
              "uk-display-block"
            }
          >
            <AppliedFilters
              filterProps={filterFinalBasic}
              filterFinal={filterFinal}
              ref={appliedFiltersRef}
              userAllowedVectors={userSettings.user?.user_allowed_vectors}
              basicFilterCountLimit={userSettings.basicFilterCountLimit}
              handleCheckboxChange={handleBasicFilterCheckBoxChange}
              sectionsData={basicFilterEntities}
              fetchData={filterOnBasicFilter}
              clearFilter={filterDialRef?.current?.clearFilter}
              vectors={vectorState.vectorOptions}
              quadrantsDisabled={!props.additionalProps?.isEntity}
              psFilterDisabled={false}
              parentMessage={"" /*props.filterWarningMessage*/}
              saveFilter={filterDialRef?.current?.startSaveFilter}
              clearBasicFilter={clearClick}
              clearFilterFromDialog={filterDialRef?.current?.clearFilter}
              forgetFilter={filterDialRef?.current?.handleOpenForgetFilterDialog}
              className={isFilterDisabled ? "uk-disabled disabled" : ""}
              tooltip={"title:" + "" + ";pos: top-left"}
              filterIsEditable={filterDialRef?.current?.state?.savedFilterData?.editable}
              isEntityStacks={isConcatenationOfFilters ? true : false}
            />
          </div>
        </div>
      );  
    }
   
    if(isEntity && props.history?.location?.state?.drilledPath?.length > 0) {
      headerElements.push(
        <div className="fourth_row_header" style={{ display: "flex", width: "100%", alignItems: "center" }}>
          <label className='fs-14 uk-margin-small-right'>{DRILL_PARAMETERS_LABEL}</label>
          <div className='uk-margin-xsmall-top'>
              {props.history?.location?.state?.drilledPath.map(function (elem, i) {
                  return <div key={elem} className={"uk-badge uk-margin-xsmall-bottom"} style={{ marginRight: convertPxToViewport(5) }}>{elem}</div>;
              })}
          </div>
        </div>
      );
    }

    return headerElements;
  };

  const checkForLimitAccessMessage = (data, showModal) => {
    let noAccessMessage = data.no_access_message;
    if (!!noAccessMessage && showModal && !props.checkedPsLines) {
      setLimitAccessDialogOpen(true, noAccessMessage);
    }
    return !!noAccessMessage;
  };

  const setLimitAccessDialogOpen = (isOpen, msg) => {
    setLimitAccessMsg(msg);
    setOpenLimitAccessDialog(isOpen);
  };

  const warningDialogActions = () => {
    return (
        <Button
            id="close-btn"
            label={lang.modal.buttons.ok}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={() => setWarningDialogOpen(false, "")}
        />
    )
  }

  const setWarningDialogOpen = (isOpen, msg) => {
    setOpenWarningDialog(isOpen);
    setWarningMsg(msg);
  }


  const renderConfigureBody = () => {
    let configureClassName = "table-configure-dialog";
    if(reportRef?.current?.state?.accessDenied){
      return;
    }
    let toggleTabs = CONFIGURE_TOGGLE_TABS;
    toggleTabs[0].label = getCurrency();
    let momToggleTabs = MOM_CONFIGURE_TOGGLE_TABS;
    momToggleTabs[0].sign = getCurrency();
    return(
      <div id="configure-dropdown">
        {/* TODO: Remove minWidth when configure MoM is done */}
        <div id="stack_table_configure_dialog" className={configureClassName + " table-configure-dialog configure-container configure_body"} >
          <div className="stacks_configure_title">Configure Stack</div>
        <RangeStackConfiguration
            ref={configureCompRef}
            toggleTabLabel={AMOUNT_TITLE}
            toggleOptions={reportRef.current.state.isFromList || reportRef.current.state.isFromBubble? toggleTabs : props.additionalProps.isMoM ? [] : momToggleTabs}            
            defaultToggleValue={reportRef.current.state.currStackConfigObj.amountType || MOM_CONFIGURE_TOGGLE_TABS[0].value}
            primaryBtnLabel="Apply"
            onPrimaryClick={reportRef.current?.pageComponent?.onApplyConfigClick}
            listOfDropdowns={props.additionalProps.isMoM ? reportRef.current?.pageComponent?.initConfigureDropdownListMoM() : reportRef.current?.pageComponent?.initConfigureDropdownList()}
            defaultObj={reportRef.current.state.currStackConfigObj}
            disableMed={reportRef.current.state.disableMed}
            isFromList={reportRef.current.state.isFromList}
            isFromGeoOrPL={reportRef.current.state.isFromBubble}
            handleToggleTabChange={props.additionalProps.isMoM || props.additionalProps?.isTotal ? handleToggleTabChange : undefined}
            defaultShowTipValue={props.additionalProps.isMoM || props.additionalProps?.isTotal ? reportRef.current.state.currStackConfigObj.amountType === MOM_CONFIGURE_TOGGLE_TABS[1].value : false }
            isMoM={props.additionalProps.isMoM}
            selectedConfOption = {selectedConfOption}
            useRadioButtons={props.additionalProps.isMoM}
            setSelectedConfOption={setSelectedConfOption}
        />
    </div>
    </div>
    ) 
  }

  const renderExportBody = () => {
    let configureClassName = "table-configure-dialog";
    return (
        <div id="configure-dropdown">
          <div id="stack_table_export_dialog" className={configureClassName + " table-configure-dialog configure-container configure_body"}>
            {exportBodyOptions.map((option, index) => (
                <div className={"export_options_" + option.value} key={index} >
                  <div className="stacks_configure_title" onClick={()=>{option?.click()}}>{option.label}</div>
                  <div className="export_description">{option.description}</div>
                  <div className="export_container_divider"></div>
                </div>
            ))}
          </div>
        </div>
    )
  }

  const handleToggleTabChange = (tab) => {
    configureCompRef.current?.setShowTip(tab === MOM_CONFIGURE_TOGGLE_TABS[1].value);
  }
 
  const handleElementChange = (name, e, fromMount = false, dimIndex = 0) => {
    switch (name) {
      case HEADER_ELEMENT.SELECTION_RANGE: {
        let selectedYears = e.filter((year) => year.isSelected);
        let tempState = copyObjectValues(periodsStatusState);
        if (selectedYears.length > 0) {
          let periods = getLocalStorageValueByParameter("periods") ? JSON.parse(getLocalStorageValueByParameter("periods")) : "";
          let sortedSelectedYears = selectedYears.map((p) => p.year + p.value).sort();
          let startPeriod = sortedSelectedYears[0];
          let endPeriod = sortedSelectedYears[sortedSelectedYears.length - 1];
          let startDate = new Date(periods.filter((e) => e.value === startPeriod)[0].start_date);
          let endDate = new Date(periods.filter((e) => e.value === endPeriod)[0].end_date);
          let isReportPSSMapping = false; // _this.props.state.stagingReport === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW || _this.props.state.stagingReport === ALL_WIDGETS.PS_MAPPING_MENU;
          // if(isReportPSSMapping && this.state.onMount){
          //     tempState.onMount = false;
          // }
          // if (_this.isVectorAnalysis && !_this.validatePeriodRange(startDate, endDate)) {
          //     _this.setInfoDialogOpen(true, lang.insufficient_periods_selection)
          //     return;
          // } else if ((_this.props.state.stagingReport === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW || _this.props.state.stagingReport === ALL_WIDGETS.PS_MAPPING_MENU) && getPeriodDifference(startPeriod, endPeriod) > 12) {
          //     _this.setState({periodsExceeded: true})
          //     _this.setInfoDialogOpen(true, lang.not_allowed_periods_selection);
          //     return;
          // }
          // _this.setState({periodsExceeded: false})
          tempState.nextCustomStartDate = startDate;
          tempState.nextCustomEndDate = endDate;
          tempState.customStartDate = startDate;
          tempState.customEndDate = endDate;
          if (
            reportTitle !== ALL_WIDGETS.FIELDS.TOTAL_STACKS && // in total stacks screen selection can exceed 12 periods so it should not be saved in the cookies
            !reportTitle.startsWith(ALL_WIDGETS.FIELDS.ENTITY_STACKS) &&
            !reportTitle.startsWith(ALL_WIDGETS.FIELDS.COMPARE_STACKS)
          ) {
            saveCookie("nextCustomStartDate", startDate);
            saveCookie("nextCustomEndDate", endDate);
          }
          setPeriodsStatusState(tempState);
          setShowGreyOverLay(true);
          setExpandDisabled(true);
          setIsExportExcelDisabled(true);
          setTableExpanded(false);
          if(reportRef.current?.pageComponent?.state?.isAllExpanded){
            expandCollapseClick();
          }
          // if (_this.props.state.stagingReport === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW || _this.props.state.stagingReport === ALL_WIDGETS.PS_MAPPING_MENU) {
          //     tempState.customStartDate = startDate;
          //     tempState.customEndDate = endDate;
          //     tempState.oldCustomStartDate = _this.state.customStartDate;
          //     tempState.oldCustomEndDate = _this.state.customEndDate;
          // }
        }
        break;
      }
      case HEADER_ELEMENT.PERIOD: {
        let tempState = copyObjectValues(clientPeriodsState);
        tempState.period = e;
        setClientPeriodsState(tempState);
        setShowGreyOverLay(true);
        setExpandDisabled(true);
        setIsExportExcelDisabled(true);
        setTableExpanded(false);
        if(reportRef.current?.pageComponent?.state?.isAllExpanded){
          expandCollapseClick();
        }
        if(!props.additionalProps?.isEntity){
          saveCookie("period", e.value);
        }
        break;
      }
      case HEADER_ELEMENT.PERIOD_RANGE: {
        setPeriodRange(e.value);
        setShowGreyOverLay(true);
        setExpandDisabled(true);
        setIsExportExcelDisabled(true);
        setTableExpanded(false);
        break;
      }
      case HEADER_ELEMENT.PROFIT_STACK: {      
        var tempState = copyObjectValues(PSViewsState) || {};
        tempState.nextProfitStackViewId = e.value;
        tempState.profitStackViewId = e.value;
        tempState.custom_stack_id = e.value;
        tempState.value = e.value;
        tempState.label = e.label;
        // tempState.previousStackConfigObj = this.props.stackConfigObj;
        // tempState.previousChartConfigObj = this.props.chartConfigObj;
        setPSViewsState(tempState);
        if(e[PROFIT_STACK_VIEW.FILTER_ID]){
            filterDialRef.onChangeSavedFilterId(e[PROFIT_STACK_VIEW.FILTER_ID] || 0, undefined, true);
        }
        reportRef.current.getProfitStackHierarchy(e.value,undefined);
        setHeaderMsg(undefined);
        setShowGreyOverLay(true);
        setExpandDisabled(true);
        setIsExportExcelDisabled(true);
        setTableExpanded(false);
        if(reportRef.current?.pageComponent?.state?.isAllExpanded){
          expandCollapseClick();
        }
        break;
      }
      default:
        break;
    }
    let headerChangedCounterState = headerChangedCounter;
    setHeaderChangedCounter(headerChangedCounterState + 1);
  };

  let showSkeleton = !(areStatesNotEmpty && headerElements.length > 0);

  useEffect(() => {
    updateWindowLoading(showSkeleton, "skeleton-loader");
  }, [showSkeleton]);

  return (
    <>
      {showSkeleton && <LoaderSkeleton />}
      <Suspense fallback={<SkeletonLoader />}>
        <Modal
          id={"check-limit-access-dialog"}
          openDialog={openLimitAccessDialog}
          closeClick={() => setLimitAccessDialogOpen(false, "")}
          bodyContent={() => messageDialogContent(limitAccessMsg)}
          dialogActions={() => (
            <Button
              label={lang.modal.buttons.ok}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              aria-label="Close"
              onBtnClick={() => setLimitAccessDialogOpen(false, "")}
            />
          )}
          size={DIALOG_SIZE.MEDIUM}
        />
        
        <Modal 
          id={"stacks-warning-dialog"}
          openDialog={openWarningDialog}
          bodyContent={() => <div className="uk-text-large">{warningMsg}</div>}
          dialogActions={warningDialogActions}
          closeClick={() => setWarningDialogOpen(false, "") }
          size={DIALOG_SIZE.MEDIUM}
        />
        <Modal
          id={"header-info-dialog"}
          openDialog={openInfoDialog}
          bodyContent={() => <span className="uk-text-large">{infoDialogMsg}</span>}
          dialogActions={() => (
            <Button
              label={lang.modal.buttons.ok}
              variant={BUTTON_VARIANT.PRIMARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              onBtnClick={() => setInfoDialogOpen(false, "")}
            />
          )}
          closeClick={() => setInfoDialogOpen(false, "")}
          size={DIALOG_SIZE.MEDIUM}
        />
        <Modal
          id={"switch-basic-to-advanced-filter"}
          openDialog={openFilterDialog}
          bodyContent={filterModalBody}
          dialogActions={saveDialogActions}
          size={DIALOG_SIZE.MEDIUM}
          closeClick={closeFilterModal}
        />
        <div
          id={"main-component-container"}
          className={"main-component-container " + (showSkeleton ? "hidden" : "")}
          style={{ "--banner-height": isScenarioBannerVisible ? "6%" : 0 }}
        >
          <Loader newLoader />
          <div className={isScenarioBannerVisible ? "header-banner-div noprint" : "header-banner-div-hidden noprint"}>
            <NewBanner
              bannerClassName={"header-banner noprint scenario-banner"}
              labelClassName={"header-banner-label scenario-banner-label"}
              icon={<RoundInfoIcon className="info-banner-icon" />}
              label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
              body={
                <Button
                  id={"banner-dismiss-btn"}
                  label={lang.modal.buttons.dismiss}
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={() => setScenarioBannerVisible(false)}
                />
              }
            />
          </div>

          <div className={"main-report-header noprint " + (showSkeleton ? "hidden" : "")}>
            <ReportHeader headerElements={headerElements} />
          </div>
          <div ref={manageColsOverlay} id="overlay-manage-columns" className="uk-hidden"></div>
          <div className="main_report">
            {headerElements.length > 0 && showGreyOverLay && <div className="overlay-div">{Object.keys(window._loading)?.length > 0 ? "" : lang.overlay_go_msg}</div>}
            <div id="main_report_container" className="main_report_container">
              {areStatesNotEmpty && headerElements.length > 0 ? (
                <ProfitStacks
                  ref={reportRef}
                  isYTD={props.additionalProps?.isYTD}
                  isYOY={props.additionalProps?.isYOY}
                  isRange={props.additionalProps?.isRange}
                  isEntity={props.additionalProps?.isEntity}
                  isTotal={props.additionalProps?.isTotal}
                  isMoM={props.additionalProps?.isMoM}
                  userSettingsState={userSettings}
                  PSViewsState={PSViewsState}
                  periodsStatusState={periodsStatusState}
                  vectorState={vectorState}
                  userAllowedMenuLinks={userAllowedMenuLinks}
                  filterFinal={filterFinal}
                  scenarioState={scenarioState}
                  checkForLimitAccessMessage={checkForLimitAccessMessage}
                  datasetState={datasetState}
                  clientPeriodsState={clientPeriodsState}
                  profitFormat={profitFormat}
                  setDataLength={setDataLength}
                  // setIsDrilling={setIsDrillingFunc}
                  // manageColsAccess={manageColsAccess}
                  // reselectProfile={reselectProfile}
                  history={props.history}
                  isScenarioBannerVisible={isScenarioBannerVisible}
                  showGreyOverLay={showGreyOverLay}
                  setExpandDisabled={setExpandDisabled}
                  setIsExportExcelDisabled = {setIsExportExcelDisabled}
                  periodRange={periodRange || lang.header.options.period_range.slice(1, -2)[0].value}
                  periodRangeOptions={periodRangeOptions}
                  filterDialRef={filterDialRef}
                  configDropdownStacksHeader={configDropdownStacksHeader}
                  setProfitFormat={setProfitFormat}
                  setShowGreyOverLay={setShowGreyOverLay}
                  // saveDrillFilter={saveDrillFilter}
                  additionalProps={props.additionalProps}
                  psLinesOptions={psLinesOptions && psLinesOptions.has(scenarioState.scenario) ? psLinesOptions.get(scenarioState.scenario)[0] : []}
                  isFromDashboards={isFromDashboards}
                  expandCollapseClick={expandCollapseClick}
                  setHeaderMsg={setHeaderMsg}
                  vectorsSelectionLimit={Number(userSettings.vectorSelectionLimit.trim())}
                  selectedConfOption={selectedConfOption}
                  setDisabledTooltip={setDisabledTooltip}
                  overwritePopupSize={userSettings.overwritePopupSize}
                  exportScopeFilter={exportScopeFilter}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
};

export { StackWrapper };
