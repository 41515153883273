// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profit-assist-container {
  display: flex;
  padding: 0.34vw 0.573vw 0.33vw 0.355vw;
  align-items: center;
  border-radius: 5px;
  border: 1px solid rgba(221, 221, 234, 0.80);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.00);
  gap: 0.3125vw;
  cursor: pointer;
}

.profit-assist-container:hover {
  border: 1px solid #89899A;
  box-shadow: 0 0.3125vw 0.521vw 0 rgba(0, 0, 0, 0.05);
}

.profit-assist-container .ask-text {
  text-align: center;
  font-size: var(--value-13-px);
  font-style: normal;
  font-weight: 800;
  border-radius: 3px;
  padding: 0.25vw 0.35vw;
  background-color: rgba(26, 209, 143, 1);
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0.7px;
  line-height: 120%;
}

.profit-assist-container .profit-assist-img {
  cursor: pointer;
  position: absolute;
  top: -1.19vw;
}

.profit-assist-container .profit-assist-img svg{
  min-width: 5.5vw;
}

.profit-assist-img-container{
  position: relative;
  min-width: 5.5vw;
  min-height:0.9567187500000001vw;
}



`, "",{"version":3,"sources":["webpack://./src/components/chatBot/ChatBotWrapper.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sCAAsC;EACtC,mBAAmB;EACnB,kBAAkB;EAClB,2CAA2C;EAC3C,0CAA0C;EAC1C,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,oDAAoD;AACtD;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,sBAAsB;EACtB,uCAAuC;EACvC,6BAA6B;EAC7B,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,+BAA+B;AACjC","sourcesContent":[".profit-assist-container {\n  display: flex;\n  padding: 0.34vw 0.573vw 0.33vw 0.355vw;\n  align-items: center;\n  border-radius: 5px;\n  border: 1px solid rgba(221, 221, 234, 0.80);\n  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.00);\n  gap: 0.3125vw;\n  cursor: pointer;\n}\n\n.profit-assist-container:hover {\n  border: 1px solid #89899A;\n  box-shadow: 0 0.3125vw 0.521vw 0 rgba(0, 0, 0, 0.05);\n}\n\n.profit-assist-container .ask-text {\n  text-align: center;\n  font-size: var(--value-13-px);\n  font-style: normal;\n  font-weight: 800;\n  border-radius: 3px;\n  padding: 0.25vw 0.35vw;\n  background-color: rgba(26, 209, 143, 1);\n  color: rgba(255, 255, 255, 1);\n  letter-spacing: 0.7px;\n  line-height: 120%;\n}\n\n.profit-assist-container .profit-assist-img {\n  cursor: pointer;\n  position: absolute;\n  top: -1.19vw;\n}\n\n.profit-assist-container .profit-assist-img svg{\n  min-width: 5.5vw;\n}\n\n.profit-assist-img-container{\n  position: relative;\n  min-width: 5.5vw;\n  min-height:0.9567187500000001vw;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
