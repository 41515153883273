import PieChart from "./PieChart";
import BarChart from "./BarChart";
import ProgressBar from "./ProgressBar";
import {ReactComponent as AlertCircle} from "../../styles/images/actionableInsights/alert-circle.svg";
import {ReactComponent as BarChartIcon} from "../../styles/images/actionableInsights/bar-chart-2.svg";
import {ReactComponent as PieChartIcon} from "../../styles/images/actionableInsights/pie-chart.svg";
import { convertPxToViewport } from "../../class/formatting";
import { getValidPeriods } from "../../templateLayout/functions/periodFunctions";
import { copyObjectValues, isValidDate } from "../../class/utils";
import { extractFromFullQuarter, generatePeriods, getFullQuarterFromStartEndQuarters, getGeneratedQuarterRange, getLastBuiltPeriodForSegments, getPeriodQuarter, getQuarterFromDate, monthDiff } from "../../class/date";
import { API_URL, FormatTypes, FY_VALUES, RETURN_INSIGHTS, ROLLING_SEGMENTS } from "../../class/constants";
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from "../../class/networkUtils";
import { lang } from "../../language/messages_en";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as DepartmentViewIcon } from "../../styles/images/actionableInsights/users.svg";
import { ReactComponent as ProductIcon } from "../../styles/images/actionableInsights/package.svg";
import { ReactComponent as OperationsIcon } from "../../styles/images/actionableInsights/operations.svg";
import { formatValString } from "../../class/format";
import { SkeletonLoader } from "../../form/elements";

const DepartmentView = (props) => {
    const [customerReturns, setCustomerReturns] = useState([]);
    const [memberTierReturns, setMemberTierReturns] = useState([]);
    const [pricingBandReturns, setPricingBandReturns] = useState([]);
    const [brandReturns, setBrandReturns] = useState([]);
    const [storeReturns, setStoreReturns] = useState([]);
    const [reasonReturns, setReasonReturns] = useState([]);
    const [insights, setInsights] = useState([]);
    const [isFetchingInsights, setIsFetchingInsights] = useState(false);

    const abortControllers = useRef(new Map());

    useEffect(() => {
        return () => {
            abortControllers.current.forEach((controller, key) => {
                if (!controller.signal.aborted) {
                    controller.abort();
                }
            });
            abortControllers.current.clear(); 
        };
    }, []);
    
    useEffect(()=> {
        if (Object.keys(props?.periodsStatusState).length > 0 && Object.keys(props.clientPeriodsState).length > 0  && Object.keys(props.datasetState).length > 0) {
            setCustomerReturns([]);
            setMemberTierReturns([]);
            setPricingBandReturns([]);
            setBrandReturns([]);
            setStoreReturns([]);

            getVectorReturns();
            setIsFetchingInsights(true);
        } else {
            setCustomerReturns([]);
            setMemberTierReturns([]);
            setPricingBandReturns([]);
            setBrandReturns([]);
            setStoreReturns([]);
        }
    }, [props?.periodsStatusState, props.clientPeriodsState, props.datasetState]);

    const createAbortController = (key) => {
        if (abortControllers.current.has(key)) {
            abortControllers.current.get(key).abort(); 
        }
        const controller = new AbortController();
        abortControllers.current.set(key, controller);
        return controller;
    };

    const getPeriodsObject =()=> {
        let periods = [];
        let periods_yoy = [];
        let periods_pa = [];
        let quarter = "";
        let quarterPre = "";
        let segmentPeriod = "";
        let months = FY_VALUES.M3;
        let startDate = getValidPeriods(props.periodsStatusState, props.clientPeriodsState)?.startDate;
        let endDate = getValidPeriods(props.periodsStatusState, props.clientPeriodsState)?.endDate;
      
        if (isValidDate(startDate) && isValidDate(endDate) && startDate  && endDate) {
            let periodsCount = monthDiff(startDate, endDate) + 1;
            periods = generatePeriods(startDate, periodsCount);
            periods_yoy =  generatePeriods(startDate, periodsCount,false);
            periods_pa = generatePeriods(startDate, periodsCount,true);
        }
    
        let periodsStateOrProps = props.clientPeriodsState?.periods;
        if (!!periodsStateOrProps && isValidDate(startDate) && isValidDate(endDate)) {
            let firstQuarter = getQuarterFromDate(startDate);
            let endQuarter = getQuarterFromDate(endDate);
    
            let firstPreQuarter = getPeriodQuarter(periods_pa[0]);
            let endPreQuarter = getPeriodQuarter(periods_pa[periods_pa.length-1]);
    
            let generatedRange = getGeneratedQuarterRange(props.datasetState.datasetOptions, firstQuarter, endQuarter);
            let generatedRangePre = getGeneratedQuarterRange(props.datasetState.datasetOptions, firstPreQuarter, endPreQuarter);
    
            let fullQuarter = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRange[0], generatedRange[1]));
            let fullQuarterPre = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRangePre[0], generatedRangePre[1]));
    
            quarter = fullQuarter.quarter;
            quarterPre = fullQuarterPre.quarter;
            months = fullQuarter.months;
    
            let lastSelectedPeriod = periods[periods.length - 1];
            let builtPeriods = props.periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label);
            segmentPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
        }
        return {periods: periods, segmentPeriod: segmentPeriod, quarter: quarter, months: months, periods_yoy: periods_yoy, periods_pa: periods_pa, preQuarter: quarterPre};
    }
    
      const getVectorReturns = () => {
        let periodsObj = getPeriodsObject();// to get the quarter
        const controller = createAbortController("getVectorReturns")
        let query = {
            action: "getVectorReturns",
            scenario_id: props.scenarioState.scenario ,
            currentPeriods:periodsObj.periods,
            previousPeriods: periodsObj.periods_pa,
            previousYearPeriods: periodsObj.periods_yoy,
            quarter: periodsObj.quarter+(periodsObj.months && periodsObj.months !== FY_VALUES.M3? periodsObj.months :""),
            rollingPeriod: periodsObj.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12,
            FY:  FY_VALUES.M3,
        }
        let onThenCallback = (data)=>{
            abortControllers.current.delete("getVectorReturns");
            setCustomerReturns(data.customer_returns);
            setMemberTierReturns(data.member_tier_returns);
            setPricingBandReturns(data.pricing_band_returns);
            setBrandReturns(data.brand_returns);
            setStoreReturns(data.store_returns);
            setReasonReturns(formatReasonData(data.return_reason_data));
            getInsights(copyObjectValues(data));
        }
    
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getVectorReturns",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.RETURNS_INSIGHTS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.returns_insights.screen_name ,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.returns_insights.get_returns,
            [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
            [FETCHAPI_PARAMS.periods]:  periodsObj.periods.join(",") + "," + periodsObj.periods_pa.join(","),
            [FETCHAPI_PARAMS.signal]:  controller.signal,
        }
        fetchAPI(fetchOptions);
    }

    const getInsights=(insightsData)=>{
        const controller = createAbortController("getInsights");
        delete insightsData.logDates
        let query = {
            action: "getInsights",
            insightsData: insightsData,
            view: RETURN_INSIGHTS.DEPRATMENT_VIEW.VALUE
        }
        let onThenCallback = (data)=>{
            abortControllers.current.delete("getInsights");
            setIsFetchingInsights(false);
            setInsights(data.insights);
        }
    
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getInsights",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.RETURNS_INSIGHTS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: lang.observability.output.returns_insights.screen_name ,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.returns_insights.get_insights_department,
            [FETCHAPI_PARAMS.signal]:  controller.signal,
        }
        fetchAPI(fetchOptions);
    }

    const formatReasonData = (data) =>{
        const totalSum = data.reduce((sum, item) => Number(sum) + Number(item.returns), 0);
        return data
            .map(item => ({ 
                ...item, 
                percentage: (Number(item.returns) * 100) / totalSum
            }))
            .sort((a, b) => b.percentage - a.percentage)
            .slice(0, 5);
    }

    const renderInfoContainer = (title, insight) =>{
        return (<div className="info-container" style={{
            width:'100%',
            backgroundColor:"#ffff",
            borderRadius:"0.417vw",
            display:"flex", flexDirection:"column",
            rowGap:convertPxToViewport(8),
            boxShadow:"3px 6px 8px 0 rgba(0, 0, 0, 0.1)",
            borderLeft:"4px solid #6495ED",
            padding:"0.885vw 0.677vw"
        }}>
            <div style={{color:"#6495ED", fontSize:"0.833vw", fontWeight:'600'}}>{title}</div>
            <div style={{color:"grey", fontSize:"0.78125vw"}}>{insight}</div>
        </div>)
    }

    const renderReturnsReason = () => {
        return reasonReturns.map((e, index) => (
            <div key={index} style={{ display: "flex", columnGap: convertPxToViewport(10) }}>
                <div style={{ width: '20%', fontWeight: '600', fontSize: convertPxToViewport(15) }}>
                    {e.name}
                </div>
                <ProgressBar percentage={e.percentage} color={"#16a34a"} />
                <div style={{ width: '20%', fontWeight: '600', fontSize: convertPxToViewport(15) }}>
                    {formatValString(e.percentage, FormatTypes.PERCENTAGE)}
                </div>
            </div>
        ));
    };

    let memberTierInsights = insights?.member_tier_returns?.[0];
    let customerInsights = insights?.customer_returns?.[0];
    let pricingBrandInsights = insights?.pricing_band_returns?.[0];
    let brandReturnsInsights = insights?.brand_returns?.[0];
    let storeReturnsInsights = insights?.store_returns?.[0];
    let returnReasonInsights = insights?.return_reason_data?.[0];
    return (
        <div style={{background:"#f9fafb", padding:"0.78125vw 0.677vw", overflow:"auto", maxHeight:"84%"}}>
            <div className="department-view-container">
                <span style={{fontSize:convertPxToViewport(18), fontWeight:'600'}}>Department Level Analysis</span>
                <div>
                    <div className="uk-margin-medium-bottom" style={{display:"flex"}}>
                        <span><DepartmentViewIcon style={{transform:'scale(0.7)'}}/></span>
                        <h4 className="bold">Customer Analysis </h4>
                    </div>
                    <div className="uk-margin-medium-bottom" style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between", rowGap:convertPxToViewport(20), width:"100%"}}>
                        <div className="info-parent-container" style={{display:"flex", gap:"1.042vw"}}>        
                            {isFetchingInsights?
                                <div className="info-container">
                                    <SkeletonLoader />
                                </div>
                                :memberTierInsights &&
                                    renderInfoContainer(memberTierInsights.title, memberTierInsights.insight)
                            }
                            {isFetchingInsights?
                                <div className="info-container">
                                    <SkeletonLoader />
                                </div>
                                :customerInsights &&
                                    renderInfoContainer(customerInsights.title, customerInsights.insight)
                            }
                        </div>
                    </div>
                    <div style={{display:"inline-flex", width:"100%", justifyContent:"space-between", gap:convertPxToViewport(35)}}>
                        <div style={{backgroundColor:"#eef2ff", padding:convertPxToViewport(10), borderRadius:convertPxToViewport(4), width:'100%', display:"flex", flexDirection:"column"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <div style={{color:"#312e81", fontSize:convertPxToViewport(15), fontWeight:'600'}}>Member Tier Analysis</div>
                                <div><PieChartIcon style={{transform:"scale(0.8)",color:"#6366f1"}} /></div>
                            </div>
                            <div>
                            <PieChart chartData={memberTierReturns} id="membertier_pie"/>
                            </div>
                        </div>
                        <div style={{backgroundColor:"#eef2ff", padding:convertPxToViewport(10), borderRadius:convertPxToViewport(4), width:'100%', display:"flex", flexDirection:"column"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <div style={{color:"#312e81", fontSize:convertPxToViewport(15), fontWeight:'600'}}>Customer Segment Analysis</div>
                                <div><BarChartIcon style={{transform:"scale(0.8)",color:"#6366f1"}} /></div>
                            </div>
                            <div>
                                <BarChart chartData={customerReturns} id={"channel_bar"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="uk-margin-small-bottom" style={{display:"flex"}}>
                        <span><ProductIcon style={{transform:'scale(0.7)'}}/></span>
                        <h4 className="bold">Product Analysis </h4>
                    </div>
                    <div className="uk-margin-medium-bottom" style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between", rowGap:convertPxToViewport(20), width:"100%"}}>
                        <div className="info-parent-container" style={{display:"flex", gap:"1.042vw"}}>
                            {isFetchingInsights?
                                <div className="info-container">
                                    <SkeletonLoader />
                                </div>
                                :pricingBrandInsights &&
                                    renderInfoContainer(pricingBrandInsights.title, pricingBrandInsights.insight)
                            }
                            {isFetchingInsights?
                                <div className="info-container">
                                    <SkeletonLoader />
                                </div>
                                :brandReturnsInsights &&
                                    renderInfoContainer(brandReturnsInsights.title, brandReturnsInsights.insight)
                            }
                        </div>
                    </div>
                    <div style={{display:"inline-flex", width:"100%", justifyContent:"space-between", gap:convertPxToViewport(35)}}>
                        <div style={{backgroundColor:"#eef2ff", padding:convertPxToViewport(10), borderRadius:convertPxToViewport(4), width:'100%', display:"flex", flexDirection:"column"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <div style={{color:"#312e81", fontSize:convertPxToViewport(15), fontWeight:'600'}}>Pricing Band Analysis</div>
                                <div><PieChartIcon style={{transform:"scale(0.8)",color:"#6366f1"}} /></div>
                            </div>
                            <div>
                            <PieChart chartData={pricingBandReturns} id="pricingband_pie"/>
                            </div>
                        </div>
                        <div style={{backgroundColor:"#eef2ff", padding:convertPxToViewport(10), borderRadius:convertPxToViewport(4), width:'100%', display:"flex", flexDirection:"column"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <div style={{color:"#312e81", fontSize:convertPxToViewport(15), fontWeight:'600'}}>Brand Segment Analysis</div>
                                <div><BarChartIcon style={{transform:"scale(0.8)",color:"#6366f1"}} /></div>
                            </div>
                            <div>
                                <BarChart chartData={brandReturns} id={"brand_bar"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="uk-margin-small-bottom" style={{display:"flex"}}>
                        <span><OperationsIcon style={{transform:'scale(0.7)'}}/></span>
                        <h4 className="bold">Operations Analysis </h4>
                    </div>
                    <div className="uk-margin-medium-bottom" style={{display:"flex", flexWrap:"wrap", justifyContent:"space-between", rowGap:convertPxToViewport(20), width:"100%"}}>
                        <div className="info-parent-container" style={{display:"flex", gap:"1.042vw"}}>
                            {isFetchingInsights?
                                <div className="info-container">
                                    <SkeletonLoader />
                                </div>
                                :storeReturnsInsights &&
                                    renderInfoContainer(storeReturnsInsights.title, storeReturnsInsights.insight)
                            }
                            {isFetchingInsights?
                                <div className="info-container">
                                    <SkeletonLoader />
                                </div>
                                :returnReasonInsights &&
                                    renderInfoContainer(returnReasonInsights.title, returnReasonInsights.insight)
                            }
                        </div>
                    </div>
                    <div style={{display:"inline-flex", width:"100%", justifyContent:"space-between", gap:convertPxToViewport(35)}}>
                        <div style={{backgroundColor:"#eef2ff", padding:convertPxToViewport(10), borderRadius:convertPxToViewport(4), width:'100%', display:"flex", flexDirection:"column"}}>
                            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                                <div style={{color:"#312e81", fontSize:convertPxToViewport(15), fontWeight:'600'}}>Store Segment Analysis</div>
                                <div><BarChartIcon style={{transform:"scale(0.8)",color:"#6366f1"}} /></div>
                            </div>
                            <div>
                                <BarChart chartData={storeReturns} id={"store_bar"}/>
                            </div>
                        </div>
                        <div className="progress-tag">
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                            <div style={{color:"#14532d", fontSize:convertPxToViewport(15), fontWeight:'600'}}>Top Return Reasons</div>
                            <div><BarChartIcon style={{transform:"scale(0.8)",color:"#22c55e"}} /></div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", rowGap:convertPxToViewport(30)}}>
                            {/* <div  style={{display:"flex", columnGap:convertPxToViewport(10)}}>
                                <div style={{width:'20%', fontWeight:'600', fontSize:convertPxToViewport(15)}}>Wrong Size/Fit</div>
                                <ProgressBar percentage={45} color={"#16a34a"}/>
                                <div style={{width:'20%', fontWeight:'600', fontSize:convertPxToViewport(15)}}>45%</div>
                            </div>
                            <div  style={{display:"flex", columnGap:convertPxToViewport(10)}}>
                                <div style={{width:'20%', fontWeight:'600', fontSize:convertPxToViewport(15)}}>Quality Issues</div>
                                <ProgressBar percentage={30} color={"#16a34a"}/>
                                <div style={{width:'20%', fontWeight:'600', fontSize:convertPxToViewport(15)}}>30%</div>
                            </div>
                            <div  style={{display:"flex", columnGap:convertPxToViewport(10)}}>
                                <div style={{width:'20%', fontWeight:'600', fontSize:convertPxToViewport(15)}}>Changed Mind</div>
                                <ProgressBar percentage={25} color={"#16a34a"}/>
                                <div style={{width:'20%', fontWeight:'600', fontSize:convertPxToViewport(15)}}>25%</div>
                            </div> */}
                            {renderReturnsReason()}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default DepartmentView;