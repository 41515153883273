import React, { Component } from 'react';
import Tabulator from "tabulator-tables"; 
import { copyObjectValues, parseBoolean, tryParse } from '../class/utils.js';
import { cleanUpTabulatorColumns } from '../class/common.js';
import { MANAGE_ACCESS, DROP_DOWN_ELEMENT,ENGINE_FILTER, FILTER } from '../class/constants.js';
import { lang } from '../language/messages_en.js';
import { getTableButton, getTableIcon, getTableIconButton } from '../newComponents/tabulatorComponents.js';

const $ = require('jquery');
var Inflector = require('inflected');
const _field = ENGINE_FILTER.KEYS.FIELD;
const _values = FILTER.KEYS.ENTITIES;
const _function = ENGINE_FILTER.KEYS.FUNCTION;

// * @author [Rafah Cheaib]
class ManageAccessTabulator extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.updateTableColumns = this.updateTableColumns.bind(this);
    }

    updateTableColumns(columns,data, userData, accessData, roleData, tabSelected) {
        var _this = this;
        var tabulatorColumns = copyObjectValues(columns);
        tabulatorColumns = cleanUpTabulatorColumns(copyObjectValues(tabulatorColumns), data, undefined, this.tabulator, {id: "manageAccessTable", headerFilters: this.tabulator.modules.filter.headerFilters});
        tabulatorColumns.forEach(col => {
            col.formatter = _this.getColumnFormatter(col.field,userData, accessData, roleData, tabSelected);
        });
        this.tabulator.setColumns(tabulatorColumns);
        if (tabSelected === MANAGE_ACCESS.USER) {
            this.tabulator.setSort([{ column: MANAGE_ACCESS.FIELDS.NAME, dir: "asc" }]);
        }
        if (tabSelected === MANAGE_ACCESS.ROLE) {
            this.tabulator.setSort([{ column: MANAGE_ACCESS.FIELDS.ROLE, dir: "asc" }]);
        }
        if (tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION) {
            this.tabulator.setSort([{ column: MANAGE_ACCESS.FIELDS.ACCESS_GROUP, dir: "asc" }]);
        }
        this.tabulator.setData(data);
        this.tabulator.clearFilter(true);
    }

    refreshManageAccessTable=()=>{
        this.tabulator.setData(this.tabulator.getData());
    }
    
    getColumnFormatter(colField,userData, accessData, roleData, tabSelected) {
        let columnFormatter;
        var obj = this;
        var actions = "actions";
        switch(colField) {
            case actions:
                columnFormatter = function(cell){
                    if(cell.getData()[MANAGE_ACCESS.FIELDS.IS_SYSTEM] === true && obj.props.loggedUser[MANAGE_ACCESS.FIELDS.IS_SYSTEM] !== "true") {
                        return "";
                    }
                    var dropdownContainer = document.createElement("div");
                    dropdownContainer.setAttribute("uk-dropdown", "mode: click; boundary: .tabulator-table");
                    dropdownContainer.classList.add("manage_access_user_container")
                    var dotsButtonContainer = document.createElement("div");
                    var dotsButton = getTableIconButton(["fa-2x", "fal", "fa-ellipsis-v"], ["uk-button-icon", "dots-button"]);
                    dotsButtonContainer.classList.add("uk-inline");
                    dotsButton.setAttribute("id",cell.getRow().getData().id);

                    // var cloneContainer = document.createElement("div");
                    // var cloneText = document.createElement("span");
                    // var cloneIcon = document.createElement("i");
                    // cloneIcon.classList.add(/*"fal", "fa-clone", "fa-lg", "uk-margin-small-right",*/"uk-display-none");
                    // //cloneText.innerText = DROP_DOWN_ELEMENT.clone + Inflector.singularize(tabSelected);
                    // cloneContainer.append(cloneIcon);
                    // cloneContainer.append(cloneText);
                    // cloneContainer.classList.add("uk-display-none");
                    // dropdownContainer.appendChild(cloneContainer);
            
                    var editContainer = getTableButton(
                        DROP_DOWN_ELEMENT.edit + Inflector.singularize(tabSelected),
                        [],
                        ["justify-content-start"],
                        ["fal", "fa-edit", "fa-lg", "uk-margin-small-right", "uk-margin-small-left"],
                        "left",
                        lang.manage_columns.titles.edit
                    ); 
                   
                    editContainer.classList.add("uk-button-icon");
                    editContainer.onclick=()=>{
                        $("#"+cell.getRow().getData().id).click();
                        obj.props.edit(tabSelected, cell.getRow().getData())
                    }
                    dropdownContainer.appendChild(editContainer);
            
                    var resetPasswordActionContainer = getTableButton(
                        DROP_DOWN_ELEMENT.resetPasswordAction,
                        [],
                        ["justify-content-start"],
                        ["fal", "fa-wrench", "fa-lg","uk-margin-small-right", "uk-margin-small-left"],
                        "left",  
                    ); 
      
                    resetPasswordActionContainer.classList.add("uk-button-icon");
                    resetPasswordActionContainer.onclick=()=>{
                        let rowData = cell.getRow().getData();
                        obj.props.confirmResetPassword(rowData[MANAGE_ACCESS.FIELDS.FIRST_NAME], rowData[MANAGE_ACCESS.FIELDS.LAST_NAME], rowData[MANAGE_ACCESS.FIELDS.EMAIL], rowData)};
                    if (tabSelected === MANAGE_ACCESS.USER) {
                        if (obj.props.isClientSSO) {
                            if (cell.getRow().getData().is_system) {
                                dropdownContainer.appendChild(resetPasswordActionContainer);
                            }
                        } else {
                            dropdownContainer.appendChild(resetPasswordActionContainer);
                        }
                    }
                    if (tabSelected === MANAGE_ACCESS.USER) {
                        var resetMFAContainer = getTableButton(
                            DROP_DOWN_ELEMENT.resetMFA,
                            [!parseBoolean(sessionStorage.getItem("MFA")) ? "disabled" : "resetMFA_container_text"],
                            ["justify-content-start"],
                            ["fal", "fa-user-lock", "fa-lg", "uk-margin-small-right", "uk-margin-small-left", "resetMFA_container_icon"],
                            "left",
                        ); 
                        resetMFAContainer.classList.add("resetMFA_container");
                       
                        if (!parseBoolean(sessionStorage.getItem("MFA"))) {
                            // resetMFAIcon.classList.add("disabled")
                            // resetMFAText.classList.add("disabled")
                            resetMFAContainer.classList.add("disabled")
                            resetMFAContainer.setAttribute("uk-tooltip", "Multi-factor authentication (MFA) is disabled");
                            resetMFAContainer.style.cursor="unset";
                        }
                   
                        resetMFAContainer.classList.add("uk-button-icon");
                        if (parseBoolean(sessionStorage.getItem("MFA"))) {
                            resetMFAContainer.onclick=()=>{
                                let rowData = cell.getRow().getData();
                                obj.props.resetMFA(rowData[MANAGE_ACCESS.FIELDS.FIRST_NAME],rowData[MANAGE_ACCESS.FIELDS.EMAIL],rowData[MANAGE_ACCESS.FIELDS.LAST_NAME],rowData[MANAGE_ACCESS.FIELDS.ID]);
                                dropdownContainer.classList.add("uk-dropdown-close");
                            }
                        }
                        if(!obj.props.isClientSSO){
                        dropdownContainer.appendChild(resetMFAContainer);
                        }
                    }
                    if (tabSelected === MANAGE_ACCESS.USER && cell.getRow().getData()[MANAGE_ACCESS.FIELDS.FAILED_ATTEMPTS_NUMBER] >= obj.props.failed_attempts_number) {
                        let blockText = DROP_DOWN_ELEMENT.unblock + " " + Inflector.singularize(tabSelected);
                        let blockIcon = "fa-check-circle";
                        var blockContainer = getTableButton(
                            blockText,
                            [],
                            ["justify-content-start", "uk-button-icon"],
                            ["fal", blockIcon, "fa-lg", "uk-margin-small-right", "uk-margin-small-left", "black"],
                            "left",
                        ); 
                        blockContainer.onclick=()=>{
                            let rowData = cell.getRow().getData();
                            obj.props.confirmUnBlock(tabSelected, rowData[MANAGE_ACCESS.FIELDS.STATUS], rowData[MANAGE_ACCESS.FIELDS.ID], rowData[MANAGE_ACCESS.FIELDS.FIRST_NAME], rowData[MANAGE_ACCESS.FIELDS.LAST_NAME], rowData[MANAGE_ACCESS.FIELDS.EMAIL], rowData[MANAGE_ACCESS.FIELDS.ROLE], rowData[MANAGE_ACCESS.FIELDS.ACCESS_GROUP], true);
                        }
                        dropdownContainer.appendChild(blockContainer);
                    }else{
                        var mainContainer = document.createElement("div");

                        let deactivateText = cell.getRow().getData()[MANAGE_ACCESS.FIELDS.STATUS] === MANAGE_ACCESS.ACTIVE ?  DROP_DOWN_ELEMENT.deactivate + Inflector.singularize(tabSelected) : DROP_DOWN_ELEMENT.activate + Inflector.singularize(tabSelected);
                        let deactivateIcon = cell.getRow().getData()[MANAGE_ACCESS.FIELDS.STATUS] === MANAGE_ACCESS.ACTIVE ? "fa-times-circle" : "fa-check-circle";
                        let deactivateContainerClassName = obj.props.limitExceeded && deactivateText === "Activate User" ? "disabled" : undefined;
                        var deactivateContainer = getTableButton(
                            deactivateText,
                            [],
                            ["justify-content-start", "uk-button-icon"],
                            ["fal", deactivateIcon, "fa-lg", "uk-margin-small-right", "uk-margin-small-left", "black"],
                            "left",
                        ); 
                        if(deactivateContainerClassName) {
                        deactivateContainer.classList.add(deactivateContainerClassName)
                        }

                        if(obj.props.limitExceeded && deactivateText === "Activate User") {
                            mainContainer.setAttribute('uk-tooltip', lang.manage_access.MAX_LIMIT_EXCEEDED);
                        }
                        if(!obj.props.limitExceeded || deactivateText === "Deactivate User"){
                            deactivateContainer.onclick=()=>{
                                let rowData = cell.getRow().getData();
                                obj.props.confirmDeactivate(tabSelected, rowData[MANAGE_ACCESS.FIELDS.STATUS], rowData[MANAGE_ACCESS.FIELDS.ID], rowData[MANAGE_ACCESS.FIELDS.FIRST_NAME], rowData[MANAGE_ACCESS.FIELDS.LAST_NAME], rowData[MANAGE_ACCESS.FIELDS.EMAIL], rowData[MANAGE_ACCESS.FIELDS.ROLE], rowData[MANAGE_ACCESS.FIELDS.ACCESS_GROUP]);
                            }
                        }
                        mainContainer.appendChild(deactivateContainer);
                        dropdownContainer.appendChild(mainContainer);
                    }
                    
                    
                    dotsButtonContainer.appendChild(dotsButton);
                    dotsButtonContainer.appendChild(dropdownContainer);
                    return dotsButtonContainer;

                }
            break;

            case MANAGE_ACCESS.FIELDS.ROLE:
                columnFormatter = function(cell){
                    var div = document.createElement('div');
                    if (tabSelected === MANAGE_ACCESS.ROLE) {
                        div.innerText = cell.getValue();
                        if(cell.getRow().getData()[MANAGE_ACCESS.FIELDS.STATUS] !== MANAGE_ACCESS.ACTIVE) {
                            $(cell.getRow().getElement()).addClass("disabled-row");
                        }
                        return div;
                    } else {
                        if (cell.getValue()) {
                            var spans = cell.getValue().split(',');
                            for (var e in spans) {
                                var span = document.createElement('span');
                                span.innerText = spans.length === 1 ? spans[e] : Number(e) !== spans.length-1 ? spans[e]+", " :  spans[e] ;
                                var roles = roleData.filter(elt=>elt[MANAGE_ACCESS.FIELDS.ROLE] === spans[e]);
                                var status = roles.length > 0 ? roles[0][MANAGE_ACCESS.FIELDS.STATUS] : "";
                                var desc = roles.length > 0 ? roles[0][MANAGE_ACCESS.FIELDS.DESCRIPTION] : "";
                                var param = "title: Description: " + (!!desc ? desc : "None") + "<br> Status: " + status;
                                span.setAttribute('uk-tooltip', param);
                                if(status !== MANAGE_ACCESS.ACTIVE){
                                    span.classList.add("red");
                                    span.style.textDecoration = "line-through";
                                }
                                div.appendChild(span);
                            }
                            return div;
                        }
                    }
                    return div;
                }
            break;

            case MANAGE_ACCESS.FIELDS.ACCESS_GROUP:
                columnFormatter = function(cell){
                    var div = document.createElement('div');
                    if (tabSelected === MANAGE_ACCESS.ACCESS_GROUP_SECTION) {
                        div.innerText = cell.getValue();
                        if(cell.getRow().getData()[MANAGE_ACCESS.FIELDS.STATUS] !== MANAGE_ACCESS.ACTIVE) {
                            $(cell.getRow().getElement()).addClass("disabled-row");
                        }
                        return div;
                    } else {
                        if (cell.getValue()) {
                            var spans = cell.getValue().split(',');
                            for (var e in spans) {
                                var span = document.createElement('span');
                                span.innerText = spans.length === 1 ? spans[e] : Number(e) !== spans.length-1 ? spans[e]+", " :  spans[e] ;
                                var accessgroups = accessData.filter(elt=>elt[MANAGE_ACCESS.FIELDS.ACCESS_GROUP] === spans[e]);
                                var status = accessgroups.length > 0 ? accessgroups[0][MANAGE_ACCESS.FIELDS.STATUS] : "";
                                var desc = accessgroups.length > 0 ? accessgroups[0][MANAGE_ACCESS.FIELDS.DESCRIPTION] : "";
                                var type = accessgroups.length > 0 ? accessgroups[0][MANAGE_ACCESS.FIELDS.TYPE] : "";
                                var param = "title: Description: " + (!!desc ? desc : "None") +"<br> Type:"+ type + "<br> Status: " + status;
                                span.setAttribute('uk-tooltip', param);
                                if(status !== MANAGE_ACCESS.ACTIVE){
                                    span.classList.add("red");
                                    span.style.textDecoration = "line-through";
                                }
                                div.appendChild(span);
                            }
                            return div;
                        }
                    }
                    return div;
                }
                break;

                case MANAGE_ACCESS.FIELDS.NAME:
                columnFormatter = function(cell){
                    var div = document.createElement('div');
                    if (tabSelected === MANAGE_ACCESS.USER) {
                        if(cell.getRow().getData()[MANAGE_ACCESS.FIELDS.STATUS] !== MANAGE_ACCESS.ACTIVE || cell.getRow().getData()[MANAGE_ACCESS.FIELDS.FAILED_ATTEMPTS_NUMBER] >= obj.props.failed_attempts_number) {
                            $(cell.getRow().getElement()).addClass("disabled-row");
                        }
                        var roleStatus = false;
                        var accessStatus = false;
                        if (cell.getRow().getData()[MANAGE_ACCESS.FIELDS.ROLE]) {
                            var spans =cell.getRow().getData()[MANAGE_ACCESS.FIELDS.ROLE].split(',');
                            for (var e in spans) {
                                var roles = roleData.filter(elt=>elt[MANAGE_ACCESS.FIELDS.ROLE] === spans[e]);
                                var status = roles.length > 0 ? roles[0][MANAGE_ACCESS.FIELDS.STATUS] : "";
                                if (status === MANAGE_ACCESS.ACTIVE) {
                                    roleStatus = true;
                                    break;
                                }
                            }
                        }
                        if (cell.getRow().getData()[MANAGE_ACCESS.FIELDS.ACCESS_GROUP]) {
                            var spans =cell.getRow().getData()[MANAGE_ACCESS.FIELDS.ACCESS_GROUP].split(',');
                            for (var e in spans) {
                                var accessgroups = accessData.filter(elt=>elt[MANAGE_ACCESS.FIELDS.ACCESS_GROUP] === spans[e]);
                                var status = accessgroups.length > 0 ? accessgroups[0][MANAGE_ACCESS.FIELDS.STATUS] : "";
                                if (status === MANAGE_ACCESS.ACTIVE) {
                                    accessStatus = true;
                                    break;
                                }
                            }
                        }
                        var span = document.createElement('span');
                        span.innerText = cell.getValue();
                        if (!roleStatus || !accessStatus) {
                            var icon = document.createElement('i');
                            icon.classList.add("fas", "fa-minus-circle", "uk-margin-small-right", "uk-text-primary");
                            div.appendChild(icon);
                        }
                        div.appendChild(span);
                        return div;
                        
                    }
                }
            break;

            case MANAGE_ACCESS.FIELDS.LIMITED_ACCESS:
                columnFormatter = function(cell) {
                    var div = document.createElement('div');
                    if (cell.getRow().getData()[MANAGE_ACCESS.FIELDS.LIMITED_ACCESS] && cell.getRow().getData()[MANAGE_ACCESS.FIELDS.LIMITED_ACCESS] !== "") {
                        var filterVal = tryParse(cell.getRow().getData()[MANAGE_ACCESS.FIELDS.LIMITED_ACCESS]);
                        filterVal = filterVal.filter;
                        var title = [];
                        var values=[];
                        for (var e in filterVal) { 
                            var values = [];
                            var loopedValues = filterVal[e][_values];
                            typeof loopedValues === "object" ? loopedValues.map(function(item){values.push(item.value)}) : values.push(filterVal[e][_values]);
                            title.push((filterVal[e].vectorLabel) + " " +filterVal[e][_field] + " " + filterVal[e][_function] + " " + values.join(","));
                        }
                        div.innerText = title.join(", ");
                        div.title = title;
                    }
                    return div;
                }
            break;
        }
        return columnFormatter;
    }
    

    componentDidUpdate(prevProps) {
        if (this.props.data && this.props.data.length > 0 && JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data)) {
            this.tabulator.replaceData(this.props.data)
        }
    }

    componentDidMount() {
        const options = {
            layout: "fitColumns",
            columns: this.props.columns,
            data: this.props.data,
            pagination:"local",
            paginationSize:15,
        }
        this.tabulator = new Tabulator("#manageAccessTable", options);
    }

    render() {
        var id = this.props.id.replace(' ','-');
        return (
            <div id="manageAccessTable" className={ id+ '-tabulator tabulator' } style={{height: "86%"}}/> 
        );
    }
}

export default ManageAccessTabulator;