import { DialogContent, DialogContentText } from '@mui/material';
import 'jquery';
import React, { Component } from 'react';
import Popup from 'react-popup';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import shortid from 'shortid';
import Tabulator from "tabulator-tables"; //import Tabulator library
import SimpleTable from '../../sections/stageData/SimpleTable.js';
import { getCurrency, toggleLoader } from '../../class/common';
import { ALERT_BOOLEAN_MESSAGE, ALL_WIDGETS, API_URL, BUTTON_TYPE, BUTTON_VARIANT, CONFIG_SETTINGS, CURRENCY_FIELDS, DATATYPE_ENUM, DATA_DICTIONARY_ENUM, DATA_TYPE, DEFAULT_DATE_FORMAT_UPLOAD, DEFINE_CREDIT_DEBIT_ENTRY_TYPE, DEFINE_FIELDS, DIALOG_SIZE, DROPDOWN_TYPE, FILE_CENSUS_FIELDS, FILE_CENSUS_TITLES, FISCAL_YEAR, FormatTypes, Formats, LEADING_CHARACTERS, MENU_ITEM, MODELING_SETTINGS, PERIOD_ONWARD, PI_CW, RAW_ITEMS, SCENARIO_TYPE, SIZES, TRAILING_CHARACTERS, UPLOAD_SECTIONS, defineDataColumns } from '../../class/constants';
import { formatValHTML } from "../../class/format";
import { convertPxToViewport, formatValString } from '../../class/formatting.js';
import { alertAndLogError } from '../../class/jqueries';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from '../../class/networkUtils';
import { compareDefinition, getSelectedSections, setDefineFileColumnNames, updateTableRow, validateFormatsPopUP } from '../../class/uploadDataFunctions.js';
import { adjustNumberFieldToInteger, buildNewObject, capitaliseFirstLetterAfterChar, copyObjectValues, findOptionByKey, getSectionExists, getTranslationFile, parseBoolean } from '../../class/utils.js';
import Button from '../../newComponents/Button';
import { createCheckbox } from '../../newComponents/CheckBoxTabulator';
import Dropdown from '../../newComponents/DropDown';
import { createDropdown } from '../../newComponents/DropdownTabulator';
import Input from '../../newComponents/Input';
import Modal from '../../newComponents/Modal';
import { getTableButton, getTableIcon } from '../../newComponents/tabulatorComponents';
import PeriodsDropDown from '../../sections/buildData/PeriodsDropDown';
import '../../styles/modelingSettings.css';
import UploadDataTable from '../../tables/UploadDataTable';
import { arePeriodsConsecutive} from '../../class/utils.js';
import { createTabulatorInput } from '../../newComponents/InputTabulator';
import { noMatchFilterFunction } from '../../class/tabulatorFormatting.js';

const $ = require('jquery');
const lang = getTranslationFile();
var obj;
const DEFINE_DATA = "define_data";

const baseUrl = process.env.REACT_APP_BASE_URL;
const path = API_URL.DATA_MODELING;
var globaltop = 0;
const ALERT_FILL_MESSAGE = "Please make sure to fill all the mandatory data fields correctly.";

const MESSAGES = getTranslationFile();
var initialDefinition = [];
var definition = [];
var mandatorySectionFieldsVar = [];
var mandatorySectionFields_selectedVar = [];
var mandatorySectionFields_uneditableVar = [];
var dataTypesFieldsVar = [];
var currentDataTypesValues = [];
var currentDataType = Formats.String; //string as default

var currentRawFileId = "";
var cell = {};
var errorMessage = "";
var isTrailingorLeading = "Leading Characters";  //default
const DEFAULT_DATA_VALUE = "Other";
const CHECK_TITLE = "";
const checkboxCellObject = {
    title: CHECK_TITLE,
    visible: true,
    headerSort: false,
    width: '6%'
};
const _index = "index";
const columns = [
    {
        title: "",
        field:_index,
        visible: false
    },
    {
        title: FILE_CENSUS_TITLES.RAW_FILE_TYPE_ID_TITLE,
        field: FILE_CENSUS_FIELDS.RAW_FILE_TYPE_ID_FIELD,
        visible: false
    },
    {
        title: FILE_CENSUS_TITLES.RAW_FILE_TYPE_ID_TITLE,
        field: FILE_CENSUS_FIELDS.RAW_FILE_TYPE_ID_FIELD,
        visible: false
    },
    {
        title: FILE_CENSUS_TITLES.SECTION_TITLE,
        field: FILE_CENSUS_FIELDS.SECTION_FIELD,
        visible: false
    },
    {
        title: FILE_CENSUS_TITLES.EXPECTED_FILES_TITLE,
        field: FILE_CENSUS_FIELDS.EXPECTED_FILES_FIELD,
        headerSort: false,
        visible:false
    },
    {
        title: FILE_CENSUS_TITLES.SUB_SECTION_TITLE,
        field: FILE_CENSUS_FIELDS.SUB_SECTION_FIELD,
        headerSort: false
    },
    {
        title: FILE_CENSUS_TITLES.NAMING_TEMPLATE,
        field: FILE_CENSUS_FIELDS.NAMING_TEMPLATE,
        headerSort: false
    },
    {
        title: FILE_CENSUS_TITLES.DELIMITER,
        field: FILE_CENSUS_FIELDS.DELIMITER,
        headerSort: false,
        width: '10%'
    },
    {
        title: FILE_CENSUS_TITLES.HEADER_ROWS_TO_SKIP,
        field: FILE_CENSUS_FIELDS.HEADER_ROWS_TO_SKIP,
        headerSort: false
    },
    {
        title: FILE_CENSUS_TITLES.START_PERIOD_TITLE,
        field: FILE_CENSUS_FIELDS.START_PERIOD_FIELD,
       
        headerSort: false       
    },
    {
        title: FILE_CENSUS_TITLES.END_PERIOD_TITLE,
        field: FILE_CENSUS_FIELDS.END_PERIOD_FIELD,
        headerSort: false,
    },
    {
        title: FILE_CENSUS_TITLES.RESTRICTION_ACCESS,
        field: FILE_CENSUS_FIELDS.PRIVATE,
        headerSort: false
    },
    {
        title: FILE_CENSUS_TITLES.UPDATE_FLAG_TITLE,
        field: FILE_CENSUS_FIELDS.UPDATE_FLAG_FIELD,
        visible: false,
        headerSort: false
    },
    {
        title: FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID,
        field: FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID,
        visible: false,
        headerSort: false
    },
    {
        title: FILE_CENSUS_TITLES.INSERT_FLAG_TITLE,
        field: FILE_CENSUS_FIELDS.INSERT_FLAG_FIELD,
        visible: false,
        headerSort: false
    },
    {
        title: "",
        field: FILE_CENSUS_FIELDS.ACTIONS,
        visible: true,
        width: '5%',
        headerSort: false,
        headerVisible:false
    },
    {
        title: FILE_CENSUS_FIELDS.SUB_CATEGORY_MUDULE,
        field: FILE_CENSUS_FIELDS.SUB_CATEGORY_MUDULE,
        visible: false,
        headerSort: false
    }
]

const P_AND_L = UPLOAD_SECTIONS.FIELDS.P_AND_L;
const TRANSACTION_DATA = UPLOAD_SECTIONS.FIELDS.TRANSACTION_DATA;
const ANCILLARY_FILES = UPLOAD_SECTIONS.FIELDS.ANCILLARY_FILES;
const MASTER_DATA = UPLOAD_SECTIONS.FIELDS.MASTER_DATA;
const CROSSWALK = UPLOAD_SECTIONS.FIELDS.CROSSWALK;
const CURRENCY = UPLOAD_SECTIONS.FIELDS.CURRENCY;
const onwardPeriod = PERIOD_ONWARD;
const delimiterOptions = [{ value: ',', label: 'Comma' }, { value: '|', label: "Pipe" }, { value: '\t', label: "Tab" }];
/**
 * Data Sources Section in Modeling settings
 * @author [Bassem]
 */
class DataSources extends Component {
		
	constructor(props) {
		super(props);
		this.state = {
            periods: [],
            sectionSubTypeIds: [],
            cell:[],
            changesNotSaved: false,
            multiValue:this.props.userNotifications,
            subsection: "",
            section: "",
            dataSourceHeaders: "",
            charactersOptions: [{"label":TRAILING_CHARACTERS,"value":TRAILING_CHARACTERS},{"label":LEADING_CHARACTERS, "value":LEADING_CHARACTERS}],
            hasDefinedColumns: false,
            rowData: [],
            savedRowNumber: -1,
            columnDefinitionWarningDialogOpen: false,
            columnHeadersDialogOpen: false,
            runAutomationDialogOpen: false,
            showPeriodsComp: false,
            selectPeriodWarning: false,
            nonConsecutivePeriod: false,
            defineOpen: false,
            creditBoolOptions: [],
            creditBoolValues: [],
            defaultBoolOption: "",
            uploadResponseColumns: [{
                Header: "",
                accessor: ""
            }],
        };
    
        obj = this;
        obj.tableReferences = {
            [DEFINE_DATA]: React.createRef()

        }
        this.validateFileCensusSections = this.validateFileCensusSections.bind(this);
        this.onCheckBoxChecked = this.onCheckBoxChecked.bind(this);
        this.getStartEndDates = this.getStartEndDates.bind(this);
        this.checkExistingsubsection = this.checkExistingsubsection.bind(this);
        this.checkPeriodExists = this.checkPeriodExists.bind(this);
        this.addsubsection = this.addsubsection.bind(this);
        this.fetchAPI = fetchAPI.bind(this);
        this.getCurrencyPrerequistes = this.getCurrencyPrerequistes.bind(this);
        this.getDistinctCurrencyValues = this.getDistinctCurrencyValues.bind(this);
        this.addSubtype = this.addSubtype.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.hidePeriods = this.hidePeriods.bind(this);
        this.isFromDialog = false;
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.hidePeriods);
    }

    setCreditBoolOptions = (options) => {this.setState({creditBoolOptions: options});}
    setCreditBoolValues = (values) => {this.setState({creditBoolValues: values});}

    resetBoolValues=()=>{
        let _this = this;
        _this.setState({
            creditBoolOptions: [],
            creditBoolValues:[]
        })
    }

    addsubsection(section, data, cell, subCategoryMudule) {
        // let group = cell.getRow().getGroup();
       let _this = this;
        var subsection = "";
        var rawFileTypeId = "";
        var fileType = "";
        var raw_file_subtype_id = "";
        var privateSubcategory = false;
        if(cell._group.field === "section") {
            rawFileTypeId = cell._group.rows[0].data[FILE_CENSUS_FIELDS.RAW_FILE_TYPE_ID_FIELD];
            section = data[0].section;
            subsection = data[0].subsection;
            fileType = cell._group.rows[0].data[FILE_CENSUS_FIELDS.RAW_FILE_TYPE_FIELD];
            raw_file_subtype_id = cell._group.rows[0].data[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID];
            privateSubcategory = privateSubcategory;
         }
        let periods = copyObjectValues(obj.props.periods);
        let activePeriods = periods.filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE || e.value === onwardPeriod);
        let startPeriod = activePeriods[activePeriods.length - 1].label;
        let endPeriod =  activePeriods[0].label;
        obj.props.data.push({
            [FILE_CENSUS_FIELDS.RAW_FILE_TYPE_ID_FIELD]: rawFileTypeId,
            [FILE_CENSUS_FIELDS.RAW_FILE_TYPE_FIELD]: fileType,
            section: section,
            subsection: "",
            expected_files: "",
            start_period: startPeriod,
            end_period: endPeriod,
            private: privateSubcategory,
            insert_flag: "1",
            update_flag: "0",
            index:shortid.generate(),
            delimiter:delimiterOptions[0].value,
            header_rows_to_skip:1,
            [FILE_CENSUS_FIELDS.SUB_CATEGORY_MUDULE]: subCategoryMudule,
        });

      this.tabulator.replaceData(obj.props.data).then(function(){       
        _this.tabulator.scrollToRow(cell._group.getRows()[cell._group.getRows().length -1].getData()[_index]);
    })
    .catch(function(error){
        //handle error loading data
    });
    }

    getAllFileTypeColumns =() => {
        var obj = this;
        var query = {
            action: "getAllFileTypeColumns",
            scenario_id: this.props.scenarioId,
            fileType: JSON.stringify(['master_data', 'transaction_data', 'ancillary_files','general_ledger', 'currency'])
        }

       let onThenCallback = (data) => {
            let response = data;
            let files = [];
            let filesString = [];
            response.data.map(function(item){
                if(!filesString.includes(item.display_file_type)){
                    files.push({value:item.file_type, label:item.display_file_type , data_file_type:item.data_file_type , raw_file_subtype_id:item.raw_file_subtype_id, mapping_column: item.mapping_column, mapping_file_type_id: item.mapping_file_type_id});
                    filesString.push(item.display_file_type);
                }
            });
            obj.setState({
                dataColumns: response.data,
                mappingFileTypes: files
            });

       }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getAllFileTypeColumns",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.modeling_settings.datasources.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.modeling_settings.datasources.requests_description.get_all_file_type_columns
        }
        fetchAPI(fetchOptions);

    }



    

     getDistinctCurrencyValues (callback){
        var obj = this;
        var query = {
            action: "getDistinctCurrencyValues",
            scenario_id: this.props.scenarioId,
            isNewArch: true
        }
       
        let onThenCallback = (data) => {
            let response = data;
            let currencies = [];
            response.data.map(function(item){
                currencies.push({value:item.value, label: item.value, isStatic:true, name: item.name});
            });
            obj.setState({
                currencies: currencies
            },()=>{
                callback();
            })

       }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getDistinctCurrencyValues",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.modeling_settings.datasources.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.modeling_settings.datasources.requests_description.get_distinct_currency_values
        }

        fetchAPI(fetchOptions);
    }

    setOpen = (open) => {
        let _this = this;
        _this.setState({ open: open });
    }
    /**
     * Function that opens the 'Set columns headers' dialog
     * @param {*} open
     */
    setColumnHeadersDialogOpen = (open) => {
        let _this = this;
        _this.setState({ columnHeadersDialogOpen: open });
    }

    /**
     * Function that opens the 'Run Automation' dialog
     * @param {*} open
     */
    setRunAutomationDialogOpen = (open) => {
        let _this = this;
        let showPeriodsComp = open ? _this.state.showPeriodsComp : false ;
        let selectPeriodWarning = open ? false : _this.state.selectPeriodWarning;
        let nonConsecutivePeriod = open ? false : _this.state.nonConsecutivePeriod;
        
        _this.setState({
            runAutomationDialogOpen: open,
            showPeriodsComp: showPeriodsComp,
            selectPeriodWarning: selectPeriodWarning,
            nonConsecutivePeriod: nonConsecutivePeriod,
        });
    }

    /**
     * Function that opens the 'Define columns warning' dialog
     * @param {*} open
     */
    setColumnDefinitionWarningDialogOpen = (open) => {
        let _this = this;
        _this.setState({ columnDefinitionWarningDialogOpen: open });
    }

    /**
     * opend define dialog
     * @param {*} open
     */
    setDefineOpen = (open) => {
        let _this = this;
        _this.setState({ defineOpen: open });
    }
    /**
     * closes define dialog on cancel
     */
    onDefineCancel = () => {
        let _this = this;
        _this.setDefineOpen(false);
        _this.setValidationDialogOpen(false)
    }

    /**
     * fetches definition of the subtype clicked along with mandatory fields
     * @param {*} subTypeId
     */
    getSubtypeDefinition = (subTypeId) => {
        var tableColumns = defineDataColumns;
        let _this = this;
        var query = {
            action: "getSubTypeDefinition",
            subTypeId: subTypeId,
            section: _this.state.section
        }

        let onThenCallback = (data)=>{
            if(data.error && data.error.message) {
                _this.setInfoDialogOpen(true, data.error.message);
                _this.setDefineOpen(false);
                return;
            }else{
                var columnNames = data.fileDefinition.map(function(item){return item.raw_field_name});
                var tempDefineTableData = data.fileDefinition;   //if the file is already defined, we have to fill its definition data in the table
                var mandatorySectionFields = data.mandatoryFields;
                var dataTypesFields = data.dataTypesFields;
                var transactionData = data.transactionData;
                var defineTableData = setDefineFileColumnNames(columnNames, tableColumns, [], tempDefineTableData, 0, dataTypesFields, []);
                var selectedMandatoryFields = getSelectedSections(defineTableData);
                defineTableData.forEach(function(currentItem) {
                    currentItem["id"] = Number(currentItem["row_number"]);
                });

                initialDefinition = copyObjectValues(defineTableData);
                definition = defineTableData;
                mandatorySectionFieldsVar = mandatorySectionFields;      //this state will be edited
                mandatorySectionFields_selectedVar = selectedMandatoryFields;
                mandatorySectionFields_uneditableVar = mandatorySectionFields;   //this state is not to be edited, will be a reference
                dataTypesFieldsVar = dataTypesFields;
                var transactionKey = undefined;
                var transactionFile = undefined;
                var transactionFileId = undefined;
                if ((_this.state.section === MASTER_DATA || _this.state.section === ANCILLARY_FILES) && _this.state.subsection !== "") {
                    if(transactionData.filter(e=>e[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID] === subTypeId).length > 0 ){
                        let mappingColumn = transactionData.filter(e=>e[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID] === subTypeId)[0]["mapping_column"];
                        let mappingFileTypeId = transactionData.filter(e=>e[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID] === subTypeId)[0]["mapping_file_type_id"]; 
                        transactionKey = mappingColumn ? mappingColumn : transactionKey
                        transactionFileId = mappingFileTypeId ? mappingFileTypeId : "";
                        transactionFile = obj.state.dataColumns.filter(e=>e[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID] === transactionFileId).length > 0 ?  _this.state.dataColumns.filter(e=>e[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID] === transactionFileId)[0]["display_file_type"] : ""
                    }
                }
                var fileTypeObj = {subsection: transactionFile, column: transactionKey};
                _this.setState({
                    transactionData: transactionData,
                    transactionKey: transactionKey,
                    transactionFile: transactionFile,
                    transactionFileId: transactionFileId,
                    definedFileNumber: data.fileDefinition[0]['file_number'],
                    mappingFileTypes: _this.state.section === MASTER_DATA ? obj.state.mappingFileTypes.filter(e=>e["value"] !== _this.state.subsection ) :_this.state.mappingFileTypes,
                }, function(){
                    // $("#dots_loader").removeClass("uk-display-flex");
                    // $("#dots_loader").css('display','none');
                    if (_this.state.section === MASTER_DATA || _this.state.section === ANCILLARY_FILES) {
                        _this.getTransactionColumns(fileTypeObj);
                    }
                    _this.tableReferences[DEFINE_DATA].current.forceUpdate();
                })
            }
        }
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getSubTypeDefinition",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.modeling_settings.datasources.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.modeling_settings.datasources.requests_description.get_subtype_definition
        }
        fetchAPI(fetchOptions);
    }

    checkIfFileIsUploadingOrRedefining = (subTypeId, checkIfFilePassed) => {
        let _this = this;
        var query = {
            action: "checkIfFileIsUploadingOrRedefining",
            subTypeId: subTypeId,
            checkIfFilePassed: checkIfFilePassed
        }

        let onThenCallback = (data)=>{
            if(!data.isFileUploadingOrRedefining) {
                if(checkIfFilePassed) {
                    _this.setColumnHeadersDialogOpen(true);
                } else {
                    _this.setDefineOpen(true);
                    _this.getSubtypeDefinition(subTypeId);
                }

                _this.setState({
                    isWarning: false,
                });
            } else {
                _this.setState({
                    isWarning: true,
                    notification: lang.modeling_settings.at_least_one_file_is_uploading
                }, () => {
                    $("#toastDataSources").addClass("show");
                    setTimeout(function(){
                        $("#toastDataSources").removeClass("show");
                    }, 3000);
                });
            }

        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "checkIfFileIsUploadingOrRedefining",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.modeling_settings.datasources.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.modeling_settings.datasources.requests_description.check_if_file_is_uploading_or_redefining
        }
        fetchAPI(fetchOptions);
    }

    /**
     *
     * @returns renders define table
     */
  defineBodyContent = () => {
    return (
      <>
        <div id="define-dropdown-table" className='uk-margin-bottom'>
          {this.state.section === MASTER_DATA || this.state.section === ANCILLARY_FILES ?
            <div className="uk-flex ">
              <h6 className="fs-14 input-width-300 uk-margin-xsmall-bottom">{MESSAGES.UPLOAD.ancillary_join_data_file_name}</h6>
              <h6 className="fs-14 input-width-300 uk-padding-large-left uk-margin-xsmall-bottom">{MESSAGES.UPLOAD.ancillary_join_data_column}</h6>
            </div>
            : ""}
          {this.state.section === MASTER_DATA || this.state.section === ANCILLARY_FILES ?
            <div className="uk-flex">
              <Dropdown
                id="select-transactionFile"
                className="input-width-300 input__dropdown"
                name="transactionColumn "
                placeholder="Transaction/Master File Name"
                value={findOptionByKey(this.state.mappingFileTypes, this.state.transactionFile)}
                options={this.state.mappingFileTypes}
                onChange={this.handleMappingFileChange}
                isDisabled={this.state.defineAsReadOnly}
                type={DROPDOWN_TYPE.INPUT}
              />
              <Dropdown
                id="select-transactionColumn"
                className="input-width-300 uk-margin-medium-left input__dropdown"
                name="transactionColumn "
                placeholder="Transaction/Master Column"
                value={findOptionByKey(this.state.transactionColumns, this.state.transactionKey)}
                options={this.state.transactionColumns}
                onChange={(e) => this.handleTransactionColumnChange(e)}
                isDisabled={this.state.defineAsReadOnly}
                type={DROPDOWN_TYPE.INPUT}
              />
            </div>
            : ""}
        </div>
        <UploadDataTable id={DEFINE_DATA + "_table"} ref={this.tableReferences[DEFINE_DATA]} initialDefinition={[]} compareDefinition={compareDefinition} table_type={DEFINE_DATA} tableData={definition} DEFINE_DATA={DEFINE_DATA}
          selectedFileColumnNames={this.state.selectedFileColumnNames} setFileHeader={this.setFileHeader} getTableData={this.getTableData}
          mandatorySectionFields={mandatorySectionFieldsVar} handleRowDataChange={this.handleRowDataChange} selectedSectionFields={mandatorySectionFields_selectedVar} defineAsReadOnly={false} isFileDefined={false} dataTypesFields={dataTypesFieldsVar}
          defineData={definition} setFormatsElements={this.setFormatsElements} section={this.state.section} subsection={this.state.subsection} configure={this.configure} clearConfiguration={this.clearConfiguration}
          fromDataSource={true} setValue={this.setValue}
        />
      </>
    )
  }

    validateJoinFile = (fileType, subTypeId) => {
        var _this = this;
        if (this.state.section !== MASTER_DATA)
            return true;
        if (fileType.data_file_type === TRANSACTION_DATA) // maped to transaction
            return true;
        if(fileType.mapping_column === null || !fileType.mapping_column || fileType.mapping_column === "") // not mapped
            return false;
        if(fileType.mapping_file_type_id === subTypeId) //mapped to the file we're mapping
            return false;
        var type = _this.state.mappingFileTypes.filter(e=>e.raw_file_subtype_id === fileType.mapping_file_type_id);
        if (type.length ===  0) {
            return false;
        }
        return this.validateJoinFile(type[0], subTypeId);
    }

    getTransactionColumns = (fileTypeObj) => {
        var _this = this;
        if (fileTypeObj.subsection === "empty-line") {
            this.setState({
                transactionColumns: []
            });
        }else{
            var data = this.state.dataColumns.filter(e=>e["display_file_type"] === fileTypeObj.subsection);
            let fields = [];
            data.map(function(item) {
                if(item["field_data_type"].toUpperCase() === "STRING") {
                    fields.push({
                        label: item["raw_field_name"].replace("_pi_cw",PI_CW),
                        value: item["raw_field_name"].replace('pi_cw',PI_CW),
                        file_type: item["file_type"],
                        raw_file_subtype_id: item["raw_file_subtype_id"],
                        field_data_type:item["field_data_type"]
                    });
                }
            });
            this.setState({
                transactionColumns: fields
            })
        }
    }


    handleMappingFileChange = (e) => {
        var _this = this;
        var fileType = _this.state.mappingFileTypes.filter(elt=>elt.raw_file_subtype_id === e.raw_file_subtype_id);
        if (this.validateJoinFile(fileType[0],e.raw_file_subtype_id)) {
            if (e !== null) {
                $("#select-transactionFile").find('[class$="singleValue"]').text(e.label);
                    this.setState({
                        transactionKey: "",
                        auditMesage: "",
                        transactionFile: e.value,
                        transactionFileId: e.raw_file_subtype_id,
                    }, function () {
                        if (this.state.subsection !== "") {
                            $("#save-new-arch").removeClass("disabled");
                        }
                        _this.getTransactionColumns({ subsection: e.label });
                    });
            }
        } else{
            _this.setState({
                warning: MESSAGES.invalid_file
            });
            _this.setColumnDefinitionWarningDialogOpen(true);
        }

    }

    handleTransactionColumnChange = (e) => {
        var _this = this;
            if (e !== null) {
                $("#select-transactionColumn").find('[class$="singleValue"]').text(e.value);
                $("#select-transactionColumn").find('[class$="placeholder"]').text(e.value);
                this.setState({
                    transactionKey: e.value,
                    auditMesage:""
                },function(){
                    if (this.state.subsection !== "") {
                        $("#save-new-arch").removeClass("disabled");
                    }

                });
        }
    }

    setFileHeader(fileName, type) {
        this.setState({
            definedFileName: fileName,
            definedTableType: type
        });
    }

    getTableData=()=>{
        return definition;
    }

    handleRowDataChange=(e, cell)=> {
        let _this = this;
        var unselectedOptions = copyObjectValues(mandatorySectionFields_uneditableVar);
        var selectedOptionsValues = copyObjectValues(mandatorySectionFields_selectedVar);
        var newSelectedValue = $(e.currentTarget).val();
        var oldSelectedValue = cell.getValue();             //value that will be replaced
        var rowData = cell.getRow().getData();
        var rowIndex = rowData[DEFINE_FIELDS.ROW_NUMBER_FIELD];   //extract the row index from the row data
        var optionIndex = -1;    //this index marks the index of the entity chosen from the dropdown
        //remove the selected option from the list of options
        $('#save-new-arch').removeClass('disabled');
        for (var option in unselectedOptions) {
            if(unselectedOptions[option].name === newSelectedValue) {
                optionIndex = option;   //save the newly selected option's index (this index is its index in the dropdown)
                break;
            }
        }

        var oldValueIndex = selectedOptionsValues.indexOf(oldSelectedValue);    //save the index of the old value (this index is its index in the list of selected options)
        var newValueIndex = selectedOptionsValues.indexOf(newSelectedValue);    //save the index of the new value (this index is its index in the list of selected options)

        if (oldValueIndex !== -1) {
            selectedOptionsValues[rowIndex] = "";   //remove the old value from the list of selected ones
        }

        if(newValueIndex === -1 && newSelectedValue !== DEFAULT_DATA_VALUE) {   //extra check
            selectedOptionsValues[rowIndex] = newSelectedValue;   //add the newly selected value to the list of selected ones, at the exact index of the cell row
        }

        var newDefineTableData = updateTableRow(cell, optionIndex, copyObjectValues(definition), dataTypesFieldsVar, mandatorySectionFields_uneditableVar);

        definition = newDefineTableData;
        mandatorySectionFieldsVar = unselectedOptions;
        mandatorySectionFields_selectedVar = selectedOptionsValues;
        var table = _this.tableReferences[DEFINE_DATA].current.tabulator;
        table.replaceData(definition);
        table.redraw();
        compareDefinition(JSON.stringify(initialDefinition), JSON.stringify(table.getData()));
    }

    setFormatsElements=(thisCell)=> {
        let _this = this;
        var rowData = thisCell.getRow().getData();
        currentDataTypesValues = rowData;
        currentDataType = rowData[DEFINE_FIELDS.DATA_TYPE_FIELD];
        currentRawFileId = rowData[DEFINE_FIELDS.RAW_SUBTYPE_FIELD_ID_FIELD];
        cell = thisCell;
        isTrailingorLeading = rowData[DEFINE_FIELDS.IS_TRAILING_FIELD] === "false" ? "Leading Characters" : "Trailing Characters";
        errorMessage = "";

        this.setFormatDialogOpen(true, currentDataType)
        ////show format hasab l data type

        // hiding all format divs, and showing only one
        $(".datatype-format").attr("hidden", "hidden");
        $(".datatype-format." + currentDataType).removeAttr("hidden");

        $("#maxLength").val(rowData[DEFINE_FIELDS.MAX_LENGTH_FIELD]);
        $("#minLength").val(rowData[DEFINE_FIELDS.MIN_LENGTH_FIELD]);
        $("#trailOrLead").val(rowData[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD]);
        $("#max").val(rowData[DEFINE_FIELDS.MAX_FIELD]);
        $("#min").val(rowData[DEFINE_FIELDS.MIN_FIELD]);
        $("#decimal").val(rowData[DEFINE_FIELDS.DECIMAL_FIELD]);
        $("#dateFormat").val(rowData[DEFINE_FIELDS.DATE_FORMAT_FIELD] === "" ? DEFAULT_DATE_FORMAT_UPLOAD : rowData[DEFINE_FIELDS.DATE_FORMAT_FIELD]);
        $("#trueInput").val(rowData[DEFINE_FIELDS.TRUE_INPUT_FIELD]);

        var trueInputLabel = thisCell.getData()[DEFINE_FIELDS.DATA_FIELD] === DEFINE_CREDIT_DEBIT_ENTRY_TYPE.ENTRY_TYPE ? DEFINE_CREDIT_DEBIT_ENTRY_TYPE.CREDIT : "True";
        $("#trueInputLabel").text(trueInputLabel);
        var boolValue = thisCell.getData()[DEFINE_FIELDS.DATA_FIELD] !== DEFINE_CREDIT_DEBIT_ENTRY_TYPE.ENTRY_TYPE ? MESSAGES.no_values_are_true : MESSAGES.no_values_are_credit;
        this.setState({defaultBoolOption: {label: boolValue, value: boolValue}});
        this.setCreditBoolOptions([this.state.defaultBoolOption]);

        var arrT = [];
        var trueVals = rowData[DEFINE_FIELDS.TRUE_INPUT_FIELD] !== "" ? rowData[DEFINE_FIELDS.TRUE_INPUT_FIELD].split(",") : [];
        if (trueVals.length > 0) {
            for (var e in trueVals) {
                arrT.push({label:trueVals[e], value:trueVals[e]});
            }
            this.setState({
                creditBoolValues: arrT
            },function(){
                _this.tableReferences[DEFINE_DATA].current.tabulator.scrollToRow(thisCell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]);
            })
        }
    }

    configure = (row) => {
        toggleLoader(true, 'global');
        let obj = this;
        $('#save-new-arch').removeClass('disabled');
        if (row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] && !row.fileType) {
            if (obj.state.dataColumns.filter(e => e[RAW_ITEMS.SUBTYPE_ID] === row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID]).length > 0) {
                row.file_type = obj.state.dataColumns.filter(e => e[RAW_ITEMS.SUBTYPE_ID] === row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID])[0][RAW_ITEMS.SUBTYPE_NAME]
            }
        }
      let tempState = {
        rowData: row,
        currencyDate: row[DEFINE_FIELDS.CURRENCY_DATE] ? { value: row[DEFINE_FIELDS.CURRENCY_DATE], label: row[DEFINE_FIELDS.CURRENCY_DATE] } : undefined,
        currencyColumn: { value: row[DEFINE_FIELDS.CURRENCY_FIELD_NAME], label: row[DEFINE_FIELDS.CURRENCY_FIELD_NAME], isStatic: row[DEFINE_FIELDS.STATIC] },
        currencysubsection: row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] ? { raw_file_subtype_id: row[DEFINE_FIELDS.CURRENCY_SUBTYPE_ID], value: row.file_type, label: row.file_type } : undefined,
        showCurrencyDate: row[DEFINE_FIELDS.CURRENCY_DATE],
        currenciesMatchingData: [],
        matching_message: undefined,
        currencyColumns: []
      }
      obj.setConfigureDialogOpen(true, tempState, () => {
        obj.getCurrencyPrerequistes(() => {
          if (row[DEFINE_FIELDS.CURRENCY_FIELD_NAME]) {
            obj.fetchCurrencyColumns(obj.state.currencysubsection);
            obj.runMatchingCurrenciesAudit()
          }
          obj.setPage(0);
          toggleLoader(false, 'global');
        });
      });
    }

    setPage = (pageNumber) => {
        this.setState({
            page: pageNumber
        });
    }

    saveconfiguration = () => {
        if ((!this.state.currencyColumn || !this.state.currencyColumn.value|| !this.state.currencysubsection) ||
            this.state.showCurrencyDate && !this.state.currencyDate) {
            this.setState({
                warning: MESSAGES.UPLOAD.fill_mandatoryFields,
                columnDefinitionWarningDialogOpen: true
            })
        } else {
            var row = {};
            var obj = this;
            var data = this.tableReferences[DEFINE_DATA].current.tabulator.getData();
            for(var e in data) {
                if (data[e][RAW_ITEMS.FIELD_NAME] === obj.state.rowData[DEFINE_FIELDS.FIELD_NAME_FIELD]) {
                    data[e][DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] = !! this.state.currencysubsection ? this.state.currencysubsection[RAW_ITEMS.SUBTYPE_ID] : undefined;
                    data[e][DEFINE_FIELDS.CURRENCY_FIELD_NAME] = !!this.state.currencyColumn ? this.state.currencyColumn.value :  undefined
                    data[e][DEFINE_FIELDS.STATIC] = !!this.state.currencyColumn ? typeof this.state.currencyColumn.isStatic  === "string"  ?  this.state.currencyColumn.isStatic === "true" ? 'true' : 'false' : this.state.currencyColumn.isStatic ? 'true' :'false' :undefined;
                    data[e][DEFINE_FIELDS.CURRENCY_DATE]= !!this.state.showCurrencyDate ?  this.state.currencyDate ? this.state.currencyDate.value : undefined : undefined;
                    data[e][DEFINE_FIELDS.DEFINED_SUBTYPE_ID] = this.state.subTypeId;
                    row = data[e];
                }
            }
            $("#define-submit").removeClass("uk-button-disabled");
            definition = data;
            this.tableReferences[DEFINE_DATA].current.tabulator.replaceData(data).then(()=>{
                obj.setState({
                    currenciesMatchingData:[]
                })
            });
            this.tableReferences[DEFINE_DATA].current.tabulator.scrollToRow(row[DEFINE_FIELDS.ROW_NUMBER_FIELD]);
            this.setConfigureDialogOpen(false);
        }
    }

    handleCurrencyChange = (e, type) => {
        if (type === RAW_ITEMS.FILE) {
            this.setState({
                currencysubsection: e,
                currencyColumn: undefined,
                currencyDate: undefined
            },function() {
                this.fetchCurrencyColumns(e);
            })
        } else if (type === RAW_ITEMS.FIELD_NAME) {
            this.setState({ currencyColumn: e });

            var currencySubcat = this.tabulator.getData().filter(f => f.subsection.toLowerCase() === this.state.currencysubsection.label.toLowerCase())[0];
            var currSubcat = this.tabulator.getData().filter(f => f.subsection === this.state.subsection)[0];
            if (currencySubcat && currencySubcat[FILE_CENSUS_FIELDS.HAS_DEFINED_FILES] && currSubcat[FILE_CENSUS_FIELDS.HAS_DEFINED_FILES]) {
                var currColumn = e ? e : obj.state.currencyColumn;
                const isStatic = currColumn.isStatic === "true" || (typeof currColumn.isStatic !== 'string' && currColumn.isStatic);
                if(isStatic){
                    obj.setState({
                        currenciesMatchingData: [],
                        matching_message: "",
                        tooltip_message:""
                    })
                } else {
                    this.runMatchingCurrenciesAudit(e)
                }
            }
        } else {
            this.setState({ currencyDate: e })
        }
    }


    runMatchingCurrenciesAudit = (currencyColumn) => {
        var obj = this;
        var currColumn = currencyColumn ? currencyColumn : obj.state.currencyColumn;
        
        var query = {
            action: "runMatchingCurrenciesAudit",
            scenario_id: this.props.scenarioId,
            fileType: this.state.currencysubsection.value,
            fileTypeDenom: this.state.subsection.replaceAll(" ", "_").toLowerCase(),
            fieldName: currColumn,
            section: this.state.section,
            period: this.state.selectedPeriod,
            fileNumber: this.state.definedFileNumber
        }

        let onThenCallback = (data) => {
            let response = data;
            let currencySubcategory = response.currencySubcategory ;
            let transactionCurrency = response.transactionCurrency;
            let message = currencySubcategory + "/" + transactionCurrency;
            if (currencySubcategory === transactionCurrency) {
                $("#matchingCurrency").removeClass("red");
            }else{
                $("#matchingCurrency").addClass("red");
            }
            obj.setState({
                currenciesMatchingData: response.result,
                matching_message: message,
                tooltip_message: currencySubcategory + " of " + transactionCurrency +
                    " ( "+ (Number(currencySubcategory) /Number(transactionCurrency)) *100 +"% ) entities in " + capitaliseFirstLetterAfterChar(obj.state.subsection.replaceAll("_"," "))+ " currency match."
            })

        }
        
        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "runMatchingCurrenciesAudit",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader] : true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.modeling_settings.datasources.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.modeling_settings.datasources.requests_description.run_matching_keys_audit
        }
        obj.fetchAPI(fetchOptions);

    }

    fetchCurrencyColumns = (option) => {
        let _this = this;
        var columns = copyObjectValues(_this.state.dataColumns);
        var columnsCurrency = copyObjectValues(_this.state.dataColumns);
        var currencyDateColumnsOrg = copyObjectValues(_this.state.dataColumns);
        var showCurrencyDate = false;
        var dataColumns = [];
        var displayColumns = [];
        var displayDateColumns = [];
        var currencyDateColumns = [];
        // columns of type string of the selected subsection file
        columns = columns.filter(e=>e.file_type === obj.state.subsection.replaceAll(" ", "_").toLowerCase() && obj.state.section === e.data_file_type && (e[RAW_ITEMS.FIELD_DATA_TYPE] === DATATYPE_ENUM.STRING.toUpperCase() || e[RAW_ITEMS.FIELD_DATA_TYPE] === ""));
        if (columns.length === 0) { // not defined file => columns wont be fetched so we get them from tableData
            columns = _this.tableReferences[DEFINE_DATA].current.tabulator.getData().filter(e=>e[DEFINE_FIELDS.DATA_TYPE_FIELD] === DATATYPE_ENUM.STRING.toUpperCase() || e[DEFINE_FIELDS.DATA_TYPE_FIELD] === "" || !e[DEFINE_FIELDS.DATA_TYPE_FIELD])
        }
        columns.map(function(item){
            displayColumns.push({value:item[RAW_ITEMS.FIELD_NAME], label:item[RAW_ITEMS.FIELD_NAME] , data_file_type:item.file_type , raw_file_subtype_id: item.raw_file_subtype_id});
        });
        // checking if curreny file selected is defined be period or date
        var currencyColumns = columnsCurrency.filter(e=>e[RAW_ITEMS.NAME].toLowerCase() === CURRENCY_FIELDS.DATE.toLowerCase() && e[RAW_ITEMS.SUBTYPE_NAME] === option.value);
        if (currencyColumns && currencyColumns.length > 0) {
            showCurrencyDate  = true; // fetching columns of type datetime from selected subsection file
            currencyDateColumns = currencyDateColumnsOrg.filter(e=>e.file_type === obj.state.subsection.replaceAll(" ", "_").toLowerCase() && e[RAW_ITEMS.FIELD_DATA_TYPE] === DATATYPE_ENUM.DATETIME.toUpperCase());
            if (currencyDateColumns.length === 0 ) {
                currencyDateColumns = _this.tableReferences[DEFINE_DATA].current.tabulator.getData().filter(e=>e[DEFINE_FIELDS.DATA_TYPE_FIELD] === DATATYPE_ENUM.DATETIME.toUpperCase())

            }
            currencyDateColumns.map(function(item){
                displayDateColumns.push({value:item[RAW_ITEMS.FIELD_NAME], label:item[RAW_ITEMS.FIELD_NAME] , data_file_type:item.data_file_type , raw_file_subtype_id:item.raw_file_subtype_id});
            });
        }

        var currencies = _this.state.currencies.filter(e=>e.name === option.value);
        dataColumns.push({label:MESSAGES.UPLOAD.static_currency, value: MESSAGES.UPLOAD.static_currency , isDisabled:true});
        dataColumns = dataColumns.concat(currencies);
        dataColumns.push({label:MESSAGES.UPLOAD.currency_column, value: MESSAGES.UPLOAD.currency_column , isDisabled:true});
        dataColumns = dataColumns.concat(displayColumns);
        _this.setState({
            currencyColumns: dataColumns,
            showCurrencyDate: showCurrencyDate,
            currencyDateColumns: displayDateColumns
        })

    }

     getCurrencyPrerequistes (callback){
        let obj = this;
        var columns = obj.state.dataColumns;
        var filesString = [];
        var currencysubsections = [];
        columns.map(function(item){
            if(!filesString.includes(item.display_file_type) && item.data_file_type === CURRENCY){
                currencysubsections.push({value:item.file_type, label:item.display_file_type , data_file_type:item.data_file_type , raw_file_subtype_id:item.raw_file_subtype_id});
                filesString.push(item.display_file_type);
            }
        });
         obj.setState({
            currencysubsections: currencysubsections
        },()=>{
            obj.getDistinctCurrencyValues(()=>{
                callback()
            });
        })

    }

    clearConfiguration = (rowData, notclear) => {
        var row = {};
        var data = this.tableReferences[DEFINE_DATA].current.tabulator.getData();
        for(var e in data) {
            if (notclear === undefined) {
                if (data[e][RAW_ITEMS.FIELD_NAME] === rowData[DEFINE_FIELDS.FIELD_NAME_FIELD]) {
                    data[e][DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] = undefined;
                    data[e][DEFINE_FIELDS.CURRENCY_FIELD_NAME] = undefined;
                    data[e][DEFINE_FIELDS.STATIC] = undefined;
                    data[e][DEFINE_FIELDS.CURRENCY_DATE]= undefined;
                    data[e].currency_checked = false;
                    row = data[e];
                }
            }else if (data[e][RAW_ITEMS.FIELD_NAME] === rowData[DEFINE_FIELDS.FIELD_NAME_FIELD]) {
                data[e].currency_checked = true;
                row = data[e];
            }
        }
        $("#define-submit").removeClass("uk-button-disabled");
        this.tableReferences[DEFINE_DATA].current.tabulator.replaceData(data);
        this.tableReferences[DEFINE_DATA].current.tabulator.scrollToRow(row[DEFINE_FIELDS.ROW_NUMBER_FIELD]);
        $('#save-new-arch').removeClass('disabled');
    }

    toggleTable(shouldShow) {
        this.tabulator.setHeight(shouldShow ? convertPxToViewport(326) : 0);
    }

    launchToast(saved, isRedefine) {
        this.setState({
            notification: isRedefine ?  lang.redefining : saved ? lang.saved : lang.not_saved,
        },function(){
            $("#toastDataSources").addClass("show");
            setTimeout(function(){
                $("#toastDataSources").removeClass("show");
            }, 3000);
        })
    }

    launchAutomationToast = () => {
        let _this = this;
        _this.setState({
            notification: lang.automation_started,
        },function(){
            $("#toastDataSources").addClass("show");
            setTimeout(function(){
                $("#toastDataSources").removeClass("show");
            }, 3000);
        })
    }

    validateFileCensusSections() {
        var data = this.props.data;
        var text = "";
        let tempState = {};
        for(var e in data) {
            if (data[e].update_flag !== "-1") {
                if (data[e].subsection === "" || data[e].subsection === undefined) {
                    text = lang.modeling_settings.validations.data_source.empty;
                    break;
                }else if(data[e].subsection && data[e].subsection !== "" && !this.isValidsubsection(data[e].subsection)) {
                    text = lang.modeling_settings.validations.data_source.alpha_numeric;
                } else if(data[e].subsection && data[e].subsection !== "" && data.filter(i=>i.subsection.toLowerCase().replace(/ /g,"") === data[e].subsection.toLowerCase().replace(/ /g,"") && i.section ===  data[e].section).length > 1) {
                    text = lang.modeling_settings.validations.data_source.unique;
                }else if(!data[e][FILE_CENSUS_FIELDS.NAMING_TEMPLATE] || data[e][FILE_CENSUS_FIELDS.NAMING_TEMPLATE] === "") {
                    text = lang.modeling_settings.validations.fileNamingTemplate.empty; 
                } else if (!!data[e][FILE_CENSUS_FIELDS.NAMING_TEMPLATE]){
                     if(!this.isNamingTemplateUnique(data[e][FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID], data[e][FILE_CENSUS_FIELDS.NAMING_TEMPLATE])) {
                        text = lang.modeling_settings.validations.fileNamingTemplate.unique;
                        break;
                    }
                }
            }
        }
        if (text.length > 0 ) {
            tempState.validation = text
            this.setValidationDialogOpen(true)
            this.setState(tempState);
            return false;
        } else {
            this.setChangesNotSaved(true);
            this.setState({
                isWarning: false,
            });
            return true;
        }
    }
    
    onTabulatorRenderComplete() {
        let obj = this;
        if(obj){
           obj.redraw();
        }
    }

    componentDidMount() {
        var obj = this;
        obj.props.periods?.map(period => period.checked = false);
        var tabulatorOptions = {
            index: _index,
            data: obj.props.data !== undefined ? obj.props.data : [],
			layout: "fitColumns",        //fit columns to width of table
			responsiveLayout: true,     //hide columns that dont fit on the table
			tooltips: true,              //show tool tips on cells
			addRowPos: "top",            //when adding a new row, add it to the top of the table
			history: true,               //allow undo and redo actions on the table
			pagination: false,          //paginate the data
			movableColumns: false,        //allow column order to be changed
			resizableRows: true,        //allow row order to be changed
			selectable: false,
			resizableColumns: false,
			virtualDomBuffer: 30000,
            virtualDom:true,
			placeholder: "",
			height: "100%",
            width: "100%",
            groupBy: [FILE_CENSUS_FIELDS.SECTION_FIELD],
			groupStartOpen: false,
            groupToggleElement: "arrow",
            addRowPos: "top",
            groupVisibilityChanged:function(group, visible){
                if(visible){
                    $('.dataSourcesContainer').css("width","99%")
                }
            },
            groupClick:function(e, group){
                obj.tabulator.getGroups().forEach(G =>{
                    obj.addListenersOnHover(G._group.key.replaceAll(" ","_").replaceAll("&","_"));
                });
            },
            groupHeader: function (groupName, count, data, cell) {
                var addBtn = getTableIcon(["fal", "fa-plus-circle", "uk-margin-small-right", "uk-button-icon"]);
                addBtn.title = "Add new row";
                addBtn.id = "addBtn_"+groupName.replaceAll(" ", "_").replaceAll("&","_");
                addBtn.onclick = (e) => {
                    if(groupName === UPLOAD_SECTIONS.TITLES.GENERAL_LEDGER && obj.props.isBudgetingAllowed){
                        document.getElementById('in-dropdown-container-gl').style["display"] = "block"
                    } else if(groupName === UPLOAD_SECTIONS.TITLES.PRELIMINARY){
                        obj.addSubtype(groupName, data, cell, DATA_TYPE.PRELIMINARY);
                    } else {
                        obj.addSubtype(groupName, data, cell, SCENARIO_TYPE.NORMAL);
                    }
                };
                var parentDiv = document.createElement("div");
                if(groupName === UPLOAD_SECTIONS.TITLES.GENERAL_LEDGER && obj.props.isBudgetingAllowed ){
                    parentDiv.id = "add-child-row" ;
                    var dropDownDiv = document.createElement("div");
                    dropDownDiv.classList.add("in-dropdown-container", "uk-margin-medium-top");
                    dropDownDiv.id = "in-dropdown-container-gl";
                    dropDownDiv.setAttribute("uk-dropdown", 'mode: click; pos:top-right; offset: 5');
                    
                    var optionChildDiv = document.createElement("ul");
                    parentDiv.appendChild(addBtn);
                    var spanDiv1 = document.createElement("li");
                    spanDiv1.classList.add("uk-button-icon")
                    spanDiv1.textContent = SCENARIO_TYPE.TITLES.NORMAL;
                    spanDiv1.onclick = () => {
                        obj.addSubtype(groupName, data, cell, SCENARIO_TYPE.NORMAL);
                    };
                    var spanDiv2 = document.createElement("li");
                    spanDiv2.classList.add("uk-button-icon")
                    spanDiv2.textContent = SCENARIO_TYPE.TITLES.BUDGETING;
                    spanDiv2.onclick = () => {
                        obj.addSubtype(groupName, data, cell, SCENARIO_TYPE.BUDGETING);
                    };
                    optionChildDiv.append(spanDiv1);
                    optionChildDiv.append(spanDiv2);
                    dropDownDiv.append(optionChildDiv);
                    parentDiv.append(dropDownDiv);
                }
                addBtn.style.visibility = 'hidden';
                var span = document.createElement("span");
                span.innerHTML = groupName;
                parentDiv.classList.add("uk-width-full", "tabulator-row-content");
                parentDiv.style.display = "inline-block";
                
                var div = document.createElement("div");
                div.appendChild(addBtn);
                div.appendChild(span);
                div.classList.add("align-self-center");
                
                var privateCheckboxDiv = document.createElement("div");
                var p = document.createElement("p");

                privateCheckboxDiv.classList.add("d-inline-flex", "uk-text-small", "uk-margin-medium-right");
                p.innerHTML = "Subcategory is Private";
                p.classList.add("uk-margin-remove", "font-weight-normal");

                var privateCheckbox = createCheckbox();
                privateCheckbox.id = 'privateSubcat';
                privateCheckbox.name = 'chosenEntity';
                privateCheckbox.classList.add('chosenEntity');
                if (cell._group && cell._group.rows[0] && cell._group.rows[0].data && cell._group.rows[0].data[FILE_CENSUS_FIELDS.PRIVATE] === true) {
                    privateCheckbox.checked = cell._group.rows[0].data[FILE_CENSUS_FIELDS.PRIVATE];
                } else {
                    privateCheckbox.checked = false;
                } 
                privateCheckbox.onchange = function (e) {  
                    if (e.target.checked) {
                        obj.makeSubCategoryPrivate(groupName, data, cell, true);
                    } else {
                        obj.makeSubCategoryPrivate(groupName, data, cell, false);
                    }
                };
                var infoIcon = document.createElement("i");
                infoIcon.classList.add("fal", "fa-info-circle", "uk-margin-small-right-left", "align-self-center");
                infoIcon.setAttribute("uk-tooltip", "Making the subcategory private");
                if (cell._group.level !== 0) {
                    privateCheckboxDiv.appendChild(privateCheckbox);
                    privateCheckboxDiv.appendChild(p);
                    privateCheckboxDiv.appendChild(infoIcon);
                }

                parentDiv.appendChild(div);
                parentDiv.appendChild(privateCheckboxDiv);

                return parentDiv;
            },
            dataEdited:function(data){
                if(obj.isFromDialog){
                    obj.isFromDialog = false;
                } else {
                    obj.setChangesNotSaved(true);
                }
            },
            rowMouseOver:function(e,row){
                let index = row.getData()[_index];
                let section =  row.getData()[FILE_CENSUS_FIELDS.SECTION_FIELD]
                if(section !== 'Transaction Data'){
                    $('#box_' + index).css("visibility", "visible");
                }

                if(section !== "P & L" && section !== "Crosswalk"){
                    $("#actions_" + index).css("visibility", "visible");
                    $("#actions_" + index).on("click", () => {obj.saveClickPosition(index)});
                }
            },
            rowMouseOut:function(e,row){
                let index = row.getData()[_index];
                if(!document.getElementById("box_"+ index).checked){
                    $('#box_' + index).css("visibility", "hidden");
                }

                if(obj.state.savedRowNumber !== index){
                    $('#actions_' + index).css("visibility", "hidden");
                }

            },
            rowUpdated:function(row) {
                if(row.getData()[FILE_CENSUS_FIELDS.INSERT_FLAG_FIELD] && row.getData()[FILE_CENSUS_FIELDS.INSERT_FLAG_FIELD] === "0"){
                    let rowIndex  =  row.getData()[_index];
                    let originalData = copyObjectValues(obj.props.originalFileCensusData);
                    let originalRow = originalData.filter(e=>e[_index] === rowIndex)[0];
                    row.getData()[FILE_CENSUS_FIELDS.UPDATE_FLAG_FIELD] = "0";
                    if(JSON.stringify(row.getData()) === JSON.stringify(originalRow)){
                        row.getData()[FILE_CENSUS_FIELDS.UPDATE_FLAG_FIELD] = "0";
                    } else {
                        row.getData()[FILE_CENSUS_FIELDS.UPDATE_FLAG_FIELD] = "1";
                    }
                }
            },
            tooltips:function(column){
				if([FILE_CENSUS_FIELDS.PRIVATE].includes(column._cell.column.field)) {
					return ""
				} else {
					return column._cell.value;
				} 
			},
            scrollVertical:function(top){
                globaltop = top;
            },
            renderComplete: this.onTabulatorRenderComplete
        }
        
        this.tabulator = new Tabulator(this.refs.dataSourcesTable, tabulatorOptions);
        this.isAccessible = !!this.props.userAllowedMenuLinks ?
        getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.TEMPORARY_DATA_MODELING) : false;

        var tableColumns = copyObjectValues(columns);
        tableColumns.unshift(checkboxCellObject);		//add checkbox column as the first column
        tableColumns = this.getTabulatorColumns(tableColumns);	//set column formatters and return column objects
        if(!this.isAccessible) {
            for (let col in tableColumns) {
                col = tableColumns[col];
                if([FILE_CENSUS_FIELDS.HEADER_ROWS_TO_SKIP].includes(col.field)) {
                    col.visible = false;
                }
            }
        }
        this.tabulator.setColumns(tableColumns);		//set the columns as the actual columns of the table
        this.toggleTable(true);
        $("#saveSettingsFC").addClass('disabled');
        $("#deleteSettingsFC").click(function(e){
            if($(e.currentTarget).hasClass("disabled")) return; //do not proceed if the svg is disabled
            obj.props.deletesubsection(obj.state.cell);
        });

        // don't add listener to the not rendered rows (not rendered row don't have parent element)
        obj.tabulator.getGroups().forEach(group =>{
            obj.addListenersOnHover(group._group.key.replaceAll(" ","_").replaceAll("&","_"));
        });
    
        this.getAllFileTypeColumns();
        document.addEventListener('mousedown', this.hidePeriods);
        $(document).on("click", function(evt) {
            if(evt.target.id !== $("#actions_" + obj.state.savedRowNumber)) {
                $("#actions_"+obj.state.savedRowNumber).css("visibility", "hidden"); // when clicking outside the three dots, hide them
                if(evt.target.children[0] && evt.target.children[0].tagName !== 'I') obj.state.savedRowNumber = -1;
            }
            if(evt.target.id !== "addBtn_General_Ledger") {
                $("#in-dropdown-container-gl").css("display", "none"); // when clicking outside the gl dropdown dots, hide them
            }
        });
    }

    /**
     *  save the position of the row we clicked on
     * @param {*} cell
     */
     saveClickPosition=(index)=>{
        let _this = this;
        if(_this.state.savedRowNumber !== index ){// hide the previous three dots icon -if exists-
            $("#actions_"+_this.state.savedRowNumber).css("visibility", "hidden");
            this.state.savedRowNumber = index;
        }

    }

    componentDidUpdate(prevProps) {
        $('#dataSourcesTable .tabulator-tableHolder').animate({
            scrollTop:  globaltop
        },100)
        if(this.props.data && this.props.periods.length > 1){
            let data = this.props.data;
            for(let e in data){
                if(data[e][FILE_CENSUS_FIELDS.INSERT_FLAG_FIELD] === "1" && !data[e][FILE_CENSUS_FIELDS.START_PERIOD_FIELD])  {
                    let periods = copyObjectValues(this.props.periods);
                    let activePeriods = periods.filter(e=>e.is_active === FISCAL_YEAR.PERIOD_STATUS_VALUES.ACTIVE || e.value === PERIOD_ONWARD);
                    let startPeriod = activePeriods[activePeriods.length - 1].label;
                    let endPeriod =  activePeriods[0].label;
                    data[e][FILE_CENSUS_FIELDS.START_PERIOD_FIELD] = startPeriod;
                    data[e][FILE_CENSUS_FIELDS.END_PERIOD_FIELD] = endPeriod;
                    this.setChangesNotSaved(true);
                }
            }
            if(JSON.stringify(data) !== JSON.stringify(this.tabulator.getData())){
                this.tabulator.setData(data); //set the data
            }
        }
       if(this.props.data && prevProps.data && JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)){
            this.tabulator.setData(this.props.data); //set the data
       }
       if(this.tabulator){
            this.tabulator.getGroups().forEach(group =>{
                obj.addListenersOnHover(group._group.key.replaceAll(" ","_").replaceAll("&","_"));
            });
        }
    }

    /**
     * When clicking on save
     * @param {*} switchAfter 
     */
    save=(switchAfter, switchCallback)=>{
        let dataValidated =  obj.validateFileCensusSections();
        if(!dataValidated){
            return;
        } else {
            this.props.setData(this.tabulator.getData(), switchAfter, switchCallback);
        }
    }
    
    /**
     * if this.state.changesNotSaved is not like the parameter sent, change it
     * @param {*} param 
     */
    setChangesNotSaved=(param)=>{
        let obj = this;
        if(obj.state.changesNotSaved !== param){
            obj.setState({
                changesNotSaved: param
            });
            if (param){
                $("#saveSettingsFC").removeClass('disabled');
                $('#reset-btn').removeClass('hide');
            } else {
                $("#saveSettingsFC").addClass('disabled');
                $('#reset-btn').addClass('hide');
            }
        }
    }

    validateConfigureColumns=()=> {
        var obj = this;
        if (obj.state.section === CURRENCY || obj.state.section === P_AND_L || obj.state.section === CROSSWALK) {
            return true;
        }
        var tableData = obj.tableReferences[DEFINE_DATA].current.tabulator.getData();
        for (var e in tableData) {
            if ($("#chosenEntity-"+tableData[e][DEFINE_FIELDS.FIELD_NAME_FIELD]).prop('checked') && (!tableData[e][DEFINE_FIELDS.CURRENCY_SUBTYPE_ID] || !tableData[e][DEFINE_FIELDS.CURRENCY_FIELD_NAME])) {
                obj.state.warning = MESSAGES.UPLOAD.missing_configuration;
                return false;
            }else if ($("#chosenEntity-"+tableData[e][DEFINE_FIELDS.FIELD_NAME_FIELD]).prop('checked') && tableData[e][DEFINE_FIELDS.STATIC] === 'false'  && tableData.filter(elt=>elt[RAW_ITEMS.FIELD_NAME].toLowerCase() === tableData[e][DEFINE_FIELDS.CURRENCY_FIELD_NAME].toLowerCase())[0][RAW_ITEMS.FIELD_DATA_TYPE] !== DATATYPE_ENUM.STRING.toUpperCase()) {
                obj.state.warning = MESSAGES.UPLOAD.changed_type.replaceAll('{x}',obj.state.subsection).replaceAll('{y}',tableData[e][RAW_ITEMS.FIELD_DATA_TYPE]);
                return false;
            } else if ($("#chosenEntity-"+tableData[e][DEFINE_FIELDS.FIELD_NAME_FIELD]).prop('checked') && tableData[e][DEFINE_FIELDS.STATIC] === 'false' && tableData[e][DEFINE_FIELDS.CURRENCY_DATE]  && tableData.filter(elt=>elt[RAW_ITEMS.FIELD_NAME] === tableData[e][DEFINE_FIELDS.CURRENCY_DATE])[0][RAW_ITEMS.FIELD_DATA_TYPE] !== DATATYPE_ENUM.DATETIME.toUpperCase()) {
                obj.state.warning = MESSAGES.UPLOAD.changed_type_datetime;
                return false;
            }
        }
        return true;
    }

    validateCurrencyPeriodDate=()=> {
        let _this = this;
        if (_this.state.section !== CURRENCY) {
            return true;
        }
        var tableData = _this.tableReferences[DEFINE_DATA].current.tabulator.getData();
        var dateFound = tableData.filter(e=>e[DEFINE_FIELDS.DATA_FIELD] === CURRENCY_FIELDS.DATE);
        var periodFound = tableData.filter(e=>e[DEFINE_FIELDS.DATA_FIELD] === CURRENCY_FIELDS.PERIOD);
        if ((!dateFound && !periodFound)) {
            obj.state.warning = MESSAGES.UPLOAD.define_period_or_date;
            return false;
        }else  if (dateFound && periodFound && dateFound.length> 0 && periodFound.length > 0) {
            obj.state.warning = MESSAGES.UPLOAD.define_period_or_date;
            return false;
        } else if (dateFound && periodFound && dateFound.length === 0 && periodFound.length === 0){
            obj.state.warning = MESSAGES.UPLOAD.define_period_or_date;
            return false;
        }else  {
            return true;
        }
    }

    validateMandatorySectionFields=()=> {
        let _this = this;
        if(!mandatorySectionFields_selectedVar) {
            return false;
        }
        var validated = true;
        var requiredFields = mandatorySectionFields_uneditableVar.filter(el => el.required === "t" || el.required === true);
        for (var section in requiredFields) {
            section = requiredFields[section];
            if(mandatorySectionFields_selectedVar.indexOf(section.name) === -1) {
                validated = false;
                break;
            }
        }
        if ((_this.state.transactionKey === "" || _this.state.transactionKey === undefined) && _this.state.section === MASTER_DATA) {
            validated = false;
        }
        if ((_this.state.transactionKey === "" || _this.state.transactionKey === undefined ) && _this.state.section === ANCILLARY_FILES
        && (_this.state.transactionFile !== "" && _this.state.transactionFile !== undefined && _this.state.transactionFile !== "empty-line"  )) {
            validated = false;
        }
        var tableData = this.tableReferences[DEFINE_DATA].current.tabulator.getData();
        for (var e in tableData) {
            if (tableData[e][DEFINE_FIELDS.TRUE_INPUT_FIELD] === "" && tableData[e][DEFINE_FIELDS.DATA_TYPE_FIELD] === Formats.Boolean) {
                _this.state.warning = ALERT_BOOLEAN_MESSAGE;
                validated = 0;
            }
        }
        return validated;
    }

    validateSubtypeDefinition=()=>{
        let _this = this;
        var mandatoryFieldsValidated = _this.validateMandatorySectionFields();
        var periodDateValidated = _this.validateCurrencyPeriodDate()
        var configureValidated = _this.validateConfigureColumns();
        let valid = true;
        let tableData = _this.tableReferences["define_data"].current.tabulator.getData()
        if(mandatoryFieldsValidated === 0){
            valid = false;
        }
        if(!mandatoryFieldsValidated) {
            obj.state.warning = ALERT_FILL_MESSAGE;
            valid = false;
        }
        if (!periodDateValidated) {
            valid = false;
        }
        if (!configureValidated) {
            valid = false;
        }
        if (!valid) {
            obj.setState({columnDefinitionWarningDialogOpen: true});
        }else{
            obj.setState({
                warning: ""
            },function(){
                obj.saveSubtypeDefinition(tableData);
            })
        }
    }

    getTabulatorColumns(columns) {
        let obj = this;
        columns = copyObjectValues(columns) || [];
        columns.forEach(col => {
            col.formatter = this.getColumnFormatter(col.title);
            if (col.field === FILE_CENSUS_FIELDS.HEADER_ROWS_TO_SKIP) {
                col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell,lang.modeling_settings.info_icons.header_rows_to_skip );
            }
            if (col.field === FILE_CENSUS_FIELDS.DELIMITER) {
                col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell,lang.modeling_settings.info_icons.delimiter);
            }
            if (col.field === FILE_CENSUS_FIELDS.NAMING_TEMPLATE) {
                col.titleFormatter = (cell) => obj.addTooltipTitleFormatter(cell,"TBD");
            }
            if(col.field === FILE_CENSUS_FIELDS.ACTIONS) {
                col.formatter = function(cell) {
                    var rowData = cell.getRow().getData();
                    var rowNumber = rowData[_index]
                    let dotsButtonContainer = document.createElement("div");
                    let dotsButton = getTableIcon(["fa-2x", "fal", "fa-ellipsis-v", "uk-button-icon", "dots-button", "actions-dots"]);
                    dotsButton.id = "actions_"+rowNumber;
                    dotsButton.style.visibility = "hidden";
                    dotsButtonContainer.classList.add("uk-inline");

                    let dropdownContainer = document.createElement("div");
                    dropdownContainer.id = "dropDown_"+rowNumber;
                    dropdownContainer.setAttribute("uk-dropdown", "mode: click; boundary: .tabulator");
                    dropdownContainer.style.width = "max-content"

                    let cellSubsection = cell.getData()['subsection'].toLowerCase();
                    if(((cellSubsection === 'cost center state' || cellSubsection ==='cost center') && cell.getData()['file_type']==='master_data' ) || (rowData[FILE_CENSUS_FIELDS.INSERT_FLAG_FIELD] != 0 && rowData[FILE_CENSUS_FIELDS.SECTION_FIELD] !== "P & L" && rowData[FILE_CENSUS_FIELDS.SECTION_FIELD] !== "Crosswalk")){
                        dotsButton.classList.add("disabled");
                        dotsButtonContainer.classList.add("disabled-with-events");
                        if(rowData[FILE_CENSUS_FIELDS.INSERT_FLAG_FIELD] != 0 && rowData[FILE_CENSUS_FIELDS.SECTION_FIELD] !== "P & L" && rowData[FILE_CENSUS_FIELDS.SECTION_FIELD] !== "Crosswalk"){
                            dotsButtonContainer.setAttribute("uk-tooltip", lang.modeling_settings.data_source.tooltips.define_columns_tooltip);
                        }
                    }

                    // Set headers column button
                    var setHeadersDiv = getTableButton(
                        lang.modeling_settings.data_source.titles.set_column_headers_btn,
                        [],
                        ["justify-content-start", "manage_stacks_dropdown_button"],
                        ["uk-text-xlarge", "fas", "fa-columns", "uk-margin-small-right", "uk-margin-small-left"],
                        "left",
                        lang.modeling_settings.data_source.titles.set_column_headers_btn
                    );
                    setHeadersDiv.style.width = "100%";

                    if(rowData[FILE_CENSUS_FIELDS.HAS_DEFINED_FILES]) {
                        setHeadersDiv.classList.add("disabled-with-events");
                        setHeadersDiv.setAttribute("uk-tooltip", lang.modeling_settings.data_source.tooltips.has_defined_files_tooltip);
                    } else {
                        setHeadersDiv.onclick = () => {
                            let tempState = {};
                            tempState.rowData = rowData; 
                            tempState.savedColumnsHeaders = rowData[FILE_CENSUS_FIELDS.COLUMN_HEADER_NAMES];
                            tempState.subsection = rowData[FILE_CENSUS_FIELDS.SUB_SECTION_FIELD];
                            tempState.subTypeId = rowData[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID];
                            tempState.hasDefinedColumns = rowData[FILE_CENSUS_FIELDS.HAS_DEFINED_COLUMNS]; 
                            tempState.hasDefinedFiles = rowData[FILE_CENSUS_FIELDS.HAS_DEFINED_FILES];

                            obj.setState(tempState,()=>{
                                obj.checkIfFileIsUploadingOrRedefining(rowData[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID], true)
                            })
                            // obj.setColumnHeadersDialogOpen(true);

                        }
                    }

                    // Define columns button
                    var defineColumnsDiv = getTableButton(
                        lang.modeling_settings.data_source.titles.define_columns_btn,
                        [],
                        ["justify-content-start", "manage_stacks_dropdown_button"],
                        ["uk-text-xlarge", "fad", "fa-line-columns", "uk-margin-small-right", "uk-margin-small-left"],
                        "left",
                         lang.modeling_settings.data_source.titles.define_columns_btn
                    );
                    defineColumnsDiv.style.width = "100%";
                    
                    if(!rowData[FILE_CENSUS_FIELDS.HAS_HEADER_COLUMNS]) {
                        defineColumnsDiv.classList.add("disabled-with-events");
                        defineColumnsDiv.setAttribute("uk-tooltip", lang.modeling_settings.data_source.tooltips.has_header_columns_tooltip);
                    }else{
                        defineColumnsDiv.onclick = () => {
                            defineColumnsDiv.classList.remove("disabled-with-events");
                            let tempState = {};
                            tempState.rowData = rowData; 
                            tempState.savedColumnsHeaders = rowData[FILE_CENSUS_FIELDS.COLUMN_HEADER_NAMES];
                            tempState.subsection = rowData[FILE_CENSUS_FIELDS.SUB_SECTION_FIELD];
                            tempState.section = rowData[FILE_CENSUS_FIELDS.SECTION_FIELD].replaceAll(" ", "_").toLowerCase();
                            tempState.subTypeId = rowData[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID];
                            tempState.hasDefinedColumns = rowData[FILE_CENSUS_FIELDS.HAS_DEFINED_COLUMNS]; 
                            tempState.hasDefinedFiles = rowData[FILE_CENSUS_FIELDS.HAS_DEFINED_FILES];

                            obj.setState(tempState,()=>{
                                obj.checkIfFileIsUploadingOrRedefining(rowData[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID], false)
                            })
                        }
                    }
                    let isAutomated = cell.getData()[FILE_CENSUS_FIELDS.IS_AUTOMATED];
                    if(obj.props.isAutomationAllowed && isAutomated){
                        // run automation button
                        var runAutomationDiv =  getTableButton(  
                            lang.modeling_settings.data_source.titles.run_automation,
                            [],
                            ["justify-content-start", "manage_stacks_dropdown_button"],
                            ["uk-text-xlarge", "fad", "fa-cogs", "uk-margin-small-right", "uk-margin-small-left"],
                            "left",
                            lang.modeling_settings.data_source.titles.run_automation
                        );
                        runAutomationDiv.style.width = "100%";
                       
                        runAutomationDiv.onclick = () => {
                            obj.setState({
                                rowData: rowData
                            },()=>{
                                obj.setRunAutomationDialogOpen(true);
                            })
                        }
                    }

                    dropdownContainer.appendChild(setHeadersDiv);
                    dropdownContainer.appendChild(defineColumnsDiv);
                    if(obj.props.isAutomationAllowed && isAutomated){
                        dropdownContainer.appendChild(runAutomationDiv);
                    }

                    dotsButtonContainer.appendChild(dotsButton);
                    dotsButtonContainer.appendChild(dropdownContainer);

                    return dotsButtonContainer;
                };
            }

        });
        return columns;
    }

    getStartEndDates(period) {
        var periods = obj.props.periods;
        var dates = [];
        for (var e in periods) {
            if (periods[e].value === period) {
                if (period === onwardPeriod){
                    dates.push(onwardPeriod);
                    dates.push(onwardPeriod); 
                }
                dates.push(periods[e]["start_date"]);
                dates.push(periods[e]["end_date"]);
            }
        }
        return dates;
    }

    checkPeriodExists(period) {
        var periods = obj.props.periods;
        var found = false;
        for (var e in periods) {
            if( periods[e].value === period) {
                found = true;
            }
        }
        return found;
    }


    validatePeriods(e,cell){
        var startPShouldBeLessThanEndPMsg = "Make sure that the “From” period occurs before the “To” period.";
        var field = cell.getField();
        var newPeriod = $(e.currentTarget).val();
        var Dates = this.getStartEndDates(newPeriod);
        var startDate = (Math.round(new Date(Dates[0]).getTime()/1000));
        var endDate = (Math.round(new Date(Dates[1]).getTime()/1000));
        let oldStartPeriod = cell.getRow().getData()[FILE_CENSUS_FIELDS.START_PERIOD_FIELD];
        let oldEndPeriod = cell.getRow().getData()[FILE_CENSUS_FIELDS.END_PERIOD_FIELD];

        if(field === FILE_CENSUS_FIELDS.START_PERIOD_FIELD){
            if(oldEndPeriod !== onwardPeriod){
                var date = this.getStartEndDates(oldEndPeriod)[1];
                var endPeriodEndtDate = (Math.round(new Date(date).getTime()/1000));
                if(startDate >= endPeriodEndtDate){
                    cell.setValue(oldStartPeriod);
                    return startPShouldBeLessThanEndPMsg;
                }
            }
        } else {
            if(newPeriod !== onwardPeriod){
                var date = this.getStartEndDates(oldStartPeriod)[0];
                var startPeriodEndtDate = (Math.round(new Date(date).getTime()/1000));
                if(endDate <= startPeriodEndtDate){
                    cell.setValue(oldEndPeriod);
                    return startPShouldBeLessThanEndPMsg;
                }
            }
        }

        return "";
      
    }

    checkExistingsubsection(cell, section, subsection) {
        var data = obj.props.data;
        var count = 0;
        cell.getRow().getData()[FILE_CENSUS_FIELDS.SUB_SECTION_FIELD] = "";
        this.tabulator.replaceData(obj.props.data);
        for (var e in data) {
            if (data[e].section === section) {
                if(data[e].subsection.toLowerCase().replace(/ /g,'') === subsection.toLowerCase().replace(/ /g,'')) {
                        count++;
                }
            }
        }
        if(count > 0) {
            return false;
        }
        return true;
    }

    setSelectValue(e,cell,isDate){
        let tempState = {};
            if(isDate){
                let message = this.validatePeriods(e,cell);
                if(message !== ""){
                    tempState.validation = message;
                    this.setValidationDialogOpen(true)
                    this.setState(tempState)
                    return;
                }
            }
            this.setChangesNotSaved(true)
            cell.setValue(e.target.value);
            let rowIndex = cell.getRow().getData()[_index];
            this.tabulator.updateRow(rowIndex, cell.getRow().getData());
    }

    setCellValue(e, cell, subsectionFlag) {
        let _this = this;
        let tempState = {};
        if(subsectionFlag && subsectionFlag === 1) {
            if ($(e.currentTarget)!== "" && !this.isValidsubsection($(e.currentTarget).val())) {
                cell.getRow().getData()[FILE_CENSUS_FIELDS.SUB_SECTION_FIELD] = $(e.currentTarget).val();
                tempState.validation = lang.modeling_settings.validations.data_source.alpha_numeric;
                this.setValidationDialogOpen(true)
            } else if (this.checkExistingsubsection(cell, cell.getRow().getData()[FILE_CENSUS_FIELDS.SECTION_FIELD], $(e.currentTarget).val())) {
                cell.getRow().update({[cell.getColumn().getDefinition().field]:$(e.currentTarget).val()});
            } else if ($(e.currentTarget).val() !== "") { 
                cell.getRow().getData()[FILE_CENSUS_FIELDS.SUB_SECTION_FIELD] = $(e.currentTarget).val();
                tempState.validation = lang.modeling_settings.validations.data_source.unique;
                this.setValidationDialogOpen(true)
               
            }
        } else {
            cell.setValue(Number($(e.currentTarget).val()));
        }
        let rowIndex = cell.getRow().getData()[_index];
        this.tabulator.updateRow(rowIndex, cell.getRow().getData()).then(()=>{
            _this.setState(tempState);
        });
    }

    setInputValue = (columnField, event, cell) => {
        let tempState = {};
        let obj = this;
        let value = $(event.currentTarget).val().toLowerCase();
        let subtypeId = cell.getRow().getData()[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID];
        let fileType = cell.getRow().getData()[FILE_CENSUS_TITLES.RAW_FILE_TYPE_TITLE];
        if(columnField === FILE_CENSUS_FIELDS.NAMING_TEMPLATE) {
            if(value === ""){
                tempState.validation = lang.modeling_settings.validations.fileNamingTemplate.empty;
                this.setValidationDialogOpen(true)

            } else if (!this.isNamingTemplateUnique(subtypeId, value)){
                tempState.validation = lang.modeling_settings.validations.fileNamingTemplate.unique;
                this.setValidationDialogOpen(true)

            }
        }
        cell.setValue(value);
        let rowIndex = cell.getRow().getData()[_index];
        this.tabulator.updateRow(rowIndex, cell.getRow().getData()).then(function(){
            obj.setState(tempState);
        });
        
    }
    
    isNamingTemplateUnique = (subtypeId, template) => {
        return this.props.data.filter(row=> row[FILE_CENSUS_FIELDS.NAMING_TEMPLATE] === template).length <= 1;
    }
    
    isValidsubsection(subsection) {
        var pattern = /^[0-9a-zA-Z ]+$/;
        return subsection.match(pattern) !== null && subsection!=="";
    }

    onCheckBoxChecked(cell) {
        let section = cell.getTable().element.getAttribute("id").replace("&", "\\&");
        var sectionName = $("#" + section);
        let hasChecked = sectionName.find('input[type=checkbox]:checked').length > 0;
        obj.state.cell = []
        if(hasChecked) {
            obj.state.cell.push(cell);
           $(".file-census-fieldset").find("div[title='Delete']").removeClass("disabled");
        }else{
            $(".file-census-fieldset").find("div[title='Delete']").addClass("disabled");
        }
    }

    getColumnFormatter(colTitle) {
        var columnFormatter;
        var obj = this;
        switch(colTitle) {
            case CHECK_TITLE:
                columnFormatter = function(cell, formatterParams) {
                    let index = cell.getRow().getData()[_index]
                    var checkbox = createCheckbox();
                    checkbox.name='chosenEntity';
                    checkbox.id = 'box_'+index;
                    checkbox.classList.add('chosenEntity');
                    let cellSubsection = cell.getData()['subsection'].toLowerCase();
                    if((cellSubsection === 'cost center state' || cellSubsection ==='cost center') && cell.getData()['file_type']==='master_data' ){
                        checkbox.classList.add('uk-disabled', 'disabled');
                    }
                    checkbox.style.visibility = "hidden";
                    checkbox.style = "margin-top:"+ convertPxToViewport(7) +" !important";
                    let option = document.createElement("option");
                    option["raw_file_subtype_id"] = cell.getRow().getData()[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID];
                    option["start_period"] = cell.getRow().getData()[FILE_CENSUS_FIELDS.START_PERIOD_FIELD];
                    option["end_period"] = cell.getRow().getData()[FILE_CENSUS_FIELDS.END_PERIOD_FIELD];
                    checkbox.appendChild(option);
                    checkbox.onchange = () => obj.onCheckBoxChecked(cell);
                    return checkbox;
                };
			break;
            case FILE_CENSUS_TITLES.SECTION_TITLE:
                columnFormatter = function(cell, formatterParams) {
                    return cell.getValue();
                };
            break;

            case FILE_CENSUS_TITLES.SUB_SECTION_TITLE:
                columnFormatter = function(cell) {
                    var input = createTabulatorInput(["form-control"]);
                    // input.classList.add("tabulator__input")
                    // input.classList.add("form-control");
                    let pMain = document.createElement("p");
                    let icon = document.createElement("i"); 
                    pMain.style.display = "flex";
                    pMain.style.alignItems = "center";
                    let cellSubsection = cell.getData()['subsection'].toLowerCase();
                    let isAutomated = cell.getData()[FILE_CENSUS_FIELDS.IS_AUTOMATED];
                    if(isAutomated) {
                        icon.classList.add("fas","fa-bolt");
                        icon.style.padding = "0.5vw";
                    }
                    if((cellSubsection === 'cost center state' || cellSubsection ==='cost center') && cell.getData()['file_type']==='master_data' ){
                        input.classList.add('uk-disabled', 'disabled');
                    }
                    input.style.width = convertPxToViewport(150);
                    input.placeholder = "Data Source Name";
                    input.onblur = (e) => obj.setCellValue(e, cell, 1);
                    if(cell.getRow().getData()[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID] != null && cell.getRow().getData()[FILE_CENSUS_FIELDS.RAW_FILE_SUBTYPE_ID] !== ""
                     && cell.getRow().getData()[FILE_CENSUS_FIELDS.FILE_COUNT] > 0) {
                        input.disabled = "disabled";   
                    }
                    input.value = cell.getValue();
                    pMain.appendChild(input);
                    pMain.appendChild(icon)
                    return pMain;
                };
            break;

            case FILE_CENSUS_TITLES.NAMING_TEMPLATE:
                 columnFormatter = function(cell) {
                    var div = document.createElement("div");
                    let cellSubsection = cell.getData()['subsection'].toLowerCase();
                    if((cellSubsection === 'cost center state' || cellSubsection ==='cost center') && cell.getData()['file_type']==='master_data' ){
                        div.classList.add('uk-disabled', 'disabled');
                    }
                    div.classList.add("inputwrapper")
                    div.setAttribute("data-required","_*_Period");
                    var input = createTabulatorInput(["form-control"]);
                    // input.classList.add("tabulator__input")
                    input.onblur = (e) => obj.setInputValue(cell.getColumn().getField(), e, cell);
                    input.onchange = (e) => obj.setChangesNotSaved(true);
                    input.style.width = "66%";
                    input.value = cell.getValue() || "";
                    if(input.value!==""){
                        div.setAttribute("data-required","");
                    }
                    input.placeholder = "Filename";
                    // input.classList.add("form-control");
                    if(colTitle === FILE_CENSUS_TITLES.NAMING_TEMPLATE) {
                        input.title = "Your uploaded file name must match the text inputted here, and it must contain as a suffix, either _[period] for one period, or _[start_period]_[end_period] for multiple periods.";
                    }
                    div.appendChild(input)
                    return div;
                };
            break;

            case FILE_CENSUS_TITLES.DELIMITER:
                columnFormatter = function(cell) {
                    var rowData = cell.getRow().getData();
                    var selectList = createDropdown(["form-control-d"]);
                    if(rowData.section === 'P & L'){
                        selectList.style.visibility = 'hidden';
                    }
                    selectList.id = "delimiterSelect";
                    for (var i = 0; i < delimiterOptions.length; i++) {
                        selectList.style.width = convertPxToViewport(104);
                        // selectList.classList.add("form-control-d");
                        let cellSubsection = cell.getData()['subsection'].toLowerCase();
                    if((cellSubsection === 'cost center state' || cellSubsection ==='cost center') && cell.getData()['file_type']==='master_data' ){
                            selectList.classList.add('uk-disabled', 'disabled');
                        }
                        var option = document.createElement("option");
                        option.value = delimiterOptions[i].value;
                        option.text = delimiterOptions[i].label;
                      
                        selectList.appendChild(option);
                    }
                    selectList.onchange = (e) => obj.setSelectValue(e,cell);
                    selectList.value = cell.getValue() === undefined ? delimiterOptions[0].value: cell.getValue();
                    return selectList;
                  
                };
            break;

            case FILE_CENSUS_TITLES.EXPECTED_FILES_TITLE:
                columnFormatter = function(cell, formatterParams) {
                    var rowData = cell.getRow().getData();
                    var input = createTabulatorInput(["form-control"]);
                    // input.classList.add("tabulator__input")
                    input.style.width = "66%";
                    // input.classList.add("form-control");
                    input.onblur = (e) => obj.setCellValue(e, cell);

                    if(cell.getValue() === "") {
                        cell.setValue("1");    //expected files should be defaulted to 1
                    }
                    input.value = cell.getValue();
                    input.type="number";
                    input.onkeydown= (e) => { return false };
                    input.min = 1;
                    if(rowData.section === 'P & L'){
                        input.value = "1";
                        input.disabled = true;
                        input.title = "You can only upload 1 file under 1 subcategory for P&L section.";
                    }
                    
                    return input;
                };
            break;

            case FILE_CENSUS_TITLES.HEADER_ROWS_TO_SKIP:
                columnFormatter = function(cell, formatterParams) {
                    var rowData = cell.getRow().getData();
                    var input = createTabulatorInput(["form-control-d"]);
                    // input.classList.add("tabulator__input")
                    input.style.width = convertPxToViewport(60);
                    // input.classList.add("form-control-d");
                    let cellSubsection = cell.getData()['subsection'].toLowerCase();
                    if((cellSubsection === 'cost center state' || cellSubsection ==='cost center') && cell.getData()['file_type']==='master_data' ){
                        input.classList.add('uk-disabled', 'disabled');
                    }
                    input.onblur = (e) => obj.setCellValue(e, cell);
                    input.onchange = (e) => obj.setChangesNotSaved(true)
                    if(rowData.section === 'P & L'){
                        input.style.visibility = 'hidden';
                    }
                  
                    input.value = Number(cell.getValue());
                    input.type="number";
                    input.onkeydown= (e) => { return false };
                    input.min = 0;
                    return input;
                };
            break;

            case FILE_CENSUS_TITLES.START_PERIOD_TITLE:
                columnFormatter = function(cell, formatterParams) {
                    let periods = copyObjectValues(obj.props.periods);
                    const select_elem = createDropdown(["form-control-d"]);
                    select_elem.id = "startPeriodSelect_"+cell.getRow().getData()[_index];
                    // select_elem.classList.add("form-control-d");
                    let cellSubsection = cell.getData()['subsection'].toLowerCase();
                    if((cellSubsection === 'cost center state' || cellSubsection ==='cost center') && cell.getData()['file_type']==='master_data' ){
                        select_elem.classList.add('uk-disabled', 'disabled');
                    }
                    periods.forEach(d=> {
                        if(d.label !== onwardPeriod){
                            select_elem.add(new Option(d.label,d.value))
                        }
                    });
                    select_elem.onchange = (e) => {obj.setSelectValue(e,cell,true)}
                    select_elem.value = cell.getValue() === undefined ? periods[periods.length -1].label : cell.getValue();
                    return select_elem;
                };
            break;

            case FILE_CENSUS_TITLES.END_PERIOD_TITLE:
                columnFormatter = function(cell, formatterParams) {
                    let periods = copyObjectValues(obj.props.periods);
                    const select_elem = createDropdown(["form-control-d"]);
                    select_elem.id = "endPeriodSelect_"+cell.getRow().getData()[_index];
                    // select_elem.classList.add("form-control-d");
                    let cellSubsection = cell.getData()['subsection'].toLowerCase();
                    if((cellSubsection === 'cost center state' || cellSubsection ==='cost center') && cell.getData()['file_type']==='master_data' ){
                        select_elem.classList.add('uk-disabled', 'disabled');
                    }
                    periods.forEach(d=>  select_elem.add(new Option(d.label,d.value)));
                    select_elem.onchange = (e) => {obj.setSelectValue(e,cell,true)}
                    select_elem.value = cell.getValue() === undefined ? periods[0].label : cell.getValue();
                    return select_elem;
                };
                break;
            case FILE_CENSUS_TITLES.RESTRICTION_ACCESS:
                columnFormatter = function(cell, formatterParams) {
                    let rowData = cell.getRow().getData();
                    let div = document.createElement("div");
                    let label = document.createElement("label");
                    label.classList.add("switch");
                    let cellSubsection = cell.getData()['subsection'].toLowerCase();
                    if((cellSubsection === 'cost center state' || cellSubsection ==='cost center') && cell.getData()['file_type']==='master_data' ){
                        label.classList.add('uk-disabled', 'disabled');
                    }
                    let input = document.createElement("input");
                    input.type = "checkbox";
                    ////////////SWITCh
                    input.id =  FILE_CENSUS_FIELDS.RESTRICTION_ACCESS + "_" +cell.getRow().getData()[_index];
                    input.name =  FILE_CENSUS_FIELDS.RESTRICTION_ACCESS;
                    input.checked = rowData[FILE_CENSUS_FIELDS.PRIVATE] === undefined ? false : rowData[FILE_CENSUS_FIELDS.PRIVATE];
                    input.value = input.checked ? 'off' : 'on';
                    input.onchange = (e) => {obj.toggleRestrictionAccess(e,cell)}
                    label.appendChild(input);
                    let span = document.createElement("span");
                    span.classList.add("slider","round");
                    label.appendChild(span)
                    div.appendChild(label);
                    div.style.marginTop = convertPxToViewport(9);
                    return div
                  };
                  break;
            default:
                columnFormatter = function(cell, formatterParams) {
                    return cell.getValue();
                };
            break;
        }
        return columnFormatter;  
    }
    toggleRestrictionAccess(e,cell){
        if(e.currentTarget.value === 'on'){
            cell.setValue(true); 
        } else {
            cell.setValue(false); 
        }
        this.setChangesNotSaved(true);
        let rowIndex = cell.getRow().getData()[_index];
        this.tabulator.updateRow(rowIndex, cell.getRow().getData());
    }

    addListenersOnHover=(rowNumber)=>{
        if( document.getElementById("addBtn_"+rowNumber)!== null){
            document.getElementById("addBtn_"+rowNumber).parentElement.parentElement.parentElement.addEventListener("mouseover", event => {
                    if(rowNumber !== 'Transaction_Data'){
                        $("#addBtn_"+rowNumber).css("visibility", "visible");
                    }
            });
            document.getElementById("addBtn_"+rowNumber).parentElement.parentElement.parentElement.addEventListener("mouseout", event => {
                    $("#addBtn_"+rowNumber).css("visibility", "hidden");

            });
        }
    }

    addTooltipTitleFormatter(cell, tooltipMessage){
        var div = document.createElement("div");

        var p = document.createElement("p");
        p.innerHTML = typeof cell === "string" ? cell : cell.getValue();

        var el = document.createElement("i");
        el.classList.add("fal", "fa-info-circle", "uk-margin-xsmall-left", "uk-cursor-pointer");
        el.setAttribute("uk-tooltip", tooltipMessage);
        // div.style = "display: flex !important"
        div.appendChild(p);
        div.appendChild(el);
        return div;
    }  

    /**
     * show warning on reset
     */
    showResetWarningPopup() {
        this.setResetDatasourceDialogOpen(true)
    }

    /**
     * reset all changes in the file census tabulator
     */
    resetFileCensus(){
        let _this = this;
        _this.setState({
            changesNotSaved: false,
        },function(){
            _this.props.resetFileCensus(true);
            _this.setResetDatasourceDialogOpen(false)
        })
    }

    handleMultiChange=(option) =>{
        this.setState(state => {
          return {
            multiValue: option,
            menuIsOpen:true,
            changesNotSaved: true
          };
        });
        this.setChangesNotSaved(true);
    }

    /**
     * Gets changes of 'set header columns' dialog
     * @param {*} e
     */
    onInputChange = (e) => {
        this.setState({dataSourceHeaders : e.target.value})
    }

    /**
     * dialog on cancel click function
     * @param {*} setOpen : function
     */
    onDialogCancel = (setOpen) => {
        setOpen(false);
    }

    /**
     * 'Set header columns' dialog on save click that saves columns headers
     * @param {*} fromSavingHeadersDialog : to know where the function is accessed from
     */
    onColumnHeadersDialogSave = (fromSavingHeadersDialog) => {
        if (!this.state.defineOpen) {
            if(this.state.hasDefinedColumns && fromSavingHeadersDialog) {
                this.state.warning = lang.modeling_settings.data_source.titles.column_definition_reset_warning;
                this.setColumnDefinitionWarningDialogOpen(true);
            } else {
                this.setColumnDefinitionWarningDialogOpen(false);
                this.validateDataSourceHeader();
            }
        }else{
            this.setState({
                columnDefinitionWarningDialogOpen:false
            })
        }
    }

    /**
     * Creates 'Set column headers' dialog body content
     * @returns a component
     */
    columnHeadersDialogBodyContent = () => {
        return (
            <>
              <div className='pi-warning-background uk-border-rounded uk-margin-bottom uk-padding-xsmall' >
                  <DialogContentText id="alert-dialog-description" fontSize={"0.63vw"} className="text ">
                      <i className="fal fa-exclamation-triangle uk-margin-default-right" />
                      {lang.modeling_settings.data_source.titles.space_replace_by_underscore_warning}
                  </DialogContentText>
              </div>
              <form autoComplete="off" id="dataSourceHeaderDialog">
                  <textarea className="uk-textarea input-default" defaultValue={this.state.savedColumnsHeaders} rows={5} placeholder={lang.modeling_settings.data_source.fields.column_header_placeholder} onChange={this.onInputChange}/>
                  <div className='uk-margin-small-top uk-margin-small-left dataSourceHeaderValidations red'></div>
              </form>
            </>
        )
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
      }
      
    hidePeriods(event) {
        let _this = this;
        if (_this.wrapperRef && !_this.wrapperRef.contains(event.target) && !(event.target.id === "select_periods")) {
            _this.setState({
                showPeriodsComp: false
            });
        }
    }

    /**
     * toggle the periods drop down when clicking on select period
     * @param {*} evt 
     */
        togglePeriodsComp = () => {
            let _this = this;
            let showPeriodsComp = !_this.state.showPeriodsComp;
            _this.setState({showPeriodsComp: showPeriodsComp});
        }

  /**
   * Creates 'Run Automation' dialog body content
   * @returns a component
   */
  runAutomationDialogBodyContent = () => {
    var _this = this;
    var element = document.getElementById("select_periods");
    var top = 0;
    var style = {};
    if (element) {
      var position = element.getBoundingClientRect();
      top = position.top - position.height;
      style = {
        position: 'absolute',
        top: top,
        left: '130px'
      }

    }
    var periodsForDropDown = (_this.props.periods || []).map(period => {
      period.value = period.label;
      return period;
    });
    periodsForDropDown = periodsForDropDown.filter(period => period.is_active === "ACTIVE");
    const isPeriodsSelected = !_this.state.periodsToBuild || (_this.state.periodsToBuild && _this.state.periodsToBuild.length === 0);

    return (
      <>
        <div className="margin-toggle info-hover ">
          <span className={"uk-display-block modal-dialog-subtitle"}>{lang.modeling_settings.data_source.statements.automation}</span>
          <span id="select_periods"
            className={"uk-text-decoration-underline uk-display-flex uk-flex-middle hide-columns-btn-force-assign" + (isPeriodsSelected ? " red" : "")}
            onClick={(evt) => { this.togglePeriodsComp() }}>{MESSAGES.selectPeriods}</span>

          {this.state.showPeriodsComp ?
            <div className="hide-columns-dropdown" id="periods_comp_configure_data" style={style} ref={this.setWrapperRef}>
              <PeriodsDropDown ref={el => this.periodRef = el} funkName={this.addPeriodsToBuild} periods={periodsForDropDown}
                placeHolderText={MESSAGES.find_period} showText={MESSAGES.select_all} hideText={MESSAGES.unselect_all}
                defaultStatus={true} is_default={""} message={MESSAGES.default_message}
                report={ALL_WIDGETS.FORCE_ASSIGN_COST} />
            </div>
            : ""
          }
        </div>
      </>
    )
  }

    /**
     * add and remove selected periods, by default all the periods are selected
     * @param {*} period 
     * @param {*} show 
     */
    addPeriodsToBuild=(periods, show)=>{
        let _this = this;
        let periodsToBuild = [...new Set(this.state.periodsToBuild )] || [];
        if(periods.length>1){// if select all or deselect all
            if(show){//select all
                periods.forEach(function(period){
                    periodsToBuild.push(period);
                });
            } else {//deselect all
                periodsToBuild=[];
            }
        } else {
            let period = periods[0];
            if(show && !periodsToBuild.includes(period)){
                periodsToBuild.push(period);
            } else if(periodsToBuild.includes(period)){
                periodsToBuild.splice(periodsToBuild.findIndex(el => el === period), 1);
            }
        }
        this.setState({
            periodsToBuild:periodsToBuild
        })
    }


    /**
     * Creates 'Define column header warning' dialog body content
     * @returns a component
     */
    columnDefinitionDialogWarningContent = () => {
        return (
            <DialogContent className='dialog-text margin-dialog-content uk-display-flex uk-flex-middle pi-warning-background uk-border-rounded uk-padding-xsmall margin-right-left'>
                <DialogContentText id="alert-dialog-description" fontSize={"0.83vw"} className="text">
                    <i className="fa-2x fal fa-exclamation-triangle uk-margin-default-right" />
                    {this.state.warning}
                </DialogContentText>
            </DialogContent>
        )
    }

    /**
     * Validations of data source headers
     */
    validateDataSourceHeader = () => {
        let _this = this;
        var query = {
            action: "validateDataSourceHeader",
            dataSourceHeaders: this.state.dataSourceHeaders,
            subTypeId: this.state.subTypeId,
            section:_this.state.section.replace(/ /g,'_').toLowerCase()
        }

        var onThenCallback = (data)=> {
            $(".dataSourceHeaderValidations").html("");
            if(data.headerValidations.length > 0) {
                data.headerValidations.forEach(validation => {
                    $(".dataSourceHeaderValidations").append("<span><i class='fal fa-times-circle uk-margin-xsmall-right'></i> " + validation+"</span><br>");
                });
            } else{
                let currRowData = _this.state.rowData;
                _this.setColumnHeadersDialogOpen(false);
                _this.launchToast(true);
                currRowData[FILE_CENSUS_FIELDS.COLUMN_HEADER_NAMES] = _this.state.dataSourceHeaders.replaceAll(" ", "_");
                currRowData[FILE_CENSUS_FIELDS.HAS_HEADER_COLUMNS] = true;
                currRowData[FILE_CENSUS_FIELDS.HAS_DEFINED_COLUMNS] = false;
                _this.isFromDialog = true;
                _this.setState({
                    rowData: currRowData
                },()=>{
                    let data = _this.tabulator.getData();
                    data.forEach((item,index)=> {
                        if(item[_index] === currRowData[_index]){
                           data[index] = currRowData;
                        }   
                    })
                    _this.tabulator.updateRow(currRowData[_index], currRowData);
                    _this.tabulator.replaceData(data);
                })
            }
        };
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "validateDataSourceHeader",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DataPipeline,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.modeling_settings.datasources.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.modeling_settings.datasources.requests_description.validate_datasource_header
        }
        this.fetchAPI(fetchOptions);
    }

    /**
     * filling validations response
     * @param {*} response
     */
    fillValidations=(response)=> {
        let _this = this;
        var uploadValidations = [];
        var uploadValidationsProgress = [];
        if(response.validation) {
            response.validation.map((item, index) => {
                if (response.status !== DATA_DICTIONARY_ENUM.not_defined && response.status !== DATA_DICTIONARY_ENUM.failed_upload &&
                    response.status !== DATA_DICTIONARY_ENUM.failed_decrypt && response.status !== DATA_DICTIONARY_ENUM.pending_decryption &&
                    response.status !== DATA_DICTIONARY_ENUM.decrypting && response.status !== DATA_DICTIONARY_ENUM.decrypted &&
                    !(item.validation_message === "" && item.validation_status === "fail")) {
                        uploadValidations.push(item);
                }
                uploadValidationsProgress.push(item);
            });
        }
        _this.setState({
            uploadValidations: uploadValidations
        });
    }

    /**
     * validates Files uploaded under subcategory and redefines them with new definition
     */
    redefineFiles() {
        const _this = this;
        const query = {
            action: "validateDefinedFileNewArch",
            section: _this.state.section.replace(/ /g,'_').toLowerCase(),
            subsection: _this.state.subsection.replace(/ /g,'_').toLowerCase(),
            tableTypeId: _this.state.definedTableType,
        }
        var onThenCallback = (data)=> {
            _this.setState({
                transactionKey: "",
                transactionFile: "",
                transactionFileId: ""
            },function(){
                _this.setDefineOpen(false);
                _this.getAllFileTypeColumns();
                _this.launchToast(true, true);
            })
        };
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "validateDefinedFileNewArch",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DataPipeline,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.modeling_settings.datasources.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.modeling_settings.datasources.requests_description.validate_defined_file
        }
        this.fetchAPI(fetchOptions);
    }

    /**
     * saves definition filled by the user
     */
    saveSubtypeDefinition = (defineTableData) => {
        let _this = this;
        var query = {
            action: "saveSubTypeDataDefinition",
            subTypeId: _this.state.subTypeId,
            definedFields: JSON.stringify(defineTableData),
            transactionColumn: _this.state.transactionKey,
            mappingFileId: _this.state.transactionFileId,
            hasDefinedFiles: _this.state.hasDefinedFiles,
            subSection: _this.state.subsection.toLowerCase().replaceAll(" ", "_"),
            section: _this.state.section.replace(/ /g,'_').toLowerCase()
        }

        var onThenCallback = (data)=> {
            let currRowData= _this.state.rowData;
            currRowData[FILE_CENSUS_FIELDS.HAS_DEFINED_COLUMNS] = true;
            _this.isFromDialog = true;
            _this.setState({
                rowData: currRowData
            },()=>{
                _this.tabulator.updateRow(currRowData[_index], currRowData);
                if (!parseBoolean(_this.state.hasDefinedFiles)) {
                    _this.setDefineOpen(false);
                    _this.launchToast(true);
                } else {
                    _this.redefineFiles();
                }
            });
            
            // _this.tabulator.updateRow(_this.state.rowData[_index], _this.state.rowData);
        };
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "saveSubTypeDataDefinition",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:lang.observability.modeling_settings.datasources.screen_name,
            [FETCHAPI_PARAMS.requestDescription]:lang.observability.modeling_settings.datasources.requests_description.save_subtype_data_definition
        }
        this.fetchAPI(fetchOptions);
    }


    setValue=(thisCell)=> {
        let obj = this;
        obj.tableReferences[DEFINE_DATA].current.setValue();
        if(obj.tableReferences[DEFINE_DATA].current.state.value === "BOOLEAN"){
            obj.state.showBooleanAlertMessage = true;
        }
        else{
            obj.state.showBooleanAlertMessage = false;
        }
    }

    applyCellFormats=(format)=> {
        var obj = this;
        var table = obj.tableReferences[DEFINE_DATA].current.tabulator;

        var errorMsgs = validateFormatsPopUP(format, obj.state);
        if(errorMsgs.length === 0) {
            var definedData = definition;
            switch (format) {
                case Formats.String:
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.MAX_LENGTH_FIELD] = $("#maxLength").val();
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.MIN_LENGTH_FIELD] = $("#minLength").val();
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD] = $("#trailOrLead").val();
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.IS_TRAILING_FIELD] = isTrailingorLeading === "Trailing Characters" ? "true" : "false";
                break;

                case Formats.Numeric:
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.MAX_FIELD] = $("#max").val().charAt(0) === "." ? "0"+ $("#max").val() :  $("#max").val() ;
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.MIN_FIELD] = $("#min").val().charAt(0) === "." ? "0"+ $("#min").val() : $("#min").val() ;
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.DECIMAL_FIELD] = $("#decimal").val();
                break;
                case Formats.DateTime:
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.DATE_FORMAT_FIELD] = $("#dateFormat").val();
                break;
                case Formats.Boolean:
                    var trueVals = obj.state.creditBoolValues;
                    definedData[cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]][DEFINE_FIELDS.TRUE_INPUT_FIELD] = trueVals.map(function(elem){return elem.value;}).join(",");
                    obj.setState({
                        creditBoolOptions: [],
                        creditBoolValues: []
                    },function(){
                       obj.forceUpdate();
                       obj.tableReferences[DEFINE_DATA].current.tabulator.scrollToRow(cell.getRow().getData()[DEFINE_FIELDS.ROW_NUMBER_FIELD]);
                    })
                    break;

            }
            errorMessage = "";
            table.replaceData(definedData);
            table.redraw();
            $("#trueInput").removeClass("emptyField");
            $("#max").removeClass("emptyField");
            $("#min").removeClass("emptyField");
            $("#maxLength").removeClass("emptyField");
            $("#minLength").removeClass("emptyField");
            $('#trailOrLead').removeClass("emptyField");
            $('#dateFormat').removeClass("emptyField");
            $('#save-new-arch').removeClass('disabled');

            this.setFormatDialogOpen(false, "")
        } else { errorMessage = errorMsgs;}
		compareDefinition(JSON.stringify(initialDefinition), JSON.stringify(table.getData()));
    }

    onCancelConfiguration = () => {
      this.setConfigureDialogOpen(false)
        this.setState ({
            currencysubsection: "",
            currenciesMatchingData: [],
            matching_message: undefined,
            currencyColumns: [],
            currencies:[]
        })

    }

    handleChangeIsTrailing=(e)=> {
        if (e !== null) {
            isTrailingorLeading = e.value;
            $('input[name="CharacterType"]').attr("value", e.value);
            $('input[name="CharacterType"]').parent().find(".Select-value-label").text(e.value);
            this.forceUpdate();
        }
    }

    directToGoogleHelp=()=> {
        window.open("https://cloud.google.com/bigquery/docs/reference/standard-sql/timestamp_functions#supported_format_elements_for_timestamp");
    }

    noOptionsMessage = () => {
        return MESSAGES.filter_text.no_results_found;
    }

    handleBooleanMultiChange = selectedOptions => {
        if(selectedOptions && selectedOptions.filter(f => f.value === MESSAGES.no_values_are_true || f.value === MESSAGES.no_values_are_credit).length > 0) { // Stop the user from inputing values if 'values are not true' is selected
            this.setCreditBoolOptions([obj.state.defaultBoolOption]);
            this.setCreditBoolValues([obj.state.defaultBoolOption]);
            return;
        }

        var arrayOfValues = [];
        if (selectedOptions && typeof selectedOptions === "object" && !Array.isArray(selectedOptions)) {
            selectedOptions = [selectedOptions];
        }
        if (selectedOptions && selectedOptions.length) {
            selectedOptions.map(labelValObj => {
                if (labelValObj.action === "set-value" && labelValObj.value.includes(",")) {
                    let tempValues = labelValObj.value.split(",");
                    tempValues.map(stringVal => {
                        if (stringVal === "") {
                            return;
                        }
                        arrayOfValues.push({ label: stringVal.trim(), value: stringVal.trim(), name: labelValObj.name, type: labelValObj.type });
                    });
                } else if (labelValObj.tagName === "INPUT") {
                    arrayOfValues.push(labelValObj.value);
                } else {
                    arrayOfValues.push(labelValObj);
                }
            });
        } else {
            this.loadOptionsOnInputChange();
        }
        var uniq = new Set(arrayOfValues.map(e => JSON.stringify(e)));
        arrayOfValues = Array.from(uniq).map(e => JSON.parse(e));
        this.setCreditBoolValues(arrayOfValues);
    };


    loadOptionsOnInputChange = (inputValue, callback) => {
        let _this = this;
        clearTimeout(this.entitiesTimeout);
        this.entitiesTimeout = setTimeout(function(){
            var keys = ["label", "value", "name", "type", "action"];
            var inputValObj = [inputValue, inputValue, "entity", "boolean", "set-value"];
            inputValue = inputValue === undefined ? "" : inputValue;

            var optionsArr = [_this.state.defaultBoolOption];
            let optionsSet = new Set(optionsArr.map(e => JSON.stringify(e)));
            optionsArr = Array.from(optionsSet).map(e => JSON.parse(e));

            let inputOption = buildNewObject(keys, inputValObj);
            if (inputValue !== "") {
                optionsArr.unshift(inputOption);
            }
            _this.setCreditBoolOptions(optionsArr);
            // Allows input value to be added directly to dropdwon options
            if(callback && typeof callback === "function") {
                callback(optionsArr);
            }
        }, 300);

    }

    initiateFTPUpload=()=> {
        let _this = this;
        _this.setState({
            pullSFTPDisabled:true
        },()=>{
            const onThenCallback = (data) => {
                console.log(data.result);
            };
            const onCompleteCallback = ()=>{
                console.log("FTP Upload initiated.")
                setTimeout(() => {
                    _this.setState({
                        pullSFTPDisabled: false
                    })
                }, 30000);
            };
            let data = {
                action: "initiateFTPUpload"
            }
            const fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "initiateFTPUpload",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
                [FETCHAPI_PARAMS.showLoader]: false,
                [FETCHAPI_PARAMS.path]: API_URL.DataPipeline,
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: data,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
                [FETCHAPI_PARAMS.onCompleteCallback]: onCompleteCallback,
                [FETCHAPI_PARAMS.email]: _this.props.user.email,
                [FETCHAPI_PARAMS.machine_name]: _this.props.machineName,
                [FETCHAPI_PARAMS.screenName]:lang.observability.modeling_settings.datasources.screen_name,
                [FETCHAPI_PARAMS.requestDescription]:lang.observability.modeling_settings.datasources.requests_description.initiate_ftp_upload
            }
            _this.fetchAPI(fetchOptions);
        })
        
    }

  columnHeadersDialogActions = () => {
    let obj = this;
    return (
      <>
        <Button
          label={"Save"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          className={"uk-padding-small-right"}
          onBtnClick={() => { obj.onColumnHeadersDialogSave(true) }}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          className="uk-padding-small-right"
          onBtnClick={() => { obj.setColumnHeadersDialogOpen(false) }}
        />
      </>
    )
  }

  warningDialogActions = () => {
    let obj = this;
    return (
      <>
        <Button
          label={this.state.defineOpen ? "Ok" : "Proceed"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          className={"uk-padding-small-right"}
          onBtnClick={() => { obj.onColumnHeadersDialogSave(false) }}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          className="uk-padding-small-right"
          onBtnClick={() => { obj.setColumnDefinitionWarningDialogOpen(false) }}
        />
      </>
    )
  }

  defineDialogActions = () => {
    let obj = this;
    return (
      <>
        <Button
          id="save-new-arch"
          label={"Save"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          className={"uk-padding-small-right"}
          onBtnClick={() => { obj.validateSubtypeDefinition() }}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          aria-label="Close"
          className="uk-padding-small-right"
          onBtnClick={obj.onDefineCancel}
        />
      </>
    )
  }

  runAutomationDialogActions = () => {
    let obj = this;
    return (
        <>
          <Button
            label={"Run"}
            variant={BUTTON_VARIANT.PRIMARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            aria-label="Close"
            className={"uk-padding-small-right"}
            onBtnClick={() => { obj.runManualAutomation(false) }}
          />
          <Button
            label={lang.modal.buttons.cancel}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            aria-label="Close"
            className="uk-padding-small-right"
            onBtnClick={() => { obj.setRunAutomationDialogOpen(false) }}
          />
        {this.state.selectPeriodWarning ? <div className='red uk-display-block uk-margin-left'><i className='fal fa-times-circle uk-margin-xsmall-right'></i>{MESSAGES.build_model_no_period_selected_alert}</div> : ""}
        {this.state.nonConsecutivePeriod ? <div className='red uk-display-block uk-margin-left'><i className='fal fa-times-circle uk-margin-xsmall-right'></i>{MESSAGES.build_model_consecutive_periods_alert}</div> : ""}
        </>
    )
  }

    addSubtype(groupName, data, cell, subCategoryMudule){
            cell._group.show();
            this.setChangesNotSaved(true);
            this.addsubsection(groupName, data, cell, subCategoryMudule);
    }

    runManualAutomation = () => {
        let _this = this;
        if(_this.state.periodsToBuild && _this.state.periodsToBuild.length > 0){
            if(arePeriodsConsecutive(_this.state.periodsToBuild)){
                let rowData = _this.state.rowData;
                let subTypeId = rowData.raw_file_subtype_id;
                var query = {
                    action: "runManualAutomation",
                    subTypeId: subTypeId,
                    periods: _this.state.periodsToBuild
                } 
                _this.setState({
                    periodsToBuild: [],
                    selectPeriodWarning: false,
                    nonConsecutivePeriod: false
                });
                _this.props.periods.map(period => period.checked = false);
                _this.setRunAutomationDialogOpen(false);
                _this.launchAutomationToast(true);

                var fetchOptions = {
                    [FETCHAPI_PARAMS.funcName]: "runManualAutomation",
                    [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                    [FETCHAPI_PARAMS.showLoader]: false,
                    [FETCHAPI_PARAMS.path]: API_URL.DataPipeline,
                    [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                    [FETCHAPI_PARAMS.query]: query,
                    [FETCHAPI_PARAMS.screenName]:lang.observability.modeling_settings.datasources.screen_name,
                    [FETCHAPI_PARAMS.requestDescription]:lang.observability.modeling_settings.datasources.requests_description.run_manual_automation
                }
                this.fetchAPI(fetchOptions);
            } else {
                _this.setState({
                    selectPeriodWarning: false,
                    nonConsecutivePeriod:true
                });
            }
        } else {
            _this.setState({
                selectPeriodWarning:true,
                showPeriodsComp: false
            });
        }
    }

  setValidationDialogOpen = (isOpen) => {
    let _this = this;
    _this.setState({
      openValidationDialog: isOpen,
    })
  }
  setResetDatasourceDialogOpen = (isOpen) => {
    let _this = this;
    _this.setState({
      openResetDatasourceDialog: isOpen,
    })
  }
  setFormatDialogOpen = (isOpen, selectedFormatType) => {
    let _this = this;
    _this.setState({
      openFormatDialog: isOpen,
      selectedFormatType: selectedFormatType
    })
  }
  setConfigureDialogOpen = (isOpen, states, callback) => {
    let _this = this;
    let tempState = states || {};
    tempState.openConfigureDialog = isOpen;
    _this.setState(tempState, () => {
      if(typeof callback === "function") {
        callback();
      }
    })
  }
 

  validationDialogContent = () => {
    return <h4>{this.state.validation}</h4>
  }
  resetDatasourceDialogContent = () => {
    return <h5>{lang.reset_warning}</h5>
  }
  formatDialogContent = () => {
    const optionLabel = ({label, isGroupTitle}) => (
        <div className={(isGroupTitle ? "option-group-header" : "") +" options-padding uk-flex-between uk-display-flex"}>
            {label}
        </div>
    );

    const customStylesMultiSelect = {
        option: (styles) => ({
            ...styles,
            cursor: 'pointer',
            fontSize: convertPxToViewport(12),
            padding: convertPxToViewport(8)+' 0 '+' '+convertPxToViewport(8)+' '+convertPxToViewport(9)
        }),
        control: (provided) => ({
            ...provided,
            cursor: 'pointer',
            borderColor: '#dcdcdc',
            minHeight: convertPxToViewport(33),
            height: convertPxToViewport(33),
            fontSize: convertPxToViewport(12),
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: convertPxToViewport(33),
            padding:  '0 '+convertPxToViewport(6),
            multiValue: () => ({
                marginRight: convertPxToViewport(15),
            })
        }),
        input: (provided) => ({
            ...provided,
            margin: '0',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: convertPxToViewport(33),
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            paddingTop: convertPxToViewport(6),
            paddingBottom: convertPxToViewport(6),
        }),
        clearIndicator: (styles) => ({
            ...styles,
            paddingTop: convertPxToViewport(6),
            paddingBottom: convertPxToViewport(6),
        }),
        placeholder: defaultStyles => {
            return {
                ...defaultStyles,
                color: "#C6CCD7",
                lineHeight: convertPxToViewport(12),
            };
        },
    };

    let formatType = this.state.selectedFormatType;

    return (
      <>
        <div>
          {
            errorMessage !== "" &&
            errorMessage.split('/').map(function (item) {
              return (<div className="error">{item}</div>);
            })
          }
        </div>
        {formatType === Formats.String &&
          <div id="datatype-format" className={Formats.String + " uk-display-inline datatype-format"}>
            <fieldset id="string-type" className="fieldset-border">
              <div className="uk-flex uk-flex-middle">
                <h6 className="uk-flex-column uk-width-1-2">Min Length</h6>
                <Input type="number" id="minLength" min="0"
                  className="uk-width-1-2 form-control uk-margin-small-left"
                  defaultValue={!currentDataTypesValues[DEFINE_FIELDS.MIN_LENGTH_FIELD] ? "" : formatValHTML(currentDataTypesValues[DEFINE_FIELDS.MIN_LENGTH_FIELD], FormatTypes.BASIS_POINT)}></Input>
              </div>
              <div className="uk-flex uk-flex-middle">
                <h6 className="uk-flex-column uk-width-1-2">Max Length</h6>
                <Input type="number" id="maxLength" min="0"
                  className={"uk-width-1-2 uk-margin-small-left form-control"}
                  defaultValue={!currentDataTypesValues[DEFINE_FIELDS.MAX_LENGTH_FIELD] ? "" : formatValHTML(currentDataTypesValues[DEFINE_FIELDS.MAX_LENGTH_FIELD], FormatTypes.BASIS_POINT)}></Input>
              </div>

              <div className="uk-flex uk-flex-middle">
                <h6 className="uk-flex-column uk-width-1-2">Character Type</h6>
                <div className='uk-width-1-2 form-control uk-margin-small-left uk-padding-remove'>
                  <Dropdown
                    id="select-TrailingChar"
                    name="CharacterType"
                    placeholder="Character Type"
                    value={findOptionByKey(obj.state.charactersOptions, isTrailingorLeading)}
                    options={this.state.charactersOptions}
                    onChange={this.handleChangeIsTrailing}
                    type={DROPDOWN_TYPE.INPUT}
                    className = "input__dropdown"
                  />
                </div>
              </div>
              <div className="uk-flex uk-flex-middle">
                <h6 className="uk-flex-column uk-width-1-2">Trailing/Leading Character</h6>
                <Input type="text" id="trailOrLead" className={"uk-width-1-2 uk-margin-small-left form-control"} maxLength="1"
                  defaultValue={!currentDataTypesValues[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD] ? "" : currentDataTypesValues[DEFINE_FIELDS.TRAIL_OR_LEAD_FIELD]}></Input>
              </div>
            </fieldset>
          </div>
        }
        {formatType === Formats.Numeric &&
          <div id="datatype-format" className={Formats.Numeric + " uk-display-inline datatype-format"}>
            <fieldset id="numeric-type" className="fieldset-border">
              <div className="uk-flex uk-flex-middle">
                <h6 className="uk-flex-column uk-width-1-2">Min</h6>
                <Input type="number" id="min"
                  className="form-control uk-width-1-2 uk-margin-small-left" defaultValue={!currentDataTypesValues[DEFINE_FIELDS.MIN_FIELD] ? "" : formatValHTML(currentDataTypesValues[DEFINE_FIELDS.MIN_FIELD], FormatTypes.BASIS_POINT)}></Input>
              </div>
              <div className="uk-flex uk-flex-middle">
                <h6 className="uk-flex-column uk-width-1-2">Max</h6>
                <Input type="number" id="max"
                  className="form-control uk-width-1-2 uk-margin-small-left" defaultValue={!currentDataTypesValues[DEFINE_FIELDS.MAX_FIELD] ? "" : formatValHTML(currentDataTypesValues[DEFINE_FIELDS.MAX_FIELD], FormatTypes.BASIS_POINT)}></Input>
              </div>

              <div className="uk-flex uk-flex-middle">
                <h6 className="uk-flex-column uk-width-1-2">Decimal</h6>
                <Input type="number" id="decimal" min="0" onKeyUp={adjustNumberFieldToInteger} onChange={adjustNumberFieldToInteger}
                  className="form-control uk-width-1-2 uk-margin-small-left" defaultValue={currentDataTypesValues[DEFINE_FIELDS.DECIMAL_FIELD] === undefined ? currentDataTypesValues[DEFINE_FIELDS.DECIMAL_FIELD] : ""} />
              </div>
            </fieldset>

          </div>
        }
        {formatType === Formats.DateTime &&
          <div id="datatype-format" className={Formats.DateTime + " uk-display-inline datatype-format"}>
            <fieldset id="datetime-type" className="fieldset-border">
              <div className="uk-flex uk-flex-middle">
                <h6 className="uk-flex-column uk-width-1-2">Date Format</h6>
                <Input type="text" id="dateFormat" min="0" className="form-control uk-width-1-2 uk-margin-small-left" defaultValue={!currentDataTypesValues[DEFINE_FIELDS.DATE_FORMAT_FIELD] || currentDataTypesValues[DEFINE_FIELDS.DATE_FORMAT_FIELD] === "" ? DEFAULT_DATE_FORMAT_UPLOAD : currentDataTypesValues[DEFINE_FIELDS.DATE_FORMAT_FIELD]}></Input>
                <i id="help-image" className="far fa-question-circle fa-lg upload-data-fa uk-margin-xsmall" alt="Big Query help" onClick={() => obj.directToGoogleHelp()}></i>
              </div>
            </fieldset>

          </div>
        }
        {formatType === Formats.Boolean &&
          <div id="datatype-format" className={Formats.Boolean + " uk-display-inline datatype-format"}>
            <fieldset id="boolean-type" className="fieldset-border">
              <div className="uk-flex uk-flex-middle">
                <h6 id="trueInputLabel" className="uk-flex-column uk-width-1-4">Value if True</h6>
                <AsyncSelect
                  id={"trueInput"}
                  className={"width-350 uk-margin-small-left uk-width-3-4"}
                  classNamePrefix="multiSelectNoWrap"
                  formatOptionLabel={optionLabel}
                  name="multiSelect"
                  loadOptions={obj.loadOptionsOnInputChange}
                  onChange={obj.handleBooleanMultiChange}
                  tabSelectsValue={true}
                  noOptionsMessage={obj.noOptionsMessage}
                  placeholder={"Boolean Values"}
                  isMulti
                  closeMenuOnSelect={false}
                  defaultOptions={obj.state.creditBoolOptions}
                  options={obj.state.creditBoolOptions}
                  value={obj.state.creditBoolValues}
                  styles={customStylesMultiSelect}
                  menuPlacement="auto"
                  maxMenuHeight={convertPxToViewport(190)}
                />
                {/* <DropDown // To remove comment and comment <AsyncSelect
                                            id={"trueInput"}
                                            className={"width-350 uk-margin-small-left uk-width-3-4"}
                                            classNamePrefix="multiSelectNoWrap"
                                            formatOptionLabel={optionLabel}
                                            name="multiSelect"
                                            loadOptions={obj.loadOptionsOnInputChange}
                                            onChange={obj.handleBooleanMultiChange}
                                            tabSelectsValue={true}
                                            noOptionsMessage={obj.noOptionsMessage}
                                            placeholder={"Boolean Values"}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            defaultOptions={obj.state.creditBoolOptions}
                                            options={obj.state.creditBoolOptions}
                                            value={obj.state.creditBoolValues}
                                            menuPlacement="auto"
                                            maxMenuHeight={convertPxToViewport(190)}
                                            type={DROPDOWN_TYPE.INPUT}
                                            isAsync={true}
                                         /> */}
              </div>
            </fieldset>

          </div>
        }
      </>
    )
  }

  currencyColumnFormatter = (colField) => {
    let columnFormatter = function(cell, formatterParams) {
        let p = document.createElement("p");
        let value = cell.getValue();
        p.innerHTML = formatValHTML(value, cell.getColumn().getDefinition().format_type);
        
        if(colField === "currency_subcategory") {
            let rowData = cell.getRow().getData();
            if((rowData.currency_subcategory === "" && rowData.transaction_currency !== "") || rowData.currency_subcategory === undefined || (rowData.currency_subcategory === 'NULL' && rowData.transaction_currency !== 'NULL')            ) {
                p.innerText = "No match";
            }
            if(rowData.currency_subcategory === undefined || (rowData.currency_subcategory === "" && rowData.transaction_currency !== "") || (rowData.currency_subcategory === 'NULL' && rowData.transaction_currency !== 'NULL')) {
                p.classList.add("red");
            }  
        }   
        return p;
    }

    return columnFormatter;
}

  configureDialogContent = () => {
    const bridgeOptions = ({ title, label, isDisabled, value }) => (
      <div title={label ? label : value} className={(isDisabled && title ? "option-group-header option-padding" : isDisabled ? " disabled " : "") + " uk-text-overflow-ellipsis"}>
        {(label ? label : value)}
      </div>
    );

    const { currenciesMatchingData } = this.state;

    return (
      <div className="uk-margin-top">
        <div className="uk-display-flex uk-flex-bottom uk-margin-xmedium-bottom uk-flex-between">
          <div className="uk-flex-inline uk-flex-column">
            <label htmlFor="select-currencysubcategory" className="fs-14 width-200 uk-margin-xsmall-bottom">{MESSAGES.UPLOAD.currency_subcategory}</label>
            <Dropdown
              id="select-currencysubcategory"
              className="width-150 input__dropdown"
              name="currencySubcategory "
              placeholder="Select currency subcategory"
              value={findOptionByKey(this.state.currencysubsections, this.state.currencysubsection)}
              options={this.state.currencysubsections}
              onChange={(e) => this.handleCurrencyChange(e, RAW_ITEMS.FILE)}
              classNamePrefix="currency-conversion-dropdown"
              type={DROPDOWN_TYPE.INPUT}
            />
          </div>
          <div className="uk-flex-inline uk-flex-column uk-padding-small-left">
            <label htmlFor="select-transactioncurrency" className="fs-14 width-200 word-wrap uk-margin-xsmall-bottom">{capitaliseFirstLetterAfterChar(this.state.subsection.replaceAll("_", ' ')) + MESSAGES.UPLOAD.transaction_currency}</label>
            <Dropdown
              id="select-transactioncurrency"
              className="width-150 input__dropdown"
              name="transactionCurrency "
              placeholder={"Select " + capitaliseFirstLetterAfterChar(this.state.subsection.replaceAll("_", " ")) + " currency "}
              value={findOptionByKey(this.state.currencyColumns, this.state.currencyColumn)}
              options={this.state.currencyColumns}
              onChange={(e) => this.handleCurrencyChange(e, RAW_ITEMS.FIELD_NAME)}
              formatOptionLabel={bridgeOptions}
              classNamePrefix="currency-conversion-dropdown"
              type={DROPDOWN_TYPE.INPUT}
            />
          </div>
          {this.state.showCurrencyDate ?
            <div className="uk-flex-inline uk-flex-column uk-padding-small-left">
              <div className="uk-flex uk-flex-middle width-150">
                <label htmlFor="select-tranctiondate" className="fs-14 uk-margin-xsmall-bottom">{capitaliseFirstLetterAfterChar(this.state.subsection.replaceAll("_", ' ')) + MESSAGES.UPLOAD.date_currency}</label>
                <i className="fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={MESSAGES.UPLOAD.date_currency_tooltip} />
              </div>
              <Dropdown
                id="select-tranctiondate"
                className="width-150 input__dropdown"
                name="transactionDate "
                placeholder={"Select " + capitaliseFirstLetterAfterChar(this.state.subsection.replaceAll("_", " ")) + " date "}
                value={findOptionByKey(this.state.currencyDateColumns, this.state.currencyDate)}
                options={this.state.currencyDateColumns}
                onChange={(e) => this.handleCurrencyChange(e, RAW_ITEMS.FIELD_DATA_TYPE)}
                classNamePrefix="currency-conversion-dropdown"
                type={DROPDOWN_TYPE.INPUT}
              />
            </div>
            :
            ""
          }
          <div className="uk-flex-inline uk-flex-column uk-padding-small-left">
            <label htmlFor="conversion-currency-input" className="fs-14 width-150 uk-margin-xsmall-bottom">{MESSAGES.UPLOAD.conversion_currency}</label>
            <Input id="conversion-currency-input" type="text" value={window._format?.currency_abbreviation} className="form-control width-150 uk-disabled uk-input-readonly"></Input>
          </div>
        </div>
         <div className="crosswalk-table uk-border">
          <div className="mapping-header uk-flex-between uk-padding-large-right">
            <div className="uk-display-flex uk-flex-middle">
              <div className="fs-14 uk-text-bold">{MESSAGES.UPLOAD.entity_matching}</div>
              <i className="fal fa-info-circle uk-margin-xsmall-left" uk-tooltip={MESSAGES.UPLOAD.entity_matching_summary_tooltip_1 + capitaliseFirstLetterAfterChar(this.state.subsection.replaceAll("_", ' ')) + MESSAGES.UPLOAD.entity_matching_summary_tooltip_2} />
            </div>
            <div id="matchingCurrency" className="text-link fs-14 uk-text-decoration-style-dashed" uk-tooltip={this.state.tooltip_message}>{this.state.matching_message ? "Matching " + this.state.matching_message : ""}</div>
          </div>
            <SimpleTable
                data={currenciesMatchingData}
                columns={[
                    { field: "transaction_currency", title: capitaliseFirstLetterAfterChar(this.state.subsection.replaceAll("_", " ")) + " Currency", format_type: "text", cssClass: "text-align-center", dontFilter: false },
                    { field: "currency_subcategory", title: "Currency Subcategory", format_type: "text", cssClass: "text-align-center", dontFilter: false },
                ]}
                pagination={"local"}
                paginationSize={5}
                paginationSizeSelector={[5, 10, 20, 25, 50, 100]}
                tabulatorId="simple-table"
                columnFormatter={this.currencyColumnFormatter}
                cleanUpTabulator
                initialSort={{column: "transaction_currency", dir: "asc"}}
                headerFilters={[{column: "currency_subcategory", headerFilterFunc: noMatchFilterFunction }]}
            />
        </div>
       </div>
    )
  }

  setInfoDialogOpen = (isOpen, infoMsg) => {
    let _this = this;
    _this.setState({
      openInfoDialog: isOpen,
      infoMsg: infoMsg
    })
  }

  openInfoDialogActions = () => {
    return (
      <Button
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.PRIMARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setInfoDialogOpen(false, "")}
      />
    )
  }
  
  validationDialogActions = () => {
    return (
      <Button
        id="close-btn"
        label={lang.modal.buttons.ok}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={this.onDefineCancel}
      />
    )
  }
  resetDatasourceDialogActions = () => {
    return (
      <>
        <Button
          label={lang.navigation.btns.discard}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.resetFileCensus()}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setResetDatasourceDialogOpen(false)}
        />
      </>
    )
  }
  formatDialogActions = () => {
    return (
      <>
        <Button
          id="submit-format-data-btn"
          label={"Apply"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => obj.applyCellFormats(currentDataType)}
        />
        <Button
          id="close-btn"
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={() => this.setFormatDialogOpen(false, "")}
        />
      </>
    )
  }
  configureDialogActions = () => {
    return (
      <>
        <Button
          label={"Submit"}
          variant={BUTTON_VARIANT.PRIMARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.saveconfiguration}
        />
        <Button
          label={lang.modal.buttons.cancel}
          variant={BUTTON_VARIANT.SECONDARY}
          size={SIZES.DEFAULT}
          type={BUTTON_TYPE.DEFAULT}
          onBtnClick={this.onCancelConfiguration}
        />
      </>
    )
  }


    render() {
        var uploadValidations = [];
        let isSaveDisabled = !this.state.changesNotSaved;
        const optionLabel = ({label, isGroupTitle}) => (
            <div className={(isGroupTitle ? "option-group-header" : "") +" options-padding uk-flex-between uk-display-flex"}>
                {label}
            </div>
        );

        const customStyles = {
            option: (styles) => ({
                ...styles,
                padding: convertPxToViewport(8) + ' 0' ,
            }),
            control: (provided) => ({
                ...provided,
                minHeight: convertPxToViewport(38),
                borderRadius: convertPxToViewport(4),
                borderWidth: convertPxToViewport(1),
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: convertPxToViewport(43),
                overflow: "auto",
                padding:  '0 '+convertPxToViewport(6),
                multiValue: (styles) => ({
                    marginRight: convertPxToViewport(2),
                })
            }),
        };
        const customStylesMultiSelect = {
            option: (styles) => ({
                ...styles,
                cursor: 'pointer',
                fontSize: convertPxToViewport(12),
                padding: convertPxToViewport(8)+' 0 '+' '+convertPxToViewport(8)+' '+convertPxToViewport(9)
            }),
            control: (provided) => ({
                ...provided,
                cursor: 'pointer',
                borderColor: '#dcdcdc',
                minHeight: convertPxToViewport(33),
                height: convertPxToViewport(33),
                fontSize: convertPxToViewport(12),
            }),
            valueContainer: (provided) => ({
                ...provided,
                height: convertPxToViewport(33),
                padding:  '0 '+convertPxToViewport(6),
                multiValue: () => ({
                    marginRight: convertPxToViewport(15),
                })
            }),
            input: (provided) => ({
                ...provided,
                margin: '0',
            }),
            indicatorSeparator: () => ({
                display: 'none',
            }),
            indicatorsContainer: (provided) => ({
                ...provided,
                height: convertPxToViewport(33),
            }),
            dropdownIndicator: (styles) => ({
                ...styles,
                paddingTop: convertPxToViewport(6),
                paddingBottom: convertPxToViewport(6),
            }),
            clearIndicator: (styles) => ({
                ...styles,
                paddingTop: convertPxToViewport(6),
                paddingBottom: convertPxToViewport(6),
            }),
            placeholder: defaultStyles => {
                return {
                    ...defaultStyles,
                    color: "#C6CCD7",
                    lineHeight: convertPxToViewport(12),
                };
            },
        };

        
        return (
            <div>
                <div id="toastDataSources" className="toast">
                    <div id="desc"><i className={"fa-lg fas" +(this.state.isError ? " fa-minus-circle uk-margin-small-right uk-text-primary" : this.state.isWarning ? " fa-exclamation-circle uk-margin-small-right orangeText" : " fa-check-circle  uk-margin-small-right greenText" )}aria-hidden="true"></i>{this.state.notification}</div>
                </div>
                <div className='dataSources-header'>
                    <div className="uk-display-flex justify-content-between">
                        <div className="manage-column  deleteDataSource">
                            <Button 
                                label={lang.modeling_settings.delete_title}
                                variant={BUTTON_VARIANT.SECONDARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                leftIcon={<i className={"fal fa-trash-alt fa-lg"} />}
                                className="uk-display-none uk-margin-left"
                                disabled={true}
                            />
                            <Button 
                                id="pull_sftp" 
                                label={lang.modeling_settings.pull_sftp_data}
                                variant={BUTTON_VARIANT.SECONDARY}
                                size={SIZES.DEFAULT}
                                type={BUTTON_TYPE.DEFAULT}
                                leftIcon={<i className={"fal fa-upload fa-lg"}  />}
                                className="uk-display-none uk-margin-left"
                                disabled={this.state.pullSFTPDisabled}
                                onBtnClick={()=>{this.initiateFTPUpload()}}
                            />
                        </div>
                        <div className="uk-display-flex saveDataSource">
                            {!isSaveDisabled?
                                <div className="uk-display-flex uk-margin-xsmall-top" id="reset-btn">
                                    <div className="uk-margin-small-right">
                                        <span className="fs-12 red italic uk-display-flex uk-flex-middle">{lang.manage_columns.text.changes_not_saved}</span>
                                    </div>
                                    <div title="Reset" uk-tooltip={"title:"+lang.reset_warning+ "; pos: bottom"} className="uk-display-inline-block uk-margin-small-right uk-cursor-pointer" onClick={()=>this.showResetWarningPopup()}>
                                        <span className="fs-12 uk-text-decoration-underline uk-display-flex uk-flex-middle">{lang.navigation.btns.reset}</span>
                                    </div>
                                </div>
                                :""
                            }
                            <div uk-tooltip={isSaveDisabled? "title: Changes already saved; pos: bottom":undefined}> 
                                <Button 
                                    id="saveSettingsFC"
                                    label={lang.navigation.btns.save}
                                    variant={BUTTON_VARIANT.PRIMARY}
                                    size={SIZES.DEFAULT}
                                    type={BUTTON_TYPE.DEFAULT}
                                    disabled={isSaveDisabled}
                                    onBtnClick={() =>{this.save(MODELING_SETTINGS.TITLES.DATA_SOURCES)}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dataSourcesBorder scrollOverflow" id="dataSourcesTable" ref="dataSourcesTable"/>
                <div  className="uk-margin-xmedium-top">
                    <div className="uk-flex-bottom">
                        <label htmlFor={lang.modeling_settings.user_notifications_title.replaceAll(" ","_").toLowerCase()} className="fs-14 uk-margin-xsmall-bottom">{lang.modeling_settings.user_notifications_title}</label>
                        <i className="fal fa-info-circle uk-padding-xsmall-right uk-margin-xsmall-left"  uk-tooltip={lang.modeling_settings.info_icons.user_notifications}></i>
                        <Select
                            id={lang.modeling_settings.user_notifications_title.replaceAll(" ","_").toLowerCase()}
                            className="uk-width-1-2 uk-display-inline-block uk-cursor-pointer"
                            classNamePrefix="user-notification-dropdown"
                            name={lang.modeling_settings.user_notifications_title}
                            defaultValue={this.props.userNotifications}
                            value={this.state.multiValue}
                            isMulti
                            onChange={this.handleMultiChange}
                            options={this.props.userOptions}
                            placeholder={lang.modeling_settings.user_notifications_placeholder}
                            formatOptionLabel={optionLabel}
                            styles={customStyles}
                            maxMenuHeight= {convertPxToViewport(300)}
                            menuIsOpen={this.state.menuIsOpen}
                            onMenuOpen={() => this.setState({ menuIsOpen: true })}
                            onMenuClose={() => this.setState({ menuIsOpen: false })}
                            menuPlacement = "top"
                        />
                        {/* <DropDown  // to remove comment and comment <Select
                            id={lang.modeling_settings.user_notifications_title.replaceAll(" ","_").toLowerCase()}
                            className="uk-width-1-2 uk-display-inline-block uk-cursor-pointer"
                            classNamePrefix="user-notification-dropdown"
                            name={lang.modeling_settings.user_notifications_title}
                            defaultValue={this.props.userNotifications}
                            value={this.state.multiValue}
                            isMulti
                            onChange={this.handleMultiChange}
                            options={this.props.userOptions}
                            placeholder={lang.modeling_settings.user_notifications_placeholder}
                            formatOptionLabel={optionLabel}
                            type={DROPDOWN_TYPE.INPUT}
                            maxMenuHeight= {convertPxToViewport(300)}
                            menuIsOpen={this.state.menuIsOpen}
                            menuPlacement = "top"
                        /> */}
                        </div>
                </div>  
                <Modal 
                  id={"set-column-headers-dialog"}
                  title={lang.modeling_settings.data_source.titles.set_column_headers_dialog + obj.state.subsection} 
                  openDialog={obj.state.columnHeadersDialogOpen}
                  closeClick={() => obj.setColumnHeadersDialogOpen(false)}
                  bodyContent={obj.columnHeadersDialogBodyContent}
                  tooltipText={lang.modeling_settings.info_icons.column_headers_info}
                  dialogActions={obj.columnHeadersDialogActions}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal 
                  id={"warning-datasource-dialog"} 
                  title={"Warning"} 
                  openDialog={obj.state.columnDefinitionWarningDialogOpen}
                  closeClick={() => obj.onDialogCancel(obj.setColumnDefinitionWarningDialogOpen)}
                  bodyContent={obj.columnDefinitionDialogWarningContent}
                  dialogActions={obj.warningDialogActions}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={'define-columns-dialog'}
                  title={lang.modeling_settings.define.define_title + capitaliseFirstLetterAfterChar(obj.state.section).replaceAll("_", " ")}
                  openDialog={obj.state.defineOpen}
                  closeClick={obj.onDefineCancel}
                  bodyContent={obj.defineBodyContent}
                  tooltipText={lang.modeling_settings.info_icons.column_headers_info}
                  dialogActions={obj.defineDialogActions}
                  size={DIALOG_SIZE.XLARGE}
                />
                <Modal
                  id={"run-automation-dialog"}
                  title={lang.modeling_settings.data_source.titles.run_automation + obj.state.subsection}
                  openDialog={obj.state.runAutomationDialogOpen}
                  closeClick={() => obj.setRunAutomationDialogOpen(false)}
                  bodyContent={obj.runAutomationDialogBodyContent}
                  dialogActions={obj.runAutomationDialogActions}
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"validation-dialog"}
                  openDialog={this.state.openValidationDialog}
                  bodyContent={this.validationDialogContent}
                  dialogActions={this.validationDialogActions}
                  closeClick={() => this.setValidationDialogOpen(false) }     
                  size={DIALOG_SIZE.SMALL}
                />
                <Modal
                  id={"reset-datasource-dialog"}
                  openDialog={this.state.openResetDatasourceDialog}
                  bodyContent={this.resetDatasourceDialogContent}
                  dialogActions={this.resetDatasourceDialogActions}
                  closeClick={() => this.setResetDatasourceDialogOpen(false) }     
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"format-dialog"}
                  title={"Format"}
                  openDialog={this.state.openFormatDialog}
                  bodyContent={this.formatDialogContent}
                  dialogActions={this.formatDialogActions}
                  closeClick={() => this.setFormatDialogOpen(false, "")}     
                  size={DIALOG_SIZE.MEDIUM}
                />
                <Modal
                  id={"configure-dialog"}
                  title={MESSAGES.UPLOAD.configure_modal_title + this.state.rowData[DEFINE_FIELDS.FIELD_NAME_FIELD]}
                  openDialog={this.state.openConfigureDialog}
                  bodyContent={this.configureDialogContent}
                  dialogActions={this.configureDialogActions}
                  closeClick={() => this.setConfigureDialogOpen(false)}     
                  size={DIALOG_SIZE.LARGE}
                />
                <Modal
                  id={"info-dialog"}
                  openDialog={this.state.openInfoDialog}
                  bodyContent={() => <h4>{this.state.infoMsg}</h4>}
                  dialogActions={this.openInfoDialogActions}
                  closeClick={() => this.setInfoDialogOpen(false)}
                  size={DIALOG_SIZE.MEDIUM}
                />
                
            </div>
        );
    }
}

export default DataSources;