import React, {Suspense, useEffect, useRef, useState} from 'react';
import {useAuthenticated} from "../CustomHooks";
import {ReportHeader} from "../ReportHeader";
import MonthlyBuild from "../../components/monthlyBuild/MonthlyBuild";
import {ALL_WIDGETS, BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, PERIOD_ONWARD, SIZES} from "../../class/constants";
import {renderBreadcrumbsAndDescription, renderTitle} from "../functions/componentFunctions";
import {logout} from "../../class/common";
import { useDispatch } from 'react-redux';
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from 'posthog-js/react';

/**
 * @author Ralph Bejjani
 * @description This component holds the  header of the monthly build and the monthly build component named MonthlyBuild where the header contains title
 * @param {*} props
 * @returns Report header and monthly build
 */
const MonthlyBuildWrapper = (props) => {
    const userAllowedMenuLinks = props.userAllowedMenuLinks;
    const userSettings = props.userSettings;
    const checkingForUnsavedChanges = props.checkingForUnsavedChanges;
    const dispatch = useDispatch();
    const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
    const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.MONTHLY_BUILD);
    const [headerElements, setHeaderElements] = useState([]);
    const [divIdState, setDivIdState] = useState();
    const [destinationClientIdState, setDestinationClientIdState] = useState();
    const reportRef = useRef();
    const useNewComponents = useFeatureFlagEnabled('use_new_components');

    /**
     * @function useEffect()
     * on screen initialization if isAuthenticated is false user gets logged out
     * isAuthenticated is a boolean read from a custom hook named useAuthenticated
     **/
    useEffect(() => {
        if (!isAuthenticated) {
            logout();
        }
    }, [isAuthenticated]);

    /**
     * @function useEffect()
     * On mount set header elements which is the title only in our case
     */
    useEffect(() => {
        let headerElements = useNewComponents ? getNewHeaderElements() : getHeaderElements();
        setHeaderElements(headerElements);
    }, [useNewComponents]);

    /**
     * @function useEffect()
     * To check for unsaved changes when changing client or report without saving
     */
    useEffect(() => {
        if (checkingForUnsavedChanges) {
            setDivIdState(props.divId);
            setDestinationClientIdState(props.destinationClientId);
            if(document.getElementById("save-before-leave")){
                reportRef?.current?.openSaveBeforeLeaveDialog("changeReport");
            } else {
                props.setCheckingForUnsavedChanges(false);
                props.setCheckedUnsavedChangesRef(true, props.divId && Object.keys(props.divId).length > 0, !!props.destinationClientId);
            }
        }
    }, [checkingForUnsavedChanges, props.divId, props.destinationClientId]);

    const changeReport = () => {
        props.setChangingReport(false)
        props.setCheckingForUnsavedChanges(false);
        props.setCheckedUnsavedChangesRef(true, divIdState && Object.keys(divIdState).length > 0, !!destinationClientIdState);
    };


    const getHeaderElements = () => {
        let headerElements = [];
        headerElements.push(
            <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="first_header_row gap_between_buttons">
                {renderTitle(reportTitle)}
            </div>
        );

        return headerElements;
    };

    const renderHeaderFirstRow = () => {
        return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription)
    }

    const getNewHeaderElements = () => {
        let headerElements = [];
        headerElements.push(
            <div style={{display: "flex", width: "100%", alignItems: "center"}}
                 className='new_header_menu first_header_row gap_between_buttons'>
                {renderHeaderFirstRow()}
            </div>
        );
        return headerElements;
    };

    return (
        <>
            <Suspense fallback={<p>Loading...</p>}>
                <div
                    id={"main-component-container"}
                    className={"main-component-container"}
                    style={{ "--banner-height": 0, gridTemplateRows: "minmax(0, var(--banner-height)) 0.1fr minmax(60%, 93%)" }}
                >
                    <div className={"header-banner-div-hidden"} />
                    <div className="main-report-header">
                        <ReportHeader headerElements={headerElements} />
                    </div>
                    <div className="main_report">
                        <div id="main_report_container" className="main_report_container" style={{border: "none"}}>
                            <MonthlyBuild ref={reportRef} machineName={userSettings.machine_name} user={userSettings.user}
                                changeReport={changeReport} dispatch={dispatch} />
                        </div>
                    </div>
                </div>
            </Suspense>
        </>
    );
};

export {MonthlyBuildWrapper};
