import { DialogContent } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { addLevelForAll, getEmbeddedChild, getEmbeddedChildren, linearizeHierarchy, updateAllElements, updateAllElementsTree } from '../../class/array';
import { BUTTON_TYPE, BUTTON_VARIANT, DASHBOARDS, DIALOG_SIZE, MANAGE_STACKS, PROFILE_COLUMN, PS_MAPPING, SIZES, costtype } from '../../class/constants';
import { capitalizeFirstLetter, copyObjectValues, isRedDot } from '../../class/utils';
import { ToggleTab } from '../../form/elements.js';
import { FormComponent } from '../../form/FormElements';
import { lang } from '../../language/messages_en';
import StackTabulator from './StackTabulator';
import Button from '../../newComponents/Button';
import Modal from '../../newComponents/Modal';

const $ = require('jquery');
const _children = "children";
const _isMoved = "isMoved";
const _isApplicableForMoving = "isApplicableForMoving"
const _checked = "checked";
const _indeterminate = "indeterminate";
const _costKey = "costKey";
const showExpandFlag = "isExpandable"; // detector for tabulator to show expand/collapse icon

const StackConfiguration = forwardRef((props,ref) => {   
    const _isInSearchResult = PROFILE_COLUMN.IS_IN_SEARCH_RESULT;
    const _isExpandable = PROFILE_COLUMN.IS_EXPANDABLE;
    const _isExpanded = PROFILE_COLUMN.IS_EXPANDED;
    const { isCompanyAllowed, isUserAllowed, tableData, toggleSaveButton, changeAddStack, saveStacks, editedStackInfo, isUserLimitReached, isCompanyLimitReached, originalStackInfo ,editedStackData,editStack, originalProfitStackFields, manageStacksDialogBodySave, checkCompatibilityBeforeSave} = props;
    const [currentManageStack, setCurrentManageStack] = useState({accessChanged:false, visibility: (isUserAllowed && !isUserLimitReached ? "user": isCompanyAllowed && !isCompanyLimitReached  ? "company" : '')});
    const [name, setName] = useState("");
    const [emptyName, setEmptyName] = useState(!editStack);
    let initialVisibility = useRef(null)
    let isEmptyName = useRef(!editStack);
    const [isCompanyValid, setIsCompanyValid] = useState(!isCompanyAllowed || isCompanyLimitReached ? false : true)
    const [isUserValid, setIsUserValid] = useState(!isUserAllowed || isUserLimitReached ? false : true)
    const [visibility, setVisibility] = useState(isCompanyAllowed && !isCompanyLimitReached && !isUserAllowed ? "company" : isUserAllowed && !isUserLimitReached && !isCompanyAllowed ? "user" : currentManageStack.visibility);
    const [newStackData, setNewStackData] = useState([]);
    const [checkedItems,setCheckedItems] = useState([]);
    const [cell,setCell] = useState({});
    const [redDot,setRedDot] = useState(false);
    let isRedDotRef = useRef(false);
    const [deletedCell,setDeletedCell] = useState(undefined);
    const [moved, setMoved] = useState(0);
    const [filter, setFilter] = useState(false);
    const [stackIsChanged, setStackIsChanged] = useState(false);
    let isStackChanged = useRef(false);
    let checkedItemsRef = useRef(checkedItems);
    const publishedStackedRef = useRef();
    const newStackRef = useRef();
    let publishedStackTabulator = useRef(null);
    let hasFilter = useRef(false);
    let filteredCostKey = useRef(null);
    let newStackTabulator = useRef(null);
    let expandedRowsCostkeysPublished = useRef([]);
    let expandedRowsCostkeysNew = useRef([]);
    let copyExpandedRowsCostkeysNew = useRef([]);
    let newStackTabulatorData = useRef([]);
    let publishedStackTabulatorData = useRef([]);
    const prevCheckedItems = usePrevious(checkedItems);
    const [openDialog, setOpen] = useState(false);
    const [openDialogDisabled, setOpendisabled] = useState(false);
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [visibilityOptions,setVisibilityOptions] = useState([{label: "User", value: "user", iconClass: "fa-lg fal fa-user"} , {label: "Company", value: "company", iconClass: "fa-lg fal fa-users"}]);
        
    const [isnameValid, setIsNameValid] = useState(true); 
    let isValidName = useRef(true);
 
    useEffect(()=>{
        if (!editStack) { // when adding a stack
            setVisibilityOptions([
                {
                    label: capitalizeFirstLetter("User"), value: "user", iconClass: "fa-lg fal fa-user",
                    tooltip: (!isUserAllowed ? lang.manage_stacks.no_permission : isUserLimitReached ? lang.manage_stacks.limit_reached : "title:"),
                    isDisabled: !isUserValid
                },
                {
                    label: capitalizeFirstLetter("Company"), value: "company", iconClass: "fa-lg fal fa-users",
                    tooltip: (!isCompanyAllowed ? lang.manage_stacks.no_permission : isCompanyLimitReached ? lang.manage_stacks.limit_reached : "title:"),
                    isDisabled: !isCompanyValid
                }
            ])
        } else if (originalStackInfo && editStack) { // when editing a stack
            setVisibilityOptions([
                {
                    label: capitalizeFirstLetter("User"), value: "user", iconClass: "fa-lg fal fa-user",
                    tooltip: (!isUserAllowed ? lang.manage_stacks.no_permission : (isUserLimitReached && originalStackInfo.visibility !== "user") ? lang.manage_stacks.limit_reached : "title:"),
                    isDisabled: originalStackInfo.visibility === "user" ? false : (!isUserValid)
                },
                {
                    label: capitalizeFirstLetter("Company"), value: "company", iconClass: "fa-lg fal fa-users",
                    tooltip: (!isCompanyAllowed ? lang.manage_stacks.no_permission : (isCompanyLimitReached && originalStackInfo.visibility !== "company") ? lang.manage_stacks.limit_reached : "title:"),
                    isDisabled: originalStackInfo.visibility === "company" ? false : !isCompanyValid
                }
            ])
        }
    },[originalStackInfo])

    let inputRefs = {};
    let inputRefsDescription = {};
    useImperativeHandle(ref,()=>{
        return {
            emptyCurrentManageStacks:emptyCurrentManageStacks
        };
    })
      

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
   const  updateIsColumnIsExpandable = (data,level = 0) => {
        for(let e in data){
            let element = data[e];
            element.level = level;
            element.column_level = level;
            element[_isExpandable] = !!element[_children] && element[_children].length > 0;
            element[_isExpanded] = false;   //all parents are collapsed by default
            element[_isInSearchResult] = true;   
            if(element[_children]){
                updateIsColumnIsExpandable(element[_children],level+1);
            }
        } 
        
        return data;
    }

    const getPublishedStackTabulator =(linearise)=>{
        let data = publishedStackTabulator.current.getData();
        return linearise? linearizeHierarchy(data,_children,true) :data
    }

    useEffect(() => {
        if(editedStackData && editedStackData.length > 0){
            let data = updateAllElements(editedStackData,_children,_isInSearchResult,true);
            data = updateAllElements(data,_children,_isExpanded,false);
            data = updateIsColumnIsExpandable(data);
            newStackTabulator.current.setData(data);
            if(!props.originalTabulatorData){
                props.setOriginalTabulatorData(newStackTabulator.current.getData());
            }
            let newData = newStackTabulator.current.getData();
            let publishedData = publishedStackTabulator.current.getData();
            for(let e in newData){
                let movedRows = getEmbeddedChildren([newData[e]],_children,_isMoved,true); 
                publishedData = updateAllElementsTree(publishedData, movedRows, _costKey, _children, _isMoved,true,true)
            }
            let linearizedNewData = linearizeHierarchy(newData,_children);//.filter(e => e.costtype === costtype.standard);
            for(let e in linearizedNewData){
                let obj = getEmbeddedChild(publishedData,_children,"returnName",linearizedNewData[e]["returnName"]);
                if(obj!== null){
                    obj['isMoved'] = true;
                }
            }
          
            let linearizedPublishedData = linearizeHierarchy(publishedData,_children);//.filter(e => e.costtype === costtype.standard || e.costtype === costtype.invoicelinetype);
            for(let f in linearizedPublishedData) {
               let returnName  = linearizedPublishedData[f]["returnName"];
               let newDataReturnNames = linearizedNewData.map(e=>e.returnName);
                if(!newDataReturnNames.includes(returnName)){
                        let obj_two = getEmbeddedChild(publishedData,_children,"returnName",returnName);
                        if(obj_two !== null){
                            obj_two["isMoved"] = false;
                        }
                }
            //    let costKey = linearizedPublishedData[f][_costKey];
            //    let path = linearizedPublishedData[f].path.split(",").reverse();
            //    for(let i in path){
            //        if(["",costKey].includes(path[i]))
            //             continue 
            //         let obj = getEmbeddedChild(publishedData,_children,_costKey,path[i]);
            //         if(obj!==null){
            //             updateParentBlueDotReverse(obj);
            //         }
                       
            //    }
            }
            linearizedPublishedData = linearizeHierarchy(publishedData,_children);
            publishedStackTabulator.current.setData(publishedData);
        }
        if(editedStackInfo && !!Object.keys(editedStackInfo).length){
            setVisibility(editedStackInfo.visibility);
            setCurrentManageStack(editedStackInfo);
            props.setManageStack(editedStackInfo);
        }
    },[editedStackData,editedStackInfo]);

    const removeLeafCheckedItems = (checkedItems,psl) => {
        let children = psl && psl.children;
        for(let e in children){
            let child = children[e];
            let costKey =child[_costKey];
            if(child.children){
                checkedItems = checkedItems.filter(e => e.costKey !== costKey);
                removeLeafCheckedItems(checkedItems,child.children)
            } else {
                checkedItems = checkedItems.filter(e => e.costKey !== costKey);
            }
        }
        return checkedItems;
    }

    const updateParentBlueDot = (parentPsl,removedPsl) => {
        let children = getChildren(parentPsl);
        let oneChildIsMoved = false;
        for(let e in children){
            let child = children[e];
            if(removedPsl && removedPsl[_costKey] === child[_costKey]){
                continue;
            }
            if(child[_isMoved]){
                oneChildIsMoved = true;
                break;
            }
        }
        if(!oneChildIsMoved){
            parentPsl[_isMoved] = false;
        } else {
            parentPsl[_isMoved] = true;
        }
        
    }

    // const updateParentBlueDotReverse = (parentPsl) => {
    //     let children = getChildren(parentPsl);
    //     let oneChildIsNotMoved = false;
    //     for(let e in children){
    //         let child = children[e];
            
    //         if(!child[_isMoved]){
    //             oneChildIsNotMoved = true;
    //             break;
    //         }
    //     }
    //     if(oneChildIsNotMoved){
    //         parentPsl["isBlueDot"] = true;
    //     } else {
    //         parentPsl["isBlueDot"] = false;
    //     }
        
    // }

    const getChildren = (psl,result = []) => {
        let children = psl && psl.children;
        if(children) {
            for(let e in children){
                let child = children[e];
                result.push(child);
                if(child.children){
                    getChildren(child,result);
                }
            }
        } else {
            result.push(psl);
        }
        return result;
    }

    const updateIndeterminateStatus = (data,checkedItems,rowData) => {
        let checked = rowData.checked;
        let costKey = rowData.costKey;
        let isExpandable = rowData[showExpandFlag];
        let path = rowData.path.split(",").reverse();
        let selectedPSL =  getEmbeddedChild(data,_children,_costKey,costKey);
        if(checked){
            selectedPSL.checked = true;
            if(isExpandable){
                updateAllElements([selectedPSL],_children,_checked,true);
                updateAllElements([selectedPSL],_children,_indeterminate,false);
            }
            for(let i in path){
                if(["",costKey].includes(path[i])){
                    if(costKey === path[i]){
                        let psl = getEmbeddedChild(data,_children,_costKey,path[i]);
                        let psl_children_costkeys = getChildren(psl).map(e=>e[_costKey]);
                        if(psl_children_costkeys.length === 1 && psl_children_costkeys[0] === psl[_costKey]){ //isLeaf
                            continue;
                        } else {
                            checkedItems = checkedItems.filter(e=>!psl_children_costkeys.includes(e[_costKey]));
                        }
                        // checkedItems = removeLeafCheckedItems(checkedItems,psl);
                        continue;
                    }
                } else {
                    let psl = getEmbeddedChild(data,_children,_costKey,path[i]);
                    if(psl.indeterminate || !psl.checked){
                        let result = []
                        checkChildrenIfChecked(psl,result)
                        if(result.length === 0){
                            psl.checked = true;
                            psl.indeterminate = false;
                            let newstackData = newStackTabulator.current.getData();
                            let linearizedNewStack = linearizeHierarchy(newstackData, _children)
                            let linearizedData = linearizedNewStack.filter(e => e.name === psl.name && psl.costKey === e.costKey)
                            let checkedChildren = newstackData.filter(elt=>elt.name === psl.name && psl.costKey === elt.costKey)
                            if (linearizedData.length > 0){
                                checkedItems.concat(psl.children);
                            }else if(checkedChildren.length > 0) { // when checking all children under an already moved ps line we dont want to move the ps line again so instead we move its children only
                                checkedItems.concat(psl.children);
                            }else {
                                checkedItems.push(psl);
                                let psl_children_costkeys = getChildren(psl).map(e=>e[_costKey]);
                                checkedItems = checkedItems.filter(e=>!psl_children_costkeys.includes(e[_costKey]) )
                            }
                            // checkedItems = removeLeafCheckedItems(checkedItems,psl);
           
                        } else {
                            psl.checked = false;
                            psl.indeterminate = true;
                        }
                    } 
                }
            }
        } else {
            if(isExpandable){
                updateAllElements([selectedPSL],_children,_checked,false);
                updateAllElements([selectedPSL],_children,_indeterminate,false);
            }
            for(let i in path){
                if(["",costKey].includes(path[i])){
                    continue;
                } else {
                    let psl = getEmbeddedChild(data,_children,_costKey,path[i]);
                    if(!psl.indeterminate && psl.checked){
                        psl.indeterminate = true;
                        psl.checked = false;
                        let result = []
                        checkChildrenIfUnChecked(psl,result)
                        checkedItems = checkedItems.concat(result);
                        checkedItems = checkedItems.filter(e=>e.costKey !== psl.costKey);
                    } else {
                        let result = [];
                        checkChildrenIfUnChecked(psl,result)
                        if(result.length === 0){
                            psl.indeterminate = false;
                            psl.checked = false;
                        }
                    }
                }
                
            }
        }
        return [data,checkedItems];
    }
    
    const checkChildrenIfChecked = (psl,result = []) => {
        let children = psl && psl.children || [];
        for(let e in children){
            let child = children[e];
            if(!child.checked){
                result.push(child);
                checkChildrenIfChecked(child.children,result);
            } else{
                if((!child.checked || child.indeterminate)){
                    result.push(child);
                }
            }
        }
    }

    const checkChildrenIfUnChecked = (psl,result=[]) => {
        let children = psl && psl.children || [];
        for(let e in children){
            let child = children[e];
            if(child.children){
                if(child.checked){
                    result.push(child)
                }
                checkChildrenIfUnChecked(child.children);
            } else{
                if(child.checked){
                    result.push(child)
                }
            }
        }
    }

    const onCheckBoxChecked = (e,cell) =>  {
        e.preventDefault();
        let checkPSLs = checkedItemsRef.current; //checkedItems length state was always being 0 while it shouldn't be so i used ref on checkedItems
        let rowData =  cell.getData();
        rowData.checked = e.target.checked;
        let data = publishedStackTabulator.current.getData();
        let dataArray = []
        if(rowData.checked){
            setCheckedItems((oldData) => {
                dataArray = updateIndeterminateStatus(data,[...oldData,rowData],rowData);
                 return  [...dataArray[1]].sort((a,b)=> {return a.rank - b.rank});
            })
        } else {
            dataArray = updateIndeterminateStatus(data,checkPSLs,rowData);
            setCheckedItems(dataArray[1].filter(e=>e.costKey !== rowData.costKey));
        }
        publishedStackTabulator.current.replaceData(dataArray[0]);
        reExpandCollapsedRows(undefined,publishedStackTabulator.current,"publishedStack");
    }
   
    const setTabulator =(tabulator,id)=>{
        if(id === "publishedStack"){
            publishedStackTabulator.current = tabulator;
        } else {
            newStackTabulator.current = tabulator;
            props.setTabulator(newStackTabulator.current);
        }
    }

    const setData = (data) => {
        setNewStackData(data);
    }

    const setRef = (ref,id) => {
        if(id === "publishedStack"){
            publishedStackedRef.current = ref.current;
        } else {
            newStackRef.current = ref.current;
        }
    }
    const setExpandedRowsCostkeys = (id,expandedRowsCostkeys)=> {
        if(id === "publishedStack"){
            expandedRowsCostkeysPublished.current = expandedRowsCostkeys;
        } else {
            expandedRowsCostkeysNew.current = expandedRowsCostkeys;
        }
       
    }
    const fetchChildren = (result) => {
        result[_children].map(result => {
            if (result[_children]) {
                fetchChildren(result);
            }
            result.isMoved = false;
        })
    }
    
    const deleteRow = (cell) => {
        let row = cell.getRow();
        let rowData = row.getData();
        let costKey = rowData[_costKey];
        let movedRows = [];
        movedRows  = getEmbeddedChildren([rowData],_children,_isMoved,true);    
        let publishedStackData = publishedStackTabulator.current.getData();
        
        let removedPsl = getEmbeddedChild(publishedStackData,_children,_costKey,rowData.costKey);
        let pathOfRemovedPSL = removedPsl !== null && removedPsl.path ?  removedPsl.path.split(",").reverse().filter(e=>![removedPsl[_costKey],""].includes(e)): [];

        publishedStackData = updateAllElementsTree(publishedStackData, movedRows, _costKey, _children, _isMoved, false);//Try using updateAllTableElements by updateRow
        // updateAllElementsLine([psl],_children,_checked,false);
        
        if(removedPsl === null) { //Is StandardGroup in new stack
            let children = getChildren(rowData);
            for(let e in children) {
                let child = children[e];
                let psl = getEmbeddedChild(publishedStackData,_children,_costKey,child[_costKey]);
                if(psl !== null){
                    psl[_isMoved] = false;
                    let path = psl.path.split(",").reverse().filter(e=>![psl[_costKey],""].includes(e));
                    for(let e in path){
                        let parent = getEmbeddedChild(publishedStackData,_children,_costKey,path[e]);
                        if(parent !== null){
                            updateParentBlueDot(parent,psl)
                            parent[_indeterminate] = false;
                            parent[_checked] = false;
                        }
                    }
                }
            }
        } else {
            for(let e in pathOfRemovedPSL){
                let parent = getEmbeddedChild(publishedStackData,_children,_costKey,pathOfRemovedPSL[e]);
                if(parent !== null){
                    updateParentBlueDot(parent,removedPsl)
                    parent[_indeterminate] = false;
                    parent[_checked] = false;
                }
            }
        }
       
        setStackIsChanged(true);
        isStackChanged.current = true;
        updateRedDotState(newStackTabulator.current.getData());
        toggleSaveButton();
        publishedStackTabulator.current.replaceData(publishedStackData);
        reExpandCollapsedRows(undefined,publishedStackTabulator.current,"publishedStack")
    }

    
    const setDeletedCellFromChild = (value) => {
        setDeletedCell(value)
    }
    const setProfitStackFields = (data) => {
        setNewStackData(data)
    }

    const  reExpandCollapsedRows = (rows, tabulator,id) => {
        var allRows = rows || tabulator.getRows();
        allRows.forEach(function (row) {
            if (row._row.modules.dataTree) {
                let isExpanded = row._row.modules.dataTree.open;
                if (id === "publishedStack" && expandedRowsCostkeysPublished.current.indexOf(row.getData()[_costKey]) !== -1 && !isExpanded)
                {
                    //!isExpanded - if row is already expanded, do not retry to expand
                    row.treeExpand();
                    reExpandCollapsedRows(row.getTreeChildren(), tabulator,id);
                }
                if (id === "newStack" && expandedRowsCostkeysNew.current.indexOf(row.getData()[_costKey]) !== -1 && !isExpanded) 
                {
                    //!isExpanded - if row is already expanded, do not retry to expand
                    row.treeExpand();
                    reExpandCollapsedRows(row.getTreeChildren(), tabulator,id);
                }
            }
        })
    }

    /**
     * to expand the filtered line with all his parents
     * @param {*} rows 
     * @param {*} tabulator 
     */
    const reExpandChildsTree = (rows, tabulator) => {
        var allRows = rows || tabulator.getRows();
        allRows.forEach(function (row) {
            if (row._row.modules.dataTree) {
                if (copyExpandedRowsCostkeysNew.current.indexOf(row.getData()[_costKey]) !== -1){
                    row.treeExpand();
                    reExpandChildsTree(row.getTreeChildren(), tabulator);
                }
            }
        })
    }
    const emptyCurrentManageStacks = () => {
        setCurrentManageStack({})
    }
    useEffect(() => {
        if(checkedItems && prevCheckedItems && JSON.stringify(checkedItems) !== JSON.stringify(prevCheckedItems)){
            checkedItemsRef.current = checkedItems;
        }
    }, [checkedItems]);

    const setCellFromChild=(cell)=>{
        setCell(cell);
    }

    const getNewStackData=()=>{
        return newStackTabulator && newStackTabulator.current != null? linearizeHierarchy(newStackTabulator.current.getData(), _children): [];
    }

    const customTreeFilter=(rowData, filterParams)=> {
        if (filterParams.values.indexOf(rowData[filterParams.fieldKey]) >= 0) {
            return true;
        }
        if (rowData[filterParams.childrenKey] && rowData[filterParams.childrenKey].length > 0) {
            for (var index in rowData[filterParams.childrenKey]) {
                //let parent = rowData[filterParams.fieldKey][index];
                let child = rowData[filterParams.childrenKey][index];
                if (filterParams.values.indexOf(child[filterParams.fieldKey]) >= 0) {
                    return true;
                }
                if (child[filterParams.childrenKey] && child[filterParams.childrenKey].length > 0) {
                    let found = customTreeFilter(child, filterParams);
                    if (found) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    /**
     * recursive function to get all the parents of a row child from the leaf to the main level
     * @param {*} rows 
     * @param {*} parents 
     * @returns 
     */
    const getAllParents =(rows, parents)=>{
        for(var row in rows) {
            if(rows.length>1){
                row = rows[row];
            }else{
                row = rows;
            }
            let parent = row.getTreeParent();
            if(parent && !parents.includes(parent.getData()[_costKey])){
                parents.push(parent.getData()[_costKey]);
                getAllParents(parent, parents);
            }
        }
        return parents;
    }
  
    /**
     * recursive function to get the path of a child in the new stack table 
     * @param {*} rows 
     * @param {*} costKey 
     * @param {*} path 
     * @returns 
     */
    const getChildPath = (rows, costKey, path)=>{
        for(var row in rows) {
            row = rows[row];
            let data = row.getData();
            if(Number(data[_costKey].replace("Insert","")) === costKey) {
                path = getAllParents(row,[]);
                return path;
            } else if(!path && row.getElement() && row.getTreeChildren()){
                path = getChildPath(row.getTreeChildren(), costKey, path);
                if(path){
                    return path;
                }
            }
        }
        return path;
    }

    /**
     * change the state of has filter when filtering or removing filter
     * @param {*} val 
     */
    const setHasFilter =(val)=>{
        setFilter(val);
        hasFilter.current = val;
    }

    /**
     * filter data and expand the selected line when clicking on the filter icon in the published table
     * @param {*} rowData 
     */
    const filterData=(rowData)=>{
        if(hasFilter.current && filteredCostKey.current === rowData[_costKey] ){
            setHasFilter(false);
            newStackTabulator.current.clearFilter();
            return;
        }
        filteredCostKey.current = rowData[_costKey];
        // let path = rowData.path.split(",").filter(e=>e);// get the path from the child to the main parent 
        let path = getChildPath(newStackTabulator.current.getRows(),Number(rowData[_costKey],[]));// get position in the stacks table
        path.push(rowData[_costKey]);
        let links = path;
        var stringLinks = [];
        links.forEach(function(link){// stringify the items in the array for the setFilter function
            stringLinks.push(link.toString());
        });
        setHasFilter(true);
        copyExpandedRowsCostkeysNew.current = path;// the lines that must be expanded
        newStackTabulator.current.setFilter(customTreeFilter, {childrenKey: _children, fieldKey: _costKey, "values": stringLinks});
        reExpandChildsTree(undefined,newStackTabulator.current);
    }
   

    const moveLines=(cell, addLevel, rowData) =>{
        if(cell){
            setCell(cell);
        }
        let checkedPSLs = checkedItemsRef.current;//saved state in ref (checkedItems)
        if(checkedPSLs.length > 0){
            updateAllElements(checkedPSLs,_children,_isMoved,true);
            let checkedItems = copyObjectValues(checkedPSLs);
            let publishedStackData = publishedStackTabulator.current.getData();
            for(let e in checkedPSLs){
                let psl = getEmbeddedChild(publishedStackData,_children,_costKey,checkedPSLs[e].costKey);
                if(psl !== null){
                    let pslCostKey = psl.costKey;
                    let path = psl.path;
                    let splittedPath = path.split(",");
                    if(splittedPath.length > 2){
                        for(let e in splittedPath){
                            if(pslCostKey === splittedPath[e] || splittedPath[e] === ""){
                                continue;
                            }
                            let parentPsl = getEmbeddedChild(publishedStackData,_children,_costKey,splittedPath[e]);
                            if(parentPsl !== null){
                                parentPsl[_checked] = false;
                                parentPsl[_isApplicableForMoving] = false;
                                parentPsl[_isMoved] = true;
                            }
                        }
                    }
                    updateAllElements([psl],_children,_isMoved,true);
                    // updateAllElements([psl],_children,"isBlueDot",false);
                    if(addLevel){
                        let toAddLevel = (rowData.column_level - checkedItemsRef.current[e].column_level)+1;
                        addLevelForAll([checkedItemsRef.current[e]],_children,"column_level",toAddLevel);// destination level (in new stack table) - old level (in the published) +1
                        addLevelForAll([checkedItemsRef.current[e]],_children,"level",toAddLevel);// destination level (in new stack table) - old level (in the published) +1

                    }
                    updateAllElements([psl],_children,_checked,false);
                }
            }
            setCheckedItems([]);
            setDeletedCell(undefined);
            setNewStackData(checkedItems);

            if(currentManageStack && currentManageStack.name && isValidName.current && !updateRedDotState(newStackTabulator.current.getData())){
                setStackIsChanged(true); 
                isStackChanged.current = true;
            }else{
                setStackIsChanged(true); 
                isStackChanged.current = true;
            }
            updateRedDotState(newStackTabulator.current.getData())
            toggleSaveButton();
            checkedItemsRef.current = [];
            publishedStackTabulator.current.replaceData(publishedStackData);
            newStackTabulator.current.replaceData(newStackTabulator.current.getData());
            reExpandCollapsedRows(undefined,publishedStackTabulator.current,"publishedStack");
            reExpandCollapsedRows(undefined,newStackTabulator.current,"newStack");
            newStackTabulator.current.setFilter(newStackTabulator.current.getFilters());
      
            // if(newStackRef.current.getIsAllExpanded()){
                // newStackRef.current.expandCollapseAll();
                // newStackRef.current.expandCollapseAll();
            // }
        }  
    }

    const updateRedDotState = (data) => {
        for (var e in data) {
            if (isRedDot(data[e]) === true) {
                setRedDot(true);
                isRedDotRef.current = true;
                // setStackIsChanged(true);
                return true;
            }
        }
        setRedDot(false);
        isRedDotRef.current = false;
        return false;
    }

    /**
     * function returns value of current component
     * @param value 
     * @returns 
     */

    useEffect(() => {
        toggleSaveButton(true);
        initialVisibility.current = currentManageStack.visibility
        // props.setCurrentRef(ref);
    }, [])

    let current_ManageStack = currentManageStack
    const handleChange = (e,value, attr)=>{ 
        if (attr !== MANAGE_STACKS.FIELDS.VISIBILITY){
            current_ManageStack[attr] = value;
        }
        let array = [];
        let tempName = "";
        let tempVisibility = "";
        if (attr === MANAGE_STACKS.FIELDS.NAME) {
            $("#stack_title_newStack").text(value);
            if (value && value !== "")  {
                setName(value);
                tempName = value;

                let isRedDottt = false;
                newStackTabulator.current.getData().forEach(function (row) {
                    if(isRedDot(row)){
                        isRedDottt = true;
                    }
                })
            }   
        }
        if (attr === MANAGE_STACKS.FIELDS.VISIBILITY) {
            if(visibilityOptions[0].isDisabled || visibilityOptions[1].isDisabled ||( !editStack && (!isCompanyValid || !isUserValid ))){
                return;
            }
            if((isCompanyValid || !visibilityOptions[1].isDisabled ) && value ==="company" ){
                setVisibility(value)
                tempVisibility = value
                current_ManageStack[attr] = value;
            } else if ((isUserValid || !visibilityOptions[0].isDisabled) && value ==="user"){
                if (current_ManageStack[attr] === "company" && value.toLowerCase() === "user") {
                    current_ManageStack["accessChanged"] = true;
                }
                setVisibility(value)
                tempVisibility = value
                 current_ManageStack[attr] = value;
            }
        }
        tempName = !!tempName ? tempName : $("#stack_title_newStack").text();
        array = tableData.filter(e => e.name.toLowerCase() === (tempName && tempName.toLowerCase() || name && name.toLowerCase()) && e.visibility === (tempVisibility || visibility));
        if (current_ManageStack.name) {
            if (array.length > 0) {
                if(editStack && current_ManageStack.name === originalStackInfo.name && current_ManageStack.visibility === originalStackInfo.visibility) {
                    setIsNameValid(true);
                    isValidName.current = true;
                } else {
                    setIsNameValid(false);
                    isValidName.current = false;
                }
                
            } else {
                setIsNameValid(true);
                isValidName.current = true;

            }
            setEmptyName(false);
            isEmptyName.current = false;
        }else{
            if (name === "" || tempName === "") {
                setEmptyName(true);
                isEmptyName.current = true;
            }
        }
        setStackIsChanged(true);
        isStackChanged.current = true;
        updateRedDotState(newStackTabulator.current.getData());
        toggleSaveButton();
        setCurrentManageStack(current_ManageStack);
        props.setManageStack(current_ManageStack);
    }

    const onBackValidation = (fromRender) =>{
        if (isStackChanged.current) {
            if ((emptyName || isEmptyName.current ) || (!isnameValid || !isValidName.current) || redDot || newStackTabulator.current.getData().length === 0) {
                if (fromRender) {
                    return true;
                }
                openManageStacksDialogDisabled(true);
                return;
            }else{
                if (!fromRender){
                    checkCompatibilityBeforeSave(true, openManageStacksDialog);
                    // openManageStacksDialog();
                }
                return;
            }
        }
        if(!fromRender){
            changeAddStack(false,false,true);
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (newStackTabulator.current.getData().length > 0) {
            newStackTabulatorData.current = newStackTabulator.current.getData();
        }
    }, [editedStackData])

    // useEffect(() => {
    //         publishedStackTabulator.current.setData(props.data);
    //         // publishedStackTabulatorData.current = publishedStackTabulator.current.getData();
    // }, [originalStackInfo])

    const onResetStacksClick = () =>{
        setOpenResetDialog(true);
    }

    const onChangeDiscard = (isChanged) =>{
        isStackChanged.current = isChanged;
        setStackIsChanged(isChanged);
    }

    const onDiscard = () => {

        inputRefs.reset();
        inputRefsDescription.reset();
        if(publishedStackedRef.current.getIsAllExpanded()) {
            publishedStackedRef.current.expandCollapseAll();
        }
        if(newStackRef.current.getIsAllExpanded()) {
            newStackRef.current.expandCollapseAll();
        }
        newStackRef.current.clearFilter();
        if (editStack) {
            let publishedData = publishedStackTabulator.current.getData();
            publishedData = updateAllElements(publishedData, _children, _isMoved, false);
            originalProfitStackFields.forEach(child => {
                let movedRows = getEmbeddedChildren([child], _children, _isMoved, true);
                publishedData = updateAllElementsTree(publishedData, movedRows, _costKey, _children, _isMoved, true)
            });
            updateAllElements(publishedData,_children,_indeterminate,false);
            updateAllElements(publishedData,_children,_checked,false);
            // updateAllElements(publishedData,_children,"checked",false);
            // $(".moveLineFirstLevel").css("display", "none");
            // $(".moveLine").css("display", "none");
            // $(".addGroupButton").css("display", "block");
            // checkedItemsRef.current = [];
            newStackTabulator.current.setData(copyObjectValues(newStackTabulatorData.current));
            publishedStackTabulator.current.setData(publishedData);
            let tempCurrentStack = currentManageStack;
            tempCurrentStack.name = originalStackInfo.name;
            tempCurrentStack.description = originalStackInfo.description;
            tempCurrentStack.visibility = originalStackInfo.visibility;
            setVisibility(tempCurrentStack.visibility);
            setCurrentManageStack(tempCurrentStack);
            $('#form_input_stackName').text(currentManageStack.name);
            $('#form_input_stackDescription').text(currentManageStack.description);
            setNewStackData(copyObjectValues(newStackTabulatorData.current));
            setStackIsChanged(false);
            isStackChanged.current = false;
            isEmptyName.current = false;
            setEmptyName(false);
            setIsNameValid(true);
            isValidName.current = true;
            toggleSaveButton();
            $(".moveLineFirstLevel").css("display", "none");
            $(".moveLine").css("display", "none");
            $(".addGroupButton").css("display", "block");
        } else {
            $("#stack_title_newStack").empty()
            setNewStackData([]);
            newStackTabulator.current.setData([]);
            publishedStackTabulator.current.setData(props.data);
            let tempCurrentStack = currentManageStack;
            tempCurrentStack.name = "";
            tempCurrentStack.description = "";
            tempCurrentStack.visibility = initialVisibility.current;
            setVisibility(tempCurrentStack.visibility);
            setCurrentManageStack(tempCurrentStack);
            $('#form_input_stackName').text("");
            $('#form_input_stackDescription').text("");
            setStackIsChanged(false);
            isStackChanged.current = false;
            isEmptyName.current = true;
            setEmptyName(true);
            toggleSaveButton();
        }
        setCheckedItems([]);
        setOpenResetDialog(false);
    }

    const openManageStacksDialog=()=> {
        setOpen(true)
    }

    const openManageStacksDialogDisabled=()=> {
        setOpendisabled(true)
    }


    const closeManageStacksDialog=()=>{
        setOpen(false);
    }

    const closeManageStacksDialogDisabled=()=>{
        setOpendisabled(false);
    }

    const closeManageStacksDialogReset=()=>{
        setOpenResetDialog(false);
    }

    const onButtonNo=()=>{
      props?.replaceManageStacksTableData();
      changeAddStack(false,false,true);
    }

    const onButtonYes=()=>{
        saveStacks() ;
    }
    const hasAccess = (visibility) =>{
        return (visibility === "user" && isUserAllowed && !isUserLimitReached) || (visibility === "company" && isCompanyAllowed && !isCompanyLimitReached);
    }

   const  manageStacksDialogBodyContent = () => {
       let incompatibleScenarios = manageStacksDialogBodySave(true);
       if ( incompatibleScenarios && incompatibleScenarios.length > 0) {
           return (
                   <div className='uk-border-rounded' >
                       <span className='fs-16'>{lang.manage_stacks.discard}</span>
                       <div className='fs-16 uk-margin-top uk-margin-bottom'>{lang.manage_stacks.invalid_scenarios}</div>
                       <div className='fs-16 uk-margin-bottom'>{incompatibleScenarios.map(e => { return (<h5 key={e}>{e}</h5>)})}</div>
                       <span className='fs-16'>{lang.manage_stacks.save_first}</span>
                   </div>
           )
       } else {
           return (
                <span className='fs-16'>{lang.manage_stacks.save_before_discard}</span>
           )
       }
    }
    const manageStacksDialogBodyDisabled = () => {
        return (
            <span className='fs-16'>{lang.manage_stacks.must_be_fully_configured}</span>
        )
    }

    const validateStack=()=>{
        if (isStackChanged.current) {
            if ((isEmptyName.current ) || !isValidName.current || isRedDotRef.current || newStackTabulator.current.getData().length === 0) {
                return true;
            }else{
                return false;
            }
        }
        return true;
    }
    const manageStacksDialogBodyReset = () => {
        return (
            <div>
                <span className='fs-16'>This action will discard your unsaved changes</span>
            </div>
        )
    }
    const dialogActionsSaveDisabled = () =>{
        return(
        <>
            <Button 
                label={"Yes"}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                aria-label="Close"
                className={"uk-padding-small-right"}
                onBtnClick={onButtonYes}
            />
            <Button 
                label={"No"}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                aria-label="Close"
                className="uk-padding-small-right"
                onBtnClick={onButtonNo}
            />
            <Button 
                label={lang.modal.buttons.cancel}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                aria-label="Close"
                className="uk-padding-small-right"
                onBtnClick={closeManageStacksDialog}
            />
            
        </>
        )
    }
    const dialogActionsNotDisabled = () =>{
        return(
            <>
                <Button 
                    label={"Continue"}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    className={"uk-padding-small-right"}
                    onBtnClick={onButtonNo}
                />
                <Button 
                    label={lang.modal.buttons.cancel}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    aria-label="Close"
                    className="uk-padding-small-right"
                    onBtnClick={closeManageStacksDialogDisabled}
                />
            </>
        )
    }
    const dialogActionsReset = () =>{
        return(
        <>
            <Button 
                label={"Discard"}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                aria-label="Close"
                className={"uk-padding-small-right"}
                onBtnClick={onDiscard}
            />
            <Button 
                label={lang.modal.buttons.cancel}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                aria-label="Close"
                className="uk-padding-small-right"
                onBtnClick={closeManageStacksDialogReset}
            />
        </>
        )
    }   
    
    useImperativeHandle(ref, () => ({
        onBackValidation: (fromRender) => {
         return onBackValidation(fromRender);
        },
        getStackIsChanged:()=>{
            return stackIsChanged;
        },
        validateStack:()=>{
            return validateStack();
        },
        onResetStacksClick: () => {
            return onResetStacksClick()
        },
      }));
    
        return(
            <div className="uk-margin-default">

                <div className="uk-flex uk-margin-xmedium-top-bottom " >
                    <div className="uk-width-medium uk-margin-default-right">
                        <label htmlFor={DASHBOARDS.NAME_ID} className="fs-14 uk-margin-xsmall-bottom">{lang.manage_stacks.stack_name}<span className="input_required_text">{"(Required)"}</span></label>  
                        <FormComponent id="form_input_stackName" ref={r=>inputRefs = r} tag="input" className="uk-input" placeholder={lang.manage_stacks.stack_name_placeholder}
                            onChange={(e,name)=>{handleChange(e,name, MANAGE_STACKS.FIELDS.NAME)}} value={currentManageStack ? currentManageStack.name : ""}
                        />
                        {isValidName.current && emptyName === false ? ""
                            :
                            !isValidName.current && !emptyName ? <p className="red italic uk-margin-default-top uk-text-medium">Name already exists</p>
                                :
                                emptyName ? <p className="red italic uk-margin-default-top uk-text-medium">Name is empty</p>
                                    :
                                    null
                        }
                    </div>
                   <div>
                        <h5 className="uk-margin-xsmall-bottom" >{lang.manage_columns.titles.visibility}</h5>
                            <span style={{display:"block", width:"10vw"}}>
                                <ToggleTab options={visibilityOptions}
                                           mode={"light"}
                                onSelectTab={(visibility)=>handleChange(undefined, visibility, MANAGE_STACKS.FIELDS.VISIBILITY)}
                                defaultValue={editStack && originalStackInfo && originalStackInfo.visibility === visibility  ? visibility : isCompanyValid && isUserValid ? currentManageStack.visibility : isUserValid ? visibilityOptions[0].value : visibilityOptions[1].value }
                                />
                            </span>
                    </div>
                </div>
                <div className="uk-width-1-4 uk-margin-xmedium-top-bottom form_input_stackDescription_container">
                    <label htmlFor={DASHBOARDS.DESCRIPTION_ID} className="fs-14 uk-margin-xsmall-bottom">{lang.manage_stacks.stack_description}<span className="input_required_text_disabled">{"(Optional)"}</span></label>  
                    <FormComponent id="form_input_stackDescription" ref={r=>inputRefsDescription = r} tag="textarea" className="uk-textarea" placeholder={lang.manage_stacks.stack_description_placeholder}
                        onChange={(e,desc)=>{handleChange(e,desc, MANAGE_STACKS.FIELDS.INPUT_DESCRIPTION)}} value={currentManageStack ? currentManageStack.description : ""}
                    />
                </div>
            
                <Modal 
                    id={"unsaved-changes-manage-stacks-dialog"}
                    openDialog={openDialog}
                    closeClick={closeManageStacksDialog}
                    bodyContent={manageStacksDialogBodyContent}       
                    dialogActions={dialogActionsSaveDisabled}
                    size={DIALOG_SIZE.MEDIUM}
                />
                <Modal 
                    id={"disabled-save-manage-stacks-dialog"}
                    openDialog={openDialogDisabled}
                    closeClick={closeManageStacksDialogDisabled}
                    bodyContent={manageStacksDialogBodyDisabled}       
                    dialogActions={dialogActionsNotDisabled}
                    size={DIALOG_SIZE.MEDIUM}
                />
                <Modal 
                    id={"reset-changes-manage-stacks-dialog"}
                    openDialog={openResetDialog}
                    closeClick={closeManageStacksDialogReset}
                    bodyContent={manageStacksDialogBodyReset}       
                    dialogActions={dialogActionsReset}
                    size={DIALOG_SIZE.MEDIUM}
                />
               
              
                <div className="psm-container uk-flex">
                    <div className="uk-width-3-7">
                        <StackTabulator id="publishedStack" tabId={"table_left"} ref={publishedStackedRef} columns={props.columns.publishedStackColumns} filterData={filterData} data={props.data? JSON.parse(props.data).filter(e=>!e.exclude_from_profitstack):[]} addCheckboxes={true} title={lang.manage_stacks.published_profit_stack_title} index={2} checkedItems={checkedItems} onCheckBoxChecked={onCheckBoxChecked} setTabulator={setTabulator} setRef={setRef} movableRows={false} reExpandCollapsedRows={reExpandCollapsedRows} setExpandedRowsCostkeys={setExpandedRowsCostkeys} getNewStackData={getNewStackData}/>
                    </div>
                    <div className="uk-margin-left uk-width-3-7">
                        <StackTabulator id="newStack" tabId={"table_right"} ref={newStackRef} columns={[{ field: PS_MAPPING.FIELDS.HANDLE_ROW, title: "", rowHandle: true, formatter: "handle", headerSort: false, /*frozen: true,*/ width: 30, minWidth: 30 }, { field: PS_MAPPING.FIELDS.ACTION, title: "", headerSort: false, /*frozen: true,*/ width: 30, minWidth: 30 }, { title: "", field: PS_MAPPING.FIELDS.ADD }].concat(props.columns.stackColumns)} data={newStackData} addCheckboxes={false} title={editStack && editedStackInfo && editedStackInfo.name?editedStackInfo.name:""} addHeaderTitle={true} index={3} checkedItems={checkedItems} 
                            moveLines={moveLines} setTabulator={setTabulator} cell={cell} setCellFromChild={setCellFromChild} setRef={setRef} movableRows={true} setProfitStackFields={setProfitStackFields} reExpandCollapsedRows={reExpandCollapsedRows} 
                            setExpandedRowsCostkeys={setExpandedRowsCostkeys}  deleteRow={deleteRow} toggleSaveButton={toggleSaveButton} redDot={redDot} setRedDot={setRedDot} deletedCell={deletedCell} setDeletedCellFromChild={setDeletedCellFromChild} 
                            setData = {setData} currentManageStack={currentManageStack} isnameValid={isnameValid || isValidName.current} updateRedDotState={updateRedDotState} scenarioState={props.scenarioState}
                            getNewStackData={getNewStackData} formats ={props.formats} setStackIsChanged={setStackIsChanged} onBackValidation={onBackValidation} hasFilter={filter} setHasFilter={setHasFilter} onChangeDiscard={onChangeDiscard} getPublishedStackTabulator={getPublishedStackTabulator} />                            
                    </div>
                </div>
            </div>
        );
})
export default StackConfiguration