import { useFeatureFlagEnabled } from "posthog-js/react";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { updateCustomGroups } from "../../actions/manageColumnsActions";
import { getEmbeddedChild } from "../../class/array";
import {
    ALL_WIDGETS,
    BUTTON_TYPE,
    BUTTON_VARIANT,
    COLUMN_PROFILE,
    DIALOG_SIZE,
    DROPDOWN_TYPE,
    FILTER,
    MANAGE_COLUMNS,
    PROFILE_COLUMN,
    SIZES
} from "../../class/constants";
import { checkStartsWithDigit, replaceSpecialChars } from "../../class/string";
import { capitalizeFirstLetter, findOptionByKey, tryParse } from "../../class/utils";
import { ToggleTab } from "../../form/elements";
import { FormComponent } from "../../form/FormElements";
import { lang } from "../../language/messages_en";
import Button from "../../newComponents/Button";
import DropDown from "../../newComponents/DropDown";
import Modal from "../../newComponents/Modal";
import FilterDialog from "../../sections/filter/FilterDialog";
import { ReactComponent as MultipleFilters } from "../../styles/images/icons/multiplefilters.svg";
import ClientFormat from "../ClientFormat";
import Backdrop from "../sidePanelNew/Backdrop";
import SidePanelNew from "../sidePanelNew/SidePanelNew";
import './AddColumn.css';
import ManageColumnsFormat from "./ManageColumnsFormat";

const AddColumn = (
    {
        column,
        profileColumns,
        customGroups,
        isEditing,
        formats,
        vectorsList,
        groups,
        lineAttributeOptions,
        profitStackLineColumns,
        profitFormat,
        visibilityOptions,
        customGroupFields,
        access,
        user,
        clearManageColumnsFromStore,
        checkVisibility,
        scenarioId,
        invalidFormula,
        maxCharacterLength,
        ...props
    },
    ref
) => {
    useImperativeHandle(ref, () => ({
        setIsSaving: (isSaving) => {
            setIsSaving(isSaving);
        },
        drawerToggleClickHandler: () => {
            setDrawerOpen(!isDrawerOpen);
        },
        handleBackDropClick: () => {
            closePanels();
        },
    }));

    const _cpcf = "_cpcf";
    const defaultValidations = {
        nameError: "",
        columnTypeError: "",
        customGroupError: "",
        formatError: "",
        formulaError: "",
    };

    const defaultColumnValues = {
        visibility: COLUMN_PROFILE.VISIBILITY_OPTIONS.USER,
        column_type: MANAGE_COLUMNS.VALUES[0],
        column_group: "vector",
        column_type_line: "",
        column_group_line: "",
        accesschanged: false,
    };
    const [createdColumn, setCreatedColumn] = useState(column);
    const [nameCharactersLength, setNameCharactersLength] = useState(maxCharacterLength);
    const [validations, setValidations] = useState(defaultValidations);
    const [profilesUsingCreatedColumn, setProfilesUsingCreatedColumn] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [nameWasEdited, setNameWasEdited] = useState(false);

    const dispatch = useDispatch();
    const nameRef = useRef();
    const clientFormatRef = useRef();
    const descriptionRef = useRef();
    const manageColumnsFormatRef = useRef();
    const columnFilterRef = useRef();

    const useColumnFilters = useFeatureFlagEnabled('use_column_filter');

    /**
     * This useEffect is used to clear the filter dialog rows when we add a new column.
     * When it's a new column, 'column' props is empty.
     */
    useEffect(() => {
        if(Object.keys(column).length === 0 && !createdColumn?.column_filter) {
            // clear filter dialog for new columns
            columnFilterRef.current?.onChangeSavedFilter("[]", true);
        }
    }, [column]);

    /**
     * This useEffect is fired when we edit a column.
    */
    useEffect(() => {
        if (isEditing) {
            onEditClick();
            setCreatedColumn(column);
            setNameCharactersLength(maxCharacterLength - column.column_name.length);
        } else {
            resetPanelInputs();
            setCreatedColumn(defaultColumnValues);
        }

        resetStatesToDefault();
    }, [isEditing]);

    useEffect(() => {
        setIsSaving(false);
        if (invalidFormula) {
            manageColumnsFormatRef?.current.formulaRef.current?.setFormulaValidation();
        }
    }, [invalidFormula]);

    const resetPanelInputs = () => {
        nameRef.current?.reset();
        descriptionRef.current?.reset();
        clientFormatRef.current?.setSelectedFormat();
        manageColumnsFormatRef.current?.resetInputs();
        setNameCharactersLength(maxCharacterLength);
        setCreatedColumn(defaultColumnValues);
        resetStatesToDefault();
        if(props.resetInputs) {
            props.resetInputs(false);
        }
    };

    const resetStatesToDefault = () => {
        setProfilesUsingCreatedColumn([]);
        setValidations(defaultValidations);
        clientFormatRef.current?.removeValidationError();
    };

    const onEditClick = () => {
        if (columnFilterRef.current && column?.column_filter) { // set filter value in the dialog
            columnFilterRef.current?.onChangeSavedFilter(JSON.stringify(column?.column_filter), true);
        }

        if (props.useNewAddColumn && column.column_type === MANAGE_COLUMNS.CALCULATED_PSL_LINE) {
            manageColumnsFormatRef?.current.formulaRef?.current.addValuesToInput(tryParse(column.formula.formula));
        } else {
            manageColumnsFormatRef.current?.calculatedPslRef?.setState({
                pslFormula: tryParse(column.formula),
                formula_unchanged: tryParse(column.formula),
            });
        }

        manageColumnsFormatRef.current?.handleChange({
            value: !!MANAGE_COLUMNS.GROUPS[column.column_group] ? MANAGE_COLUMNS.GROUPS[column.column_group] : column.column_group,
        });
        manageColumnsFormatRef.current?.handleChangeOptions(
            { value: column.column_group_line, label: column.column_group_line },
            !!MANAGE_COLUMNS.GROUPS[column.column_group] ? MANAGE_COLUMNS.GROUPS[column.column_group] : column.column_group
        );
        if (column.column_type === MANAGE_COLUMNS.PER_VECTOR) {
            manageColumnsFormatRef.current?.vectorHandleChange({ value: column.column_type_line, label: column.column_type_line });
        } else {
            manageColumnsFormatRef.current?.lineAttributeHandleChange({ value: column.column_type_line, label: column.column_type_line });
        }
    };

    const isNameUnique = (name) => {
        var data = customGroupFields.filter((f) => f.column_profile_custom_field_id !== createdColumn?.column_profile_custom_field_id);
        let userCond = data.filter(
            (e) =>
                (e[PROFILE_COLUMN.DISPLAY_NAME].toLowerCase() === name.toLowerCase() &&
                    e[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER &&
                    Number(e[COLUMN_PROFILE.CREATED_BY]) === Number(user.userId) &&
                    Number(e[PROFILE_COLUMN.COLUMN_FIELD_ID]) !== Number(createdColumn.column_profile_custom_field_id)) ||
                (access.view_company_cols &&
                    e[PROFILE_COLUMN.DISPLAY_NAME].toLowerCase() === name.toLowerCase() &&
                    e[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.COMPANY &&
                    Number(e[PROFILE_COLUMN.COLUMN_FIELD_ID]) !== Number(createdColumn.column_profile_custom_field_id))
        );
        let cond = data.filter(
            (e) =>
                (e[PROFILE_COLUMN.DISPLAY_NAME].toLowerCase() === name.toLowerCase() &&
                    e[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.COMPANY &&
                    access.view_company_cols &&
                    Number(e[PROFILE_COLUMN.COLUMN_FIELD_ID]) !== Number(createdColumn.column_profile_custom_field_id)) ||
                (e[PROFILE_COLUMN.DISPLAY_NAME].toLowerCase() === name.toLowerCase() &&
                    e[COLUMN_PROFILE.VISIBILITY] === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER &&
                    Number(e[COLUMN_PROFILE.CREATED_BY]) === Number(user.userId) &&
                    Number(e[PROFILE_COLUMN.COLUMN_FIELD_ID]) !== Number(createdColumn.column_profile_custom_field_id))
        ); // name already exists in company
        if (createdColumn.visibilitySelected === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER) {
            if (userCond.length > 0) {
                return false;
            }
        } else {
            if (cond.length > 0) {
                return false;
            }
        }
        return true;
    };

    const handleFieldChange = (eventOrValue, field) => {
        if (field === MANAGE_COLUMNS.FIELD_TYPE) {
            setCreatedColumn({...createdColumn, column_type: eventOrValue.value});
            manageColumnsFormatRef.current?.resetInputs();
        }
        if (field === MANAGE_COLUMNS.FIELD_NAME) {
            let value = $(eventOrValue.currentTarget).val();
            let nameError = "";
            setNameWasEdited(true);
            if (!!value) {
                if (!isNameUnique(value)) {
                    nameError = lang.manage_access.name_already_used;
                }
                if (checkStartsWithDigit(value)) {
                    nameError = lang.name_starting_number;
                }
            }
            setValidations({...validations, nameError: nameError});
            setCreatedColumn({...createdColumn, column_name: value});
        }
        if (field === MANAGE_COLUMNS.FIELD_DESCRIPTION) {
            let value = $(eventOrValue.currentTarget).val();
            setCreatedColumn({...createdColumn, column_description: value});
        }
        if (field === MANAGE_COLUMNS.FIELD_GROUP) {
            setCreatedColumn({...createdColumn, customGroup: eventOrValue});
            setValidations({...validations, customGroupError: ""});
        }
        setHasUnsavedChanges(true)
    }

    const handleNameCharacterLength = (e) => {
        setNameCharactersLength(maxCharacterLength - e.target?.value.length);
    };

    const validateNameAndReturnError = (name) => {
        if (name === undefined || name === "") {
            return lang.manage_access.name_empty;
        }
        if (nameWasEdited && name && !isNameUnique(name)) {
            return lang.manage_access.name_already_used;
        }
        if (name && checkStartsWithDigit(name)) {
            return lang.name_starting_number;
        }

        if(name.length > maxCharacterLength) {
            return lang.manage_columns.text.name_surpasses_limit.replace("[X]", maxCharacterLength);
        }
        return "";
    };

    const validateCustomGroupAndReturnError = (customGroup) => {
        if (customGroup === undefined || !customGroup.value) {
            return lang.manage_columns.custom_empty;
        }

        return "";
    };

    const validateFormulaAndReturnError = () => {
        if(manageColumnsFormatRef.current?.formulaRef.current?.isFormulaEmpty()){
            return lang.manage_columns.formula_empty;
        }
            return "";
    };

    const validateFormat = () => {
        return clientFormatRef.current?.validateFields(createdColumn.format_type_id);
    };

    const validateFields = () => {
        let canSave = true;
        let nameError = "";
        let customGroupError = "";
        let formulaError = "";
        let formatError = "";

        nameError = validateNameAndReturnError(createdColumn.column_name);
        customGroupError = validateCustomGroupAndReturnError(createdColumn.customGroup);
        formulaError = validateFormulaAndReturnError();
        let isFormatValid = validateFormat();

        if (!manageColumnsFormatRef.current.validateFields()) {
            canSave = false;
        }

        canSave = canSave && nameError === "" && customGroupError === "" && formulaError === "" && isFormatValid;

        setValidations({
            ...validations,
            nameError: nameError,
            customGroupError: customGroupError,
            formatError: formatError,
            formulaError: formulaError,
        });

        return canSave;
    };

    const handleVisibilityChange = (newVisibility) => {
        let accesschanged = false;
        if (
            createdColumn.visibility.toLowerCase() === COLUMN_PROFILE.VISIBILITY_OPTIONS.COMPANY &&
            newVisibility.toLowerCase() === COLUMN_PROFILE.VISIBILITY_OPTIONS.USER
        ) {
            accesschanged = true;
        }

        let nameError = "";
        if (createdColumn.column_name && !isNameUnique(createdColumn.column_name)) {
            nameError = lang.manage_access.name_already_used;
        }
        let profiles = checkVisibility(newVisibility, true);
        // let visibilityCheckedMessage = lang.dashboards.messages.visibility_check_two;

        accesschanged = profiles === true ? accesschanged : false;
        setValidations({ ...validations, nameError: nameError });
        setProfilesUsingCreatedColumn(profiles);
        setCreatedColumn({ ...createdColumn, visibility: newVisibility.toLowerCase(), accesschanged: accesschanged });
        setHasUnsavedChanges(true)
    };

    /**
     * This function increments the machineName if it exists. if we have machineName=test__cpcf already exists in data, we increment it to be test__1_cpcf.
     * @param {*} originalVal the value without any suffix (ex: test_, test, test__1, ...)
     * @param {*} data the custom fields array where we search for existing machine names
     * @param {*} counter
     * @returns
     */
    const incrementMachineName = (originalVal, data, counter = 0) => {
        let newValue = originalVal + (counter > 0 ? "_" + counter : "");
        newValue = newValue.toLowerCase();
        let valWithSuffix = newValue + _cpcf;

        if (data.filter((f) => f.machine_name.toLowerCase() === valWithSuffix).length > 0) {
            return incrementMachineName(originalVal, data, ++counter);
        }
        return newValue + _cpcf;
    };

    const saveCreatedColumn = () => {
        clearManageColumnsFromStore(scenarioId);
        dispatch(updateCustomGroups([]));

        if (profilesUsingCreatedColumn.length > 0) {
            return;
        }

        if (!validateFields()) {
            return;
        }

        if(props.canColumnChangeToUser && !props.canColumnChangeToUser(createdColumn)) {
            return;
        }

        setIsSaving(true);

        let manageColumnsFormatValues = getManageColumnsFormatValues();
        let firstOption = manageColumnsFormatValues.firstOption;
        let secondOption = manageColumnsFormatValues.secondOption;
        let formula = manageColumnsFormatValues.formula;

        let selectedF = clientFormatRef.current?.getSelectedFormat();
        let embededChild = getEmbeddedChild(
            profileColumns,
            PROFILE_COLUMN.CHILDREN,
            PROFILE_COLUMN.COLUMN_FIELD_ID,
            createdColumn.column_profile_custom_field_id
        );
        let isEditFromCompanyToUser = embededChild && embededChild[COLUMN_PROFILE.VISIBILITY] === "company" && createdColumn.visibility === "user";
        
        let customGroupFieldsWithoutEditedColumn = customGroupFields;
        if(isEditing) {
            customGroupFieldsWithoutEditedColumn = customGroupFieldsWithoutEditedColumn.filter(f => f.machine_name !== createdColumn.column_return_name);
        }
        let createdColumnMachineName = replaceSpecialChars(createdColumn.column_name, "_");
        createdColumnMachineName = incrementMachineName(createdColumnMachineName, customGroupFieldsWithoutEditedColumn);

        createdColumn.column_profile_custom_field_id = createdColumn.column_profile_custom_field_id || "";
        createdColumn.display_name = createdColumn.column_name;
        createdColumn.name = createdColumn.column_name;
        createdColumn.column_return_name = createdColumnMachineName;
        createdColumn.machine_name = createdColumnMachineName;
        createdColumn.format_type_name = selectedF.value;
        createdColumn.format_type_id = selectedF.format_type_id;
        createdColumn.unit = selectedF.unit_sample;
        createdColumn.formula = formula;
        createdColumn.column_group = manageColumnsFormatRef.current?.state.group;
        createdColumn.column_group_line = firstOption || "";
        createdColumn.column_type_line = secondOption;
        createdColumn.line_type = createdColumn.column_type;
        createdColumn.is_creator_system = embededChild ? embededChild.is_creator_system : "";
        createdColumn.created_by = Number(!isEditFromCompanyToUser && !!createdColumn.created_by ? createdColumn.created_by : user.userId);
        createdColumn.creator_name =
            !isEditFromCompanyToUser && !!createdColumn.created_name ? createdColumn.created_name : user.first_name + " " + user.last_name;

        // manageColumnsFormatRef.current.formulaRef.createFormula();
        props.saveCreatedColumn(createdColumn);

    };

    let types = [];
    const len = lang.manage_columns.labels.length;
    for (var i = 0; i < len; i++) {
        types.push({ value: MANAGE_COLUMNS.VALUES[i], label: lang.manage_columns.labels[i], tooltipText: lang.manage_columns.type_tooltips[i] });
    }

    const calculatedColumnDescription = ({ label, tooltipText }) => (
        <div className={"uk-flex-between uk-display-flex"}>
            {label}
            <i className="fal fa-info-circle text-white uk-margin-xsmall-top" uk-tooltip={!!tooltipText ? tooltipText : lang.formula.tooltip} />
        </div>
    );

    const customGroupsformatOptionLabel = ({ label }) => (
        <div className={"uk-flex-between uk-display-flex"}>
            {label}
            <div className="uk-flex uk-flex-between uk-flex-middle dropdown_row_fullheight">
                <div className="uk-flex uk-flex-between uk-flex-middle">
                    <i className=" fa-lg fal fa-edit uk-margin-small-right" onClick={(e) => {}}></i>
                    <i className=" fa-lg fal fa-trash-alt uk-margin-small-right" onClick={(e) => {}}></i>
                </div>
            </div>
        </div>
    );

    const getManageColumnsFormatValues = () => {
        let firstOption = "";
        let secondOption = "";
        let formula = "";

        if (manageColumnsFormatRef.current) {
            if (manageColumnsFormatRef.current?.state.group === lang.manage_columns.group_labels[0]) {
                firstOption = manageColumnsFormatRef.current?.state.selectedProfitStackLine[PROFILE_COLUMN.RETURN_NAME];
            } else {
                firstOption = manageColumnsFormatRef.current?.state.option;
            }
            if (createdColumn.column_type === MANAGE_COLUMNS.VALUES[1]) {
                secondOption = manageColumnsFormatRef.current?.state.vector;
            } else if (createdColumn.column_type === MANAGE_COLUMNS.VALUES[2]) {
                secondOption = manageColumnsFormatRef.current?.state.lineAttribute;
            } else {
                if (manageColumnsFormatRef.current?.formulaRef.current) {
                    formula = manageColumnsFormatRef.current?.formulaRef.current.createFormula();
                }
            }
        }

        return {firstOption: firstOption, secondOption: secondOption, formula: formula}
    }

    const formatFormulaObj = (obj) => {
        let array = [];
        if (typeof obj === "object") {
            for (let e of obj.formula) {
                let newObj = {};
                if (e.value) {
                    delete e.value;
                }
                newObj.display_name = e.display_name
                newObj.formula = e.formula
                if (e.type) {
                    newObj.type = e.type
                }
                newObj.class = e.class
                newObj.returnName = e.returnName;
                array.push(newObj);
            }
            obj.formula = array;
        }
        return obj;
    }

    const isThereAnyEditedChanges = () => {
        let manageColumnsFormatValues = getManageColumnsFormatValues();
        let firstOption = manageColumnsFormatValues.firstOption;
        let secondOption = manageColumnsFormatValues.secondOption;
        let formula = manageColumnsFormatValues.formula;
        let selectedF = clientFormatRef.current?.getSelectedFormat();
        let formattedFormula;
        if (formula !== "") {
            formattedFormula = formatFormulaObj(JSON.parse(formula));
        }
        let columnFormattedFormula = formatFormulaObj(column.formula);

        /** removing these attribute since they are added while saving/fetching columns and the won't affect the checking if we have unsaved chnages */
        let newCreatedColumn = structuredClone(createdColumn);
        newCreatedColumn?.column_filter?.forEach(f => {
            delete f?.vectorLabel;
            delete f?.filter_row_type;
        });
        let oldColumn = structuredClone(column);
        oldColumn?.column_filter?.forEach(f => {
            delete f?.vectorLabel;
            delete f?.filter_row_type;
            delete f?.name;
        });
        
        return (
            firstOption !== column.column_group_line ||
            secondOption !== column.column_type_line ||
            selectedF.machine_name !== column.format_type_name ||
            (JSON.stringify(formattedFormula) !== JSON.stringify(columnFormattedFormula) && formula !== "") ||
            JSON.stringify(newCreatedColumn) !== JSON.stringify(oldColumn)
        );
    }

    const areThereAnyNewChanges = () => {
        let manageColumnsFormatValues = getManageColumnsFormatValues();
        let firstOption = manageColumnsFormatValues.firstOption;
        let secondOption = manageColumnsFormatValues.secondOption;
        let formula = manageColumnsFormatValues.formula;
        let selectedF = clientFormatRef.current?.getSelectedFormat();

        let hasChanges = (firstOption !== "" && firstOption !== undefined) || (secondOption !== "" && secondOption !== undefined) || formula !== "" || selectedF !== "";
        return hasUnsavedChanges || hasChanges;

    }

    const handleBackDropClick  = () => {
        if((!isEditing && areThereAnyNewChanges()) || (isEditing && isThereAnyEditedChanges()) ) {
            setOpenUnsavedChangesDialog(true)
        } else {
            closePanels();
        }
    };

    const closePanels = () => {
        resetPanelInputs();
        setOpenUnsavedChangesDialog(false);
        setHasUnsavedChanges(false)
        setDrawerOpen(false);
    }

    const unsavedChangesDialogActions = () => {
        return (
            <>
                <Button
                    id="manage-columns-apply"
                    label={lang.modal.buttons.yes}
                    variant={BUTTON_VARIANT.PRIMARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => {
                        saveCreatedColumn()
                        setOpenUnsavedChangesDialog(false)
                    }
                    }
                />
                <Button
                    label={lang.modal.buttons.no}
                    variant={BUTTON_VARIANT.SECONDARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={closePanels}
                />
            </>
        );
    }

    const setFieldDropdownValue = (pslArray) => {
        if (pslArray.length > 0) {
            return pslArray?.map(psl => {
                if (psl.children) {
                    setFieldDropdownValue(psl.children)
                }
                return psl;
            })
        }
    }

    const applyColumnFilter = (filter) => {
        let newFilter = JSON.parse(filter);
        for (let e in newFilter) {
          let values = [];
          typeof newFilter[e][FILTER.KEYS.ENTITIES] === "object"
            ? newFilter[e][FILTER.KEYS.ENTITIES].map(function (item) {
                values.push(item.value);
              })
            : values.push(newFilter[e][FILTER.KEYS.ENTITIES]);
          newFilter[e][FILTER.KEYS.FILTER_ROW_TYPE] = FILTER.VALUES.FILTER_ROW_TYPE.COLUMN_FILTER;
        }
        setCreatedColumn({ ...createdColumn, column_filter: newFilter });
    };

    const renderColumnFilterDiv = () => {
        let hasFilter = createdColumn?.column_filter?.length > 0;
        let showColumnFilter = profitFormat === ALL_WIDGETS.FIELDS.LIST && createdColumn?.column_type === MANAGE_COLUMNS.CALCULATED_PSL_LINE;
        let dialogId = "addColumn" + (props?.constraint || "");

        if(!showColumnFilter) {
            return <></>;
        }

        return (
            <div id="column_filters" className="column-filters-container">
                <span className={`uk-text-medium ${hasFilter && "uk-text-bold"}`}>
                    {hasFilter ? lang.basic_filter.applied_filters_text : lang.dashboards.titles.filter}
                </span>
                <div className={`column-filters-buttons gap_between_buttons ${isSaving ? "disabled" : ""}`}>
                    <Button
                        label={lang.modal.buttons.advanced_filter}
                        variant={BUTTON_VARIANT.SECONDARY}
                        size={SIZES.DEFAULT}
                        type={BUTTON_TYPE.DEFAULT}
                        leftIcon={<MultipleFilters />}
                        className={"mui-secondary-button-widget uk-margin-small-top"}
                        uk-toggle={`target: #filterModal_${dialogId}`}
                    />
                    <FilterDialog                                
                        ref={columnFilterRef}
                        vectorOptions={vectorsList}
                        componentName={dialogId}
                        useOutsideFilter
                        onChangeFilter={applyColumnFilter}
                        scenario_id={props.scenarioState?.scenario}
                        mustFetchDatasets={false}
                        useCookies={false}
                        user={user}
                        isDrilling={false}
                        fromOptions={[]}
                        quadrantDefault={false}
                        filterDisplayEditable
                        hideFilterDisplay={false}
                        isWidgetFilter
                        savedFilter
                        useNewQuickFilters
                        filterFinal={createdColumn?.column_filter}
                        shouldAddRank
                    />
                </div>
            </div>
        );
    }

    const renderBody = () => {
        return<>
            <div className="component-body add_column_body">
                <div className="add_column_first_section_body">
                <div className="column-name-div">
                    <FormComponent
                        ref={nameRef}
                        tag="input"
                        id={MANAGE_COLUMNS.NAME_ID}
                        onChange={e => handleFieldChange(e,MANAGE_COLUMNS.FIELD_NAME)}
                        className="uk-input"
                        placeholder={lang.manage_columns.column_name + "*"}
                        value={createdColumn[MANAGE_COLUMNS.COLUMN_NAME] || ""}
                        onLiveChange={handleNameCharacterLength}
                        maxLength={maxCharacterLength}
                        disabled={isSaving}
                    />
                    {`${nameCharactersLength < 0 ? 0 : nameCharactersLength}/${maxCharacterLength} characters left`}
                    {validations.nameError !== "" && <div className="red fs-12">{validations.nameError}</div>}
                </div>
                    <div style={{ width: "100%"}}
                         className="custom-group-div">
                        <DropDown
                            id={MANAGE_COLUMNS.CUSTOM_GROUP_ID}
                            value={findOptionByKey(customGroups, createdColumn?.customGroup?.value) || ""}
                            onChange={e => handleFieldChange(e,MANAGE_COLUMNS.FIELD_GROUP)}
                            isSearchable
                            onInputChange={(inputVal, params) => props.onInputChange(inputVal, params)}
                            options={customGroups}
                            placeholder={lang.manage_columns.select_custom_group + "*"}
                            type={DROPDOWN_TYPE.INPUT}
                            className="input__dropdown"
                            disabled={isSaving}
                        />
                        {/*<i className="fa-lg far fa-plus-circle add-custom-group-icon" onClick={() => setAddNewGrpOpen(true)} />*/}
                        {!validations.customGroupError !== "" && <span className="red fs-12">{validations.customGroupError}</span>}
                    </div>

                <FormComponent
                    ref={descriptionRef}
                    tag="textarea"
                    id={MANAGE_COLUMNS.DESCRIPTION_ID}
                    className="uk-textarea"
                    placeholder={lang.manage_columns.column_description}
                    value={createdColumn.column_description}
                    onChange={e => handleFieldChange(e,MANAGE_COLUMNS.FIELD_DESCRIPTION)}
                    disabled={isSaving}
                />
                <div>
                    <DropDown
                        className={isEditing ? "disabled" : "input__dropdown"}
                        id={MANAGE_COLUMNS.TYPE_ID}
                        value={findOptionByKey(types, createdColumn.column_type)}
                        onChange={e => handleFieldChange(e, MANAGE_COLUMNS.FIELD_TYPE)}
                        options={types}
                        formatOptionLabel={calculatedColumnDescription}
                        type={DROPDOWN_TYPE.INPUT}
                        disabled={isSaving}
                    />
                    {!validations.columnTypeError !== "" &&
                        <span className="red fs-12">{validations.columnTypeError}</span>}
                </div>
                </div>
                <div className="add_column_second_section_body">
                    <ManageColumnsFormat
                        ref={manageColumnsFormatRef}
                        profileColumns={profileColumns}
                        vectorsList={vectorsList}
                        type={createdColumn.column_type}
                        groups={groups}
                        lineAttributeOptions={lineAttributeOptions}
                        profitStackLineColumns={profitStackLineColumns}
                        profitFormat={profitFormat}
                        scenarioState={props.scenarioState}
                        useNewAddColumn={props.useNewAddColumn}
                        isDrawerOpen={isDrawerOpen}
                        isEditing={isEditing}
                        disabled={isSaving}
                    />
                    {!validations.formulaError !== "" && createdColumn.column_type === MANAGE_COLUMNS.CALCULATED_PSL_LINE && validations.formulaError ?
                        <span className="red fs-12">{validations.formulaError}</span>
                        :
                        ""}
                    <ClientFormat
                        ref={clientFormatRef}
                        formats={formats}
                        formatType={createdColumn[PROFILE_COLUMN.FORMAT_NAME] || ""}
                        unit={createdColumn[PROFILE_COLUMN.UNIT] || ""}
                        useNewAddColumn={props.useNewAddColumn}
                        disabled={isSaving}
                    />
                </div>
                <ToggleTab
                    options={visibilityOptions}
                    onSelectTab={(visibility) => handleVisibilityChange(visibility)}
                    defaultValue={capitalizeFirstLetter(createdColumn.visibility)}
                    isDisabled={isSaving}
                    mode={"light"}
                />

                {useColumnFilters && renderColumnFilterDiv()}
            </div>
            {profilesUsingCreatedColumn?.length > 0 && (
                <div className="fs-12 red italic" style={{display: "inline-grid"}}>
                    {lang.dashboards.messages.visibility_check_two}
                    {profilesUsingCreatedColumn?.map((profile) => (
                        <span key={profile} className={"uk-text-medium"}>{profile}</span>
                    ))}
                </div>
            )}

        </>
    }


    let embededChild = getEmbeddedChild(
        profileColumns,
        PROFILE_COLUMN.CHILDREN,
        PROFILE_COLUMN.COLUMN_FIELD_ID,
        createdColumn.column_profile_custom_field_id
    );

    return (
        <div className="side-panel-shrink">
            <SidePanelNew
                body={renderBody()}
                show={isDrawerOpen}
                close={closePanels}
                onSubmit={saveCreatedColumn}
                backdropClickHandler={handleBackDropClick}
                // largeDisplay={true}
                variant="shrink"
                buttons={[{ button: isSaving ? "Validating" : lang.modal.buttons.save, isSubmit: true, isDisabled: isSaving, id: "save-created-column-btn" },
                    {button:lang.modal.buttons.cancel, isSubmit: false, isDisabled : isSaving, onClick:closePanels}]}
                title={
                    isEditing
                        ? lang.manage_columns.titles.edit_column +
                        (embededChild && embededChild[COLUMN_PROFILE.IS_CREATOR_SYSTEM]
                            ? ""
                            : " - " + lang.manage_columns.titles.created_by + " " + createdColumn["creator_name"])
                        : lang.manage_columns.titles.create_column
                }
                subtitle={lang.manage_columns.add_column_subtitles.add}
                backButton={true}
            />
            {isDrawerOpen && <Backdrop close={handleBackDropClick} />}
            <Modal
                id={"unsaved-changes-dialog"}
                openDialog={openUnsavedChangesDialog}
                bodyContent={() => <span className='fs-16'>{lang.stage_unsaved_changes}</span>}
                dialogActions={unsavedChangesDialogActions}
                closeClick={() => setOpenUnsavedChangesDialog(false)}
                size={DIALOG_SIZE.MEDIUM}
            />
        </div>
    );
};

export default forwardRef(AddColumn);