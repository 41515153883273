import React, { useMemo } from "react";
import ScenarioContainer from "./ScenarioContainer";
import { Footer } from "./Footer.js"
import "./styles/landingPage.css";
import { BUTTON_TYPE, BUTTON_VARIANT, CUSTOM_REPORT, MENU_ITEM, MENU_ITEMS_SVG_COLOR, SIZES } from "../class/constants";
import { lang } from "../language/messages_en";
import {splitAndGetURLLastHrefElement} from "../class/utils";
import {convertPxToViewport} from "../class/formatting";
import { ReactComponent as LeftArrow } from "../styles/images/chevron-right.svg";
import {ReactComponent as Arrow} from "../styles/images/icons/small-thick-arrow.svg";
import Accordion from "../components/accordion/Accordion";
import {getMenuItemsAccordionContent} from "./functions/componentFunctions";
import { useFeatureFlagEnabled } from "posthog-js/react";
import Button from "../newComponents/Button.js";
/**
 * @description component displays the menu links for the clicked link group
 * @example clicked group is Financial analaytics then it displays all repors under financial analytics a long with the scenario container
 * @param {*} props 
 * @returns {@link ScenarioContainer} , menu links, {@link Footer}
 */
const NewLeftHandMenu = (props) => {
  let menuItemLinkGroup = useMemo(() =>  props?.menuLinks?.length > 0 && props?.menuLinks[0], [props?.menuLinks, props?.scenarioOptions,props?.location?.key]);
  const useNewComponents = useFeatureFlagEnabled('use_new_components');

  return (
    <div className="new_left_menu left-hand-menu">
        <div style={{display: "flex", flexDirection: "column", height: "100%", paddingBottom:"0.833vw"}}>
            <div className="left-menu-header">
                <div className="left-menu-title-container">
                    <img
                        alt={menuItemLinkGroup[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME]}
                        src={"/images/menu-svgs/" + menuItemLinkGroup[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] + ".svg"}
                        style={{
                            minWidth: "1.51042vw",
                            maxWidth: "1.51042vw",
                            pointerEvents: "none",
                            backgroundColor: "black",
                            padding: "0.36458vw",
                            borderRadius: "5px"
                        }}/>
                    <label  className={`menu-group-title ${useNewComponents && "new-design"}`}>{menuItemLinkGroup && menuItemLinkGroup[MENU_ITEM.COLUMNS.MENU_ITEM_DISPLAY_NAME]}</label>
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "column", height:"100%"}}>
                <div className="menu-links-container">
                    <Accordion key={menuItemLinkGroup?.menu_item_machine_name} items={getMenuItemsAccordionContent(menuItemLinkGroup,(e)=>props.goToReport(e))} />

                    <div className="menu-footer">
                        <Button 
                            className="left_menu_slider"
                            variant={BUTTON_VARIANT.SECONDARY}
                            size={SIZES.ICON}
                            type={BUTTON_TYPE.DEFAULT}
                            onBtnClick={props?.openCloseLeftMenu}
                            leftIcon= {<Arrow style={{width: convertPxToViewport(14), height: convertPxToViewport(14), transform: props.isMenuCollapsed ? "rotate(90deg)" : "rotate(-90deg)"}}/>}
                        />
                        {!props.isScenarioHidden && (
                            <ScenarioContainer
                                scenarioOptions={props?.scenarioOptions}
                                defaultScenarioId={props?.selectedState}
                                saveScenario={props?.saveScenario}
                                isReadOnly={props?.isScenarioDisabled || props?.isScenarioDisabledProps}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default NewLeftHandMenu;
