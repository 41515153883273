import { convertPxToViewport } from "../../class/formatting";

const ProgressBar = ({percentage, color}) => {
    
    return (
        <div style={{width:'100%', backgroundColor: '#e5e7eb', borderRadius: "1.5625vw", height: convertPxToViewport(15), alignSelf:"center"}}>
            <div style={{minWidth: "8%", width:percentage+"%",  maxWidth: "100%", backgroundColor:color,borderRadius: "1.5625vw", height:convertPxToViewport(15)}}></div>
         </div>
    );
}
export default ProgressBar;