import React, { Component } from 'react';
import Tabulator from "tabulator-tables";
import { cleanUpTabulatorColumns, toggleLoader } from '../../class/common';
import { getCostClassification } from '../../class/commonRequests';
import { ALL_WIDGETS, API_URL, BUTTON_TYPE, BUTTON_VARIANT, COLUMN_PROFILE, COMPARE_SCENARIOS, DIALOG_SIZE, FormatTypes, ORDER_VALUES, PSL_RETURN_NAMES, ROLLING_SEGMENTS, SCENARIOS, SIZES, VECTOR_ANALYSIS, comparison_suffixes } from '../../class/constants';
import { getMonthName } from '../../class/date';
import { formatValHTML, formatValString } from '../../class/format';
import { convertViewportToPx } from '../../class/formatting';
import { getCleanReturnName } from '../../class/jqueries';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from '../../class/networkUtils';
import { exportToExcel } from '../../class/tabulatorExport';
import { capitalizeFirstLetter, getTranslationFile, parseBoolean, removeAttributes, tryParse } from '../../class/utils';
import CostClassification from '../../components/CostClassification';
import Button from '../../newComponents/Button';
import Modal from '../../newComponents/Modal';
import ExcelDetailsTable from '../../tables/ExcelDetailsTable';

const $ = require("jquery");
const _cnt = "cnt";
const lang = getTranslationFile();
class CompareScenarios extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shouldFetchSummation: false
        }

        this.fetchAPI = fetchAPI.bind(this);
        this.getCostClassification = getCostClassification.bind(this);
        this.go = this.go.bind(this);
        this.initTable = this.initTable.bind(this);
        this.getTabulator = this.getTabulator.bind(this);
        this.getTableColumns = this.getTableColumns.bind(this);
        this.exportTableToExcel = this.exportTableToExcel.bind(this);
    }

    // static getDerivedStateFromProps(props, state) {
    //     let tempState = {}

    //     if(!deepCompareObjects(props.scenarios, state.scenarios) || props.endQuarter !== state.endQuarter || props.months !== state.months) {
    //         //props.scenarios isn't filled until GO is clicked, however since state.scenarios is not
    //         //defined, it will enter the condition the first time and fetch the summation -- correct behavior
    //         tempState.scenarios = props.scenarios;
    //         tempState.endQuarter = props.endQuarter;
    //         tempState.months = props.months;
    //         tempState.shouldFetchSummation = true;
    //     }

    //     if(!Object.keys(tempState).length) {
    //         return null;    //update nothing if there is nothing to update
    //     }
    //     return tempState;
    // }

    go() {
        this.getCompareVectorsData();
        this.getCostClassification_local();
    }

    getCompareVectorsData() {
        const _this = this;
        let columnsViews = this.props.manageColumnsProfile ? removeAttributes(this.props.manageColumnsProfile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS]) || [] : [];
        columnsViews = columnsViews.filter(e=> !((e.type === "line_attribute" || e.type === "psl") && (e.machine_name === "per_selection" || e.machine_name === "per_total")));
        let query = {
            action: "getCompareVectorsData",
            scenario_id: this.props?.scenarios[0],
            comparison_scenario_id: this.props?.scenarios[1],
            vector: this.props?.vector?.value,
            columnsViews: columnsViews,
            profitFormat: this.props?.profitFormat,
            months: "M3", // this is not used anymore
            quarter: this.props?.quarter,
            selectedPeriods: this.props?.selectedPeriods,
            limit: this.props?.compareVectorsLimit,
            filter: JSON.stringify({'filter': tryParse(this.props.filter)}),
            order: this.state.sorter || ORDER_VALUES.DESC,     
            columns: this.props?.manageColumnsProfile ? this.props?.manageColumnsProfile[COLUMN_PROFILE.COLUMNS] || [] : [],
            fetchSummation: true,//this.state.shouldFetchSummation,
            orderer: this.state.orderer,
            rollingSegment: ROLLING_SEGMENTS.R_12,
            rollingPeriod: this.props?.selectedPeriods[this.props?.selectedPeriods?.length - 1]
        }

        let onThenCallback = (data)=>{
            if (data.ERROR || data.no_access_message) {
                _this.tabulator.clearData();
                _this.props.setLimitAccessDialogOpen(true, data.ERROR || data.no_access_message)
            } else {
                _this.setState({
                    data: data.data || [],
                    columns: data.columns || [],
                    shouldFetchSummation: false,
                    recordsLimitReached: Number(data.data[0][_cnt]) > Number(this.props.compareVectorsLimit)
                }, ()=>{
                    _this.initTable(data,_this.state.sorter || ORDER_VALUES.DESC);
                });
            }
        };

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getCompareVectorsData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.MANAGE_SCENARIOS,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.scopeFilterApplicable]: true,
        }
        //if there is a tracking obj in the session, action is two_dimension_heatmap, else generate_heatmap
        this.fetchAPI(fetchOptions);
    }

    getCostClassification_local() {
        const _this = this;
        let referenceCallback=(data) => {
            _this.setState({
                referenceCostClassification: data.costClassification ? data.costClassification : [],
                referenceGlCosts: data.glCosts ? data.glCosts : []
            });
        }
        
        let comparisonCallback=(data) => {
            _this.setState({
                comparisonCostClassification: data.costClassification ? data.costClassification : [],
                comparisonGlCosts: data.glCosts ? data.glCosts : []
            });
        }

        this.getCostClassification(referenceCallback, this.props.scenarios[0]);
        this.getCostClassification(comparisonCallback, this.props.scenarios[1]);
    }

    initTable(data, orderer) {
        let cols = this.getTableColumns(data.columns);
        this.setCol(cols,data.data);
        this.tabulator.setData(data.data);
        this.tabulator.setSort([{ 
            column: data.profitConfig,
            dir: orderer.toLowerCase()
        }]);
        let tableColumns = [];  //setting columns in add columns popup
        data.columns.forEach(col=>{
            if(col.columns) {
                tableColumns = tableColumns.concat(col.columns);
            }
        });

        if(data.columnConfig.isPSTotalMatching) {
            let retNamesWithWarning = [];
            Object.keys(data.columnConfig.isPSTotalMatching).forEach(retName=>{
                if(parseBoolean(data.columnConfig.isPSTotalMatching[retName]) === false) {
                    retNamesWithWarning.push(retName);
                }
            })
            this.props.setManageColumnsWarnings(retNamesWithWarning, lang.compare_scenarios_manage_columns_warning, lang.compare_scenarios_manage_columns_warning_by_psl)
        }
        let allColumns = data.columnConfig.isApplied.concat(data.columnConfig.isDefault, data.columnConfig.isPrimary);
        this.props.setManageColumnsAdditionalColumns(allColumns);   //setting extra columns checked
        // this.props.headerRef.setLimit(data.data ? data.data.length : 0);
    }

    getOldVisibility = (oldColumns, field) => {
        let visible = false;
        if (!oldColumns) {
            return false;
        } else {
            let columns = Array.from(oldColumns);
            for (let i = 0; i < columns.length; i++) {
                if (columns[i].field !== VECTOR_ANALYSIS.FIELDS.KEY &&  columns[i].field !== VECTOR_ANALYSIS.FIELDS.NUMBER) {
                    if (columns[i].columns) {
                        for (let j = 0; j < columns[i].columns.length; j++) {
                            if (columns[i].columns[j].field === field) {
                                visible = columns[i].columns[j].visible ? columns[i].columns[j].visible : false;
                                break;
                            }
                        }
                    }
                }
            }
        }
        return visible;
    }

    /**
     * Adds tooltip to header titles
     * @param {*} col 
     * @returns 
     */
    addHeaderTooltip = (col) => {
        let _this = this;
        let colValue = col.titleDownload;

        if (col.field && (col.field.includes(comparison_suffixes.comparison) || col.field.includes(comparison_suffixes.selection))) {
            col.hideIcon = true;
            col.title = col.titleDownload;
            col.tooltipMessage = _this.getToolTipMessage(colValue);
         }
    }

    /**
     * Returns the tooltip message based on the colValue
     * @param colValue
     * @returns {string}
     */
    getToolTipMessage = (colValue) => {
        let _this = this;
        let tooltipMessage = "";
        let scenarios = _this.props.scenarioObjects;
        let betaTooltip = scenarios && scenarios[0] ? scenarios[0][SCENARIOS.F.NUMBER] + "-" + scenarios[0][SCENARIOS.F.STATUS] : "";
        let alphaTooltip = scenarios && scenarios[1] ? scenarios[1][SCENARIOS.F.NUMBER] + "-" + scenarios[1][SCENARIOS.F.STATUS] : "";

        tooltipMessage = colValue === VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL ? betaTooltip : alphaTooltip;
        return tooltipMessage;
    }

    /**
     * Returns formatted symbols
     * @param {*} colValue 
     * @returns 
     */
    getLegendSpan = (colValue) => {
        let _this = this;
        let legend = "<span class='uk-text-bolder fs-12'>" + colValue + ":</span> <span class='fs-12'>" + _this.getToolTipMessage(colValue) + "</span>";
        return legend;
    }

    getTableColumns(tableColumns) {
        const _this = this;
        //set listener for header click
        window._pi_onHeaderClickCallback = (e, colField, colTitle) => {
            e.stopPropagation();
            colField = getCleanReturnName(colField || "");
            _this.setState({
                clickedPSReturnName: colField,
                clickedPSName: (colTitle || "")
            }, ()=>{
              _this.setCostClassificationDialogOpen(true)
            });
        }

        tableColumns?.map(group => {
            if(group.columns) {
                let columnField = getCleanReturnName(group.columns[0].field);
                if(_this.state.comparisonCostClassification && _this.state.comparisonCostClassification.filter(e => e.returnname === columnField).length) {
                    group.hasTitleClickCallback = true;
                }
            }
            return group;
        });

        let accessorParams = {}
        let columnAccessor=(value, data, type, params, column)=>{
          if([PSL_RETURN_NAMES.QUADRANT_TIER + comparison_suffixes.selection, PSL_RETURN_NAMES.QUADRANT_TIER + comparison_suffixes.comparison].includes(column.getField())) {
            return formatValString(value, FormatTypes.QUADRANT);          
          }
          return value || lang.N_A;
        }

        let onToggleColumns = (columnField, $element, isExpanded) => {
            $(".collapsed-table-group").removeClass("collapsed-table-group");
            $(".inset-parent:has(.inset)").addClass("collapsed-table-group");
            if(columnField && columnField.includes(PSL_RETURN_NAMES.NAME)) {
                if(isExpanded) {
                    $element.parent(".inset-parent").removeClass("width-245");
                } else {
                    $element.parent(".inset-parent").addClass("width-245");
                }
            }
        }
        let oldColumns=_this.tabulator.getColumnDefinitions();

        tableColumns?.forEach(col => {
          if(col.columns){
            col.columns.forEach(c => {
              if(c.field) {
                  _this.addHeaderTooltip(c);
                }
              });
            }
            if (col.field.includes(PSL_RETURN_NAMES.NUMBER)) {
                col.alphaLegend = _this.getLegendSpan(VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL);
                col.betaLegend = _this.getLegendSpan(VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL);
                col.alphaClassName = "compare-scenarios-legend";
                col.cssClass = "compare-scenarios-number";
            }
            if (![PSL_RETURN_NAMES.NUMBER, PSL_RETURN_NAMES.NAME].includes(col.field)) {
                col.width = convertViewportToPx(130);
            } else if ([PSL_RETURN_NAMES.NAME].includes(col.field)) {
                col.width = convertViewportToPx(230);
            }
          });

          tableColumns = cleanUpTabulatorColumns(tableColumns, null, onToggleColumns, _this.getTabulator, {id: COMPARE_SCENARIOS});
          tableColumns?.forEach(col => {
            if(col.columns){
                col.columns.forEach(c => {
                    if(c.field) {
                        c.formatter = _this.getColumnFormatter(c.field);
                        c.title = capitalizeFirstLetter(c.title);
                        c.accessorDownload = columnAccessor;
                        c.accessorDownloadParams = accessorParams;
                        c.width = ![PSL_RETURN_NAMES.NAME].includes(col.field) ? convertViewportToPx(135) :  convertViewportToPx(250);
                        c.hideFilter = _this.state.recordsLimitReached; //hide column filter when records > compare_vectors_limit

                        //hide useless column
                        if(c.field === PSL_RETURN_NAMES.NAME + comparison_suffixes.difference) {
                            c.visible = false;  
                        }
                        //start columns collapsed, use visible attribute
                        if (oldColumns.length == 0) {
                            if ([PSL_RETURN_NAMES.NAME + comparison_suffixes.selection].includes(c.field)) {
                                c.alwaysVisible = true;
                            } else if (c.field.includes(comparison_suffixes.selection) || c.field.includes(comparison_suffixes.comparison)) {
                                c.visible = false;
                            }
                        }else {
                            if ([PSL_RETURN_NAMES.NAME + comparison_suffixes.selection].includes(c.field)) {
                                c.alwaysVisible = true;
                                c.visible = true;
                            }else {
                                let visibilityLength = 0;
                                col.columns.forEach(c => {
                                    if(c.field && oldColumns.length> 0) {
                                        let oldVisibility = _this.getOldVisibility(oldColumns, c.field);
                                        c.formatter =_this.getColumnFormatter(c.field);
                                        if (c.field.endsWith(comparison_suffixes.comparison) || c.field.endsWith(comparison_suffixes.selection)) {
                                            c.visible = oldVisibility;
                                        }
                                        if ([PSL_RETURN_NAMES.NAME + comparison_suffixes.selection].includes(c.field)) {
                                            c.alwaysVisible = true;
                                            c.visible = true;
                                        }
                                        if (c.field.endsWith(comparison_suffixes.difference) && ![PSL_RETURN_NAMES.NAME + comparison_suffixes.difference].includes(c.field)) {
                                            c.visible = true;
                                            c.alwaysVisible = true;
                                        }
                                        if (c.visible === true) {
                                            visibilityLength++;
                                        }
                                    }
                                })
                                col.visibilityLength = visibilityLength;
                            }
                        }
                    }
                });
            }else {
                col.formatter = _this.getColumnFormatter(col.field);
                col.title = capitalizeFirstLetter(col.field);
                col.accessorDownload = columnAccessor;
                col.accessorDownloadParams = accessorParams;

                if([PSL_RETURN_NAMES.KEY].includes(col.field)) {
                    col.visible = false;
                }
            }
            col.hideFilter = _this.state.recordsLimitReached;
        });
        return tableColumns;
    }

    setOrder=(sorter,orderer)=>{
        let _this = this;
        _this.setState({
            sorter: sorter,
            orderer: orderer,
        }, ()=>{
            _this.getCompareVectorsData();}
        )}
    
    
    setCol=(columns, data)=>{
        let obj = this;
        if(data[0] && (Number(data[0].cnt) > Number(obj.props.compareVectorsLimit))) {
            columns.forEach(col => {
                if(col.collapsable){
                    if(col.columns){
                        col.columns.forEach(cl=>{
                            cl.headerClick = function(e, column) {
                                var sortField = column.getTable().getSorters().length > 0 ? obj.tabulator.getSorters()[0].field : "";
                                var dir = sortField === column.getField() && obj.tabulator.getSorters()[0].dir == "asc" ? "desc" : "asc";
                                obj.setOrder(dir, column.getField());
                            }
                        })
                    }
                }else{
                    col.headerClick = function(e, column) {
                        var sortField = column.getTable().getSorters().length > 0 ? obj.tabulator.getSorters()[0].field : "";
                        var dir = sortField === column.getField() && obj.tabulator.getSorters()[0].dir == "asc" ? "desc" : "asc";
                        obj.setOrder(dir, column.getField());
                    }
                }
            })
        }
        obj.tabulator.setColumns(columns);
    }

    getColumnFormatter(colField) {
        let columnFormatter;
        let cleanField = getCleanReturnName(colField);

        let isNameNull = (cell) => {
            let rowData = cell.getRow().getData();
            let undefArr = [null, undefined, ""];

            let suffix = cell.getField().slice(cell.getField().lastIndexOf("_"));     //splitting over last "_"
            if(suffix === comparison_suffixes.difference) {
                return false;   //always has a value
            }
            return undefArr.includes(rowData[PSL_RETURN_NAMES.NAME+suffix]);
        }
        
        if( ![PSL_RETURN_NAMES.KEY, PSL_RETURN_NAMES.NUMBER, PSL_RETURN_NAMES.NAME, PSL_RETURN_NAMES.QUADRANT_TIER].includes(cleanField)) {
            columnFormatter = (cell) => {
                if(isNameNull(cell)) {
                    let p = document.createElement("p");
                    p.innerText = lang.N_A;
                    p.title = lang.N_A_full;
                    p.classList.add("red");
                    return p;
                } else {
                    return formatValHTML(cell.getValue(), cell.getColumn().getDefinition().format_type);
                }
            }
        } else if(colField.indexOf(PSL_RETURN_NAMES.NAME) === 0) {
            columnFormatter = (cell)=>{
                let p = document.createElement("p");
                p.innerText = cell.getValue();
                if(cell.getRow().getData()[PSL_RETURN_NAMES.NAME + comparison_suffixes.comparison] !== cell.getValue()) {
                    p.classList.add("red");
                    if(isNameNull(cell)) {      //if name reference, display in red if different than comparison
                        p.innerText = lang.N_A;
                        p.title = lang.N_A_full;
                    }
                } else if(isNameNull(cell)) {   //if name comparison, display N/A in red only if name is null
                    p.innerText = lang.N_A;
                    p.title = lang.N_A_full;
                    p.classList.add("red");
                }
                return p;
            }
        } else if([PSL_RETURN_NAMES.QUADRANT_TIER + comparison_suffixes.selection, PSL_RETURN_NAMES.QUADRANT_TIER + comparison_suffixes.comparison].includes(colField)
                || [PSL_RETURN_NAMES.QUADRANT + comparison_suffixes.selection, PSL_RETURN_NAMES.QUADRANT + comparison_suffixes.comparison].includes(colField)) {
            columnFormatter = (cell) => {
              return formatValHTML(cell.getValue(), FormatTypes.QUADRANT);
            };
        } else if([PSL_RETURN_NAMES.QUADRANT_TIER + comparison_suffixes.difference, PSL_RETURN_NAMES.QUADRANT + comparison_suffixes.difference].includes(colField)) {
            columnFormatter = (cell) => {
                let p = document.createElement("p");
                if(isNameNull(cell)) {
                    p.innerText = "Yes";    //show yes if an entity is missing
                    p.classList.add("red");
                } else {
                    p.innerText = cell.getValue();
                    if(cell.getValue() && cell.getValue().toLowerCase() === "yes") {
                        p.classList.add("red");
                    }
                }
                return p;
            }
        }
		return columnFormatter;
    }

    getTabulator() {
		if(this.tabulator)
			return this.tabulator;
		return null;
    }

    exportTableToExcel(exportOpts) {
      let tempSheetName = exportOpts.vector;
      let mainSheet = tempSheetName.length > 31 ? tempSheetName.substring(0,28) + "..." : tempSheetName;
      let sheets = {};
      let startDate = "";
      let endDate = "";
      
      if(this.props.selectedPeriods?.length > 0) {
        startDate = this.props.selectedPeriods[0];
        endDate = this.props.selectedPeriods[this.props.selectedPeriods.length - 1];
      }
      sheets["Description"] = "#excel_details_table";
      sheets[mainSheet] = true;
      exportOpts.sheets = sheets;
      exportOpts.dataStartRow = 2;
      exportOpts.data = this.tabulator.getData();
      exportOpts.isProfitStack = false;

      exportToExcel(exportOpts, this.tabulator, startDate, endDate);
    }
    
    componentWillUnmount() {
        window._pi_onHeaderClickCallback = undefined;   //cleaning up before leaving
    }

    componentDidMount() {
      const _this = this;
      const options = {
        layout: "fitData",      //fit columns to width of table
        responsiveLayout: false,  //hide columns that dont fit on the table
        addRowPos: "top",          //when adding a new row, add it to the top of the table
        history: true,             //allow undo and redo actions on the table
        pagination: "local", //"local",       //paginate the data
        movableColumns: false,     //allow column order to be changed
        autoResize: false,
        resizableRows: false,       //allow row order to be changed
        resizableColumns: true,       //allow column order to be changed
        selectable: false,
        invalidOptionWarnings: false,
        // initialSort: [
        //     {column: VECTOR_ANALYSIS.FIELDS.PROFIT_DIFFERENCE, dir:"desc"},
        // ],
        virtualDomBuffer: 2000,
        columnHeaderVertAlign: "bottom",
        placeholder: lang.no_data_available,
        width: "100%",
        height: "100%",
        virtualDom: true,
        paginationSize: 100,
        paginationSizeSelector: [10, 50, 100],
        footerElement: "<div id='Compare_vectors_footer'></div>",
        // renderComplete: obj.onTabulatorRenderComplete,
        tooltips: function (cell) {
          if (cell.getValue() === lang.N_A) {
            return lang.N_A_full;
          }
          return cell.getValue();
        },
        dataFiltered: function (filters, rows) {
          _this.addFooterText(rows);
        },
        // accessorDownload: (dataObj) => {
        //   let params = {
        //     "Report": capitaliseFirstLetterAfterChar(ALL_WIDGETS.FIELDS.COMPARE_SCENARIOS).replace(/_/g, " "),
        //     "User": _this.props.user.first_name + " " + _this.props.user.last_name,
        //     "Date Run": new Date().getDate() + '-' + getMonthName(new Date().getMonth()) + '-' + new Date().getFullYear(),
        //     // "Filter": _this.props.exportQueryFilter || "None",
        //     // "Range": _this.props.quarters ? _this.props.quarters[0] === _this.props.quarters[1] ? _this.props.quarters[0] : _this.props.quarters[0] +" "+ lang.COMMON.TO.toLowerCase() +" "+ _this.props.quarters[1] : "None",
        //     "Reference Scenario": _this.props.scenarioObjects[0][SCENARIOS.F.NUMBER],
        //     "Comparison Scenario": _this.props.scenarioObjects[1][SCENARIOS.F.NUMBER],
        //   }
        //   return tabulatorExport(dataObj, _this.tabulator, params);
        // },
        downloadReady: function (fileContents, blob) {
          toggleLoader(false, "tablesToExcel");
          return blob; //must return a blob to proceed with the download, return false to abort download
        }
      }

      this.tabulator = new Tabulator(this.tableRef, options);
      window.addEventListener("resize", () => { this?.tabulator?.setColumns(this?.getTableColumns(this.state.columns)); });
    }

    addFooterText=(propsData)=>{
        let data = propsData || [];
        let totalsText = "";
        let iconDiv = document.createElement('div');
        if (data && data.length > 0) {
            if (Number(data[0].getData()[_cnt]) > Number(this.props.compareVectorsLimit)) {
                iconDiv.classList.add("uk-display-inline-flex");
                iconDiv.id = "icon_div"
                let infoIcon = document.createElement("i");
                infoIcon.classList.add("fal", "fa-info-circle", "uk-margin-xsmall-left");
                infoIcon.style.cursor = "pointer";
                $(iconDiv).attr('uk-tooltip', lang.record_limit_exceeded);
                iconDiv.appendChild(infoIcon);
                totalsText = "<span class=\"uk-margin-small-right\">" + formatValString(this.props.compareVectorsLimit, FormatTypes.NUMERIC) + " / " + formatValString((data && data.length > 0 ? data[0].getData()[_cnt] : "0"), FormatTypes.NUMERIC) +
                    " records </span>"
            } else {
                totalsText = "<span class=\"uk-margin-small-right\">" + formatValString(data.length, FormatTypes.NUMERIC) + " records " + "</span>"
            }
        }
        document.getElementById("Compare_vectors_footer").innerHTML = "<p>" + totalsText + "</p>";
        document.getElementById("Compare_vectors_footer").appendChild(iconDiv);
        document.getElementById("Compare_vectors_footer").classList.add("uk-flex", "uk-flex-middle");

        let icon = document.getElementById("icon_div");
        if (icon && data && data.length > 0 && Number(data[0].getData()[_cnt]) > Number(this.props.compareVectorsLimit)) {
            UIkit.tooltip(document.getElementById("Compare_vectors_footer")["children"][1]).show();
        }
    }

    setCostClassificationDialogOpen = (isOpen) => {
      let _this = this;
      _this.setState({
          openCostClassificationDialog: isOpen,
      })
    }

  costClassificationDialogContent = () => {
    return (
      <div className="uk-display-flex uk-flex-around">
        <div>
          <div className="fs-14 uk-text-bold uk-text-center">{lang.header.titles.reference_scenario}</div>
          <CostClassification costClassification={this.state.referenceCostClassification} showFYData={false}
            costCenter={this.props.costCenter} glCosts={this.state.referenceGlCosts}
            periods={this.props.periods} rowData={{ returnName: this.state.clickedPSReturnName }}
          />
        </div>
        <div>
          <div className="fs-14 uk-text-bold uk-text-center">{lang.header.titles.comparison_scenario}</div>
          <CostClassification costClassification={this.state.comparisonCostClassification} showFYData={false}
            costCenter={this.props.costCenter} glCosts={this.state.comparisonGlCosts}
            periods={this.props.periods} rowData={{ returnName: this.state.clickedPSReturnName }}
          />
        </div>
      </div>
    )
  }

  costClassificationDialogActions = () => {
    return (
      <Button 
        label={"Close"}
        variant={BUTTON_VARIANT.SECONDARY}
        size={SIZES.DEFAULT}
        type={BUTTON_TYPE.DEFAULT}
        onBtnClick={() => this.setCostClassificationDialogOpen(false)}
    />
    )
  }

  getParams=()=>{
    let obj = this;
    let exportQueryFilter = this.props?.exportQueryFilter? this.props?.exportQueryFilter() : undefined;
    let params = {
        "Report": ALL_WIDGETS.TITLES.DATA_MODELING.COMPARE_SCENARIOS,
        "User": obj.props.user.first_name + " " + obj.props.user.last_name,
        "Date Run": new Date().getDate() + '-' + getMonthName(new Date().getMonth()) + '-' + new Date().getFullYear(),
        "Filter": exportQueryFilter || "None",
        "Reference Scenario": obj.props.scenarios[0],
        "Comparison Scenario": obj.props.scenarios[1],
        "Start Period": this.props.selectedPeriods[0],
        "End Period": this.props.selectedPeriods[this.props.selectedPeriods.length - 1]
    }

    let scopeFilter = this.props.exportScopeFilter ? this.props.exportScopeFilter() : "";
    if(scopeFilter) { // if fetaure flag is on
        params["Scope"] = scopeFilter;
    }

    params = this.formatParams(params);
    return params;
  }

  formatParams = (params)=>{
      let data = []
      for(const property in params){
          let obj = {title:property,detail:params[property]}
          data.push(obj)
      }
      return data;
  }

    render() {
      let params = this.getParams();
        return (
            <React.Fragment>
            <div className="compare_scenarios_container">
              <div id={COMPARE_SCENARIOS} className="compare_scenarios_table has_grouping" ref={r => this.tableRef = r} />
            </div>
            <Modal
              id={"cost-classification-dialog"}
              title={lang.psl_tooltip.profit_stack_line + this.state.clickedPSName}
              openDialog={this.state.openCostClassificationDialog}
              bodyContent={this.costClassificationDialogContent}
              dialogActions={this.costClassificationDialogActions}
              closeClick={() => this.setCostClassificationDialogOpen(false)}
              size={DIALOG_SIZE.LARGE}
            />
            <Modal
              id={"error-msg-dialog"}
              openDialog={this.state.openErrorMsgDialog}
              bodyContent={() => <h4>{this.state.errorMsg}</h4>}
              dialogActions={() => (
                <Button 
                  label={"Close"}
                  variant={BUTTON_VARIANT.SECONDARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={() => this.props.setLimitAccessDialogOpen(false, "")}
              />
              )}
              closeClick={() => this.props.setLimitAccessDialogOpen(false, "")}
              size={DIALOG_SIZE.SMALL}
            />
  
            <div className="uk-hidden">
              <ExcelDetailsTable params = {params}/>
            </div>
            </React.Fragment>
        );
    }
}

export default CompareScenarios;