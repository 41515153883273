import { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { convertPxToViewport } from "../../class/formatting";
import * as am5percent from '@amcharts/amcharts5/percent';
import { Skeleton } from "@mui/material";
import { Segment } from "../../components/Segment";

const BarChart = (props) => {
  const chartRef = useRef(null);
  const rootRef = useRef(null);
  

   
  const renderChart = () => {
    if (!rootRef.current) {
      const root = am5.Root.new(props.id);
      rootRef.current = root;
      root._logo.dispose();
      const chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "none",
          wheelY: "none",
        })
      );
  
  
      const loyaltyXAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "segment",
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 30
          })
        })
      );
    
      const loyaltyYAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {})
        })
      );
  
      const loyaltySeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Returns",
          xAxis: loyaltyXAxis,
          yAxis: loyaltyYAxis,
          valueYField: "returns",
          categoryXField: "segment",
        })
      );
      loyaltySeries.columns.template.setAll({
        tooltipText: "{categoryX}: {valueY}$ \nReturn Rate: {returnRate.formatNumber('#.#')}%",
        cornerRadiusTL: 5,
        cornerRadiusTR: 5,
        interactive: true
    });
 
  
      loyaltySeries.columns.template.setAll({
        cornerRadiusTL: 5,
        cornerRadiusTR: 5
      });
  
      loyaltySeries.appear(1000, 100);
      chartRef.current = { chart, loyaltySeries, loyaltyXAxis };
    }
  }

  useEffect(() => {
      renderChart();
      return () => {
        if (rootRef.current) {
            rootRef.current.dispose();
            rootRef.current = null;
        }
      }
    }, [props.chartData]);
      
  useEffect(() => {
      if (chartRef.current && props.chartData && props.chartData?.length > 0) {
          let data = formatData(props.chartData);
          chartRef.current.loyaltyXAxis.data.setAll(data);
          chartRef.current.loyaltySeries.data.setAll(data);
      }else if (chartRef.current && props.chartData?.length === 0) {
        rootRef.current?.dispose();
        rootRef.current = null;
        chartRef.current = null;
      } else {
        renderChart();
      }
  }, [props.chartData]);
      
  const formatData = (data)=> {
        data.forEach(element => {
            element.segment = new Segment().getSegmentObject(element.segment)?.label;
            element.returns = parseFloat(element.returns);
        });
        return data;
  }

  return (
    <>
      {props?.chartData?.length === 0 ?
              <div style={{paddingTop:'6vw', display:'flex', flexDirection:"column", justifyContent:"space-between"}}>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
              </div>
        : ""}
      <div id={props.id} style={{ width: "100%", height: "41vh" }}></div>
    </>
  );
};

export default BarChart;
