import {useFeatureFlagEnabled} from "posthog-js/react";

/**
 * 
 * @param {string} id Id of the toggle
 * @param {string} name Name of the toggle
 * @param {Boolean} checked If the toggle is toggled on or off
 * @param {Boolean} isDisabled If the toggle is Disabled
 * @param {string} value value of the toggle ("ON" or "OFF")
 * @param {function} onChange event to trigger when switching the switch on or off
 * @param {string} tooltip tooltip to  be added of hover of the toggle
 * @param {string} addedClassName external added className for the main div
 * @returns 
 */
const SwitchToggle = ({
  id,
  name,
  checked,
  isDisabled,
  value,
  onChange,
  tooltip,
  addedClassName,
  ...props // other props that needs to be added. like: aria-label
}) => {
    let labelClassName = isDisabled? "switchToggle disabled" :"switchToggle";
    let inputClassName = "checkbox";
    let spanClassName = (isDisabled? "switchSlider disabled" : "switchSlider") +" round";
    const useNewComponents = useFeatureFlagEnabled('use_new_components');

    const startOnChange=(e)=>{
      if(!isDisabled){
        onChange(e);
      }
    }
  return (
    <div className={addedClassName} uk-tooltip = {tooltip}>
      <label className={labelClassName}>
          <input type={inputClassName} checked={checked} id={id} name={name} value={value} onChange={(e)=>{startOnChange(e)}}/> {/* this input is hidden */}
          <span className={(useNewComponents ? "new_components " : "") + spanClassName}></span>
      </label>
    </div>
  );
};

// default props
SwitchToggle.defaultProps = {
  id: "switchToggle",
  isDisabled: false
};

export default SwitchToggle;
