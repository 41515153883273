import PropTypes from "prop-types";
import { BUTTON_DROPDOWN_VARIANT, BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../../class/constants";
import { lang } from "../../language/messages_en";
import Button from "../../newComponents/Button";
import ButtonDropdown from "../../newComponents/ButtonDropdown";

const AddFilterButton = ({ onBtnClick, dropdownOpen }) => (
  <ButtonDropdown
    id="filter-by-btn"
    className="button-drop-filter-by-btn"
    type={BUTTON_TYPE.DEFAULT}
    size={SIZES.DEFAULT}
    placeholder={lang.basic_filter.add_filter}
    variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
    onBtnClick={onBtnClick}
    aria-expanded={dropdownOpen}
    mode={"dark"}
  />
);

const AddFilterIconButton = ({ onBtnClick }) => (
  <Button
    id="filter-by-btn-icon"
    className="button-drop-filter-by-btn"
    type={BUTTON_TYPE.DEFAULT}
    size={SIZES.ICON}
    leftIcon={<i className="far fa-plus" />}
    variant={BUTTON_VARIANT.SECONDARY}
    onBtnClick={onBtnClick}
    uk-tooltip={"Add a new quick filter"}
  />
);

const VectorButton = ({ onBtnClick, vectorName, numberOfSelectedEntities = 0, onVectorRemove }) => (
  <div className="vector-filter-container">
    <Button
      id={`vector-filter-btn-${vectorName?.replace(/ /g, "_")}`}
      className="button-drop-filter-by-btn vector-filter-btn"
      type={BUTTON_TYPE.DEFAULT}
      size={SIZES.DEFAULT}
      label={vectorName}
      variant={BUTTON_VARIANT.SECONDARY}
      onBtnClick={onBtnClick}
      rightIcon={numberOfSelectedEntities > 0 ? <span className="filters-label">{numberOfSelectedEntities}</span> : null}
    />
    <Button
      id={`remove-vector-filter-btn-${vectorName?.replace(/ /g, "_")}`}
      className="button-drop-filter-by-btn remove-vector-filter-btn"
      type={BUTTON_TYPE.DEFAULT}
      size={SIZES.ICON}
      leftIcon={<i className="far fa-times" />}
      variant={BUTTON_VARIANT.SECONDARY}
      onBtnClick={onVectorRemove}
      uk-tooltip={"Clear"}
    />
  </div>
);

AddFilterButton.propTypes = {
  onBtnClick: PropTypes.func.isRequired,
  dropdownOpen: PropTypes.bool,
};
AddFilterIconButton.propTypes = {
  onBtnClick: PropTypes.func.isRequired,
};
VectorButton.propTypes = {
  onBtnClick: PropTypes.func.isRequired,
  vectorName: PropTypes.string,
  numberOfSelectedEntities: PropTypes.number,
  onVectorRemove: PropTypes.func.isRequired,
};

export { AddFilterButton, AddFilterIconButton, VectorButton };
