import { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { convertPxToViewport } from "../../class/formatting";
import { Skeleton } from "@mui/material";
import { formatValNumber } from "../../class/format";
import { FormatTypes } from "../../class/constants";
import { abbreviateNumber } from "../../class/number";

const TrenChart = (props) => {
    const chartRef = useRef(null);
    const rootRef = useRef(null);
    const renderChart = () => {
      if (!rootRef.current) {
        const root = am5.Root.new("returnsChart");
        rootRef.current = root;
        root._logo?.dispose();

        const chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                height: am5.percent(80),
            })
        );

        chartRef.current = chart;

        const xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "period",
                renderer: am5xy.AxisRendererX.new(root, {}),
            })
        );

        const yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: am5xy.AxisRendererY.new(root, {}),
            })
        );          
        const returnsSeries = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: "Returns",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "returns",
                categoryXField: "period",
                stroke: "#ce1010",
            })
        );

        returnsSeries.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: undefined,
            sprite: am5.Circle.new(root, {
              radius: 4,
              fill: "#ce1010"
            })
          })
        })

        const salesSeries = chart.series.push(
            am5xy.LineSeries.new(root, {
                name: "Sales",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "sales",
                categoryXField: "period",
                stroke: "#21a10c",
            })
        );

        salesSeries.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: undefined,
            sprite: am5.Circle.new(root, {
              radius: 4,
              fill: "#21a10c"
            })
          })
        })
        salesSeries.appear(1000, 100);
        returnsSeries.appear(1000, 100);
        chart.xAxis = xAxis;
        chart.yAxis = yAxis;
        chart.returnsSeries = returnsSeries;
        chart.salesSeries = salesSeries;

        const legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50,
                y: am5.percent(100),
                dy: 30,
                height: am5.percent(20),
            })
        );
        legend.data.setAll(chart.series.values);
      }
      

    }

    useEffect(() => {
        renderChart();
        return () => {
          if (rootRef.current) {
              rootRef.current.dispose();
              rootRef.current = null;
          }
      }
    }, [props.chartData]);

    useEffect(() => {
        if (chartRef.current && props.chartData && props.chartData.length > 0) {
            let data = formatData(props.chartData);
            chartRef.current.xAxis.data.setAll(data);
            chartRef.current.returnsSeries.data.setAll(data);
            chartRef.current.salesSeries.data.setAll(data);
        }else if (chartRef.current && props.chartData.length === 0) {
          rootRef.current?.dispose();
          rootRef.current = null;
          chartRef.current = null;
        } else {
          renderChart();
        }
    }, [props.chartData]);

    const formatData = (data)=> {
      for (var e in data) {
          data[e].returns = Number(data[e].returns)//, FormatTypes.NUMERIC);
          data[e].sales = Number(data[e].sales)//, FormatTypes.NUMERIC);
      }
      return data;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: "1vw", height: "55vh" }}>
            <div className="bold">Returns vs Sales Trend</div>
            {props.chartData.length === 0 ?
              <div style={{paddingTop:'6vw', display:'flex', flexDirection:"column", justifyContent:"space-between"}}>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
                <Skeleton variant="text" sx={{ fontSize: '2rem' }} width={'100%'} height={'15%'} animation="wave"/>
              </div>
              : ""}
            <div id="returnsChart" style={{ width: "100%", height: "100%", alignSelf: "center" }}></div>
        </div>
    );
};

export default TrenChart;
