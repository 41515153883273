import { useMemo } from "react";
import ScenarioContainer from "./ScenarioContainer";
import { Footer } from "./Footer.js"
import "./styles/landingPage.css";
import { CUSTOM_REPORT, MENU_ITEM, MENU_ITEMS_SVG_COLOR } from "../class/constants";
import { lang } from "../language/messages_en";
import {splitAndGetURLLastHrefElement} from "../class/utils";
import Label from "../newComponents/Label.js";
/**
 * @description component displays the menu links for the clicked link group
 * @example clicked group is Financial analaytics then it displays all repors under financial analytics a long with the scenario container
 * @param {*} props 
 * @returns {@link ScenarioContainer} , menu links, {@link Footer}
 */
const LeftHandMenu = (props) => {
  var menuItemLinkGroup = useMemo(() =>  props?.menuLinks?.length > 0 && props?.menuLinks[0], [props?.menuLinks, props?.scenarioOptions,props?.location?.key]);
  var menuItems = useMemo(() => props?.menuLinks?.length > 0 && props?.menuLinks[0]?.children?.filter(e=>e[MENU_ITEM.COLUMNS.CATEGORY] !== MENU_ITEM.FIELDS.FEATURE), [props?.menuLinks, props?.scenarioOptions,props?.location?.key]);


  return (
    <div className="left-hand-menu">
      <div style={{ display: "flex", flexDirection: "column", rowGap: 30, height: "90%" }}>
        <div className="left-menu-header">
          <div className="left-menu-title-container">
            <div className="svgContainer" style={{ backgroundColor: MENU_ITEMS_SVG_COLOR["reporting"] }}>
              <img className={menuItemLinkGroup[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] === "actionable_insights" ? "scale-image" : ""}src={"/images/menu-svgs/" + menuItemLinkGroup[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] + ".svg"} />
            </div>
            <label className="menu-group-title">{menuItemLinkGroup && menuItemLinkGroup[MENU_ITEM.COLUMNS.MENU_ITEM_DISPLAY_NAME]}</label>
          </div>
          <div className="left-menu-description-container">
          <div className="left-menu-description">{menuItemLinkGroup && menuItemLinkGroup[MENU_ITEM.COLUMNS.MENU_ITEM_DESCRIPTION]}</div>
          </div>
          {!props.isScenarioHidden && (
            <ScenarioContainer
              scenarioOptions={props?.scenarioOptions}
              defaultScenarioId={props?.selectedState}
              saveScenario={props?.saveScenario}
              isReadOnly={props?.isScenarioDisabled || props?.isScenarioDisabledProps}
            />
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16, height: "80%" }}>
          <label className={"reports-title"}>{menuItems?.length ? lang.reports_label : ""}</label>
          <div className="menu-links-container">
            {menuItems?.length > 0 &&
              menuItems.map(function (item) {
                return (
                  <div
                    key={item[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME]}
                    highlighted={String(item[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] === splitAndGetURLLastHrefElement(props.history.location.pathname))}
                    id={`menu-link-${item[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME]}`}
                    className="menu-link"
                    url={
                      "/" + item.url
                    } 
                    redirect_only="true"
                    onClick={(e) => props?.goToReport(e)}
                    uk-tooltip={props?.isMenuCollapsed ? "title:" + item[MENU_ITEM.COLUMNS.MENU_ITEM_DISPLAY_NAME] + ";pos: right" : "title:"}
                    is_custom_report={`${item.category === CUSTOM_REPORT}`}
                    parent_machine_name={`${menuItemLinkGroup.parent_machine_name}`}
                    machine_name={`${menuItemLinkGroup.menu_item_machine_name}`}
                    is_tableau={`${item.is_tableau}`}
                    menu_item_machine_name={`${item.menu_item_machine_name}`}
                  >
                    <img
                      style={{ minWidth: "1.2vw", maxWidth: "1.2vw", pointerEvents:"none" }}
                      className={item[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME]=== "return_insights" ? " scale-image" : ""}
                      src={
                        menuItemLinkGroup[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] === MENU_ITEM.FIELDS.REPORT_CONFIGURATIONS
                          ? `/images/menu-svgs/configuration.svg`
                          : item.category === CUSTOM_REPORT
                          ? `/images/menu-svgs/custom-report.svg`
                          : menuItemLinkGroup[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] === MENU_ITEM.FIELDS.GENERAL_SETTINGS
                          ? `/images/menu-svgs/general_settings_menu.svg`
                          : `/images/menu-svgs/${item[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME]}.svg`
                      }
                    />
                    <label className="menu-link-name" style={{pointerEvents:"none"}}>{item[MENU_ITEM.COLUMNS.MENU_ITEM_DISPLAY_NAME]}</label>
                    {!props?.isMenuCollapsed && item[MENU_ITEM.COLUMNS.MENU_ITEM_MACHINE_NAME] === "return_insights" && <Label size="small" value="Beta"/>}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Footer
        className={"left-menu-footerContainer"}
        footerBody={
          <> {/* Adding 2 image icons to create a fading effect between them */}
            <img className={"footer-logo favicon-logo"} src="/images/footer-logo.png" />
            <img className={"footer-logo pi-platform-logo"} src="/images/login-logo.png" /> 
          </>
        }
      />
    </div>
  );
};

export default LeftHandMenu;
