import { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { useAuthenticated } from "../CustomHooks";
import ManageAccruals from "../../sections/manageAccruals/ManageAccruals";
import ErrorBoundary from "../../ErrorBoundary";
import DropDown from "../../newComponents/DropDown";
import Button from "../../newComponents/Button";
import { LoaderSkeleton } from "../LoaderSkeleton";
import { ReportHeader } from "../ReportHeader";
import { lang } from "../../language/messages_en";
import {
  ALL_WIDGETS,
  API_URL,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  DASHBOARDS,
  DROPDOWN_TYPE,
  FISCAL_YEARS,
  HEADER_ELEMENT, MENU_ITEM,
  SIZES
} from "../../class/constants";
import { getClientPeriods, getEngineScenarios, getFiscalYears } from "../api/apiDataModeling";
import {renderBreadcrumbsAndDescription, renderTitle} from "../functions/componentFunctions";
import { updateVectorState } from "../../actions/scenariosActions";
import { getVectors, logout } from "../api/api";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../class/networkUtils";
import { capitaliseFirstLetterAfterChar, copyObjectValues, findOptionByKey, parseBoolean } from "../../class/utils";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from "posthog-js/react";

/**
 * @author Sandra Abdelkhalek
 * @description This component holds the the header of the manage accruals screen and its table
 * @returns Report Header, {@link ManageAccruals}
 */
const ManageAccrualsWrapper = (props) => {
  const { userAllowedMenuLinks } = props;
  const { userSettings } = props;

  const reportTitle = ALL_WIDGETS.TITLES.DATA_MODELING.MANAGE_ACCRUALS;
  const profitFormat = ALL_WIDGETS.MANAGE_ACCRUALS_NEW;

  /** References & react utility */
  const reportRef = useRef();
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  /** Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

  const [clientPeriodsState, setClientPeriodsState] = useState([]);
  const [vectorState, setVectorState] = useState({});
  const [scenariosState, setScenariosState] = useState([]);
  const [headerElements, setHeaderElements] = useState([]);

  const [isMainView, setIsMainView] = useState(true); // main screen meaning the main table of manage accruals
  const [fiscalYearsOptions, setFiscalYearsOptions] = useState([]);
  const [fiscalYear, setFiscalYear] = useState();
  const [isFiscalYearDisabled, setFiscalYearDisabled] = useState(false);
  const [overlayMsg, setOverlayMsg] = useState("");
  const [showGreyOverLay, setShowGreyOverLay] = useState(false);
  const useNewComponents = useFeatureFlagEnabled('use_new_components');

  /**
   * @function useEffect()
   * @description on screen initialization if isAuthenticated is false user gets logged out
   * isAuthenticated is a boolean read from a custom hook named useAuthenticated
   **/
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  /**
   * @function useEffect()
   * @description On component mount, fetch client periods & engine scenarios
   */
  useEffect(() => {
    getClientPeriods(undefined, undefined, setClientPeriodsState, profitFormat, params, userSettings);
    getEngineScenarios(userAllowedMenuLinks, reportTitle, scenariosState, undefined, false, setScenariosState, true, history,lang.observability.manage_accruals.key);
  }, []);


  /**
   * @function useEffect()
   * @description after fetching the scenarios, fetch fiscal years and vectors
   */
  useEffect(() => {
    getFiscalYears(scenariosState, setFiscalYearsOptions, userSettings, profitFormat, hanleFiscalYearChange,lang.observability.manage_accruals.key);
    getVectors(
      scenariosState,
      dispatch,
      updateVectorState,
      setVectorState,
      reportTitle,
      profitFormat,
      props,
      false,
      [],
      userSettings,
      vectorState,
      undefined
    );
  }, [scenariosState]);

  /**
   * @function useEffect()
   * @description render the header elements
   */
  useEffect(() => {
    if (Object.keys(scenariosState).length > 0 && Object.keys(clientPeriodsState).length > 0) {
      let headerElements = [];
      headerElements = useNewComponents ? getNewHeaderElements() : getHeaderElements();
      setHeaderElements(headerElements);
    }
  }, [scenariosState, clientPeriodsState, reportTitle, isMainView, fiscalYear]);

  /**
   * @function handleScenarioChange()
   * @description Handle scenario dropdown change. The trigger to refetch data is handled in componentDidUpdate() in ManageAccruals,js
   * @param {*} newScenario 
   */
  const handleScenarioChange = (newScenario) => {
    var tempState = copyObjectValues(scenariosState);
    tempState.scenario = newScenario;
    tempState.scenarios = [newScenario.value];
    tempState.scenarioObjects = [newScenario];
    tempState.nextScenarios = [newScenario];
    tempState.nextScenariosObjects = [newScenario];
    tempState.scenarioStatus = newScenario.scenario_status;

    reportRef.current?.onChangeScenario();
    setScenariosState(tempState);
  };

  
  /**
   * @function hanleFiscalYearChange()
   * @description This function handles changing the fiscal year value.
   * When we change it, we set the state to the new value which will trigger the componentDidUpdate in ManageSAccruals.js.
   * In componentDidUpdate, we fetch the new data.
   * @param {*} e 
   */
  const hanleFiscalYearChange = (e) => {
    setFiscalYear(e);
    checkIfFiscalYearNotBuilt(e);
    reportRef.current?.setChosenFiscalYear(e); //this is responsable to display the small loader in the table
  };

  /**
   * @function checkIfFiscalYearNotBuilt()
   * @description This function checks if the selected year is built or complete and displays the grey overlay if needed.
   * @param {*} year 
   */
  const checkIfFiscalYearNotBuilt = (year) => {
    const fiscalComplete = year[FISCAL_YEARS.is_complete];
    const fiscalBuilt = year[FISCAL_YEARS.all_built];

    setShowGreyOverLay(fiscalComplete && !fiscalBuilt);
    setOverlayMsg(fiscalComplete && !fiscalBuilt ? lang.accruals.build_fiscal_year : "");
  };

  /**
   * @function setAccrualsNotBuiltRefYears()
   * @description This function handles the overlay message based on not built years count
   * @param {*} notBuiltYears 
   */
  const setAccrualsNotBuiltRefYears = (notBuiltYears) => {
    let fiscalOverlayMsg =
      fiscalYear[FISCAL_YEARS.fiscal_year] + lang.accruals.accrual_overlay_message_1.replace("$not_built_years$", notBuiltYears.join(", "));
    fiscalOverlayMsg += notBuiltYears.length > 1 ? lang.accruals.accrual_overlay_message_2_multiple : lang.accruals.accrual_overlay_message_2_single;

    setShowGreyOverLay(true);
    setOverlayMsg(fiscalOverlayMsg);
  };

  /**
   * @function goToBuild()
   * @description This function redirects the user to Configure and Build screen
   */
  const goToBuild = () => {
    props.goToReport(undefined, undefined, undefined, "/" + DASHBOARDS.WIDGET.CONFIGURATION + "/" + MENU_ITEM.FIELDS.DATA_MODELING + "/" + MENU_ITEM.FIELDS.CONFIGURE_BUILD);
  };

  /**
   * @function getHeaderElements()
   * @description This function renders reportTile, save button and reset button in case the user makes any change
   * @returns array of components to be rendered in {@link ReportHeader} component
   */
  const getHeaderElements = () => {
    let headerElements = [];
    let fiscalYears = isMainView ? fiscalYearsOptions : fiscalYearsOptions.filter((e) => e[FISCAL_YEARS.is_complete]);

    headerElements.push(
      <div style={{ display: "flex", alignItems: "center", columnGap: "0.42vw", width: "100%" }}>
        {renderTitle(reportTitle)}
        <i className="fal fa-info-circle uk-cursor-pointer" uk-tooltip={lang.accruals.tooltip_title} />
        <DropDown
          id="stage-select-set"
          className="width-200 input__dropdown"
          name={HEADER_ELEMENT.SCENARIO}
          value={findOptionByKey(scenariosState.scenarioList, scenariosState.scenario)}
          onChange={handleScenarioChange}
          options={scenariosState.scenarioList}
          // disabled={isScenarioDisabled}
          type={DROPDOWN_TYPE.INPUT}
        />

        <DropDown
          id="FiscalYears"
          className="width-200 input__dropdown"
          name="FiscalYears"
          value={findOptionByKey(fiscalYearsOptions, fiscalYear)}
          onChange={hanleFiscalYearChange}
          options={fiscalYears}
          disabled={isFiscalYearDisabled}
          type={DROPDOWN_TYPE.INPUT}
        />

        <div className={"gap_between_buttons"} style={{ display: "flex", flexDirection: "row", marginLeft: "auto", alignItems: "center" }}>
          {isMainView ? (
            <>
              <Button
                id="header-next"
                label={lang.navigation.btns.save}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => saveAccruals()}
              />
            </>
          ) : (
            <>
              <Button
                id="cancel"
                label={lang.navigation.btns.cancel}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={cancelAndGoBackToMainScreen}
              />
              <Button
                id="submit-accrual"
                label={lang.modal.buttons.submit}
                variant={BUTTON_VARIANT.PRIMARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={submitAndGoBackToMainScreen}
              />
            </>
          )}
        </div>
      </div>
    );

    return headerElements;
  };

  const renderHeaderFirstRow = () => {
    return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription)
  }

  const renderHeaderSecondRow = (fiscalYears) => {
    return (
        <>
          <i className="fal fa-info-circle uk-cursor-pointer" uk-tooltip={lang.accruals.tooltip_title}/>
          <DropDown
              id="stage-select-set"
              className="width-200 input__dropdown"
              name={HEADER_ELEMENT.SCENARIO}
              value={findOptionByKey(scenariosState.scenarioList, scenariosState.scenario)}
              onChange={handleScenarioChange}
              options={scenariosState.scenarioList}
              // disabled={isScenarioDisabled}
              type={DROPDOWN_TYPE.INPUT}
          />

          <DropDown
              id="FiscalYears"
              className="width-200 input__dropdown"
              name="FiscalYears"
              value={findOptionByKey(fiscalYearsOptions, fiscalYear)}
              onChange={hanleFiscalYearChange}
              options={fiscalYears}
              disabled={isFiscalYearDisabled}
              type={DROPDOWN_TYPE.INPUT}
          />

          <div className={"gap_between_buttons"}
               style={{display: "flex", flexDirection: "row", marginLeft: "auto", alignItems: "center"}}>
            {isMainView ? (
                <>
                  <Button
                      id="header-next"
                      label={lang.navigation.btns.save}
                      variant={BUTTON_VARIANT.PRIMARY}
                      size={SIZES.DEFAULT}
                      type={BUTTON_TYPE.DEFAULT}
                      onBtnClick={() => saveAccruals()}
                  />
                </>
            ) : (
                <>
                  <Button
                      id="cancel"
                      label={lang.navigation.btns.cancel}
                      variant={BUTTON_VARIANT.SECONDARY}
                      size={SIZES.DEFAULT}
                      type={BUTTON_TYPE.DEFAULT}
                      onBtnClick={cancelAndGoBackToMainScreen}
                  />
                  <Button
                      id="submit-accrual"
                      label={lang.modal.buttons.submit}
                      variant={BUTTON_VARIANT.PRIMARY}
                      size={SIZES.DEFAULT}
                      type={BUTTON_TYPE.DEFAULT}
                      onBtnClick={submitAndGoBackToMainScreen}
                  />
                </>
            )}
          </div>
        </>
    );
  }

  const getNewHeaderElements = () => {
    let headerElements = [];
    let fiscalYears = isMainView ? fiscalYearsOptions : fiscalYearsOptions.filter((e) => e[FISCAL_YEARS.is_complete]);

    headerElements.push(
        <div style={{display: "flex", width: "100%", alignItems: "center"}}
             className='new_header_menu first_header_row gap_between_buttons'>
          {renderHeaderFirstRow()}
        </div>
    );

    headerElements.push(
        <div
            className="new_header_menu second_header_row gap_between_buttons"
            style={{display: "flex", width: "100%", padding: "0.41667vw 0"}}
        >
          {renderHeaderSecondRow(fiscalYears)}
        </div>
    );

    return headerElements;
  };

  /**
   * @function saveAccruals()
   * @description This function is triggered when we click on 'Save' button and 'Save & Continue to build' button.
   * if we click to 'Save & Continue to build' button, redirectToBuild = true => changes will be saved and the user will be redirected to Configure & build screen.
   * @param {*} redirectToBuild
   */
  const saveAccruals = (redirectToBuild) => {
    reportRef.current?.saveChanges(redirectToBuild);
  };

  /**
   * @function submitAndGoBackToMainScreen()
   * @description This function is triggered when we click on 'Submit' button in evaluate/predict screen
   */
  const submitAndGoBackToMainScreen = () => {
    reportRef.current?.unfocusAccrual(true);
  };

  /**
   * @function cancelAndGoBackToMainScreen()
   * @description This function is triggered when we click on 'Cancel' button in evaluate/predict screen
   */
  const cancelAndGoBackToMainScreen = () => {
    reportRef.current?.unfocusAccrual();
  };

  const showSkeleton = !(Object.keys(scenariosState).length > 0 && Object.keys(clientPeriodsState).length && headerElements?.length > 0);

  return (
      <ErrorBoundary>
        <Suspense fallback={<p id="loading">Loading...</p>}>
          {showSkeleton && <LoaderSkeleton/>}
          <div
              id={"main-component-container"}
              className={"main-component-container " + (showSkeleton ? "hidden" : "")}
              style={{"--banner-height": 0}}
          >
            <div className={"header-banner-div-hidden"}/>
            <div className="main-report-header">
              <ReportHeader headerElements={headerElements}/>
            </div>
            <div className="main_report">
              {showGreyOverLay && (
              <div className="overlay-div accruals-overlay-div">
                {overlayMsg}
                <div className="overlay-secondary-text">
                  {lang.accruals.accrual_overlay_action}
                  <p className="uk-link uk-text-decoration-underline" onClick={goToBuild}>
                    {capitaliseFirstLetterAfterChar(ALL_WIDGETS.BUILD)}
                  </p>
                </div>
              </div>
            )}
            {Object.keys(scenariosState).length > 0 && Object.keys(clientPeriodsState).length && (
              <ManageAccruals
                ref={reportRef}
                costCenter={userSettings.costCenter}
                fiscalYear={fiscalYear}
                fiscalYears={fiscalYearsOptions}
                FY_COST_Threshold={userSettings?.FYThresholdPerc}
                goToBuild={goToBuild}
                periods={clientPeriodsState.periods}
                R_Squared_Threshold={userSettings?.rSquaredThresholdPercentage}
                scenarioId={scenariosState?.scenario?.value}
                setNotBuiltRefYears={setAccrualsNotBuiltRefYears}
                setIsMainAccrualView={setIsMainView}
                // toggleAccrualsView={this.headerRef.toggleAccrualsView}
                toggleYearDisablement={setFiscalYearDisabled}
                vectorOptions={vectorState.vectorOptions}
              />
            )}
          </div>
        </div>
      </Suspense>
    </ErrorBoundary>
  );
};

export { ManageAccrualsWrapper };
