import {ReactComponent as CopySVG} from "../../styles/images/chatbot/copy.svg";
import {ReactComponent as BotSVG} from "../../styles/images/chatbot/bot.svg"
import {extractInitials} from "../../class/jqueries";
import {PROFIT_ASSIST} from "../../class/constants";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const ChatBubble = ({
    id,
    name,
    content,
    timePart,
    datePart,
    messageType,
    launchToast
}) =>{
    const senderIsMe = messageType === PROFIT_ASSIST.USER_MESSAGE;

    const setTableAndTextElements = (responseContainer, htmlParts, plainTextParts) => {
        // Loop on child elements to check if table or text
        responseContainer.childNodes.forEach((children) => {
            if (children.tagName === "TABLE") {
                // If the child is a table, add its HTML and plain text representation
                htmlParts.push(children.outerHTML);
                plainTextParts.push(children.innerText);
            } else {
                // children.forEach((child) => {
                    if (children.tagName === "UL" || children.tagName === "OL") {
                        // Handle lists (unordered or ordered)
                        htmlParts.push(children.outerHTML);
                        // Extract plain text for the list while preserving bullet points or numbering
                        let listItemsText = [];
                        children.querySelectorAll("li").forEach((li) => {
                            listItemsText.push(`- ${li.innerText}`); // Add a dash (-) or any other bullet character
                        });
                        plainTextParts.push(listItemsText.join("\n")); // Join items with line breaks
                    } else {
                        // for text elmelents
                        if (children.innerText) {
                            htmlParts.push(`<p>${children.innerText}</p>`);
                            plainTextParts.push(children.innerText);
                        }
                    }
                // })

            }
        });
    }

    const handleCopy = () => {
        let messageId = event.target.parentElement.id.split("copy_")[1];
        // Container having all elements
        const responseContainer = document.getElementById(`bubble_${messageId}`);

        if (responseContainer) {
            let htmlParts = [];
            let plainTextParts = [];

            setTableAndTextElements(responseContainer, htmlParts, plainTextParts);

            // Use Clipboard API to copy the combined content
            navigator.clipboard.write([
                new ClipboardItem({
                    "text/html": new Blob([htmlParts.join("\n")], {type: "text/html"}), // Combine HTML parts
                    "text/plain": new Blob([plainTextParts.join("\n\n")], {type: "text/plain"}), // Combine plain text parts
                }),
            ])
                .then(() => {
                    launchToast(true,PROFIT_ASSIST.COPY_SUCCESSFUL)
                })
                .catch((err) => {
                    console.error("Failed to copy content: ", err);
                });
        } else {
            console.error("Response container not found!");
        }

    };

    return (
        <div key ={id} id={"message_" + id} className={"bubble-main-container" +  (senderIsMe ? " right" : " left")}>
            <div className={"timestamp-container" + (senderIsMe ? " right" : " left")}>
                {/*{senderIsMe && <span className="green_dot"></span>}*/}
                <span className="hour_text">{timePart}</span>
                <span className="divider_text">|</span>
                <span className="date_text">{datePart}</span>
            </div>
            <div className="bubble-container">
              {!senderIsMe && <BotSVG/>}
              <div id={"bubble_" + id} className="bubble" style={{backgroundColor: senderIsMe ? 'white' : 'rgba(245, 245, 245, 1)'}}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {content}
                    </ReactMarkdown>
              </div>
              {senderIsMe && <div className="avatar">{extractInitials(name).toUpperCase()}</div>}
            </div>
            {!senderIsMe && 
              <div id={"copy_" + id} className="copy_image_container">
                  <CopySVG id={"copy_" + id} onClick={()=>{handleCopy()}} style={{ padding: '5px 10px', cursor: 'pointer', height: '25px', width: '40px' }}/>
              </div>
            }
            
      </div>
    );
}
export default ChatBubble;