import {parseBoolean} from "../class/utils";
import {getLocalStorageValueByParameter} from "../class/common";

var isNewDesign = true;
function createCheckbox() {
    var checkbox = document.createElement('INPUT');
    checkbox.type = 'checkbox';
    if (isNewDesign){
        if(parseBoolean(getLocalStorageValueByParameter("useNewComponents"))){
            checkbox.classList.add('new_components');
        }
        checkbox.classList.add('input-checkbox-default');
    } 
    return checkbox;
}

export {createCheckbox};