import { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import NewBanner from "../../components/NewBanner";
import Button from "../../newComponents/Button";
import { ReportHeader } from "../ReportHeader";

import { API_URL, BUTTON_TYPE, BUTTON_VARIANT, DROPDOWN_TYPE, HEADER_ELEMENT, SCENARIO_STATUS, SIZES } from "../../class/constants";
import { lang } from "../../language/messages_en";

import { useRef } from "react";
import { logout } from "../../class/common";
import { capitalizeFirstLetter, copyObjectValues, findOptionByKey } from "../../class/utils";
import ErrorBoundary from "../../ErrorBoundary";
import { Loader } from "../../form/elements";
import DropDown from "../../newComponents/DropDown";
import ProfitStackLineDefinitions from "../../sections/defineProfitStackLines/ProfitStackLineDefinitions";
import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import { updatePeriodsStatusState, updateScenarioState, updateVectorState } from "../../actions/scenariosActions";
import { getPeriodsStatus, getVectors } from "../api/api";
import { useAuthenticated } from "../CustomHooks";
import { renderTitle, tablesToExcel } from "../functions/componentFunctions";
import { LoaderSkeleton } from "../LoaderSkeleton";
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from "../../class/networkUtils";
import { DataObjectSharp } from "@mui/icons-material";
/**
 * @author Sandra Abdelkhalek
 * @description component renders title, scenario dropdown, export button,  expand all button with  {@link ProfitStackLineDefinitions} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link ProfitStackLineDefinitions}
 */
const ProfitStackLineDefinitionsWrapper = (props) => {
  const userAllowedMenuLinks = props.userAllowedMenuLinks;
  const userSettings = props.userSettings;
  const propsScenarioState = props.scenarioState;

  /** References & react utility */
  const dispatch = useDispatch();
  const reportRef = useRef();

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

  /** State */
  const [periodsStatusState, setPeriodsStatusState] = useState({});
  const [vectorState, setVectorState] = useState({});


  /** Header Related States */
  const [headerElements, setHeaderElements] = useState([]);
  const [profitFormat, setProfitFormat] = useState(lang.profit_stack_line_definitions.profit_stack_line_definitions_title);
  const [reportTitle, setReportTitle] = useState(
    capitalizeFirstLetter(lang.profit_stack_line_definitions.profit_stack_line_definitions_title)
  );

  const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [scenarioState, setScenarioState] = useState(propsScenarioState);
  const [clientCostTerms, setClientCostTerms] = useState([]);

  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    getClientCostTerms();
  }, []);

  useEffect(() => {
    /** When comming from header info, inherit the already selected scenario */
    if (window.scenario && reportRef.current && clientCostTerms.length > 0) {
      handleScenarioChange(JSON.parse(window.scenario));
      window.scenario = undefined;
    }
  }, [propsScenarioState, reportRef.current, clientCostTerms]);

  useEffect(()=>{
    if (Object.keys(vectorState).length > 0 && Object.keys(periodsStatusState).length > 0 ) {
      reportRef.current?.pageComponent.getPSLDefinitonsData(scenarioState.scenario, clientCostTerms);
    }
  },[vectorState, periodsStatusState])

  useEffect(() => {
    if (scenarioState) {
      setPeriodsStatusState({});
      setVectorState({});
      getPeriodsStatus(
        scenarioState,
        dispatch,
        updatePeriodsStatusState,
        setPeriodsStatusState,
        props,
        profitFormat,
        userSettings,
        12
      );
      getVectors(
        scenarioState,
        dispatch,
        updateVectorState,
        setVectorState,
        reportTitle,
        profitFormat,
        props,
        false,
        [],
        userSettings,
        vectorState,
        null,
        "",
        true
      );
      setScenarioBannerVisible(scenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
    }
  }, [scenarioState]);

  useEffect(() => {
    if (Object.keys(scenarioState).length > 0) {
      let headerElements = getHeaderElements();
      setHeaderElements(headerElements);
    }
  }, [scenarioState, isExpanded]);

  /** Header elements actions */
  const expandClick = () => {
    reportRef.current?.pageComponent?.expandAll();
    setExpanded(true);
  };

  const collapseClick = () => {
    reportRef.current?.pageComponent?.collapseAll();
    setExpanded(false);
  };

  const getClientCostTerms = () => {
    let query = {
        action: "getClientCostTerms"
    }
    let onThenCallback = function (data) {
        let emptyOption = [{value:"Not Defined", label:"Not Defined", cost_term_id:"",psLabel:""}]
            let options = data.data;
            options.map(function(item){
                item.value = item.cost_term_value;
                item.label = item.cost_term_value;
                item.cost_term_id = item.cost_term_id.toString();
                item.psLabel =  item.cost_term_value.charAt(0).toUpperCase();
            });
            setClientCostTerms(emptyOption.concat(options));
    }

    let options = {
      [FETCHAPI_PARAMS.funcName]: "getClientCostTerms",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.config,
      [FETCHAPI_PARAMS.showLoader]: true,
      [FETCHAPI_PARAMS.path]: API_URL.OUTPUT,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.screenName]: lang.observability.output.psl_definition.screen_name,
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.psl_definition.request_description.cost_terms,
    };
    fetchAPI(options);
}

  const handleScenarioChange = (newScenario) => {
    let tempState = copyObjectValues(scenarioState);
    tempState.scenario = newScenario.value;
    tempState.scenarios = [newScenario.value];
    tempState.scenarioObjects = [newScenario];
    tempState.nextScenarios = [newScenario.value];
    tempState.nextScenariosObjects = [newScenario];
    tempState.scenarioStatus = newScenario.scenario_status;

    setScenarioState(tempState);
    setExpanded(false);
    dispatch(updateScenarioState(tempState));
  };

  const tablesToExcelWrapper = () => {
    tablesToExcel(reportTitle, userSettings, [], [], scenarioState, reportRef);
  };

  /** Header elements */
  const getHeaderElements = () => {
    let headerElements = [];
    headerElements.push(
      <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="first_header_row gap_between_buttons">
        {renderTitle(reportTitle)}
      </div>
    );

    headerElements.push(
      <div className="second_header_row gap_between_buttons" style={{ display: "flex", width: "100%", padding: "0.41667vw 0" }}>
        <div style={{ display: "flex", width: "100%" }}>
          {!isExpanded ? (
            <Button
              id="Expand_PS"
              label={"Expand All"}
              title={"Expand All"}
              variant={BUTTON_VARIANT.SECONDARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              leftIcon={<i className={"fal fa-plus-square fa-lg"} />}
              onBtnClick={expandClick}
            />
          ) : (
            <Button
              id="Collapse_PS"
              label={"Collapse all"}
              title={"Collapse all"}
              variant={BUTTON_VARIANT.SECONDARY}
              size={SIZES.DEFAULT}
              type={BUTTON_TYPE.DEFAULT}
              className="uk-display-none"
              leftIcon={<i className={"fal fa-minus-square fa-lg"} aria-hidden="true" />}
              onBtnClick={collapseClick}
            />
          )}
        </div>
        <div style={{ display: "flex", columnGap: "0.42vw", flexDirection: "row" }}>
          <DropDown
            id="select-set"
            className="width-200 input__dropdown"
            name={HEADER_ELEMENT.SCENARIO}
            value={findOptionByKey(scenarioState.scenarioList, scenarioState.scenario)}
            onChange={handleScenarioChange}
            options={scenarioState.scenarioList}
            disabled={false}
            type={DROPDOWN_TYPE.INPUT}
          />
          <Button
            id="Export"
            label={lang.header.titles.xls}
            title={lang.header.tooltips.xls}
            variant={BUTTON_VARIANT.SECONDARY}
            size={SIZES.DEFAULT}
            type={BUTTON_TYPE.DEFAULT}
            onBtnClick={tablesToExcelWrapper}
            leftIcon={<i className="fal fa-file-excel fa-lg" />}
          />
        </div>
      </div>
    );

    return headerElements;
  };

  let trackingData = window._pi_getTrackingDataObj();
  trackingData = trackingData === null ? "" : JSON.stringify(trackingData);
  let scenarioLabel = scenarioState?.scenarioObjects?.length > 0 ? scenarioState?.scenarioObjects[0].label : "";

  return (
    <>
      <Suspense fallback={<p id="loading">Loading...</p>}>
        <div
          id={"main-component-container"}
          className={"main-component-container"}
          style={{ "--banner-height": isScenarioBannerVisible ? "6%" : 0 }}
        >
          <div className={isScenarioBannerVisible ? "header-banner-div" : "header-banner-div-hidden"}>
            <Loader newLoader />

            <NewBanner
              bannerClassName={"header-banner scenario-banner"}
              labelClassName={"header-banner-label scenario-banner-label"}
              icon={<RoundInfoIcon className="info-banner-icon" />}
              label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
              body={
                <Button
                  id={"banner-dismiss-btn"}
                  label={lang.modal.buttons.dismiss}
                  variant={BUTTON_VARIANT.TERTIARY}
                  size={SIZES.DEFAULT}
                  type={BUTTON_TYPE.DEFAULT}
                  onBtnClick={() => setScenarioBannerVisible(false)}
                />
              }
            />
          </div>

          <div className="main-report-header">
            <ReportHeader headerElements={headerElements} />
          </div>
          <div className="main_report">
            <div id="main_report_container" className="main_report_container">
              {Object.keys(periodsStatusState).length > 0 &&
                headerElements.length > 0 && (
                  <ProfitStackLineDefinitions
                    ref={reportRef}
                    userSettingsState={userSettings}
                    userAllowedMenuLinks={userAllowedMenuLinks}
                    scenarioState={scenarioState}
                    periodsStatusState={periodsStatusState}
                    profitFormat={profitFormat}
                    reportTitle={reportTitle}
                    vectorState={vectorState}
                  />
                )}
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
};

export { ProfitStackLineDefinitionsWrapper };
