import { DATASET, PERIOD, PERIODS, header_comparison_values, period_calendar_type } from "../../class/constants";
import { getPeriodFromDate, getPreviousAdjacentPeriods, getPreviousBuiltPeriods, getPreviousYearPeriods } from "../../class/date";
import { copyObjectValues } from "../../class/utils";
import { lang } from "../../language/messages_en";
import cookie from "react-cookies";

function getFirstBuiltPeriod(builtPeriods, year) {
  if (!builtPeriods) {
    return null;
  }
  return builtPeriods
    .filter((p) => p[PERIOD.IS_BUILT] === true)
    .filter((f) => Number(f.value.split("P")[0]) === year)
    .sort((a, b) => (a.value > b.value ? 1 : -1))[0];
}

function getLastBuiltPeriod(builtPeriods, year) {
  if (!builtPeriods) {
    return null;
  }
  return builtPeriods
    .filter((p) => p[PERIOD.IS_BUILT] === true)[0];
}

function periodTileContent(tile, periods, periodsToCheck, checkSegmentGeneration, typeOfCheck) {
  let year = tile.year;
  let period = tile.value;

  let isActive = isPeriodActive(year + period, periods);
  let isBuilt = isPeriodBuilt(year + period, periodsToCheck);

  let tooltip = "title:";
  if (checkSegmentGeneration && tile.isDisabled) {
    tooltip = lang.period_not_generated;
  } else if (typeOfCheck === period_calendar_type.ACTIVE && !isActive) {
    tooltip = lang.period_not_activated;
  } else if (!checkSegmentGeneration && !isBuilt && typeOfCheck === period_calendar_type.BUILT) {
    tooltip = lang.period_not_built;
  }

  return <p uk-tooltip={tooltip}>{period}</p>
}

function isPeriodTileDisabled(year, period, periodsToCheck, checkSegmentGeneration, typeOfCheck) {  
  let isActive = isPeriodActive(year + period, periodsToCheck);
  let isBuilt = isPeriodBuilt(year + period, periodsToCheck);
  let isGenerated = isPeriodSegmentGenerated(year + period, 12, periodsToCheck);

  let isDisabled = false;
  if (checkSegmentGeneration) {
    isDisabled = !isGenerated;
  } else if (typeOfCheck === "active") {
    isDisabled = !isActive;
  } else {
    isDisabled = !isBuilt;
  }
  return isDisabled;
}

function isPeriodSegmentGenerated(period, rolling, builtPeriods) {
  return builtPeriods?.find(f => f.generated && f.value === period && f.rolling === rolling);
}

/**
 * @function checkIfAtLeastOnePeriodIsGenerated()
 * @description This function checks if at least one period is generated based on the rolling
 * @param {*} allPeriods 
 * @param {*} rolling 
 * @returns 
 */
function checkIfAtLeastOnePeriodIsGenerated(allPeriods, rolling) {
  return allPeriods?.filter(f => f.is_built && f.rolling === rolling)?.length > 0;
}

/** this function enables the previous periods of generated periods.
 * Ex: if 2022P12 is generated then enable its 11 previous periods (2022P01 -> 2022P11) in calendar.
 */
function enablePreviousPeriods(calendarPeriods, builtPeriods, checkSegmentGeneration) {
  if(!checkSegmentGeneration) {
    return calendarPeriods;
  }
  
  let rolling = 12; // we are using r12 because this logic is for landscape & erosion

  builtPeriods.map(period => {
      if(period.generated && period.rolling === rolling) {
        let prevPeriods = getPreviousBuiltPeriods(builtPeriods, period).slice(0, rolling - 1); // slice it based on rolling (here rolling=12)

        calendarPeriods?.map(m => {
          if(prevPeriods.map(p => p.value).includes(m.year + m.value) && m.isDisabled) {
            m.isDisabled = false;
          }
          return m;
        })
      }
  })

  return calendarPeriods;
}

function isPeriodBuilt(periodDate, builtPeriodsParam) {
  if (typeof periodDate === "string" && builtPeriodsParam) {
    let builtPeriodsFiltered = copyObjectValues(builtPeriodsParam)
      .filter((f) => f["is_built"])
      .map((p) => p.value);
    return builtPeriodsFiltered.includes(periodDate);
  }
  let builtPeriods = copyObjectValues(builtPeriodsParam);
  for (let opt in builtPeriods) {
    let startD = new Date(builtPeriods[opt][DATASET.START_DATE]);
    let endD = new Date(builtPeriods[opt][DATASET.END_DATE]);

    if (isNaN(startD.getTime()) || isNaN(endD.getTime())) {
      continue;
    }

    //nullify the time to only compare the dates
    periodDate.setHours(0);
    periodDate.setMinutes(0);
    periodDate.setSeconds(0);
    periodDate.setMilliseconds(0);

    startD.setHours(0);
    startD.setMinutes(0);
    startD.setSeconds(0);
    startD.setMilliseconds(0);

    endD.setHours(0);
    endD.setMinutes(0);
    endD.setSeconds(0);
    endD.setMilliseconds(0);

    if (
      periodDate >= startD &&
      periodDate <= endD &&
      builtPeriods[opt][PERIOD.IS_BUILT]
    ) {
      return true;
    }
  }
  return false;
}

function getDefaultPeriodYears(periodYears, year) {
  periodYears.push({
    year: year,
    value: PERIODS.P1.value,
    isSelected: false,
    isDisabled: false,
    startDate: year + "-01-01T00:00",
    endDate: year + "-01-31T23:59:59",
  });
  periodYears.push({
    year: year,
    value: PERIODS.P2.value,
    isSelected: false,
    isDisabled: false,
    startDate: year + "-02-01T00:00",
    endDate: year + "-02-28T23:59:59",
  });
  periodYears.push({
    year: year,
    value: PERIODS.P3.value,
    isSelected: false,
    isDisabled: false,
    startDate: year + "-03-01T00:00",
    endDate: year + "-03-31T23:59:59",
  });
  periodYears.push({
    year: year,
    value: PERIODS.P4.value,
    isSelected: false,
    isDisabled: false,
    startDate: year + "-04-01T00:00",
    endDate: year + "-04-30T23:59:59",
  });
  periodYears.push({
    year: year,
    value: PERIODS.P5.value,
    isSelected: false,
    isDisabled: false,
    startDate: year + "-05-01T00:00",
    endDate: year + "-05-31T23:59:59",
  });
  periodYears.push({
    year: year,
    value: PERIODS.P6.value,
    isSelected: false,
    isDisabled: false,
    startDate: year + "-06-01T00:00",
    endDate: year + "-06-30T23:59:59",
  });
  periodYears.push({
    year: year,
    value: PERIODS.P7.value,
    isSelected: false,
    isDisabled: false,
    startDate: year + "-07-01T00:00",
    endDate: year + "-07-31T23:59:59",
  });
  periodYears.push({
    year: year,
    value: PERIODS.P8.value,
    isSelected: false,
    isDisabled: false,
    startDate: year + "-08-01T00:00",
    endDate: year + "-08-31T23:59:59",
  });
  periodYears.push({
    year: year,
    value: PERIODS.P9.value,
    isSelected: false,
    isDisabled: false,
    startDate: year + "-09-01T00:00",
    endDate: year + "-09-30T23:59:59",
  });
  periodYears.push({
    year: year,
    value: PERIODS.P10.value,
    isSelected: false,
    isDisabled: false,
    startDate: year + "-10-01T00:00",
    endDate: year + "-10-31T23:59:59",
  });
  periodYears.push({
    year: year,
    value: PERIODS.P11.value,
    isSelected: false,
    isDisabled: false,
    startDate: year + "-11-01T00:00",
    endDate: year + "-11-30T23:59:59",
  });
  periodYears.push({
    year: year,
    value: PERIODS.P12.value,
    isSelected: false,
    isDisabled: false,
    startDate: year + "-12-01T00:00",
    endDate: year + "-12-31T23:59:59",
  });
}

function initPeriodYears(minYear, maxYear, allPeriods) {
  let periodYears = [];
  let defaultPeriodYears = [];

  for (let i = minYear; i <= maxYear; i++) {
    getDefaultPeriodYears(defaultPeriodYears, i);
    let periods = allPeriods
      ? Array.from(new Set(allPeriods.filter((f) => Number(f.year) === i)))
      : [];
    if (periods.length === 0) {
      periodYears = copyObjectValues(defaultPeriodYears);
    } else {
      periods
        .sort((a, b) => (a.value > b.value ? 1 : -1))
        .forEach((period) =>
          periodYears.push({
            year: Number(period.year),
            value: "P" + period.value.split("P")[1],
            isSelected: false,
            isDisabled: false,
            startDate: period.start_date,
            endDate: period.end_date,
          })
        );
      if (periods.length < 12) {
        // if we do not have all periods of the year -> add missing default periods
        periods = periods.map((p) => p.value);
        defaultPeriodYears
          .filter((f) => f.year === i)
          .forEach((period) => {
            if (!periods.includes(period.year + period.value)) {
              periodYears.push(period);
            }
          });
      }
    }
  }

  return periodYears;
}

function getDateFromPeriod(date, periods) {
  let startDate = new Date(date);
  let customStartDate = checkPeriodAvailability(startDate, periods);
  if (!periods || (periods && !periods.find((f) => f.value === customStartDate))) {
    return startDate;
  }

  return typeof customStartDate !== "string" ? customStartDate : periods.find((f) => f.value === customStartDate);
}

function checkPeriodAvailability(period, builtPeriods) {
  let availablePeriod = getPeriodFromDate(period);
  let stagingReport = false; // this.props.state.stagingReport;
  // if (stagingReport && (stagingReport === STAGING_SECTIONS.PROFIT_STACK_MAPPING_NEW || stagingReport === ALL_WIDGETS.PS_MAPPING_MENU)) {
  //     for (let i = 0; i < 20; i++) {
  //         if (this.isPeriodActive(availablePeriod)) {
  //             availablePeriod = period;
  //             break;
  //         } else {
  //             let time = period.setMonth(period.getMonth() - 1);
  //             availablePeriod = getPeriodFromDate(new Date(time));
  //         }
  //     }
  // } else {
  for (let i = 0; i < 20; i++) {
    if (isPeriodBuilt(availablePeriod, builtPeriods)) {
      availablePeriod = period;
      break;
    } else {
      let time = period.setMonth(period.getMonth() - 1);
      availablePeriod = getPeriodFromDate(new Date(time));
    }
  }
  // }
  return availablePeriod;
}

/**
 * This function checks whether the date is a period that is built/generated in the current scenario.
 * If the period is not available, we return th last built/generated period.
 * @param {Date} date The date to be checked
 * @param {JSONArray} builtPeriods the periods of the selected scenario
 * @param {Boolean} checkForSegmentation It's usually true when we are in Landscape/Erosion
 * @returns JSONObject that contains the startDate, endDate and period of the date.
 */ 
function checkBuiltPeriodAvailability(date, builtPeriods, checkForSegmentation = false) {
  let availablePeriod = getPeriodFromDate(date);
  let defaultObj = {startDate: date, endDate: date, period: availablePeriod};

  if(checkForSegmentation) {
    // We check on periods that have generated=true and the previous 11 periods of the first generated period
    // if the selected period is inclued in these periods array then it is available so we return it
    let rolling = 12;
    let generatedPeriods = builtPeriods?.filter(f => f.generated);
    if(!generatedPeriods?.length) { return defaultObj; }

    let prevPeriods = getPreviousBuiltPeriods(builtPeriods, generatedPeriods[generatedPeriods.length - 1]).slice(0, rolling - 1);
    if(!isPeriodSegmentGenerated(availablePeriod, rolling, builtPeriods) && !prevPeriods?.map(m => m.label).includes(availablePeriod)) {
      return { startDate: generatedPeriods[0].start_date, endDate: generatedPeriods[0].end_date, period: generatedPeriods[0].value };
    }
  } else {
    let actualBuiltPeriods = builtPeriods?.filter(f => f['is_built']);
    if(!actualBuiltPeriods?.length) { return defaultObj; }

    if(!isPeriodBuilt(availablePeriod, builtPeriods)) {
      return { startDate: actualBuiltPeriods[0].start_date, endDate: actualBuiltPeriods[0].end_date, period: actualBuiltPeriods[0].value };
    }
  }

  return defaultObj;
}

/**
     * gets the valid period from tcustmDate
     * @returns 
     */
function getValidPeriods(periodsStatusState, clientPeriodsState, customStartDate, customEndDate){
  let startDate = customStartDate || periodsStatusState?.customStartDate;
  let endDate = customEndDate || periodsStatusState?.customEndDate;

 // checks if the customDate has a built period or not 
 if(startDate && periodsStatusState?.builtPeriods) {
      startDate = getDateFromPeriod(startDate, periodsStatusState.builtPeriods);
      endDate = getDateFromPeriod(endDate, periodsStatusState.builtPeriods);
      startDate = startDate.start_date ? new Date(startDate.start_date) : startDate;
      endDate = endDate.end_date ? new Date(endDate.end_date): endDate;
      let isStartPeriodAvailable = isPeriodBuilt(getPeriodFromDate(startDate), periodsStatusState.builtPeriods);
      let isEndPeriodAvailable = isPeriodBuilt(getPeriodFromDate(endDate), periodsStatusState.builtPeriods);

      // if the period is available, no need to put it in a state
      if(!isStartPeriodAvailable && !isEndPeriodAvailable) {
          startDate = checkBuiltPeriodAvailability(startDate, periodsStatusState?.builtPeriods).period;
          endDate = checkBuiltPeriodAvailability(endDate, periodsStatusState?.builtPeriods).period;
          startDate = typeof startDate !== 'string' ? startDate : clientPeriodsState.periods.find(f => f.label === startDate) ? new Date(clientPeriodsState.periods.find(f => f.label === startDate).start_date) : periodsStatusState.customStartDate; // if startDate is a period -> find its start_date
          endDate = typeof endDate !== 'string' ? endDate : clientPeriodsState.periods.find(f => f.label === endDate) ? new Date(clientPeriodsState.periods.find(f => f.label === endDate).end_date) : periodsStatusState.customEndDate;
      }
  }
      
  return {startDate: startDate, endDate: endDate}
}

function isPeriodActive(periodDate, periodsArg) {
  if(typeof periodDate === "string" && periodsArg) {
      let periods = periodsArg.map(p => p.value);
      return periods.includes(periodDate);
  }
  
  for(let opt in periodsArg) {
      let startD = new Date(periodsArg[opt][DATASET.START_DATE]);
      let endD = new Date(periodsArg[opt][DATASET.END_DATE]);

      if(isNaN(startD.getTime()) || isNaN(endD.getTime())) {
          continue;
      }

      //nullify the time to only compare the dates
      periodDate.setHours(0);
      periodDate.setMinutes(0);
      periodDate.setSeconds(0);
      periodDate.setMilliseconds(0);
      
      startD.setHours(0);
      startD.setMinutes(0);
      startD.setSeconds(0);
      startD.setMilliseconds(0);
      
      endD.setHours(0);
      endD.setMinutes(0);
      endD.setSeconds(0);
      endD.setMilliseconds(0);

      if(periodDate >= startD && periodDate <= endD) {
          return true;
      }
  }
  return false;
}


function validatePeriodRange(startPeriod, endPeriod, comparisonValue, builtPeriods) {
  let previousPeriods = {}
  if(comparisonValue === header_comparison_values.YOY) {
      previousPeriods = getPreviousYearPeriods(startPeriod, endPeriod);
      return isPeriodBuilt(previousPeriods.start, builtPeriods) && isPeriodBuilt(previousPeriods.end, builtPeriods);
  } else if(comparisonValue === header_comparison_values.PREV_AD) {
      previousPeriods = getPreviousAdjacentPeriods(startPeriod, endPeriod);
      return isPeriodBuilt(previousPeriods.start, builtPeriods) && isPeriodBuilt(previousPeriods.end, builtPeriods);
  }
  return true;
}

function disablePeriods(periods) {
    periods.map((period) => (period.isDisabled = !period.is_built));
    return periods;
}

 /**
   * This function checks if the selected range should still be selected or not.
   * If the selected range is not valid, we select the last valid(built/generated) period.
   * @param {*} checkForSegmented if true, we check for segmented periods else we check for built periods.
   * @returns new state with correct start and end date.
   */
 const checkIfSelectedPeriodsAreValid = (periodsState, checkForSegmented) => {
  let tempState = periodsState;
  let nextCustomStartDateCookie = cookie.load("nextCustomStartDate");
  let nextCustomEndDateCookie = cookie.load("nextCustomEndDate");

  if (nextCustomStartDateCookie && nextCustomEndDateCookie) {
    tempState.customStartDate = new Date(nextCustomStartDateCookie.replaceAll('"', ""));
    tempState.customEndDate = new Date(nextCustomEndDateCookie.replaceAll('"', ""));
  }

  let startDate = checkBuiltPeriodAvailability(new Date(tempState.customStartDate), tempState.builtPeriods, checkForSegmented).startDate;
  let endDate = checkBuiltPeriodAvailability(new Date(tempState.customEndDate), tempState.builtPeriods, checkForSegmented).endDate;

  if(new Date(endDate) < new Date(startDate)) {
    endDate = startDate;
  }

  tempState.customStartDate = new Date(startDate);
  tempState.customEndDate = new Date(endDate);
  tempState.nextCustomStartDate = tempState.customStartDate;
  tempState.nextCustomEndDate = tempState.customEndDate;
  
  return tempState;
}


export {
  getDefaultPeriodYears,
  getFirstBuiltPeriod,
  initPeriodYears,
  isPeriodBuilt,
  isPeriodTileDisabled,
  periodTileContent,
  getDateFromPeriod,
  enablePreviousPeriods,
  checkPeriodAvailability,
  getValidPeriods,
  isPeriodActive,
  isPeriodSegmentGenerated,
  validatePeriodRange,
  getLastBuiltPeriod,
  disablePeriods,
  checkIfAtLeastOnePeriodIsGenerated,
  checkBuiltPeriodAvailability,
  checkIfSelectedPeriodsAreValid
};
