import React, {forwardRef} from "react";
import {ReactComponent as Arrow} from "../../styles/images/icons/small-thick-arrow.svg";

const SuggestionsList = ({filteredSuggestions,setShowSuggestions, maxVisibleSuggestions,highlightMatch,setSeeMore, seeMore, setInputValue}, ref)=>{
    return (
        <ul className="suggestions-list" ref={ref}>
            <div className="suggestions_rows_container">
                {filteredSuggestions.slice(0, maxVisibleSuggestions).map((suggestion, index) => (
                    <li
                        key={index}
                        onMouseDown={() => { // Use onMouseDown to prevent blur when selecting a suggestion
                            setInputValue(suggestion);
                            setShowSuggestions(false);
                        }}
                    >
                        {highlightMatch(suggestion)}
                    </li>
                ))}
            </div>
            {filteredSuggestions.length > 5 && (
                <div className="see_more_arrow_container"
                     onMouseDown={(e) => e.preventDefault()} // Prevent blur
                     onClick={() => setSeeMore(!seeMore)}>
                    <div
                        className="see_more_less_suggestions"
                    >
                        {seeMore ? "See less" : "See more"}
                    </div>
                    <div className={"see_more_arrow" + (seeMore ? "_open" : "")}>
                        <Arrow/>
                    </div>
                </div>
            )}
        </ul>
    );
}

export default forwardRef(SuggestionsList)