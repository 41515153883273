import React, {Suspense, useEffect, useRef, useState} from 'react';
import ConfigureGlAccountRanges from "../../components/configureTieOut/ConfigureGlAccountRanges";
import {useAuthenticated} from "../CustomHooks";
import {ReportHeader} from "../ReportHeader";
import {Loader} from "../../form/elements";
import {ALL_WIDGETS, BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, PERIOD_ONWARD, SIZES} from "../../class/constants";
import {renderBreadcrumbsAndDescription, renderTitle} from "../functions/componentFunctions";
import {logout} from "../../class/common";
import {getAllFileTypeColumns} from "../api/apiDataModeling";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from 'posthog-js/react';

/**
 * @author Ralph Bejjani
 * @description This component holds the  header of the configure tie out and the configure tie out component named ConfigureGlAccountRanges where the header contains title
 * @param {*} props
 * @returns Report header and configure tie out
 */
const ConfigureGlAccountRangesWrapper = (props) => {
    const userAllowedMenuLinks = props.userAllowedMenuLinks;
    const userSettings = props.userSettings;
    const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
    const [headerElements, setHeaderElements] = useState([]);
    const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.CONFIGURE_GL_ACCOUNT_RANGES);
    const [allFileTypeColumns, setAllFileTypeColumns] = useState();
    const useNewComponents = useFeatureFlagEnabled('use_new_components');

    /**
     * @function useEffect()
     * on screen initialization if isAuthenticated is false user gets logged out
     * isAuthenticated is a boolean read from a custom hook named useAuthenticated
     **/
    useEffect(() => {
        if (!isAuthenticated) {
            logout();
        }
    }, [isAuthenticated]);

    /**
     * @function useEffect()
     * On mount set header elements which is the title only in our case
     */
    useEffect(() => {
        let headerElements = useNewComponents ? getNewHeaderElements() : getHeaderElements();
        setHeaderElements(headerElements);
    }, [useNewComponents]);

    /**
     * @function useEffect()
     * On mount call this function and send setAllFileTypeColumns as param to use allActualGLColumns and glSubtypes and send to component
     */
    useEffect(() => {
        getAllFileTypeColumns(undefined, undefined, setAllFileTypeColumns)
    }, []);


    const getHeaderElements = () => {
        let headerElements = [];
        headerElements.push(
            <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="first_header_row gap_between_buttons">
                {/*{renderTitle(reportTitle)}*/}
            </div>
        );

        return headerElements;
    };

    const renderHeaderFirstRow = () => {
        return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription)
    }

    const getNewHeaderElements = () => {
        let headerElements = [];
        headerElements.push(
            <div style={{display: "flex", width: "100%", alignItems: "center"}}
                 className='new_header_menu first_header_row gap_between_buttons'>
                {renderHeaderFirstRow()}
            </div>
        );

        return headerElements;
    };


    return (
        <>
            <Suspense fallback={<p>Loading...</p>}>
                <div
                    id={"main-component-container"}
                    className={"main-component-container"}
                    style={{ "--banner-height": 0 }}
                >
                    <div className={"header-banner-div-hidden"} />
                    <div className="main-report-header">
                        <ReportHeader headerElements={headerElements} />
                    </div>
                    <div className="main_report">
                        <div id="main_report_container" className="main_report_container" style={{border: "none", height: "100%", width: "100%", justifyContent: "center", display: "flex", alignItems:"center"}}>
                            <ConfigureGlAccountRanges machineName={userSettings.machineName} user={userSettings.user}
                                                      glSubtypes={allFileTypeColumns?.glSubtypes}
                                                      allActualGLColumns={allFileTypeColumns?.allActualGLColumns}/>
                        </div>
                    </div>
                </div>
            </Suspense>
        </>
    );
};

export {ConfigureGlAccountRangesWrapper};
