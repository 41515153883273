// CustomChatBot.js
import { sessionState, useChatInteract, useChatMessages, useChatSession } from '@chainlit/react-client';
import { ClickAwayListener } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { PROFIT_ASSIST } from '../../class/constants';
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from "../../class/networkUtils";
import { getSectionExists } from "../../class/utils";
import { ReactComponent as Microphone } from '../../styles/images/chatbot/microphone.svg';
import { ReactComponent as NewChatImage } from "../../styles/images/chatbot/new_chat.svg";
import { ReactComponent as SendBtn } from '../../styles/images/chatbot/send.svg';
import { Span } from "../../Tracing/Span";
import ChatBubble from "./ChatBubble";
import SoundFlicker from './SoundFlicker';
import SuggestionsList from "./SuggestionsList";
import { VectorSuggestions } from './VectorSuggestions';

const  recent_chats = [{title:"show my top 5 Customers for 2034Q1", history:{}},
    {title:"show my top 5 Products for 2034Q1", history:{}},
    {title:"show my top 5 Orders for 2034Q1", history:{}},
    {title:"See more ...", history:{}},

];

const inputSuggestions = [
    "Who are the least profitable customers?",
    "Who are the bottom 5 customers?",
    "Which customers declined the most period over period, and why?",
    "Which customers grew the most period over period, and why?",
    "What are the bottom 5 least profitable SKUs/Products, and what are their associated costs?",
    "Which are the bottom 5 products?",
    "Which are the most profitable products?",
    "Which products declined the most period over period, and why?",
]

const response_messages = ["Just a moment",
    "Thinking, just a moment...",
    "Analysing, one moment please...",
    "Gathering data, this won’t take long...",
    "Calculating, this won’t take long..."]

function CustomProfitAssist(props) {
    const userEnv = {};
    const [inputValue, setInputValue] = useState("");
    const { sendMessage, clear } = useChatInteract();
    const {messages} = useChatMessages(); // Use Recoil for global chat state
    const [isListening, setIsListening] = useState(false);
    const recognitionRef = useRef(null);
    const { connect } = useChatSession();
    const session = useRecoilState(sessionState);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const chainlitUrl = process.env.REACT_APP_CHATBOT_APP_BASE_URL;
    const textAreaRef = useRef(null);
    const suggestionsListRef = useRef(null);
    const suggestionsCategoriesRef = useRef(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [category, setCategory] = useState(false);
    const [showCategoriesSuggestions, setShowCategoriesSuggestions] = useState(false);
    const [seeMore, setSeeMore] = useState(false);
    const [suggestionsList, setSuggestionsList] = useState(inputSuggestions)
    const [categoriesSuggestionsList, setCategoriesSuggestionsList] = useState([])
    const flatMessages = useMemo(() => {
        return flattenMessages(messages, (m) => m.type.includes("message"))
    }, [messages])
    let isProcessing = flatMessages && flatMessages[flatMessages?.length - 1]?.output?.replace(/ /g, '') === "";
    const randomIndex = useMemo(() => Math.floor(Math.random() * response_messages.length), [isProcessing]);
    const filteredSuggestions = suggestionsList.filter((suggestion) =>
        suggestion.toLowerCase().startsWith(inputValue.toLowerCase())
    );

    const filteredCategoriesSuggestions = categoriesSuggestionsList.filter((suggestion) =>
        suggestion.toLowerCase().startsWith(inputValue.toLowerCase())
    );
    const maxVisibleSuggestions = seeMore ? (!category ? filteredSuggestions.length : filteredCategoriesSuggestions.length) : 5;

    function flattenMessages(messages, condition) {
        return messages.reduce((acc, node) => {
            if (condition(node)) {
                acc.push(node);
            }

            if (node.steps && node.steps.length) {
                acc.push(...flattenMessages(node.steps, condition));
            }

            return acc;
        }, []);
    }



    useEffect(() => {
      if (session[0]?.socket?.connected) {
        return;
      }
       if (Object.keys(props.userSettings).length > 0) {
        getAccessToken(baseUrl, chainlitUrl)
      }
    }, [connect, props.userSettings, session?.socket?.connected]);


    useEffect(() => {
        const SpeechRecognition =
            window.SpeechRecognition || window.webkitSpeechRecognition;

        if (!SpeechRecognition) {
            console.error("Speech recognition is not supported in your browser.");
            return;
        }

        const recognition = new SpeechRecognition();
        recognition.continuous = false;
        recognition.interimResults = false;
        recognition.lang = "en-US";

        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            setInputValue(transcript); // Update input with the recognized text
        };

        recognition.onend = () => {
            console.log("Speech recognition ended.");
            recognitionRef.current.stop();
            recognitionRef.current.abort();
            setIsListening(false);
        };

        recognition.onspeechend = () => {
            console.log("Speech recognition ended.");
            recognitionRef.current.stop();
            recognitionRef.current.abort();
            setIsListening(false);
        };

        recognition.onerror = (event) => {
            console.error("Speech recognition error:", event.error);
            setIsListening(false);
            alert("Could not process speech. Please try again.");
        };

        recognitionRef.current = recognition;

        return () => {
            if (recognitionRef.current) {
                recognitionRef.current.abort();
                recognitionRef.current = null;
            }
        };
    }, []);


    // Function to get chatbotVectorsInfo
    const getCloudRunToken = () => {
        return new Promise((resolve, reject) => {
            let query = { action: "getChatbotAccessToken" };
            let onThenCallback = (data) => {
                if (data?.accessToken) {
                    resolve(data.accessToken); // Resolve the promise
                } else {
                    reject(new Error('Failed to get chatbotAccessToken'));
                }
            };
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "getChatbotAccessToken",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: false,
                [FETCHAPI_PARAMS.path]: "/get-chat-bot-access-token",
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            };
            fetchAPI(fetchOptions);
        });
    };

    // Function to get access token from the /custom-auth endpoint
    const getAccessToken = async (baseUrl, chainlitUrl) => {
        try {
            // Fetch the access token from the new endpoint
            const cloudRunToken = await getCloudRunToken();
            // Now use the access token to authenticate the existing API call
            const requestBody = {
                userId: props.userSettings.user.userId,
                clientId: props.userSettings.clientId,
            };
            let span = new Span( "POST"+ "/custom-auth");
            // Make the POST request to your custom-auth endpoint
            const authResponse = await fetch(`${chainlitUrl}/custom-auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${cloudRunToken}`, // Use the access token here
                    'X-Trace-Id': span.getTraceId(),
                    'X-Span-Id': span.getSpanId(),
                },
                credentials: 'include',
                body: JSON.stringify(requestBody),
            });

            const data = await authResponse.json();
            if (authResponse.ok) {
                connect({
                    userEnv,
                    accessToken: `Bearer: ${data.token}`,
                  });
                }
        } catch (error) {
            return null;
        }
    };

    const handleSendMessage = async() => {
        if (!isProcessing) {
            const content = inputValue.trim();
            if (content) {
                const message = {
                    name: props.userSettings.user.first_name + " " + props.userSettings.user.last_name,
                    type: PROFIT_ASSIST.USER_MESSAGE,
                    output: content,
                };
                setInputValue("");
                sendMessage(message, []);
            }
        }
    };


    const renderMessage = (message) => {
        const dateOptionsDate = {
            year: "numeric",
            month: "long",
            day: "numeric"
        };
        const dateOptionsTime = {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
        };
        const datePart = new Date(message.createdAt).toLocaleDateString(undefined, dateOptionsDate);
        const timePart = new Date(message.createdAt).toLocaleTimeString(undefined, dateOptionsTime);

        return (
            (message.output.replace(/ /g, '') !== "" &&
                <ChatBubble id={message.id} name={message.name} content={message.output} datePart={datePart}
                            timePart={timePart} messageType={message.type} launchToast={launchToast}/>)
        );
    };


    const handleMicClick = () => {
        if (!recognitionRef.current) return;
        recognitionRef?.current?.start();
        setIsListening(true);
    };

    const saveChatandExit = () => {
        // setSessions(...sessions,...[session[0].socket.id]);
        // setRecentChats(recentChats.push({title:"Latest Chat", messages:messages}));
        clear();
        session[0].socket.disconnect();
        getAccessToken(baseUrl, chainlitUrl)
    }

    const addCategoriesSuggestions = (suggestions) => {
        let suggestionsArray = [];
        for (const suggestion of suggestions) {
            suggestionsArray.push(suggestion.text);
        }
        setCategoriesSuggestionsList(suggestionsArray);
        setShowSuggestions(false);
        setShowCategoriesSuggestions(true);
    }

    const handleClickAway = (e)=>{
        if (e?.target?.id === "message-input") {
            // setSuggestionsList([]);
            // setCategoriesSuggestionsList([])
            return;
        }
        setCategory();
        setSuggestionsList([]);
        setCategoriesSuggestionsList([])
        setShowCategoriesSuggestions(false);
    }

    const resumeChat = (index) => {
        // session[0].socket.disconnect();
        // connect({ idToResume: sessions[index] });
        // setMessages(recentChats[index].messages);
    }

    const handleTextArea = (e) => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
            textAreaRef.current.style.height = `${e.target.scrollHeight}px`;
            textAreaRef.current.style.maxHeight = `5vw`;
        }
    };

    const highlightMatch = (suggestion) => {
        const match = suggestion.substring(0, inputValue.length);
        const rest = suggestion.substring(inputValue.length);
        return (
            <>
                <span className="profit_assist_suggestion_match">{match}</span>
                {rest}
            </>
        );
    };

    const launchToast = (success, message) => {
        if (success === true) {
            $("#profit_assist_toast #message").text(message);
        } else {
            $("#profit_assist_toast #message").text(message);
        }
        $("#profit_assist_toast").addClass("show");
        setTimeout(function () {
            $("#profit_assist_toast").removeClass("show");
        }, 4000);
    }

    const showSuggestionsOnFocus = () => {
        if (category === "Customer" || category === "Product" || category === "Operations") {
            setCategoriesSuggestionsList(categoriesSuggestionsList)
            setShowCategoriesSuggestions(true);
            setShowSuggestions(false);
        } else {
            setShowSuggestions(true);
            setSuggestionsList(inputSuggestions);
        }
    }

    const showSuggestionsOnBlur = () =>{
        setShowSuggestions(false)
        setSuggestionsList(inputSuggestions)
    }

    return (
        <div className={"main-container-chatbot" + (flatMessages.length !== 0 ? " conversation" : "")}>
            <div id="profit_assist_toast" className="toast toast-success">
                <div id="desc"><span id="message"/></div>
            </div>
            <div className="new_chat_icon" onClick={() => saveChatandExit()}>
                <NewChatImage/>
            </div>
            {isListening && <SoundFlicker/>}
            <div className="chatbot_input_container">
                <div className={flatMessages?.length !== 0 ? 'scrollable-chat' : ''}>
                    <div className="chat-container">
                        {flatMessages?.map((message) => renderMessage(message))}
                        {isProcessing && (
                            <div className="typing-indicator-container">
                                <div className="typing_image">
                                    <img src='/images/3dots.gif' className=""/>
                                </div>
                                <p className="typing_text">{response_messages[randomIndex]}</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="input-container" style={{width: '100%'}}>
                    <div className="input" style={{width: '100%'}}>
                        <textarea
                            id="message-input"
                            ref={textAreaRef}
                            placeholder="Ask me (almost) anything..."
                            value={inputValue}
                            onInput={handleTextArea}
                            onFocus={() => showSuggestionsOnFocus()}
                            onBlur={() => showSuggestionsOnBlur()}
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyUp={(e) => {
                                if (e.key === "Enter") {
                                    handleSendMessage();
                                    setShowSuggestions(false);
                                }
                            }}
                        />
                        {showSuggestions && getSectionExists(props.allowedMenuLinks, PROFIT_ASSIST.SUGGESTIONS_ACCESS) && flatMessages.length === 0 && filteredSuggestions.length > 0 && (
                            <SuggestionsList ref={suggestionsListRef.current} filteredSuggestions={filteredSuggestions}
                                             setShowSuggestions={setShowSuggestions}
                                             maxVisibleSuggestions={maxVisibleSuggestions}
                                             highlightMatch={highlightMatch}
                                             setSeeMore={setSeeMore} seeMore={seeMore} setInputValue={setInputValue}/>
                        )}

                        {showCategoriesSuggestions && getSectionExists(props.allowedMenuLinks, PROFIT_ASSIST.SUGGESTIONS_CATEGORIES_ACCESS) && flatMessages.length === 0 && filteredCategoriesSuggestions.length > 0 && (
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <SuggestionsList ref={suggestionsCategoriesRef.current}
                                                 filteredSuggestions={filteredCategoriesSuggestions}
                                                 setShowSuggestions={setShowCategoriesSuggestions}
                                                 maxVisibleSuggestions={maxVisibleSuggestions}
                                                 highlightMatch={highlightMatch}
                                                 setSeeMore={setSeeMore} seeMore={seeMore}
                                                 setInputValue={setInputValue}/>
                            </ClickAwayListener>
                        )}
                    </div>
                    <div className="micro-send-container">
                        {showSuggestions && getSectionExists(props.allowedMenuLinks, PROFIT_ASSIST.SUGGESTIONS_ACCESS)  && flatMessages.length === 0 && <div className="suggestions_typing_text">{PROFIT_ASSIST.SUGGESTIONS_TYPING}</div>}
                        <div className="micro-container">
                            {inputValue === "" && !isProcessing && <Microphone onClick={handleMicClick}/>}
                        </div>
                        <div className={"send_button" + (isProcessing ? "_disabled" : "")}>
                            <SendBtn onClick={handleSendMessage}/>
                        </div>
                    </div>
                </div>
            </div>
            {getSectionExists(props.allowedMenuLinks, PROFIT_ASSIST.SUGGESTIONS_CATEGORIES_ACCESS) &&(
                <>
                    <VectorSuggestions addSuggestions={addCategoriesSuggestions} setSuggestionsList={setSuggestionsList} setCategory={setCategory}/>
                    {/*<RecentChats recentChats={recentChats} resumeChat={resumeChat}/>*/}
                </>
            )}
            <div className="profit_assist_footer_text" style={{marginTop: "auto", alignSelf: "center"}}>Profit Assist
                adjusts responses based on your access level, ensuring restricted data remains secure.
            </div>
        </div>
    )
}

export default CustomProfitAssist;
