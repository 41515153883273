import { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ReportHeader } from "../ReportHeader";

import { lang } from "../../language/messages_en";

import { useRef } from "react";
import { logout, updateWindowLoading } from "../../class/common";
import { capitalizeFirstLetter } from "../../class/utils";
import CustomReport from "../../CustomReport";
import ErrorBoundary from "../../ErrorBoundary";
import { Loader } from "../../form/elements";
import { useAuthenticated } from "../CustomHooks";
import {renderBreadcrumbsAndDescription, renderTitle} from "../functions/componentFunctions";
import NewBanner from "../../components/NewBanner";
import Button from "../../newComponents/Button";
import { ReactComponent as RoundInfoIcon } from "../../styles/images/menu-svgs/info.svg";
import { BUTTON_TYPE, BUTTON_VARIANT, SCENARIO_STATUS, SIZES } from "../../class/constants";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from "posthog-js/react";
/**
 * @author Ralph Bejjani
 * @description component renders  Custom Report  Title with  {@link CustomReport} component , calls isAuthenticated hook to check if user has authentication view the screen
 * @param {*} props 
 * @returns {@link ReportHeader}, {@link CustomReport}
 */
const ManageCustomReportsWrapper = (props) => {
  const userAllowedMenuLinks = props.userAllowedMenuLinks;
  const userSettings = props.userSettings;
  const propsScenarioState = props.scenarioState;

  /** References & react utility */
  const dispatch = useDispatch();
  const reportRef = useRef();

  /**Custom Hooks */
  const isAuthenticated = useAuthenticated(userAllowedMenuLinks);

  /** State */

  /** Header Related States */
  const [headerElements, setHeaderElements] = useState([]);
  const [profitFormat, setProfitFormat] = useState(lang.profit_stack_line_definitions.profit_stack_line_definitions_title);
  const [reportTitle, setReportTitle] = useState(capitalizeFirstLetter(lang.manage_reports.manage_reports_title));

  const [isScenarioBannerVisible, setScenarioBannerVisible] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const useNewComponents = useFeatureFlagEnabled('use_new_components');

  /**on screen initialization if user entered url with no access to screen he gets logged out */
  useEffect(() => {
    if (!isAuthenticated) {
      logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (Object.keys(propsScenarioState).length > 0) {
      let headerElements = useNewComponents ? getNewHeaderElements() : getHeaderElements();
      setHeaderElements(headerElements);
    }
  }, [propsScenarioState, isExpanded, useNewComponents]);

  useEffect(() => {
    if (propsScenarioState) {
      //when changing scenario, clear all states so that it won't run useEffect when new states are still not updated
      setScenarioBannerVisible(propsScenarioState?.scenarioStatus === SCENARIO_STATUS.REVIEW);
    }
  }, [propsScenarioState]);

  /** Header elements */
  const getHeaderElements = () => {
    let headerElements = [];
    headerElements.push(
      <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="first_header_row gap_between_buttons">
        {renderTitle(reportTitle)}
      </div>
    );

    return headerElements;
  };

  const renderHeaderFirstRow = () => {
    return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription)
  }

  const getNewHeaderElements = () => {
    let headerElements = [];
    headerElements.push(
        <div style={{display: "flex", width: "100%", alignItems: "center"}}
             className='new_header_menu first_header_row gap_between_buttons'>
          {renderHeaderFirstRow()}
        </div>
    );

    return headerElements;
  };


  let trackingData = window._pi_getTrackingDataObj();
  trackingData = trackingData === null ? "" : JSON.stringify(trackingData);
  let scenarioLabel = propsScenarioState?.scenarioObjects?.length > 0 ? propsScenarioState?.scenarioObjects[0].label : "";

  return (
    <>
        <Suspense fallback={<p>Loading...</p>}>
          <div
            id={"main-component-container"}
            className={"main-component-container"}
            style={{ "--banner-height": isScenarioBannerVisible ? "6%" : 0 }}
          >
            <div className={isScenarioBannerVisible ? "header-banner-div" : "header-banner-div-hidden"}>
              <Loader newLoader />

             {isScenarioBannerVisible &&  <NewBanner
                bannerClassName={"header-banner scenario-banner"}
                labelClassName={"header-banner-label scenario-banner-label"}
                icon={<RoundInfoIcon className="info-banner-icon" />}
                label={lang.under_review_scenario_banner + " (" + scenarioLabel + ")"}
                body={
                  <Button
                    id={"banner-dismiss-btn"}
                    label={lang.modal.buttons.dismiss}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={SIZES.DEFAULT}
                    type={BUTTON_TYPE.DEFAULT}
                    onBtnClick={() => setScenarioBannerVisible(false)}
                  />
                }
              />}
            </div>
            <div className="main-report-header">
              <ReportHeader headerElements={headerElements} />
            </div>
            <div className="main_report">
              <div id="main_report_container" className="main_report_container">
                <CustomReport
                  ref={reportRef}
                  userSettingsState={userSettings}
                  userAllowedMenuLinks={userAllowedMenuLinks}
                  profitFormat={profitFormat}
                  reportTitle={reportTitle}
                  history={props.history}
                  callGetUserSection={props.callGetUserSection}
                  goToReport={props.goToReport}
                />
              </div>
            </div>
          </div>
        </Suspense>
    </>
  );
};

export { ManageCustomReportsWrapper };
