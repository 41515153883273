import React, { Component } from "react";
import { DASHBOARDS, ALL_REPORTS, COLUMN_PROFILE, IDENTIFIERS, PROFILE_COLUMN, ALL_WIDGETS, MANAGE_ACCESS, INVOICE_LINE, VECTOR_STAGING_ATTRIBUTES, MANAGE_COLUMNS, YEAR_OVER_YEAR,
         TOP_QUADRANT, TOP_QUADRANT_TIER, BP_QUADRANT_TIER, FY_VALUES, PREVIOUS_ADJACENT, TOTAL_COMPANY, VECTOR_ANALYSIS, FormatTypes, VECTOR_GROUPS, time_range_values, FILTER, BUTTON_VARIANT, SIZES, BUTTON_TYPE, DIALOG_SIZE, MENU_ITEM } from "../../class/constants";
import { tryParse, getSectionExists, getTranslationFile, parseBoolean, capitaliseFirstLetterAfterChar, copyObjectValues, findOptionByKey} from "../../class/utils";
import { SkeletonLoader } from "../../form/elements";
import { getVectorAllowedAttributes } from "../manageAccess/ManageAccessUtils";
import  ProfitMapList  from "../ProfitMapList";
import MetricWidget from "./MetricWidget";
import ProfitStacks  from '../profitStacks/ProfitStacks';
import { extractFromFullQuarter, generatePeriods, generateQuarter, getFullQuarterFromStartEndQuarters, getGeneratedQuarterRange, getPeriodQuarter, getQuarterFromDate, isValidDate, monthDiff } from "../../class/date"
import ErosionAndGrowth from "../../ErosionAndGrowth";
import BridgeReport from "../vectorAnalysis/BridgeReport";
import { formatValHTML } from "../../class/format";
import Button from "../../newComponents/Button";
import { replaceSpecialCharacters } from "../../class/jqueries";
import Modal from "../../newComponents/Modal";

const Inflector = require('inflected');
const lang = getTranslationFile();

const _error = "ERROR";
const _widgetId = DASHBOARDS.WIDGET.ID;
const _visibilityOptions = COLUMN_PROFILE.VISIBILITY_OPTIONS;
const _visibility = DASHBOARDS.VISIBILITY;
const _isCompanyDefault = DASHBOARDS.IS_COMPANY_DEFAULT;
const _isSystem = MANAGE_ACCESS.FIELDS.IS_SYSTEM;

/**
 * Dashboards widgets
 * @author [Sarah Farjallah]
 */
class Widget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,//this.props.widget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC ? this.props.fetching : true,  //true by default until the widget's data is fetched
            widgetSectionAccess: true
        }

        this.setIsLoading = this.setIsLoading.bind(this);
        this.handleAPIError = this.handleAPIError.bind(this);
        this.hasMOMAccess = React.createRef(true);
    }

    getLimit(){
        return 10;
    }

    checkVectorAccess(config) {
        let vectors = [];
        let widget = this.props.widget;
        if(widget[DASHBOARDS.WIDGET.WIDGET_TYPE] === DASHBOARDS.WIDGET_TYPES.LIST){
            vectors = this.props.vectorOptions;
        } else {
            vectors = this.addVector(copyObjectValues(this.props.vectorOptions));
        }
        let groupVect = vectors.filter(e=>e.value === config[DASHBOARDS.VECTOR] && !e.isDisabled).length > 0 ? vectors.filter(e=>e.value === config[DASHBOARDS.VECTOR] && !e.isDisabled)[0].vector_group : "";
        let allowed = getVectorAllowedAttributes(config[DASHBOARDS.VECTOR], groupVect, this.props.user);
        if (!allowed.includes(IDENTIFIERS.NAME) && !allowed.includes(IDENTIFIERS.NUMBER)) {
            return false;
        }
        return true;
    }

    addVector = (vectors)=>{
        if (vectors ){
           if(vectors.filter(e=>e.value === INVOICE_LINE.value).length === 0) {
                vectors.push({
                    label:INVOICE_LINE.label,
                    value:INVOICE_LINE.value,
                    [VECTOR_STAGING_ATTRIBUTES.GROUP]:INVOICE_LINE.vector_group,
                    [VECTOR_STAGING_ATTRIBUTES.DISPLAY_ORDER]:vectors.length
                });
                return vectors;
            }
        }
      return vectors;
    }

    setIsLoading(isLoading, tempState={}) {
        let _this = this;
        if(isLoading !== this.state.isLoading) {
            tempState.isLoading = isLoading;
        }
        if(Object.keys(tempState).length) {
            _this.setState(tempState,function(){
                _this.props.checkLoadingWidgets();
            });
        }
    }

    handleAPIError(errorMessage) {
        this.setIsLoading(false, {
            errorMessage: errorMessage
        });
    }


    componentDidUpdate(prevProps) {
        if(!this.state.widgetSectionAccess) {
            this.setIsLoading(false);       //user has no access
            return;
        }
        let tempState={};
        let isMetricWidget = [DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC,  DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC,  DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC,  DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE].includes(this.props.widget[DASHBOARDS.WIDGET.WIDGET_TYPE]);
        let isLoading = this.state.isLoading;
        if (prevProps.scenario !== this.props.scenario) {
            tempState.errorMessage = undefined;
        }
        if((!!this.props.data && !prevProps.data) || (!!this.props.data && isMetricWidget) || !this.getWidgetAccess()) {
            isLoading = false;
        } else if(!this.props.data && !!prevProps.data) {
            isLoading = true;
        }
        this.setIsLoading(isLoading, tempState);
    }

    componentDidMount() {
        this.hasMOMAccess.current = getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_MOM);

        let widget = this.props.widget;
        let widgetType = widget[DASHBOARDS.WIDGET.WIDGET_TYPE];
        let config = tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]);
        let type = config.profit_stack_type
        if(type === time_range_values.periods){
            widget[DASHBOARDS.WIDGET.WIDGET_NAME] = this.hasMOMAccess.current ? ALL_WIDGETS.FIELDS.MOM_STACKS : ALL_WIDGETS.FIELDS.YTD_STACKS
        }else if(type === time_range_values.ytd||type === time_range_values.qtd){
            widget[DASHBOARDS.WIDGET.WIDGET_NAME] = ALL_WIDGETS.FIELDS.YOY_STACKS
        }
        if(widgetType === DASHBOARDS.WIDGET_TYPES.LIST) {
            this.setState({     //set sectionAccess only once on mount to avoid looping on userAllowedMenuLinks more than once
                widgetSectionAccess: getSectionExists(this.props.userAllowedMenuLinks, config[DASHBOARDS.PROFIT_FORMAT].replace(/ /g,'').toLowerCase()),
            })
        }
    }

    goToWidgetReport = () => {
        let _this = this;
        const {widget} = this.props;
        let url = widget[DASHBOARDS.WIDGET.SECTION_URL]
        let config = copyObjectValues(tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]));
        let concatenatedFilter = copyObjectValues(tryParse(widget[DASHBOARDS.FILTER]));//this.props.filterFinal?copyObjectValues(tryParse(this.props.filterFinal)):"";
        let dashboardFilterObj = "";
        let dashBoardFilter = tryParse(this.props.dashboardFilter);
        let isConcatenationOfFilters = false;
        if(this.props.dashboardFilter && dashBoardFilter.length>0){
            let widgetFilterLength = concatenatedFilter.filter.length;
            if(widgetFilterLength>0){
                isConcatenationOfFilters = true;
            }
            if(widgetFilterLength>1){
                concatenatedFilter.filter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
                concatenatedFilter.filter[widgetFilterLength -1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
            }
            if(dashBoardFilter.length>1){
                dashBoardFilter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
                dashBoardFilter[dashBoardFilter.length-1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
            }
            if(widgetFilterLength){
                concatenatedFilter.filter.forEach(e=>e.filter_display_name = undefined)
            }
            dashBoardFilter[0].logical_operator = "AND";
            dashBoardFilter.forEach(e=>{
                if(widgetFilterLength>0){// if we have a widget filter AND a dashboard filter, translate the basic dashboard filter to advanced 
                    e.isBasicFilter = false;
                }
                dashboardFilterObj = e;
                concatenatedFilter.filter.push(dashboardFilterObj);
            })
        }
        concatenatedFilter = JSON.stringify(concatenatedFilter);
        config[DASHBOARDS.FILTER] = dashboardFilterObj? concatenatedFilter: widget[DASHBOARDS.FILTER];      //avoid using too many arguments
        let type = config.profit_stack_type;
        if(type === time_range_values.periods){  
          if(this.hasMOMAccess.current) {
            url = url.replace(ALL_WIDGETS.FIELDS.PROFIT_STACKS,ALL_WIDGETS.FIELDS.STACKS_MOM)
          } else {
            url = url.replace(ALL_WIDGETS.FIELDS.PROFIT_STACKS,ALL_WIDGETS.FIELDS.STACKS_YTD)
          }
        }else if(type === time_range_values.ytd||type === time_range_values.qtd){    
            url=url.replace(ALL_WIDGETS.FIELDS.PROFIT_STACKS,ALL_WIDGETS.FIELDS.STACKS_YOY)
        }
        this.props.goToWidgetReport(config, url, isConcatenationOfFilters);
    }

    getWidgetAccess() {
        let widget = this.props.widget;
        let widgetType = widget[DASHBOARDS.WIDGET.WIDGET_TYPE];
        let config = tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]);
        let access = true;

        switch(widgetType){
            case DASHBOARDS.WIDGET_TYPES.LIST:
                let profile = this.props.profiles ? this.props.profiles.filter(e=>e[COLUMN_PROFILE.ID] === config[COLUMN_PROFILE.ID])[0] : undefined;
                if (!!profile) {
                    access = this.checkVectorAccess(config);
                    
                    if(access && config[DASHBOARDS.CONFIG_FIELD.SORTING_PSL]) {
                        //check if this profile contains columns that are not available for the selected scenario
                        // access = !this.props.getProfileInvalidColumns(profile).map(col=>col[PROFILE_COLUMN.RETURN_NAME]).includes(config[(widgetType === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS? DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS : DASHBOARDS.CONFIG_FIELD.SORTING_PSL)]);
                        access = this.props.getProfileInvalidColumns(profile).map(col=>col[PROFILE_COLUMN.RETURN_NAME]).filter(e=>(config[(widgetType === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS? DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS : DASHBOARDS.CONFIG_FIELD.SORTING_PSL)]).includes(e)).length === 0;
                    }
                    if (access) {
                        access = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.LIST);
                    }
                }else{
                    access = false;
                }
                break;
            case DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS:
                let profileVect = this.props.profilesVect ? this.props.profilesVect.filter(e=>e[COLUMN_PROFILE.ID] === config[COLUMN_PROFILE.ID])[0] : undefined;
                if (!!profileVect) {
                    access = this.checkVectorAccess(config);
                    
                    if(access && config[DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS]) {
                        let colName = config[DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS] ? config[DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS]
                        .replace("count_per_selection","").replace("count_per_total","").replace("_per_selection","").replace("_per_total","").replace("_minimum","")
                        .replace("_median","").replace("_range","").replace("_entity_count","").replace("_maximum","").replace("_average","")
                        .replace("_difference","").replace("_comparison","").replace("_selection","") : "";
                        //check if this profile contains columns that are not available for the selected scenario
                        // access = !this.props.getProfileInvalidColumns(profileVect).map(col=>col[PROFILE_COLUMN.RETURN_NAME]).includes(config[(widgetType === DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS? DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS : DASHBOARDS.CONFIG_FIELD.SORTING_PSL)]);
                        access = this.props.getProfileInvalidColumns(profileVect).map(col=>col[PROFILE_COLUMN.RETURN_NAME]).filter(e=>(colName).includes(e)).length === 0;
                    }
                    if (access) {
                        access = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.EROSION_AND_GROWTH);
                    }
                }else{
                    access = false;
                }
                break;

            case DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC:
                if(this.props.data && this.props.data[0]){
                    access = this.checkVectorAccess(config) && (_error in this.props.data[0] ? false : true);
                }
                break;

            case DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC:
                if(this.props.data && this.props.data[0]){
                    access = _error in this.props.data[0] ? false : true;
                }
                break;
            case DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE:
                if(this.props.data && this.props.data[0]){
                    access = _error in this.props.data[0] ? false : true;
                }
            break;

            case DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS:
                let type = config.profit_stack_type
                if(type === time_range_values.periods){    
                    var hasYTDAccess = getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_YTD);
                    access = this.hasMOMAccess.current || hasYTDAccess;
                }else if(type === time_range_values.ytd||type === time_range_values.qtd){    
                    access = getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_YOY);
                }else if(!type){
                    access = false;
                }
                break;

            case DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC:
                if(this.props.data && this.props.data[0]){
                    access = _error in this.props.data[0] ? false : true;
                }
                break;

            case DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT:
                let vectorAccess = this.checkVectorAccess(config);
                let bridgeReportAccess = getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.BRIDGE_REPORT);
                let allowedBridgeReport = getVectorAllowedAttributes(TOTAL_COMPANY, VECTOR_GROUPS.OPERATIONS, this.props.user);
                let accessAllowed = true;
                if (!allowedBridgeReport.includes(IDENTIFIERS.NAME) && !allowedBridgeReport.includes(IDENTIFIERS.NUMBER)) {
                    accessAllowed =  false;
                }
                // let accessDinied = this.widgetRef && this.widgetRef.state && this.widgetRef.state.isError;
                access = vectorAccess && bridgeReportAccess && accessAllowed; //&& !accessDinied;
            break;
        }

        return access;
    }

    computePriorPeriods=(startQuarter, endQuarter, customStartDate, customEndDate)=> {
        let _this = this;
        if(!_this.props){
            return [];
        }
        let comparisonValue = _this.props.widget && tryParse(_this.props.widget[DASHBOARDS.WIDGET.CONFIGURATION]).comparison;

        var currStartDate = customStartDate ? customStartDate : _this.props.customStartDate;
        var currEndDate = customEndDate ? customEndDate : _this.props.customEndDate;

        var periodsCount = monthDiff(currStartDate, currEndDate) + 1;
        var currentPeriods = generatePeriods(currStartDate, periodsCount);
        var previousPeriods = generatePeriods(currStartDate, periodsCount, ![YEAR_OVER_YEAR,PREVIOUS_ADJACENT].includes(comparisonValue)? undefined : comparisonValue === PREVIOUS_ADJACENT);
        var currPeriods = currentPeriods.join("','");
        var prePeriods = "" + previousPeriods.join("','");
        _this.setState({
            periods: [currPeriods, prePeriods]
        })
        return [currPeriods, prePeriods];
    }

    checkForLimitAccessMessage=(data, showModal)=> {
        let noAccessMessage = data.no_access_message || data.ERROR;
        if(!!noAccessMessage && showModal && !this.props.checkedPsLines && !this.props.isDialogOpen) {
            if(data.no_access_message){
                this.props.setIsDialogOpen(true);
                this.props.setAccessDenied(true);
                this.setLimitAccessDialogOpen(true, noAccessMessage)        
            }
        }
        return !!noAccessMessage;
    }

    setLimitAccessDialogOpen = (isOpen, msg) => {
        let _this = this;
        _this.setState({
            openLimitAccessDialog: isOpen,
            limitAccessMsg: msg
        });
    }

    getMessageSpan(message, size) {
        let textSize = size === undefined ? "widget-error-message-text" : size; 
        return <span className={"uk-flex uk-flex-middle uk-flex-center uk-width-1-1 uk-height-1-1 " + textSize}>{message}</span>
    }

    getLegendItems = () => {
        let _this = this;
        let legendItems = [];

        let alphaLegend = this.getLegendMessage(VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL);
        let betaLegend = this.getLegendMessage(VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL);
        let data = _this.widgetRef && _this.widgetRef.state.chartData ? _this.widgetRef.state.chartData : [];
        let value = Number(data.filter(e => e.returnName.includes("selection_")).length > 0 ?
              data.filter(e => e.returnName.includes("selection_"))[0].value : "0") - Number(data.filter(e => e.returnName.includes("comparison_")).length > 0 ?
            data.filter(e => e.returnName.includes("comparison_"))[0].value : "0");

        let conf = tryParse(_this.props.widget[DASHBOARDS.WIDGET.CONFIGURATION]);
        let formattedValue = formatValHTML(value, FormatTypes.AMOUNT);
        let variance = "<span class='uk-text-bolder'>"+VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL + " " + findOptionByKey(this.props.bridgeOptions, conf.bridge.value)["label"] + ":</span> " + formattedValue;

        legendItems.push(alphaLegend);
        legendItems.push(betaLegend);
        legendItems.push(variance);

        return legendItems;
    }

    /**
     * Function that returns the legend message based on the comparison value and and the symbol in colValue
     * @param colValue
     * @returns {string}
     */
     getLegendMessage = (colValue) => {
        let legendMessage = "";
        let conf = tryParse(this.props.widget[DASHBOARDS.WIDGET.CONFIGURATION]);
        let comparisonValue = conf[DASHBOARDS.CONFIG_FIELD.COMPARISON];
        let periods = this.state.periods;
        let comparisonArray = [];
        let selectionArray = [];

        if (periods !== undefined && periods.length !== 0) {
            comparisonArray = periods[1].replaceAll("\'", "").split(",");
            selectionArray = periods[0].replaceAll("\'", "").split(",");
        }

        if (comparisonValue === YEAR_OVER_YEAR || comparisonValue === PREVIOUS_ADJACENT) {
            if (colValue === VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL) {
                if (comparisonArray[0] === comparisonArray[comparisonArray.length - 1]) {
                    legendMessage = "<span class='uk-text-bolder'>α:</span> " + comparisonArray[0];
                } else {
                    legendMessage = "<span class='uk-text-bolder'>α:</span> " + comparisonArray[0] + " to " + comparisonArray[comparisonArray.length - 1];
                }
            } else if (colValue === VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL) {
                if (selectionArray[0] === selectionArray[selectionArray.length - 1]) {
                    legendMessage = "<span class='uk-text-bolder'>β:</span> " + selectionArray[0];
                } else {
                    legendMessage = "<span class='uk-text-bolder'>β:</span> " + selectionArray[0] + " to " + selectionArray[selectionArray.length - 1];
                }
            }
        }
        if (colValue === VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL) {
            legendMessage = VECTOR_ANALYSIS.FIELDS.DELTA_FORMULA;
        }
        return legendMessage;
    }

    checkIfFilterApplicable=()=>{
        let _this = this;
        let jsonFilter="";
        if(_this.props.dashboardFilter){
            jsonFilter = typeof _this.props.dashboardFilter === "string" ? JSON.parse(_this.props.dashboardFilter) : _this.props.dashboardFilter;
            return jsonFilter.filter(e=>e.field.includes('quadrant')).length===0
        }
        return true;
    }

    renderSelf(){
        let widget = this.props.widget;
        let widgetType = widget[DASHBOARDS.WIDGET.WIDGET_TYPE];
        let config = copyObjectValues(tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]));
        let type = config.profit_stack_type;

        let isProfitStacks = widgetType === DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS;
        let isPSPeriodType = type === time_range_values.periods;
        let psPeriodTitle = this.hasMOMAccess.current ? ALL_WIDGETS.FIELDS.MOM_STACKS : ALL_WIDGETS.FIELDS.YTD_STACKS
        let profitStacksTitle = isProfitStacks ? (isPSPeriodType ? psPeriodTitle : ALL_WIDGETS.FIELDS.YOY_STACKS) : undefined;
        let bridgeReportTitle = widgetType === DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT ? ALL_WIDGETS.TITLES.PROFIT_LANDSCAPE.VECTOR_ANALYSIS : undefined;
        
        let footer = bridgeReportTitle || profitStacksTitle || Inflector.singularize(widget[DASHBOARDS.WIDGET.WIDGET_NAME]);
        let comp = "";
        let title = widget[DASHBOARDS.NAME];
        let widgetId = widget[_widgetId];
        let tooltip = widget[DASHBOARDS.DESCRIPTION];

        if(!!this.state.errorMessage) {
            comp = this.getMessageSpan(this.state.errorMessage);
            return this.renderHeaderandFooter(widgetId, title, comp, footer, tooltip, widget);
        }
        let has_access = this.getWidgetAccess();
        let isFilterApplicable = true;
        switch(widgetType){
            case DASHBOARDS.WIDGET_TYPES.LIST: {
                let config = tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]);
                let profile = this.props.profiles.length > 0  ? this.props.profiles.filter(e=>e[COLUMN_PROFILE.ID] === config[COLUMN_PROFILE.ID])[0] : undefined;
                let columns = [];
                let simplified_columns = [];
                if (!!profile) {
                    profile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS] = profile[COLUMN_PROFILE.COLUMNS];
                    columns = profile[COLUMN_PROFILE.COLUMNS];
                    simplified_columns = profile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS];
                }
                
                comp = has_access && !!profile ?
                    <ProfitMapList key={"list-report"}
                        ref={el => this.widgetRef=el}
                        headerGoEnabled={this.props.headerGoEnabled}
                        columns={columns}
                        simplifiedColumns={simplified_columns}
                        filter={widget[DASHBOARDS.FILTER]} headerRef={this.props.headerRef}
                        user={this.props.user}
                        isQuadrant={config[DASHBOARDS.PROFIT_FORMAT] === ALL_REPORTS.LIST_QUADRANT.toLowerCase().replace(" ","_") || config[DASHBOARDS.PROFIT_FORMAT] === ALL_REPORTS.LIST_QUADRANT_TIER.toLowerCase().replace(" ","_") ? true : false}
                        dataset={this.props.dataset} FY={this.props.months}
                        selectedPeriods={this.props.selectedPeriods}
                        dataTier={config[DASHBOARDS.VECTOR]}
                        currency={this.state.currency}
                        limit={this.getLimit}
                        options={this.props.vectorOptions}
                        dbDataSet={this.props.dbDataSet}
                        project_id={this.props.project_id}
                        tablePrefix={this.props.tablePrefix}
                        order={config[DASHBOARDS.CONFIG_FIELD.SORTING_DIRECTION]}
                        profitFormat={config[DASHBOARDS.PROFIT_FORMAT]}
                        manageColumnsProfile={profile}
                        isDrilling={false}
                        scenario_id={this.props.scenario}
                        scenarioNumber={this.props.scenarioNumber}
                        fetchDataOnMount={true}
                        checkForLimitAccessMessage={this.checkForLimitAccessMessage}
                        parentComp={this}
                        isDashBoards={true}
                        selectedDashboardId={this.props.selectedDashboardId}
                        unique_id={config[DASHBOARDS.PROFIT_FORMAT] + this.props.dataset + this.props.FY + this.props.selectedDashboardId + this.props.scenario + JSON.stringify(this.props.selectedPeriods)+ JSON.stringify(this.props.dashboardFilter)}
                        sorting_psl={config[DASHBOARDS.CONFIG_FIELD.SORTING_PSL] ? config[DASHBOARDS.CONFIG_FIELD.SORTING_PSL] : ""}
                        loaderCallback={this.setIsLoading}
                        handleAPIError={this.handleAPIError}
                        fromWidget = {true}
                        dashboardFilter={this.props.dashboardFilter}
                        widgetId={widgetId}
                        rollingPeriod={this.props.rollingPeriod}
                        /> :
                    // : !!profile ?
                        this.getMessageSpan(lang.dashboards.messages.no_permission)
                    // : ""
            }
            break;
            case DASHBOARDS.WIDGET_TYPES.VECTOR_ANALYSIS: {
                let config = copyObjectValues(tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]));
                let profile = this.props.profilesVect.length > 0 ? this.props.profilesVect.filter(e=>e[COLUMN_PROFILE.ID] === config[COLUMN_PROFILE.ID])[0] : undefined;
                let columns = [];
                let simplified_columns = [];
                if (!!profile) {
                    profile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS] = profile[COLUMN_PROFILE.COLUMNS];
                    columns = profile[COLUMN_PROFILE.COLUMNS];
                    simplified_columns = profile[COLUMN_PROFILE.SIMPLIFIED_COLUMNS];
                }
                
                comp = has_access && !!profile ?
                    <ErosionAndGrowth
                        ref={el => this.widgetRef=el}
                        index={this.props.index}
                        match={this.props.match} 
                        location={this.props.location} 
                        history={this.props.history} 
                        hideHeader={true}
                        showLoader={false}
                        userAllowedMenuLinks={this.props.userAllowedMenuLinks}
                        headerGoEnabled={this.props.headerGoEnabled}
                        columns={columns}
                        vectorObjects={this.props.vectorObjects}
                        simplifiedColumns={simplified_columns}
                        filter={widget[DASHBOARDS.FILTER]} headerRef={this.props.headerRef}
                        user={this.props.user}
                        isQuadrant={config[DASHBOARDS.PROFIT_FORMAT] === ALL_REPORTS.LIST_QUADRANT.toLowerCase().replace(" ","_") || config[DASHBOARDS.PROFIT_FORMAT] === ALL_REPORTS.LIST_QUADRANT_TIER.toLowerCase().replace(" ","_") ? true : false}
                        dataset={this.props.dataset}
                        FY={this.props.months}
                        startQuarter={this.props.startQuarter}
                        endQuarter = {this.props.endQuarter}
                        customStartDate={this.props.customStartDate}
                        customEndDate={this.props.customEndDate}
                        selectedPeriods={this.props.selectedPeriods}
                        comparisonValue={config[DASHBOARDS.CONFIG_FIELD.COMPARISON]}
                        vector={config[DASHBOARDS.VECTOR]}
                        currency={this.state.currency}
                        limit={this.getLimit}
                        options={this.props.vectorOptions}
                        dbDataSet={this.props.dbDataSet}
                        project_id={this.props.project_id}
                        tablePrefix={this.props.tablePrefix}
                        order={config[DASHBOARDS.CONFIG_FIELD.SORTING_DIRECTION]}
                        sorter={config[DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS] ? config[DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS].replace(MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME,"")
                            :""}
                        profitFormat={config[DASHBOARDS.PROFIT_FORMAT]}
                        manageColumnsProfile={profile}
                        scenario_id={this.props.scenario}
                        scenarioNumber={this.props.scenarioNumber}
                        fetchDataOnMount={true}
                        checkForLimitAccessMessage={this.checkForLimitAccessMessage}
                        parentComp={this}
                        isDashBoards={true}
                        selectedDashboardId={this.props.selectedDashboardId}
                        unique_id={config[DASHBOARDS.CONFIG_FIELD.COMPARISON] + config[DASHBOARDS.PROFIT_FORMAT] + this.props.dataset + this.props.FY + this.props.selectedDashboardId + JSON.stringify(this.props.selectedPeriods)}
                        sorting_psl={config[DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS] ? config[DASHBOARDS.CONFIG_FIELD.SORTING_PSL_VECTOR_ANALYSIS].replace(MANAGE_COLUMNS.SELECTED_VECTOR_MACHINE_NAME,"")
                        :""}
                        loaderCallback={this.setIsLoading}
                        handleAPIError={this.handleAPIError}
                        fromWidget = {true}
                        datasetOptions={this.props.datasetOptions}
                        dashboardFilter={this.props.dashboardFilter}
                        periods={this.props.periods}
                        height={this.props.widgetHeight}
                        vectorState={this.props.vectorState}
                        datasetState={this.props.datasetState}
                        scenarioState={this.props.scenarioState}
                        periodsStatusState={this.props.periodsStatusState}
                        clientPeriodsState={this.props.clientPeriodsState}
                        userSettingsState={this.props.userSettingsState}
                        checkIfAtLeastOnePeriodIsGenerated={this.props?.checkIfAtLeastOnePeriodIsGenerated}

                    /> :
                    // : !!profile ?
                        this.getMessageSpan(lang.dashboards.messages.no_permission)
                    // : ""
            }
            break;
            case DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC:
            case DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC :
            case DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC :{
                let config = copyObjectValues(tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]));
                let comparison = config ? capitaliseFirstLetterAfterChar(config[DASHBOARDS.CONFIG_FIELD.COMPARISON].replace(/_/g," ")) : "";
                comp = has_access ? 
                    <MetricWidget config={config} data={this.props.data ? this.props.data[0] : {}} comparison={comparison}/> 
                    : this.getMessageSpan(lang.dashboards.messages.no_permission, "widget-error-message-text")
            }
            break;
            case DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE :{
                let config = copyObjectValues(tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]));
                let comparison_attr = config ? capitaliseFirstLetterAfterChar(config[DASHBOARDS.CONFIG_FIELD.COMPARISON].replace(/_/g," ")) : "";
                comp = has_access ? <MetricWidget config = {config} data={this.props.data ? this.props.data[0] : {}} comparison={comparison_attr}/> 
                        : this.getMessageSpan(lang.dashboards.messages.no_permission, "widget-error-message-text")
            }
            break;
            case DASHBOARDS.WIDGET_TYPES.PROFIT_STACKS:
                let config = copyObjectValues(tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]));
                let profitStackType = config && config[DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_TYPE];
                let checkedPsLines = config && config[DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_LINE];
                checkedPsLines = Array.isArray(checkedPsLines) ? checkedPsLines : [checkedPsLines];
                let customViewId = config ? config.custom_view : "";
                let type = config.profit_stack_type
                if(type === time_range_values.periods){   
                  if(!this.hasMOMAccess.current) {
                    this.hasMOMAccess.current = getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_MOM);
                  }
                  var hasYTDAccess = getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_YTD); 
                  has_access = this.hasMOMAccess.current || hasYTDAccess;
                }else if(type === time_range_values.ytd||type === time_range_values.qtd){    
                  has_access = getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_YOY);
                }
                isFilterApplicable = this.checkIfFilterApplicable();
                comp = !!has_access ? (!isFilterApplicable?
                    this.getMessageSpan(lang.dashboards.messages.not_compatible_with_filter, " widget-error-message-text")
                    :
                     <ProfitStacks
                        ref={el => this.widgetRef=el}
                        match={this.props.match} 
                        location={this.props.location} 
                        history={this.props.history} 
                        callOnMount={true}
                        hideTabulator={true}
                        hideRightSection={true}
                        hideLeftSection={true}
                        print={true}
                        hideTable={true}
                        hideLegend={false}
                        checkedPsLines={Array.isArray(config[DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_LINE]) ? config[DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_LINE] : [config[DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_LINE]]}
                        profitStackViewId={Number(config.custom_view)} 
                        periodRange={config[DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_TYPE]}
                        scenario={this.props.scenario}
                        loaderCallback={this.setIsLoading}
                        hideHeader={true}
                        width={"100%"}
                        userAllowedMenuLinks={this.props.userAllowedMenuLinks}
                        height={this.props.widgetHeight}
                        filterFinal={widget[DASHBOARDS.FILTER]}
                        dashboardFilter={this.props.dashboardFilter}
                        checkForLimitAccessMessage={this.checkForLimitAccessMessage}
                        periodOptions={this.props.periodOptions}
                        showLoader={false}
                        periods={this.props.periods}
                        has_access ={!has_access || this.props.profitStackViews &&  this.props.profitStackViews.filter(e=>e.value === config[DASHBOARDS.CUSTOM_VIEW]).length == 0 ?  false : true}
                        handleAPIError={this.handleAPIError}
                        unique_id={profitStackType+"_"+JSON.stringify(checkedPsLines)+"_"+customViewId+"_"+JSON.stringify(this.props.selectedPeriods)+"_"+widget[DASHBOARDS.FILTER]+"_"+ JSON.stringify(this.props.dashboardFilter)}
                        period={this.props.selectedPeriods && this.props.selectedPeriods.length > 0 && {value:this.props.selectedPeriods[this.props.selectedPeriods.length-1], quarter:"Q"+getPeriodQuarter(this.props.selectedPeriods[this.props.selectedPeriods.length-1]).split("Q")[1]}}
                        isWidgetProfitStacks
                        isDashBoards
                        headerRef={this.props.headerRef}
                        isMoM={config[DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_TYPE] === time_range_values.periods && this.hasMOMAccess.current}
                        isYOY={[time_range_values.qtd, time_range_values.ytd].includes(config[DASHBOARDS.CONFIG_FIELD.PROFIT_STACK_TYPE])}
                        clientPeriodsState={this.props.clientPeriodsState}
                        periodsStatusState={this.props.periodsStatusState}
                        datasetState={this.props.datasetState}
                        profitStackViews={this.props.profitStackViews}
                />) : this.getMessageSpan(lang.dashboards.messages.no_permission, "uk-text-medium")

            break;
            case DASHBOARDS.WIDGET_TYPES.BRIDGE_REPORT:{
                let config = copyObjectValues(tryParse(widget[DASHBOARDS.WIDGET.CONFIGURATION]));
                isFilterApplicable = this.checkIfFilterApplicable();
                comp = has_access ?
                        (!isFilterApplicable? 
                            this.getMessageSpan(lang.dashboards.messages.not_compatible_with_filter, "widget-error-message-text")
                        :
                        <BridgeReport
                            bridge = {config.bridge.value}
                            bridgeCustomViewId = {config.bridge.custom_view_id}
                            bridgeLabel={findOptionByKey(this.props.bridgeOptions, config.bridge.value)["label"]}
                            bridgeReportId = {config.bridge.report_id}
                            checkForLimitAccessMessage = {this.checkForLimitAccessMessage}
                            comparisonValue = {config.comparison}
                            computePriorPeriods = {this.computePriorPeriods}
                            dashboardFilter={this.props.dashboardFilter}
                            dontRenderTable = {true}
                            fetchDataOnMount = {true}
                            filter = {tryParse(widget[DASHBOARDS.FILTER]).filter}
                            fromWidget={true}
                            getLegendItems={this.getLegendItems} 
                            getPeriodsObject = {this.props.getPeriodsObject}
                            handleAPIError={this.handleAPIError}
                            isPV = {this.props.isPV}
                            key = {widget[DASHBOARDS.NAME] + config.bridge.custom_view_id + "_" + config.bridge.report_id + "_" + widget[DASHBOARDS.FILTER] + "_" + config.comparison + "_" + config[DASHBOARDS.VECTOR]}
                            loaderCallback={this.setIsLoading}
                            mixVector = {config[DASHBOARDS.VECTOR]}
                            ref={el => this.widgetRef=el}
                            scenario = {this.props.scenario}
                            unique_id = {widget[DASHBOARDS.NAME]}
                            vector = {TOTAL_COMPANY}
                            vectorOptions={this.props.vectorOptions}
                            isDashboards = {true}
                            />)
                        :
                        this.getMessageSpan(lang.dashboards.messages.no_permission);
            }
            break;
        }
        
        return this.renderHeaderandFooter(widgetId,title, comp, footer, tooltip, widget, has_access, isFilterApplicable);
    }

    renderHeaderandFooter(widgetId,title, comp, footer, tooltip, widget, has_access, isFilterApplicable) {
        //let currentDashboard = this.state.dashboards.filter(dshb=>dshb[_dashboardId] === dashboardId)[0];
        let _this = this;
        let isManageDashboardsAllowed = (getSectionExists(_this.props.userAllowedMenuLinks,MENU_ITEM.FIELDS.CREATE_EDIT_USER_DASHBOARDS) && _this.props.selectedDashboard[_visibility] === _visibilityOptions.USER) || 
                                        (getSectionExists(_this.props.userAllowedMenuLinks,MENU_ITEM.FIELDS.CREATE_EDIT_COMPANY_DASHBOARDS) && _this.props.selectedDashboard[_visibility] === _visibilityOptions.COMPANY
                                        && (!_this.props.selectedDashboard[_isCompanyDefault] || (_this.props.user && parseBoolean(_this.props.user[_isSystem]))));
        let isOldManageDashboardsAllowed = (getSectionExists(_this.props.userAllowedMenuLinks,ALL_WIDGETS.MANAGE_USER_DASHBOARDS) && _this.props.selectedDashboard[_visibility] === _visibilityOptions.USER) || 
        (getSectionExists(_this.props.userAllowedMenuLinks,ALL_WIDGETS.MANAGE_COMPANY_DASHBOARDS) && _this.props.selectedDashboard[_visibility] === _visibilityOptions.COMPANY
        && (!_this.props.selectedDashboard[_isCompanyDefault] || (_this.props.user && parseBoolean(_this.props.user[_isSystem]))));
        
        isManageDashboardsAllowed = isManageDashboardsAllowed || isOldManageDashboardsAllowed;
        let key = this.props.widget[_widgetId];

        // let isLoading = has_access === true ? this.state.isLoading : false;
        let isLoading = !isFilterApplicable? false : this.state.isLoading;
        
        return (
            <div className={(!has_access || !isFilterApplicable) && !isLoading ? "uk-background-primary" : ""}>
                <div style={{height:'100%'}} className={`${(this.state.isLoading ? "uk-height-1-1" : "widget-container")} uk-padding-xsmall`}>
                    <div className="uk-flex uk-flex-between">
                        <div className="uk-flex">
                            <div className="uk-flex-inline uk-flex-middle grabbable-header">
                                <i className="text-dark-grey fa-lg fas fa-grip-vertical uk-margin-small-right" aria-hidden="true"></i>
                                <span className="has-partial-red-line">{title}</span>
                                {tooltip ? <i className="fal fa-info-circle uk-cursor-pointer uk-margin-small-left" uk-tooltip={tooltip}></i> : ""}
                            </div>
                        </div>
                        <div className="uk-padding-xsmall-left uk-flex align_items_center">
                            {!((!has_access || !isFilterApplicable) && !isLoading) && ![DASHBOARDS.WIDGET_TYPES.VECTOR_METRIC, DASHBOARDS.WIDGET_TYPES.PROFIT_STACK_LINE_METRIC, DASHBOARDS.WIDGET_TYPES.OTHER_COLUMNS_METRIC, DASHBOARDS.WIDGET_TYPES.LINE_ATTRIBUTE].includes(this.props.widget[DASHBOARDS.WIDGET.WIDGET_TYPE]) &&
                                <a className="uk-margin-small-right link_text_button" id={replaceSpecialCharacters(footer,"_")} onClick={this.goToWidgetReport}>{footer}</a>
                            }
                            <Button  
                                variant={BUTTON_VARIANT.TERTIARY}
                                size={SIZES.ICON}
                                type={BUTTON_TYPE.DEFAULT}
                                leftIcon={<i className="fa-2x fal fa-ellipsis-v" aria-hidden="true" />}
                                className={"uk-button-icon"}
                                aria-expanded="true"
                                disabled={!isManageDashboardsAllowed}
                            />
                            <div uk-dropdown="mode: click; pos: bottom-right;" id={"widget_icons_dropdown_"+key}>
                                <div className="uk-button-icon uk-flex-between dots_option" onClick={() => { this.props.onEditWidget(widget, key) }}>
                                    <div className="uk-flex uk-flex-middle">
                                        <i className="fal fa-pen fa-lg uk-margin-small-right" /><span>{lang.dashboards.messages.edit_widget}</span>
                                    </div>
                                </div>
                                <div className="uk-button-icon uk-flex-between dots_option" onClick={() => this.props.onDelete(widgetId, key)}>
                                    <div className="uk-flex uk-flex-middle">
                                        <i className="fa-lg fal fa-trash-alt uk-margin-small-right" /><span>{lang.dashboards.messages.delete_widget}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-container-height">
                        {isLoading ?
                            <div className="dashboard-skeleton-container-height">
                                <SkeletonLoader />
                            </div>
                            : ""
                        }
                        <div className={`${(isLoading ? "uk-hidden" : "")} uk-height-1-1 uk-margin-small-top widget-data`}>
                            {comp}
                        </div>
                    </div>
                </div>
                <Modal 
					id={"check-limit-access-dialog"}
					openDialog={this.state.openLimitAccessDialog}
					closeClick={() => this.setLimitAccessDialogOpen(false, "")}
					bodyContent={() => <span className="uk-text-large">{this.state.limitAccessMsg}</span>}
					dialogActions={() => (
                        <Button 
                            label={lang.modal.buttons.ok}
                            variant={BUTTON_VARIANT.PRIMARY}
                            size={SIZES.DEFAULT}
                            type={BUTTON_TYPE.DEFAULT}  
                            aria-label="Close"
                            onBtnClick={() => this.setLimitAccessDialogOpen(false, "") }
                        /> 
                    )}
					size={DIALOG_SIZE.MEDIUM}
				/>
            </div>
        )
   }

    render() {
        var renderSelf = this.renderSelf();
        return (
            renderSelf      
        );
    }
}

export default Widget;
