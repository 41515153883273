import React, {useEffect, useRef} from 'react';
import Tabulator from "tabulator-tables";
import {customFilterEditor, getExpandCollapseButtons} from '../../newComponents/tabulatorComponents';
import { FORMAT_TYPE, FormatTypes, PS_MAPPING } from '../../class/constants.js';
import { convertPxToViewport } from '../../class/formatting.js';
import { Format, formatValString, formatQuadrant, formatValHTML } from '../../class/format.js';
import { linearizeHierarchy } from '../../class/array.js';
const $ = require("jquery");

const _children = PS_MAPPING.FIELDS.CHILDREN;

const OutliersTable = (props) => {
    const headerValue = useRef("")
    const isExpanded = useRef(false)
    const tabulatorRef = useRef(null);

    useEffect(() => {
        const options = {
            layout: "fitColumns",
            responsiveLayout: false,
            addRowPos: "top",
            pagination:"local",
            paginationSize:10,
            paginationSizeSelector:[5, 10, 20, 25, 50, 100],
            history: true,
            movableColumns: false,
            autoResize: false,
            resizableColumns: false,
            resizableRows: false,
            selectable: false,
            dataTree: true,
            dataTreeChildField: _children,
            dataTreeStartExpanded: function (row){
                if (headerValue.current !== null && headerValue.current !== "" && (hasMatchingChild(row.getData(), headerValue.current))) {
                    return true;
                } else {
                    return isExpanded.current;
                }
            },
            dataTreeRowExpanded: function (row, level) {
                row.getData().expanded = true;
            },
            dataTreeRowCollapsed: function (row, level) {
                row.getData().expanded = false;
            },
            dataTreeElementColumn: PS_MAPPING.FIELDS.NAME,
            dataTreeCollapseElement: "<button id='expanded_tabulator_arrow'></button>",
            dataTreeExpandElement: "<button id='collapsed_tabulator_arrow'></button>",
            dataTreeChildIndent: 15,
            dataTreeBranchElement: false,
            invalidOptionWarnings: false,
            reactiveData: true,
            virtualDomBuffer: 9000,
            columnHeaderVertAlign: "bottom",
            placeholder: "No data available",
            height: "100%",
            width: "100%",
            renderComplete: () => {
            },
            accessorDownload: (dataObj) => {
                // const data = linearize(dataObj.data, [], "children");
                // dataObj.data = data;
                // return tabulatorExport(dataObj, tabulatorRef.current);
            },
            downloadReady: (fileContents, blob) => {
                toggleLoader(false, "tablesToExcel");
                return blob;
            }
        };
        tabulatorRef.current = new Tabulator("#outliers-table", options);
        return () => {
            tabulatorRef.current?.destroy();
        };
    }, []);

    useEffect(()=>{
        if(props?.data?.length > 0 &&  tabulatorRef.current) {
          setColumnsForTable([{field:"name", title:"Brand/Sku Name"},{field:"segment", title:"Segment", format_type:FormatTypes.QUADRANT},{field:"returns", title:"Returns", format_type:FormatTypes.AMOUNT},{field:"sales",title:"Sales", format_type:FormatTypes.AMOUNT},{field:"return_rate", title:"Return Rate", format_type:FormatTypes.PERCENTAGE}, {field:"percentage_of_profit", title:"Impact On Margin", format_type:FormatTypes.PERCENTAGE}, {field:"min_opportunity", title:"Min Opportunity", format_type:FormatTypes.AMOUNT}, {field:"max_opportunity", title:"Max Opportunity", format_type:FormatTypes.AMOUNT}], props.data);
        }
    },[props.data])


    const getColumnFormatter = (colField, format) => {
        let columnFormatter = "";
        switch (colField) {
            case PS_MAPPING.FIELDS.NAME:
                columnFormatter = function (cell, formatterParams) {
                    var rowData = cell.getRow().getData();
                    var p = document.createElement("p");
                    var name = cell.getValue();
                    // p.textContent = (!cell.getRow().getData()[_children] || cell.getRow().getData()[_children].length === 0) && (!cell.getRow().getData()[COST_FUNCTIONS_FIELDS.SHOW_CHILDREN] && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.calculated && cell.getRow().getData()[COST_FUNCTIONS_FIELDS.COST_TYPE] !== costtype.attribute) && rowData['level'] !== 1 ? cell.getValue() + _hidden : cell.getValue();
                    p.title = cell.getValue();
                    if (cell.getRow().getData()["level"] !== 1) { // moving each child level to the right to appear as a child of its parent
                        var pixels = (cell.getRow().getData()["level"] - 1) * 20;
                        $(p).css("padding-left", convertPxToViewport(pixels));
                    }
                    if (rowData['level'] === 1) {
                        $(cell.getRow().getElement()).css({"background-color": "#f3f3f3"});
                        $(cell.getRow().getElement()).css({"border-color": "#DCDCDC"});
                    } else if (rowData["level"] === 2) {
                        $(cell.getRow().getElement()).css({"background-color": "rgba(202, 202, 202, 0.5)"});
                        $(cell.getRow().getElement()).css({"border-color": "#c6c6c6"});
                    } else {
                        $(cell.getRow().getElement()).css({"background-color": "rgb(202, 202, 202, 0.8)"});
                        $(cell.getRow().getElement()).css({"border-color": "#cacaca"});
                    }
                    if (headerValue.current !== "" && name.toLowerCase().includes(headerValue.current)) { // Convert name to lowercase for case-insensitive comparison
                        var index = name.toLowerCase().indexOf(headerValue.current); // Convert name to lowercase for case-insensitive comparison
                        var highlightedName = name.substring(0, index) +
                            '<span class="highlight_search_result">' + name.substring(index, index + headerValue.current.length) + '</span>' +
                            name.substring(index + headerValue.current.length);
                        p.innerHTML = highlightedName;
                    } else {
                        p.innerHTML = name;
                    }
                    return p;
                }
                break;
            default:  columnFormatter = function (cell) {
                    return formatValHTML(cell.getValue(), format);
                }
                break;
        }

        return columnFormatter;
    }

    // const exportTableToExcel = (exportOpts) => {
    //     const data = linearize(tabulatorRef.current.getData(), [], "children");
    //     const fileName = capitalizeFirstLetter(props.reportTitle);
    //     const sheetName = fileName.length > 31 ? fileName.substring(0, 28) + "..." : fileName;

    //     exportOpts.data = data;
    //     exportOpts.fileName = fileName;
    //     exportOpts.sheets = {[sheetName]: true};
    //     exportOpts.isProfitStack = false;

    //     exportToExcel(exportOpts, tabulatorRef.current);
    //     trackingExportExcelClick();
    // };

    // const trackingExportExcelClick = () => {
    //     fetchAPI({
    //         [FETCHAPI_PARAMS.funcName]: "trackingExportExcelClick",
    //         [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
    //         [FETCHAPI_PARAMS.showLoader]: false,
    //         [FETCHAPI_PARAMS.path]: API_URL.DATA_MODELING,
    //         [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
    //         [FETCHAPI_PARAMS.query]: {action: "trackingExportExcelClick"}
    //     });
    // };

    // const linearize = (arr, resultArr, childrenField) => {
    //     arr.forEach(row => {
    //         row[VECTOR_ANALYSIS.FIELDS.NAME] = appendExportUnderScore(row[VECTOR_ANALYSIS.FIELDS.NAME], row.level);
    //         resultArr.push(row);
    //         if (row[childrenField] && row[childrenField].length > 0 && row.expanded) {
    //             resultArr = linearize(row[childrenField], resultArr, childrenField);
    //         }
    //     });
    //     return resultArr;
    // };

    // const appendExportUnderScore = (name, level) => {
    //     return "_".repeat(level) + name;
    // };

 
    const callSearch = (headerValue) => {
      let rows = tabulatorRef.current.getRows();
      searchInNestedRows(rows, headerValue);
  }
    const setColumnsForTable = (columns, data) => {
        const formattedColumns = columns.map(col => {
            const updatedCol = {
                ...col,
                formatter: getColumnFormatter(col.field, col.format_type),
                headerSort: false,
                cssClass: "no-cursor",
                minWidth: 120,
            };
            if (col.field === PS_MAPPING.FIELDS.NAME) {
                updatedCol.headerFilter = customFilterEditor;
                updatedCol.headerFilterParams = {
                    func: (headerValue) => { callSearch(headerValue); },
                    getResultCount: getSearchResultCount,
                    collapseOnClearSearch: collapseAll,
                };
                updatedCol.headerFilterFunc = () => true;
            }
            return updatedCol;
        });

        tabulatorRef.current.setColumns(formattedColumns);
        tabulatorRef.current.setData(data);
        tabulatorRef.current.setSort("max_opportunity", "desc");
    };

    const collapseAll=(fromSearch)=> {
      let dataState = expandCollapseAllTabData(tabulatorRef.current.getData(), false);
      isExpanded.current = false;
      tabulatorRef.current.replaceData(dataState);
      if (fromSearch) {
          // props.callCollapse();
      }
  }

  /**
   * function applies a recursive loop that modifies the expanded flag and sets it equal to the valus of isExpanded sent as a parameter
   */
  const expandCollapseAllTabData=(dataState, isExpanded)=>{
      dataState.forEach((item) => {
          item.expanded = isExpanded;
          if (item.children && item.children.length > 0 && isExpanded) {
            item.children = expandCollapseAllTabData(item.children, isExpanded);
          }
      });
      return dataState;
  }

  /**
   * function calls @function expandCollapseAllTabData, changes the state of isExpanded and replaces data in tabulator
   */
  const expandAll=()=>{
      let dataState = expandCollapseAllTabData(tabulatorRef.current.getData(), true);
      isExpanded.current = true;
      tabulatorRef.current.replaceData(dataState);
  }

  
    const searchInNestedRows = (dataRows, headerValueVal) => {
        headerValue.current = headerValueVal;
        tabulatorRef.current.replaceData(tabulatorRef.current.getData());
    }

    const getSearchResultCount = () => {
        let dataState = tabulatorRef.current.getData();
        let linearizedData = linearizeHierarchy(dataState, _children);
        let resultFound = linearizedData.filter(f=>f.name.toLowerCase().includes(headerValue.current.toLowerCase().trim()));
        return resultFound.length;

    }

    const hasMatchingChild = (row, headerValue, fromChildren) => {
        if ((row.name.toLowerCase().includes(headerValue.toLowerCase().trim())) && fromChildren) {
            return true;
        }
        let children = row.children;
        if (children) {
            for (var e in children) {
                if (hasMatchingChild(children[e], headerValue, true)) {
                    return true;
                }
            }
        }
    }

		return(
            <div id="outliers-table"/>
		);
	}



export default OutliersTable;