import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SUPPLY_CHAIN_SETUP } from "../../class/constants.js";
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../class/networkUtils.js";
import FilterDialog from "../../sections/filter/FilterDialog.js";
import { linearizeHierarchy } from "../../class/array.js";
import { updateVectorEntities } from "../../templateLayout/actions/vectorEntitiesActions.js";
import { lang } from "../../language/messages_en.js";
import { getOutputScreenName } from "../../class/common.js";

const ExclusionsConfiguration = (props, ref) => {
  useImperativeHandle(ref, () => ({
    saveExclusionsConf: (callback) => {
      startSaveConfiguration(callback);
    },
  }));

  const dispatch = useDispatch();
  
  const filterSet = useRef(false);
  const filterDialRef = useRef();
  const isSaveBtnClicked = useRef(false);
  const usavedChangesCallback = useRef();
  const [exclusions, setExclusions] = useState(JSON.stringify(props.filter));
  // const [isFilterValid, setIsFilterValid] = useState(false);

  const psLinesOptions = useSelector(state=>state.psLinesOptions);
  const quadrantTierOptions = useSelector((state) => state.quadrantTierOptions);

  /**
   * Checking to see if the vectors or pslines used are still valid in the published scenario
   * **/
  useEffect(() => {
    if(props.filter) {
      let isConfValid = isExclusionsConfigurationValid(props.filter, props.vectorState?.vectorOptions, props.psLines);
      props.setHasUnsavedChanges(!isConfValid);
    }
  }, []);

  const isExclusionsConfigurationValid = (exclusionsList, vectors, psLines) => {
    let invalidFieldDetected = false;
    if (exclusionsList?.length == 0) {
      return true;
    }
    for (let item of exclusionsList) {
      if (!isVectorFieldValid(item, vectors)) {
        invalidFieldDetected = true;
        break;
      }

      if (!isPSLFieldValid(item, psLines)) {
        invalidFieldDetected = true;
        break;
      }
    }

    return !invalidFieldDetected;
  };

  const isVectorFieldValid = (row, vectors) => {
    return vectors.filter((f) => f.value === row.vector)?.length > 0;
  };

  const isPSLFieldValid = (row, psLines) => {
    if (!row.returnname && row.type !== "profit_stack") {
      return true;
    }
    return linearizeHierarchy(psLines).filter((f) => f.value === row.returnname)?.length > 0;
  };

  const getIndexesOfInvalidExclusions = (exclusionsList) => {
    let indexes = [];
    let vectors = props?.vectorState?.vectorOptions;
    let psLines = props?.psLines;

    if (!exclusionsList || exclusionsList?.length === 0) {
      return indexes;
    }

    for (let e in exclusionsList) {
      if (!isVectorFieldValid(exclusionsList[e], vectors)) {
        indexes.push(Number(e));
        continue;
      }

      if (!isPSLFieldValid(exclusionsList[e], psLines)) {
        indexes.push(Number(e));
      }
    }

    return indexes;
  }

  const setFilterTo = () => {
    let filterFinal = props.filter;
    let filter;
    //before saving the filter, save the old value if not previously set
    if (filterFinal && typeof filterFinal !== "string") {
      filter = JSON.stringify(filterFinal); //onChangeSavedFilter expects a string and not an array
    }
    let filterSetRef = filterSet.current;
    if (!filterSetRef && filterFinal?.length > 0) {
      filterSet.current = true;
      filterDialRef?.current?.onChangeSavedFilter(filter, false);
    }
  };

  /**
   * This function is triggered when we click on save.
   * It checks for validations and if all validations passed, it triggers onChangeFilter()
   */
  const startSaveConfiguration = (callback) => {
    isSaveBtnClicked.current = true;
    props.setHasUnsavedChanges(false);
    usavedChangesCallback.current = callback;

    filterDialRef?.current?.startApply();
  };

  const onChangeFilter = (exclusionsList) => {
    setExclusions(exclusionsList);
  };

  useEffect(() => {
    // check if save btn is clicked
    if (!isSaveBtnClicked.current) {
      return;
    }

    saveConfiguration(exclusions);
  }, [exclusions]);

  /**
   * This function sends an api request to save the exclusions
   * @param {*} exclusions
   */
  const saveConfiguration = (exclusions) => {
    const query = {
      action: "saveExclusionsConfiguration",
      exclusions: exclusions,
    };

    const onThenCallback = (data) => {
      let savedExclusions = [];
      isSaveBtnClicked.current = false;

      if (data.success) {
        savedExclusions = JSON.parse(exclusions);
      }

      dispatch(updateVectorEntities(undefined, [], true));
      props.updateConfigurationObject(!!data.success, SUPPLY_CHAIN_SETUP.TABS_DETAILS.EXCLUSIONS.NAME, savedExclusions);
      if (usavedChangesCallback?.current && typeof usavedChangesCallback?.current === 'function') {
        usavedChangesCallback.current();
      }
    };

    const fetchOptions = {
      [FETCHAPI_PARAMS.funcName]: "saveExclusionsConfiguration",
      [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
      [FETCHAPI_PARAMS.showLoader]: false,
      [FETCHAPI_PARAMS.path]: SUPPLY_CHAIN_SETUP.API_URLS.SAVE_EXCLUSIONS,
      [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
      [FETCHAPI_PARAMS.query]: query,
      [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
      [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.supply_chain.save_exclusions,
      [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href),
    };

    fetchAPI(fetchOptions);
  };

  return (
    <>
      <FilterDialog
        ref={filterDialRef}
        filterDisplayEditable={false}
        hideFilterDisplay={true}
        isDrilling={false}
        mustFetchDatasets={false}
        onChangeFilter={onChangeFilter}
        quadrantDefault={false}
        quadrantsDisabled={true}
        scenario_id={props?.scenarioId}
        useCookies={false}
        user={props.user}
        vectorOptions={props.vectorState?.vectorOptions}
        isFromExclusionConfiguration
        isNotModal
        setFilterTo={setFilterTo}
        // setIsFilterValid={setIsFilterValid}
        setHasUnsavedChanges={props.setHasUnsavedChanges}
        unsavedChangesClosingCallback={usavedChangesCallback?.current}
        dispatch={props.dispatch}
        getIndexesOfInvalidExclusions={getIndexesOfInvalidExclusions}
        psLinesOptions={psLinesOptions}
        quadrantTierOptions={quadrantTierOptions}
        shouldAddRank
      />
    </>
  );
};

export default forwardRef(ExclusionsConfiguration);
