import React from 'react';
import { DROPDOWN_TYPE } from "../class/constants";
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { convertPxToViewport } from "../class/formatting";
import { Icon } from "@mui/material";
// import '../../public/css/globalStyle.css'
import { forwardRef, useEffect, useState } from "react";
import { useImperativeHandle } from "react";
import $ from 'jquery'
import { lang } from '../language/messages_en';

/**
 * Dropdown component
 * @param {string} id Id of the dropdown
 * @param {string} className additional classNames
 * @param {string} type Could be "input" or "button" (use constants) (required)
 * @param {Boolan} disabled Sets the dropdown to be disbaled
 * @param {function} onDropdownClick A function that is called when the dropdown is clicked. (required in one case )
 * @returns The rendered button component
 */

const Dropdown = ({
    id,
    className,
    type,
    onDropdownClick,
    isOpen,
    placeholder,
    icon,
    menuWidth,
    dropDownWidth,
    disabled,
    isAsync,
    menuIsOpen,
    firstAttr,
    secondAttr,
    defaultStyles,
    value,
    maxMenuHeight,
    onlyArrow,
    defaultComponents,
    isFocused,
    dropdownRight,
    showSelectedTooltip,
    isTextAddedToIndicator,
    menuPlacement,
    removeDropdownIndicator=false,
    ...props // other props that needs to be added. like: aria-label
},ref) => {
    const [isDisabled, setIsDisabled] = useState(undefined);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        setDisabled:(disabledValue)=>{
            return setDisabled(disabledValue)
        },
        showDropdown:(forceClose)=>{
            return showDropdown(forceClose)
        },
        getDropDown:()=>{
            return isDropdownOpen
        }
      }));

  useEffect(() => {
    closeDropdown(firstAttr, secondAttr)
  }, [isDropdownOpen])

  const selectRef = React.createRef();
  const handleChange = (e) => {
    selectRef.current.blur();
    props.onChange(e);
  }
  const setDisabled = (disabledValue) => {
    setIsDisabled(disabledValue);
  }

  const closeDropdown = (firstAttr, secondAttr) => {
    if (firstAttr) {
      $(document).on("mouseover click", function (event) {
        if (!onlyArrow && ($(`${firstAttr}:hover`).length === 0) && (secondAttr ? $(`${secondAttr}:hover`).length === 0 : true) && (isDropdownOpen)) {
          if (event.type === "click") {
            setIsDropdownOpen(false);
          }
        }
      });
    }
  }
  const showDropdown = (forceClose) => {
      if (forceClose) {
          setIsDropdownOpen(false);
      }
      else{
          setIsDropdownOpen(!isDropdownOpen);
      }

  }

      const customStyles = {
        option: (styles) => ({
            ...styles,
            cursor: 'pointer',
            fontSize: convertPxToViewport(12),
            padding: convertPxToViewport(8) + ' 0 ' + convertPxToViewport(8) + ' ' + convertPxToViewport(9),
    
        }),
        control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            borderColor: '#dcdcdc',
            minHeight: convertPxToViewport(33),
            fontSize: convertPxToViewport(12),
            borderRadius: convertPxToViewport(4),
            borderWidth: convertPxToViewport(1),
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: convertPxToViewport(30),
            padding: '0 ' + convertPxToViewport(6)
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: convertPxToViewport(30),
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            paddingTop: convertPxToViewport(6),
            paddingBottom: convertPxToViewport(6),
        }),
        clearIndicator: (styles) => ({
            ...styles,
            paddingTop: convertPxToViewport(6),
            paddingBottom: convertPxToViewport(6),
        }),
        placeholder: defaultStyles => {
            return {
                ...defaultStyles,
                color: "#C6CCD7",
                lineHeight: convertPxToViewport(12)
            };
        },
        menu: provided => ({ ...provided, zIndex: 99, })
    };
    const customInputStyles = {
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
            fontSize: convertPxToViewport(14),
            padding: onlyArrow ? (state.isDisabled ? 0 : ' 0 ' + convertPxToViewport(8)) : state.isDisabled ? 0 : (convertPxToViewport(8) + ' ' + convertPxToViewport(12)),
            fontFamily: "Lato",
            borderBottom: !onlyArrow ? "none" : convertPxToViewport(1) + ' solid ' + ' #ECECEC ',
            height: onlyArrow ? (state.isDisabled ? convertPxToViewport(32) : convertPxToViewport(40)) : /*state.isDisabled ? convertPxToViewport(32) : */ "unset",
        }),
        control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            padding: ' 0 ' + convertPxToViewport(8),
            // borderColor: state.isFocused ? "#5455A8" : '#C6C6C6',
            border: onlyArrow ? "none" : (state.isFocused && state.menuIsOpen) ? convertPxToViewport(2) + ' solid ' + ' #5455A8 ': convertPxToViewport(2) + ' solid ' + ' #C6C6C6 ',
            outline: onlyArrow ? "1px solid #e4e4e4" : "none",
            boxShadow: state.isFocused ? "none" : 'none',
            minHeight: convertPxToViewport(24),
            height: onlyArrow ? "1.67vw" : convertPxToViewport(33),
            fontSize: convertPxToViewport(14),
            borderRadius: onlyArrow? (' 0 ' + convertPxToViewport(6) + ' ' + convertPxToViewport(6) + ' 0 ')   : convertPxToViewport(6),
            borderWidth: convertPxToViewport(2),
            background: state.isDisabled ? ' #E8E8E8' : '#FFFFFF',
            fontFamily: "Lato",
            '&:hover': {
                borderColor: '#5455A8',
            },
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            // height: convertPxToViewport(30),
            padding: 0,
            display: onlyArrow ? "none" : "flex"
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0',
            caretColor: state.selectProps.menuIsOpen ? 'black' : 'transparent' ,
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            // height: convertPxToViewport(33),
        }),
        dropdownIndicator: (styles, state) => ({
            ...styles,
            // paddingTop: convertPxToViewport(6),
            // paddingBottom: convertPxToViewport(6),
            padding: 0,
            color: state.isDisabled ? "#8D8D8D" : "#000000",
            // transform: `${state.isFocused ? "scaleY(-1)" : "scaleX(1)"}`,
            // transition: `all 0.2s ease`,
        }),
        clearIndicator: (styles) => ({
            ...styles,
            paddingTop: convertPxToViewport(6),
            paddingBottom: convertPxToViewport(6),
        }),
        placeholder: (defaultStyles, state) => {
            return {
                ...defaultStyles,
                color: state.isDisabled ? "#8D8D8D" : "#C6C6C6",
                lineHeight: convertPxToViewport(12),
                fontFamily: "Lato",
                '&:hover': {
                    borderColor: '#5455A8',
                }
            };
        },
        singleValue: (base, state) => ({
            ...base,
            marginRight: 0,
            maxWidth: "100%",
            marginLeft:0
            // transform: "translateY(-30%)",
        }),
        menu: provided => ({
            ...provided,
            zIndex: 99,
            width: menuWidth ? convertPxToViewport(menuWidth) : "100%",
            right:0,
        })
    };
    
    const customButtonStyles = {
        option: (styles) => ({
            ...styles,
            cursor: 'pointer',
            fontSize: convertPxToViewport(12),
            padding: convertPxToViewport(8) + ' 0 ' + convertPxToViewport(8) + ' ' + convertPxToViewport(9),
            fontFamily: "Lato",
            ...styles,
            textAlign: 'start',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
    
        }),
        control: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            borderColor: (state.getValue().length > 0 || state.isFocused) ? '#35366B' : '#C6C6C6',
            boxShadow: state.isFocused ? "none" : 'none',
            minHeight: convertPxToViewport(32),
            height: convertPxToViewport(32),
            fontSize: convertPxToViewport(14),
            borderRadius: convertPxToViewport(6),
            borderWidth: convertPxToViewport(1),
            borderColor:"#E4E4E4",
            background: state.isDisabled ? "#C6C6C6" : (state.getValue().length > 0 || state.isFocused) ? '#35366B' : '#FFFFFF',
            width: dropDownWidth ? convertPxToViewport(dropDownWidth) : "unset",
            fontFamily: "Lato",
            whiteSpace: "nowrap",
            padding:' 0 ' + convertPxToViewport(16),
            ".__single-value": {
                color: state.getValue().length > 0 ? "#FFFFFF" : "#08155E",
            },
            '&:hover': {
                borderColor: state.isFocused ? '#35366B' : '#C4C5F7',
                background: state.isFocused ? '#35366B' : '#F1F2FD',
                "& .__single-value": {
                    color: state.isFocused ? '#FFFFFF' : '#35366B' //using this method to change other elements style when hovering over control container
                },
                "& .__indicator": {
                    color: state.isFocused ? '#FFFFFF' : '#35366B',
                }
            },
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            height: convertPxToViewport(30),
            padding: 0,
            color: (state.getValue().length > 0 ) ? "#FFFFFF" : "#08155E",
            marginLeft: 0,
            flexWrap:"nowrap",
            display:"flex",
            width: "100%",
            // minWidth: icon ? convertPxToViewport(97) : convertPxToViewport(95),
            alignItems:"initial"
        }),
        input: (provided, state) => ({
            ...provided,
            margin: '0',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            // height: convertPxToViewport(30),
        }),
        dropdownIndicator: (styles, state) => ({
            // ...styles,
            // padding:0,
            // display:"none",
            padding: 0,
            color: state.isDisabled ? "#8D8D8D" : "#08155E",
            display: "flex",
            // color: (state.getValue().length > 0 || state.isFocused) ? "#FFFFFF" : "#000000",
            // transform: `${state.isFocused ? "scaleY(-1)" : "scaleX(1)"}`,
            // transition: `all 0.2s ease`,
            // '&:hover': {
            //     color: state.isFocused ? "#FFFFFF" : '#35366B',
            // },
        }),
        clearIndicator: (styles) => ({
            ...styles,
            paddingTop: convertPxToViewport(6),
            paddingBottom: convertPxToViewport(6),
        }),
        placeholder: (defaultStyles, state) => {
            return {
                ...defaultStyles,
                padding:0,
                margin:0,
                fontSize:convertPxToViewport(14),
                // display: $(".placeholder_button_dropdown .__placeholder").width() > 0 ? "flex" : "none",
                alignItems:"center",
                fontWeight: "bold",
                color: state.isDisabled ? "#8D8D8D" : state.isFocused ? "#FFFFFF" : "#08155E",
                lineHeight: convertPxToViewport(12),
                fontFamily: "Lato",
                '&:hover': {
                    borderColor: '#5455A8',
                }
            };
        },
        singleValue: (base, state) => {
            return {
            ...base,
            fontSize:convertPxToViewport(14),
            padding:0,
            margin:0,
            display:"flex",
            alignItems:"center",
            fontWeight: "bold",
            lineHeight: convertPxToViewport(12),
            fontFamily: "Lato",
            position: "relative",
            maxWidth: "unset"
        };
    },
        menu: styles => ({
            ...styles,
            width: menuWidth ? convertPxToViewport(menuWidth) : "100%",
            zIndex: 99,
            right: dropdownRight ? 0 : "unset"
        }),
    };

    const TooltipOption = (props) => {
        return (
            <div title={props?.data?.label} uk-tooltip="pos: top">
                <components.SingleValue {...props} />
            </div>
        );
    };

    const Option = (props) => {
        return (
            <div title={props.data.title}>
                 <components.Option {...props} />
            </div>
        );
    };

      const ArrowDownIcon = () => {
          return (
            <Icon id="chevron_down_icon" sx={{fontSize:convertPxToViewport(16)}} className={"far fa-chevron-down"} />
          );
      };
      
      const DropdownIndicator = props => {
          return (
            <div className={isTextAddedToIndicator ? "uk-display-flex" : ""}>
              {isTextAddedToIndicator && (
                <div className="start_on_left">
                  <span>{lang.twelve_months_rolling}</span>
                </div>
              )}
              {!removeDropdownIndicator && (
                <div id="arrow_icon_container" className="arrow_icon_container" onClick={showDropdown}>
                  <components.DropdownIndicator {...props}>
                    <ArrowDownIcon />
                  </components.DropdownIndicator>
                </div>
              )}
            </div>
          );
      };

      const Placeholder = props => { // before selecting the placeholder is displayed and after selecting an option the placeholder will be replaced by Single Value
          return (
              <div className="placeholder_button_dropdown">
                  <components.Placeholder {...props} >
                      {icon}
                      <span className="dropdown_placholder" style={{ padding: icon ? "0px 0.41667vw" :"0 0.41667vw 0 0" }} uk-tooltip={"title:"+(value?.length > 0 ? value.label:"")}>
                          {value?.length > 0 ? value.label : placeholder ? placeholder : "Select..."}
                      </span>
                      {/* <span className="dropdown_indicator_arrow"> */}
                      {/* {type === DROPDOWN_TYPE.BUTTON
                      ?
                      <ArrowDownIcon />
                      :
                      ""} */}
                      {/* </span> */}
                  </components.Placeholder>
              </div>
          );
      }
         
    const SingleValue = ({ children, ...props }) => ( // using single value to avoid replacing placeholder with value 
        <div className="value_container_button_dropdown">
            <components.SingleValue {...props}>
                {icon}
                <span style={{ padding: icon ? "0px 0.41667vw" :"0 0.41667vw 0 0" }}>
                {placeholder}
                </span>
                {/* <span className="dropdown_indicator_arrow">
                    <ArrowDownIcon />
                </span> */}
            </components.SingleValue>
        </div>
    );
    
     if(defaultComponents) {
        defaultComponents.DropdownIndicator=DropdownIndicator;
     }

     const click = () => {
        if(onDropdownClick) {
            onDropdownClick();
        } else if(firstAttr) {
            showDropdown()
        }
     }
    let menuPlcacementDirection = menuPlacement || "auto";
    return (
        //remove props.showDimensionDropdown and replace with props.onDropdownClick and send it as paramter 
        <div onClick={click} className={className} /*title={value && value.label && showSelectedTooltip ? value.label :  showSelectedTooltip ? 'None selected' :""}  uk-tooltip="pos: top"*/>
            {!isAsync ?
                <Select
                    data-testid="select-set"
                    id={id ? id : ""}
                    ref={selectRef}
                    classNamePrefix={"scenario-label " + (props.class || "")}
                    styles={type === DROPDOWN_TYPE.INPUT ? customInputStyles : type === DROPDOWN_TYPE.BUTTON ? customButtonStyles : customStyles}
                    // styles={type === DROPDOWN_TYPE.INPUT ? customStyles : type === DROPDOWN_TYPE.BUTTON ? customStyles : customStyles}
                    menuPlacement={menuPlcacementDirection}
                    maxMenuHeight={maxMenuHeight ? maxMenuHeight : convertPxToViewport(190)}
                    menuIsOpen={isFocused ? true : firstAttr ? isDropdownOpen : isOpen}
                    value={value}
                    {...props}
                    onChange={handleChange}
                    components={(onlyArrow && type === DROPDOWN_TYPE.INPUT) ? defaultComponents : (type === DROPDOWN_TYPE.INPUT) ? showSelectedTooltip ? { Placeholder, DropdownIndicator, SingleValue:TooltipOption } :{ Placeholder, DropdownIndicator, Option } : { Placeholder, SingleValue, DropdownIndicator, Option }}
                    isSearchable={!(type === DROPDOWN_TYPE.BUTTON)}
                    isDisabled={isDisabled !== undefined ? isDisabled : disabled}
                />
                :
                <AsyncSelect
                    ref={selectRef}
                    classNamePrefix={"scenario-label " + (props.class || "")}
                    // styles={type === DROPDOWN_TYPE.INPUT ? customInputStyles : type === DROPDOWN_TYPE.BUTTON ? customButtonStyles : customStyles}
                    styles={type === DROPDOWN_TYPE.INPUT ? customStyles : type === DROPDOWN_TYPE.BUTTON ? customStyles : customStyles}
                    menuPlacement={menuPlcacementDirection}
                    maxMenuHeight={convertPxToViewport(190)}
                    // menuIsOpen={props.report === ALL_REPORTS.HEATMAP ? undefined : props.dimensionDropdownOpen}
                    {...props}
                    onChange={handleChange}
                    components={(type === DROPDOWN_TYPE.INPUT) ? {DropdownIndicator, Placeholder } : { DropdownIndicator, Placeholder, SingleValue }}
                    isSearchable={!(type === DROPDOWN_TYPE.BUTTON)}
                    isDisabled={isDisabled !== undefined ? isDisabled : disabled}
                />
            }

        </div>
    );
};

export default forwardRef (Dropdown);