import { Skeleton } from "@mui/material";
import { convertPxToViewport } from "../../class/formatting";

const Tag = ({title, value, info, icon:Icon, color, hexColor, bgColor})=>{

    return (
        <div>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <div style={{display:"flex", flexDirection:"column",  rowGap:convertPxToViewport(8), fontWeight:'600', color:hexColor}}>
            <div style={{fontSize:"0.833vw", color:"#4b5563"}}>{title}</div>
            {value ? <div className="tag-content-value">{value}</div> : <Skeleton variant="text" sx={{ fontSize: '2rem',bgcolor: bgColor }} width={125} height={25} animation="wave"/>}
          </div>
        </div>
        <div style={{ display:"flex", alignItems:"center", gap:"0.417vw"}}>
          <div style={{alignSelf:"center"}}>
              {Icon && <Icon style={{color:hexColor, width:"0.833vw"}}/>}
          </div>
          <div style={{color:"grey", fontSize:"0.78125vw", minHeight:info?"1.042vw":"0vw",}}>{info}</div>
        </div>
      </div>
    )
};
export default Tag;