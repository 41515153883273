import React from "react";
import { BUTTON_TYPE, BUTTON_VARIANT, SIZES } from "../class/constants"
import SidePanelNew from "../components/sidePanelNew/SidePanelNew";
import Button from "../newComponents/Button"
import {useState,} from "react"
import { ChainlitAPI } from "@chainlit/react-client";
import ChatBotWrapper from "../components/chatBot/ChatBotWrapper";
import {ReactComponent as PofitAssistImg} from "../styles/images/chatbot/profit_assist.svg"
import ProfitAssistButton from "../components/chatBot/ProfitAssistButton";
import Backdrop from "../components/sidePanelNew/Backdrop";
 const CHAINLIT_SERVER = process.env.REACT_APP_CHATBOT_APP_BASE_URL + "/chainlit";

const ProfitAssist = React.memo(({
    userSettings,
    allowedMenuLinks
}) =>{
    const [openAssistant, setOpenAssistant] = useState(false);

    const renderBody = () => {
        return(
            <ChatBotWrapper userSettings={userSettings} newBot allowedMenuLinks={allowedMenuLinks}/>
        )
    }

    const handleBackDropClick  = () => {
        setOpenAssistant(false)
    };
    const style = {
        color: "#FFF",
        textAlign: "center",
        leadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Lato",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "110%",
    };

    return (
        <div className="profit_assist_side_panel_container">
            {/* <Button 
                label={<PofitAssistImg/>}
                variant={BUTTON_VARIANT.TERTIARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                leftIcon={<span style={{style}}>ASK</span>}
                onBtnClick={() => setOpenAssistant(!openAssistant)}
            /> */}
            <ProfitAssistButton onClick={()=>{setOpenAssistant(!openAssistant)}}/>
            <SidePanelNew
                body={renderBody()}
                show={openAssistant}
                close={() => setOpenAssistant(false)}
                backdropClickHandler={handleBackDropClick}
                variant="shrink"
                title={<div><PofitAssistImg className="profit_assist_panel_title"/></div>}
                // subtitle={<PofitAssistImg/>}
                buttons={[]}
                largeDisplay
            />
            {openAssistant && <Backdrop close={handleBackDropClick} />}
        </div>
    )
})

export  {ProfitAssist};