import React, {Suspense, useEffect, useRef, useState} from 'react';
import {useAuthenticated} from "../CustomHooks";
import {ReportHeader} from "../ReportHeader";
import BridgeConfigurationsManager from "../../sections/system/BridgeConfigurationsManager";
import {Loader} from "../../form/elements";
import {ALL_WIDGETS, BUTTON_TYPE, BUTTON_VARIANT, DIALOG_SIZE, PERIOD_ONWARD, SIZES} from "../../class/constants";
import {renderBreadcrumbsAndDescription, renderTitle} from "../functions/componentFunctions";
import {logout} from "../../class/common";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useFeatureFlagEnabled } from 'posthog-js/react';

/**
 * @author Ralph Bejjani
 * @description This component holds the  header of the configure bridge report and the configure bridge report component named BridgeConfigurationsManager where the header contains title
 * @param {*} props
 * @returns Report header and configure bridge report
 */
const ConfigureBridgeReportWrapper = (props) => {
    const userAllowedMenuLinks = props.userAllowedMenuLinks;
    const userSettings = props.userSettings;
    const isAuthenticated = useAuthenticated(userAllowedMenuLinks);
    const [headerElements, setHeaderElements] = useState([]);
    const [reportTitle, setReportTitle] = useState(ALL_WIDGETS.TITLES.SYSTEM.BRIDGE_CONFIGURATIONS_MANAGER);
    const useNewComponents = useFeatureFlagEnabled('use_new_components');


    /**
     * @function useEffect()
     * on screen initialization if isAuthenticated is false user gets logged out
     * isAuthenticated is a boolean read from a custom hook named useAuthenticated
     **/
    useEffect(() => {
        if (!isAuthenticated) {
            logout();
        }
    }, [isAuthenticated]);

    /**
     * @function useEffect()
     * On mount set header elements which is the title only in our case
     */
    useEffect(() => {
        let headerElements = useNewComponents ? getNewHeaderElements() : getHeaderElements();
        setHeaderElements(headerElements);
    }, [useNewComponents]);

    const getHeaderElements = () => {
        let headerElements = [];
        headerElements.push(
            <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="first_header_row gap_between_buttons">
                {renderTitle(reportTitle)}
            </div>
        );

        return headerElements;
    };

    const renderHeaderFirstRow = () => {
        return renderBreadcrumbsAndDescription(() => props?.goToLandingPage(), undefined, props.reportDescription)
    }

    const getNewHeaderElements = () => {
        let headerElements = [];
        headerElements.push(
            <div style={{ display: "flex", width: "100%", alignItems: "center" }} className="new_header_menu first_header_row gap_between_buttons">
                {renderHeaderFirstRow()}
            </div>
        );

        return headerElements;
    };

    return (
        <>
            <Suspense fallback={<p>Loading...</p>}>
                <div
                    id={"main-component-container"}
                    className={"main-component-container"}
                    style={{ "--banner-height": 0 }}
                >
                    <div className={"header-banner-div-hidden"} />
                    <div className="main-report-header">
                        <ReportHeader headerElements={headerElements} />
                    </div>
                    <div className="main_report">
                        <div id="main_report_container" className="main_report_container" style={{border: "none"}}>
                            <BridgeConfigurationsManager  />
                        </div>
                    </div>
                </div>
            </Suspense>
        </>
    );
};

export {ConfigureBridgeReportWrapper};
