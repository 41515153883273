// CustomChatBot.js
import React, { useEffect } from 'react';
import { FETCH_METHOD, fetchAPI, FETCHAPI_PARAMS } from "../../class/networkUtils";
import {Span} from "../../Tracing/Span";

function CustomChatBot(props) {
    // Define styles as constants
    const chatBotButtonStyles = {
        size: "60px",
        bgcolor: "#00bfa5",
        color: "#ffffff",
        bgcolorHover: "#008e76",
        borderColor: "transparent",
        borderWidth: "0px",
        borderStyle: "none",
        borderRadius: "50%",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    };
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const chainlitUrl = process.env.REACT_APP_CHATBOT_APP_BASE_URL;

    useEffect(() => {
        let cleanupFunc;

        const initializeChatBot = async () => {
            const token = await getAccessToken(baseUrl, chainlitUrl);
            cleanupFunc = loadCopilotWidget(chainlitUrl, token);
        };

        initializeChatBot();

        // Add the event listener for disabling 'p' hotkey
        const keydownListener = function(event) {
            if (event.key === 'p' || event.key === 'P') {
                // Stop the event from reaching other listeners (disables the hotkey)
                event.stopImmediatePropagation();
            }
        };

        document.addEventListener('keydown', keydownListener, true); // Use capture phase to intercept the event early

        return () => {
            if (cleanupFunc) {
                cleanupFunc();
            }
            // Clean up the event listener when component unmounts
            document.removeEventListener('keydown', keydownListener, true);
        };
    }, []);

    // Function to check and hide the watermark
    const checkAndHideWatermark = () => {
        const widget = document.querySelector('#chainlit-copilot');
        const shadowRoot = widget && widget.shadowRoot;
        const watermarkDiv = shadowRoot && shadowRoot.querySelector('div.watermark');
        const tooltips = shadowRoot && shadowRoot.querySelectorAll("div .MuiTooltip-popper div");

        if (watermarkDiv) {
            watermarkDiv.style.display = 'none';
        }
        if (tooltips?.length > 0) {
            tooltips.forEach((tooltip) => {
                tooltip.textContent = tooltip.textContent.replace("(P)","");
            });
        }
        // Continue checking every 100ms
        setTimeout(checkAndHideWatermark, 100);
    };

    // Function to get sessionId
    const getSessionId = () => {
        return new Promise((resolve, reject) => {
            let query = { action: "getSessionId" };
            let onThenCallback = (data) => {
                if (data?.sessionId) {
                    resolve(data.sessionId); // Resolve the promise with the sessionId
                } else {
                    reject(new Error('Failed to get sessionId'));
                }
            };
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "getSessionId",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: true,
                [FETCHAPI_PARAMS.path]: "/get-session-id",
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            };
            fetchAPI(fetchOptions);
        });
    };

    // Function to get chatbotVectorsInfo
    const getCloudRunToken = () => {
        return new Promise((resolve, reject) => {
            let query = { action: "getChatbotAccessToken" };
            let onThenCallback = (data) => {
                if (data?.accessToken) {
                    resolve(data.accessToken); // Resolve the promise
                } else {
                    reject(new Error('Failed to get chatbotAccessToken'));
                }
            };
            let fetchOptions = {
                [FETCHAPI_PARAMS.funcName]: "getChatbotAccessToken",
                [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
                [FETCHAPI_PARAMS.showLoader]: true,
                [FETCHAPI_PARAMS.path]: "/get-chat-bot-access-token",
                [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
                [FETCHAPI_PARAMS.query]: query,
                [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            };
            fetchAPI(fetchOptions);
        });
    };

    // Function to get access token from the /custom-auth endpoint
    const getAccessToken = async (baseUrl, chainlitUrl) => {
        try {
            // Fetch the access token from the new endpoint
            const cloudRunToken = await getCloudRunToken();
            // Now use the access token to authenticate the existing API call
            const sessionId = await getSessionId();
            const requestBody = {
                sessionId: sessionId,
                url: `${baseUrl}/chat-bot-get-vector`,
                authority: baseUrl,
                origin: window.location.origin,
                firstName: props.userSettings.user.first_name,
                userId: props.userSettings.user.userId,
                clientId: props.userSettings.clientId,
                clientName: props.userSettings.clientName,
            };
            let span = new Span( "POST"+ "/custom-auth");
            // Make the POST request to your custom-auth endpoint
            const authResponse = await fetch(`${chainlitUrl}/custom-auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${cloudRunToken}`, // Use the access token here
                    'X-Trace-Id': span.getTraceId(),
                    'X-Span-Id': span.getSpanId(),
                },
                credentials: 'include',
                body: JSON.stringify(requestBody),
            });

            const data = await authResponse.json();
            if (authResponse.ok) {
                return data.token;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };

    // Function to inject custom styles into the shadow DOM
    const injectCustomStyles = () => {
        const widget = document.querySelector('#chainlit-copilot');
        const shadowRoot = widget && widget.shadowRoot;
        if (document.querySelectorAll('#chainlit-copilot')?.length > 1) {
            document.body.removeChild(document.querySelectorAll('#chainlit-copilot')[1])
        }
        if (shadowRoot) {
            // Check if the custom style tag already exists
            let styleTag = shadowRoot.querySelector('style#custom-styles');
            if (!styleTag) {
                styleTag = document.createElement('style');
                styleTag.id = 'custom-styles';
                shadowRoot.appendChild(styleTag);
            }

            styleTag.textContent = `
                /* Input area styling */
                #copilot-chat-input {
                    font-size: small !important;
                    font-family: Lato !important;
                    padding-bottom: 0px !important;
                }

                /* Icons inside buttons */
                button svg:not([data-testid="CloseIcon"]) {
                    width: 20px !important;
                    height: 20px !important;
                }

                .watermark {
                    display: none !important
                }

                /* Chat container messages */
                .markdown-body {
                    font-size: small !important;
                }

                /* Tables in messages */
                table, th, td {
                    font-size: small !important;
                }

                /* Tooltip styling */
                .MuiTooltip-popper div {
                    font-size: 10px !important;
                }

                /* Remove (P) from tooltips */
                .MuiTooltip-popper div::after {
                    content: '' !important;
                }

                /* Hide the first button */
                .MuiIconButton-root.MuiIconButton-sizeMedium.css-1yxmbwk:first-of-type {
                    display: none !important;
                }

                /* Style for primary buttons */
                .MuiButton-textPrimary {
                    background: #ffffff !important;
                    border: none !important;
                    color: #08155e !important;
                    outline: 1px solid #e4e4e4 !important;
                    font-size: 0.72917vw !important;
                    line-height: 24px !important;
                    padding: 0px 0.83333vw !important;
                }

                /* Style for outlined primary buttons */
                .MuiButton-outlinedPrimary {
                    background: #08155e !important;
                    border: none !important;
                    color: #ffffff !important;
                    font-size: 0.72917vw !important;
                    line-height: 24px !important;
                    padding: 0px 0.83333vw !important;
                }

                /* Dialog styles */
                .MuiDialog-paper {
                    width: 34vw !important;
                    height: 21.27vh !important;
                    background-color: white !important;
                }

                /* Style for dialog buttons */
                .MuiDialog-paper button {
                    font-size: 0.72917vw !important;
                    line-height: 24px !important;
                    padding: 0px 0.83333vw !important;
                }
                .MuiDialog-paper button:first-of-type {
                    background: #ffffff !important;
                    border: none !important;
                    color: #08155e !important;
                    outline: 1px solid #e4e4e4 !important;
                }
                .MuiDialog-paper button:last-of-type {
                    background: #08155e !important;
                    border: none !important;
                    color: #ffffff !important;
                }

                /* Recorder SVG color */
                .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-1itwfbm {
                    color: rgb(0, 191, 165) !important;
                }

                /* Edit text input style */
                #edit-chat-input {
                    font-size: small !important;
                }
            `;
           
        } else {
            // Retry after a short delay if shadowRoot is not yet available
            setTimeout(injectCustomStyles, 100);
        }
    };

    const loadCopilotWidget = (chainlitUrl, token) => {
        const script = document.createElement('script');
        script.src = `${chainlitUrl}/chainlit/copilot/index.js`;
        script.async = true;

        script.onload = () => {
            if (token) {
                window.mountChainlitWidget({
                    chainlitServer: `${chainlitUrl}/chainlit`,
                    accessToken: token,
                    theme: "light",
                    fontFamily: "Arial, sans-serif",
                    button: {
                        imageUrl: "https://profitisle.com/wp-content/uploads/2024/08/cropped-favicon-192x192.png",
                        style: chatBotButtonStyles,
                    },
                });

                injectCustomStyles();

                checkAndHideWatermark();
            }
        };

        document.body.appendChild(script);

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    };

    return null; // This component doesn't render anything visible
}

export default CustomChatBot;
