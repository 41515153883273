import React, { createRef } from 'react';
import shortid from 'shortid';
import { linearizeHierarchy } from '../../class/array';
import { formatAdvancedFilter, formatBasicFilter, getOutputScreenName, getRandomColor, handleCurrency, wrapFilterWithParenthesis } from '../../class/common';
import {
    ALL_REPORTS,
    ALL_WIDGETS,
    API_URL,
    BP_QUADRANT_TIER,
    column_suffixes_values,
    comparison_suffixes,
    FILTER,
    FormatTypes,
    HEADER_ELEMENT,
    PERIOD,
    PREVIOUS_ADJACENT,
    PSS,
    PS_MAPPING,
    time_range_values,
    TOP_QUADRANT,
    TOP_QUADRANT_TIER,
    VECTOR_ANALYSIS,
    YEAR_OVER_YEAR,
    MANAGE_REPORT,
    FY_VALUES,
    COLORS_PALETTE,
    PSL_RETURN_NAMES,
    QUADRANTS,
    CONFIGURE_TOGGLE_TABS_NOT_LIST,
    FIRST_ATTRIBUTE,
    SECOND_ATTRIBUTE,
    PSL,
    STACK_CONF,
    SCENARIO_STATUS,
    BUTTON_VARIANT,
    SIZES,
    BUTTON_TYPE,
    BUTTON_DROPDOWN_VARIANT,
    DIALOG_SIZE,
    ROLLING_SEGMENTS,
    SEGMENTS_TIERS,
    SEGMENTS_TITLES,
    SEGMENTS,
    MENU_ITEM,
    ENTITY_TYPES,
    costtype,
    AVG_MED_TOGGLE_TABS,
    VECTOR_ATTRIBUTE,
    MOM_CONFIGURE_TOGGLE_TABS,
    ENTITY_STACKS_OBJECT,
    SUFFIX,
    CONFIGURE_TOGGLE_TABS,
    NAME_TITLE,
    NAME_FIELD,
    NUMBER_FIELD,
    NUMBER_TITLE,
    PROFIT_TIER,
    USD_SIGN,
} from '../../class/constants';
import { extractFromFullQuarter, generatePeriods, getFullQuarterFromStartEndQuarters, getGeneratedQuarterRange, getLastBuiltPeriodForSegments, getMonthName, getPeriodFromDate, getPeriodQuarter, getQuarterFromDate, isValidDate, monthDiff } from '../../class/date';
import { formatValString } from "../../class/format";
import { convertPxToViewport } from '../../class/formatting';
import { getPeriodNumber, getPeriodYear, readCookie, replaceSpecialCharacters } from '../../class/jqueries';
import { FETCHAPI_PARAMS, FETCH_METHOD, fetchAPI } from "../../class/networkUtils";
import { formaliseName } from '../../class/string';
import {
    capitalizeFirstLetter,
    copyObjectValues,
    findOptionByKey,
    getSectionExists,
    getTranslationFile,
    concatenateStrings,
    findOptionByKeyValue
} from '../../class/utils';
import { tryParse } from '../../class/utils.js';
import LineChart from '../../components/charts/LineChart';
import OptionsList from '../../components/OptionsList';
import { getPrintButton, getProfitIsleHeader } from '../../components/Print';
import ToggleOptionsList from '../../components/ToggleOptionsList';
import SessionTimeout from '../../SessionTimeout';
import ProfitStackTabulator from '../../tables/deepDive/ProfitStackTabulator';
import ExcelDetailsTable from "../../tables/ExcelDetailsTable";
import StackChartConfiguration from './StackChartConfiguration';
import cookie from 'react-cookies';

import { connect } from "react-redux";
import { updateProfitstackHierarchyByStack } from '../../actions/scenariosActions';
import { getItemFromStore } from '../../class/reduxStoreUtils';
import Button from '../../newComponents/Button';
import ButtonDropdown from '../../newComponents/ButtonDropdown';
import Modal from '../../newComponents/Modal';
import { Segment } from '../../components/Segment';
import { Component } from 'react';
import { getValidPeriods } from '../../templateLayout/functions/periodFunctions';
// import { updateUserSettings } from '../../actions';
const lang = getTranslationFile();
const PROFIT_STACK = "profit_stack";
const PSL_LINES = "Profit Stack Lines";
const AMOUNT = "amount";
const AMOUNT_TITLE = "Amount";
const _uniqueKey = "uniqueKey";
const _vectorValue = "vectorValue";

const Inflector = require('inflected');

class ProfitStacks extends Component {
    constructor(props) {
        super(props);
        let isSecondDim = props.history?.location?.state && [ALL_REPORTS.HEATMAP, ALL_REPORTS.CONTOUR_MAP].includes(props.history.location.state.profitFormat);
        let titleFromList = isSecondDim ? props.history?.location?.state.vectorName : props.history?.location?.state?.vectorOptions?.find((e) => e.value === props.history.location.state.mainVector)?.label
        let titleFromDrill = props.history?.location?.state?.vectorOptions && props?.history?.location?.state?.profitStackItems && props.history.location.state.vectorOptions.find((e) => e.value === props.history.location.state.profitStackItems[0].tier)?.label
        let redirectFromStacks = props.history?.location?.state?.isRedirectionFromStacks || false;
        let report = props.history?.location?.state?.isRedirectionFromBubble? props.history.location.state.bubbleReportTitle
            : this.props.isMoM ? ALL_WIDGETS.FIELDS.MOM_STACKS : this.props.isYTD ? ALL_WIDGETS.FIELDS.YTD_STACKS : this.props.isYOY ? ALL_WIDGETS.FIELDS.YOY_STACKS : (this.props.isRange && redirectFromStacks && props.history.location.state.drilling) ? ALL_WIDGETS.FIELDS.ENTITY_STACKS + ` - ${titleFromDrill}` : (this.props.isRange && this.props.isEntity && redirectFromStacks) ? ALL_WIDGETS.FIELDS.ENTITY_STACKS + ` - ${titleFromList}` : (this.props.isRange && this.props.isTotal && !redirectFromStacks) ? ALL_WIDGETS.FIELDS.TOTAL_STACKS : (this.props.isRange && this.props.isEntity) ? ALL_WIDGETS.FIELDS.ENTITY_STACKS  : ALL_WIDGETS.FIELDS.PROFIT_STACKS;
        let periodRange = this.props.isYTD ? "" : this.props.isRange ? HEADER_ELEMENT.SELECTION_RANGE : HEADER_ELEMENT.PERIOD_RANGE;
        let periodDropdown = this.props.isRange ? "" : HEADER_ELEMENT.PERIOD
        let backBtn = props.history?.location?.state && props.isRange && props.history.location.state.origin_name ? HEADER_ELEMENT.NAVIGATION_BACK : "";
        this.initialState = {
            headerElements: [HEADER_ELEMENT.PROFIT_STACK, periodRange, periodDropdown, HEADER_ELEMENT.GO, backBtn],
            quadrantFilterDisabled: props.history?.location?.state?.isRedirectionFromBubble || (props.isRange && props.history?.location?.state?.isRedirectionFromStacks) ? false : true,
            defaultScreen: true,
            reportTitle: formaliseName(report),
            hasDefaultHeaderValues: true,
            checkedPsLines: [],
            checkedChartPsLines: [],
            checkedYears: [],
            isChartExpanded: false,
            isChartFullScreen: false,
            isEntity: this.props.isEntity? this.props.isEntity : false,
            chartAxes: {
                x: {field: [PERIOD.PERIOD_NAME], title: ""},
                y: {title: ""}  //titles required to be empty
            },
            showChartConfigureDialog: false,
            chartAmountType: AMOUNT,
            checkedEntities: props.history?.location?.state && props.isRange && props.isEntity ? props.history?.location.state.profitStackItems : props.isRange && props.isEntity? props?.history?.location?.state?.profitStackItems : [],
            customStartDate: props.history?.location?.state && props.isRange ? props.history?.location.state.startDate : props?.periodsStatusState?.customStartDate,
            customEndDate: props.history?.location?.state && props.isRange ? props.history?.location.state.endDate : props?.periodsStatusState?.customEndDate,
            datasetOptions: props.history?.location?.state ? props.history?.location.state.datasetOptions : props?.datasetState?.datasetOptions,
            drilledPath: props.history?.location?.state && props.isRange ? props.history.location.state.drilledPath : undefined,
            drillFilter: props.history?.location?.state && props.isRange ? props.history.location.state.drillFilter : undefined,
            drilling: props.history?.location?.state && props.isRange ? props.history.location.state.drilling : undefined,
            FY: props.history?.location?.state && props.isRange ? props.history?.location.state.FY : undefined,
            filterFinal: props.history?.location?.state && props.isRange ? props.history?.location.state.outsideFilter : undefined,
            toBeAddedFilter: props.history?.location?.state && props.isRange && props.history?.location.state.toBeAddedFilter && props.history?.location.state.toBeAddedFilter.length? props.history?.location.state.toBeAddedFilter: undefined,
            isSecondDimension: isSecondDim,
            mainVector: this.props.history?.location?.state ? this.props.history?.location.state.mainVector : props?.history?.location?.state?.mainVector,
            nextProfitStackViewId: props.history?.location?.state && (props.isRange || props.isFromDashboards)? props.history.location.state.profitStackViewId : props?.PSViewsState?.nextProfitStackViewId || 0,
            originPath: props.history?.location?.state && props.isRange ? props.history?.location.state.origin : undefined,
            originPathName: props?.history?.location?.state?.origin_name,
            originalprofitFormat: props.history?.location?.state && props.isRange ? props.history?.location.state.profitFormat : props?.history?.location?.state?.profitFormat,
            originalFY: props.history?.location?.state && props.isRange ? props.history?.location.state.FY : undefined,
            originalDimensionListFilter: props.history?.location?.state && props.isRange ? props.history?.location.state.outsideFilter : undefined,// back
            originalMainFilter: props.history?.location?.state && props.isRange ? props.history?.location.state.mainFilter : undefined,// back
            periods: props.history?.location?.state && props.isRange ? props.history?.location.state.periods : props?.clientPeriodsState?.periods,
            profitFormat: props.history?.location?.state && props.isRange ? props.history?.location.state.profitFormat : props?.history?.location?.state?.profitFormat || report,
            profitStackViewId: props.history?.location?.state && (props.isRange || props.isFromDashboards) ? props.history?.location?.state.profitStackViewId : props?.PSViewsState?.nextProfitStackViewId || 0,
            ps_default_line: props.history?.location?.state && props.isRange ? props.history?.location.state.ps_default_line || false : false,
            selectedEntities: props.history?.location?.state && props.isRange && props.isEntity ? props.history.location.state.profitStackItems : undefined,
            scenario: props.history?.location?.state && props.history?.location.state.scenarios ? props.history?.location.state.scenarios[0] : props?.scenarioState?.scenarios,
            originalScenarios: props.history?.location?.state && props.history?.location.state.originalScenarios ? props.history?.location.state.originalScenarios : props?.history?.location?.state?.originalScenarios,
            secondDimensionVectors: props.history?.location?.state && props.isRange ? props.history?.location.state.secondDimVectors : undefined,
            secondDimensionDataset: this.props.history?.location?.state ? this.props.history?.location.state.dataset : undefined,
            isFromList: props?.location?.state?.isRedirectionFromStacks || props?.history?.location?.state?.isRedirectionFromStacks ? true : false,
            isFromBubble: props?.location?.state?.isRedirectionFromBubble || props?.history?.location?.state?.isRedirectionFromBubble ? true : false,            drillFilter: props.history?.location?.state && props.isRange ? props.history.location.state.drillFilter : undefined,
            originalDrillFilter: props.history?.location?.state && props.isRange ? props.history.location.state.originalDrillFilter : undefined,

            listTitle: titleFromList,
            drillTitle: titleFromDrill,
            useFilterCookies: props?.history?.location?.state?.useFilterCookies,
            costClassification: []
        }
        //concat the parent state with ProfitStacks' initial state and store resulting object in this.state
        this.state = Object.assign({}, this.state, this.initialState);

        this.fetchProfitStackData = this.fetchProfitStackData.bind(this);
        this.generatePeriods = this.generatePeriods.bind(this);
        this.computePeriods = this.computePeriods.bind(this);
        this.onChangeCheckedPsLine = this.onChangeCheckedPsLine.bind(this);
        this.onChangeCheckedYears = this.onChangeCheckedYears.bind(this);
        this.getYearOptions = this.getYearOptions.bind(this);
        this.getExportOpts = this.getExportOpts.bind(this);
        this.getDownloadFormatterParams = this.getDownloadFormatterParams.bind(this);
        this.stackChartConfRef = React.createRef();
        this.isMainReport = { isProfitStacks: true, hideHeader: this.props.hideHeader }
        // this.isUserAuthenticated = false;
        this.stackChartConfig = React.createRef();
        this.lastAppliedStacksConf = undefined;
        this.changedConfiguration = [];
        this.lastRequestSentTime = undefined;
        // this.getPeriodsObject = getPeriodsObject.bind(this);
        this.configDropdown = React.createRef();

        // This will be used to abort request from other stack screen on redirection
        this.abortControllerRef = createRef();
        this.abortChartControllerRef = createRef();
        this.initializeAbortController();

    }

    /**
     * Creates a new AbortController instance to manage abort signals
     * Assigns the created controller to the abortControllerRef for future use
     */
    initializeAbortController = () => {
        this.controller = new AbortController();
        this.abortControllerRef.current = this.controller;
    }

    /**
     * Creates a new AbortController instance to manage abort signals for the chart
     * Assigns the created controller to the abortChartControllerRef for future use.
     * This is needed when we want to abort the chart and the table request. 
     * Without this, the controller signal will be overidden and only 1 request will be aborted.
     */
    initializeAbortChartController = () => {
        this.chartController = new AbortController();
        this.abortChartControllerRef.current = this.chartController;
    }

    getPeriodsObject =()=> {
        const _this = this;
        let periods = [];
        let periods_yoy = [];
        let periods_pa = [];
        let quarter = "";
        let quarterPre = "";
        let segmentPeriod = "";
        let months = FY_VALUES.M3;
        let startDate = getValidPeriods(this.props?.periodsStatusState, this.props?.clientPeriodsState)?.startDate;
        let endDate = getValidPeriods(this.props?.periodsStatusState, this.props?.clientPeriodsState)?.endDate;
      
        if (isValidDate(startDate) && isValidDate(endDate) && startDate  && endDate) {
            let periodsCount = monthDiff(startDate, endDate) + 1;
            periods = generatePeriods(startDate, periodsCount);
            periods_yoy =  generatePeriods(startDate, periodsCount,false);
            periods_pa = generatePeriods(startDate, periodsCount,true);
        }
    
        let periodsStateOrProps = this.props.isDashBoards? _this.props?.periods : _this.props?.clientPeriodsState?.periods;
        if (!!periodsStateOrProps && isValidDate(startDate) && isValidDate(endDate)) {
            let firstQuarter = getQuarterFromDate(startDate);
            let endQuarter = getQuarterFromDate(endDate);
    
            let firstPreQuarter = getPeriodQuarter(periods_pa[0]);
            let endPreQuarter = getPeriodQuarter(periods_pa[periods_pa.length-1]);
    
            let generatedRange = getGeneratedQuarterRange(this.props.datasetState.datasetOptions, firstQuarter, endQuarter);
            let generatedRangePre = getGeneratedQuarterRange(this.props.datasetState.datasetOptions, firstPreQuarter, endPreQuarter);
    
            let fullQuarter = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRange[0], generatedRange[1]));
            let fullQuarterPre = extractFromFullQuarter(getFullQuarterFromStartEndQuarters(generatedRangePre[0], generatedRangePre[1]));
    
            quarter = fullQuarter.quarter;
            quarterPre = fullQuarterPre.quarter;
            months = fullQuarter.months;
    
            let lastSelectedPeriod = periods[periods.length - 1];
            // let headerRef = this.props.isDashBoards? _this.props.headerRef : this.headerRef;
            let builtPeriods = _this.props?.periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label);
            segmentPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
        }
        return {periods: periods, segmentPeriod: segmentPeriod, quarter: quarter, months: months, periods_yoy: periods_yoy, periods_pa: periods_pa, preQuarter: quarterPre};
    }

    onBackNavigation() {
        let _this = this;
        var pathName = this.props.history.location.state.origin || "";
        pathName = pathName.replace(window.location.origin, "");
        pathName = pathName[0] === "/" ? pathName.substr(1) : pathName;
        let scenarioState = this.props.history.location.state.scenarioState;
        let scenarios = scenarioState?.scenarioList;
        let scenario = scenarios && this.props.history.location.state?.scenarios && scenarios.filter(e=>e.value === this.props.history.location.state.scenarios[0])?.length > 0 ? scenarios.filter(e=>e.value === this.props.history.location.state.scenarios[0])[0] : undefined;
        if (scenario) {
            scenarioState.scenario = scenario.value;
            scenarioState.scenarios = [scenario.value];
            scenarioState.scenarioObjects = [scenario];
            scenarioState.nextScenarios = [scenario.value];
            scenarioState.nextScenariosObjects = [scenario];
            scenarioState.scenarioStatus = scenario.scenario_status;
        }
        let basicFilter = _this.props?.location?.state?.mainFilterFinalBasic === "[]"?[]: _this.props?.location?.state?.mainFilterFinalBasic?.filter(f => JSON.parse(this.props.history.location.state.mainFilter).map(m => m.vector).includes(f.vector));
        // saveCookie(CURRENT_FILTER_COOKIE, typeof this.state.originalMainFilter === "string" ? JSON.parse(this.state.originalMainFilter) : this.state.originalMainFilter)
        this.props.history.push({
            pathname: "/" + pathName,
            search: "?",
            hash: "",
            state: {
                scenarioState: scenarioState,
                outsideFilter: JSON.stringify({ filter: tryParse(this.props.history.location.state.mainFilter) }),
                mainFilter: this.props.history.location.state.mainFilter,
                filter: this.state.originalDimensionListFilter,
                mainFilterFinalBasic: basicFilter,
                FY: this.state.originalFY,
                profitFormat: this.state.originalprofitFormat,
                scenario: this.state?.originalScenarios && this.state?.originalScenarios[0],
                scenarios: this.state?.originalScenarios,
                ps_default_line: this.state?.ps_default_line,
                drilledPath: this.state?.drilledPath,
                drillFilter: this.props?.history?.location?.state?.drillFilter,
                drilling: this.props?.history?.location?.state?.drilling,
                drillRows: this.props?.history?.location?.state?.drillRows,
                vectors: this.state?.secondDimensionVectors || this.props?.history?.location?.state?.secondDimVectors || this.props.history?.location?.state?.vector,
                dataset: this.state?.secondDimensionDataset,
                headerOptionChanged: false,
                isRedirectionFromStacks: true,
                cardsData: this.props?.history?.location?.state?.cardsData,
                drillProfile: this.props?.history?.location?.state?.drillProfile,
                drillTier: this.state?.selectedEntities && !this.state?.isSecondDimension ? this.state?.selectedEntities[0]?.tier : "",
                submitDrill: this.props.history?.location?.state?.submitDrill,
                toBeAddedFilter: undefined,
                manageColumnsProfile:this.props?.history?.location?.state?.manageColumnsProfile,
                selectedBoundaryName: this.props?.history?.location?.state?.selectedBoundaryName,
                useFilterCookies: this.props?.history?.location?.state?.useFilterCookies,
            }
        });
    }

    userSectionsCallback() {
        let temp = this.state.checkedEntities ? this.state.checkedEntities : []; 
        if(temp.length === 0 && this.props.isRange && this.props.isEntity
            || this.props.isYTD && !getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_YTD) 
            || this.props.isYOY && !getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_YOY)
            || (this.props.isRange && this.props.isTotal  && !getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.TOTAL_STACKS_URL) && !getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.MANAGE_STACKS) && !getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.VIEW_COMPANY_STACKS) && !getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_USER_STACKS) && !getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_COMPANY_STACKS))
            || this.props.isRange && this.props.isEntity && !getSectionExists(this.props.userAllowedMenuLinks, MENU_ITEM.FIELDS.MANAGE_STACKS) && !getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.VIEW_STACK)
            || this.props.isMoM && !getSectionExists(this.props.userAllowedMenuLinks, ALL_WIDGETS.FIELDS.STACKS_MOM)) {
            this.logout();
        } else {
            // this.isUserAuthenticated = true;
        }
        // super.userSectionsCallback();
    }

    computePeriods(selectedPeriodNumber, selectedPeriodYear,selectedPeriod, startingIndex){
        let _this = this;
        if (_this.props.isRange || _this.props.isMoM) {
            let periodsCount = monthDiff(this.props?.periodsStatusState.customStartDate, this.props?.periodsStatusState.customEndDate) + 1;
            let periods = generatePeriods(this.props?.periodsStatusState.customStartDate, periodsCount);
            return periods;
        }
        startingIndex = startingIndex !== undefined ? startingIndex : 1;
        var generatedPeriods = [];
        for (let i = Number(startingIndex); i < selectedPeriodNumber; i++) {
            let prependZero = i < 10 ? "P0" : "P";
            generatedPeriods.push(selectedPeriodYear+prependZero+i);
        }
        generatedPeriods.push(selectedPeriod);
        return generatedPeriods;
    }

    go(selectedStack){
        let _this = this;
        // to get the valid built periods before sending requests
        _this.setState({
            customStartDate: getValidPeriods(this.props?.periodsStatusState, this.props?.clientPeriodsState).startDate,
            customEndDate: getValidPeriods(this.props?.periodsStatusState, this.props?.clientPeriodsState).endDate
        }, () => {
            if (_this.props.isRange) {
                if(_this.pageComponent && _this.pageComponent.state.showConfigureDialog) {
                    _this.pageComponent.setState({showConfigureDialog: false})
                }
                _this.fetchProfitStackByRangeData(true,selectedStack); // get data for table
                _this.fetchProfitStackByRangeChartData(true,selectedStack); // get data for chart
            }else if(_this.props.isMoM) {
                _this.getProfitStackByConfigurationData();
            } else {
                _this.fetchProfitStackData();
            }

        })
    }

    fetchCostClassification = () => {
        // Get screen name based on view type
        const getScreenName = () => {
            const { isDashBoards, isRange, isEntity, isYOY, isMoM } = this.props;
            const { output } = lang.observability;

            if (isDashBoards) {
                return output.dashboards.screen_name;
            }
            
            if (isRange) {
                return isEntity ? this.getScreenName() : output.total_stacks.screen_name;
            }
            
            if (isYOY) {
                return output.yoy_stacks.screen_name;
            }
            
            if (isMoM) {
                return output.mom_stacks.screen_name;
            }

            return '';
        };

        // Get request description based on view type
        const getRequestDescription = () => {
            const { isDashBoards, isRange, isEntity, isYOY, isMoM, history } = this.props;
            const { output } = lang.observability;
            const isManageStacks = history?.location?.state?.isManageStacks;

            let description = '';

            if (isManageStacks) {
                description = output.manage_stacks.request_description.total_stacks_costClassification;
            } else if (isRange) {
                description = isEntity 
                    ? output.entity_stacks.requests_description.costClassification 
                    : output.total_stacks.requests_description.costClassification;
            } else if (isYOY) {
                description = output.yoy_stacks.requests_description.costClassification;
            } else if (isMoM) {
                description = output.mom_stacks.requests_description.costClassification;
            }

            return description + (isDashBoards ? output.dashboards.widget : '');
        };

        const screenName = getScreenName();
        const requestDescription = getRequestDescription();

        let query = {
            action: "getStackCostClassification",
            scenario_id: this.props?.scenarioState?.scenario || this.props.scenario || (this.headerRef && this.headerRef.state.scenario),
        }

        const onThenCallback = (data) => {
            let tempState = {};
            tempState.costClassification = data.costClassification;
            tempState.glCosts = data.glCosts;
            tempState.hiddenVectors = data.hiddenVectors;
            tempState.client_costcenter = data.client_costcenter;
            tempState.metricFields = data.metricFields;
            this.setState(tempState);
        }

        let fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getStackCostClassification",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: false,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_STACK,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: screenName,
            [FETCHAPI_PARAMS.requestDescription]: requestDescription,
        }
        fetchAPI(fetchOptions);
    }
    /**
     * gets the valid period from tcustmDate
     * @returns
     */
    // getValidPeriods = () => {
    //     let nextCustomStartDateCookie = cookie.load("nextCustomStartDate");
    //     let nextCustomEndDateCookie = cookie.load("nextCustomEndDate");
    //     let startDate = ![undefined, "undefined"].includes(nextCustomStartDateCookie) ? new Date(nextCustomStartDateCookie.replaceAll("\"","")) : this.props?.periodsStatusState.customStartDate;
    //     let endDate = ![undefined, "undefined"].includes(nextCustomEndDateCookie) ? new Date(nextCustomEndDateCookie.replaceAll("\"","")) : this.props?.periodsStatusState.customEndDate;

    //     // checks if the customDate has a built period or not
    //     if (this.props?.periodsStatusState.customStartDate && this.props?.clientPeriodsState?.periods && this.props?.periodsStatusState?.actuallyBuiltPeriods) {
    //         startDate = this.headerRef.getDateFromPeriod(this.props?.periodsStatusState.customStartDate, this.props?.clientPeriodsState?.periods);
    //         endDate = this.headerRef.getDateFromPeriod(this.props?.periodsStatusState.customEndDate, this.props?.clientPeriodsState?.periods);
    //         startDate = startDate.start_date ? new Date(startDate.start_date) : startDate;
    //         endDate = endDate.end_date ? new Date(endDate.end_date) : endDate;
    //         let isStartPeriodAvailable = this.headerRef.isPeriodBuilt(getPeriodFromDate(startDate));
    //         let isEndPeriodAvailable = this.headerRef.isPeriodBuilt(getPeriodFromDate(endDate));

    //         // if the period is available, no need to put it in a state
    //         if (!isStartPeriodAvailable && !isEndPeriodAvailable) {
    //             startDate = this.headerRef.checkPeriodAvailability(startDate);
    //             endDate = this.headerRef.checkPeriodAvailability(endDate);
    //             startDate = typeof startDate !== 'string' ? startDate : this.props?.clientPeriodsState?.periods.find(f => f.label === startDate) ? new Date(this.props?.clientPeriodsState?.periods.find(f => f.label === startDate).start_date) : this.props?.periodsStatusState.customStartDate; // if startDate is a period -> find its start_date
    //             endDate = typeof endDate !== 'string' ? endDate : this.props?.clientPeriodsState?.periods.find(f => f.label === endDate) ? new Date(this.props?.clientPeriodsState?.periods.find(f => f.label === endDate).end_date) : this.props?.periodsStatusState.customEndDate;
    //         }

    //     }

    //     return { startDate: startDate, endDate: endDate }
    // }

    componentDidUpdate(prevProps, prevState) {
        let isPrevEntity = prevProps.isEntity && this.props.isEntity !== prevProps.isEntity && prevState.selectedEntities?.length > 0;
        let isPrevMoM = prevProps.isMoM && (this.props.isMoM !== prevProps.isMoM);
        let isPrevYoy = prevProps.isYOY && (this.props.isYOY !== prevProps.isYOY);
        let isPrevScreenStacks = isPrevEntity || isPrevMoM || isPrevYoy;
        if(isPrevScreenStacks ){
            this.setState({
                selectedEntities: undefined,
                checkedEntities: undefined,
                nextProfitStackViewId: 0,
                profitStackViewId: 0
            })
        }
        if(prevProps.isYOY !== this.props.isYOY || prevProps.isRange !== this.props.isRange) {
            if(this.state.isChartExpanded) { // when changing stacks screen -> collapse chart if it was expanded 
                this.expandTable();
            }
        }
      
        // when changing between MoM and Total, reset cahrt psl selection
        if(prevProps.isMoM !== this.props.isMoM || prevProps.isRange !== this.props.isRange) {
          this.setState({
            chartSelectedNodes: [],
            selectedNodes: []
          })
        }

        if(this.props.history?.location?.state?.isRedirectionFromBubble !== this.state.isFromBubble) {
          this.setState({
            isFromBubble: this.props.history?.location?.state?.isRedirectionFromBubble
          })
        }
        if(this.props.history?.location?.state?.isRedirectionFromStacks !== this.state.isFromList) {
          this.setState({
            isFromList: this.props.history?.location?.state?.isRedirectionFromStacks
          })
        }

        if(this.props.history?.location?.state?.dimensionListFilter !== this.state.filterFinal) {
          this.setState({
            filterFinal: this.props.history?.location?.state?.dimensionListFilter
          })
        }
        if(this.state.periodRange !== this.props.history?.location?.state?.periodRange) {
          this.setState({
            periodRange: this.props.history?.location?.state?.periodRange
          })
        }

        if(!this.props.history?.location?.state?.profitStackItems 
          && JSON.stringify(this.props.history?.location?.state?.profitStackItems) !== JSON.stringify(this.state.selectedEntities)) {
          /** if we go from compare stacks/entity stacks to yoy/ytd/total stacks, the component won't re-render and execute did mount
           * so when changing report we empty profitStackItems from history and we check it in did update
          */ 
          this.setState({
            selectedEntities: undefined
          })
        }

        if(this.props.history?.location?.state?.toBeAddedFilter !== this.state.toBeAddedFilter) {
          this.setState({
            toBeAddedFilter: this.props.history?.location?.state?.toBeAddedFilter
          })
        }

        // when coming from dasbhoard, we want the psls selected in Dashboards so we read them from history when we rediect
        if(this.props?.history?.location?.state?.checkedPsLines) {
          this.setState({
            selectedNodes: this.props?.history?.location?.state?.checkedPsLines
          }, () => {
            let historyState = this.props?.history?.location?.state;
            historyState.checkedPsLines = undefined;
            this.props.history.push({
              state: historyState
            })
          });

        }

        if (this.props.isRange !== prevProps.isRange || this.props.isMoM !== prevProps.isMoM || this.props.isTotal !== prevProps.isTotal || this.props.isYOY !== prevProps.isYOY) { 
            //when switching screens, abort previous requests
            if (this.abortControllerRef.current) {
                this.abortControllerRef.current.abort();
              }
            if (this.abortChartControllerRef.current) {
                this.abortChartControllerRef.current.abort();
              }
          }
        if(this.props?.scenarioState?.scenario !== prevProps?.scenarioState?.scenario) {
            this.fetchCostClassification();
        }
    }

    setEntityStacksRowTitle = (entity) => {
        let _this = this;
        let isMultiVectors = Object.keys(entity).find(e=>e.includes(ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME));
        if (_this.state.isFromList && isMultiVectors) {
            let vectorName = (_this.props.history.location.state.drilling? this.props.vectorState?.vectorOptions?.find(e=>e.value === entity.tier)?.label : entity.vectorName)
            let textArray = []
            if(vectorName){
                textArray.push(vectorName + " is " + (entity.name || entity.number || new Segment().getSegmentObject(entity.profittier).label || new Segment().getSegmentObject(entity.segment).label));
            }
            for (let i = 1; i <= _this.props.vectorsSelectionLimit; i++) {
                if (entity[ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME + i] && entity[ENTITY_STACKS_OBJECT.VECTOR_NAME + i]) {
                    if (entity[entity[ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME + i] + SUFFIX.NAME]) {
                        textArray.push(entity[ENTITY_STACKS_OBJECT.VECTOR_NAME + i] + " is " + (entity[entity[ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME + i] + SUFFIX.NAME]));
                    } else if (entity[entity[ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME + i] + SUFFIX.NUMBER]) {
                        textArray.push(entity[ENTITY_STACKS_OBJECT.VECTOR_NAME + i] + " # is " + (entity[entity[ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME + i] + SUFFIX.NUMBER]));
                    } else if (entity[entity[ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME + i] + SUFFIX.SEGMENT]) {
                        textArray.push(entity[ENTITY_STACKS_OBJECT.VECTOR_NAME + i] + " is " + (new Segment().getSegmentObject(entity[entity[ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME + i] + SUFFIX.SEGMENT]).label));
                    } else {
                        textArray.push(entity[ENTITY_STACKS_OBJECT.VECTOR_NAME + i] + " is " + (new Segment().getSegmentObject(entity[entity[ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME + i] + SUFFIX.SEGMENT_TIER]).label));
                    }
                }
            }
            return concatenateStrings([...new Set(textArray)]);
        } else {
            return entity.key ? (entity[PSS.NAME] ? entity[PSS.NAME] : "") + (entity[PSS.NAME] && entity.number ? " (" + entity.number + ")" : entity.number ? entity.number : "") :
                entity.segment ? new Segment().getSegmentObject(entity.segmentTier || entity.segment).name : "";
        }
    }

    addValuesToObject = (entity, property, type, hasSuffix, specificValue) => {
        let dynamicProperties = {};
        // Loop through the desired number of valueVector properties
        for (let i = 1; i < this.props.vectorsSelectionLimit; i++) {
            // Define the property name dynamically
            let propertyName = `${property + i}`;

            if (specificValue === NAME_FIELD) {
                dynamicProperties[propertyName] = (entity[type + i]);
            } else if (specificValue === NUMBER_FIELD) {
                dynamicProperties[propertyName] = entity[entity[type + i] + SUFFIX.NUMBER]
            } else if (specificValue === "entity") {
                if (entity[entity[type + i] + SUFFIX.NUMBER]) {
                    dynamicProperties[propertyName] = NUMBER_TITLE
                } else if (entity[entity[type + i] + SUFFIX.NAME]) {
                    dynamicProperties[propertyName] = NAME_TITLE
                } else if (entity[entity[type + i] + SUFFIX.SEGMENT_TIER]) {
                    dynamicProperties[propertyName] = PROFIT_TIER
                } else if (entity[entity[type + i] + SUFFIX.SEGMENT]) {
                    dynamicProperties[propertyName] = ENTITY_TYPES.SEGMENT
                }
            } else if (hasSuffix) {
                dynamicProperties[propertyName] = (entity[entity[type + i] + SUFFIX.NUMBER] || entity[entity[type + i] + SUFFIX.NAME] || entity[entity[type + i] + SUFFIX.SEGMENT_TIER] || entity[entity[type + i] + SUFFIX.SEGMENT])
            }
        }
        return dynamicProperties;
    }

    componentDidMount(){
        // if we have dimension filter, it means we are coming from heatmap, so filter final should be the dimensionFilter
        if(this.props.history?.location?.state?.dimensionListFilter) {
          this.setState({
            filterFinal: this.props.history?.location?.state?.dimensionListFilter
          })
        }
        if (((this.props.isDashBoards || this.props.isFromDashboards)) && (this.props.callOnMount || this.props.history?.location?.state?.callOnMount) && (this.props.checkedPsLines || this.props.history?.location?.state?.checkedPsLines)){
            if(this.props.setShowGreyOverLay){
                this.props.setShowGreyOverLay(false);
            }
            this.go();
            if(this?.props?.history?.location?.state?.callOnMount) {
                this.props.history.location.state.callOnMount = false;
            }
        }
        // format selected entities so we can use them in components 
        if (this.state.selectedEntities && this.state.selectedEntities[0] && Object.keys(this.state.selectedEntities[0]).length>0) {
            let checkedEntities = this.state.checkedEntities.map(
              (entity) => {
                return {
                  label: this.setEntityStacksRowTitle(entity),
                    //   entity.key ?
                    // (entity[PSS.NAME] ? entity[PSS.NAME] : "") + (entity[PSS.NAME] && entity.number ? " (" + entity.number + ")" : entity.number ? entity.number: "" )
                    // :
                    // entity.segment ? new Segment().getSegmentObject(entity.segmentTier || entity.segment).name : "",
                  value: entity.key? (entity[PSS.NAME] || entity.number) : entity.segment,
                  color: entity.color,
                  segment: entity.segment,
                  quadrant: entity.segment,
                  profittier: entity.profittier,
                  key: entity.key,
                  number: entity.number,
                  tier: entity.tier,
                  dataset: entity.dataset,
                    ...this.addValuesToObject(entity, "vectorValue", ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME, true),
                    ...this.addValuesToObject(entity, "vectorMachineName", ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME, false,"name"),
                    ...this.addValuesToObject(entity, "vectorNumber", ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME, false, "number"),
                    ...this.addValuesToObject(entity, "entityType", ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME, false, "entity"),

                };
              }
            );
            let selectedEntities = checkedEntities;
            this.setState({checkedEntities: checkedEntities, selectedEntities: selectedEntities})
          }
          if(this.props.isRange || (this.props.isMoM && !this.props.isDashBoards)){
            let isPreview = this.state.originPathName === ALL_REPORTS.MANAGE_STACKS && this.state.defaultScreen;
            this.getProfitStackHierarchy((this.props.PSViewsState.nextProfitStackViewId || 0), this.props?.scenarioState?.scenarios[0], undefined, undefined, undefined, !isPreview);
          }

          let chartColors = [];
          COLORS_PALETTE.forEach(color => {
            let colors = {};
            colors.color = color;
            colors.taken = false;
            chartColors.push(colors);
          });
          this.setState({chartColors: chartColors});

          this.closeConfigureDropdown();
          this.fetchCostClassification();
    }

    closeConfigureDropdown = () => {
        let _this = this;
        $(document).on("mouseover click", function (event) {
          if (($("#stack_configure_dialog:hover").length === 0) && ($("#stacks-configure-btn:hover").length === 0)) {
            if (event.type === "click" && _this.state.showChartConfigureDialog) {
              _this.setState({
                showChartConfigureDialog: false
              })
            }
          }
        });
      }

    /**
     * Sets all colors except the first one to taken=false
     * @returns 
     */
    initializeChartColors = () => {
        let chartColors = this.state.chartColors;
        chartColors.forEach(color => color.taken = false);
        chartColors[0].taken = true; // this first color is taken by the default selected psl
        return chartColors;
    }

    hideconfigureDialog=()=>{
        let _this = this;
        $("#stack_table_configure_dialog").hide();
        let nodes = _this.state.selectedNodes;
        let finalNodes = [];
        for (var e in nodes) {
            if (finalNodes.filter(elt=>elt.value === nodes[e].value).length === 0) {
                finalNodes.push(nodes[e]);
            }
        }
        _this.setState({
            selectedNodes: finalNodes
        },()=>{
            if (_this.pageComponent != null) {
                _this.pageComponent.setState({
                    showConfigureDialog: false
                })
            }
        })
    }

    hideChartConfigureDialog=()=>{
        let _this = this;
        $("#stack_configure_dialog").hide();
        let nodes = _this.state.chartSelectedNodes;
        let finalNodes = [];
        for (var node in nodes) {
            if (finalNodes.filter(e=>e.value === nodes[node].value).length === 0) {
                finalNodes.push(nodes[node]);
            }
        }
        _this.setState({
            showChartConfigureDialog: false,
            chartSelectedNodes: finalNodes
        })
    }

    fetchListsCallback(scenarioChanged) {
        let _this = this;
        _this.setState({
            isYOY: !this.props.isYTD && [time_range_values.ytd, time_range_values.qtd].includes(_this.props?.periodRange),
            yearOptions: _this.getYearOptions(),
            period: _this.props?.clientPeriodsState?.period || _this.props?.periodsStatusState?.actuallyBuiltPeriods[0]
        }, function(){
            if(scenarioChanged){
                let customViewId = isNaN(Number(_this.props.PSViewsState.nextProfitStackViewId)) ? 0 : Number(_this.props.PSViewsState.nextProfitStackViewId);
                if(_this.state.shouldShowWarning || _this.props.PSViewsState.profitStackViews.filter(e=>e.value ===customViewId).length === 0){
                    _this.setWarningDialogOpen(true, "The stack you are viewing is incompatible or restricted on the scenario chosen");
                    return;
                }
            }
            if ((_this.props.callOnMount || (_this.props.location.state && _this.props.location.state.callOnMount)) && (_this.props.checkedPsLines || (_this.props.location.state && _this.props.location.state.checkedPsLines))){
                _this.go();
            }

            if(this.props.isRange || this.props.isEntity) {
                _this.getProfitStackHierarchy(_this.props.PSViewsState.nextProfitStackViewId, this.props?.scenarioState?.scenarios[0]);
            }
        });
    }

    getYearOptions() {
        let currentYear = getPeriodYear(this.props?.clientPeriodsState?.period?.value || (this.props?.period && this.props?.period.value) || (this.props?.periodsStatusState?.actuallyBuiltPeriods && this.props?.periodsStatusState?.actuallyBuiltPeriods[0].value));
        return [{label: currentYear - 1, value: currentYear - 1, color: COLORS_PALETTE[1]}, {label: currentYear, value: currentYear, color: COLORS_PALETTE[0]}];
    }

    /**
     * function generates list of periods based on the profit stack type 
     * selected along with the periods of the last year if yoy option is selected
     */
    generatePeriods() {
        const _this = this;

        let customStartDate = getValidPeriods(this.props?.periodsStatusState, this.props?.clientPeriodsState).startDate;
        let customEndDate = getValidPeriods(this.props?.periodsStatusState, this.props?.clientPeriodsState).endDate;
        if (_this.props.isRange || _this.props.isMoM) {
            let periodsCount = monthDiff(customStartDate, customEndDate) + 1;
            let periods = generatePeriods(customStartDate, periodsCount);
            return [periods.join("','"), periods.join("','")];
        }
        var selectedPeriod = (this.props?.clientPeriodsState?.period?.value) || (this.props?.period && this.props?.period?.value) || (this.props?.periodsStatusState?.actuallyBuiltPeriods && this.props?.periodsStatusState?.actuallyBuiltPeriods[0]?.value) || "";
        var selectedPeriodNumber = Number(getPeriodNumber(selectedPeriod));
        var selectedPeriodYear = getPeriodYear(selectedPeriod);
        var type = this.state.periodRange || this.props?.periodRange;
        var startPeriods = "";
        let periodOptions = this.props?.clientPeriodsState?.periodOptions?.length? this.props?.clientPeriodsState?.periodOptions : this.props?.periodOptions || this?.props?.clientPeriodsState?.periods;
        let period = (_this.props?.clientPeriodsState?.period?.value) || (_this.props?.period && _this.props?.period.value) || (this.props?.periodsStatusState?.actuallyBuiltPeriods && this.props?.periodsStatusState?.actuallyBuiltPeriods[0]?.value) || "";
        var endPeriods = "";
        if ([time_range_values.ytd].includes(type)) { // ytd always starts from p01
           startPeriods = this.computePeriods(selectedPeriodNumber,selectedPeriodYear, selectedPeriod, 1).join("','");
           endPeriods = startPeriods.replaceAll(selectedPeriodYear.toString(),(selectedPeriodYear-1).toString());

        }else if ([time_range_values.qtd].includes(type)){ // qtd start from first period of current quarter of selected period
            let quarter = (_this.props?.clientPeriodsState?.period?.quarter) || _this.props?.period?.quarter;
            var periodsOfCurrentQuarter = periodOptions.filter(e=>e[PERIOD.QUARTER] === quarter && getPeriodYear(e.value) === getPeriodYear(period));
            var firstPeriodInQuarter = periodsOfCurrentQuarter[periodsOfCurrentQuarter.length-1]? Number(periodsOfCurrentQuarter[periodsOfCurrentQuarter.length-1].value.split("P")[1]):"";
            startPeriods = this.computePeriods(selectedPeriodNumber,selectedPeriodYear,selectedPeriod, firstPeriodInQuarter).join("','");           
            endPeriods = startPeriods.replaceAll(selectedPeriodYear.toString(),(selectedPeriodYear-1).toString());
            
        } else {
            startPeriods = this.computePeriods(selectedPeriodNumber,selectedPeriodYear, selectedPeriod, 1).join("','");
        }
        return [startPeriods, endPeriods];
    }

    checkInvalidCheckedLines = (data, lines) =>{
        for (var e in lines) {
            if (!data.includes(lines[e])) {
                return true;
            }
        }
        return false
    }


    getEntityType = () => {
      let _this = this;
      if(_this.state?.selectedEntities?.filter(ee=>ee && Object.keys(ee).length > 0).length > 0 && _this.state.selectedEntities[0].key) {
        return ENTITY_TYPES.KEY;
      }
      if(_this.state?.selectedEntities?.length && _this.state?.selectedEntities[0].segment) {
        return _this.state?.selectedEntities[0].profittier? ENTITY_TYPES.PROFITTIER : ENTITY_TYPES.SEGMENT;
      }
      return ENTITY_TYPES.KEY;
    }

    setDisabledParentOptions = (val) =>{
        let _this = this;
        if(this.props.isDashBoards){
            return;
        }
        this.props?.setExpandDisabled(val);
        this.props?.setIsExportExcelDisabled(val);
        if(!val){
            $("#Export_Bridge_Table_Parent_Div").attr("uk-tooltip","title:");
            _this.props.setDisabledTooltip("");
        }
    }

    addUniqueKeyForEntities=(entities, entityType)=> {
        // let newEntities = copyObjectValues(entities);
        entities.forEach(ent => {
            let allKeys =  Object.keys(ent).filter(e => e.includes(_vectorValue))
            .map(key => ent[key]).filter(e=>e);
    
            // Add the main vector key
            let mainVectorKey = ent[entityType.toLowerCase()];
            allKeys.push(mainVectorKey);
    
            // Sort the keys
            allKeys.sort();
    
            ent[_uniqueKey] = allKeys.join('_');
        });
        return entities;
    }

    /**
     * request to get the chart data in the stacks by range screen 
     * @param {*} fromGo 
     * @param {*} chartDataToBeUpdated 
     */
    fetchProfitStackByRangeChartData=(fromGo, viewId)=> {
        this.initializeAbortChartController();

        const _this = this;
        let periods = this.generatePeriods();
        let periodsObj = _this.getPeriodsObject();// to get the quarter

        let filter = _this.getDrillFilter();
        if(_this.state.toBeAddedFilter){
            let tempFilter = copyObjectValues(JSON.parse(filter));
            let toBeAdded = _this.state.toBeAddedFilter;
            tempFilter.filter.push(toBeAdded[0]);
            filter = JSON.stringify(tempFilter);
        }
        let customStack = findOptionByKey(this.props?.PSViewsState?.profitStackViews, (_this.props?.PSViewsState?.nextProfitStackViewId !== undefined? _this.props?.PSViewsState?.nextProfitStackViewId : _this.props.profitStackViewId ? _this.props.profitStackViewId : _this.state.nextProfitStackViewId) || 0);
        let viewLabel = replaceSpecialCharacters(customStack.label,"_")?.toLowerCase();
        customStack = viewId ? viewId : customStack? customStack.value :0;
        let profitFormat = _this.props?.history?.location?.state?.profitFormat;
        let entityType = _this.getEntityType();
        let entities = _this.state.selectedEntities? _this.state.selectedEntities.filter(ee=>ee && Object.keys(ee).length>0):[];
        if(entities){
            _this.addUniqueKeyForEntities(entities, entityType);
        }
        var query = {
            action: "getProfitStackByRangeData",
            scenario_id: _this.props?.scenarioState.scenario || _this.props.scenario || (_this.headerRef && _this.headerRef?.state.scenario)  ,
            type: this.props.isRange ? ALL_WIDGETS.FIELDS.TOTAL_STACKS_URL: this.props.isYOY ? ALL_WIDGETS.FIELDS.STACKS_YOY : this.props.isMoM ? ALL_WIDGETS.FIELDS.STACKS_MOM : "",
            currentPeriods: periods[0],
            filter: filter,
            entityType:entityType,
            profitFormat: profitFormat,
            quarter: periodsObj.quarter+(periodsObj.months && periodsObj.months !== FY_VALUES.M3? periodsObj.months :""),
            quadrantFilterDisabled: _this.state.quadrantFilterDisabled,
            entities: entities,
            drillVector: _this.state.selectedEntities && !_this.state.isSecondDimension ? _this.state.selectedEntities[0].tier : "",
            vector: _this.state.isSecondDimension && _this.state.selectedEntities ? _this.state.selectedEntities[0].tier : _this.state.mainVector ? _this.state.mainVector : "",
            view_id: customStack,
            stackConfigObj: _this.state.chartConfigObj,
            isChart: true,
            rollingPeriod: periodsObj.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12,
            viewLabel: viewLabel
        }
        _this.sentRequests = _this.sentRequests || 0;
        _this.sentRequests += 1;
        var onThenCallback = (data)=>{
            let tempState = {}
            _this.sentRequests -= 1;
            if (data) {
                let hasInvalidAccess = _this.props.checkForLimitAccessMessage(data, _this.sentRequests === 0);
                if(hasInvalidAccess) {
                    _this.setState({
                        dataId: shortid.generate(),
                        chartLines: []
                    });
                    _this.setDisabledParentOptions(true);
                }
                tempState.checkedEntitiesWithUniqueKey = entities;
                tempState.chartAmountType = this.state.typeApplied ? this.state.chartAmountType : this.state.originalChartType;
                tempState.dataOrderer = data.orderer;
                tempState.chartSelectedNodes = _this.state.chartSelectedNodes || (_this.props.location && _this.props.location.state.checkedPsLines) || []
                tempState.isYOY = !this.props.isYTD && !this.props.isMoM && !this.props.isRange && [time_range_values.ytd, time_range_values.qtd].includes(_this.props?.periodRange);
                if(data.firstLevelPsl) {
                    let chartPslOptions = this.formatPslLines(data.firstLevelPsl);
                    if (tempState.chartSelectedNodes && tempState.chartSelectedNodes.length > 0) {
                        let checkedItems = tempState.chartSelectedNodes.map(function(item){return item.value});
                        chartPslOptions = _this.checkItems(chartPslOptions, checkedItems);
                    } else {
                        chartPslOptions.filter(line => line.value === data.orderer).forEach(psl => {psl.checked = true; psl.color = _this.state.chartColors[0].color}); // set checked psl lines
                        tempState.chartColors = this.initializeChartColors(); // to set the first color as taken when assignong it for the default selected psl
                    }
                    tempState.chartPslOptions = chartPslOptions;
                }
                let yearOptions = _this.getYearOptions();
                tempState.yearOptions = tempState.isYOY ? yearOptions : [];
                tempState.originalChartData = copyObjectValues(data.data);
                tempState.checkedYears = tempState.yearOptions;     //setting both years as selected
                //setting ps line received from API checked by default, or all lines to be checked
                if (tempState.chartSelectedNodes && tempState.chartSelectedNodes.length > 0) {
                    tempState.checkedChartPsLines = linearizeHierarchy(tempState.chartPslOptions, "children").filter(line => tempState.chartSelectedNodes.find(f=>f.value===line.value))//tempState.chartPslOptions.filter(line => tempState.chartSelectedNodes.includes(line.value));
                }else {
                    tempState.checkedChartPsLines = linearizeHierarchy(tempState.chartPslOptions).filter(line => line.value === data.orderer);
                }
                let period = _this.props?.clientPeriodsState?.period?.value || _this.props?.period.value;
                //updating chart lines as well
                let chartData = [];
                let periodNumber = getPeriodNumber(period);
                let periodYear = getPeriodYear(period);
                let firstPeriod = undefined;

                let chartConfigObj = { // default configuration for the chart
                    psl: tempState.chartPslOptions && tempState.chartPslOptions.length > 0 ? tempState.chartPslOptions[0].value : undefined,
                    amountType: tempState.chartAmountType || AMOUNT,
                    isFirstMedian: false,
                    isSecondMedian: false,
                }
                tempState.chartConfigObj = chartConfigObj;

                chartData = _this.props.checkedPsLines ? 
                _this.formChartData(periodNumber, periodYear,period, firstPeriod,tempState, [], data.data, true) :
                 _this.formChartData(periodNumber, periodYear, period, firstPeriod,tempState,[], data.data)
                
                tempState.chartData = chartData;
                _this.setDisabledParentOptions(tempState.checkedChartPsLines.length === 0)
                typeof _this.props.loaderCallback === 'function' && _this.props.loaderCallback(false);
                tempState.chartSelectedNodes && tempState.chartSelectedNodes.length > 0
                 ? _this.setState(tempState, function(){_this.updateChartData(tempState.checkedChartPsLines, tempState.checkedYears, _this.state.checkedEntities)}) :  _this.setState(tempState, _this.updateChartData); 

                _this.setState(tempState);
            }
        }

        let vector = _this.state.selectedEntities && _this.state.selectedEntities[0]?.tier?  _this.state.selectedEntities[0].tier : _this.state.mainVector;
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getProfitStackByRangeData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: !this.props.isDashBoards && !this.props.showGreyOverLay,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_STACK,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:  this.props.isRange ? this.props.isEntity? this.getScreenName() : lang.observability.output.total_stacks.screen_name: this.props.isYOY ? lang.observability.output.yoy_stacks.screen_name : this.props.isMoM ? lang.observability.output.mom_stacks.screen_name : "",
            [FETCHAPI_PARAMS.requestDescription]: _this.props?.history?.location?.state?.isManageStacks ? lang.observability.output.manage_stacks.request_description.total_stacks_chart :this.props.isRange ? this.props.isEntity? lang.observability.output.entity_stacks.requests_description.chart : lang.observability.output.total_stacks.requests_description.chart: this.props.isYOY ? lang.observability.output.yoy_stacks.requests_description.chart : this.props.isMoM ? lang.observability.output.mom_stacks.requests_description.chart : "",
            [FETCHAPI_PARAMS.vector]:vector,
            [FETCHAPI_PARAMS.periods]:  periods[0],
            [FETCHAPI_PARAMS.signal]: this.chartController.signal
        }
        //if there is a tracking obj in the session, action is two_dimension_heatmap, else generate_heatmap
        fetchAPI(fetchOptions);
    }

    getScreenName = () =>{
		let screenName;
		switch (this.state.originPathName) {
			case ALL_WIDGETS.FIELDS.HEATMAP:
				screenName = lang.observability.output.heatmap.screen_name;
				break;
			case ALL_WIDGETS.GEOGRAPHY_CONCENTRATION:
				screenName = lang.observability.output.geography.screen_name;
				break;
			case MENU_ITEM.FIELDS.PROFIT_LANDSCAPE:
				screenName = lang.observability.output.landscape.screen_name;
				break;
			default:
				screenName = lang.observability.output.list.screen_name;
		}
		return lang.observability.output.entity_stacks.screen_name +" (" + screenName +")";
	}

  
    getDrillFilter = () => {
        let _this = this;
        let jsonFilter = tryParse(_this.props.filterFinal); //handling filter coming from second dimension
        if(!jsonFilter) {
          return JSON.stringify({ filter: [] });
        }
        let filter = !Array.isArray(jsonFilter) && !!jsonFilter.filter ? jsonFilter : { filter: tryParse(_this.props.filterFinal) };
        if(filter.filter?.length > 1){
            filter = wrapFilterWithParenthesis(filter);
        }
        let df = _this.state.originalDrillFilter;
        let filterEntities = [];


        // add drill filter to filterFinal
        if (Array.isArray(df)) {
            for (var j = 0; j < df.length; j++) {
                if (df[j][FILTER.KEYS.FILTER_ROW_TYPE] === FILTER.VALUES.FILTER_ROW_TYPE.DRILL) {
                    // add quarter and months to filter if it's Q or QT
                    filterEntities.push(df[j]);
                }
            }
        }
        for (let j = 0; j < filterEntities.length; j++) {
            filter.filter.push(filterEntities[j]);
        }

        return JSON.stringify(filter);
    }

    /**
     * check if we need to send a new request, if we are removing a line for ex, no need to refetch data
     * @param {*} nextConf 
     * @param {*} diff 
     * @returns 
     */
    checkIfFetchNeeded = (nextConf, diff) => {
        let shoulfFetch = false;
        if(
            diff.includes(STACK_CONF.AMOUNT_TYPE) 
            || (diff.includes(PSL.value) && nextConf.psl) 
            || (diff.includes(FIRST_ATTRIBUTE.value) && nextConf.firstAttribute)
            || (diff.includes(SECOND_ATTRIBUTE.value) && nextConf.secondAttribute)
            || (diff.includes(STACK_CONF.IS_FIRST_MED) && nextConf.firstAttribute)
            || (diff.includes(STACK_CONF.IS_SECOND_MED) && nextConf.secondAttribute)
            ){
                shoulfFetch = true;
        }
        if(diff.length === 1 && ((diff.includes("firstAttribute") && nextConf.isFirstMedian))){
            shoulfFetch = false
        }
        return shoulfFetch;
    }

    /**
     * get the different attributes between the previous and the current configuration
     * @param {*} obj1 
     * @param {*} obj2 
     * @returns 
     */
    getObjectDiff = (obj1, obj2) => {
        return Object.keys(obj1).reduce((result, key) => {
          if (!obj2.hasOwnProperty(key)) {
            result.push(key);
          } else if (obj1[key] === obj2[key] || (obj1[key] && obj2[key] && obj1[key] === obj2[key].value) || (obj1[key] && obj2[key] && obj1[key].value === obj2[key]) ) {
            const resultKeyIndex = result.indexOf(key);
            result.splice(resultKeyIndex, 1);
          }
          return result;
        }, Object.keys(obj2));
    }

    fetchProfitStackByRangeData=(fromGo, viewId)=> {
        this.initializeAbortController();

        const _this = this;
        let periods = this.generatePeriods();
        let periodsObj = _this.getPeriodsObject();
        let filter = _this.getDrillFilter();
        if(_this.state.toBeAddedFilter){
            let tempFilter = copyObjectValues(JSON.parse(filter));
            let toBeAdded = _this.state.toBeAddedFilter;
            tempFilter.filter.push(toBeAdded[0]);
            filter = JSON.stringify(tempFilter);
        }
        let customStack = findOptionByKey(this.props.PSViewsState.profitStackViews, (_this.props?.PSViewsState?.nextProfitStackViewId !== undefined? _this.props?.PSViewsState?.nextProfitStackViewId : _this.state.nextProfitStackViewId) || 0);
        let viewLabel = replaceSpecialCharacters(customStack.label, "_")?.toLowerCase();
        customStack = viewId ? viewId : customStack ? customStack.value :0;
        let stackConf = _this.state.stackConfigObj;
        let profitFormat = _this.props?.history?.location?.state?.profitFormat;
        let entityType = this.getEntityType();
        let requestTime = Date.now().toString();
        var query = {
            action: "getProfitStackByRangeData",
            scenario_id: _this.props?.scenarioState?.scenario || _this.props.scenario || (_this.headerRef && _this.headerRef.state.scenario)  ,
            type: this.props.isRange ? ALL_WIDGETS.FIELDS.TOTAL_STACKS_URL: this.props.isYOY ? ALL_WIDGETS.FIELDS.STACKS_YOY : this.props.isMoM ? ALL_WIDGETS.FIELDS.STACKS_MOM : "",
            currentPeriods: periods[0],
            filter: filter,
            requestTime: requestTime,
            entityType: entityType,
            quarter: periodsObj.quarter+(periodsObj.months && periodsObj.months !== FY_VALUES.M3? periodsObj.months :""),
            quadrantFilterDisabled: _this.state.quadrantFilterDisabled,
            entities: _this.state.selectedEntities? _this.state.selectedEntities.filter(ee=>ee && Object.keys(ee).length>0):[],
            drillVector: _this.state.selectedEntities && !_this.state.isSecondDimension ? _this.state.selectedEntities[0].tier : "",
            vector: _this.state.isSecondDimension && _this.state.selectedEntities ? _this.state.selectedEntities[0].tier : _this.state.mainVector ? _this.state.mainVector : "",
            view_id: customStack,
            rollingPeriod: periodsObj.segmentPeriod,
            rollingSegment: ROLLING_SEGMENTS.R_12,
            profitFormat: profitFormat,
            viewLabel: viewLabel,
            stackConfigObj: stackConf && (_this.state.stackConfigApplied ||  _this.state.isDefaultStackConfigObj)
                ? {
                psl: !stackConf.hidePsl? stackConf.psl:undefined,
                amountType: stackConf.amountType,
                firstAttribute: stackConf.firstAttribute && !stackConf.hideFirstAttribute? stackConf.firstAttribute.value: undefined,
                secondAttribute: stackConf.secondAttribute && !stackConf.hideSecondAttribute? stackConf.secondAttribute.value: undefined,
                isFirstMedian: stackConf.isFirstMedian,
                isSecondMedian: stackConf.isSecondMedian
             } : 
             {
                psl: !_this.state.defaultStackConfigObj.hidePsl? _this.state.defaultStackConfigObj.psl:undefined,
                amountType: _this.state.defaultStackConfigObj.amountType,
                firstAttribute: _this.state.defaultStackConfigObj.firstAttribute && !_this.state.defaultStackConfigObj.hideFirstAttribute? _this.state.defaultStackConfigObj.firstAttribute.value: undefined,
                secondAttribute: _this.state.defaultStackConfigObj.secondAttribute && !_this.state.defaultStackConfigObj.hideSecondAttribute? _this.state.defaultStackConfigObj.secondAttribute.value: undefined,
                isFirstMedian: _this.state.defaultStackConfigObj.isFirstMedian,
                isSecondMedian: _this.state.defaultStackConfigObj.isSecondMedian
             }
        }
        let currentConf = _this.lastAppliedStacksConf || _this.state.defaultStackConfigObj;
        let diff = _this.getObjectDiff(currentConf , query.stackConfigObj);
        let shouldFetchData = true;

        if(!fromGo){// from applyConfig
            shouldFetchData = _this.checkIfFetchNeeded(query.stackConfigObj, diff);
            if(shouldFetchData){
                _this.changedConfiguration.push({requestTime:requestTime, diff:diff});// requestTime is a unique param for each request, it is sent to the api and returned to the callback, to detect each callback is related to which request
                _this.lastRequestSentTime = requestTime;// save the last sent request time, to abort all other requests
                _this.props.setIsExportExcelDisabled(_this.changedConfiguration.map(e=>e.diff).flat().length > 0);
                if(_this.changedConfiguration.map(e=>e.diff).flat().length > 0){
                    $("#Export_Bridge_Table_Parent_Div").attr("uk-tooltip",lang.data_still_loading);
                    _this.props.setDisabledTooltip(lang.data_still_loading);
                }
            }
        } else {
            _this.lastRequestSentTime = requestTime;// save the last sent request time, to abort all other requests
        }
        _this.lastAppliedStacksConf = query.stackConfigObj;
        _this.sentRequests = _this.sentRequests || 0;
        _this.sentRequests += 1;
        var onThenCallback = (data)=>{
            if(_this.lastRequestSentTime && data.requestTime !== _this.lastRequestSentTime){// if the request received is not the last request sent, abort it
                return;
            }
            _this.changedConfiguration = [];// if the request received id the last one, reset the changedConfiguration array
            let tempState = {}
            _this.sentRequests -= 1;
            // _this.headerRef.disableExportExcel(false);// enable the export button
            _this.pageComponent?.exportBtnRef?.current?.setIsDisabled(false)

            if (data) {
                let hasInvalidAccess = _this.props.checkForLimitAccessMessage(data, _this.sentRequests === 0);
                if(hasInvalidAccess) {
                    _this.setState({
                        profitStackTableData: [],
                        dataId: shortid.generate(),
                        chartLines: []
                    });
                    _this.setDisabledParentOptions(true);
                }
                var columns = copyObjectValues(data.columns);
                columns = _this.pageComponent.removeToggledOffCols(columns,_this.lastAppliedStacksConf,true);// remove the turned off lines
                if (columns && columns.length > 0) {
                    columns.forEach(col => {
                        // assign color to column to be displayed next to each column
                        if(_this.state.selectedEntities) {
                            let entity = _this.state.selectedEntities.find(f=>f.label === col.title);
                            col.color = entity ? entity.color : undefined;
                            col.title = new Segment().getSegmentObject(col.title)?.label || col.title
                        }   
                    });

                    tempState = {
                        dataId: shortid.generate(),
                        columns: columns,
                        profitStackTableData: data.data,
                        firstParentCH: {[PS_MAPPING.FIELDS.RETURN_NAME]: query.stackConfigObj.psl},
                        firstAttributeCH: {[PS_MAPPING.FIELDS.RETURN_NAME]: query.stackConfigObj.firstAttribute},
                        secondAttributeCH: {[PS_MAPPING.FIELDS.RETURN_NAME]: query.stackConfigObj.secondAttribute},
                        mediansData: data.medians,
                        vectorCountData: data.vectorCount,
                    }
                    if(fromGo){
                        tempState.goPressed = fromGo;
                    }
                }
                tempState.dataOrderer = data.orderer;
                // if chart changes not applied, reset to applied values when change in table configure
                tempState.chartAmountType = _this.state.typeApplied ? _this.state.chartAmountType : _this.state.originalChartType;
                if(!_this.state.typeApplied && _this.state.chartPslOptions) {
                    tempState.chartSelectedNodes = _this.state.chartSelectedNodes || [];
                    let chartPslOptions = _this.state.chartPslOptions || [];
                    chartPslOptions = _this.uncheckAllPsLines(chartPslOptions);

                    if (tempState.chartSelectedNodes && tempState.chartSelectedNodes.length > 0) {
                        let checkedItems = tempState.chartSelectedNodes.map(function(item){return item.value});
                        chartPslOptions = _this.checkItems(chartPslOptions, checkedItems);
                        chartPslOptions.filter(line => checkedItems.includes(line.value)).map(m => m.checked = true); // set checked psl lines
                    } else {
                        chartPslOptions.filter(line => line.value === data.orderer).map(m => m.checked = true); // set checked psl lines
                    }
                    tempState.chartPslOptions = chartPslOptions;
                }

                tempState.defaultStackConfigObj = _this.state.stackConfigApplied || _this.state.isDefaultStackConfigObj ? stackConf : _this.state.defaultStackConfigObj;
                tempState.selectedNodes = _this.state.selectedNodes || (_this.props.location && _this.props.location.state.checkedPsLines) || []
                tempState.isYOY = !this.props.isYTD && !this.props.isMoM && !this.props.isRange && [time_range_values.ytd, time_range_values.qtd].includes(_this.props?.periodRange);
                if(data.firstLevelPsl) {
                    let pslOptions = this.formatPslLines(data.firstLevelPsl);
                    if (tempState.selectedNodes.length > 0) {
                        let checkedItems = tempState.selectedNodes.map(function(item){return item.value});
                        pslOptions = _this.checkItems(pslOptions, checkedItems);
                        pslOptions.filter(line => checkedItems.includes(line.value)).map(m => m.checked = true); // set checked psl lines
                    } else {
                        pslOptions.filter(line => line.value === data.orderer).map(m => m.checked = true); // set checked psl lines
                    }
                    tempState.pslOptions = pslOptions;
                }

                let yearOptions = _this.getYearOptions();
                tempState.yearOptions = tempState.isYOY ? yearOptions : [];
                tempState.checkedYears = tempState.yearOptions;     //setting both years as selected
                //setting ps line received from API checked by default, or all lines to be checked
                if (tempState.selectedNodes && tempState.selectedNodes.length > 0) {
                    tempState.checkedPsLines = tempState.pslOptions.filter(line => tempState.selectedNodes.includes(line.value));
                }else {
                    tempState.checkedPsLines = tempState.pslOptions.filter(line => line.value === data.orderer);
                }
                _this.setDisabledParentOptions(tempState.profitStackTableData.length === 0);
                _this.setState(tempState);
            }
        }
        let vector = _this.state.selectedEntities && _this.state.selectedEntities[0]?.tier ? _this.state.selectedEntities[0].tier : _this.state.mainVector;
        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "fetchProfitStackByRangeData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: fromGo && ((!this.props.isDashBoards && !this.props.showGreyOverLay) || (this.state.originPathName && [ALL_REPORTS.LIST_TOP, ALL_REPORTS.HEATMAP, ALL_REPORTS.CONTOUR_MAP].includes(this.state.originPathName))),
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_STACK,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]:  this.props.isDashBoards? lang.observability.output.dashboards.screen_name : this.props.isRange ? this.props.isEntity? this.getScreenName() : lang.observability.output.total_stacks.screen_name: this.props.isYOY ? lang.observability.output.yoy_stacks.screen_name : this.props.isMoM ? lang.observability.output.mom_stacks.screen_name : "",
            [FETCHAPI_PARAMS.requestDescription]: ( _this.props?.history?.location?.state?.isManageStacks ? lang.observability.output.manage_stacks.request_description.total_stacks_apply : this.props.isRange ? this.props.isEntity? lang.observability.output.entity_stacks.requests_description.apply : lang.observability.output.total_stacks.requests_description.apply: this.props.isYOY ? lang.observability.output.yoy_stacks.requests_description.apply : this.props.isMoM ? lang.observability.output.mom_stacks.requests_description.apply : "") + (this.props.isDashBoards? lang.observability.output.dashboards.widget : ""),
            [FETCHAPI_PARAMS.vector]: vector,
            [FETCHAPI_PARAMS.periods]:  periods[0],
            [FETCHAPI_PARAMS.signal]: this.controller.signal
        }
        //if there is a tracking obj in the session, action is two_dimension_heatmap, else generate_heatmap
        _this.setState({
            stackConfigObj: stackConf && (_this.state.stackConfigApplied ||  _this.state.isDefaultStackConfigObj)? stackConf : _this.state.defaultStackConfigObj,
            currStackConfigObj: stackConf && (_this.state.stackConfigApplied ||  _this.state.isDefaultStackConfigObj)? stackConf : _this.state.defaultStackConfigObj
        },function(){
            if(!fromGo){
                if(shouldFetchData){
                    _this.pageComponent?.exportBtnRef?.current?.setIsDisabled(true);
                    // _this.headerRef.disableExportExcel(true);
                }
                _this.pageComponent.showLoaderOnColumn(_this.changedConfiguration.map(e=>e.diff).flat(), _this.lastAppliedStacksConf);// show loader on changed columns
            }
            if(shouldFetchData){
                fetchAPI(fetchOptions);
            }
        })
    }

    checkItems=(pslOptions, checkedItems, chartColors = this.state.chartColors)=>{
        let _this = this;
        for (var e in pslOptions) {
            if (checkedItems.includes(pslOptions[e].value ? pslOptions[e].value : pslOptions[e])) {
                pslOptions[e].checked = true;
                // to set the corresponding color for each psl, after refetching data
                if(_this.state.chartSelectedNodes && _this.state.chartSelectedNodes.length > 0) {
                    pslOptions[e].color = _this.state.chartSelectedNodes.length > 0  && _this.state.chartSelectedNodes.find(m => m.value === pslOptions[e].value).color; 
                } else {
                    let colorObj = chartColors.find(f => !f.taken);
                    pslOptions[e].color = colorObj.color;
                    colorObj.taken = true;
                }
            }
            if (pslOptions[e].children && pslOptions[e].children.length > 0) {
                _this.checkItems(pslOptions[e].children, checkedItems, chartColors)
            }
        }
        return pslOptions;
    }

    /**
     * Set the corresponding color for each psl based on its color in selectedData
     * @param {*} pslines 
     * @param {*} selectedData 
     * @returns 
     */
    setColorsToPsl = (pslines, selectedData) => {
        let _this = this;
        pslines.forEach(option => {
            if(selectedData.find(f => f.value === option.value)) {
                option.color = selectedData.find(f => f.value === option.value).color;
            } else {
                option.color = undefined; // to remove color of an unselected psl
            }
            if (option.children && option.children.length > 0) {
                _this.setColorsToPsl(option.children, selectedData)
            }
        });
        
        return pslines;
    }

    uncheckAllPsLines = (arr) => {
        if(arr){
            arr.forEach(row => {
                row.checked = false;
                if (!row.children) { return; }
                this.uncheckAllPsLines(row.children);
            });
            return arr;
        }
    }

    fetchProfitStackData() {
        const _this = this;
        this.initializeAbortController();

        if (this.props.checkedPsLines && !this.props?.periods) {
            return;
        }
        typeof _this.props.loaderCallback === 'function' && _this.props.loaderCallback(true);
        var periods = this.generatePeriods();
        let periodsObj = this.getPeriodsObject();
        let concatenatedFilter = this.props.filterFinal?copyObjectValues(tryParse(this.props.filterFinal)): [];
        let dashBoardFilter = tryParse(this.props.dashboardFilter);
        if(this.props.dashboardFilter && dashBoardFilter.length>0){
            if(concatenatedFilter.filter.length>0){
                concatenatedFilter.filter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
                concatenatedFilter.filter[concatenatedFilter.filter.length -1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
            }
            let dashboardFilterObj = "";
            dashBoardFilter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
            dashBoardFilter[0].logical_operator = "AND";
            dashBoardFilter[dashBoardFilter.length-1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
            dashBoardFilter.forEach(e=>{
                dashboardFilterObj = e;
                concatenatedFilter.filter.push(dashboardFilterObj);
            })
        }
        concatenatedFilter = JSON.stringify(concatenatedFilter);
        let filter = _this.props.isDashBoards? concatenatedFilter : JSON.stringify({filter: tryParse(concatenatedFilter)}); 
        let customStack = findOptionByKey(this.props?.PSViewsState?.profitStackViews || this.props.profitStackViews, (_this.props?.PSViewsState?.nextProfitStackViewId !== undefined? _this.props?.PSViewsState?.nextProfitStackViewId : _this.props.profitStackViewId ? _this.props.profitStackViewId : _this.state.nextProfitStackViewId) || 0);
        let viewLabel = replaceSpecialCharacters(customStack.label,"_")?.toLowerCase();
        customStack = customStack? customStack.value :0;
        if(_this.props.isDashBoards) {
          customStack = this.props?.profitStackViewId || 0; 
        }
        
        let chartAxes = this.state.chartAxes;
        if(_this.state.toBeAddedFilter){
            let tempFilter = copyObjectValues(JSON.parse(filter));
            let toBeAdded = _this.state.toBeAddedFilter;
            tempFilter.filter.push(toBeAdded[0]);
            filter = JSON.stringify(tempFilter);
        }
        
        let lastSelectedPeriod = periods[periods.length - 1];
        let builtPeriods = _this.props?.periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label);
        let rollingPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12); 
        let periodRange = _this.state.periodRange || _this.props?.periodRange;
        var query = {
            action: "getProfitStacksData",
            scenario_id: this.props?.scenarioState?.scenario || this.props.scenario,
            type: (this.props.isYTD || this.props.isMoM) ? lang.header.options.period_range[4].value : periodRange,
            currentPeriods: periods[0],
            previousPeriods: periods[1],
            selectedPeriod: (this.props?.clientPeriodsState?.period?.value) || (this.props?.period && this.props?.period.value) || (this.props?.periodsStatusState?.actuallyBuiltPeriods && this.props?.periodsStatusState?.actuallyBuiltPeriods[0]?.value) || "",
            filter: filter,
            view_id: customStack,
            viewLabel:viewLabel,
            rollingPeriod: rollingPeriod || (this.props?.clientPeriodsState?.period?.value) || (this.props?.period && this.props?.period.value) || (this.props?.periodsStatusState?.actuallyBuiltPeriods && this.props?.periodsStatusState?.actuallyBuiltPeriods[0]?.value) || "",
            rollingSegment: ROLLING_SEGMENTS.R_12,   
        }
        if (this.props.isWidgetProfitStacks) {
            let arr = copyObjectValues(this.props.checkedPsLines).map(function(e){return e.value})
            query.checkedLines = arr.join(',');
        }
        _this.sentRequests = _this.sentRequests || 0;
        _this.sentRequests += 1;
        var onThenCallback = (data)=>{
            _this.sentRequests -= 1;
            if (data) {
                let tempState = {}
                let hasInvalidPSSAccess = !!data.ERROR;
                let checkedLines = _this.props.isWidgetProfitStacks? _this.props.checkedPsLines : _this.props?.history?.location?.state?.checkedPsLines || _this.props.checkedPsLines;
                if (checkedLines) {
                    checkedLines.map(function(item){item.checked = true});
                }
                if (!hasInvalidPSSAccess &&  checkedLines) {
                    let pssLines = linearizeHierarchy(data.data, PS_MAPPING.FIELDS.CHILDREN).map(function(item){return item.returnname})
                    let lines = checkedLines.map(function(item){return item.value})
                    hasInvalidPSSAccess = _this.checkInvalidCheckedLines(pssLines,lines);
                }
                let hasInvalidAccess = _this.props?.checkForLimitAccessMessage(data, _this.sentRequests === 0);
                if(hasInvalidAccess || (hasInvalidPSSAccess && checkedLines) || (_this.props.has_access !== undefined && _this.props.has_access === false)) {
                    _this.setState({
                        profitStackTableData: [],
                        dataId: shortid.generate(),
                        chartLines: []
                    })
                    _this.setDisabledParentOptions(true);

                    if(typeof _this.props.handleAPIError==="function"){
                        _this.props?.handleAPIError(lang.dashboards.messages.no_permission);
                    } else{
                        this.setWarningDialogOpen(true, lang.manage_stacks.no_permission)
                    }
                    return;
                }
                var columns = data.columns;
                if (columns && columns.length > 0) {
                    columns.forEach(col => {
                        if(col.columns) {
                            col.columns.forEach(c => {
                                if(c.field !== VECTOR_ANALYSIS.FIELDS.NAME) {
                                    c.title = capitalizeFirstLetter(c.title);
                                } else {
                                    c.cssClass = "leftAlign name-field";
                                }
                                c.dontFilter = true;
                                c.headerSort = false;
                            });
                            if (col.title === PSL_LINES) {
                                col.cssClass="fit-header-yoy"
                            }
                        } else {
                            col.dontFilter = true;
                            col.headerSort = false;
                            if(col.field && col.field === VECTOR_ANALYSIS.FIELDS.LEVEL) {
                                col.visible = false
                            }
                            if (col.title === PSL_LINES) {
                                col.cssClass = "leftAlign name-field" +  (periodRange === time_range_values.periods ? " static-periods-width" : "" );
                            }
                        } 
                    });
                    
                    tempState = {
                        dataId: shortid.generate(),
                        columns: columns,
                        profitStackTableData: data.data
                    }
                }
                tempState.selectedNodes = (!_this.state.selectedNodes || _this.state.selectedNodes.length === 0) ? (checkedLines) : _this.state.selectedNodes //|| (checkedLines) || []
                tempState.isYOY = !this.props.isYTD && !this.props.isMoM && [time_range_values.ytd, time_range_values.qtd].includes(_this.props?.periodRange);
                tempState.firstParentCH = undefined;
                tempState.firstAttributeC = undefined;
                tempState.secondAttributeCH = undefined;
                tempState.dataOrderer = data.orderer;
                if(data.firstLevelPsl) {
                    let pslOptions = _this.formatPslLines(data.firstLevelPsl);
                    if (tempState.selectedNodes && tempState.selectedNodes.length > 0) {
                        let checkedItems = tempState.selectedNodes.map(function(item){return item.value});
                        let chartColors = copyObjectValues(_this.state.chartColors)
                        chartColors.forEach(color => color.taken = false);
                        pslOptions = _this.checkItems(pslOptions, checkedItems, chartColors);
                        linearizeHierarchy(pslOptions).filter(f => f.color).forEach(opt => tempState.selectedNodes.find(f => f.value === opt.value).color = opt.color); // assign the color to the psl
                    } else {
                        pslOptions.filter(line => line.value === data.orderer).forEach(psl => {psl.checked = true; psl.color = _this.state.chartColors[0].color}); // set checked psl lines
                        tempState.chartColors = this.initializeChartColors(); // to set the first color as taken when assignong it for the default selected psl
                        tempState.checkedPsLines = pslOptions;
                        tempState.selectedNodes = pslOptions.filter(line => line.value === data.orderer);
                    }
                    tempState.pslOptions = pslOptions;
                    if(!_this.props.isDashBoards) {
                        tempState.chartAxes = chartAxes;
                        let sign = tempState.selectedNodes.length === 1 && tempState.selectedNodes[0].format === FormatTypes.PERCENTAGE? CONFIGURE_TOGGLE_TABS[1].label : CONFIGURE_TOGGLE_TABS[0].label
                        sign = handleCurrency(sign);
                        tempState.chartAxes.y.title = (_this.props.isYOY ? linearizeHierarchy(pslOptions).filter(f => f.checked)[0].label : AMOUNT_TITLE) + " ("+sign+")";
                    }
                    
                }

                let yearOptions = _this.getYearOptions();
                tempState.yearOptions = tempState.isYOY ? yearOptions : [];
                tempState.checkedYears = tempState.yearOptions;     //setting both years as selected
                //setting ps line received from API checked by default, or all lines to be checked
                tempState.checkedPsLines = tempState.pslOptions.filter(line => line.value === data.orderer);
                let period = _this.props?.clientPeriodsState?.period?.value || _this.props?.period.value;
                let periods = _this.props?.clientPeriodsState?.periods ||_this.props?.periods;
                //updating chart lines as well
                let chartData = [];
                let periodNumber = getPeriodNumber(period);
                let periodYear = getPeriodYear(period);
                let firstPeriod = undefined;
                if ([time_range_values.qtd].includes(periodRange)){ // qtd start from first period of current quarter of selected period
                    let quarter = (_this.props?.clientPeriodsState?.period?.quarter) || _this.props?.period.quarter
                    var periodsOfCurrentQuarter = periods.filter(e=>e[PERIOD.QUARTER] === quarter && getPeriodYear(e.value) === getPeriodYear(period));
                    firstPeriod = getPeriodNumber(periodsOfCurrentQuarter[periodsOfCurrentQuarter.length-1].value);
                }
                chartData = checkedLines ? _this.formChartData(periodNumber, periodYear,period, firstPeriod,tempState, [], data.data, true) : _this.formChartData(periodNumber, periodYear, period, firstPeriod,tempState,[], data.data)
                
                tempState.chartData = chartData;
                typeof _this.props.loaderCallback === 'function' && _this.props.loaderCallback(false);
                _this.setDisabledParentOptions(tempState.profitStackTableData.length === 0);

                tempState.selectedNodes ? _this.setState(tempState, function()
                    {
                        _this.updateChartData(tempState.selectedNodes,tempState.checkedYears)
                    })
                :  _this.setState(tempState, _this.updateChartData); 
            }
        }

        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getProfitStacksData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: !this.props.isDashBoards && !this.props.showGreyOverLay,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_STACK,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: this.props.isDashBoards || this.props.isFromDashboards ? lang.observability.output.dashboards.screen_name : this.props.isRange ? lang.observability.output.total_stacks.screen_name: this.props.isYOY ? lang.observability.output.yoy_stacks.screen_name : this.props.isMoM ? lang.observability.output.mom_stacks.screen_name : "",
            [FETCHAPI_PARAMS.requestDescription]: (this.props.isRange ? lang.observability.output.total_stacks.requests_description.apply: this.props.isYOY ? lang.observability.output.yoy_stacks.requests_description.apply : this.props.isMoM ? lang.observability.output.mom_stacks.requests_description.apply : "") + (this.props.isDashBoards || this.props.isFromDashboards ? lang.observability.output.dashboards.widget : ""),
            [FETCHAPI_PARAMS.periods]:  "Current Periods:" + periods[0] + ", Previous Periods: " + periods[1],
            [FETCHAPI_PARAMS.signal]: this.controller.signal
        }
        //if there is a tracking obj in the session, action is two_dimension_heatmap, else generate_heatmap
        fetchAPI(fetchOptions);
    }

    getProfitStackByConfigurationData = () => {
        this.initializeAbortController();

        const _this = this;
        if (this.props.checkedPsLines && !this.props?.periods) {
            return;
        }
        typeof _this.props.loaderCallback === 'function' && _this.props.loaderCallback(true);
        var periods = this.generatePeriods();
        let periodsObj = this.getPeriodsObject();
        let concatenatedFilter = this.props.filterFinal? copyObjectValues(tryParse(this.props.filterFinal)): [];
        let dashBoardFilter = tryParse(this.props.dashboardFilter);
        if(this.props.dashboardFilter && dashBoardFilter.length>0){
            if(concatenatedFilter.filter.length>0){
                concatenatedFilter.filter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
                concatenatedFilter.filter[concatenatedFilter.filter.length -1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
            }
            let dashboardFilterObj = "";
            dashBoardFilter[0][FILTER.KEYS.PARENTHESIS_BEFORE] += "(";
            dashBoardFilter[0].logical_operator = "AND";
            dashBoardFilter[dashBoardFilter.length-1][FILTER.KEYS.PARENTHESIS_AFTER] += ")";
            dashBoardFilter.forEach(e=>{
                dashboardFilterObj = e;
                concatenatedFilter.filter.push(dashboardFilterObj);
            })
        }
        concatenatedFilter = JSON.stringify(concatenatedFilter);
        let filter = _this.props.isDashBoards? concatenatedFilter : JSON.stringify({filter: tryParse(concatenatedFilter)});
        let customStack = findOptionByKey(this.props?.PSViewsState?.profitStackViews || this.props.profitStackViews, (_this.props?.PSViewsState?.nextProfitStackViewId !== undefined? _this.props?.PSViewsState?.nextProfitStackViewId : _this.props.profitStackViewId ? _this.props.profitStackViewId : _this.state.nextProfitStackViewId) || 0);
        let viewLabel = replaceSpecialCharacters(customStack.label,"_")?.toLowerCase();
        customStack = customStack? customStack.value :0;

        if(_this.props.isDashBoards) {
          customStack = this.props?.profitStackViewId || 0; 
        }

        let chartAxes = this.state.chartAxes;
        if(_this.state.toBeAddedFilter){
            let tempFilter = copyObjectValues(JSON.parse(filter));
            let toBeAdded = _this.state.toBeAddedFilter;
            tempFilter.filter.push(toBeAdded[0]);
            filter = JSON.stringify(tempFilter);
        }

        let lastSelectedPeriod = periods[periods.length - 1];
        let builtPeriods = _this.props?.periodsStatusState?.actuallyBuiltPeriods?.map(m => m.label);
        let rollingPeriod = getLastBuiltPeriodForSegments(builtPeriods, lastSelectedPeriod, 12);
        let periodRange = _this.state.periodRange || _this.props?.periodRange;
        let stackConf = _this.state.stackConfigObj;
        let profitFormat = _this.props?.history?.location?.state?.profitFormat;
        var query = {
            action: "getProfitStackByRangeData",
            scenario_id: _this.props?.scenarioState?.scenario || _this.props.scenario,
            type: ALL_WIDGETS.FIELDS.STACKS_MOM,
            currentPeriods: periods[0],
            filter: filter,
            quarter: periodsObj.quarter+(periodsObj.months && periodsObj.months !== FY_VALUES.M3? periodsObj.months :""),
            quadrantFilterDisabled: _this.state.quadrantFilterDisabled,
            entities: _this.state.selectedEntities? _this.state.selectedEntities.filter(ee=>ee && Object.keys(ee).length>0):[],
            drillVector: _this.state.selectedEntities && !_this.state.isSecondDimension ? _this.state.selectedEntities[0].tier : "",
            view_id: customStack,
            viewLabel: viewLabel,
            rollingPeriod: rollingPeriod || (this.props?.clientPeriodsState?.period?.value) || (this.props?.period && this.props?.period.value) || (this.props?.periodsStatusState?.actuallyBuiltPeriods && this.props?.periodsStatusState?.actuallyBuiltPeriods[0]?.value) || "",
            rollingSegment: ROLLING_SEGMENTS.R_12,
            profitFormat: profitFormat,
            selectedPeriod: periodsObj.periods[periodsObj.periods.length - 1],
            stackConfigObj: stackConf && (_this.state.stackConfigApplied ||  _this.state.isDefaultStackConfigObj)
                ? {
                    psl: !stackConf?.hidePsl? stackConf.psl:undefined,
                    amountType: !!stackConf.psl ? MOM_CONFIGURE_TOGGLE_TABS[0].value : stackConf?.amountType,
                    firstAttribute: stackConf?.firstAttribute ? stackConf.firstAttribute?.value: undefined,
                    secondAttribute: stackConf?.secondAttribute ? stackConf.secondAttribute?.value : undefined,
                    isFirstMedian: stackConf?.isFirstMedian,
                    isSecondMedian: stackConf?.isSecondMedian
                } :
                {
                    psl: !_this.props.isDashBoards && !_this.props.isFromDashboards && !_this.state.defaultStackConfigObj?.hidePsl? _this.state.defaultStackConfigObj.psl:undefined,
                    amountType: _this.props.isDashBoards || _this.props.isFromDashboards ? MOM_CONFIGURE_TOGGLE_TABS[0].value : _this.state.defaultStackConfigObj?.amountType,
                    firstAttribute:  !_this.props.isDashBoards && !_this.props.isFromDashboards && _this.state.defaultStackConfigObj?.firstAttribute ? _this.state.defaultStackConfigObj.firstAttribute?.value: undefined,
                    secondAttribute: !_this.props.isDashBoards && !_this.props.isFromDashboards && _this.state.defaultStackConfigObj?.secondAttribute ? _this.state.defaultStackConfigObj.secondAttribute?.value : undefined,
                    isFirstMedian: !_this.props.isDashBoards && !_this.props.isFromDashboards && _this.state.defaultStackConfigObj?.isFirstMedian,
                    isSecondMedian: !_this.props.isDashBoards && !_this.props.isFromDashboards && _this.state.defaultStackConfigObj?.isSecondMedian
                }
        }
        if (this.props.isWidgetProfitStacks) {
            let arr = copyObjectValues(this.props.checkedPsLines).map(function(e){return e.value})
            query.checkedLines = arr.join(',');
        }
        var onThenCallback = (data)=>{
            if (data) {
                let tempState = {}
                let hasInvalidPSSAccess = !!data.ERROR;
                let checkedLines = _this.props.isWidgetProfitStacks ? _this.props.checkedPsLines : _this.state.selectedNodes || _this.props.checkedPsLines;
                if (checkedLines) {
                    checkedLines.map(function(item){item.checked = true});
                }
                if (!hasInvalidPSSAccess &&  checkedLines) {
                    let pssLines = linearizeHierarchy(data.data, PS_MAPPING.FIELDS.CHILDREN).map(function(item){return item.returnname})
                    let lines = checkedLines.map(function(item){return item.value})
                    hasInvalidPSSAccess = _this.checkInvalidCheckedLines(pssLines,lines);
                }
                let hasInvalidAccess = _this.props?.checkForLimitAccessMessage(data, _this.sentRequests === 0);
                if(hasInvalidAccess || (hasInvalidPSSAccess && checkedLines) || (_this.props.has_access !== undefined && _this.props.has_access === false)) {
                    _this.setState({
                        profitStackTableData: [],
                        dataId: shortid.generate(),
                        chartLines: []
                    })
                    _this.setDisabledParentOptions(true);

                    if(typeof _this.props.handleAPIError==="function"){
                        _this.props?.handleAPIError(lang.dashboards.messages.no_permission);
                    } else{
                        this.setWarningDialogOpen(true, lang.manage_stacks.no_permission)
                    }
                    return;
                }
                var columns = data.columns;
                if (columns && columns.length > 0) {
                    columns.forEach(col => {
                        if(col.columns) {
                            col.columns.forEach(c => {
                                if(c.field !== VECTOR_ANALYSIS.FIELDS.NAME) {
                                    c.title = capitalizeFirstLetter(c.title);
                                } else {
                                    c.cssClass = "leftAlign name-field";
                                }
                                c.dontFilter = true;
                                c.headerSort = false;
                            });
                            if (col.title === PSL_LINES) {
                                col.cssClass="fit-header-yoy"
                            }
                        } else {
                            col.dontFilter = true;
                            col.headerSort = false;
                            if(col.field && col.field === VECTOR_ANALYSIS.FIELDS.LEVEL) {
                                col.visible = false
                            }
                            if (col.title === PSL_LINES) {
                                col.cssClass = "leftAlign name-field" +  (periodRange === time_range_values.periods ? " static-periods-width" : "" );
                            }
                        }
                    });

                    tempState = {
                        dataId: shortid.generate(),
                        columns: columns,
                        profitStackTableData: data.data,
                        mediansData: data.medians,
                        mediansDataByPeriod: data.medians_by_period,
                    }
                }
                tempState.selectedNodes = (!_this.state.chartSelectedNodes || _this.state.chartSelectedNodes.length === 0) ? (checkedLines) : _this.state.chartSelectedNodes //|| (checkedLines) || []
                tempState.chartSelectedNodes = tempState.selectedNodes;

                tempState.isYOY = !this.props.isYTD && !this.props.isMoM && [time_range_values.ytd, time_range_values.qtd].includes(_this.props?.periodRange);
                tempState.firstParentCH = undefined;
                tempState.firstAttributeC = undefined;
                tempState.secondAttributeCH = undefined;
                tempState.dataOrderer = data.orderer;
                if(data.firstLevelPsl) {
                    let pslOptions = _this.formatPslLines(data.firstLevelPsl);
                    if (tempState.selectedNodes && tempState.selectedNodes.length > 0) {
                        let checkedItems = tempState.selectedNodes.map(function(item){return item.value});
                        let chartColors = copyObjectValues(_this.state.chartColors)
                        chartColors.forEach(color => color.taken = false);
                        pslOptions = _this.checkItems(pslOptions, checkedItems, chartColors);
                        linearizeHierarchy(pslOptions).filter(f => f.color).forEach(opt => tempState.selectedNodes.find(f => f.value === opt.value).color = opt.color); // assign the color to the psl
                    } else {
                        pslOptions.filter(line => line.value === data.orderer).forEach(psl => {psl.checked = true; psl.color = _this.state.chartColors[0].color}); // set checked psl lines
                        tempState.chartColors = this.initializeChartColors(); // to set the first color as taken when assignong it for the default selected psl
                        tempState.checkedPsLines = pslOptions;
                        tempState.selectedNodes = pslOptions.filter(line => line.value === data.orderer);
                    }
                    tempState.pslOptions = pslOptions;
                    if(!_this.props.isDashBoards) { // commented because we are changing axes name in updateChart()
                        // let amountType = _this.state?.stackConfigObj?.amountType || MOM_CONFIGURE_TOGGLE_TABS[0].value;
                        // let selectedToggle = MOM_CONFIGURE_TOGGLE_TABS.find(e=>e.value === amountType);
                        // let isPercentage = selectedToggle?.value === MOM_CONFIGURE_TOGGLE_TABS[1].value;
                        // let sign = selectedToggle.sign;
                        // tempState.chartAxes.y.title = AMOUNT_TITLE + (isPercentage ? " percentage" : "") + " (" + sign + ")";
                      }

                }
            
                tempState.vectorCountData = data.vectorCount; // this is the data that gets the selected vector count for each period in MoM

                let yearOptions = _this.getYearOptions();
                tempState.yearOptions = tempState.isYOY ? yearOptions : [];
                tempState.checkedYears = tempState.yearOptions;     //setting both years as selected
                //setting ps line received from API checked by default, or all lines to be checked
                tempState.checkedPsLines = tempState.pslOptions?.filter(line => line.value === data.orderer);
                let period = _this.props?.clientPeriodsState?.period?.value || _this.props?.period.value;
                let periods = _this.props?.clientPeriodsState?.periods ||_this.props?.periods;
                //updating chart lines as well
                let chartData = [];
                let periodNumber = getPeriodNumber(period);
                let periodYear = getPeriodYear(period);
                let firstPeriod = undefined;
                if ([time_range_values.qtd].includes(periodRange)){ // qtd start from first period of current quarter of selected period
                    let quarter = (_this.props?.clientPeriodsState?.period?.quarter) || _this.props?.period.quarter
                    var periodsOfCurrentQuarter = periods.filter(e=>e[PERIOD.QUARTER] === quarter && getPeriodYear(e.value) === getPeriodYear(period));
                    firstPeriod = getPeriodNumber(periodsOfCurrentQuarter[periodsOfCurrentQuarter.length-1].value);
                }
                chartData = checkedLines ? _this.formChartData(periodNumber, periodYear,period, firstPeriod,tempState, [], data.data, true) : _this.formChartData(periodNumber, periodYear, period, firstPeriod,tempState,[], data.data)

                tempState.chartData = chartData;
                typeof _this.props.loaderCallback === 'function' && _this.props.loaderCallback(false);
                _this.setDisabledParentOptions(tempState.profitStackTableData?.length === 0);

                tempState.selectedNodes ? _this.setState(tempState, function()
                    {
                        _this.updateChartData(tempState.selectedNodes,tempState.checkedYears)
                    })
                    :  _this.setState(tempState, _this.updateChartData);
            }
        }

        var fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getProfitStackByRangeData",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: !this.props.isDashBoards && !this.props.showGreyOverLay,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_STACK,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.screenName]: this.props.isDashBoards || this.props.isFromDashboards ? lang.observability.output.dashboards.screen_name : this.props.isRange ? lang.observability.output.total_stacks.screen_name: this.props.isYOY ? lang.observability.output.yoy_stacks.screen_name : this.props.isMoM ? lang.observability.output.mom_stacks.screen_name : "",
            [FETCHAPI_PARAMS.requestDescription]: (this.props.isRange ? lang.observability.output.total_stacks.requests_description.chart: this.props.isYOY ? lang.observability.output.yoy_stacks.requests_description.chart : this.props.isMoM ? lang.observability.output.mom_stacks.requests_description.apply : "") + (this.props.isDashBoards || this.props.isFromDashboards ? lang.observability.output.dashboards.widget : ""),
            [FETCHAPI_PARAMS.periods]:  periods[0],
            [FETCHAPI_PARAMS.signal]: this.controller.signal

        }

        _this.setState({
          stackConfigObj: stackConf && (_this.state.stackConfigApplied ||  _this.state.isDefaultStackConfigObj)? stackConf : _this.state.defaultStackConfigObj,
          currStackConfigObj: stackConf && (_this.state.stackConfigApplied ||  _this.state.isDefaultStackConfigObj)? stackConf : _this.state.defaultStackConfigObj
        });
        //if there is a tracking obj in the session, action is two_dimension_heatmap, else generate_heatmap
        fetchAPI(fetchOptions);
    }

    updateAllDataChecked = (arr, isChecked) => {
        let _this = this;
        arr.forEach((row) => {
          row.checked = isChecked;
          if (!row.children) {
            return;
          }
          _this.updateAllDataChecked(row.children, isChecked);
        });
        return arr;
      };


    resetChartSelection=()=>{
        let _this = this;
        let pslOptions = _this.updateAllDataChecked(_this.state.pslOptions , false);
        pslOptions.filter(line => line.value === _this.state.dataOrderer).map(m => m.checked = true); // set checked psl lines        
        _this.setState({
            pslOptions: pslOptions,
            selectedNodes: pslOptions.filter(line => line.value === _this.state.dataOrderer)
        },function(){
            _this.updateChartData(pslOptions.filter(e=>e.checked));
        })
    }

    /**
     * 
     * @param {*} stack 
     * @param {*} scenario 
     * @param {*} fromReset 
     * @param {*} previousStackConfigObj 
     * @param {*} previousChartConfigObj 
     * @param {*} ignorGrayOverlay 
     * @param {Boolean} isReportChanged this is true when we change from yoy to ytd. Because when changing, we need to empty selectedNodes
     * @returns 
     */
    getProfitStackHierarchy = (stack, scenario, fromReset, previousStackConfigObj, previousChartConfigObj, ignorGrayOverlay, isReportChanged) => {
        const _this = this;
        let shouldReadNextPSView = _this.props.isFromDashboards && !_this.state.screenLoaded;
        let isExtended = _this.state.originPathName && [ALL_REPORTS.LIST_TOP, ALL_REPORTS.HEATMAP, ALL_REPORTS.CONTOUR_MAP, MENU_ITEM.FIELDS.PROFIT_LANDSCAPE, ALL_WIDGETS.GEOGRAPHY_CONCENTRATION].includes(_this.state.originPathName) && this.state.defaultScreen;
        let isPreview = _this.state.originPathName === ALL_REPORTS.MANAGE_STACKS && this.state.defaultScreen;
        let selectedStack = isPreview && !ignorGrayOverlay? _this.props.history.location.state.stack.custom_stack_id : isExtended || shouldReadNextPSView? _this.state.nextProfitStackViewId : stack;
        if(isExtended || (isPreview && !ignorGrayOverlay)) {
            this.props.setShowGreyOverLay(false);
        }
        let selectedScenario = isExtended ? _this.props?.scenarioState?.scenario : scenario;

        let currentSelectedStack = selectedStack !== undefined? selectedStack : ((_this.props?.PSViewsState?.nextProfitStackViewId !== undefined? _this.props?.PSViewsState?.nextProfitStackViewId : _this.state.nextProfitStackViewId) || 0);
        if(_this.pageComponent && _this.pageComponent.state.showConfigureDialog) {
            _this.pageComponent.setState({showConfigureDialog: false})
        }
        if(!_this.props.isRange && !_this.props.isMoM) {
            if(!scenario || isReportChanged){
                _this.setState({
                    selectedNodes:[],
                    chartSelectedNodes:[],
                })
            }
            return;
        }

        let query = {
            action: "getCostHierarchyByStack",
            scenario_id: selectedScenario || this.props?.scenarioState?.scenario,
            view_id: currentSelectedStack
        }
        const onThenCallback = (data)=>{
            if (data) {
                if(data.no_access_message){
                  _this.getProfitStackHierarchy(0,scenario, undefined, undefined, undefined, true);
                  _this.props.setShowGreyOverLay(true);   
                }
                let dataFromStore = getItemFromStore("profitStackByCostHierarchy",(selectedScenario || _this.state.scenario) + "_" + currentSelectedStack);
                if (_this.props.dispatch && !dataFromStore) {
                    _this.props.dispatch(updateProfitstackHierarchyByStack([copyObjectValues(data)], (selectedScenario || _this.state.scenario) + "_" + (currentSelectedStack)))
                }
                let pslOptions = _this.formatPslLines(data.firstLevelPsl)

                let attributes = _this.formatPslLines(data.attributes)
                let firstAttributeCH = attributes.length > 0 ? attributes.find(f => f.isCount) : attributes[0];
                let secondAttributeCH = _this.props.vectorState?.vectorOptions?.filter(v => !v.isGroupTitle)[0]; //We set the default value to be the first vector in vectorOptions
                
                let stackConfigObj = {
                    psl: !_this.props.isMoM && pslOptions && pslOptions.length > 0 ? pslOptions[0].value : undefined,
                    firstAttribute: _this.props.isMoM ? undefined : firstAttributeCH,
                    secondAttribute: _this.props.isMoM ? undefined : secondAttributeCH,
                    amountType: AMOUNT,
                    isFirstMedian: false,
                    isSecondMedian: false
                }
                if(this.props.history?.location?.state?.isRedirectionFromBubble){
                    stackConfigObj.hideFirstAttribute = true;
                    stackConfigObj.hideSecondAttribute = true;
                }
                let tempState={};
                if(!scenario){
                    tempState.selectedNodes = [];
                    tempState.chartSelectedNodes = [];
                }
                tempState.firstLevelData = data.firstLevelPsl;
                tempState.stackConfigObj = fromReset? previousStackConfigObj: stackConfigObj;
                tempState.currStackConfigObj = tempState.stackConfigObj;
                tempState.defaultStackConfigObj = fromReset? previousStackConfigObj: stackConfigObj;
                if(fromReset){
                    tempState.nextProfitStackViewId = _this.state.profitStackViewId;
                    pslOptions.filter(line => line.value === _this.state.dataOrderer).map(m => m.checked = true);
                    tempState.selectedNodes = pslOptions.filter(line => line.value === _this.state.dataOrderer);
                    tempState.chartSelectedNodes = tempState.selectedNodes;
                }
                tempState.pslOptions = pslOptions;
                tempState.isDefaultStackConfigObj = true;
                tempState.goPressed = !!fromReset;
                tempState.originalChartType = AMOUNT;
                tempState.attributes = attributes;
                tempState.defaultScreen = false;
                tempState.screenLoaded = true;

                tempState.disableMed = currentSelectedStack !== 0 ; // to disable MED for custom stacks
                _this.setState(tempState, () => {
                    if(isExtended || isPreview) {
                        this.go(currentSelectedStack);
                    }
                });
            }
        }

        const fetchOptions = {
            [FETCHAPI_PARAMS.funcName]: "getProfitStackHierarchy",
            [FETCHAPI_PARAMS.requestType]: FETCHAPI_PARAMS.requestTypeValues.data,
            [FETCHAPI_PARAMS.showLoader]: true,
            [FETCHAPI_PARAMS.path]: API_URL.PROFIT_STACK,
            [FETCHAPI_PARAMS.method]: FETCH_METHOD.POST,
            [FETCHAPI_PARAMS.query]: query,
            [FETCHAPI_PARAMS.onThenCallback]: onThenCallback,
            [FETCHAPI_PARAMS.useStore]: true,
            [FETCHAPI_PARAMS.dataKey]: "profitStackByCostHierarchy",
            [FETCHAPI_PARAMS.scenarioId]: (selectedScenario || _this.props?.scenarioState?.scenario) + "_" + currentSelectedStack,
            [FETCHAPI_PARAMS.requestDescription]: lang.observability.output.configurations.get_cost_hierarchy,
            [FETCHAPI_PARAMS.screenName]: getOutputScreenName(window.location.href),
        }

        let comp = {props: this.props};
        fetchAPI(fetchOptions, comp);
    }

    formChartData = (periodNumber, periodYear,period, firstPeriod, tempState, chartData=[],data, recursion=true)=> {
        let _this = this;
        _this.computePeriods(periodNumber, periodYear, period, firstPeriod).forEach(period=>{
            let dataPoint = {[PERIOD.PERIOD_NAME]: tempState.isYOY ? getPeriodNumber(period, true) : period};
            let previousPeriod = getPeriodYear(period) - 1 + getPeriodNumber(period, true);
            _this.computeChartData(data,tempState, recursion, period, [], dataPoint, previousPeriod, linearizeHierarchy(copyObjectValues(data), "children"));
            chartData.push(dataPoint);
        });
        
        return chartData;
    }

    computeChartData = (data,tempState, recursion, period, chartData, dataPoint, previousPeriod, linearizedData)=> {
        let _this = this;
        for (var row in data) {
            if (tempState.isYOY) {
                dataPoint[data[row][PSS.RETURN_NAME] +"_"+ getPeriodYear(previousPeriod)] = data[row][comparison_suffixes.comparison + previousPeriod] || 0;
                dataPoint[data[row][PSS.RETURN_NAME] +"_"+ getPeriodYear(period)] = data[row][comparison_suffixes.selection + period] || 0;
            } else if (_this.props.isRange && _this.state.selectedEntities && _this.state.selectedEntities.length > 1) {
                // if coming from List and selected entities are > 1
                tempState.checkedEntitiesWithUniqueKey?.forEach(entity => {
                    dataPoint[data[row][PSS.RETURN_NAME] + "_" + (entity[_uniqueKey])] = data[row][entity[_uniqueKey] + comparison_suffixes.selection + period] || 0;
                });
            } else {
                // if psl is calculated, we set its value to 0 if in configure we select per attribute or per vector
                let isNullified = [costtype.calculated].includes(data[row].costtype) && data[row].format === FormatTypes.PERCENTAGE;

                if (_this.props.isMoM && _this.state.currStackConfigObj?.psl) {
                  let value = parseFloat((data[row][comparison_suffixes.selection + period]? data[row][comparison_suffixes.selection + period].toFixed(2) : 0));
                  let psl = linearizedData?.filter(e=>e.returnname === _this.state.currStackConfigObj.psl);
                  let pslPercValue = parseFloat((psl[0][comparison_suffixes.selection + period] ?psl[0][comparison_suffixes.selection + period].toFixed(2) : 0));
                  dataPoint[data[row][PSS.RETURN_NAME]] = isNullified ? 0 : (value/pslPercValue)*100;
                } else if (_this.props.isMoM && _this.state.currStackConfigObj?.firstAttribute) {
                  let attribute = _this.state.currStackConfigObj?.firstAttribute.value;
                  let mediansDataByPeriod = tempState.mediansDataByPeriod;

                  if(_this.state.currStackConfigObj?.isFirstMedian) {
                    dataPoint[data[row][PSS.RETURN_NAME]] = isNullified ? 0 : (mediansDataByPeriod[attribute + "_A_" + period] && mediansDataByPeriod[attribute + "_A_" + period][data[row][PSS.RETURN_NAME]]);
                  } else {
                    let value = parseFloat((data[row][comparison_suffixes.selection + period]? data[row][comparison_suffixes.selection + period].toFixed(2) : 0));
                    let selectedAttrVal = linearizedData?.find(e=>e.returnname === attribute)[comparison_suffixes.selection + period];
                    dataPoint[data[row][PSS.RETURN_NAME]] = isNullified ? 0 : value/selectedAttrVal;
                  }
                } else if (_this.props.isMoM && _this.state.currStackConfigObj?.secondAttribute) {
                  if(_this.state.currStackConfigObj?.isSecondMedian){
                    let mediansDataByPeriod = tempState.mediansDataByPeriod;
                    let attribute = _this.state.currStackConfigObj?.secondAttribute.value;
                    dataPoint[data[row][PSS.RETURN_NAME]] = isNullified ? 0 : (mediansDataByPeriod[attribute + "_A_" + period] && mediansDataByPeriod[attribute + "_A_" + period][data[row][PSS.RETURN_NAME]]);
                  }else{
                    let value = parseFloat((data[row][comparison_suffixes.selection + period]? data[row][comparison_suffixes.selection + period].toFixed(2) : 0));
                    let vectorCount = tempState.vectorCountData?.find(f => f.period === period)?.count
                    dataPoint[data[row][PSS.RETURN_NAME]] = isNullified ? 0 : value/vectorCount;
                  }
                } else {
                    dataPoint[data[row][PSS.RETURN_NAME]] = parseFloat((data[row][comparison_suffixes.selection + period]? data[row][comparison_suffixes.selection + period].toFixed(2) : 0));
                }
            }
            if (recursion && data[row][PS_MAPPING.FIELDS.CHILDREN] && data[row][PS_MAPPING.FIELDS.CHILDREN].length > 0) {
                _this.computeChartData(data[row][PS_MAPPING.FIELDS.CHILDREN], tempState, recursion, period,chartData,dataPoint, previousPeriod, linearizedData);
            }
        }
    }

    updateChartData = (checkedChartPsLines=this.state.checkedChartPsLines, checkedYears=this.state.checkedYears, checkedEntities=this.state.checkedEntities) => {
        const _this = this;
        let chartLines = [];
        let config = _this.props.isRange? _this.state.chartConfigObj: _this.state.stackConfigObj;
        let sign = checkedChartPsLines.length === 1 && checkedChartPsLines[0].format === FormatTypes.PERCENTAGE? CONFIGURE_TOGGLE_TABS[1].label : CONFIGURE_TOGGLE_TABS[0].label;
        sign = handleCurrency(sign);
        checkedChartPsLines.forEach(psl=>{
            if(!psl.format &&  _this.props.isRange) {
                let psLineOption = _this.state.chartPslOptions.filter(line=>line.value === psl.value)[0];
                if(!!psLineOption) {
                    psl.format = config && (config.amountType === CONFIGURE_TOGGLE_TABS[1].value ||
                        config.amountType === CONFIGURE_TOGGLE_TABS[2].value) ?  FormatTypes.PERCENTAGE : psLineOption.format;       //this might occur when checked lines are received from props without format
                }
            }

            if(_this.props.isMoM && !_this.props.isDashBoards) {
              const AVG = AVG_MED_TOGGLE_TABS[0].label;
              const MED = AVG_MED_TOGGLE_TABS[1].label;
              let selectedToggle = MOM_CONFIGURE_TOGGLE_TABS.find(e=>e.value === config.amountType);
              let isPercentage = !!config.psl || selectedToggle?.value === MOM_CONFIGURE_TOGGLE_TABS[1].value || psl.defaultFormat === FormatTypes.PERCENTAGE;
              // let isFormatRatio = !!config.firstAttribute || !!config.secondAttribute;
              sign = config.psl ? MOM_CONFIGURE_TOGGLE_TABS[1].sign : selectedToggle?.sign || MOM_CONFIGURE_TOGGLE_TABS[0].sign;
              sign = handleCurrency(sign);
              let yTitle = "Amount" + (isPercentage ? " percentage" : "") + " (" + sign + ")";
              yTitle = config.psl ? "% of " + linearizeHierarchy(this.state.pslOptions, "children")?.find(e=>e.value === config.psl)?.label : yTitle;
              yTitle = config.firstAttribute ? handleCurrency(FIRST_ATTRIBUTE.label).trim().replace("[selected attribute]", Inflector.singularize(config.firstAttribute?.label)) + " (" + (config.isFirstMedian ? MED : AVG) + ")" : yTitle;
              yTitle = config.secondAttribute ? handleCurrency(VECTOR_ATTRIBUTE.label).trim().replace("[selected vector]", Inflector.singularize(config.secondAttribute?.label)) + " (" + (config.isSecondMedian ? MED : AVG) + ")" : yTitle;
              this.state.chartAxes.y.title = yTitle;
              psl.format = isPercentage ? FormatTypes.PERCENTAGE : (selectedToggle?.value || MOM_CONFIGURE_TOGGLE_TABS[0].value);
            }

            if(_this.state.isYOY) {
                checkedYears.forEach(year=>{
                    let balloonData = [year.value, psl.label];
                    psl.color = year.color;
                    chartLines.push({ field: psl.value +"_"+ year.value, title: year.label, legendTitle: year.label, color: year.color, bulletShape: "round", format: psl.format, balloonData: balloonData});
                });
                if(!_this.props.isDashBoards) {
                    _this.state.chartAxes.y.title = checkedChartPsLines?.length ? checkedChartPsLines[0]?.label + " ("+sign+")" : "";
                }
            } else {
                if(_this.props.isYTD){
                    this.state.chartAxes.y.title = "Amount" + " ("+ sign +")";
                }
                
                if(_this.props.isRange && _this.state.selectedEntities && _this.state.selectedEntities.length > 1) {
                    checkedEntities.forEach(entity => {
                        let balloonData = [entity.key ? entity.value : entity.label, psl.label];
                        psl.color = entity.color;
                        chartLines.push({ field: psl.value + "_" + (entity[_uniqueKey]), title: entity.label, legendTitle: entity.label, color: entity.color, bulletShape: "round", format: psl.format, balloonData: balloonData});
                    });
                } else {
                    let balloonData = [psl.label];
                    chartLines.push({ field: psl.value, title: psl.label, legendTitle: psl.label, color: psl.color, bulletShape: "round", format: psl.format, balloonData: balloonData});
                    
                }
            }
        });

        if(_this.props.isRange){
            let amountType = config?.amountType || CONFIGURE_TOGGLE_TABS[0].value;
            let selectedToggle = CONFIGURE_TOGGLE_TABS.find(e=>e.value === amountType);
            let suffix = selectedToggle.label === CONFIGURE_TOGGLE_TABS[1].label? column_suffixes_values.per_selection.label : selectedToggle.label === CONFIGURE_TOGGLE_TABS[2].label?column_suffixes_values.per_total.label:"";
            sign = checkedChartPsLines.length === 1 && checkedChartPsLines[0].format === FormatTypes.PERCENTAGE? CONFIGURE_TOGGLE_TABS[1].label : selectedToggle.label === CONFIGURE_TOGGLE_TABS[0].label? CONFIGURE_TOGGLE_TABS[0].label : CONFIGURE_TOGGLE_TABS[1].label;
            sign = handleCurrency(sign);
            let axeName = _this.state.selectedEntities && _this.state.selectedEntities.length > 1 ? checkedChartPsLines?.filter(f => f.checked)[0]?.label : "Amount";
            this.state.chartAxes.y.title = axeName + suffix + " (" + sign + ")";
        }
        let chartData = _this.state.chartData;
        this.setState({
            checkedChartPsLines: checkedChartPsLines,
            checkedYears: checkedYears,
            checkedEntities: checkedEntities,
            chartLines: chartLines,
            showChartConfigureDialog: false,
            chartData: chartData
        },()=>{
                let period = _this.props?.clientPeriodsState?.period?.value || _this.props?.period.value;
                let periodNumber = getPeriodNumber(period);
                let periodYear = getPeriodYear(period);
                let firstPeriod = undefined;
                if (this.props.isRange) {
                    chartData =  _this.formChartData(periodNumber, periodYear, period, firstPeriod,_this.state,[], _this.state.originalChartData)
                    _this.setState({
                        chartData: chartData
                    })
                }
        });
    }

    onChangeCheckedPsLine(option, isChecked) {
        let checkedPsLines = copyObjectValues(this.state.checkedPsLines);
        if(this.state.isYOY) {
            checkedPsLines = [];     //emptying the array (cannot have more than 1 value for radio buttons)
            checkedPsLines.push(option);
        } else {
            if(isChecked) {     //add the selected option
                checkedPsLines.push(option);
            } else {    //remove the unselected option
                checkedPsLines.splice(checkedPsLines.map(line=>line.value).indexOf(option.value), 1);
            }
        }

        this.updateChartData(checkedPsLines);
    }
    
    onChangeCheckedYears(option, isChecked) {
        let checkedYears = copyObjectValues(this.state.checkedYears);
        if(isChecked) {     //add the selected option
            checkedYears.push(option);
        } else {    //remove the unselected option
            checkedYears.splice(checkedYears.map(year=>year.value).indexOf(option.value), 1);
        }
        
        this.updateChartData(undefined, checkedYears);
    }

    onChangeCheckedEntities = (option, isChecked) => {
        let checkedEntities = copyObjectValues(this.state.checkedEntities);
        if(isChecked) {     //add the selected option
            checkedEntities.push(option);
        } else {    //remove the unselected option
            checkedEntities.splice(checkedEntities.map(year=>year.value).indexOf(option.value), 1);
        }
        
        this.updateChartData(undefined, undefined, checkedEntities);
    }

    getExportFilter = () => {
        let filter = typeof this.props.filterFinal === "string" ? JSON.parse(this.props.filterFinal) : this.props.filterFinal;
        filter =
          filter && filter.length
            ? filter.find((f) => !f.isBasicFilter)
              ? formatAdvancedFilter(
                  filter,
                  this.props.userSettingsState?.user?.user_allowed_vectors,
                  this.props.datasetState?.datasetOptions,
                  this.props.vectorState?.vectorOptions,
                  this.props.psLinesOptions
                )
              : formatBasicFilter(
                  filter,
                  this.props.userSettingsState?.user?.user_allowed_vectors
                )
            : undefined;
        return filter;
    }

    getDownloadFormatterParams() {
        var obj = this;
        let params = {}
        if(obj.props?.scenarioState?.scenarioObjects[0]){
            let filter = this.getExportFilter();
            let profitStackObj = this.props.PSViewsState?.profitStackViews.filter(e=>e.value === (this.props.PSViewsState.profitStackViewId || this.props.PSViewsState.nextProfitStackViewId || this.state.profitStackViewId || 0))[0];
            let areMultipleVectorsSelected = this.props?.history?.location?.state?.profitStackItems && Object.keys(this.props?.history?.location?.state.profitStackItems[0]).find(e=>e.includes(ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME));
            let isDrilling = this.props?.history?.location?.state?.drilling;
            params = {
                Report: this.props.history?.location?.state && this.props.history.location.state.isRedirectionFromBubble? this.props.history.location.state.bubbleReportTitle: 
                this.props.isMoM ? ALL_WIDGETS.FIELDS.MOM_STACKS :
                this.props.isYTD ? ALL_WIDGETS.FIELDS.YTD_STACKS : 
                this.props.isYOY ? ALL_WIDGETS.FIELDS.YOY_STACKS : 
                (this.props.isRange && this.state.isFromList && this.props.history?.location.state.drilling) ? (ALL_WIDGETS.FIELDS.ENTITY_STACKS + (areMultipleVectorsSelected && isDrilling?"": ` - ${this.state.drillTitle}`)) : 
                (this.props.isRange && this.state.isFromList) ? (ALL_WIDGETS.FIELDS.ENTITY_STACKS + (areMultipleVectorsSelected && isDrilling? "" :` - ${this.state.listTitle}`) ):
                (this.props.isRange && !this.state.isFromList) ? ALL_WIDGETS.FIELDS.TOTAL_STACKS : "Profit Stacks",
                User: obj.props.userSettingsState.user.first_name + " " + obj.props.userSettingsState.user.last_name,
                "Date Run": new Date().getDate() + '-' + getMonthName(new Date().getMonth()) + '-' + new Date().getFullYear(),
                "Scenario": "'" + obj.props?.scenarioState?.scenarioObjects[0]["scenario_number"],
                "Profit Stack": profitStackObj? profitStackObj.label:"",
                "Profit Stack Type": findOptionByKey(obj.props.periodRangeOptions, obj.props.periodRange).label,
                "Period": obj.props?.clientPeriodsState?.period?.value,
                "Filter": filter || "none"
            }  
            if (this.state.drilledPath && this.state.drilledPath.length > 0) {
                params["Drilled Parameters"] = this.state.drilledPath.join(",")
            }
        }
        const profitStackType = "Profit Stack Type";
        const period = "Period";
        if(this.props.isYTD || this.props.isRange || this.props.isMoM){
            delete params[profitStackType]
        }
        if(this.props.isRange || this.props.isMoM){
            if(obj.props?.clientPeriodsState?.periodOptions){
                let periodsCount = monthDiff(this.props?.periodsStatusState.customStartDate, this.props?.periodsStatusState.customEndDate) + 1;
                let periods = generatePeriods(this.props?.periodsStatusState.customStartDate, periodsCount);
                if (periods.length > 1) {
                    params["Start Date"] = periods[0];
                    params["End Date"]=  periods[periods.length - 1];
                    delete params[period]
                }else{
                    params["Start Date"]=  periods[0];
                    params["End Date"]=  periods[0];
                    delete params[period]
                }
            }
        }
        params = this.formatParams(params);
        return params;
    }
    formatParams = (params)=>{
        let data = []
        for(const property in params){
            let obj = {title:property,detail:params[property]}
            data.push(obj)
        }
        return data;
    }

    getExportOpts() {
        var exportOpts = {}
        if(this.props?.scenarioState?.scenarioObjects[0]){
            let period = this.generatePeriods();
            let startPeriod = "";
            let endPeriod = "";
            if(period){
                if(period[0].split(",").length>1){
                    startPeriod = period[0].split(",")[0].replaceAll("'","");
                    endPeriod = period[0].split(",")[period[0].split(",").length-1].replaceAll("'","");
                }else{
                    startPeriod = period[0];
                    endPeriod = period[0];
                }
                if(startPeriod===endPeriod){
                    period = startPeriod;
                }else{
                    period = startPeriod + " To " + endPeriod;
                }
            }
            exportOpts = {
                report: "Profit Stacks Table",
                dataset: this.props.isRange || this.props.isMoM ? period : this.props?.clientPeriodsState?.period?.value,
                user: this.props.userSettingsState.user.first_name +" " + this.props.userSettingsState.user.last_name,
                scenarioNumber: this.props?.scenarioState?.scenarioObjects[0]["scenario_number"],
                vector:"",
                dataStartRow: [time_range_values.periods, time_range_values.ytd].includes(this.props.periodRange) || this.props.isRange ? 1 :2,
                isProfitStack: this.props.isRange ? false : undefined
                //skipColumn: 1
            };
        }
        return exportOpts;
    }

    getSymbolsTooltipFormatter = (col,tooltipMessage,showTooltip = true) => {
        let div = document.createElement("div");
        let p = document.createElement("p");
        let colValue = col.getValue();

        p.innerHTML = col.getValue();

        if (colValue === VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL) {
            p.classList.add("uk-text-bolder", "fs-14", "text-blue");
        } else if (colValue === VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL) {
            p.classList.add("uk-text-bolder", "fs-14", "redText");
        } else if (colValue === VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL) {
            p.classList.add("uk-text-bolder", "fs-14", "black");
        }

        if (showTooltip){
            p.setAttribute("uk-tooltip", tooltipMessage);
        }

        div.appendChild(p);
        return div;
    }

    getToolTipMessage = (colValue, comparisonValue, obj) => {
        let tooltipMessage = "";

        if (comparisonValue === YEAR_OVER_YEAR || comparisonValue === PREVIOUS_ADJACENT) {
            if (colValue === VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL) {
                tooltipMessage = this.getLegendItems()[0].substring(3);
            } else if (colValue === VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL) {
                tooltipMessage = this.getLegendItems()[1].substring(3);
            }
        } else {
            if (colValue === VECTOR_ANALYSIS.FIELDS.ALPHA_SYMBOL) {
                if (comparisonValue === BP_QUADRANT_TIER && obj.state.isVectorAnalysis) {
                    tooltipMessage = "BP";
                } else if (comparisonValue === BP_QUADRANT_TIER && !obj.state.isVectorAnalysis) {
                    tooltipMessage = "BP QT";
                } else if (comparisonValue === TOP_QUADRANT) {
                    tooltipMessage = "IOP";
                } else if (comparisonValue === TOP_QUADRANT_TIER) {
                    tooltipMessage = "IOP:1";
                }
            } else if (colValue === VECTOR_ANALYSIS.FIELDS.BETA_SYMBOL) {
                tooltipMessage = this.getLegendItems()[1].substring(3);
            }
        }
        if (colValue === VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL){
            tooltipMessage = VECTOR_ANALYSIS.FIELDS.DELTA_FORMULA;
        }
        return tooltipMessage;
    }

    getLegendItems = () => {
        let _this = this;
        let legendItems = [];
        let periods = this.generatePeriods();

        if (periods !== undefined && periods.length !== 0) {
            let comparisonArray = periods[1].replaceAll("\'", "").split(",");
            let selectionArray = periods[0].replaceAll("\'", "").split(",");

            let comparisonValueText = "α: " + comparisonArray[0] + " to " + comparisonArray[comparisonArray.length - 1];
            let selectionValueText = "β: " + selectionArray[0] + " to " + selectionArray[selectionArray.length - 1];
            let value = _this.bridgeRef ? Number(_this.bridgeRef.state.chartData.filter(e => e.returnName.includes("selection_"))[0].value) - Number(_this.bridgeRef.state.chartData.filter(e => e.returnName.includes("comparison_"))[0].value) : ""
            let formattedValue = formatValString(value, FormatTypes.AMOUNT);
            let variance = VECTOR_ANALYSIS.FIELDS.DELTA_SYMBOL + " " + findOptionByKey(this.state.bridgeOptions, this.state.bridge)["label"] + ": " + formattedValue;

            legendItems.push(comparisonValueText);
            legendItems.push(selectionValueText);
            legendItems.push(variance);
        }

        return legendItems;
    }

    /**
     * toggle expanded chart view on expand button click
     */
    expandTable=()=>{
        this.setState({
            isChartExpanded: !this.state.isChartExpanded
        },function(){
            if(this.props.isDashBoards){
                $("#chart-container").show();
            }else{
                if(!this.state.isChartExpanded){
                    $("#chart-container").hide();
                    $("#chart-div").addClass("chart-margin-top");
                    $("#chart-div").removeClass("chart-margin-bottom")
                    $("#profit-stack-table").show();
                    if(this.props.isYTD || this.props.isYOY || this.props.isRange || this.props.isMoM){
                      $("#profit-stack-table").css('height', "94%");
                      $("#profit-stack-table").css('visibility', 'visible');
                      $("#chart-div").removeClass("half-screen-view");
                    }
                    if(this.props.isYOY) {
                      $("#profit_stack_comp_table .tabulator-tableHolder").css('height', "88%");
                    }
                    this.pageComponent.checkIfContainerHasOverflow();
                } else {
                    $("#chart-container").show();
                    $("#chart-div").removeClass("chart-margin-top")
                    $("#chart-div").addClass("chart-margin-bottom")
                    if (this.props.isYTD || this.props.isYOY || this.props.isRange || this.props.isMoM) {
                        $("#profit-stack-table").css('height', "45%");
                    }
                    $("#chart-div").addClass("half-screen-view");
                    if(this.props.isYOY) {
                      $("#profit_stack_comp_table .tabulator-tableHolder").css('height', "78%");
                    }
                    this.pageComponent.checkIfContainerHasOverflow();
                }
            }
        })
        if(this.state.isChartExpanded){
            this.setState({
                isChartFullScreen: false
            })
        }
    }
    /**
     * toggle full screen view on full screen button click
     */
    maximizeTable = () => {
        let _this = this;
        this.setState({
            isChartFullScreen:!this.state.isChartFullScreen,
            height: !this.state.isChartFullScreen ? $("#profit-stack-table").css('height') : this.state.height
        }, function () {
            if (_this.state.isChartFullScreen) {
                $("#profit-stack-table").css('visibility', 'hidden');
                $("#profit-stack-table").css('height', '0px');
                if(this.props.isYOY){
                    $("#chart-container").addClass("chart-container-zoom-YoY");
                    $("#chart-container").addClass("overflow-x-clip");
                    $("#chart-lines-div").addClass("chart-zoom");
                    $("#chart-lines-div").addClass("print-chart-zoom");
                }else{
                    $("#chart-container").addClass("chart-container-zoom");
                    $("#chart-container").addClass("overflow-x-clip");
                    $("#chart-lines-div").addClass("chart-zoom");
                    $("#chart-lines-div").addClass("print-chart-zoom");
                    $("#chart-div").removeClass("half-screen-view")
                    $(".range .chart-container-zoom ul.toggle-options-container .options-list").width("10vw");
                }
            } else {
                $("#chart-container").removeClass("chart-container-zoom-YoY");
                $("#chart-container").removeClass("chart-container-zoom");
                $("#chart-container").removeClass("overflow-x-clip");
                $("#chart-lines-div").removeClass("chart-zoom");
                $("#chart-lines-div").removeClass("print-chart-zoom");
                $("#profit-stack-table").css('visibility', 'visible');
                $("#profit-stack-table").css('height', "45%");
                $(".range .options-list").width("20.47vw");
                $("#chart-div").addClass("half-screen-view");

            } 
            if(_this.periodRef){
                _this.periodRef.refreshSearch();
            }
            if(document.getElementById("filterText")){
                document.getElementById("filterText").focus();
            }
        })
    }

    showChartConfigureDialog = () => {
        this.setState({
            showChartConfigureDialog: !this.state.showChartConfigureDialog
        })
    }

    setChartAmountToggleTab = (tab) => {
        let originalChartType = this.state.chartAmountType;
        this.setState({
            typeChanged:true,
            chartAmountType: tab,
            originalChartType: originalChartType,
            typeApplied: false
        })
    }

    formatPslLines = (pslLines) => {
        let _this = this;
        return pslLines?.map(psl => {
            let isCount = psl.name_in_fact ? psl.name_in_fact.toLowerCase().replaceAll(" ","").startsWith("count") : false
            if(psl.children){
                return {label: psl[PSS.NAME], value: psl[PSS.RETURN_NAME], color: undefined, format: psl.format, defaultFormat: psl.format, children: _this.formatPslLines(psl.children)};
            }else{
                return {label: psl[PSS.NAME], value: psl[PSS.RETURN_NAME], color: undefined, format: psl.format, isCount: isCount, defaultFormat: psl.format};
            }
        })
    }

    callbackFunk = (data, selectedTab) => {
        let _this = this;
        let unusedColors = [];
        let chartColors = _this.state.chartColors;
        let nodes = data;
        let chartPslOptions = _this.props.isRange ? _this.state.chartPslOptions : _this.state.pslOptions ;
        let tempSelectedNodes =  _this.state.tempChartSelectedNodes;

        let unselectedNodes = _this.state.checkedChartPsLines.filter(f => !data.some(s => s.value === f.value));
        unselectedNodes.forEach(f => {
            unusedColors.push(f.color);
            f.color = undefined; 
        });
        chartColors.filter(f => unusedColors.includes(f.color)).forEach(m => m.taken = false)
        
        nodes.forEach(node => {
            if(!node.color) {
                let color = chartColors.find(f => !f.taken) ? chartColors.find(f => !f.taken).color : "";
                node.color = color;
                if (chartColors.find(f => !f.taken)) {
                    chartColors.find(f => !f.taken).taken = true;
                } 
            }
        });

        // set the corresponding color for each psl
        chartPslOptions = _this.setColorsToPsl(chartPslOptions, nodes);
        if(tempSelectedNodes) {
            tempSelectedNodes = _this.setColorsToPsl(tempSelectedNodes, nodes);
        }

        if(selectedTab!==AMOUNT || this.state.typeChanged){
            let chartConfigObj = copyObjectValues(_this.state.chartConfigObj);
            chartConfigObj.amountType = selectedTab;
            _this.setState({
                chartAmountType: selectedTab,
                checkedChartPsLines: nodes,
                chartConfigObj: chartConfigObj,
                chartSelectedNodes: tempSelectedNodes,
                // stackConfigApplied: true,
                typeChanged:false,
                typeApplied: true,
                chartColors: chartColors,
                chartPslOptions: chartPslOptions
                // shouldResetAfter:false
            },function(){
                _this.fetchProfitStackByRangeChartData(false);
            });
        }else{
            _this.setState({
                chartSelectedNodes: tempSelectedNodes,
                chartColors: chartColors,
                chartPslOptions: chartPslOptions

            },function(){
                _this.updateChartData(nodes);
            })
        }
        _this.configDropdown.current.setConfigDropdownOpen(false);

    }

    onApplyConfigClick = (stackConfigObj, notApply) => {
        let _this = this;
        let defaultStackObj = _this.state.defaultStackConfigObj ? _this.state.defaultStackConfigObj : copyObjectValues(_this.state.stackConfigObj);
        this.setState({
            selectedNodes: _this.state.tempSelectedNodes,
            stackConfigObj: typeof notApply === 'boolean' && notApply ?_this.state.stackConfigObj : stackConfigObj,
            currStackConfigObj: stackConfigObj,
            defaultStackConfigObj:typeof notApply === 'object' || (typeof notApply === 'boolean' && !notApply) ? copyObjectValues(stackConfigObj) : defaultStackObj,
            stackConfigApplied: typeof notApply === 'boolean' && notApply ? false : true,
            // chartAmountType: stackConfigObj.amountType,
            isDefaultStackConfigObj : _this.state.goPressed && !notApply
        },function(){
            if (typeof notApply === 'object' || (typeof notApply === 'boolean' && !notApply)) {
                _this.pageComponent.setState({
                    showConfigureDialog: false
                })
                this.props.configDropdownStacksHeader.current.setConfigDropdownOpen(false);

            }
            if(_this.props.isMoM && typeof notApply === 'object' || (typeof notApply === 'boolean' && !notApply)){
                _this.props.setHeaderMsg(_this.getHeaderMsg(stackConfigObj));
                if (!_this.props.showGreyOverLay) {
                    _this.getProfitStackByConfigurationData()
                }
            }else if(_this.state.goPressed && typeof notApply === 'object' || (typeof notApply === 'boolean' && !notApply)){
                _this.fetchProfitStackByRangeData();
            }
        });
    }

    getHeaderMsg = (confObj) => {
      if(!!confObj.psl){
        return lang.you_are_viewing_percentage.replace("[PSL]", linearizeHierarchy(this.state.pslOptions,"children")?.find(e=>e.value === confObj.psl)?.label);
    } else if(!!confObj.firstAttribute) {
        let msg = lang.you_are_viewing_amounts_selected_attribute;
        msg = Inflector.singularize(msg.replace("[selected attribute]", confObj.firstAttribute?.label));
        msg = msg.replace("[average]", confObj.isFirstMedian ? AVG_MED_TOGGLE_TABS[1].title.toLowerCase() : AVG_MED_TOGGLE_TABS[0].title.toLowerCase());
        return msg;
      } else if(!!confObj.secondAttribute) {
        let msg = lang.you_are_viewing_amounts_selected_vector;
        msg = Inflector.singularize(msg.replace("[selected vector]", confObj.secondAttribute?.label));
        msg = msg.replace("[average]", confObj.isSecondMedian ? AVG_MED_TOGGLE_TABS[1].title.toLowerCase() : AVG_MED_TOGGLE_TABS[0].title.toLowerCase());
        return msg;
      } else if(confObj.amountType === MOM_CONFIGURE_TOGGLE_TABS[1].value) {
          return lang.you_are_viewing_amounts_percentage;
      }
      return undefined;
    }
    setChartSelectedNodes=(nodes)=>{
        let _this = this;
        if(this.props.isRange){
          _this.setState({
            tempChartSelectedNodes: nodes,
            typeApplied: false
          })
        }else{
          _this.setState({
            tempChartSelectedNodes: nodes
          });
        }
        // _this.state.shouldResetAfter = true;
        // _this.setState({
        //     selectedNodes: nodes
        // })
    }

    /**
     * when clicking on cancel or print inside the print window
     */
    afterPrint = () => {
        this.pageComponent.addBordersToCells();
    }

    printChart=()=>{
        $("#chart-container").addClass("chart-container-print");
        if($('.amcharts-chart-div')[0] && !$('.amcharts-chart-div')[0].childNodes[0].childNodes[11].classList.contains("noprint")){
            $('.amcharts-chart-div')[0].childNodes[0].childNodes[11].classList.add("noprint")
        }
        if(!this.state.isChartFullScreen) {
            $(".range .options-list").width("100%")
        }
        $(".chart-container-zoom").removeClass("overflow-x-clip");
        $(".chart-container-zoom-YoY").removeClass("overflow-x-clip");

        window.onafterprint = this.afterPrint;
        window.print();
        if(!this.state.isChartFullScreen) {
            $(".range .options-list").width("20.47vw")
        }
        $(".chart-container-zoom").addClass("overflow-x-clip");
        $(".chart-container-zoom-YoY").addClass("overflow-x-clip");
        $("#chart-container").removeClass("chart-container-print");

    }

    renderConfigureBody = () => {
      let _this = this;
        return(
          <div id="stack_configure_dialog" className={"noprint configure-dialog configure_body" + (this.props.isRange ? " dialog-width" : (this.props.isYTD || this.props.isMoM) ? " small-dialog-width" : "")/* +(this.state.showChartConfigureDialog? "":" uk-hidden")*/}>
              <StackChartConfiguration
                  ref={this.stackChartConfig}
                  configureChartWithoutTooltip={this.props.isYOY || this.props.isYTD || this.props.isMoM}
                  dropdownLabel={this.props.isYOY ? lang.dashboards.titles.profit_stack_line : lang.profit_stack_line}
                  dropdownPlaceholder={lang.heatmap_configure.select_ps_line}
                  dropdownData={this.props.isRange? this.state.chartPslOptions: this.state.pslOptions}
                  isMultiSelect={(this.props.isRange && !this.state.selectedEntities) || this.props.isYTD || this.props.isMoM || (this.props.isRange && this.state.selectedEntities && this.state.selectedEntities.length <= 1)}
                  multiSelectLimit={10}
                  toggleOptions={this.state.isFromList || this.state.isFromBubble ? CONFIGURE_TOGGLE_TABS : CONFIGURE_TOGGLE_TABS_NOT_LIST}
                  toggleDefaultValue={this.state.chartAmountType}
                  onToggleSelectTab={this.setChartAmountToggleTab}
                  hasToggleTabs={this.props.isRange}
                  primaryBtnLabel={"Apply"}
                  onPrimaryClick={this.callbackFunk}
                  setChartSelectedNodes={this.setChartSelectedNodes}
                  selectedNodes={(_this.props.location && _this.props.location.state.checkedPsLines) || (this.props.isRange? _this.state.chartSelectedNodes : _this.state.selectedNodes)}
                  isFromList={this.state.isFromList}
              />
          </div>
        )
    }

    setWarningDialogOpen = (isOpen, msg) => {
        let _this = this;
        _this.setState({
            openWarningDialog: isOpen,
            warningMsg: msg
        })
    }

    warningDialogActions = () => {
        let _this = this;
        return (
            <Button
                id="close-btn"
                label={lang.modal.buttons.ok}
                variant={BUTTON_VARIANT.SECONDARY}
                size={SIZES.DEFAULT}
                type={BUTTON_TYPE.DEFAULT}
                onBtnClick={() => _this.setWarningDialogOpen(false, "")}
            />
        )
    }

    render() {
        let _this = this
        // if(!this.isUserAuthenticated) {
        //     return (<div></div>)
        // }
        if($('.amcharts-chart-div')[0] && !$('.amcharts-chart-div')[0].childNodes[0].childNodes[11].classList.contains("noprint")){
            $('.amcharts-chart-div')[0].childNodes[0].childNodes[11].classList.add("uk-hidden")
        }
        
        // match colors of chart and right options
        let checkedChartPsLines = this.state.checkedChartPsLines;
        let chartLines = this.state.chartLines;
        if(this.props.isRange && chartLines) {
            chartLines.forEach(line => {
                if(checkedChartPsLines.find(f => f.value === line.field))
                    line.color = checkedChartPsLines.find(f => f.value === line.field).color
            })
        }

        var downloadFormatterParams = !this.props.hideTable && this.getDownloadFormatterParams();
        var exportOpts = !this.props.hideTable && this.getExportOpts();
        var params = this.getDownloadFormatterParams();
        let areMultipleVectorsSelected = this.props?.history?.location?.state?.profitStackItems && Object.keys(this.props.history.location.state.profitStackItems[0]).find(e=>e.includes(ENTITY_STACKS_OBJECT.VECTOR_MACHINE_NAME))
        let printComponents = [];
        printComponents.push(<h3 className={"uk-margin-xsmall-bottom"} key={shortid.generate()}>{findOptionByKey(this.props?.PSViewsState?.profitStackViews, this.props.PSViewsState?.nextProfitStackViewId || this.state.profitStackViewId || 0)?.label}</h3>);
        printComponents.push(
          <h4 className={"uk-margin-xsmall-bottom"} key={shortid.generate()}>
            {MANAGE_REPORT.TITLES.NAME}:
            {this.props.additionalProps?.isMoM
                ? ALL_WIDGETS.FIELDS.MOM_STACKS
              :this.props.additionalProps?.isYTD
              ? ALL_WIDGETS.FIELDS.YTD_STACKS
              : this.props.additionalProps?.isYOY
              ? ALL_WIDGETS.FIELDS.YOY_STACKS
              : this.props.additionalProps?.isTotal
              ? ALL_WIDGETS.FIELDS.TOTAL_STACKS
              : this.props.history?.location?.state?.bubbleReportTitle
              ? this.props.history.location.state.bubbleReportTitle
              : ALL_WIDGETS.FIELDS.ENTITY_STACKS + (areMultipleVectorsSelected && this.props.history?.location?.state?.drilling? "" : (" - " + (this.props?.history?.location?.state?.vectorName ?? findOptionByKey(this.props.vectorState?.vectorOptions, ((this.props.history.location.state.profitStackItems && this.props.history.location.state.profitStackItems[0]?.tier) || this.props.vectorState?.nextVectors[0])).label)))}
          </h4>
        );
        if (this.state.drilling) {
            printComponents.push(
                <h4 className={"uk-margin-xsmall-bottom"} key={shortid.generate()}>{MANAGE_REPORT.TITLES.DRILL_PARAMS}: {this.state.drilledPath.map(function (elem, i) {
                    return <h4 style={{ display: 'inline-flex', paddingRight: "5px" }} key={elem}>{elem}</h4>;
                })}
                </h4>);
        }
        if(this.props.isYOY){
            printComponents.push(<h4 className={"uk-margin-xsmall-bottom"} key={shortid.generate()}>{lang.header.titles.profit_stack_type}: {findOptionByKey(this.props.periodRangeOptions, this.props.periodRange).label}</h4>);
        }
        let filterText = "";
        // if($('.filter-display-filters') && $('.filter-display-filters')[0] && $('.filter-display-filters')[0].children){
        //     for( var e in $('.filter-display-filters')[0].children){
        //         filterText += $('.filter-display-filters')[0].children[e].title? $('.filter-display-filters')[0].children[e].title + " ":"";
        //     }
        //     if(filterText){
        //         printComponents.push(<h4 className={"uk-margin-xsmall-bottom"} key={shortid.generate()}>{lang.COMMON.CREATE_FILTER}: {filterText}</h4>);
        //     }
        // }
        if(this.props.filterFinal && tryParse(_this.props.filterFinal).length > 0){//////////////
            printComponents.push(<h4 className={"uk-margin-xsmall-bottom"} key={shortid.generate()}>{lang.COMMON.CREATE_FILTER}: {this.getExportFilter()}</h4>);
        }
        // if(this.state.isYOY && this.state.checkedPsLines[0]) {
        //     printComponents.push(<h4 className={"uk-margin-xsmall-bottom"} key={shortid.generate()}>{"Selected PS Line:"} {this.state.checkedPsLines[0].label}</h4>);
        // }
        let printHeader = !this.props.print && getProfitIsleHeader(printComponents);
        let hasBanner = this.props?.scenarioState?.scenarioObjects[0].scenario_status === SCENARIO_STATUS.REVIEW && this.props.isScenarioBannerVisible;
        let hasFilter = $(".border-third-header").height() !== undefined;
        let yoyClassWithBannerandFilter = hasBanner && hasFilter ? "yoy yoy-filter-with-banner" :"";
        let rangeClassWithBannerandFilter = hasBanner && hasFilter ? "range range-filter-banner" :"";
        let profitStackData = copyObjectValues(this.state.profitStackTableData)?.filter(f => f[PSS.COSTTYPE] !== costtype.attribute);
        
        return (
            <div style={{height:"100%"}} >
                {printHeader}
                {!this.props.hideTable &&
                    <div id="profit-stack-table" style={{height: "94%"}}
                         className={"noprint " + (this.props.isYTD ? "ytd" : this.props.isMoM ? "mom_stacks" : this.props.isYOY ? "yoy" : this.props.isEntity ? (this.state.isFromBubble ? " compare_stacks " : "") + "range entity-stacks-table" : "range")}>
                    <ProfitStackTabulator ref={r => this.pageComponent = r} data={profitStackData} columns={this.state.columns || []}
                        dataId={this.state.dataId} linearizedData={linearizeHierarchy(copyObjectValues(this.state.profitStackTableData),"children")}
                        tableId={"profit_stack_comp_table"} component={PROFIT_STACK}
                        showChartColors={this.props.periodRange === time_range_values.periods ? false : true}
                        downloadFormatterParams={downloadFormatterParams} exportOpts={exportOpts} yearOptions={this.state.yearOptions}
                        periodsList={this.props?.clientPeriodsState?.periods} isProfitStack = {true} currStackConfigObj = {this.state.currStackConfigObj || []}
                        costClassification={this.state.costClassification} glCosts={this.state.glCosts} hiddenVectors={this.state.hiddenVectors} metricFields={this.state.metricFields}
                        client_costcenter={this.state.client_costcenter} vectorOptions={this.props.vectorState?.vectorOptions}
                        getSymbolsTooltipFormatter={this.getSymbolsTooltipFormatter} scenarioObject={this.props?.scenarioState?.scenarioObjects[0]}
                        getToolTipMessage={this.getToolTipMessage}  firstParentCH={this.state.firstParentCH} showScenarioBanner={this.props.isScenarioBannerVisible}
                        firstAttributeCH={this.state.firstAttributeCH || {}} secondAttributeCH={this.state.secondAttributeCH || {}} isRange={this.props.isRange} isEntity={this.props.isEntity} isTotal={this.props.isTotal}
                        viewId={this.state.profitStackViewId} mediansData={this.state.mediansData} stackConfigObj={this.props.isYTD || this.props.isYOY ?undefined : this.state.stackConfigObj}
                        pslOptions={this.state.pslOptions} lenearizedPslOptions={linearizeHierarchy(this.state.pslOptions,"children")} attributes={this.state.attributes} 
                        onApplyConfigClick={this.onApplyConfigClick} changedConfiguration={_this.changedConfiguration.map(e=>e.diff).flat()}
                        isExtended={this.state.originPathName && [ALL_REPORTS.LIST_TOP, ALL_REPORTS.HEATMAP, ALL_REPORTS.CONTOUR_MAP, MENU_ITEM.FIELDS.PROFIT_LANDSCAPE, ALL_WIDGETS.GEOGRAPHY_CONCENTRATION].includes(this.state.originPathName)}
                        disableMed={this.state.disableMed} isDrilling={this.state.drilling} drilledPath={this.state.drilledPath} isFromList={this.state.isFromList} isFromBubble={this.state.isFromBubble}  checkedEntities={this.state.checkedEntities}
                        isYTD={this.props.isYTD} isMoM={this.props.isMoM} isYOY={this.props.isYOY} expandCollapseClick={this.props.expandCollapseClick}
                        vectorCountData={this.state.vectorCountData} isFromGeoOrPL={this.state.isFromBubble}
                        mediansDataByPeriod={this.state.mediansDataByPeriod} overwritePopupSize={this.props.overwritePopupSize}
                    /> 
                </div>
                }
                <div id="chart-div" className={!this.props.isDashBoards ? this.props.isRange ? "range chart-margin-top" : "chart-margin-top" : "uk-flex-between uk-margin-medium-top"}>
                    {!this.props.isDashBoards ?
                      <div id='expanded-chart-header' className="noprint uk-border drill-header-container uk-flex uk-flex-between ">
                        <div className="uk-flex uk-flex-middle">
                                {/* <i className={"circle-button bg-blue fs-14 far fa-chevron" + (this.state.isChartExpanded ? "-down" : "-up")} onClick={() => this.expandTable()}></i> */}
                                <Button
                                    variant={BUTTON_VARIANT.TERTIARY2}
                                    size={SIZES.ICON}
                                    type={BUTTON_TYPE.DEFAULT}
                                    leftIcon={<i className={"far fa-chevron" + (this.state.isChartExpanded ? "-down" : "-up")} />}
                                    onBtnClick={() => this.expandTable()}
                                />
                                {this.state.isChartExpanded ?
                                    <Button  
                                        variant={BUTTON_VARIANT.TERTIARY}
                                        size={SIZES.ICON}
                                        type={BUTTON_TYPE.DEFAULT}
                                        className={"uk-margin-xsmall-left"}
                                        leftIcon={<i className={"far fa-" + (!this.state.isChartFullScreen ? "expand" : "compress")} />}
                                        onBtnClick={()=>{this.maximizeTable()}} 
                                    />
                                    : ""}
                                <span className="uk-flex uk-padding-xxsmall-left">
                                    <b className="fs-14">{ALL_WIDGETS.FIELDS.TREND_CHART}</b>
                                </span>
                            </div>

                            {this.state.isChartExpanded ? 
                                <div className="uk-flex noprint align-items-center gap_between_buttons">
                                    <div className="uk-text-left">
                                        <div className={"uk-grid uk-grid-small uk-height-1-1"} uk-grid="">
                                            <Button  
                                                label={lang.print}
                                                variant={BUTTON_VARIANT.SECONDARY}
                                                size={SIZES.DEFAULT}
                                                type={BUTTON_TYPE.DEFAULT}
                                                className="noprint uk-display-inline-block uk-height-fit"
                                                onBtnClick={_this.printChart}
                                                leftIcon={<i className="fal fa-print" />}
                                            />
                                        </div>
                                    </div>
                                    <ButtonDropdown
                                        id={"stacks-configure-btn"}
                                        placeholder={lang.heatmap_configure.configure} 
                                        ref={this.configDropdown}
                                        className="configure-button" 
                                        // onBtnClick={this.showChartConfigureDialog}
                                        dropId={"stack_configure_dialog"}
                                        variant={BUTTON_DROPDOWN_VARIANT.BUTTON}
                                        size={SIZES.SMALL}
                                        firstAttr={"#button-drop-stacks-configure-btn"}
                                        secondAttr={"#stack_configure_dialog"}
                                        renderContainerBody={_this.renderConfigureBody}
                                        
                                    />
                                </div>
                                : ""
                            }
                        </div>
                        : ""}
                        {/* {this.state.showChartConfigureDialog? */}
                            {/* <div id="stack_configure_dialog" className={"noprint configure-dialog" + (this.props.isRange ? " dialog-width" : "")}> */}
                                {/* <StackChartConfiguration
                                    ref={this.stackChartConfig}
                                    configureChartWithoutTooltip={this.props.isYOY || this.props.isYTD}
                                    dropdownLabel={this.props.isYOY ? lang.dashboards.titles.profit_stack_line : lang.profit_stack_line}
                                    dropdownPlaceholder={lang.heatmap_configure.select_ps_line}
                                    dropdownData={this.props.isRange? this.state.chartPslOptions: this.state.pslOptions}
                                    isMultiSelect={(this.props.isRange && !this.state.selectedEntities) || this.props.isYTD || (this.props.isRange && this.state.selectedEntities && this.state.selectedEntities.length <= 1)}
                                    multiSelectLimit={10}
                                    toggleOptions={this.state.isFromList || this.state.isFromBubble ? CONFIGURE_TOGGLE_TABS : CONFIGURE_TOGGLE_TABS_NOT_LIST}
                                    toggleDefaultValue={this.state.chartAmountType}
                                    onToggleSelectTab={this.setChartAmountToggleTab}
                                    hasToggleTabs={this.props.isRange}
                                    primaryBtnLabel={"Apply"}
                                    onPrimaryClick={this.callbackFunk}
                                    setChartSelectedNodes={this.setChartSelectedNodes}
                                    selectedNodes={(_this.props.location && _this.props.location.state.checkedPsLines) || (this.props.isRange? _this.state.chartSelectedNodes : _this.state.selectedNodes)}
                                    isFromList={this.state.isFromList}
                                />
                            </div>
                            : ""
                        } */}
                        {this.state.isChartExpanded || this.props.isDashBoards?
                        <div id="chart-container" className={" line-chart-container print-justify-content-flex-start " + (this.props.isRange && this.state.selectedEntities && this.state.selectedEntities.length > 1 ? "place-content-center" : "justify-content-evenly") + (this.state.isChartFullScreen ? " uk-margin-default-top-bottom chart_lines_gap" : "")}>

                            {/* {!this.props.hideRightSection &&
                                <ToggleOptionsList isRadio={this.state.isYOY} className={this.state.isYOY ? "noprint" : ""}
                                    options={this.state.pslOptions} values={_this.state.checkedPsLines} onChange={this.onChangeCheckedPsLine} />
                            } */}
                            <div id="chart-lines-div" style={{ width: this.state.isChartFullScreen ? "100%" : "69%" }} className={"widget-line-chart-width print-chart-width " + (this.props.isDashBoards ? "uk-padding-remove " : " print-margin-none-left ") + (this.state.isChartFullScreen ? "" : "")}>
                                {!this.props.print && getPrintButton()}
                                {this.props.hideLegend !== undefined && !this.props.hideLegend ?
                                    <LineChart ref={el => this.lineChartRef = el} className={"uk-flex uk-flex-center"} refreshAxes={true} axes={this.state.chartAxes} lines={chartLines}
                                        data={this.state.chartData} bigNumbersMultipliers width={this.props.width} height={this.props.height} stackConfigObj={this.state.chartConfigObj} hideZoomBar={true}/>
                                    :<LineChart ref={el => this.lineChartRef = el} className={"uk-flex uk-flex-center"} refreshAxes={true} axes={this.state.chartAxes} lines={chartLines}
                                        data={this.state.chartData} hideLegend bigNumbersMultipliers width={this.props.width} height={this.props.height} stackConfigObj={this.state.chartConfigObj} hideZoomBar={true}/>
                                }
                            </div>
                            {(this.state.isYOY && !this.props.hideLeftSection) &&
                                <ToggleOptionsList options={this.state.yearOptions} values={this.state.checkedYears} onChange={this.onChangeCheckedYears} className={"print-margin-top " + (this.state.isChartFullScreen ? "print-chart-width" : "")} />
                            }
                            {(this.props.isRange && !this.props.hideLeftSection && this.state.selectedEntities && this.state.selectedEntities.length > 1) &&
                                <ToggleOptionsList options={this.state.selectedEntities} isChartFullScreen = {this.state.isChartFullScreen} values={this.state.checkedEntities} onChange={this.onChangeCheckedEntities} className={"print-margin-top "+(this.state.isChartFullScreen ? "print-chart-width" : "")} />
                            }
                            {!this.props.hideLeftSection && ((!this.props.isYOY && !this.state.selectedEntities) || (this.props.isRange && this.state.selectedEntities && this.state.selectedEntities.length <= 1)) &&
                                <OptionsList options={this.state.checkedChartPsLines && this.state.checkedChartPsLines.length > 0 ? this.state.checkedChartPsLines : this.state.chartSelectedNodes} className={"print-margin-top " +(this.state.isChartFullScreen ? "uk-padding-left" : "uk-padding-remove")}/>
                            }
                        </div>
                        :
                        ""}
                </div>
                <div className="uk-hidden">
                        <ExcelDetailsTable ref={el=>this.excelDetails= el} params = {params}/>
                </div>
                
                <Modal 
                    id={"stacks-warning-dialog"}
                    openDialog={this.state.openWarningDialog}
                    bodyContent={() => <div className="uk-text-large">{this.state.warningMsg}</div>}
                    dialogActions={this.warningDialogActions}
                    closeClick={() => this.setWarningDialogOpen(false, "") }
                    size={DIALOG_SIZE.MEDIUM}

                />
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        // userSettings: state.userSettings,
        // periodsStatus: state.periodsStatus,
        // datasetOptions: state.datasetOptions,
        // customViewData: state.customViewData,
        // tiers: state.tiers,
        // scenarios: state.scenarios,
        // periodsStatus: state.periodsStatus,
        // userSections: state.userSections,
        profitStackByCostHierarchy: state.profitStackByCostHierarchy,
        // profitFormats: state.profitFormats,
        // psLinesOptions: state.psLinesOptions,
        // quadrantTierOptions:state.quadrantTierOptions,
    };
  }
export default connect(mapStateToProps,null,null,{forwardRef: true})(ProfitStacks);
